import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { CurrencyEuroPipe, I18nMileageOrUnknownPipe } from '@caronsale/frontend-pipes';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CosBuyerClientService } from '@cosCoreServices/cos-salesman-client/cos-buyer-client.service';
import { combineLatest, debounceTime, filter, map, Observable, startWith } from 'rxjs';
import { EVehicleReadyToDriveStatus } from '@caronsale/cos-vehicle-models';
import { ESellerBusinessType, I18nFormatUtils } from '@caronsale/cos-models';
import { LookAndFeelService } from '../look-and-feel/look-and-feel.service';
import { AuctionRanges } from '@cosBuyer/partials/utils/auction-ranges.util';
import { Option } from '@caronsale/enzo';

const decimalPipe = new DecimalPipe('de');
export const currencyPipe: CurrencyEuroPipe = new CurrencyEuroPipe(new CurrencyPipe('de'));

export const PS_TO_KW = 0.7355;

@Injectable()
export class AuctionFilterRangesService {
  public constructor(
    private translateService: TranslateService,
    private cosBuyerClient: CosBuyerClientService,
    private lnfService: LookAndFeelService,
    private i18nMileageOrUnknownPipe: I18nMileageOrUnknownPipe,
  ) {}

  private countryOptions() {
    return combineLatest([this.cosBuyerClient.getCurrentBuyerUser(), this.translateService.onLangChange.pipe(startWith(null))]).pipe(
      filter(([user, _]) => !!user),
      map(([user, _]) => {
        let availableCountries = AuctionRanges.countries;

        if (availableCountries.includes(user.countryCode)) {
          availableCountries = [user.countryCode, ...availableCountries.filter(countryCode => countryCode !== user.countryCode)];
        }

        return [
          ...availableCountries.map(
            countryCode =>
              ({
                value: countryCode,
                label: this.translateService.instant('country-label.' + countryCode.toLowerCase()),
              }) as Option,
          ),
        ];
      }),
    );
  }

  private convertHPtoKW(hp: number) {
    return Math.floor(hp * PS_TO_KW);
  }

  public getRanges(): Observable<Record<string, Option[]>> {
    return combineLatest([this.countryOptions(), this.lnfService.selectedLookAndFeel$.pipe(startWith(null))]).pipe(
      debounceTime(0),
      map(([countries, lnf]) => {
        const EMPTY_OPTION: Option = {
          value: null,
          label: this.translateService.instant('auction-filter.any'),
        };

        return {
          countries,
          years: [
            EMPTY_OPTION,
            ...AuctionRanges.registrationYears.map(year => ({
              value: year.toString(),
              label: year.toString(),
            })),
          ],
          price: [
            EMPTY_OPTION,
            ...AuctionRanges.prices.map(price => ({
              value: price,
              label: decimalPipe.transform(price),
            })),
          ],
          mileage: [
            EMPTY_OPTION,
            ...AuctionRanges.mileages.map(i => ({
              value: i,
              label: this.i18nMileageOrUnknownPipe.transform(i, { addUnit: false }),
            })),
          ],
          transmission: AuctionRanges.transmissionTypes.map(transmission => ({
            value: transmission,
            label: this.translateService.instant(I18nFormatUtils.formatVehicleTransmission(transmission)),
          })),
          horsePower: [
            EMPTY_OPTION,
            ...AuctionRanges.enginePower.map(hp => ({
              value: hp,
              label: hp.toString(),
            })),
          ],
          kw: [
            EMPTY_OPTION,
            ...AuctionRanges.enginePower.map(value => {
              return {
                value,
                label: this.convertHPtoKW(value).toString(),
              };
            }),
          ],
          fuelTypes: AuctionRanges.fuelTypes
            .map(fuelType => ({
              value: fuelType,
              label: this.translateService.instant(I18nFormatUtils.formatVehicleFuelType(fuelType)),
            }))
            .sort((a, b) => a.label.localeCompare(b.label)),
          vehicleColors: AuctionRanges.colors
            .map(vehicleColor => ({
              value: vehicleColor,
              label: this.translateService.instant(I18nFormatUtils.formatVehicleColor(vehicleColor)),
            }))
            .sort((a, b) => a.label.localeCompare(b.label)),
          euroNorm: AuctionRanges.euroNorms.map(norm => ({
            value: norm,
            label: this.translateService.instant(I18nFormatUtils.formatVehicleEuroNorm(norm)),
          })),
          preOwners: [
            EMPTY_OPTION,
            ...AuctionRanges.preOwners.map(i => {
              return {
                value: i,
                label: this.translateService.instant('auction-filter.previous-owners', { numberOfPreOwners: i }),
              };
            }),
          ],
          vehicleCategory: AuctionRanges.vehicleCategory.map(category => ({
            value: category,
            label: this.translateService.instant(I18nFormatUtils.formatVehicleCategory(category)),
          })),
          numberOfDoors: AuctionRanges.doors.map(doors => ({
            value: doors,
            label: this.translateService.instant(I18nFormatUtils.formatVehicleDoors(doors)),
          })),
          numberOfSeats: [EMPTY_OPTION, ...AuctionRanges.numberOfSeats.map(seats => ({ value: seats, label: seats.toString() }))],
          searchRadius: [
            EMPTY_OPTION,
            ...AuctionRanges.searchRadius.map(distance => ({
              value: distance,
              label: this.translateService.instant('auction-filter.distance-km', { distance }),
            })),
          ],
          readyToDrive: [
            {
              value: EVehicleReadyToDriveStatus.YES,
              label: this.translateService.instant('vehicle.ready-to-drive.yes'),
            },
            {
              value: EVehicleReadyToDriveStatus.NO,
              label: this.translateService.instant('vehicle.ready-to-drive.no'),
            },
          ],
          taxation: [
            {
              value: null,
              label: this.translateService.instant('general.not-relevant'),
            },
            {
              value: true,
              label: this.translateService.instant('auction.vat-taxation-label-for-country.regular.??'),
            },
            {
              value: false,
              label: this.translateService.instant('auction.vat-taxation-label-for-country.differential.??'),
            },
          ],
          sellerType: [
            {
              value: ESellerBusinessType.TRADER,
              label: this.translateService.instant('auction.seller-type-label.trader'),
            },
            {
              value: ESellerBusinessType.LICENSED,
              label: this.translateService.instant('auction.seller-type-label.licensed'),
            },
            {
              value: ESellerBusinessType.OEM,
              label: this.translateService.instant('auction.seller-type-label.oem'),
            },
          ],
          minAskPrice: [
            {
              value: null,
              label: this.translateService.instant('general.not-relevant'),
            },
            {
              value: true,
              label: this.translateService.instant('auction.seller-view.auction-card.min-ask-reached'),
            },
            {
              value: false,
              label: this.translateService.instant('auction.seller-view.auction-card.min-ask-not-reached'),
            },
          ],
          advertisementTypes: lnf?.visibleAdvertisementTypes.map(option => ({ value: option, label: option })) || [],
        };
      }),
    );
  }
}
