import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { EUserType, IAuthenticationResult, IServiceMetaData, Time } from '@caronsale/cos-models';
import { COS_CONSTANTS } from '@cosCoreServices/constants/cos-constants.service';
import { I18nService } from '@cosCoreServices/i18n/i18n.service';
import { CosCoreClient } from '@cosCoreServices/core-client/cos-core-client.service';

import { environment } from '@cosCoreEnvironments/environment';
import { CookieLawComponent } from '@cosCoreComponentsGeneral/cookie-law/cookie-law.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatBottomSheet } from '@angular/material/bottom-sheet';

import { defineCustomElements, EnzoLanguageChangePayload } from '@caronsale/enzo/loader';
import { TranslateService } from '@ngx-translate/core';
import { I18nErrorDialogComponent } from '@cos/components/general/i18n/error-dialog/i18n-error-dialog.component';
import { I18nInfoDialogComponent } from '@cos/components/general/i18n/info-dialog/i18n-info-dialog.component';

defineCustomElements();

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  // Force refresh after 6 hours
  private readonly FORCE_REFRESH_THRESHOLD_IN_MS: number = 6 * 3600 * 1000;

  public uiEnvironmentLabel: string;

  public serviceEnvironmentLabel: string;

  public bannerIsHidden: boolean;

  private showErrorDialog: boolean;

  public constructor(
    public dialog: MatDialog,
    public translateService: TranslateService,
    private bottomSheet: MatBottomSheet,
    private router: Router,
    private cosCoreClient: CosCoreClient,
    private i18nService: I18nService,
    private constants: COS_CONSTANTS,
  ) {
    this.uiEnvironmentLabel = environment.label;
    this.serviceEnvironmentLabel = '?';
    this.bannerIsHidden = environment.production;
    this.showErrorDialog = false;
  }

  public ngOnInit(): void {
    const checkIfRefreshIsNecessary = () => {
      const lastUpdate = Number(localStorage.getItem('lastPageRefresh'));

      const now: number = Time.now().getTime();

      if (lastUpdate) {
        // If last update has happened
        if (lastUpdate < now - this.FORCE_REFRESH_THRESHOLD_IN_MS) {
          console.log(`Last refresh has happened ${(now - lastUpdate) / 1000} seconds ago. Refreshing....`);
          localStorage.setItem('lastPageRefresh', now.toString());
          location.reload();
        } else {
          console.log(`Last refresh has happened ${(now - lastUpdate) / 1000} seconds ago. Therefore not refreshing.`);
        }
      } else {
        // Initial refresh
        localStorage.setItem('lastPageRefresh', now.toString());
      }
    };

    checkIfRefreshIsNecessary();

    this.cosCoreClient.setUnauthorizedHandler(() => {
      // TODO: What to do if that happens?
    });

    // TODO Check if cookies are accepted
    if (localStorage.getItem('cookieLaw') === null) {
      this.bottomSheet
        .open(CookieLawComponent, {
          disableClose: true,
        })
        .afterDismissed()
        .subscribe(() => {
          localStorage.setItem('cookieLaw', '1');
        });
    }

    this.cosCoreClient.getServiceMetaInfo().subscribe((metaInfo: IServiceMetaData) => {
      this.serviceEnvironmentLabel = `${metaInfo.environment} (${environment.backendUrl})`;
    });

    this.cosCoreClient.validateAuthentication().subscribe((response: IAuthenticationResult) => {
      if (!response?.authenticated && null === this.router.url.match(/^\/$/) && null === this.router.url.match(/^\/register.*/)) {
        console.log(`User is not authenticated anymore. Redirecting to login modal.`);
        this.router.navigate(['/login']);
        this.cosCoreClient.signOff();
      } else {
        // Persist updated authentication
        this.cosCoreClient.updateAuthenticationData(response);

        // TODO: Redirect to internal/admin view

        // TODO: Refactor this logic!
        if (response.type === EUserType.DEALERSHIP && null !== this.router.url.match(/^\/salesman/)) {
          this.router.navigate(['/dealership']);
        }

        if (response.type === EUserType.SALESMAN && null !== this.router.url.match(/^\/dealership/)) {
          this.router.navigate(['/salesman/auctions']);
        }
      }
    });

    this.cosCoreClient.setSessionExpiredHandler(() => {
      // Go back to login page
      this.router.navigate(['/login']).then(() => {
        if (!this.showErrorDialog) {
          this.showErrorDialog = true;
          I18nErrorDialogComponent.show(this.dialog, 'error.session-expired', '', '450px').subscribe(() => this.cosCoreClient.signOff());
        }
      });
    });

    this.cosCoreClient.setNotFoundHandler(() => {
      if (!this.showErrorDialog) {
        this.showErrorDialog = true;
        I18nErrorDialogComponent.show(this.dialog, 'error.not-available-try-again', '', '450px').subscribe(() => (this.showErrorDialog = false));
      }
    });

    this.cosCoreClient.setServerErrorHandler(() => {
      if (!this.showErrorDialog) {
        this.showErrorDialog = true;
        I18nErrorDialogComponent.show(
          this.dialog,
          'error.server-problems-try-again-or-contact',
          '',
          { infoEmail: this.constants.INFO_EMAIL },
          '450px',
        ).subscribe(() => (this.showErrorDialog = false));
      }
    });

    this.cosCoreClient.setServiceNotAvailableHandler(() => {
      if (!this.showErrorDialog) {
        this.showErrorDialog = true;
        I18nInfoDialogComponent.show(this.dialog, 'dialog.general.connection-issue', '450px').subscribe(() => (this.showErrorDialog = false));
      }
    });

    this.cosCoreClient.setNoConnectionHandler(() => {
      if (!this.showErrorDialog) {
        this.showErrorDialog = true;
        I18nErrorDialogComponent.show(this.dialog, 'error.server-not-reachable', '', '450px').subscribe(() => (this.showErrorDialog = false));
      }
    });
  }

  public isServiceProductionEnvironment(): boolean {
    return this.serviceEnvironmentLabel === 'production' || this.serviceEnvironmentLabel === '?';
  }

  public isUIProductionEnvironment(): boolean {
    return environment.production;
  }

  public languageChange(event: CustomEvent<EnzoLanguageChangePayload>) {
    this.i18nService.selectLanguage(event.detail.newLanguage);
  }
}
