import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BaseModule } from '@cosCoreComponentsGeneral/base.module';
import { TaxationInfoComponent } from './taxation-info.component';
import { I18nAuctionPipesModule } from '@caronsale/frontend-pipes';
import { AdditionalTaxInfoComponent } from '../additional-tax-info/additional-tax-info.component';
import { EnzoComponentsModule } from '@enzo';
import { CosPopoverModule } from '@cosCoreFeatures/@cos/cos-popover/cos-popover.module';

@NgModule({
  declarations: [TaxationInfoComponent],
  exports: [TaxationInfoComponent],
  imports: [AdditionalTaxInfoComponent, BaseModule, CommonModule, CosPopoverModule, EnzoComponentsModule, I18nAuctionPipesModule],
})
export class TaxationInfoModule {}
