import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { ECountryCode } from '@cosTypes';

@Component({
  selector: 'enzo-flag',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: '<ng-content />',
  standalone: true,
  host: {
    '[country]': 'country()',
    '[size]': 'size()',
  },
})
export class EnzoFlagComponent {
  public country = input<ECountryCode>();
  public size = input<'small' | 'medium'>('medium');
}
