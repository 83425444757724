<form [formGroup]="guaranteeForm" [class.disable-all]="isHotBidPhaseActive">
  <div class="option enabled standard-option" *ngIf="standardOption" testid="standard-option">
    <enzo-icon class="start-align" icon="check-outline" color="black" size="small" />
    <div class="standard-label">
      <div class="option-label">
        {{ 'prebooked-service.guarantee.standard-label' | translate }}
        <enzo-icon icon="info-outline" color="purple" size="small" (click)="showPopoverFromTemplate($event, standardInfo)" testid="standard-option-icon" />
      </div>
      <enzo-text kind="caption" color="grey" [innerHtml]="'auction.guarantee.standard-sub-text' | translate: { urlLabelLink: conditionsUrlTemplate }" />
    </div>
    <span class="price start-align" testid="standard-option-price">{{ 0 | currencyEuro }}</span>
  </div>
  <div *ngIf="cosCheckPlusOption" class="option" [class.enabled]="(cosCheckPlusOption.isEnabled || cosCheckPlusOption.isSelected) && !isHotBidPhaseActive">
    <mat-checkbox #cosCheckPlusCheckbox [formControlName]="GUARANTEE_OPTION_COS_CHECK_PLUS" testid="cos-check-plus-option">
      <div class="option-label">
        {{ 'prebooked-service.guarantee.cos-check-plus-label' | translate }}
        <enzo-icon
          icon="info-outline"
          color="purple"
          size="small"
          (click)="showPopoverFromTemplate($event, cosCheckPlusInfo)"
          testid="cos-check-plus-option-icon" />
        <enzo-badge
          *ngIf="cosCheckPlusOption.discountPriceNet === 0 || cosCheckPlusOption.discountPriceNet"
          [label]="'prebooked-service.guarantee.new-price' | translate"
          color="yellow"
          testid="cos-check-plus-option-badge" />
      </div>
      <enzo-text kind="caption" color="grey" [innerHtml]="'auction.guarantee.cos-check-plus-sub-text' | translate: { urlLabelLink: conditionsUrlTemplate }" />
    </mat-checkbox>
    <span class="price" testid="cos-check-plus-option-price">
      {{
        (cosCheckPlusOption.discountPriceNet === 0 || cosCheckPlusOption.discountPriceNet ? cosCheckPlusOption.discountPriceNet : cosCheckPlusOption.priceNet)
          | currencyEuro
      }}
    </span>
    <enzo-icon
      class="disabled-info start-align"
      icon="info-outline"
      color="red"
      size="small"
      testid="cos-check-plus-option-icon-disabled"
      (click)="showPopoverFromTemplate($event, cosCheckPlusDisabledInfo)" />
  </div>
  <div *ngIf="extendedOption" class="option" [class.enabled]="(extendedOption.isEnabled || extendedOption.isSelected) && !isHotBidPhaseActive">
    <mat-checkbox #extendedCheckbox testid="extended-option" [formControlName]="GUARANTEE_OPTION_EXTENDED">
      <div class="option-label">
        {{ 'prebooked-service.guarantee.extended-label' | translate }}
        <enzo-icon icon="info-outline" color="purple" size="small" (click)="showPopoverFromTemplate($event, extendedInfo)" testid="extended-option-icon" />
        <enzo-badge
          *ngIf="extendedOption.discountPriceNet"
          testid="extended-option-badge"
          [label]="'prebooked-service.guarantee.new-price' | translate"
          color="yellow" />
      </div>
      <enzo-text kind="caption" color="grey">{{ 'auction.guarantee.extended-sub-text' | translate }}</enzo-text>
    </mat-checkbox>
    <span class="price" testid="extended-option-price">
      {{ (extendedOption.discountPriceNet ? extendedOption.discountPriceNet : extendedOption.priceNet) | currencyEuro }}
    </span>
    <enzo-icon
      class="disabled-info start-align"
      icon="info-outline"
      color="red"
      size="small"
      testid="extended-option-icon-disabled"
      (click)="showPopoverFromTemplate($event, extendedDisabledInfo)" />
  </div>
</form>

<ng-template #standardInfo>
  <span [innerHtml]="'prebooked-service.guarantee.info-icon-explanation.standard' | translate: TRANSLATE_PARAMS"></span><br /><br />
  {{ 'prebooked-service.guarantee.info-icon-common.guarantee-period' | translate: TRANSLATE_PARAMS }}
  <ul>
    <li>{{ 'prebooked-service.guarantee.info-icon-common.period-start-option-1' | translate }}</li>
  </ul>
  <span>{{ 'prebooked-service.guarantee.info-icon-common.period-extension-hint' | translate: TRANSLATE_PARAMS }}<br /></span>
  <span>{{ 'prebooked-service.guarantee.info-icon-common.period-extension-hint-2' | translate: TRANSLATE_PARAMS }}<br /><br /></span>
  <span [innerHtml]="'prebooked-service.guarantee.info-icon-common.disclaimer' | translate: TRANSLATE_PARAMS"></span>
</ng-template>

<ng-template #cosCheckPlusInfo>
  <div *ngIf="cosCheckPlusOption.discountPriceNet === 0 || cosCheckPlusOption.discountPriceNet">
    <span>{{ 'prebooked-service.guarantee.cos-check-plus-discount-label' | translate }}</span>
    <br /><br />
  </div>
  <span [innerHtml]="'prebooked-service.guarantee.info-icon-explanation.cos-check-plus-1' | translate: TRANSLATE_PARAMS"></span>
  <enzo-icon class="cos-check-plus-check-icon" icon="check" color="green" size="small" />
  <span [innerHtml]="'prebooked-service.guarantee.info-icon-explanation.cos-check-plus-2' | translate: TRANSLATE_PARAMS"></span>
  <br /><br />
  {{ 'prebooked-service.guarantee.info-icon-common.guarantee-period' | translate: TRANSLATE_PARAMS }}
  <ul>
    <li>{{ 'prebooked-service.guarantee.info-icon-common.period-start-option-1' | translate }}</li>
  </ul>
  <span>{{ 'prebooked-service.guarantee.info-icon-common.period-extension-hint' | translate: TRANSLATE_PARAMS }}<br /></span>
  <span>{{ 'prebooked-service.guarantee.info-icon-common.period-extension-hint-2' | translate: TRANSLATE_PARAMS }}<br /><br /></span>
  <span [innerHtml]="'prebooked-service.guarantee.info-icon-common.disclaimer' | translate: TRANSLATE_PARAMS"></span>
</ng-template>

<ng-template #cosCheckPlusDisabledInfo>
  {{ 'prebooked-service.guarantee.info-icon-disabled.cos-check-plus' | translate: TRANSLATE_PARAMS }}
  <br /><br />
  <span [innerHtml]="'prebooked-service.guarantee.info-icon-common.disclaimer' | translate: TRANSLATE_PARAMS"></span>
</ng-template>

<ng-template #extendedInfo>
  <span [innerHtml]="'prebooked-service.guarantee.info-icon-explanation.extended' | translate: TRANSLATE_PARAMS"></span>
  <br /><br />
  {{ 'prebooked-service.guarantee.info-icon-common.guarantee-period' | translate: TRANSLATE_PARAMS }}
  <ul>
    <li>{{ 'prebooked-service.guarantee.info-icon-common.period-start-option-1' | translate }}</li>
  </ul>
  <span>{{ 'prebooked-service.guarantee.info-icon-common.period-extension-hint' | translate: TRANSLATE_PARAMS }}<br /><br /></span>
  <span [innerHtml]="'prebooked-service.guarantee.info-icon-common.disclaimer' | translate: TRANSLATE_PARAMS"></span>
</ng-template>

<ng-template #extendedDisabledInfo>
  {{ 'prebooked-service.guarantee.info-icon-disabled.extended' | translate: TRANSLATE_PARAMS }}
  <br /><br />
  <span [innerHtml]="'prebooked-service.guarantee.info-icon-common.disclaimer' | translate: TRANSLATE_PARAMS"></span>
</ng-template>
