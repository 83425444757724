import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { distinctUntilChanged, Observable } from 'rxjs';
import { DestroyRef } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

/**
 * Reusable Angular validators. Named only by what they require.
 * (Same naming as the validators in Angular's Validators class).
 */
export class AngularValidators {
  /**
   * Creates a regex validator with custom errors.
   *
   * @param regex - regex to test against
   * @param error - what to return in case validation fails
   */
  public static regex(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return regex.test(control.value) ? null : error;
    };
  }

  /**
   * Creates a validator that checks if field complies with current COS password policy.
   *
   */
  public static password(): ValidatorFn {
    return Validators.compose([
      this.regex(/\d/, { hasNumber: true }),
      this.regex(/[A-Z]/, { hasUpperCase: true }),
      this.regex(/[a-z]/, { hasLowerCase: true }),
      this.regex(/[ !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/, { hasSpecialCharacter: true }),
      this.regex(/^.{8,}/, { hasMinLength: true }),
      Validators.maxLength(255),
      Validators.required,
    ]);
  }

  /**
   * Creates a validator that checks if the content of two fields is the same.
   *
   * @param field1Name
   * @param field2Name
   */
  public static fieldMatch(field1Name: string, field2Name: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.get(field1Name).value !== control.get(field2Name).value) {
        control.get(field2Name).setErrors({ mismatch: true });
        return { mismatch: true };
      }
      control.get(field2Name).setErrors(null);
      return null;
    };
  }

  public static requiredIf(predicate: (parentControl?: AbstractControl) => boolean): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (predicate(control.parent)) {
        return Validators.required(control);
      }
      return null;
    };
  }

  public static requiredIf$(
    observableFactory: (parentControl?: AbstractControl) => Observable<boolean>,
    initialValue: boolean,
    destroyRef?: DestroyRef,
  ): ValidatorFn {
    let isRequired = initialValue;
    let isSubscribed = false;
    function subscribeToUpdates(control: AbstractControl) {
      if (!isSubscribed) {
        isSubscribed = Boolean(
          observableFactory(control.parent)
            ?.pipe(distinctUntilChanged(), takeUntilDestroyed(destroyRef))
            .subscribe(value => {
              isRequired = value;
              control.updateValueAndValidity();
            }),
        );
      }
    }
    return (control: AbstractControl): ValidationErrors | null => {
      subscribeToUpdates(control);
      if (isRequired) {
        return Validators.required(control);
      }
      return null;
    };
  }

  public static exactLength(length: number): ValidatorFn {
    return Validators.compose([Validators.minLength(length), Validators.maxLength(length)]);
  }
}
