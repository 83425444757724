import { booleanAttribute, ChangeDetectionStrategy, Component, ElementRef, inject, input, model, signal, viewChild } from '@angular/core';
import { Color, Icon } from '@caronsale/enzo';
import { EnzoBadgeComponent } from '../../miscellaneous/enzo-badge/enzo-badge.component';
import { EnzoIconComponent } from '../../iconography/enzo-icon/enzo-icon.component';
import { EnzoLanguageSelectComponent } from './enzo-language-select/enzo-language-select.component';
import { EnzoLogoComponent } from '../../iconography/enzo-logo/enzo-logo.component';
import { EnzoTextComponent } from '../../typography/enzo-text/enzo-text.component';
import { TranslateModule } from '@ngx-translate/core';
import { EUserRole } from '@cosTypes';
import { Router } from '@angular/router';

export type EnzoHeaderMenuItem = {
  icon: Icon;
  label: string;
  dataAttributes?: Record<string, string>;
  badge?: { color: Color; label: string };
  isActive?: boolean;
  handler(): void;
};

export type EnzoHeaderMenuOptions = Array<Array<EnzoHeaderMenuItem>>;
export type EnzoHeaderAccountBannerConfig = {
  title: string;
  description: string;
  action?: {
    label: string;
    handler(): void;
  };
};

@Component({
  selector: 'enzo-header',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './enzo-header.component.html',
  styleUrl: './enzo-header.component.scss',
  imports: [EnzoBadgeComponent, EnzoIconComponent, EnzoLogoComponent, EnzoTextComponent, EnzoLanguageSelectComponent, TranslateModule],
  standalone: true,
})
export class EnzoHeaderComponent {
  public readonly EUserRole = EUserRole;

  public accountBanner = input<EnzoHeaderAccountBannerConfig>();
  public selectedUserRole = model<EUserRole>();
  public canChangeUserRole = input(false, { transform: booleanAttribute });
  public logoLink = input<string>('');
  public menuOptions = input<EnzoHeaderMenuOptions>([]);

  public flyout = viewChild<ElementRef<HTMLDivElement>>('flyout');
  public isMenuOpen = signal(false);

  public router = inject(Router);

  public menuButtonBlur(event: FocusEvent) {
    if (!this.flyout()?.nativeElement.contains(event.relatedTarget as HTMLElement)) {
      this.closeMenu();
    }
  }

  public menuButtonClick(event: Event, menuItem: EnzoHeaderMenuItem) {
    event.stopPropagation();
    menuItem.handler();
    this.closeMenu();
  }

  public closeMenu() {
    this.isMenuOpen.set(false);
  }

  public handleLogoClick(event: MouseEvent): void {
    event.preventDefault();

    if (event.ctrlKey || event.metaKey) {
      window.open(this.logoLink(), '_blank');
    } else {
      this.router.navigateByUrl(this.logoLink());
    }
  }
}
