import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { FeatureFlagKeys, ProductAnalyticsService } from '@cos/services/product-analytics/product-analytics.service';
import { of, timeout } from 'rxjs';

export function generateFeatureFlagResolver(featureFlag: FeatureFlagKeys): ResolveFn<boolean> {
  return () => {
    const analyticsService = inject(ProductAnalyticsService);
    return analyticsService.isOn(featureFlag).pipe(timeout({ first: 3000, with: () => of(false) }));
  };
}
