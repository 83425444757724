import { booleanAttribute, ChangeDetectionStrategy, Component, effect, Input, input, numberAttribute, output } from '@angular/core';
import { EnzoTextfieldCustomEvent, EnzoTextfieldInputPayload, ErrorStateMatchStrategy, FieldPrefix, FieldSuffix, InputType } from '@caronsale/enzo';
import { fromEvent } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ValueAccessor } from '../value-accessor';
import { parseGermanLocaleNumberString } from './utils';

@Component({
  selector: 'enzo-textfield',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: '<ng-content />',
  standalone: true,
  host: {
    '[type]': 'type()',
    '[label]': 'label()',
    '[description]': 'description()',
    '[hintClickable]': 'hintClickable()',
    '[hintText]': 'hintText()',
    '[showClearIcon]': 'showClearIcon()',
    '[placeholder]': 'placeholder()',
    '[autocomplete]': 'autocomplete()',
    '[maxLength]': 'maxLength()',
    '[min]': 'min()',
    '[max]': 'max()',
    '[step]': 'step()',
    '[pattern]': 'pattern()',
    '[resize]': 'resize()',
    '[fieldPrefix]': 'fieldPrefix()',
    '[fieldSuffix]': 'fieldSuffix()',
    '[validation]': 'validation()',
    '[rows]': 'rows()',
    '[minRows]': 'minRows()',
    '[maxRows]': 'maxRows()',
    '(enzoInput)': 'handleChangeEvent($event.target.value)',
    '(focusout)': 'onTouched()',
  },
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: EnzoTextfieldComponent,
      multi: true,
    },
  ],
})
export class EnzoTextfieldComponent extends ValueAccessor {
  public type = input<InputType>('text');
  public label = input<string>('');
  public description = input<string>('');
  public hintClickable = input(false, { transform: booleanAttribute });
  public hintText = input<string>('');
  public showClearIcon = input(false, { transform: booleanAttribute });
  public placeholder = input<string>('');
  public autocomplete = input<HTMLInputElement['autocomplete']>('');
  public maxLength = input<number | null, any>(null, { transform: value => numberAttribute(value, null) });
  public min = input<number | null, any>(null, { transform: value => numberAttribute(value, null) });
  public max = input<number | null, any>(null, { transform: value => numberAttribute(value, null) });
  public step = input<number | null, any>(null, { transform: value => numberAttribute(value, null) });
  public pattern = input<string>();
  public resize = input<'none' | 'vertical' | 'horizontal' | 'both'>('none');
  public fieldPrefix = input<FieldPrefix>({ type: 'default' });
  public fieldSuffix = input<FieldSuffix>({ type: 'default' });
  public validation = input<{
    type?: 'angular';
    errorStateMatch: ErrorStateMatchStrategy;
  }>({ errorStateMatch: 'onTouch' });
  public rows = input<number, any>(2, { transform: value => numberAttribute(value, 2) });
  public minRows = input<number | null, any>(null, { transform: value => numberAttribute(value, null) });
  public maxRows = input<number | null, any>(null, { transform: value => numberAttribute(value, null) });
  public readonly = input(false, { transform: booleanAttribute });

  @Input()
  public get value(): string {
    return this.el.value;
  }

  public set value(newValue: string) {
    this.el.value = newValue;
  }

  @Input()
  public get disabled(): boolean {
    return this.el.disabled;
  }

  public set disabled(newValue: boolean) {
    this.el.disabled = newValue;
  }

  public enzoInput = output<EnzoTextfieldCustomEvent<EnzoTextfieldInputPayload>>();
  public hintClick = output<EnzoTextfieldCustomEvent<void>>();

  public constructor() {
    super();
    // angular host property binding would change the name to "readOnly" and detect that it is not a property of the element
    effect(() => (this.el.readonly = this.readonly()));
    fromEvent(this.el, 'enzoInput')
      .pipe(takeUntilDestroyed())
      .subscribe(event => {
        (event as Event).stopImmediatePropagation();
        this.enzoInput.emit(event as EnzoTextfieldCustomEvent<EnzoTextfieldInputPayload>);
      });
    fromEvent(this.el, 'hintClick')
      .pipe(takeUntilDestroyed())
      .subscribe(event => {
        (event as Event).stopImmediatePropagation();
        this.hintClick.emit(event as EnzoTextfieldCustomEvent<void>);
      });
  }

  public forceFocus() {
    this.el.focus({ preventScroll: true });

    requestAnimationFrame(() => {
      this.el.scrollIntoView({
        block: 'nearest',
        inline: 'nearest',
        behavior: 'smooth',
      });
    });
  }

  public async enterInput(input: string, options: { forceInput?: boolean; replaceValue?: boolean } = {}) {
    return this.el.enterInput(input, options);
  }

  public async reset() {
    return this.el.reset();
  }

  public async decreaseNumber() {
    return this.el.decreaseNumber();
  }

  public async increaseNumber() {
    return this.el.increaseNumber();
  }

  public handleChangeEvent(value: string) {
    if (this.type() === 'number') {
      super.handleChangeEvent(value ? parseGermanLocaleNumberString(value) : null);
    } else {
      super.handleChangeEvent(value);
    }
  }

  public writeValue(value: any) {
    super.writeValue(value == null ? '' : value);
  }
}
