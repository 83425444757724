import { Duration, setMilliseconds, isPast, isEqual } from 'date-fns';
import intervalToDuration from 'date-fns/intervalToDuration';

export class DateUtils {
  public static expirationDateToDuration(expirationDate: Date): Duration {
    const now = setMilliseconds(new Date(), 0);
    const safeExpirationDate = setMilliseconds(expirationDate, 0);

    if (isPast(safeExpirationDate) || isEqual(now, safeExpirationDate)) {
      return {
        years: 0,
        months: 0,
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }

    return intervalToDuration({
      start: now,
      end: safeExpirationDate,
    });
  }
}
