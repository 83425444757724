import { enumToArray } from '@cosUtils/general';
import { EEuroNorm, EFuelType, ETransmissionType, EVehicleCategory, EVehicleColor, EVehicleDoors } from '@caronsale/cos-vehicle-models';
import { VehicleUtils } from '@cosUtils/business';
import { ECountryCode } from '@caronsale/cos-models';

export const MIN_PS = 10;
export const MAX_PS = 450;
export const STEP_PS = 10;

export abstract class AuctionRanges {
  public static readonly doors = enumToArray(EVehicleDoors, EVehicleDoors.UNKNOWN);
  public static readonly countries = [ECountryCode.DE, ECountryCode.AT, ECountryCode.FR];
  public static readonly registrationYears = VehicleUtils.EZS.map(String);
  public static readonly prices = VehicleUtils.PRICES;
  public static readonly mileages = VehicleUtils.MILEAGES;
  public static readonly transmissionTypes = enumToArray(ETransmissionType, ETransmissionType.UNKNOWN);
  public static readonly enginePower = VehicleUtils.generateNumberSequence(MIN_PS, MAX_PS, [[MAX_PS, STEP_PS]]);
  public static readonly fuelTypes = enumToArray<EFuelType>(EFuelType).filter(fuelType => fuelType !== EFuelType.UNKNOWN && fuelType !== EFuelType.OTHER);
  public static readonly colors = enumToArray<EVehicleColor>(EVehicleColor).filter(color => color !== EVehicleColor.UNKNOWN && color !== EVehicleColor.OTHER);
  public static readonly euroNorms = enumToArray(EEuroNorm, EEuroNorm.OTHER);
  public static readonly preOwners = Array.from(Array(9), (_, i) => {
    return i + 1;
  });
  public static readonly vehicleCategory = enumToArray(EVehicleCategory, EVehicleCategory.UNKNOWN);
  public static readonly numberOfSeats = Array.from(Array(7), (_, i) => i + 2);
  public static readonly searchRadius = [50, 100, 150, 200, 300, 400, 500];
}
