import { IAuction } from '@caronsale/cos-models';
import {
  ButtonNames,
  EPriceSource,
  ERenegotiationState,
  ICounterOfferFormParams,
  IRenegotiationData,
  RenegotiationData,
  EVisualRenegotiationStateBuyer,
} from './renegotiation-data';

export class BuyerRenegotiationData extends RenegotiationData {
  protected readonly currentPrice: number; // the price that this party would accept, if he pressed the "accept" button
  protected readonly currentPriceNet: number; // the net price (without VAT) that this party would accept, if he pressed the "accept" button
  protected readonly priceSource: EPriceSource; // used to determine the backend calls for the "accept" and "withdraw" buttons
  protected readonly buyerTranslationBaseKey: string;
  protected readonly buttons: ButtonNames[];
  protected readonly counterOfferFormParams: ICounterOfferFormParams;

  public constructor(public auction: IAuction) {
    super(auction); // determine renegotiationState

    // text keys
    this.buyerTranslationBaseKey = this.getRenegotiationStateTranslateKey() + '.buyer';

    this.priceSource = this.getAcceptButtonPriceSource();
    [this.currentPrice, this.currentPriceNet] = this.getCurrentPrices(this.priceSource);

    const lastPriceWeOffered = auction.counterOfferByBuyer || Math.max(auction.highestBidValueAtEndingTime, auction.currentHighestBidValue);
    const counterOfferStep = this.currentPrice <= 5000 ? 50 : 100;
    const renegotiationRoundData = this.getRenegotiationRoundData();
    this.counterOfferFormParams = {
      startWith: renegotiationRoundData.buyer.minOffer,
      min: renegotiationRoundData.buyer.minOffer,
      max: renegotiationRoundData.buyer.maxOffer,
      step: counterOfferStep,
      lastOffer: lastPriceWeOffered,
      counterOfferConfirmBaseKey: 'dialog.buyer.confirm-renegotiation-offer',
      auctionUuid: auction.uuid,
    };

    // buttons
    this.buttons = this.getButtons();
  }

  // return all the values determined in the constructor
  // the declaration as "readonly" makes sure they all are initialized in the contructor.
  public getStatus(): IRenegotiationData {
    const renegotiationRoundData = this.getRenegotiationRoundData();
    return {
      round: renegotiationRoundData.buyer.currentRound,
      totalRounds: renegotiationRoundData.totalRounds,
      currentPrice: this.currentPrice,
      currentPriceNet: this.currentPriceNet,
      priceSource: this.priceSource,
      isWaitingForTheOtherSide: this.isWaitingForSeller(),
      statusHeadlineKey: this.buyerTranslationBaseKey + '.headline',
      statusHintKey: this.buyerTranslationBaseKey + '.status',
      offerLine1Key: this.buyerTranslationBaseKey + '.offer1',
      offerLine2Key: this.buyerTranslationBaseKey + '.offer2',
      hasAcceptButton: this.buttons.includes('Accept'),
      hasAgreeOnMidpointButton: this.buttons.includes('AgreeOnMidpoint'),
      hasCounterOfferButton: this.buttons.includes('Offer'),
      hasWithdrawButton: this.buttons.includes('Withdraw'),
      counterOfferFormParams: this.counterOfferFormParams,
    };
  }

  private getAcceptButtonPriceSource(): EPriceSource {
    switch (this.renegotiationState) {
      case ERenegotiationState.INITIAL_TEST_CAR:
      case ERenegotiationState.INITIAL_WAITING_FOR_MANUAL_ACTION:
        return EPriceSource.NONE;

      case ERenegotiationState.INITIAL:
      case ERenegotiationState.INITIAL_BUYER_SENT_AN_OFFER_WAITING_FOR_SELLER:
      case ERenegotiationState.INITIAL_BUYER_HAS_WITHDRAWN_WAITING_FOR_SELLER:
      case ERenegotiationState.INITIAL_SELLER_HAS_WITHDRAWN_WAITING_FOR_BUYER:
      case ERenegotiationState.INITIAL_SELLER_HAS_WITHDRAWN:
      case ERenegotiationState.INITIAL_BOTH_HAVE_WITHDRAWN:
        return EPriceSource.MINIMUM_ASK;

      case ERenegotiationState.INITIAL_SELLER_SENT_AN_OFFER_WAITING_FOR_BUYER:
      case ERenegotiationState.INITIAL_BUYER_HAS_WITHDRAWN:
      case ERenegotiationState.NEW_ROUND:
      case ERenegotiationState.NEW_ROUND_BUYER_SENT_AN_OFFER_WAITING_FOR_SELLER:
      case ERenegotiationState.NEW_ROUND_BUYER_HAS_WITHDRAWN_WAITING_FOR_SELLER:
      case ERenegotiationState.NEW_ROUND_SELLER_SENT_AN_OFFER_WAITING_FOR_BUYER:
      case ERenegotiationState.NEW_ROUND_SELLER_HAS_WITHDRAWN_WAITING_FOR_BUYER:
      case ERenegotiationState.NEW_ROUND_BUYER_HAS_WITHDRAWN:
      case ERenegotiationState.NEW_ROUND_SELLER_HAS_WITHDRAWN:
      case ERenegotiationState.NEW_ROUND_BOTH_HAVE_WITHDRAWN:
      case ERenegotiationState.TOO_CLOSE_SELLER_HAS_WITHDRAWN_BUYER_ACCEPTED_MIDPOINT:
      case ERenegotiationState.TOO_CLOSE_BOTH_HAVE_WITHDRAWN:
        return EPriceSource.LAST_OFFER_BY_SELLER;

      case ERenegotiationState.TOO_CLOSE:
      case ERenegotiationState.TOO_CLOSE_BUYER_ACCEPTED_MIDPOINT_WAITING_FOR_SELLER:
      case ERenegotiationState.TOO_CLOSE_BUYER_HAS_WITHDRAWN_WAITING_FOR_SELLER:
      case ERenegotiationState.TOO_CLOSE_SELLER_ACCEPTED_MIDPOINT_WAITING_FOR_BUYER:
      case ERenegotiationState.TOO_CLOSE_SELLER_HAS_WITHDRAWN_WAITING_FOR_BUYER:
      case ERenegotiationState.TOO_CLOSE_BUYER_HAS_WITHDRAWN_SELLER_ACCEPTED_MIDPOINT:
        return EPriceSource.MIDPOINT;
    }
  }

  private getButtons(): ButtonNames[] {
    switch (this.renegotiationState) {
      case ERenegotiationState.INITIAL_TEST_CAR:
      case ERenegotiationState.INITIAL_WAITING_FOR_MANUAL_ACTION:
        return [];

      case ERenegotiationState.INITIAL:
        return ['Accept', 'Offer', 'Withdraw'];
      case ERenegotiationState.INITIAL_BUYER_SENT_AN_OFFER_WAITING_FOR_SELLER:
        return ['Accept', 'Offer'];
      case ERenegotiationState.INITIAL_BUYER_HAS_WITHDRAWN_WAITING_FOR_SELLER:
        return ['Accept'];
      case ERenegotiationState.INITIAL_SELLER_SENT_AN_OFFER_WAITING_FOR_BUYER:
        return ['Accept', 'Offer', 'Withdraw'];
      case ERenegotiationState.INITIAL_SELLER_HAS_WITHDRAWN_WAITING_FOR_BUYER:
        return ['Accept', 'Offer', 'Withdraw'];
      case ERenegotiationState.INITIAL_BUYER_HAS_WITHDRAWN:
        return ['Accept'];
      case ERenegotiationState.INITIAL_SELLER_HAS_WITHDRAWN:
        return ['Accept'];
      case ERenegotiationState.INITIAL_BOTH_HAVE_WITHDRAWN:
        return ['Accept'];

      case ERenegotiationState.NEW_ROUND:
        return ['Accept', 'Offer', 'Withdraw'];
      case ERenegotiationState.NEW_ROUND_BUYER_SENT_AN_OFFER_WAITING_FOR_SELLER:
        return ['Accept', 'Offer'];
      case ERenegotiationState.NEW_ROUND_BUYER_HAS_WITHDRAWN_WAITING_FOR_SELLER:
        return ['Accept'];
      case ERenegotiationState.NEW_ROUND_SELLER_SENT_AN_OFFER_WAITING_FOR_BUYER:
        return ['Accept', 'Offer', 'Withdraw'];
      case ERenegotiationState.NEW_ROUND_SELLER_HAS_WITHDRAWN_WAITING_FOR_BUYER:
        return ['Accept', 'Offer', 'Withdraw'];
      case ERenegotiationState.NEW_ROUND_BUYER_HAS_WITHDRAWN:
        return ['Accept'];
      case ERenegotiationState.NEW_ROUND_SELLER_HAS_WITHDRAWN:
        return ['Accept'];
      case ERenegotiationState.NEW_ROUND_BOTH_HAVE_WITHDRAWN:
        return ['Accept'];

      case ERenegotiationState.TOO_CLOSE:
        return ['AgreeOnMidpoint', 'Withdraw'];
      case ERenegotiationState.TOO_CLOSE_BUYER_ACCEPTED_MIDPOINT_WAITING_FOR_SELLER:
        return [];
      case ERenegotiationState.TOO_CLOSE_BUYER_HAS_WITHDRAWN_WAITING_FOR_SELLER:
        return ['AgreeOnMidpoint'];
      case ERenegotiationState.TOO_CLOSE_SELLER_ACCEPTED_MIDPOINT_WAITING_FOR_BUYER:
        return ['AgreeOnMidpoint', 'Withdraw'];
      case ERenegotiationState.TOO_CLOSE_SELLER_HAS_WITHDRAWN_WAITING_FOR_BUYER:
        return ['AgreeOnMidpoint', 'Withdraw'];
      case ERenegotiationState.TOO_CLOSE_BUYER_HAS_WITHDRAWN_SELLER_ACCEPTED_MIDPOINT:
        return ['Accept'];
      case ERenegotiationState.TOO_CLOSE_SELLER_HAS_WITHDRAWN_BUYER_ACCEPTED_MIDPOINT:
        return ['Accept'];
      case ERenegotiationState.TOO_CLOSE_BOTH_HAVE_WITHDRAWN:
        return ['Accept'];
    }
  }

  public showCountdown(): boolean {
    switch (this.renegotiationState) {
      case ERenegotiationState.INITIAL:
      case ERenegotiationState.INITIAL_SELLER_SENT_AN_OFFER_WAITING_FOR_BUYER:
      case ERenegotiationState.INITIAL_SELLER_HAS_WITHDRAWN_WAITING_FOR_BUYER:
      case ERenegotiationState.NEW_ROUND:
      case ERenegotiationState.NEW_ROUND_SELLER_SENT_AN_OFFER_WAITING_FOR_BUYER:
      case ERenegotiationState.NEW_ROUND_SELLER_HAS_WITHDRAWN_WAITING_FOR_BUYER:
      case ERenegotiationState.TOO_CLOSE:
      case ERenegotiationState.TOO_CLOSE_SELLER_ACCEPTED_MIDPOINT_WAITING_FOR_BUYER:
      case ERenegotiationState.TOO_CLOSE_SELLER_HAS_WITHDRAWN_WAITING_FOR_BUYER:
        return true;

      case ERenegotiationState.INITIAL_BUYER_SENT_AN_OFFER_WAITING_FOR_SELLER:
      case ERenegotiationState.INITIAL_BUYER_HAS_WITHDRAWN_WAITING_FOR_SELLER:
      case ERenegotiationState.NEW_ROUND_BUYER_SENT_AN_OFFER_WAITING_FOR_SELLER:
      case ERenegotiationState.NEW_ROUND_BUYER_HAS_WITHDRAWN_WAITING_FOR_SELLER:
      case ERenegotiationState.TOO_CLOSE_BUYER_ACCEPTED_MIDPOINT_WAITING_FOR_SELLER:
      case ERenegotiationState.TOO_CLOSE_BUYER_HAS_WITHDRAWN_WAITING_FOR_SELLER:
      case ERenegotiationState.INITIAL_BUYER_HAS_WITHDRAWN:
      case ERenegotiationState.INITIAL_SELLER_HAS_WITHDRAWN:
      case ERenegotiationState.INITIAL_BOTH_HAVE_WITHDRAWN:
      case ERenegotiationState.NEW_ROUND_BUYER_HAS_WITHDRAWN:
      case ERenegotiationState.NEW_ROUND_SELLER_HAS_WITHDRAWN:
      case ERenegotiationState.NEW_ROUND_BOTH_HAVE_WITHDRAWN:
      case ERenegotiationState.TOO_CLOSE_BUYER_HAS_WITHDRAWN_SELLER_ACCEPTED_MIDPOINT:
      case ERenegotiationState.TOO_CLOSE_SELLER_HAS_WITHDRAWN_BUYER_ACCEPTED_MIDPOINT:
      case ERenegotiationState.TOO_CLOSE_BOTH_HAVE_WITHDRAWN:
        return false;
    }
  }

  public getVisualRenegotiationState(): EVisualRenegotiationStateBuyer {
    switch (this.renegotiationState) {
      case ERenegotiationState.INITIAL:
        return EVisualRenegotiationStateBuyer.INITIAL;

      case ERenegotiationState.INITIAL_BUYER_SENT_AN_OFFER_WAITING_FOR_SELLER:
      case ERenegotiationState.NEW_ROUND_BUYER_SENT_AN_OFFER_WAITING_FOR_SELLER:
      case ERenegotiationState.TOO_CLOSE_BUYER_ACCEPTED_MIDPOINT_WAITING_FOR_SELLER:
        return EVisualRenegotiationStateBuyer.OFFER_SENT_BY_BUYER;

      case ERenegotiationState.INITIAL_SELLER_SENT_AN_OFFER_WAITING_FOR_BUYER:
      case ERenegotiationState.NEW_ROUND:
      case ERenegotiationState.NEW_ROUND_SELLER_SENT_AN_OFFER_WAITING_FOR_BUYER:
      case ERenegotiationState.INITIAL_SELLER_HAS_WITHDRAWN_WAITING_FOR_BUYER:
      case ERenegotiationState.TOO_CLOSE:
      case ERenegotiationState.TOO_CLOSE_SELLER_ACCEPTED_MIDPOINT_WAITING_FOR_BUYER:
      case ERenegotiationState.NEW_ROUND_SELLER_HAS_WITHDRAWN_WAITING_FOR_BUYER:
      case ERenegotiationState.TOO_CLOSE_SELLER_HAS_WITHDRAWN_WAITING_FOR_BUYER:
        return EVisualRenegotiationStateBuyer.OFFER_SENT_BY_SELLER;

      case ERenegotiationState.INITIAL_TEST_CAR:
      case ERenegotiationState.INITIAL_WAITING_FOR_MANUAL_ACTION:
        return EVisualRenegotiationStateBuyer.TEST;

      default:
        return EVisualRenegotiationStateBuyer.WITHDRAWN_BUYER;
    }
  }
}
