import {
  CommonUtils,
  EPrebookedServiceType,
  IPrebookedService,
  IPrebookedServiceBNPLOption,
  IPrebookedServiceGuaranteeOption,
  IPrebookedServiceTransportOption,
  PrebookedServiceOptionType,
} from '@caronsale/cos-models';

export class PrebookedServicesUtils {
  public static getService(prebookedServices: IPrebookedService[], prebookedServiceType: EPrebookedServiceType): IPrebookedService {
    return prebookedServices?.find(service => service.type === prebookedServiceType);
  }

  public static getOption<T extends IPrebookedServiceTransportOption | IPrebookedServiceGuaranteeOption | IPrebookedServiceBNPLOption>(
    prebookedService: IPrebookedService,
    optionType: PrebookedServiceOptionType,
  ): T {
    return prebookedService?.options?.find(option => option.type === optionType) as T;
  }

  public static isOptionSelected(
    prebookedServices: IPrebookedService[],
    prebookedServiceType: EPrebookedServiceType,
    optionType: PrebookedServiceOptionType,
  ): boolean {
    const prebookedService: IPrebookedService = PrebookedServicesUtils.getService(prebookedServices, prebookedServiceType);
    return PrebookedServicesUtils.getOption(prebookedService, optionType)?.isSelected;
  }

  public static areSelectedOptionsEqual(prebookedServices1: IPrebookedService[], prebookedServices2: IPrebookedService[]): boolean {
    if (!Array.isArray(prebookedServices1) || !Array.isArray(prebookedServices2) || prebookedServices1.length !== prebookedServices2.length) {
      return false;
    }
    return prebookedServices1.every((service1, index) => this.getSelectedOptionNames(service1) === this.getSelectedOptionNames(prebookedServices2[index]));
  }

  private static getSelectedOptionNames(prebookedService: IPrebookedService) {
    return prebookedService.options.reduce((optionNameList, option) => (option.isSelected ? optionNameList + ',' + option.type : optionNameList), '');
  }

  public static copyOptionSelectionsForAllServices(fromPrebookedServices: IPrebookedService[], toPrebookedServices: IPrebookedService[]): void {
    if (!Array.isArray(fromPrebookedServices) || !Array.isArray(toPrebookedServices) || fromPrebookedServices.length !== toPrebookedServices.length) {
      return;
    }
    toPrebookedServices.forEach((toService, index) => this.copyOptionsSelectionsForOneService(fromPrebookedServices[index], toService));
  }

  private static copyOptionsSelectionsForOneService(fromService: IPrebookedService, toService: IPrebookedService) {
    toService.options.forEach((toOption, index) => (toOption.isSelected = fromService.options[index].isSelected));
  }

  public static selectServiceOption(
    auctionUuid: string, // for debug output only
    prebookedServices: IPrebookedService[],
    serviceType: EPrebookedServiceType,
    optionTypeToSelect: PrebookedServiceOptionType,
    deselectOthers: boolean,
    isAdmin?: boolean, // allow selection of disabled options.
  ): IPrebookedService[] {
    if (!prebookedServices) {
      console.error(`Cannot prebook a ${serviceType} option for auction ${auctionUuid}: no prebookedServices`);
      return null;
    }

    const newPrebookedServices = CommonUtils.deepCloneObject(prebookedServices);
    const prebookedService = PrebookedServicesUtils.getService(newPrebookedServices, serviceType);
    if (!prebookedService) {
      console.error(`Cannot prebook a ${serviceType} option for auction ${auctionUuid}: no ${serviceType} service in prebookedServices`);
      return null;
    }
    if (!prebookedService.isEnabled && !isAdmin) {
      console.error(`Cannot prebook a ${serviceType} option for auction ${auctionUuid}: ${serviceType} service in not enabled in prebookedServices`);
      return null;
    }

    const optionToSelect = prebookedService.options.find(option => option.type === optionTypeToSelect);
    if (!optionToSelect) {
      console.error(
        `Cannot prebook a ${serviceType} option for auction ${auctionUuid}: option ${optionTypeToSelect} does not exist in the ${serviceType} service of this auction`,
      );
      return null;
    }
    if (optionToSelect.isSelected) {
      return newPrebookedServices; // OK (nothing to do)
    }
    if (!optionToSelect.isEnabled && !isAdmin) {
      console.error(
        `Cannot prebook a ${serviceType} option for auction ${auctionUuid}: option ${optionTypeToSelect} is not enabled in the ${serviceType} service of this auction`,
      );
      return null;
    }

    if (deselectOthers) {
      prebookedService.options.forEach(option => (option.isSelected = false));
    }
    optionToSelect.isSelected = true;
    return newPrebookedServices;
  }

  public static deselectServiceOption(
    auctionUuid: string, // for debug output only
    prebookedServices: IPrebookedService[],
    serviceType: EPrebookedServiceType,
    optionTypeToDeselect: PrebookedServiceOptionType,
  ): IPrebookedService[] {
    if (!prebookedServices) {
      console.error(`Cannot prebook a ${serviceType} option for auction ${auctionUuid}: no prebookedServices`);
      return null;
    }

    const newPrebookedServices = CommonUtils.deepCloneObject(prebookedServices);
    const prebookedService = PrebookedServicesUtils.getService(newPrebookedServices, serviceType);
    if (!prebookedService) {
      console.error(`Cannot prebook a ${serviceType} option for auction ${auctionUuid}: no ${serviceType} service in prebookedServices`);
      return null;
    }
    if (!prebookedService.isEnabled) {
      console.error(`Cannot prebook a ${serviceType} option for auction ${auctionUuid}: ${serviceType} service in not enabled in prebookedServices`);
      return null;
    }

    const optionToDeSelect = prebookedService.options.find(option => option.type === optionTypeToDeselect);
    if (!optionToDeSelect) {
      console.error(
        `Cannot prebook a ${serviceType} option for auction ${auctionUuid}: option ${optionTypeToDeselect} does not exist in the ${serviceType} service of this auction`,
      );
      return null;
    }
    if (!optionToDeSelect.isSelected) {
      return newPrebookedServices; // OK (nothing to do)
    }
    if (!optionToDeSelect.isEnabled) {
      console.error(
        `Cannot prebook a ${serviceType} option for auction ${auctionUuid}: option ${optionTypeToDeselect} is not enabled in the ${serviceType} service of this auction`,
      );
      return null;
    }

    optionToDeSelect.isSelected = false;
    return newPrebookedServices;
  }
}
