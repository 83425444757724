<label>
  <input
    #checkbox
    type="checkbox"
    [checked]="_checked()"
    [indeterminate]="_indeterminate()"
    [disabled]="_disabled()"
    (change)="handleChange($event)"
    testid="input" />

  @if (label()) {
    <enzo-text class="label" kind="emphasis-label" [align]="labelPosition() === 'leading' ? 'right' : 'left'" testid="label">{{ label() }}</enzo-text>
  }
  @if (description()) {
    <enzo-text class="description" kind="caption" [align]="labelPosition() === 'leading' ? 'right' : 'left'" testid="description">
      {{ description() }}
    </enzo-text>
  }
  @if (showValidationState() && !_disabled() && isValid() === false) {
    <enzo-text class="error-message" kind="caption" testid="error-message">{{ errorMessage }}</enzo-text>
  }
</label>
