import { HttpClient } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { catchError, map, Observable, of, retry, tap, throwError } from 'rxjs';
import { IPhraseConfig, TranslationUrlConfig } from '@cosCoreServices/i18n/i18n.service';
import { ELanguageCode } from '@caronsale/cos-models';

const LANGUAGES_TO_LOAD_FROM_ASSETS = [ELanguageCode.DE];

export class PhraseTranslateLoader implements TranslateLoader {
  private lastErrors: Error[];
  private lastRetryCount: number;

  public constructor(
    private http: HttpClient,
    private phraseConfig: IPhraseConfig,
    private translationUrls: TranslationUrlConfig,
  ) {}

  public getTranslation(lang: ELanguageCode): Observable<unknown> {
    this.lastErrors = [];
    this.lastRetryCount = 0;
    return LANGUAGES_TO_LOAD_FROM_ASSETS.includes(lang) ? this.loadFromAssets(lang) : this.loadFromCloudinaryWithFallbackToPhrase(lang);
  }

  private retryOnceAndLogToSentryOnError(langCode: string) {
    return retry({
      delay: (error, retryCount) => {
        this.lastErrors.push(error);
        this.lastRetryCount = retryCount;
        if (retryCount <= 1) {
          window['SENTRY_CLIENT']?.captureMessage(`Retrying to load translations for ${langCode}`, 'error', { originalException: error });
          return of(true);
        }
        return throwError(error);
      },
    });
  }

  private logRetryCountAndErrorsOnSuccess(langCode: string) {
    return tap(() => {
      if (this.lastErrors.length) {
        window['SENTRY_CLIENT']?.captureMessage(`Succeeded to load translations for ${langCode} after ${this.lastRetryCount} retries`, 'log', {
          data: this.lastErrors,
        });
      }
    });
  }

  private loadFromAssets(langCode: string): Observable<unknown> {
    return this.http.get(`./assets/i18n/${langCode}.json`).pipe(
      this.retryOnceAndLogToSentryOnError(langCode),
      this.logRetryCountAndErrorsOnSuccess(langCode),
      map(translations => JSON.parse(JSON.stringify(translations).replace(/\[\/*NOTRANSLATE\]/g, ''))),
    );
  }

  private loadFromCloudinaryWithFallbackToPhrase(langCode: ELanguageCode) {
    return this.http.get(`${this.translationUrls[langCode]}?t=${new Date().getTime()}`).pipe(
      catchError(error => {
        window['SENTRY_CLIENT']?.captureMessage(`Cannot load translations for ${langCode} from ${this.translationUrls[langCode]}`, 'error', {
          originalException: error,
        });
        return this.loadFromPhrase(langCode);
      }),
    );
  }

  private loadFromPhrase(langCode: string): Observable<unknown> {
    const headers = {
      Authorization: `token ${this.phraseConfig.phraseAccessToken}`,
      'Content-Type': 'application/json',
    };
    const uri = `${this.phraseConfig.phraseEndpointUrl}${this.phraseConfig.phraseProjectId}/locales/${langCode}/download`;
    const params = {
      file_format: 'nested_json',
      ...(this.phraseConfig.sourceBranchName ? { branch: this.phraseConfig.sourceBranchName } : {}),
    };

    return this.http.get(uri, { headers, params }).pipe(this.retryOnceAndLogToSentryOnError(langCode), this.logRetryCountAndErrorsOnSuccess(langCode));
  }
}
