/* eslint-disable @angular-eslint/component-class-suffix */
import { AfterViewInit, Component, computed, DestroyRef, ElementRef, inject, Injector, input, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { combineLatest, noop, of, startWith } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ValidationResultType } from '@caronsale/enzo/dist/types/helpers';

@Component({ template: '' })
export abstract class EnzoBaseValueAccessor<T> implements ControlValueAccessor, AfterViewInit {
  public errorStateMatch = input<'onTouch' | 'onDirty' | 'immediately' | 'never'>('onTouch');

  protected el = inject(ElementRef).nativeElement;
  protected injector: Injector = inject(Injector);

  protected showValidationState = computed(() =>
    !this.errorStateMatch() || this.errorStateMatch() === 'onTouch'
      ? this.isTouched()
      : this.errorStateMatch() === 'onDirty'
        ? this.isDirty()
        : this.errorStateMatch() === 'immediately',
  );
  protected isTouched = signal(false);
  protected isDirty = signal(false);
  protected isValid = signal(true);
  protected errorMessage: string;
  protected validationResultType: ValidationResultType;
  protected onTouched: () => void = noop;
  protected lastValue: T;
  // tells if "undefined" is actually the lastValue or just the initial "no value propagated yet" state
  private hasLastValue = false;

  protected destroyRef: DestroyRef = inject(DestroyRef);
  private translateService: TranslateService = inject(TranslateService, { optional: true });
  private hasAbstractFormControl = false;
  private onChange: (value: T) => void = noop;

  public ngAfterViewInit() {
    if (this.hasAbstractFormControl) {
      const ngControl = this.injector.get(NgControl, null, { optional: true });
      if (ngControl?.statusChanges) {
        combineLatest({
          status: ngControl.statusChanges.pipe(startWith(ngControl.status)),
          langChangeEvent: this.translateService?.onLangChange?.pipe(startWith(null)) || of(null),
        })
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe(({ status }) => {
            this.isValid.set(status !== 'INVALID');
            this.errorMessage = null;
            this.validationResultType = null;
            Object.values(ngControl!.errors ?? {}).forEach((error: any) => {
              if (error && typeof error === 'object') {
                this.addError(error);
              }
            });
            this.isTouched.set(ngControl!.touched);
            this.isDirty.set(ngControl!.dirty);
          });
      }
    }
  }

  private addError(error: { messageKey?: string; translationParams?: object; message?: string; type?: ValidationResultType }) {
    if (typeof error.messageKey === 'string') {
      const translatedMessage = this.translateService.instant(error.messageKey, error.translationParams);
      this.appendToErrorMessage(translatedMessage);
    } else if (typeof error.message === 'string') {
      this.appendToErrorMessage(error.message);
    }
    if (typeof error.type !== 'string') {
      // undefined means "invalid"
      this.validationResultType = 'invalid';
      return;
    }
    if (this.validationResultType !== 'invalid') {
      // undefined, null or 'incomplete'. If it is already "invalid", it stays "invalid"
      this.validationResultType = error.type;
    }
  }

  private appendToErrorMessage(translatedMessage: string) {
    if (!this.errorMessage) {
      this.errorMessage = translatedMessage;
      return;
    }
    this.errorMessage += '\n' + translatedMessage;
  }

  public abstract writeValue(value: unknown): void;
  public abstract setDisabledState(isDisabled: boolean): void;

  protected propagateChange(value: T) {
    if (!this.hasLastValue || value !== this.lastValue) {
      this.lastValue = value;
      this.hasLastValue = true;
      this.onChange(value);
    }
  }

  public registerOnChange(fn: (value: T) => void) {
    this.hasAbstractFormControl = true;
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void) {
    this.hasAbstractFormControl = true;
    this.onTouched = fn;
  }
}
