import { booleanAttribute, ChangeDetectionStrategy, Component, computed, input, model } from '@angular/core';
import { EnzoTextComponent } from '../../typography/enzo-text/enzo-text.component';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { EnzoCheckboxToggleBaseComponent } from './enzo-checkbox-toggle-base.component';

@Component({
  selector: 'enzo-checkbox',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './enzo-checkbox-toggle.component.html',
  styleUrls: ['../enzo-checkbox-toggle-radio-texts.scss', './enzo-checkbox.component.scss'],
  standalone: true,
  imports: [EnzoTextComponent],
  host: {
    '(focusout)': 'isTouched.set(true); onTouched()',
    '(keydown.enter)': 'checkbox().nativeElement.click()',
    '[attr.indeterminate]': '_indeterminate()',
    '[attr.checked]': '_checked()',
    '[attr.disabled]': '_disabled()',
    '[attr.data-label-position]': 'labelPosition()',
    '[attr.data-border]': 'border()',
    '[attr.data-internal-select-mode]': 'internalSelectMode()',
  },
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: EnzoCheckboxComponent,
      multi: true,
    },
  ],
})
export class EnzoCheckboxComponent extends EnzoCheckboxToggleBaseComponent {
  public border = input(false, { transform: booleanAttribute });
  // used by the select dropdown for multiple selection
  public internalSelectMode = input(false, { transform: booleanAttribute });

  public indeterminate = model(false);
  protected _indeterminate = computed(() => booleanAttribute(this.indeterminate()));
}
