<label>
  <input
    #radio
    type="radio"
    [checked]="_checked()"
    [tabIndex]="internalFocusable() ? 0 : -1"
    [value]="value()"
    [name]="name()"
    [disabled]="_disabled()"
    (change)="handleChange($event)"
    testid="input" />

  @if (label()) {
    <enzo-text class="label" kind="emphasis-label" [align]="labelPosition() === 'leading' ? 'right' : 'left'" testid="label">{{ label() }}</enzo-text>
  }
  @if (description()) {
    <enzo-text class="description" kind="caption" [align]="labelPosition() === 'leading' ? 'right' : 'left'" testid="description">
      {{ description() }}
    </enzo-text>
  }
</label>
