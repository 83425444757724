import { ChangeDetectionStrategy, Component, computed, ElementRef, inject, signal } from '@angular/core';
import { EnzoFlagComponent } from '../../../iconography/enzo-flag/enzo-flag.component';
import { EnzoTextComponent } from '../../../typography/enzo-text/enzo-text.component';
import { ELanguageCode } from '@caronsale/cos-models';
import { I18nService } from '@cos/services/i18n/i18n.service';
import { toSignal } from '@angular/core/rxjs-interop';
import { EnzoLoaderComponent } from '../../../miscellaneous/enzo-loader/enzo-loader.component';

@Component({
  selector: 'enzo-language-select',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './enzo-language-select.component.html',
  styleUrl: './enzo-language-select.component.scss',
  standalone: true,
  imports: [EnzoFlagComponent, EnzoTextComponent, EnzoLoaderComponent],
  host: {
    '[class.is-open]': 'isLanguageSelectionOpen()',
    '(focusout)': 'onFocusout($event)',
  },
})
export class EnzoLanguageSelectComponent {
  private i18nService = inject(I18nService);

  public supportedLanguages = toSignal(this.i18nService.availableLanguages$, { initialValue: [] });
  public currentLanguage = toSignal(this.i18nService.languageChanged$);
  public countryOfCurrentLanguage = computed(() => I18nService.getCountryCodeForLanguage(this.currentLanguage()));

  public isLanguageSelectionOpen = signal<boolean>(false);
  public loading = signal<boolean>(false);
  public getCountryCodeForLanguage = I18nService.getCountryCodeForLanguage;

  private hostEl = inject(ElementRef).nativeElement;

  public selectLanguage(newLanguage: ELanguageCode) {
    this.loading.set(true);
    this.close();
    this.i18nService.selectLanguage(newLanguage).subscribe(() => this.loading.set(false));
  }

  public toggleOpen() {
    this.isLanguageSelectionOpen.update(isOpen => !isOpen);
  }

  public onFocusout(event: FocusEvent) {
    if (!this.hostEl.contains(event.relatedTarget as HTMLElement)) {
      this.close();
    }
  }

  private close() {
    this.isLanguageSelectionOpen.set(false);
  }
}
