import { AfterViewInit, booleanAttribute, Directive, effect, ElementRef, inject, Injector, input, signal } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormControl, NgControl, ValidationErrors } from '@angular/forms';
import { toSignal } from '@angular/core/rxjs-interop';
import { from, Observable, take } from 'rxjs';
import { isPromise } from 'rxjs/internal/util/isPromise';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'enzo-textfield[showRequired], enzo-select[showRequired]',
  standalone: true,
})
export class ShowRequiredDirective implements AfterViewInit {
  public showRequired = input(true, { transform: booleanAttribute });
  public required = input(false, { transform: booleanAttribute });

  private el = inject(ElementRef);
  private translateService = inject(TranslateService, { optional: true }); // in unit tests, it might be undefined
  private injector = inject(Injector);
  private langChangeSignal = toSignal(this.translateService?.onLangChange || new Observable());

  public constructor() {
    effect(() => {
      this.langChangeSignal();
      if (this.required() || this.initialized()) {
        if (this.showRequired()) {
          this.el.nativeElement.hintText = this.translateService.instant(
            this.required() || this.hasRequiredValidator ? 'general.required' : 'general.optional',
          );
        } else {
          this.el.nativeElement.hintText = '';
        }
      }
    });
  }

  private hasRequiredValidator: boolean = false;
  private initialized = signal<boolean>(false);
  private dummyEmptyFormControl = new FormControl(null);

  public ngAfterViewInit() {
    const ngControl: NgControl | null = this.injector.get(NgControl, null, { optional: true });
    Promise.resolve().then(() => {
      if (ngControl?.control?.validator) {
        this.hasRequiredValidator = !!ngControl!.control!.validator(this.dummyEmptyFormControl)?.['required'];
      }
      if (ngControl?.control?.asyncValidator) {
        const result = ngControl!.control!.asyncValidator(this.dummyEmptyFormControl);
        const observable = isPromise(result) ? from(result) : result;
        observable.pipe(take(1)).subscribe((errors: ValidationErrors | null) => {
          this.hasRequiredValidator ||= !!errors?.['required'];
          this.initialized.set(true);
        });
        return;
      }
      this.initialized.set(true);
    });
  }
}
