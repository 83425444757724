import { EAuctionBelowMinAskBehaviour, IAuction, IRenegotiationRoundData } from '@caronsale/cos-models';

/* eslint-disable no-fallthrough */
export type ButtonNames = 'Accept' | 'AgreeOnMidpoint' | 'Offer' | 'Withdraw';

export enum EPriceSource {
  NONE, // buyer side only, for test cars, since they lack a reasonable offer from the seller side
  MINIMUM_ASK,
  HIGHEST_BID_AT_AUCTION_END,
  LAST_OFFER_BY_SELLER,
  COUNTER_OFFER_BY_BUYER,
  MIDPOINT,
  CURRENT_HIGHEST_BID_BY_BUYER,
}

// general state, covers both sides
export enum ERenegotiationState { // start at 1, to be able to use the ngIf-hack to assign the state to a new variable in the template
  INITIAL = 1, // show minimumRequiredAsk / currentHighestBidValue
  INITIAL_BUYER_SENT_AN_OFFER_WAITING_FOR_SELLER, // Buyer cannot withdraw, show minimumRequiredAsk / counterOfferByBuyer
  INITIAL_BUYER_HAS_WITHDRAWN_WAITING_FOR_SELLER, // same as "INITIAL", different status wording, show minimumRequiredAsk
  INITIAL_SELLER_SENT_AN_OFFER_WAITING_FOR_BUYER, // same as "INITIAL"
  INITIAL_SELLER_HAS_WITHDRAWN_WAITING_FOR_BUYER, // same as "INITIAL", different status wording, show minimumRequiredAsk / counterOfferByBuyer
  INITIAL_BUYER_HAS_WITHDRAWN, // same as "INITIAL", different status wording, show minimumRequiredAsk / counterOfferByBuyer
  INITIAL_SELLER_HAS_WITHDRAWN, // same as "INITIAL", different status wording, show minimumRequiredAsk / counterOfferByBuyer
  INITIAL_BOTH_HAVE_WITHDRAWN, // same as "INITIAL", different status wording, show minimumRequiredAsk / counterOfferByBuyer
  NEW_ROUND, // show lastOfferBySeller / counterOfferByBuyer
  NEW_ROUND_BUYER_SENT_AN_OFFER_WAITING_FOR_SELLER, // same as "NEW_ROUND", except Buyer cannot withdraw
  NEW_ROUND_BUYER_HAS_WITHDRAWN_WAITING_FOR_SELLER, // only accept (in case he regrets), show counterOfferByBuyer as still valid,
  NEW_ROUND_SELLER_SENT_AN_OFFER_WAITING_FOR_BUYER, // same as "NEW_ROUND"
  NEW_ROUND_SELLER_HAS_WITHDRAWN_WAITING_FOR_BUYER, // same as "NEW_ROUND"
  NEW_ROUND_BUYER_HAS_WITHDRAWN, // same values as "BUYER_HAS_WITHDRAWN_WAITING_FOR_SELLER", different status wording
  NEW_ROUND_SELLER_HAS_WITHDRAWN, // no more counterOffer or withdrawal possible, only accept  lastOfferBySeller
  NEW_ROUND_BOTH_HAVE_WITHDRAWN, // only accept (in case he regrets), show counterOfferByBuyer as still valid,
  TOO_CLOSE, // no more counterOffers, only midpoint accept or withdraw
  TOO_CLOSE_BUYER_ACCEPTED_MIDPOINT_WAITING_FOR_SELLER, // nothing possible on buyer side
  TOO_CLOSE_BUYER_HAS_WITHDRAWN_WAITING_FOR_SELLER, // only accept (in case he regrets)
  TOO_CLOSE_SELLER_ACCEPTED_MIDPOINT_WAITING_FOR_BUYER, // same as "TOO_CLOSE"
  TOO_CLOSE_SELLER_HAS_WITHDRAWN_WAITING_FOR_BUYER, // same as "TOO_CLOSE"
  TOO_CLOSE_BUYER_HAS_WITHDRAWN_SELLER_ACCEPTED_MIDPOINT, // only accept (in case he regrets)
  TOO_CLOSE_SELLER_HAS_WITHDRAWN_BUYER_ACCEPTED_MIDPOINT, // buyer can accept the lastOfferBySeller
  TOO_CLOSE_BOTH_HAVE_WITHDRAWN, // buyer can accept the lastOfferBySeller
  INITIAL_TEST_CAR, // Show a "please wait" message, since an account manager must put in a min-ask price
  INITIAL_WAITING_FOR_MANUAL_ACTION, // Show a "please wait" message, since an account manager will do the renegotiation manually
}

export enum EVisualRenegotiationStateBuyer {
  TEST = 0,
  INITIAL,
  OFFER_SENT_BY_BUYER,
  OFFER_SENT_BY_SELLER,
  WITHDRAWN_BUYER,
}

export enum EVisualRenegotiationStateSeller {
  TEST = 0,
  INITIAL,
  OFFER_SENT_BY_SELLER,
  OFFER_SENT_BY_BUYER,
  WITHDRAWN_SELLER,
}

export interface ICounterOfferFormParams {
  min: number;
  max: number;
  startWith: number;
  step: number;
  lastOffer: number;
  counterOfferConfirmBaseKey: string;
  auctionUuid: string;
}

export interface IRenegotiationData {
  readonly round: number;
  readonly totalRounds: number;
  readonly currentPrice: number; // the price that this party would accept, if he pressed the "accept" button
  readonly currentPriceNet: number; // the net price (without VAT) that this party would accept, if he pressed the "accept" button
  readonly priceSource: EPriceSource; // used to determine the backend calls for the "accept" and "withdraw" buttons
  readonly isWaitingForTheOtherSide: boolean;
  readonly statusHeadlineKey: string;
  readonly statusHintKey: string;
  readonly offerLine1Key: string;
  readonly offerLine2Key: string;
  readonly hasAcceptButton: boolean;
  readonly hasAgreeOnMidpointButton: boolean;
  readonly hasCounterOfferButton: boolean;
  readonly hasWithdrawButton: boolean;
  readonly counterOfferFormParams: ICounterOfferFormParams;
}

// map of base keys. Append ".buyer" or ".seller" to get the keys for the individual views
const renegotiationStateTranslateKeyMap: Record<ERenegotiationState, string> = {
  [ERenegotiationState.INITIAL]: 'auction.renegotiation.initial',
  [ERenegotiationState.INITIAL_BUYER_SENT_AN_OFFER_WAITING_FOR_SELLER]: 'auction.renegotiation.initial-buyer-sent-an-offer-waiting-for-seller',
  [ERenegotiationState.INITIAL_BUYER_HAS_WITHDRAWN_WAITING_FOR_SELLER]: 'auction.renegotiation.initial-buyer-has-withdrawn-waiting-for-seller',
  [ERenegotiationState.INITIAL_SELLER_SENT_AN_OFFER_WAITING_FOR_BUYER]: 'auction.renegotiation.initial-seller-sent-an-offer-waiting-for-buyer',
  [ERenegotiationState.INITIAL_SELLER_HAS_WITHDRAWN_WAITING_FOR_BUYER]: 'auction.renegotiation.initial-seller-has-withdrawn-waiting-for-buyer',
  [ERenegotiationState.INITIAL_BUYER_HAS_WITHDRAWN]: 'auction.renegotiation.initial-buyer-has-withdrawn',
  [ERenegotiationState.INITIAL_SELLER_HAS_WITHDRAWN]: 'auction.renegotiation.initial-seller-has-withdrawn',
  [ERenegotiationState.INITIAL_BOTH_HAVE_WITHDRAWN]: 'auction.renegotiation.initial-both-have-withdrawn',
  [ERenegotiationState.NEW_ROUND]: 'auction.renegotiation.new-round',
  [ERenegotiationState.NEW_ROUND_BUYER_SENT_AN_OFFER_WAITING_FOR_SELLER]: 'auction.renegotiation.new-round-buyer-sent-an-offer-waiting-for-seller',
  [ERenegotiationState.NEW_ROUND_BUYER_HAS_WITHDRAWN_WAITING_FOR_SELLER]: 'auction.renegotiation.new-round-buyer-has-withdrawn-waiting-for-seller',
  [ERenegotiationState.NEW_ROUND_SELLER_SENT_AN_OFFER_WAITING_FOR_BUYER]: 'auction.renegotiation.new-round-seller-sent-an-offer-waiting-for-buyer',
  [ERenegotiationState.NEW_ROUND_SELLER_HAS_WITHDRAWN_WAITING_FOR_BUYER]: 'auction.renegotiation.new-round-seller-has-withdrawn-waiting-for-buyer',
  [ERenegotiationState.NEW_ROUND_BUYER_HAS_WITHDRAWN]: 'auction.renegotiation.new-round-buyer-has-withdrawn',
  [ERenegotiationState.NEW_ROUND_SELLER_HAS_WITHDRAWN]: 'auction.renegotiation.new-round-seller-has-withdrawn',
  [ERenegotiationState.NEW_ROUND_BOTH_HAVE_WITHDRAWN]: 'auction.renegotiation.new-round-both-have-withdrawn',
  [ERenegotiationState.TOO_CLOSE]: 'auction.renegotiation.too-close',
  [ERenegotiationState.TOO_CLOSE_BUYER_ACCEPTED_MIDPOINT_WAITING_FOR_SELLER]: 'auction.renegotiation.too-close-buyer-accepted-midpoint-waiting-for-seller',
  [ERenegotiationState.TOO_CLOSE_BUYER_HAS_WITHDRAWN_WAITING_FOR_SELLER]: 'auction.renegotiation.too-close-buyer-has-withdrawn-waiting-for-seller',
  [ERenegotiationState.TOO_CLOSE_SELLER_ACCEPTED_MIDPOINT_WAITING_FOR_BUYER]: 'auction.renegotiation.too-close-seller-accepted-midpoint-waiting-for-buyer',
  [ERenegotiationState.TOO_CLOSE_SELLER_HAS_WITHDRAWN_WAITING_FOR_BUYER]: 'auction.renegotiation.too-close-seller-has-withdrawn-waiting-for-buyer',
  [ERenegotiationState.TOO_CLOSE_BUYER_HAS_WITHDRAWN_SELLER_ACCEPTED_MIDPOINT]: 'auction.renegotiation.too-close-buyer-has-withdrawn-seller-accepted-midpoint',
  [ERenegotiationState.TOO_CLOSE_SELLER_HAS_WITHDRAWN_BUYER_ACCEPTED_MIDPOINT]: 'auction.renegotiation.too-close-seller-has-withdrawn-buyer-accepted-midpoint',
  [ERenegotiationState.TOO_CLOSE_BOTH_HAVE_WITHDRAWN]: 'auction.renegotiation.too-close-both-have-withdrawn',
  [ERenegotiationState.INITIAL_TEST_CAR]: 'auction.renegotiation.initial-test-car',
  [ERenegotiationState.INITIAL_WAITING_FOR_MANUAL_ACTION]: 'auction.renegotiation.initial-waiting-for-manual-action',
};

export abstract class RenegotiationData {
  protected readonly renegotiationState: ERenegotiationState;

  protected constructor(protected readonly auction: IAuction) {
    this.renegotiationState = this.getRenegotiationState();
  }

  private getRenegotiationState(): ERenegotiationState {
    if (this.auction.isTest) {
      return ERenegotiationState.INITIAL_TEST_CAR;
    }
    if (this.auction.belowMinAskBehaviour === EAuctionBelowMinAskBehaviour.WAIT_FOR_MANUAL_ACTION) {
      return ERenegotiationState.INITIAL_WAITING_FOR_MANUAL_ACTION;
    }
    if (this.auction.renegotiationStoppedByBuyer) {
      if (this.auction.renegotiationStoppedBySeller) {
        if (this.auction.renegotiationMidpointValue) {
          return ERenegotiationState.TOO_CLOSE_BOTH_HAVE_WITHDRAWN;
        }
        if (!this.auction.lastOfferBySeller && !this.auction.counterOfferByBuyer) {
          return ERenegotiationState.INITIAL_BOTH_HAVE_WITHDRAWN;
        }
        if (this.auction.lastOfferBySeller && this.auction.counterOfferByBuyer) {
          return ERenegotiationState.NEW_ROUND_BOTH_HAVE_WITHDRAWN;
        }
        // if both sides were stopped by timeout or withdrawl, the crm could still patch the offer values
        // then these offers override the withdrawl (since we want to show the values in the seller and buyer UI)
        if (this.auction.lastOfferBySeller) {
          // treat it as if seller made an offer / only the buyer has withdrawn
          return ERenegotiationState.INITIAL_BUYER_HAS_WITHDRAWN;
        }
        if (this.auction.counterOfferByBuyer) {
          // treat it as if buyer made an offer / only the seller has withdrawn
          return ERenegotiationState.INITIAL_SELLER_HAS_WITHDRAWN;
        }
      }
      if (!this.auction.counterOfferByBuyer) {
        // buyer stopped in INITIAL (there cannot be a midpoint), seller did not stop
        if (!this.auction.lastOfferBySeller) {
          return ERenegotiationState.INITIAL_BUYER_HAS_WITHDRAWN_WAITING_FOR_SELLER;
        }
        return ERenegotiationState.INITIAL_BUYER_HAS_WITHDRAWN;
      }
      if (this.auction.renegotiationMidpointValue) {
        // buyer stopped in TOO_CLOSE, seller did not stop
        if (this.auction.sellerAcceptedRenegotiationMidpoint) {
          return ERenegotiationState.TOO_CLOSE_BUYER_HAS_WITHDRAWN_SELLER_ACCEPTED_MIDPOINT;
        }
        // There is no other possible state. Seller can only stop or accept a midpoint, and if neither is indicated, we are still waiting.
        return ERenegotiationState.TOO_CLOSE_BUYER_HAS_WITHDRAWN_WAITING_FOR_SELLER;
      }
      if (this.auction.numBuyerRenegotiationOfferRounds === this.auction.numSellerRenegotiationOfferRounds) {
        return ERenegotiationState.NEW_ROUND_BUYER_HAS_WITHDRAWN_WAITING_FOR_SELLER;
      }
      return ERenegotiationState.NEW_ROUND_BUYER_HAS_WITHDRAWN;
    }

    if (this.auction.renegotiationStoppedBySeller) {
      // but not by buyer
      if (!this.auction.lastOfferBySeller) {
        // seller stopped in INITIAL (there cannot be a midpoint), buyer did not stop
        if (!this.auction.counterOfferByBuyer) {
          return ERenegotiationState.INITIAL_SELLER_HAS_WITHDRAWN_WAITING_FOR_BUYER;
        }
        return ERenegotiationState.INITIAL_SELLER_HAS_WITHDRAWN;
      }
      if (this.auction.renegotiationMidpointValue) {
        // seller stopped in TOO_CLOSE, buyer did not stop
        if (this.auction.buyerAcceptedRenegotiationMidpoint) {
          return ERenegotiationState.TOO_CLOSE_SELLER_HAS_WITHDRAWN_BUYER_ACCEPTED_MIDPOINT;
        }
        // There is no other possible state. Buyer can only stop or accept a midpoint, and if neither is indicated, we are still waiting.
        return ERenegotiationState.TOO_CLOSE_SELLER_HAS_WITHDRAWN_WAITING_FOR_BUYER;
      }
      if (this.auction.numBuyerRenegotiationOfferRounds === this.auction.numSellerRenegotiationOfferRounds) {
        return ERenegotiationState.NEW_ROUND_SELLER_HAS_WITHDRAWN_WAITING_FOR_BUYER;
      }
      return ERenegotiationState.NEW_ROUND_SELLER_HAS_WITHDRAWN;
    }

    if (this.auction.renegotiationMidpointValue) {
      // no one has stopped, they can only stop or accept
      if (this.auction.buyerAcceptedRenegotiationMidpoint) {
        return ERenegotiationState.TOO_CLOSE_BUYER_ACCEPTED_MIDPOINT_WAITING_FOR_SELLER;
      }
      if (this.auction.sellerAcceptedRenegotiationMidpoint) {
        return ERenegotiationState.TOO_CLOSE_SELLER_ACCEPTED_MIDPOINT_WAITING_FOR_BUYER;
      }
      return ERenegotiationState.TOO_CLOSE;
    }

    if (!this.auction.lastOfferBySeller && !this.auction.counterOfferByBuyer) {
      // both are null, undefined or 0, so we did not start negotiating yet
      return ERenegotiationState.INITIAL;
    }
    if (!this.auction.lastOfferBySeller && this.auction.counterOfferByBuyer) {
      return ERenegotiationState.INITIAL_BUYER_SENT_AN_OFFER_WAITING_FOR_SELLER;
    }
    if (this.auction.lastOfferBySeller && !this.auction.counterOfferByBuyer) {
      return ERenegotiationState.INITIAL_SELLER_SENT_AN_OFFER_WAITING_FOR_BUYER;
    }

    // check if anybody sent an offer
    if (this.auction.numBuyerRenegotiationOfferRounds > this.auction.numSellerRenegotiationOfferRounds) {
      return ERenegotiationState.NEW_ROUND_BUYER_SENT_AN_OFFER_WAITING_FOR_SELLER;
    }
    if (this.auction.numSellerRenegotiationOfferRounds > this.auction.numBuyerRenegotiationOfferRounds) {
      return ERenegotiationState.NEW_ROUND_SELLER_SENT_AN_OFFER_WAITING_FOR_BUYER;
    }

    return ERenegotiationState.NEW_ROUND;
  }

  protected getRenegotiationStateTranslateKey(): string {
    return renegotiationStateTranslateKeyMap[this.renegotiationState];
  }

  protected getCurrentPrices(priceSource: EPriceSource): [number, number] {
    switch (priceSource) {
      case EPriceSource.NONE:
        return [this.auction.currentHighestBidValue, this.auction.currentHighestBidValueNet];

      case EPriceSource.LAST_OFFER_BY_SELLER:
        if (this.auction.lastOfferBySeller) {
          return [this.auction.lastOfferBySeller, this.auction.lastOfferBySellerNet];
        }
      // else: fall through
      // see RO-1159: use minAsk in case our state detection logic is fooled by CRM actions

      case EPriceSource.MINIMUM_ASK:
        return [this.auction.minimumRequiredAsk, this.auction.minimumRequiredAskNet];

      case EPriceSource.COUNTER_OFFER_BY_BUYER:
        if (this.auction.counterOfferByBuyer) {
          return [this.auction.counterOfferByBuyer, this.auction.counterOfferByBuyerNet];
        }
      // else: fall through
      // scenario from RO-1159, mirrored: use highest bid in case our state detection logic is fooled by CRM actions
      case EPriceSource.CURRENT_HIGHEST_BID_BY_BUYER:
        return [this.auction.currentHighestBidValue, this.auction.currentHighestBidValueNet];

      case EPriceSource.HIGHEST_BID_AT_AUCTION_END:
        return [this.auction.highestBidValueAtEndingTime, this.auction.highestBidValueAtEndingTimeNet];

      case EPriceSource.MIDPOINT:
        return [this.auction.renegotiationMidpointValue, this.auction.renegotiationMidpointValueNet];
    }
  }

  protected getRenegotiationRoundData(): Required<IRenegotiationRoundData> {
    if (
      this.renegotiationState === ERenegotiationState.INITIAL_TEST_CAR ||
      this.renegotiationState === ERenegotiationState.INITIAL_WAITING_FOR_MANUAL_ACTION ||
      !this.auction.renegotiationRoundData
    ) {
      return {
        totalRounds: 0,
        buyer: {
          currentRound: 0,
          maxOffer: 0,
          minOffer: 0,
        },
        seller: {
          currentRound: 0,
          maxOffer: 0,
          minOffer: 0,
        },
      };
    }

    return {
      totalRounds: this.auction.renegotiationRoundData.totalRounds || 0,
      buyer: {
        currentRound: this.auction.renegotiationRoundData.buyer?.currentRound || 0,
        minOffer: Math.ceil(this.auction.renegotiationRoundData.buyer?.minOffer) || 0,
        maxOffer: Math.floor(this.auction.renegotiationRoundData.buyer?.maxOffer) || 0,
      },
      seller: {
        currentRound: this.auction.renegotiationRoundData.seller?.currentRound || 0,
        minOffer: Math.ceil(this.auction.renegotiationRoundData.seller?.minOffer) || 0,
        maxOffer: Math.floor(this.auction.renegotiationRoundData.seller?.maxOffer) || 0,
      },
    };
  }

  // generate a string that is guaranteed to change if the auction data (the part relevant for our ui) changes
  public getFingerprintOfState(): string {
    // the state and any value that contains a price that might change. In most case, the state is enough.
    // But we might change from NEW_ROUND to NEW_ROUND with a different set of offer prices
    return `${this.renegotiationState}/${this.auction.lastOfferBySeller}/${this.auction.counterOfferByBuyer}`;
  }

  // when we detected a state change (renegotiationState is the new state): do we need to alert the user?
  // the answer is always "yes" if we reveal some state change or price change from the other side.
  public isAlertRequired(): boolean {
    switch (this.renegotiationState) {
      case ERenegotiationState.INITIAL:
      case ERenegotiationState.INITIAL_BUYER_SENT_AN_OFFER_WAITING_FOR_SELLER:
      case ERenegotiationState.INITIAL_BUYER_HAS_WITHDRAWN_WAITING_FOR_SELLER:
      case ERenegotiationState.INITIAL_SELLER_SENT_AN_OFFER_WAITING_FOR_BUYER:
      case ERenegotiationState.INITIAL_SELLER_HAS_WITHDRAWN_WAITING_FOR_BUYER:
      case ERenegotiationState.INITIAL_TEST_CAR:
      case ERenegotiationState.INITIAL_WAITING_FOR_MANUAL_ACTION:
        return false;

      case ERenegotiationState.INITIAL_BUYER_HAS_WITHDRAWN:
      case ERenegotiationState.INITIAL_SELLER_HAS_WITHDRAWN:
      case ERenegotiationState.INITIAL_BOTH_HAVE_WITHDRAWN:
      case ERenegotiationState.NEW_ROUND:
        return true;

      case ERenegotiationState.NEW_ROUND_BUYER_SENT_AN_OFFER_WAITING_FOR_SELLER:
      case ERenegotiationState.NEW_ROUND_BUYER_HAS_WITHDRAWN_WAITING_FOR_SELLER:
      case ERenegotiationState.NEW_ROUND_SELLER_SENT_AN_OFFER_WAITING_FOR_BUYER:
      case ERenegotiationState.NEW_ROUND_SELLER_HAS_WITHDRAWN_WAITING_FOR_BUYER:
        return false;

      case ERenegotiationState.NEW_ROUND_BUYER_HAS_WITHDRAWN:
      case ERenegotiationState.NEW_ROUND_SELLER_HAS_WITHDRAWN:
      case ERenegotiationState.NEW_ROUND_BOTH_HAVE_WITHDRAWN:
      case ERenegotiationState.TOO_CLOSE:
        return true;

      case ERenegotiationState.TOO_CLOSE_BUYER_ACCEPTED_MIDPOINT_WAITING_FOR_SELLER:
      case ERenegotiationState.TOO_CLOSE_BUYER_HAS_WITHDRAWN_WAITING_FOR_SELLER:
      case ERenegotiationState.TOO_CLOSE_SELLER_ACCEPTED_MIDPOINT_WAITING_FOR_BUYER:
      case ERenegotiationState.TOO_CLOSE_SELLER_HAS_WITHDRAWN_WAITING_FOR_BUYER:
        return false;

      case ERenegotiationState.TOO_CLOSE_BUYER_HAS_WITHDRAWN_SELLER_ACCEPTED_MIDPOINT:
      case ERenegotiationState.TOO_CLOSE_SELLER_HAS_WITHDRAWN_BUYER_ACCEPTED_MIDPOINT:
      case ERenegotiationState.TOO_CLOSE_BOTH_HAVE_WITHDRAWN:
        return true;
    }
  }

  public isWaitingForSeller(): boolean {
    switch (this.renegotiationState) {
      case ERenegotiationState.INITIAL_BUYER_SENT_AN_OFFER_WAITING_FOR_SELLER:
      case ERenegotiationState.INITIAL_BUYER_HAS_WITHDRAWN_WAITING_FOR_SELLER:
      case ERenegotiationState.NEW_ROUND_BUYER_SENT_AN_OFFER_WAITING_FOR_SELLER:
      case ERenegotiationState.NEW_ROUND_BUYER_HAS_WITHDRAWN_WAITING_FOR_SELLER:
      case ERenegotiationState.TOO_CLOSE_BUYER_ACCEPTED_MIDPOINT_WAITING_FOR_SELLER:
      case ERenegotiationState.TOO_CLOSE_BUYER_HAS_WITHDRAWN_WAITING_FOR_SELLER:
        return true;
      default:
        return false;
    }
  }

  public isWaitingForBuyer(): boolean {
    switch (this.renegotiationState) {
      case ERenegotiationState.INITIAL_SELLER_SENT_AN_OFFER_WAITING_FOR_BUYER:
      case ERenegotiationState.INITIAL_SELLER_HAS_WITHDRAWN_WAITING_FOR_BUYER:
      case ERenegotiationState.NEW_ROUND_SELLER_SENT_AN_OFFER_WAITING_FOR_BUYER:
      case ERenegotiationState.NEW_ROUND_SELLER_HAS_WITHDRAWN_WAITING_FOR_BUYER:
      case ERenegotiationState.TOO_CLOSE_SELLER_ACCEPTED_MIDPOINT_WAITING_FOR_BUYER:
      case ERenegotiationState.TOO_CLOSE_SELLER_HAS_WITHDRAWN_WAITING_FOR_BUYER:
        return true;
      default:
        return false;
    }
  }

  public isRenegotiationStillOngoing(): boolean {
    switch (this.renegotiationState) {
      case ERenegotiationState.INITIAL:
      case ERenegotiationState.NEW_ROUND:
      case ERenegotiationState.TOO_CLOSE:
      case ERenegotiationState.INITIAL_BUYER_SENT_AN_OFFER_WAITING_FOR_SELLER:
      case ERenegotiationState.INITIAL_SELLER_SENT_AN_OFFER_WAITING_FOR_BUYER:
      case ERenegotiationState.NEW_ROUND_BUYER_SENT_AN_OFFER_WAITING_FOR_SELLER:
      case ERenegotiationState.NEW_ROUND_SELLER_SENT_AN_OFFER_WAITING_FOR_BUYER:
      case ERenegotiationState.TOO_CLOSE_BUYER_ACCEPTED_MIDPOINT_WAITING_FOR_SELLER:
      case ERenegotiationState.TOO_CLOSE_SELLER_ACCEPTED_MIDPOINT_WAITING_FOR_BUYER:
        return true;

      case ERenegotiationState.INITIAL_TEST_CAR:
      case ERenegotiationState.INITIAL_WAITING_FOR_MANUAL_ACTION:
      case ERenegotiationState.INITIAL_BUYER_HAS_WITHDRAWN_WAITING_FOR_SELLER:
      case ERenegotiationState.INITIAL_SELLER_HAS_WITHDRAWN_WAITING_FOR_BUYER:
      case ERenegotiationState.INITIAL_BUYER_HAS_WITHDRAWN:
      case ERenegotiationState.INITIAL_BOTH_HAVE_WITHDRAWN:
      case ERenegotiationState.INITIAL_SELLER_HAS_WITHDRAWN:
      case ERenegotiationState.NEW_ROUND_BUYER_HAS_WITHDRAWN_WAITING_FOR_SELLER:
      case ERenegotiationState.NEW_ROUND_SELLER_HAS_WITHDRAWN_WAITING_FOR_BUYER:
      case ERenegotiationState.NEW_ROUND_BUYER_HAS_WITHDRAWN:
      case ERenegotiationState.NEW_ROUND_SELLER_HAS_WITHDRAWN:
      case ERenegotiationState.NEW_ROUND_BOTH_HAVE_WITHDRAWN:
      case ERenegotiationState.TOO_CLOSE_BUYER_HAS_WITHDRAWN_WAITING_FOR_SELLER:
      case ERenegotiationState.TOO_CLOSE_SELLER_HAS_WITHDRAWN_WAITING_FOR_BUYER:
      case ERenegotiationState.TOO_CLOSE_SELLER_HAS_WITHDRAWN_BUYER_ACCEPTED_MIDPOINT:
      case ERenegotiationState.TOO_CLOSE_BUYER_HAS_WITHDRAWN_SELLER_ACCEPTED_MIDPOINT:
      case ERenegotiationState.TOO_CLOSE_BOTH_HAVE_WITHDRAWN:
        return false;
    }
  }
}
