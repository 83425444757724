import { booleanAttribute, ChangeDetectionStrategy, Component, input } from '@angular/core';
import { Color } from '@caronsale/enzo';

export type EnzoHeadlineLevel = 1 | 2 | 3 | 4 | 5 | 6;

@Component({
  selector: 'enzo-headline',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: '<ng-content />',
  standalone: true,
  host: {
    '[color]': 'color()',
    '[level]': 'level()',
    '[align]': 'align()',
    '[balance]': 'balance()',
  },
})
export class EnzoHeadlineComponent {
  public color = input<Color | 'inherit'>('inherit');
  public level = input.required<EnzoHeadlineLevel, EnzoHeadlineLevel | '1' | '2' | '3' | '4' | '5' | '6' | '' | null | undefined>({
    transform: (value: any) => ([1, 2, 3, 4, 5, 6].includes(Number(value)) ? (Number(value) as 1 | 2 | 3 | 4 | 5 | 6) : 1),
  });
  public align = input<'left' | 'center' | 'right'>('left');
  public balance = input(true, { transform: booleanAttribute });
}
