<div *ngIf="showSelectedTransportOptionOnly; else showChoices" class="text-grey option" testid="noChoiceOption">
  <enzo-text kind="label">{{ 'prebooked-service.transport.standard-label' | translate }}</enzo-text>
  <enzo-text kind="label" align="right" class="price"> {{ transportPrice | currency: 'EUR' : 'symbol' }} </enzo-text>
</div>

<ng-template #showChoices>
  <form [formGroup]="transportForm" [class.disable-all]="isHotBidPhaseActive">
    <mat-radio-group color="primary" formControlName="transport">
      <div class="option">
        <mat-radio-button #selfPickupOption testid="selfPickupRadioButton" [value]="TRANSPORT_OPTION_SELF_PICKUP">
          <div class="radio-button-label">
            {{ 'prebooked-service.transport.self-pickup-label' | translate }}
            <enzo-icon
              *ngIf="selfPickupNotOfferedInfo"
              icon="info-outline"
              size="small"
              color="purple"
              popoverWidth="350px"
              [popoverText]="'prebooked-service.self-pickup-not-offered.transport-selected' | translate"
              testid="selfPickupNotOfferedIcon" />
            <enzo-icon
              *ngIf="isDutchToForeignAuction"
              icon="info-outline"
              size="small"
              color="purple"
              popoverWidth="400px"
              [popoverTemplate]="dutchToForeignAuctionPopover"
              testid="dutchToForeignInfoIcon" />
          </div>
          <enzo-text color="grey" kind="caption" testid="selfPickupIncreasedTimeMessage" *ngIf="showPickupTimeSubtext">
            {{ 'prebooked-service.transport.ready-for-collection-sub-text' | translate }}
          </enzo-text>
        </mat-radio-button>
        <span *ngIf="!selfPickupNotOfferedInfo && selfPickupAvailable" class="price" testid="selfPickupPrice">{{ 0 | currencyEuro }}</span>
      </div>
      <div class="option">
        <mat-radio-button #transportOption name="transportRadioButton" testid="transportRadioButton" [value]="TRANSPORT_OPTION_TRANSPORT">
          <div class="radio-button-label">
            {{ 'prebooked-service.transport.standard-label' | translate }}
            <enzo-icon
              *ngIf="transportNotOfferedInfoKey"
              icon="info-outline"
              size="small"
              [color]="transportAvailable ? 'purple' : 'red'"
              popoverWidth="350px"
              [popoverText]="'transport.not-available.not-allowed-for-region' | translate"
              testid="transportNotOfferedIcon" />
            <enzo-badge
              [label]="'prebooked-service.transport.estimated-delivery-in' | translate: estimatedDeliveryDays"
              color="purple-light"
              testid="estimatedDeliveryDateBadge" />
          </div>
        </mat-radio-button>
        <ng-container *ngIf="!transportNotOfferedInfoKey">
          <span *ngIf="transportPrice !== 0; else freeTransportPriceDisplay" class="price" testid="transportPrice">{{ transportPrice | currencyEuro }}</span>
        </ng-container>
      </div>
    </mat-radio-group>
  </form>
</ng-template>

<ng-template #dutchToForeignAuctionPopover>
  <enzo-text kind="emphasis-label">{{ 'auction.dutch-to-foreign.pickup-popover.title' | translate }}</enzo-text>
  <enzo-text kind="label">{{ 'auction.dutch-to-foreign.pickup-popover.content' | translate }}</enzo-text>
</ng-template>

<ng-template #freeTransportPriceDisplay>
  <span class="price" testid="transportPrice">{{ 'prebooked-service.transport.free-transportation' | translate }}</span>
</ng-template>
