import { IAppEnvironment } from '@cosCoreEnvironments/IAppEnvironment';
import { ELanguageCode } from '@caronsale/cos-models';
import { enumToArray } from '@cosUtils/general/type-script-utils';
import { TranslationUrlConfig } from '@cosCoreServices/i18n/i18n.service';

export const commonEnvironmentSettings: Pick<IAppEnvironment, 'phraseConfig' | 'translationUrls'> = {
  phraseConfig: {
    phraseProjectId: '0f536a58e5500bf2e87f3a0d5115d961',
    phraseAccessToken: 'b41bff11461079659104134c344bba376f226923177b5cb6cb78b9779429c421',
    phraseEndpointUrl: 'https://api.phrase.com/v2/projects/',
    locallyStoredDefaultLanguage: ELanguageCode.DE,
    languageFallback: ELanguageCode.EN,
    hiddenLanguagesPhraseVariableName: 'hiddenLanguages',
  },

  translationUrls: enumToArray<ELanguageCode>(ELanguageCode)
    .filter(langCode => langCode !== ELanguageCode.DE)
    .reduce(
      (urls, langCode) => ({ ...urls, [langCode]: `https://res.cloudinary.com/castle-tech-gmbh/raw/upload/i18n/frontend-web/${langCode}.json` }),
      {} as TranslationUrlConfig,
    ),
};
