import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyEuroPipe } from '@caronsale/frontend-pipes';

// angular currency pipe doesn't support only showing decimals when they are present
// this pipe is a workaround to show the currency with 2 decimals only when they are present
// and without decimals when the value is an integer
@Pipe({
  standalone: true,
  name: 'currencyEuroWithDecimals',
})
export class CurrencyEuroWithDecimalsPipe extends CurrencyEuroPipe implements PipeTransform {
  public constructor(protected pipe: CurrencyPipe) {
    super(pipe);
  }
  public transform(value: number): string {
    if (Number.isInteger(+value)) {
      return super.transform(value);
    }

    return this.pipe.transform(value, 'EUR', 'symbol', '1.2-2');
  }
}
