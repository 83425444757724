<mat-dialog-content>
  <div class="header">
    <div [innerHTML]="'auction.guarantee.advertise-dialog.auction-label.message' | translate: { messageTemplate: translateTemplates.auctionLabel }"></div>
    <img [src]="data.vehicleImage | urlThumbnail: 150" />
  </div>

  <div class="details">
    <div class="details-icon">
      <fa-icon icon="exclamation" />
    </div>
    <div class="details-text">{{ 'auction.guarantee.advertise-dialog.inspection-details' | translate }}</div>
  </div>

  <mat-divider />

  <div class="without-guarantee">
    <div class="without-guarantee-badge">
      <app-guarantee-badge iconPath="/assets/logo/cos-guarantee/yellow-shield-icon.svg" size="md" />
      <fa-icon icon="ban" />
    </div>
    <div>{{ 'auction.guarantee.advertise-dialog.without-cos-check-plus' | translate }}:</div>
  </div>

  <div class="without-guarantee-description">
    <div class="without-guarantee-images">
      <img src="/assets/images/control-panel.png" />
      <img src="/assets/images/manual-transmission.png" />
    </div>

    <div class="without-guarantee-texts">
      <div class="without-guarantee-compensation">
        <fa-icon icon="ban" />
        <div>{{ 'auction.guarantee.advertise-dialog.no-compensation' | translate }}</div>
      </div>

      <div class="without-guarantee-responsibility">{{ 'auction.guarantee.advertise-dialog.no-responsibility' | translate }}</div>
    </div>
  </div>

  <mat-divider />

  <div class="with-guarantee">
    <app-guarantee-badge iconPath="/assets/logo/cos-guarantee/yellow-shield-icon.svg" size="md" />
    <div>{{ 'auction.guarantee.advertise-dialog.with-cos-check-plus' | translate }}:</div>

    <app-guarantee-badge class="with-guarantee-badge" labelPath="/assets/logo/cos-guarantee/cos-check-plus.svg" />
  </div>

  <div class="with-guarantee-coverage">
    <fa-icon icon="check-circle" />
    <div>{{ 'auction.guarantee.advertise-dialog.covers-damage' | translate }}</div>
  </div>

  <div class="with-guarantee-coverage">
    <fa-icon icon="check-circle" />
    <div>{{ 'auction.guarantee.advertise-dialog.reimbursement' | translate }}</div>
  </div>

  <div class="with-guarantee-coverage">
    <fa-icon icon="check-circle" />
    <div>{{ 'auction.guarantee.advertise-dialog.drive-up' | translate }}</div>
  </div>

  <div class="with-guarantee-coverage">
    <fa-icon icon="check-circle" />
    <div>{{ 'auction.guarantee.advertise-dialog.condition-checked' | translate }}</div>
  </div>

  <div
    class="with-guarantee-offer"
    [innerHTML]="'auction.guarantee.advertise-dialog.limited-time-offer.message' | translate: { messageTemplate: translateTemplates.discount }"></div>

  <div
    class="with-guarantee-disclaimer"
    [innerHTML]="'auction.guarantee.advertise-dialog.disclaimer.message' | translate: { messageTemplate: translateTemplates.disclaimer }"></div>
</mat-dialog-content>

<mat-dialog-actions *ngIf="data.displayButtons">
  <button mat-stroked-button (click)="onCancel()">{{ 'auction.guarantee.advertise-dialog.proceed-without-it' | translate }}</button>
  <button mat-flat-button color="primary" (click)="onConfirm()">{{ 'auction.guarantee.book-guarantee' | translate }}</button>
</mat-dialog-actions>
