import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, TemplateRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { startWith, takeUntil, Subject } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';

import {
  EPrebookedServiceGuaranteeOptionType,
  EPrebookedServiceType,
  IBuyerAuctionView,
  IPrebookedService,
  IPrebookedServiceGuaranteeOption,
} from '@caronsale/cos-models';
import { PrebookedServiceGuaranteeOptionType } from '@caronsale/cos-models/dist/Auction/IPrebookedService';
import { CurrencyEuroPipe } from '@caronsale/frontend-pipes';
import { COS_CONSTANTS } from '@cosCoreServices/constants/cos-constants.service';
import { PrebookedServicesUtils } from '@cosUtils/business';

import { BuyerAuctionService } from '@cosCoreFeatures/auction-detail/common/auction-service/buyer-auction.service';
import { Popover } from '@cosCoreFeatures/@cos/cos-popover/services/popover.service';
import {
  EXTENDED_GUARANTEE_COVERAGE_IN_EUROS,
  EXTENDED_GUARANTEE_PERIOD_IN_DAYS,
  PrebookedServicesService,
  STANDARD_GUARANTEE_COVERAGE_IN_EUROS,
  STANDARD_GUARANTEE_PERIOD_IN_DAYS,
} from '@cosCoreServices/prebooked-services/prebooked-services.service';

const GUARANTEE_OPTION_STANDARD = EPrebookedServiceGuaranteeOptionType.STANDARD;
export const GUARANTEE_OPTION_COS_CHECK_PLUS = EPrebookedServiceGuaranteeOptionType.COS_CHECK_PLUS;
export const GUARANTEE_OPTION_EXTENDED = EPrebookedServiceGuaranteeOptionType.EXTENDED;

export type PrebookedGuaranteeOptionValues = {
  [key in PrebookedServiceGuaranteeOptionType]: boolean;
};

export interface IGuaranteeSelectionChange {
  guaranteeOptionValues: PrebookedGuaranteeOptionValues;
  guaranteeOptionsRequiringAcceptance: PrebookedServiceGuaranteeOptionType[];
}

@Component({
  selector: 'app-prebooked-guarantee',
  templateUrl: './prebooked-guarantee.component.html',
  styleUrls: ['./prebooked-guarantee.component.scss'],
})
export class PrebookedGuaranteeComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  public auction: IBuyerAuctionView;

  @Input()
  public currentPrebookedServices: IPrebookedService[];

  @Output()
  public guaranteeSelectionChange: EventEmitter<IGuaranteeSelectionChange> = new EventEmitter<IGuaranteeSelectionChange>();

  // export constants to be used in template
  public readonly GUARANTEE_OPTION_COS_CHECK_PLUS = GUARANTEE_OPTION_COS_CHECK_PLUS;
  public readonly GUARANTEE_OPTION_EXTENDED = GUARANTEE_OPTION_EXTENDED;
  public readonly TRANSLATE_PARAMS;

  public standardOption: IPrebookedServiceGuaranteeOption;
  public cosCheckPlusOption: IPrebookedServiceGuaranteeOption;
  public extendedOption: IPrebookedServiceGuaranteeOption;
  public guaranteeForm: UntypedFormGroup;
  public isHotBidPhaseActive = false;
  public conditionsUrlTemplate: unknown;

  private unsubscribe$ = new Subject<void>();

  public constructor(
    private translateService: TranslateService,
    private formBuilder: UntypedFormBuilder,
    private popover: Popover,
    private prebookedServicesService: PrebookedServicesService,
    private buyerAuctionService: BuyerAuctionService,
    private constants: COS_CONSTANTS,
    currencyEuroPipe: CurrencyEuroPipe,
  ) {
    this.TRANSLATE_PARAMS = {
      conditionsLink: this.constants.GUARANTEE_CONDITIONS_URL,
      standardGuaranteePeriodInDays: STANDARD_GUARANTEE_PERIOD_IN_DAYS,
      extendedGuaranteePeriodInDays: EXTENDED_GUARANTEE_PERIOD_IN_DAYS,
      standardGuaranteeCoverageInEuros: currencyEuroPipe.transform(STANDARD_GUARANTEE_COVERAGE_IN_EUROS),
      extendedGuaranteeCoverageInEuros: currencyEuroPipe.transform(EXTENDED_GUARANTEE_COVERAGE_IN_EUROS),
    };
  }

  public ngOnInit(): void {
    this.translateService
      .get('auction.guarantee.conditions.message', {
        messageTemplate: `<a target="_blank" href="${this.constants.GUARANTEE_CONDITIONS_URL}"><u>{label}</u></a>`,
      })
      .subscribe(value => {
        this.conditionsUrlTemplate = value;
      });

    this.guaranteeForm = this.formBuilder.group({
      [GUARANTEE_OPTION_COS_CHECK_PLUS]: {
        value: !!this.cosCheckPlusOption?.isSelected,
        disabled: !this.cosCheckPlusOption?.isEnabled,
      },
      [GUARANTEE_OPTION_EXTENDED]: {
        value: !!this.extendedOption?.isSelected,
        disabled: !this.extendedOption?.isEnabled,
      },
    });

    this.guaranteeForm.valueChanges
      .pipe(
        startWith({
          [GUARANTEE_OPTION_COS_CHECK_PLUS]: !!this.cosCheckPlusOption?.isSelected,
          [GUARANTEE_OPTION_EXTENDED]: !!this.extendedOption?.isSelected,
        }),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((newGuaranteeOptionValues: PrebookedGuaranteeOptionValues) => {
        this.guaranteeSelectionChange.emit({
          guaranteeOptionValues: newGuaranteeOptionValues,
          guaranteeOptionsRequiringAcceptance: this.getRequiredGuaranteeOptionsNotAccepted(
            newGuaranteeOptionValues[GUARANTEE_OPTION_COS_CHECK_PLUS],
            newGuaranteeOptionValues[GUARANTEE_OPTION_EXTENDED],
          ),
        });
      });
  }

  public ngOnChanges(): void {
    this.extractGuaranteeOptionsFromPrebookedServices(this.currentPrebookedServices);
    this.isHotBidPhaseActive = this.buyerAuctionService.isHotBidPhaseActive(this.auction);
    if (this.guaranteeForm && this.cosCheckPlusOption) {
      this.guaranteeForm.controls[GUARANTEE_OPTION_COS_CHECK_PLUS].reset(
        {
          value: this.cosCheckPlusOption.isSelected,
          disabled: !this.cosCheckPlusOption.isEnabled,
        },
        { emitEvent: false },
      );
    }
    if (this.guaranteeForm && this.extendedOption) {
      this.guaranteeForm.controls[GUARANTEE_OPTION_EXTENDED].reset(
        {
          value: this.extendedOption.isSelected,
          disabled: !this.extendedOption.isEnabled,
        },
        { emitEvent: false },
      );
    }
  }

  private getRequiredGuaranteeOptionsNotAccepted(cosCheckPlusSelected: boolean, extendedSelected: boolean): PrebookedServiceGuaranteeOptionType[] {
    const required = this.getGuaranteeOptionsRequiringAcceptance(cosCheckPlusSelected, extendedSelected);
    return this.prebookedServicesService.getGuaranteeOptionsNotAccepted(required);
  }

  private getGuaranteeOptionsRequiringAcceptance(cosCheckPlusSelected, extendedSelected): PrebookedServiceGuaranteeOptionType[] {
    const guaranteeOptionsRequiringAcceptance: PrebookedServiceGuaranteeOptionType[] = [];
    const persistedGuaranteeService: IPrebookedService = PrebookedServicesUtils.getService(this.auction.prebookedServices, EPrebookedServiceType.GUARANTEE);
    if (!persistedGuaranteeService) {
      return [];
    }
    if (PrebookedServicesUtils.getOption<IPrebookedServiceGuaranteeOption>(persistedGuaranteeService, GUARANTEE_OPTION_STANDARD)) {
      guaranteeOptionsRequiringAcceptance.push(GUARANTEE_OPTION_STANDARD);
    }
    if (cosCheckPlusSelected) {
      guaranteeOptionsRequiringAcceptance.push(GUARANTEE_OPTION_COS_CHECK_PLUS);
    }
    if (extendedSelected) {
      guaranteeOptionsRequiringAcceptance.push(GUARANTEE_OPTION_EXTENDED);
    }
    return guaranteeOptionsRequiringAcceptance;
  }

  private extractGuaranteeOptionsFromPrebookedServices(prebookedServices: IPrebookedService[]) {
    const guaranteeService: IPrebookedService = PrebookedServicesUtils.getService(prebookedServices, EPrebookedServiceType.GUARANTEE);
    if (guaranteeService) {
      this.standardOption = PrebookedServicesUtils.getOption<IPrebookedServiceGuaranteeOption>(guaranteeService, GUARANTEE_OPTION_STANDARD);
      this.cosCheckPlusOption = PrebookedServicesUtils.getOption<IPrebookedServiceGuaranteeOption>(guaranteeService, GUARANTEE_OPTION_COS_CHECK_PLUS);
      this.extendedOption = PrebookedServicesUtils.getOption<IPrebookedServiceGuaranteeOption>(guaranteeService, GUARANTEE_OPTION_EXTENDED);
    }
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
  }

  public showPopoverFromTemplate(event: Event, templateRef: TemplateRef<any>): void {
    event.preventDefault(); // do not check the checkbox
    this.popover.open({
      content: templateRef,
      origin: event.currentTarget as HTMLElement,
      width: '350px',
      customClasses: ['underline-links'],
    });
  }
}
