import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { EPrebookedServiceGuaranteeOptionType, EPrebookedServiceType, IBuyerAuctionView, IPrebookedServiceGuaranteeOption } from '@caronsale/cos-models';
import { COS_CONSTANTS } from '@cosCoreServices/constants/cos-constants.service';
import { PrebookedServicesUtils } from '@cosUtils/business';
import { NotificationCenterService } from '@cosCoreFeatures/common/notification-center/services/notification.service';

export interface IBuyerGuaranteeAdvertiseDialogParams {
  auction: IBuyerAuctionView;
  vehicleImage: string;
  displayButtons: boolean;
}

export interface IBuyerGuaranteeAdvertiseDialogResult {
  confirmed: boolean;
}

@Component({
  selector: 'app-buyer-guarantee-advertise-dialog',
  templateUrl: './buyer-guarantee-advertise-dialog.component.html',
  styleUrls: ['./buyer-guarantee-advertise-dialog.component.scss'],
})
export class BuyerGuaranteeAdvertiseDialogComponent {
  public readonly CONDITIONS_LINK: string;
  public translateTemplates: Record<string, string>;
  private guaranteePrices: Record<string, number>;

  public constructor(
    @Inject(MAT_DIALOG_DATA) public data: IBuyerGuaranteeAdvertiseDialogParams,
    private dialogRef: MatDialogRef<BuyerGuaranteeAdvertiseDialogComponent>,
    private constants: COS_CONSTANTS,
    private notificationCenterService: NotificationCenterService,
  ) {
    this.CONDITIONS_LINK = this.constants.GUARANTEE_CONDITIONS_URL;

    const { discountPriceNet: discount, priceNet: original } = PrebookedServicesUtils.getOption<IPrebookedServiceGuaranteeOption>(
      PrebookedServicesUtils.getService(this.data.auction.prebookedServices, EPrebookedServiceType.GUARANTEE),
      EPrebookedServiceGuaranteeOptionType.COS_CHECK_PLUS,
    );
    this.guaranteePrices = { discount, original };

    this.notificationCenterService.trackGuaranteeNotificationTrigger('guarantee_popup_opened', this.data.auction.uuid);

    this.translateTemplates = {
      auctionLabel: `{prefix} <span>${this.data.auction.label}</span>`,
      disclaimer: `{text} <a target="_blank" href="${this.CONDITIONS_LINK}"><u>{label}</u></a>.`,
      discount: `{text} <span>${this.guaranteePrices.discount}€</span> {comparator} ${this.guaranteePrices.original}€`,
    };
  }

  public onConfirm(): void {
    this.notificationCenterService.trackGuaranteeNotificationTrigger('guarantee_popup_converted', this.data.auction.uuid);
    const result: IBuyerGuaranteeAdvertiseDialogResult = { confirmed: true };
    this.dialogRef.close(result);
  }

  public onCancel(): void {
    const result: IBuyerGuaranteeAdvertiseDialogResult = { confirmed: false };
    this.dialogRef.close(result);
  }
}
