import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, finalize, map, Observable, of, Subject, takeUntil, tap } from 'rxjs';
import isEqual from 'lodash.isequal';

import {
  EAuctionListType,
  IAuctionFilter,
  IBuyerAuctionBiddingDataView,
  IBuyerAuctionView,
  IPage,
  IPrebookedService,
  IPurchaseFeeBucketPrice,
  IResultMap,
  Validation,
} from '@caronsale/cos-models';
import { CosBuyerClientService, IPageBuyerAuctionViewWithFilter } from '@cosCoreServices/cos-salesman-client/cos-buyer-client.service';
import { ConfigService } from '@cosCoreServices/config/config.service';
import { I18nErrorDialogComponent } from '@cosCoreComponentsGeneral/i18n/error-dialog/i18n-error-dialog.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { AuctionService } from '@cosCoreFeatures/auction-detail/common/auction-service/auction.service';
import { HeartbeatService, ErrorHandlers } from '@caronsale/frontend-services';
import { BuyerAuctionViewStates, EBuyerAuctionViewVariant, IBuyerAuctionViewState } from './buyer-auction-view-states';
import { ViewStateSubscriptionManagement } from '@cosCoreFeatures/auction-detail/common/auction-service/view-state-subscription-management';
import { BuyerAuctionHeartbeatHandler } from '@cosCoreFeatures/auction-detail/common/auction-service/buyer-auction-heartbeat-handler';
import { removeEmptyValuesFromObject } from '@cosUtils/general';
import { NotificationCenterService } from '@cos/features/common/notification-center/services/notification.service';
import { ProductAnalyticsService } from '@cosCoreServices/product-analytics/product-analytics.service';
import { MAX_PAGE_SIZE } from '@cosBuyer/auctions/partials/services/auction-filter/auction-filter.service';
import { EBuyerAuctionTabId } from '@cos/features/auction-detail/common/auction-service/buyer-auction.types';

export interface IBuyerAuctionList {
  auctionUuids: string[];
  totalCount: number;
  recommendationId?: string;
  filter?: IAuctionFilter;
}

export type TBuyerAuctionListFunctionName = keyof Pick<
  CosBuyerClientService,
  | 'getRunningAuctionsPage'
  | 'getRunningAuctionsWithActiveInstantPurchasePage'
  | 'getWatchlistAuctions'
  | 'getRecommendedAuctions'
  | 'getLastViewedAuctions'
  | 'getLastSearchedAuctions'
  | 'getPurchasePreferenceAuctions'
>;

export const DETECT_AUCTION_CLOSED = true;

export const IS_POLLING = true;

type AuctionFetchError = {
  type: 'error' | 'warning';
  messageKey: string;
  shouldNavigateTo?: string;
};

const TECH_PUSH_AUCTION_UPDATED_TOPIC = 'auction.updated';

@Injectable({
  providedIn: 'root',
})
export class BuyerAuctionService extends AuctionService {
  public readonly MINIMUM_DAYS_UNTIL_REAUCTIONING = 5;

  private subscriptionManagement = new ViewStateSubscriptionManagement<IBuyerAuctionViewState>();
  private buyerAuctionViewStates = new BuyerAuctionViewStates(this.subscriptionManagement);

  // indicate (during hot bid phase) that an auction has just closed
  // the detail view switches to the next auction and the bidding service closes any open bidding dialogs
  // On the seller side the detail view stays open if the auction finishes and lists refresh timer-based only
  private _auctionBuyerClosed$: Subject<string> = new Subject<string>();
  public auctionBuyerClosed$: Observable<string> = this._auctionBuyerClosed$.asObservable();
  // separate observable for list views
  // if this is observed, we will update unsubscribed auctions when they time out instead of removing them
  public auctionBuyerClosedForListViews$: Subject<string> = new Subject<string>();

  private _watchlistCount$ = new BehaviorSubject<number>(0);
  public watchlistCount$ = this._watchlistCount$.asObservable();

  private _auctionFetchError$: Subject<AuctionFetchError> = new Subject<AuctionFetchError>();
  public auctionFetchError$: Observable<AuctionFetchError> = this._auctionFetchError$.asObservable();

  private reversePurchaseFeePriceList: IPurchaseFeeBucketPrice[] = [];
  private heartbeatHandler: BuyerAuctionHeartbeatHandler;
  private biddingInfoRequestInProgressUuids: Set<string> = new Set<string>();
  private fullAuctionRequestsIndicateWhenClosedFlags: Map<string, boolean> = new Map<string, boolean>();

  public constructor(
    private cosBuyerClient: CosBuyerClientService,
    configService: ConfigService,
    heartbeatService: HeartbeatService,
    private dialog: MatDialog,
    private router: Router,
    productAnalyticsService: ProductAnalyticsService,
    notificationCenterService: NotificationCenterService,
  ) {
    super(configService, heartbeatService);
    this.heartbeatHandler = new BuyerAuctionHeartbeatHandler(
      this,
      this.buyerAuctionViewStates,
      this.subscriptionManagement,
      this.heartbeatSecondsElapsed$,
      productAnalyticsService,
    );

    this.cosBuyerClient.getPurchaseFeePriceList().subscribe(purchaseFeePriceList => {
      this.reversePurchaseFeePriceList = purchaseFeePriceList.reverse();
    });

    this.auctionBuyerClosed$.pipe(takeUntil(this.unsubscribe$)).subscribe(uuid => {
      this.auctionBuyerClosedForListViews$.next(uuid);
      this.buyerAuctionViewStates.delete(uuid);
    });

    notificationCenterService.newTechnicalPush$.subscribe((newTechnicalPush: any) => {
      if (!newTechnicalPush || newTechnicalPush.topic !== TECH_PUSH_AUCTION_UPDATED_TOPIC || !newTechnicalPush.auctionUuid) {
        return;
      }
      const createdAt = newTechnicalPush.createdAt ? new Date(newTechnicalPush.createdAt) : new Date();
      this.buyerAuctionViewStates.reportUpdateFromServer(newTechnicalPush.auctionUuid, createdAt);
    });
  }

  public updateCurrentPrebookedServices(uuid: string, newCurrentPrebookedServices: IPrebookedService[]): void {
    if (!newCurrentPrebookedServices || !Array.isArray(newCurrentPrebookedServices)) {
      return;
    }
    this.buyerAuctionViewStates.updateCurrentPrebookedServices(uuid, newCurrentPrebookedServices);
  }

  public getPurchaseFeeForBidAmount(bidAmount: number): number {
    const bucket: IPurchaseFeeBucketPrice = this.reversePurchaseFeePriceList.find(bucket => bucket.minValue <= bidAmount);
    return bucket?.feeNet || 0;
  }

  public getAuctionList(
    serviceFnName: TBuyerAuctionListFunctionName,
    filter: IAuctionFilter,
    isPolling?: boolean,
    queryParams?: { count?: boolean; addToSearchHistory?: boolean },
  ): Observable<IBuyerAuctionList> {
    const requestDate = new Date();
    const args = [filter, isPolling, queryParams] as unknown as [IAuctionFilter, boolean];

    return this.cosBuyerClient[serviceFnName](...args).pipe(
      tap((page: IPage<IBuyerAuctionView> | IPageBuyerAuctionViewWithFilter) =>
        page.items.forEach(auction =>
          // auctions fetched in a list do not contain navigation flags. Therefore we do not store a filter.
          this.buyerAuctionViewStates.updateAuctionViewState(auction.uuid, auction, requestDate, null, EBuyerAuctionViewVariant.LIST_VIEW, null),
        ),
      ),
      map((page: IPage<IBuyerAuctionView> | IPageBuyerAuctionViewWithFilter) => ({
        recommendationId: page.items[0]?.recommendationId,
        auctionUuids: page.items.map(auction => auction.uuid),
        filter: (page as IPageBuyerAuctionViewWithFilter).filter,
        totalCount: page.total,
      })),
    );
  }

  // indicateWhenClosed is used during the hot bid phase to detect that the auction ended
  // it shall be set only if we know for sure the auction was ACTIVE before.
  public refreshBuyerAuction(uuid: string, indicateWhenClosed?: boolean, isPolling?: boolean): void {
    const { auction, auctionFilter, tabId } = this.buyerAuctionViewStates.getState(uuid) || {};
    this.loadBuyerAuction(uuid, auctionFilter, tabId, indicateWhenClosed, auction, isPolling);
  }

  // leaving the auctionFilter undefined means: the caller is not interested in previous and next values
  public getBuyerAuctionViewState$(
    uuid: string,
    auctionFilter?: IAuctionFilter,
    requestedViewVariant?: EBuyerAuctionViewVariant,
    tabId?: EBuyerAuctionTabId,
  ): Observable<IBuyerAuctionViewState> {
    let buyerAuctionViewState$ = this.subscriptionManagement.getViewStateObservable$(uuid);
    const existingViewState = this.buyerAuctionViewStates.getState(uuid);
    // if it is stale, the heartbeat will refresh it since it will be subscribed now

    if (!buyerAuctionViewState$) {
      buyerAuctionViewState$ = this.subscriptionManagement.createViewStateObservable$(uuid);
      if (existingViewState) {
        this.subscriptionManagement.emitViewState(uuid, existingViewState);
      }
    }

    if (!existingViewState || this.needToReload(existingViewState, auctionFilter, requestedViewVariant, tabId)) {
      this.loadBuyerAuction(uuid, auctionFilter, tabId);
    }

    return buyerAuctionViewState$;
  }

  private needToReload(
    existingViewState: IBuyerAuctionViewState,
    auctionFilter: IAuctionFilter,
    requestedViewVariant: EBuyerAuctionViewVariant,
    tabId: EBuyerAuctionTabId,
  ): boolean {
    const needPrevAndNext = Validation.isAuctionRunning(existingViewState.auction) && Boolean(auctionFilter);
    const filtersMatch = isEqual(this.normalizeAuctionFilter(existingViewState.auctionFilter), this.normalizeAuctionFilter(auctionFilter));
    const safeTabId = tabId ?? null;
    const tabIdsMatch = existingViewState.tabId === safeTabId;

    // if we need previous/next flags but the stored flags are for a different filter or tab
    if (needPrevAndNext && (!filtersMatch || !tabIdsMatch)) {
      // reset the previous/next flags and request a reload, just to get the flags according to the new filter
      this.buyerAuctionViewStates.resetAuctionNavigation(existingViewState.auction.uuid);
      return true;
    }

    // a full detail reload is only needed, if the caller requests a detail view and the existing view state is not a detail view
    return requestedViewVariant === EBuyerAuctionViewVariant.DETAIL_VIEW && existingViewState.viewVariant !== EBuyerAuctionViewVariant.DETAIL_VIEW;
  }

  private closeAuctionAndEmitError(auctionUuid: string, errorMessageKey: string, tabId?: EBuyerAuctionTabId): void {
    const currentAuction = this.buyerAuctionViewStates.getState(auctionUuid);
    this.buyerAuctionViewStates.delete(auctionUuid);
    this._auctionBuyerClosed$.next(auctionUuid);

    if (currentAuction?.auction.hasNextAuction) {
      return;
    }

    const tabIdParam = tabId ? `?tabId=${tabId}` : '';
    this._auctionFetchError$.next({ type: 'error', messageKey: errorMessageKey, shouldNavigateTo: '/salesman/auctions' + tabIdParam });
  }

  private loadBuyerAuction(
    uuid: string,
    auctionFilter: IAuctionFilter,
    tabId?: EBuyerAuctionTabId,
    indicateWhenClosed?: boolean,
    currentAuction?: IBuyerAuctionView,
    isPolling?: boolean,
  ): void {
    const requestErrorHandlers: ErrorHandlers = {
      404: () => this.closeAuctionAndEmitError(uuid, 'error.auction-not-found', tabId),
      500: () => this.closeAuctionAndEmitError(uuid, 'error.auction-not-available', tabId),
    };
    const requestDate = new Date();
    // for a no longer running auction we don't want to filter by the current context or auction filter because it returns a 404
    const skipAuctionContextForNormalAuctions = currentAuction && !currentAuction.remainingTimeInSeconds;
    const skipAuctionContextForInstantPurchaseAuctions =
      currentAuction?.allowInstantPurchase && !currentAuction.remainingTimeForInstantPurchaseInSeconds && tabId === EBuyerAuctionTabId.INSTANT_PURCHASE;
    const skipAuctionContext = skipAuctionContextForNormalAuctions || skipAuctionContextForInstantPurchaseAuctions || indicateWhenClosed;
    if (this.fullAuctionRequestsIndicateWhenClosedFlags.has(uuid)) {
      // accumulate the indicateWhenClosed flags but do not issue another request
      this.fullAuctionRequestsIndicateWhenClosedFlags.set(uuid, this.fullAuctionRequestsIndicateWhenClosedFlags.get(uuid) || Boolean(indicateWhenClosed));
      return;
    }
    this.fullAuctionRequestsIndicateWhenClosedFlags.set(uuid, Boolean(indicateWhenClosed));
    this.cosBuyerClient
      .getAuction(uuid, skipAuctionContext ? undefined : auctionFilter, skipAuctionContext ? undefined : tabId, isPolling, requestErrorHandlers)
      .pipe(finalize(() => this.fullAuctionRequestsIndicateWhenClosedFlags.delete(uuid)))
      .subscribe({
        next: (auction: IBuyerAuctionView) => {
          // for normal auctions, if we skip the auction context we want to maintain the navigation as it was
          // since without the context the directional travel is incorrect
          if (skipAuctionContextForNormalAuctions) {
            auction.hasNextAuction = currentAuction.hasNextAuction;
            auction.hasPreviousAuction = currentAuction.hasPreviousAuction;
          }
          // for instant purchases, if we skip the auction context we want to switch off the navigation
          // since all auctions end at the same time
          if (skipAuctionContextForInstantPurchaseAuctions) {
            auction.hasNextAuction = false;
            auction.hasPreviousAuction = false;
          }
          this.buyerAuctionViewStates.updateAuctionViewState(uuid, auction, requestDate, auctionFilter, EBuyerAuctionViewVariant.DETAIL_VIEW, tabId);
          if (this.fullAuctionRequestsIndicateWhenClosedFlags.get(uuid) && !Validation.isAuctionRunning(auction)) {
            this._auctionBuyerClosed$.next(uuid);
          }
        },
      });
  }

  private removeInProgressUuidsFrom(uuids: string[]): string[] {
    return uuids.filter(uuid => !this.biddingInfoRequestInProgressUuids.has(uuid));
  }

  private setInProgress(uuids: string[]): void {
    uuids.forEach(uuid => this.biddingInfoRequestInProgressUuids.add(uuid));
  }

  private resetInProgress(uuids: string[]): void {
    uuids.forEach(uuid => this.biddingInfoRequestInProgressUuids.delete(uuid));
  }

  public refreshBiddingInfos(uuids: string[], isPolling?: boolean): void {
    const requestDate = new Date();
    const requestedUuids = this.removeInProgressUuidsFrom(uuids);
    if (requestedUuids.length === 0) {
      return;
    }
    this.setInProgress(requestedUuids);

    this.cosBuyerClient
      .getBiddingDataView({ uuids: requestedUuids, limit: MAX_PAGE_SIZE }, isPolling)
      .pipe(finalize(() => this.resetInProgress(requestedUuids)))
      .subscribe({
        next: (biddingInfos: IBuyerAuctionBiddingDataView[]) => {
          biddingInfos?.forEach((biddingInfo: IBuyerAuctionBiddingDataView) => {
            this.buyerAuctionViewStates.updateBiddingInfo(biddingInfo, requestDate);
          });
          requestedUuids.forEach((uuid: string) => {
            if (!biddingInfos?.find(biddingInfo => biddingInfo.uuid === uuid)) {
              // not in returned bidding infos means: the auction is already closed
              this.refreshBuyerAuction(uuid, DETECT_AUCTION_CLOSED, IS_POLLING);
            }
          });
        },
        error: errorResponse => {
          if (errorResponse.status === 404) {
            this.showErrorAndNavigateTo('error.bid-information', '/salesman/auctions');
          }
        },
      });
  }

  private showErrorAndNavigateTo(errorName: string, targetRoute: string): void {
    I18nErrorDialogComponent.show(this.dialog, errorName).subscribe(() => {
      this.dialog.closeAll();
      if (targetRoute) {
        this.router.navigateByUrl(targetRoute);
      }
    });
  }

  // Navigation functions
  // If there is no previous or next running auction, these functions return an observable that completes without emitting anything
  // (but the navigation flags for this uuid will be updated to reflect the missing next or previous auction)
  public getPreviousBuyerAuctionUuid$(uuid: string, auctionFilter: IAuctionFilter, tabId: EBuyerAuctionTabId): Observable<string> {
    return this.loadPreviousOrNextBuyerAuction$(uuid, auctionFilter, 'previous', tabId);
  }

  public getNextBuyerAuctionUuid$(uuid: string, auctionFilter: IAuctionFilter, tabId: EBuyerAuctionTabId): Observable<string> {
    return this.loadPreviousOrNextBuyerAuction$(uuid, auctionFilter, 'next', tabId);
  }

  private loadPreviousOrNextBuyerAuction$(
    uuid: string,
    auctionFilter: IAuctionFilter,
    direction: 'previous' | 'next',
    tabId: EBuyerAuctionTabId,
  ): Observable<string> {
    const requestErrorHandlers: ErrorHandlers = {
      404: () => {
        this._auctionFetchError$.next({ type: 'error', messageKey: 'error.auction-not-found', shouldNavigateTo: `/salesman/auctions?tabId=${tabId || 0}` });
      },
    };
    const requestDate = new Date();
    return this.cosBuyerClient[direction === 'previous' ? 'getPreviousAuction' : 'getNextAuction'](uuid, auctionFilter, tabId, requestErrorHandlers).pipe(
      map((auction: IBuyerAuctionView) => {
        if (auction) {
          this.buyerAuctionViewStates.updateAuctionViewState(auction.uuid, auction, requestDate, auctionFilter, EBuyerAuctionViewVariant.DETAIL_VIEW, tabId);
          return auction.uuid;
        } else {
          // there is no previous or next auction anymore, so switch off the navigation link
          this.buyerAuctionViewStates.resetNavigationFlag(uuid, direction);
          return null;
        }
      }),
      catchError(() => of(null)),
    );
  }

  public addToWatchlist(uuid: string, recommendationId?: string): Observable<void> {
    return this.cosBuyerClient.__startWatchingAuctionAsSalesman(uuid, recommendationId).pipe(tap(() => this.updateWatchlistState(uuid, true)));
  }

  public removeFromWatchlist(uuid: string, recommendationId?: string): Observable<void> {
    return this.cosBuyerClient.__stopWatchingAuctionAsSalesman(uuid, recommendationId).pipe(tap(() => this.updateWatchlistState(uuid, false)));
  }

  public updateWatchlistState(uuid: string, isParked: boolean): void {
    this._watchlistCount$.next(this._watchlistCount$.getValue() + (isParked ? 1 : -1));
    const { auction, lastBiddingInfoRequestDate, auctionFilter, viewVariant, tabId } = this.buyerAuctionViewStates.getState(uuid);
    this.buyerAuctionViewStates.updateAuctionViewState(
      uuid,
      { ...auction, isParked, timesParked: auction.timesParked + (isParked ? 1 : -1) },
      lastBiddingInfoRequestDate,
      auctionFilter,
      viewVariant,
      tabId,
    );
  }

  public getAuctionCounts(filter: IAuctionFilter, types: EAuctionListType[]): Observable<IResultMap<number>> {
    return this.cosBuyerClient.__getAuctionCounts(filter, types).pipe(
      tap(result => {
        if (result[EAuctionListType.PARKED]) {
          this._watchlistCount$.next(result[EAuctionListType.PARKED]);
        }
      }),
    );
  }

  public hasReauctionWarning(auction: IBuyerAuctionView): boolean {
    return (
      Validation.isAuctionClosedAndWaitingForPayment(auction) &&
      !auction.incomingPaymentConfirmedAt &&
      auction.remainingDaysUntilReauctioning <= this.MINIMUM_DAYS_UNTIL_REAUCTIONING
    );
  }

  private normalizeAuctionFilter(auctionFilter: IAuctionFilter): IAuctionFilter {
    return removeEmptyValuesFromObject({
      ...auctionFilter,
      offset: null,
      limit: null,
    });
  }
}
