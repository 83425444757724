<div (click)="togglePanel()" class="title" [ngClass]="{ stretch: titleStretch, 'icon-left': iconPosition === 'left' }">
  <div #titleReference>
    <ng-content select="[panelTitle]" />
  </div>
  <enzo-icon class="title-icon" [class.expanded]="expandedState" *ngIf="titleReference.children.length > 0" icon="arrow-down" />
  <ng-content select="[panelTitleSuffix]" />
</div>

<mat-expansion-panel [expanded]="expandedState" class="panel">
  <ng-content select="[panelContent]" />
</mat-expansion-panel>
