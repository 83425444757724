import { ChangeDetectionStrategy, Component, computed, input, output, Signal } from '@angular/core';
import { EnzoIcon, Icon } from '@caronsale/enzo';
import { EnzoGhostButtonComponent } from '../../buttons/enzo-ghost-button/enzo-ghost-button.component';
import { EnzoTextComponent } from '../../typography/enzo-text/enzo-text.component';

export type EnzoBannerVariations = 'neutral' | 'highlight' | 'success' | 'warning' | 'danger' | 'muted' | 'attention';

@Component({
  selector: 'enzo-banner',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <enzo-text iconSize="small" iconSpacing="3" [icon]="iconName()" kind="emphasis-label">
      {{ label() }}
      <ng-content />
    </enzo-text>
    @if (hasCta()) {
      <enzo-ghost-button [href]="ctaHref()" [label]="ctaLabel()" variant="highlight" size="small" type="link" (enzoClick)="ctaClick.emit()" />
    }
  `,
  styleUrl: './enzo-banner.component.scss',
  standalone: true,
  imports: [EnzoTextComponent, EnzoGhostButtonComponent],
  host: {
    '[attr.data-kind]': 'kind()',
  },
})
export class EnzoBannerComponent {
  public icon = input<EnzoIcon['icon'] | 'default'>('default');
  public kind = input<EnzoBannerVariations>('neutral');
  public label = input<string>('');
  public ctaHref = input<string>('');
  public ctaLabel = input<string>('');
  public ctaClick = output();

  protected hasCta = computed(() => !!this.ctaHref() && !!this.ctaLabel());

  protected iconName = computed(() => {
    if (this.icon() === 'default') {
      switch (this.kind()) {
        case 'neutral':
        case 'muted':
        case 'attention':
        case 'highlight':
          return 'info-outline';
        case 'success':
          return 'check-outline';
        case 'warning':
        case 'danger':
          return 'warning';
        default:
          return null;
      }
    }
    return this.icon();
  }) as Signal<Icon>;
}
