import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ECountryCode, ELanguageCode } from '@caronsale/cos-models';
import { CountryCodeService } from '@caronsale/frontend-services';
import { I18nService } from '@cosCoreServices/i18n/i18n.service';

@Injectable({
  providedIn: 'root',
})
export class COS_CONSTANTS {
  private fallbackCountryCode = ECountryCode.DE;

  private fallbackUploadEmail = 'reg@caronsale.com';

  private fallbackUploadUrl = 'https://support.caronsale.de/en/support/solutions/articles/47001220880-what-documents-are-required-for-registration';

  // CoS
  public readonly COMPANY_NAME = 'Castle Tech GmbH';
  public readonly COMPANY_ADDRESS_LINE_1 = 'Hauptstraße 27, Haus 9 Aufgang N';
  public readonly COMPANY_ADDRESS_LINE_2 = '10827 Berlin, Deutschland';
  public readonly REGISTRATION_COURT = 'Amtsgericht Charlottenburg (Berlin), HRB 213350 B';
  public readonly REPRESENTING_DIRECTORS = 'Tom Krüger';
  public readonly TAX_ID = 'DE320199054';
  public readonly TAX_NUMBER = '30/249/52834';

  // app
  public readonly APP_LOGIN_PAGE = 'https://app.caronsale.de/login';
  public readonly APP_URLS = {
    CRM: 'https://crm.caronsale.de',
    TRANSPORT: 'https://transport.caronsale.de',
  };

  // CRM
  public readonly STRIPE_QUERY_URL = 'https://dashboard.stripe.com/search?query=';

  // CoS URLs
  public readonly FAQ_URL = 'http://help.caronsale.de/support/home';
  public readonly GUARANTEE_CONDITIONS_URL = 'https://res.cloudinary.com/castle-tech-gmbh/image/upload/docs/Garantiebedingungen.pdf';
  public readonly BNPL_CONDITIONS_URL =
    'https://res.cloudinary.com/castle-tech-gmbh/image/upload/docs/Geschaaftsbedingungen_Jetzt_kaufen_spaater_bezahlen_bedingungen.pdf';

  // other URLs
  public readonly VEHICLE_INTAKE_SHEET_MAP = {
    [ELanguageCode.DE]: 'https://res.cloudinary.com/castle-tech-gmbh/raw/upload/v1637144662/docs/Fahrzeugaufnahmeliste.xlsx',
    [ELanguageCode.FR]: 'https://res.cloudinary.com/castle-tech-gmbh/raw/upload/v1637144662/docs/Fahrzeugaufnahmeliste_FR.xlsx',
    [ELanguageCode.NL]: 'https://res.cloudinary.com/castle-tech-gmbh/raw/upload/v1637144662/docs/Fahrzeugaufnahmeliste_NL.xlsx',
    [ELanguageCode.EN]: 'https://res.cloudinary.com/castle-tech-gmbh/raw/upload/v1637144662/docs/Fahrzeugaufnahmeliste_EN.xlsx',
  };

  public readonly TERMS_OF_SERVICE_URL_MAP = {
    [ELanguageCode.DE]: 'https://legal.caronsale.de/de.pdf',
    [ELanguageCode.FR]: 'https://legal.caronsale.de/fr.pdf',
    [ELanguageCode.NL]: 'https://legal.caronsale.de/nl.pdf',
    [ELanguageCode.EN]: 'https://legal.caronsale.de/en.pdf',
  };

  public readonly SUPPORT_URL_MAP = {
    [ELanguageCode.DE]: 'https://support.caronsale.de/de/support/home',
    [ELanguageCode.FR]: 'https://support.caronsale.de/fr/support/home',
    [ELanguageCode.NL]: 'https://support.caronsale.de/nl/support/home',
    [ELanguageCode.EN]: 'https://support.caronsale.de/en/support/home',
    [ELanguageCode.PL]: 'https://support.caronsale.de/pl/support/home',
    [ELanguageCode.CS]: 'https://support.caronsale.de/cs/support/home',
  };

  public readonly EC_DISPUTE_SETTLEMENT_PLATFORM = 'https://ec.europa.eu/consumers/odr';

  public readonly DAMAGE_CATALOG_URL = 'https://res.cloudinary.com/castle-tech-gmbh/image/upload/v1712307689/docs/Schadenkatalog.pdf';

  // country specific data
  private readonly countrySpecific = {
    banner: {
      auctionRooms: {
        vertical: {
          de: 'https://res.cloudinary.com/castle-tech-gmbh/image/upload/v1711114775/caronsale/banners/auction/2402_platform_banner_auction_rooms_de_brands-(DE).png',
          pl: 'https://res.cloudinary.com/castle-tech-gmbh/image/upload/v1711114775/caronsale/banners/auction/2402_platform_banner_auction_rooms_de_brands-(PL).png',
          fr: 'https://res.cloudinary.com/castle-tech-gmbh/image/upload/v1711114775/caronsale/banners/auction/2402_platform_banner_auction_rooms_de_brands-(FR).png',
          nl: 'https://res.cloudinary.com/castle-tech-gmbh/image/upload/v1711114775/caronsale/banners/auction/2402_platform_banner_auction_rooms_de_brands-(NL).png',
        },
        horizontal: {
          de: 'https://res.cloudinary.com/castle-tech-gmbh/image/upload/v1711114775/caronsale/banners/auction/2402_platform_banner_auction_rooms_de_brands-(DE-rectangle).png',
          pl: 'https://res.cloudinary.com/castle-tech-gmbh/image/upload/v1711114775/caronsale/banners/auction/2402_platform_banner_auction_rooms_de_brands-(PL-rectangle).png',
          fr: 'https://res.cloudinary.com/castle-tech-gmbh/image/upload/v1711114775/caronsale/banners/auction/2402_platform_banner_auction_rooms_de_brands-(FR-rectangle).png',
          nl: 'https://res.cloudinary.com/castle-tech-gmbh/image/upload/v1711114775/caronsale/banners/auction/2402_platform_banner_auction_rooms_de_brands-(NL-rectangle).png',
        },
        urls: {
          de: 'https://www.caronsale.com/de/auktion/junge-fahrzeuge',
          pl: 'https://www.caronsale.com/auction/young-vehicles',
          nl: 'https://www.caronsale.com/auction/young-vehicles',
          fr: 'https://www.caronsale.com/auction/young-vehicles',
        },
      },
      referralSeller: {
        vertical: {
          de: 'https://res.cloudinary.com/castle-tech-gmbh/image/upload/f_auto/v1658423681/caronsale/banners/referral/de-referral-banner-seller.jpg',
          en: 'https://res.cloudinary.com/castle-tech-gmbh/image/upload/f_auto/v1658423681/caronsale/banners/referral/en-referral-banner-seller.jpg',
          fr: 'https://res.cloudinary.com/castle-tech-gmbh/image/upload/f_auto/v1658423681/caronsale/banners/referral/fr-referral-banner-seller.jpg',
          nl: 'https://res.cloudinary.com/castle-tech-gmbh/image/upload/f_auto/v1658423681/caronsale/banners/referral/nl-referral-banner-seller.jpg',
        },
        horizontal: {
          de: 'https://res.cloudinary.com/castle-tech-gmbh/image/upload/f_auto/v1658423681/caronsale/banners/referral/de-referral-banner-seller-md.jpg',
          en: 'https://res.cloudinary.com/castle-tech-gmbh/image/upload/f_auto/v1658423681/caronsale/banners/referral/en-referral-banner-seller-md.jpg',
          fr: 'https://res.cloudinary.com/castle-tech-gmbh/image/upload/f_auto/v1658423681/caronsale/banners/referral/fr-referral-banner-seller-md.jpg',
          nl: 'https://res.cloudinary.com/castle-tech-gmbh/image/upload/f_auto/v1658423681/caronsale/banners/referral/nl-referral-banner-seller-md.jpg',
        },
      },
      cosCheckPlusBuyer: {
        vertical: {
          de: 'https://res.cloudinary.com/castle-tech-gmbh/image/upload/f_auto/v1658423681/caronsale/banners/guarantee/de-COSCheck-plus-banner-buyer.png',
          en: 'https://res.cloudinary.com/castle-tech-gmbh/image/upload/f_auto/v1658423681/caronsale/banners/guarantee/en-COSCheck-plus-banner-buyer.png',
          tr: 'https://res.cloudinary.com/castle-tech-gmbh/image/upload/f_auto/v1658423681/caronsale/banners/guarantee/tr-COSCheck-plus-banner-buyer.png',
          ru: 'https://res.cloudinary.com/castle-tech-gmbh/image/upload/f_auto/v1658423681/caronsale/banners/guarantee/ru-COSCheck-plus-banner-buyer.png',
          pl: 'https://res.cloudinary.com/castle-tech-gmbh/image/upload/f_auto/v1658423681/caronsale/banners/guarantee/pl-COSCheck-plus-banner-buyer.png',
        },
      },
    },
    email: {
      appraise: {
        [ECountryCode.DE]: 'gutachter@caronsale.de',
      },
      documents: {
        [ECountryCode.DE]: 'reg@caronsale.de',
        [ECountryCode.AT]: 'reg@caronsale.at',
        [ECountryCode.FR]: 'reg@caronsale.fr',
        [ECountryCode.NL]: 'reg@caronsale.nl',
      },
      info: {
        [ECountryCode.DE]: 'info@caronsale.de',
      },
      invoice: {
        [ECountryCode.DE]: 'rechnung@caronsale.de',
        [ECountryCode.FR]: 'facture@caronsale.fr',
        [ECountryCode.NL]: 'factuur@caronsale.nl',
      },
      pricingRequest: {
        [ECountryCode.DE]: 'preisanfrage@caronsale.de',
      },
      service: {
        [ECountryCode.DE]: 'service@caronsale.de',
      },
      support: {
        [ECountryCode.DE]: 'support@caronsale.de',
        [ECountryCode.FR]: 'support@caronsale.fr',
        [ECountryCode.NL]: 'support@caronsale.nl',
        [ECountryCode.PL]: 'support@caronsale.pl',
      },
    },
    phoneNumber: {
      contact: {
        [ECountryCode.DE]: '+49 30 311 96 400',
        [ECountryCode.AT]: '+43 1 4350333',
        [ECountryCode.FR]: '+33 1 88 24 02 88',
        [ECountryCode.NL]: '+31 20 808 9911',
        [ECountryCode.PL]: '+48 22 263 95 49',
      },
      expiredPassword: {
        [ECountryCode.DE]: '+49 30 311 96 400',
      },
      faq: {
        [ECountryCode.DE]: '+49 30 311 96 402',
      },
      pricingRequest: {
        [ECountryCode.DE]: '+49 30 311 96 401',
      },
    },
    url: {
      base: {
        [ECountryCode.DE]: 'https://www.caronsale.com/de',
        [ECountryCode.AT]: 'https://www.caronsale.com/at',
        [ECountryCode.CZ]: 'https://www.caronsale.com/cz',
        [ECountryCode.DK]: 'https://www.caronsale.com/dk',
        [ECountryCode.GB]: 'https://www.caronsale.com',
        [ECountryCode.FR]: 'https://www.caronsale.com/fr',
        [ECountryCode.IT]: 'https://www.caronsale.com/it',
        [ECountryCode.NL]: 'https://www.caronsale.com/nl',
        [ECountryCode.PL]: 'https://www.caronsale.com/pl',
        [ECountryCode.SE]: 'https://www.caronsale.com/sv',
        [ECountryCode.TR]: 'https://www.caronsale.com/tr',
        [ECountryCode.US]: 'https://www.caronsale.com/',
      },
      privacy: {
        [ECountryCode.DE]: '/impressum',
        [ECountryCode.AT]: '/impressum',
        [ECountryCode.CZ]: '/tiraz',
        [ECountryCode.DK]: '/aftryk',
        [ECountryCode.GB]: '/imprint',
        [ECountryCode.FR]: '/mentions-legales',
        [ECountryCode.IT]: '/note-legali',
        [ECountryCode.NL]: '/imprint',
        [ECountryCode.PL]: '/nadruk',
        [ECountryCode.SE]: '/imprint',
        [ECountryCode.TR]: '/damga',
        [ECountryCode.US]: '/imprint',
      },
    },
    documentUrl: {
      [ECountryCode.DE]:
        'https://support.caronsale.de/de/support/solutions/articles/47001220880-welche-dokumente-werden-f%C3%BCr-die-registrierung-ben%C3%B6tigt',
      [ECountryCode.FR]: 'https://support.caronsale.de/fr/support/solutions/articles/47001220880-quels-documents-sont-n%C3%A9cessaires-pour-l-enregistrement',
      [ECountryCode.NL]: 'https://support.caronsale.de/nl/support/solutions/articles/47001220880-welke-documenten-zijn-vereist-voor-de-registratie',
      [ECountryCode.AT]:
        'https://support.caronsale.de/de/support/solutions/articles/47001220880-welche-dokumente-werden-f%C3%BCr-die-registrierung-ben%C3%B6tigt',
    },
    other: {
      businessHours: {
        [ECountryCode.DE]: '9:00 - 18:00',
        [ECountryCode.US]: '9:00 AM - 6:00 PM',
      },
      taxation: {
        differential: {
          articleNr: {
            [ECountryCode.DE]: '§25a',
            [ECountryCode.AT]: '§24',
            [ECountryCode.FR]: '§25a',
            [ECountryCode.NL]: '§28b',
          },
        },
      },
    },
  };

  public constructor(
    //
    private countryCodeSvc: CountryCodeService,
    private i18nService: I18nService,
  ) {}

  private getCountrySpecificProperty(entries): string {
    return entries[this.countryCodeSvc.userCountryCode] || entries[this.fallbackCountryCode];
  }

  // country specific e-mails
  public get APPRAISER_EMAIL(): string {
    return this.getCountrySpecificProperty(this.countrySpecific.email.appraise);
  }

  public get INFO_EMAIL(): string {
    return this.getCountrySpecificProperty(this.countrySpecific.email.info);
  }

  public get INVOICE_EMAIL(): string {
    return this.getCountrySpecificProperty(this.countrySpecific.email.invoice);
  }

  public get PRICING_REQUEST_EMAIL(): string {
    return this.getCountrySpecificProperty(this.countrySpecific.email.pricingRequest);
  }

  public get SERVICE_EMAIL(): string {
    return this.getCountrySpecificProperty(this.countrySpecific.email.service);
  }

  public get SUPPORT_EMAIL(): string {
    return this.getCountrySpecificProperty(this.countrySpecific.email.support);
  }

  // country specific phone numbers
  public get CONTACT_PHONE(): string {
    return this.getCountrySpecificProperty(this.countrySpecific.phoneNumber.contact);
  }

  public get EXPIRED_PASSWORD_PHONE(): string {
    return this.getCountrySpecificProperty(this.countrySpecific.phoneNumber.expiredPassword);
  }

  public get FAQ_PHONE(): string {
    return this.getCountrySpecificProperty(this.countrySpecific.phoneNumber.faq);
  }

  public get PRICING_REQUEST_PHONE(): string {
    return this.getCountrySpecificProperty(this.countrySpecific.phoneNumber.pricingRequest);
  }

  private getCountrySpecificURL(entries): string {
    const base = this.countrySpecific.url.base[this.countryCodeSvc.userCountryCode];
    const suffix = entries[this.countryCodeSvc.userCountryCode];

    if (base && suffix) {
      return `${base}${suffix}`;
    } else {
      return `${this.countrySpecific.url.base[this.fallbackCountryCode]}${entries[this.fallbackCountryCode]}`;
    }
  }

  // country specific URLs
  public get DATA_PRIVACY_URL(): string {
    return 'https://legal.caronsale.de/datenschutz.pdf';
  }

  // marketing banners
  public get auctionRoomsVerticalBanner$(): Observable<string> {
    return this.translateObj(this.countrySpecific.banner.auctionRooms.vertical);
  }

  public get auctionRoomsHorizontalBanner$(): Observable<string> {
    return this.translateObj(this.countrySpecific.banner.auctionRooms.horizontal);
  }

  public get auctionRoomsBannerLink$(): Observable<string> {
    return this.translateObj(this.countrySpecific.banner.auctionRooms.urls);
  }

  public get referralVerticalBannerSeller$(): Observable<string> {
    return this.translateObj(this.countrySpecific.banner.referralSeller.vertical);
  }

  public get referralHorizontalBannerSeller$(): Observable<string> {
    return this.translateObj(this.countrySpecific.banner.referralSeller.horizontal);
  }

  public get cosCheckPlusVerticalBannerBuyer$(): Observable<string> {
    return this.translateObj(this.countrySpecific.banner.cosCheckPlusBuyer.vertical);
  }

  public get termsOfServiceURL(): Observable<string> {
    return this.translateObj(this.TERMS_OF_SERVICE_URL_MAP);
  }

  public get supportURL(): Observable<string> {
    return this.translateObj(this.SUPPORT_URL_MAP);
  }

  private translateObj(obj: { [key: string]: string }): Observable<string> {
    return this.i18nService.languageChanged$.pipe(map(lang => obj[lang] || obj['de']));
  }

  // other country specific
  public getDifferentialTaxArticleNumber(countryCode: ECountryCode) {
    return this.countrySpecific.other.taxation.differential.articleNr[countryCode] ?? '';
  }

  public getCountrySpecificUploadEmail(): string {
    return this.countrySpecific.email.documents[this.countryCodeSvc.userCountryCode] || this.fallbackUploadEmail;
  }

  public getCountrySpecificUploadUrl(): string {
    return this.countrySpecific.documentUrl[this.countryCodeSvc.userCountryCode] || this.fallbackUploadUrl;
  }

  public get BUSINESS_HOURS(): string {
    return this.getCountrySpecificProperty(this.countrySpecific.other.businessHours);
  }
}
