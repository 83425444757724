import {
  AfterContentInit,
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  computed,
  ContentChildren,
  input,
  model,
  output,
  QueryList,
} from '@angular/core';
import { Spacer, spacers } from '@caronsale/enzo';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { EnzoBaseValueAccessor } from '../enzo-base-value-accessor';
import { EnzoInternalSelectRadioButtonPayload, EnzoRadioButtonComponent } from '../enzo-radio-button/enzo-radio-button.component';
import { outputToObservable, takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { merge, startWith, switchMap, tap } from 'rxjs';

const FORCE_UPDATE_EVEN_IF_DISABLED = true;
const DO_NOT_UPDATE_WHEN_DISABLED = false;
const PROPAGATE_TO_FROM_CONTROL = true;
const DO_NOT_PROPAGATE = false;

export type EnzoRadioButtonGroupInputPayload = {
  value: any;
};

// Increasing integer for generating unique ids for radio button groups.
let nextUniqueId = 0;

@Component({
  selector: 'enzo-radio-button-group',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: '<ng-content />',
  styles: ':host { flex-wrap: wrap }',
  standalone: true,
  host: {
    '(focusout)': 'isTouched.set(true); onTouched()',
    '[style.display]': 'layout() === "none" ? "": "flex"',
    '[style.flex-direction]': 'layout() === "horizontal" ? "row" : "column"',
    '[style.gap]': 'layout() === "none" ? "": spacers[spacing()]',
  },
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: EnzoRadioButtonGroupComponent,
      multi: true,
    },
  ],
})
export class EnzoRadioButtonGroupComponent extends EnzoBaseValueAccessor<any> implements AfterContentInit {
  public layout = input<'none' | 'horizontal' | 'vertical'>('vertical');
  public spacing = input<Spacer>('4');
  public value = model<unknown>();
  public disabled = model<boolean>(false);
  protected _disabled = computed(() => booleanAttribute(this.disabled()));
  public name = input<string>(`enzo-radio-button-group-${nextUniqueId++}`);
  public enzoInput = output<CustomEvent<EnzoRadioButtonGroupInputPayload>>();

  // don't know how to mock contentChildren signal query in enzo-testing.
  // The way we mock viewChild is not working for contentChildren.
  @ContentChildren(EnzoRadioButtonComponent, { descendants: true })
  public radioButtons: QueryList<EnzoRadioButtonComponent>;

  public constructor() {
    super();
  }

  public ngAfterContentInit() {
    this.radioButtons.changes
      .pipe(
        startWith(this.radioButtons),
        tap((radioButtons: QueryList<EnzoRadioButtonComponent>) => {
          this.setRadioButtonValues(radioButtons);
          radioButtons?.forEach(button => {
            button.disabled.set(this._disabled());
            button['name'].set(this.name());
          });
        }),
        switchMap(radioButtons => merge(...radioButtons.map(button => outputToObservable(button.internalSelect)))),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(internalSelectEvent => this.handleClickFromUser((internalSelectEvent as CustomEvent<EnzoInternalSelectRadioButtonPayload>).detail.value));
  }

  // control value accessor interface methods
  public writeValue(value: unknown) {
    this.setValue(value, FORCE_UPDATE_EVEN_IF_DISABLED, DO_NOT_PROPAGATE);
  }

  public setDisabledState(isDisabled: boolean) {
    this.disabled.set(isDisabled);
    this.radioButtons?.forEach(button => button.disabled.set(isDisabled));
  }

  private setValue(newValue: any, force: boolean, propagateToFormControl: boolean) {
    if (this._disabled() && !force) {
      return;
    }
    if (this.value() === newValue) {
      return;
    }
    this.value.set(newValue);
    this.setRadioButtonValues(this.radioButtons);
    if (propagateToFormControl) {
      this.propagateChange(newValue);
    }
  }

  private setRadioButtonValues(radioButtons: QueryList<EnzoRadioButtonComponent>) {
    let hasSelectedButton = false;
    radioButtons?.forEach(button => {
      if (button.value() === this.value()) {
        button.checked.set(true);
        button.internalFocusable.set(true);
        hasSelectedButton = true;
      } else {
        button.checked.set(false);
        button.internalFocusable.set(false);
      }
    });
    // if none is selected, the first one is the one to receive focus when you tab into this group
    if (!hasSelectedButton) {
      this.radioButtons?.first?.internalFocusable.set(true);
    }
  }

  public handleClickFromUser(newValue: any) {
    this.setValue(newValue, DO_NOT_UPDATE_WHEN_DISABLED, PROPAGATE_TO_FROM_CONTROL);
    this.enzoInput.emit(
      new CustomEvent<EnzoRadioButtonGroupInputPayload>('enzoInput', {
        detail: {
          value: newValue,
        },
      }),
    );
  }

  protected readonly spacers = spacers;
}
