<div class="content" [class]="panelClass">
  <ng-template cdkPortalOutlet />
</div>
<div class="button-container" [ngClass]="'enzo-variant-' + variant">
  <enzo-ghost-button
    *ngIf="hasCancelButton"
    [variant]="variant"
    (enzoClick)="onCancel()"
    [label]="cancelButtonKey | translate"
    testid="cancel-button"
    [attr.data-cy]="cancelButtonDataCy" />
  <enzo-button
    *ngIf="leftButtonKey"
    [variant]="variant"
    (enzoClick)="onLeft()"
    stretch="true"
    [label]="leftButtonKey | translate"
    testid="left-button"
    [attr.data-cy]="leftButtonDataCy" />
  <enzo-button
    [variant]="variant"
    (enzoClick)="onRight($event)"
    stretch="true"
    [label]="rightButtonKey | translate"
    [loading]="isLoading"
    testid="right-button"
    [attr.data-cy]="rightButtonDataCy" />
</div>
