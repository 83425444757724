import { Observable, Subscription, timer, map, switchMap, take } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

/**
 * Countdown timer for the digital renegotiation timeout.
 * You have to provide two callback functions
 *   - a "tick()" function that gets the formatted remaining time as a string every second
 *   - a "complete()" function that is called when the timer runs out
 *
 * The remaining seconds get formatted as follows:
 *  - values < 60:       "x Sekunden"
 *  - values >= 1 minute:    "Xm YYs"
 *  - values >= 1 hour:  "Xh YYm ZZs"
 */
export class CountdownTimer {
  private countdownTimerSubscription: Subscription;

  public constructor(private translateService: TranslateService) {}

  public cancel(): void {
    if (this.countdownTimerSubscription) {
      this.countdownTimerSubscription.unsubscribe();
      this.countdownTimerSubscription = undefined;
    }
  }

  public startOrRestart(seconds: number, tick: (formattedRemainingSeconds: string) => void, complete: () => void): void {
    this.cancel();
    this.countdownTimerSubscription = timer(0, 1000)
      .pipe(
        map(i => seconds - i),
        take(seconds + 1),
        switchMap(countdownSeconds => this.formatHoursMinutesSeconds(countdownSeconds)),
      )
      .subscribe({
        next: formattedRemainingTime => tick(formattedRemainingTime),
        complete: () => complete(),
      });
  }

  private formatHoursMinutesSeconds(seconds: number): Observable<string> {
    const hours = Math.floor(seconds / (60 * 60));
    seconds -= hours * 60 * 60;
    const minutes = Math.floor(seconds / 60);
    seconds -= minutes * 60;
    if (hours === 0) {
      if (minutes === 0) {
        return this.translateService.get('general.time-display.seconds-only', { seconds });
      }
      return this.translateService.get('general.time-display.minutes-and-seconds', {
        minutes,
        seconds: this.zeroPad(seconds),
      });
    }
    return this.translateService.get('general.time-display.hours-minutes-seconds', {
      hours,
      minutes: this.zeroPad(minutes),
      seconds: this.zeroPad(seconds),
    });
  }

  private zeroPad(num: number): string {
    if (num < 10) {
      return '0' + num.toString();
    }
    return num.toString();
  }
}
