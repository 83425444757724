// Angular
import { registerLocaleData } from '@angular/common';

// locales
import localeBg from '@angular/common/locales/bg';
import localeCs from '@angular/common/locales/cs';
import localeDa from '@angular/common/locales/da';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import localeEn from '@angular/common/locales/en-150';
import localeEs from '@angular/common/locales/es';
import localeFr from '@angular/common/locales/fr';
import localeHu from '@angular/common/locales/hu';
import localeIt from '@angular/common/locales/it';
import localeLt from '@angular/common/locales/lt';
import localeNl from '@angular/common/locales/nl';
import localePl from '@angular/common/locales/pl';
import localePt from '@angular/common/locales/pt';
import localeRo from '@angular/common/locales/ro';
import localeSk from '@angular/common/locales/sk';
import localeSv from '@angular/common/locales/sv';
import localeTr from '@angular/common/locales/tr';

export class CosLocales {
  public static registerLocales() {
    registerLocaleData(localeBg, 'bg');
    registerLocaleData(localeCs, 'cs');
    registerLocaleData(localeDa, 'da');
    registerLocaleData(localeDe, 'de', localeDeExtra);
    registerLocaleData(localeEn, 'en');
    registerLocaleData(localeEs, 'es');
    registerLocaleData(localeFr, 'fr');
    registerLocaleData(localeHu, 'hu');
    registerLocaleData(localeIt, 'it');
    registerLocaleData(localeLt, 'lt');
    registerLocaleData(localeNl, 'nl');
    registerLocaleData(localePl, 'pl');
    registerLocaleData(localePt, 'pt');
    registerLocaleData(localeRo, 'ro');
    registerLocaleData(localeSk, 'sk');
    registerLocaleData(localeSv, 'sv');
    registerLocaleData(localeTr, 'tr');
  }
}
