import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BaseModule } from '@cosCoreComponentsGeneral/base.module';

import { FeeInfoComponent } from './fee-info.component';
import { CrossBorderDiscountBadgeModule } from '@cosBuyer/partials/cross-border-discount-badge/cross-border-discount-badge.module';
import { ExpandablePanelModule } from '@cos/partials/expandable-panel/expandable-panel.module';
import { EnzoComponentsModule } from '@enzo';
import { TaxationInfoModule } from '@cos/partials/taxation-info/taxation-info.module';
import { BuyNowPayLaterServiceModule } from '@cosCoreServices/buy-now-pay-later/buy-now-pay-later-service.module';
import { CurrencyEuroWithDecimalsPipe } from '../utils/currency-euro-with-decimals.pipe';

@NgModule({
  imports: [
    CommonModule,
    BaseModule,
    CrossBorderDiscountBadgeModule,
    ExpandablePanelModule,
    EnzoComponentsModule,
    TaxationInfoModule,
    BuyNowPayLaterServiceModule,
    CurrencyEuroWithDecimalsPipe,
  ],
  declarations: [FeeInfoComponent],
  exports: [FeeInfoComponent],
})
export class FeeInfoModule {}
