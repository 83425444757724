import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import {
  EPrebookedServiceType,
  IBuyerAuctionView,
  IPrebookedService,
  PrebookedServiceBNPLOptionType,
  PrebookedServiceTransportOptionType,
} from '@caronsale/cos-models';
import { IGuaranteeSelectionChange } from '@cosBuyer/partials/prebooked-services/prebooked-guarantee/prebooked-guarantee.component';
import { BuyerTransportationService } from '@cosCoreServices/buyer-transportation/buyer-transportation.service';
import { PrebookedServiceGuaranteeOptionType } from '@caronsale/cos-models/dist/Auction/IPrebookedService';
import { PrebookedServicesUtils } from '@cosUtils/business';

@Component({
  selector: 'app-prebooked-services',
  templateUrl: './prebooked-services.component.html',
  styleUrls: ['./prebooked-services.component.scss'],
})
export class PrebookedServicesComponent implements OnChanges {
  @Input()
  public auction: IBuyerAuctionView;

  @Input()
  public auctionPrice: number;

  @Input()
  public currentPrebookedServices: IPrebookedService[];

  @Input()
  public view: 'CONFIRMATION_MODAL' | 'DETAIL_PAGE' = 'DETAIL_PAGE';

  @Input()
  public isInternalView: boolean = false;

  @Input()
  public displayBuyNowPayLaterDisclaimer: boolean = false;

  @Input()
  public displayPrebookedSevices: boolean = true;

  @Output()
  public serviceSelectionChange: EventEmitter<IPrebookedService[]> = new EventEmitter<IPrebookedService[]>();

  @Output()
  public requireGuaranteeTermsAcceptance: EventEmitter<PrebookedServiceGuaranteeOptionType[]> = new EventEmitter<PrebookedServiceGuaranteeOptionType[]>();

  public hasTransportationService: boolean;
  public hasGuaranteeService: boolean;

  public constructor(private buyerTransportationService: BuyerTransportationService) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.currentPrebookedServices) {
      this.hasTransportationService = PrebookedServicesUtils.getService(this.currentPrebookedServices, EPrebookedServiceType.TRANSPORT)?.isEnabled;
      this.hasGuaranteeService = PrebookedServicesUtils.getService(this.currentPrebookedServices, EPrebookedServiceType.GUARANTEE)?.isEnabled;
    }
  }

  public onTransportSelectionChange(selectedOption: PrebookedServiceTransportOptionType) {
    this.serviceSelectionChange.emit(
      this.buyerTransportationService.selectPrebookedTransportOption(this.auction.uuid, this.currentPrebookedServices, selectedOption),
    );
  }

  public onGuaranteeSelectionChange(guaranteeSelectionChange: IGuaranteeSelectionChange) {
    let newPrebookedServices = this.currentPrebookedServices;
    let optionType: PrebookedServiceGuaranteeOptionType;
    for (optionType in guaranteeSelectionChange.guaranteeOptionValues) {
      if (guaranteeSelectionChange.guaranteeOptionValues[optionType]) {
        newPrebookedServices = PrebookedServicesUtils.selectServiceOption(
          this.auction.uuid,
          newPrebookedServices,
          EPrebookedServiceType.GUARANTEE,
          optionType,
          false,
        );
      } else {
        newPrebookedServices = PrebookedServicesUtils.deselectServiceOption(
          this.auction.uuid,
          newPrebookedServices,
          EPrebookedServiceType.GUARANTEE,
          optionType,
        );
      }
    }
    this.serviceSelectionChange.emit(newPrebookedServices);
    this.requireGuaranteeTermsAcceptance.emit(guaranteeSelectionChange.guaranteeOptionsRequiringAcceptance);
  }

  public onBuyNowPayLaterSelectionChange(selectedOption: PrebookedServiceBNPLOptionType): void {
    const newPrebookedServices = PrebookedServicesUtils.selectServiceOption(
      this.auction.uuid,
      this.currentPrebookedServices,
      EPrebookedServiceType.BUY_NOW_PAY_LATER,
      selectedOption,
      true,
    );
    this.serviceSelectionChange.emit(newPrebookedServices);
  }
}
