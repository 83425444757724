import { Inject, Injectable } from '@angular/core';
import {
  BlacklistedBuyerAccount,
  CommonUtils,
  EAccountAugmentation,
  ECustomerCommunicationChannel,
  EGeneralUserAugmentation,
  EInvoicedArticle,
  EMailTemplate,
  EManagerRelation,
  EUserType,
  IAccount,
  IAccountAddress,
  IAccountFilter,
  IAccountSettingsBuyer,
  IAccountSettingsSeller,
  IAuction,
  IAuctionAccountSellerSettings,
  IAuctionBid,
  IAuctionBidFilter,
  IAuctionExpiryData,
  IAuctionFilter,
  IAuctionMemo,
  IAuctionMistake,
  IAuctionPaymentState,
  IAuctionRecommendationEmailRequest,
  IBalanceAdjustmentValuePayloadParams,
  IBroadcastMailRequest,
  IBuyerBalanceAuctionView,
  IBuyerBalanceRemainingFunds,
  IChargeParams,
  ICustomerBalance,
  ICustomerCommunicationEvent,
  ICustomerMemo,
  ICustomerUser,
  IEmailTemplateSettings,
  IFile,
  IGeneralUser,
  IGeneralUserFilter,
  IGeneralUserSettings,
  IGeneralUserUpdateParams,
  IGPRFilter,
  IGPRFulfillmentResponse,
  IGPRInternalPricingData,
  IGPRInternalView,
  IGuaranteedPriceRequest,
  IInternalAccountView,
  IInternalAuctionBidView,
  IInternalAuctionUpdate,
  IInternalAuctionView,
  IInternalBuyerView,
  IInternalBuyerWatchingAuctionView,
  IInternalPaymentFundsChargeView,
  IInternalSellerAccountView,
  IInternalSellerView,
  IInternalUser,
  IInternalUserFilterRequest,
  IInvoice,
  IKAMSalesmanAuctionAccessView,
  IKAMSalesmanMemoView,
  IKAMTradeInValuationRequestView,
  IKAMTransportationTask,
  IMail,
  IMemoFilterRequest,
  IMobileNotificationRequest,
  INotification,
  IPage,
  IPaymentBalanceTransaction,
  IPaymentFunds,
  IPaymentFundsTransaction,
  IPrebookedService,
  IResourceCountResult,
  ISellerAccount,
  ISellerAccountFilter,
  ISellerAccountVerificationState,
  ISellerBankAccount,
  ISellerBankAccountCreationParams,
  ISellerUser,
  ISellerUserFilter,
  ITradeInValuationRequest,
  ITradeInValuationRequestFilter,
  ITransportationProviderDaySchedule,
  ITransportationProviderTasksPriceList,
  ITransportationTask,
  ITransportationTaskFilterRequest,
  ITransporterUser,
  ITransporterUserFilter,
} from '@caronsale/cos-models';
import { BehaviorSubject, map, Observable, of, throwError } from 'rxjs';
import { AppEnvironment, IAppEnvironment } from '@cosCoreEnvironments/IAppEnvironment';
import { CosCoreClient } from '@cosCoreServices/core-client/cos-core-client.service';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MediaUtils } from '@cosUtils/general';
import { IVehicle, IVehicleI18n, IVehicleValuation } from '@caronsale/cos-vehicle-models';
import { KYCDocumentType } from '@cosCoreServices/cos-salesman-client/types';
import { AuctionUtils } from '@cosUtils/business';

@Injectable()
export class CosInternalClientService {
  public whatsappMessages: BehaviorSubject<ICustomerCommunicationEvent[]> = new BehaviorSubject<ICustomerCommunicationEvent[]>(null);

  public constructor(
    private cosClient: CosCoreClient,
    private translateService: TranslateService,
    @Inject(AppEnvironment) private environment: IAppEnvironment,
    private http: HttpClient,
  ) {}

  public getBalanceForBuyer(buyerUser: IGeneralUser): Observable<ICustomerBalance> {
    return this.cosClient.requestWithPrivileges('get', `/internal/buyer/payment/${buyerUser.uuid}/balance`);
  }

  public getBalanceForAccount(account: IAccount): Observable<ICustomerBalance> {
    return this.cosClient.requestWithPrivileges('get', `/internal/payment/balance/purchase?accountUuid=${account.uuid}`);
  }

  public updateAccountBalanceAdjustmentValue(account: IAccount, params: IBalanceAdjustmentValuePayloadParams): Observable<void> {
    return this.cosClient.requestWithPrivileges('post', `/internal/payment/balance/purchase/manual-offset?accountUuid=${account.uuid}`, params);
  }

  public getRemainingFundsForBuyer(buyerUser: IGeneralUser): Observable<IBuyerBalanceRemainingFunds> {
    return this.cosClient.requestWithPrivileges('get', `/internal/buyer/payment/${buyerUser.uuid}/remaining-funds`);
  }

  public getBalanceHistoryForBuyer(buyer: IGeneralUser, filter: IAuctionFilter): Observable<IPage<IBuyerBalanceAuctionView>> {
    return this.cosClient.requestV2WithPrivileges(
      'get',
      `/internal/buyer/payment/${buyer.uuid}/balance/history?filter=${this.cosClient.encodeParamObject(filter)}`,
    );
  }

  public getPaymentFundsForBuyer(buyerUser: IGeneralUser): Observable<IPaymentFunds> {
    return this.cosClient.requestWithPrivileges('get', `/internal/buyer/payment/${buyerUser.uuid}/funds`);
  }

  public getPaymentFundsTransactionsForBuyer(buyerUser: IGeneralUser): Observable<IPaymentFundsTransaction[]> {
    return this.cosClient.requestWithPrivileges('get', `/internal/buyer/payment/${buyerUser.uuid}/funds/transaction/_all`);
  }

  public getPaymentFundsChargesForBuyer(buyerUser: IGeneralUser): Observable<IInternalPaymentFundsChargeView[]> {
    return this.cosClient.requestWithPrivileges('get', `/internal/buyer/payment/${buyerUser.uuid}/funds/charge/_all`);
  }

  public getBankAccounts(account: IAccount): Observable<ISellerBankAccount[]> {
    return this.cosClient.requestWithPrivileges('get', `/internal/payment/provider/bank-account?accountUuid=${account.uuid}`);
  }

  public triggerAccountVerification(account: IAccount): Observable<Partial<IAccount>> {
    return this.cosClient.requestWithPrivileges('post', `/internal/account/${account.uuid}/verification`);
  }

  public createBankAccount(account: IAccount, creationParams: ISellerBankAccountCreationParams): Observable<ISellerBankAccount> {
    creationParams._fk_uuid_sellerAccount = account.uuid;

    return this.cosClient.requestWithPrivileges('post', `/internal/payment/provider/bank-account`, creationParams);
  }

  public updateBankAccount(bankAccountToUpdate: ISellerBankAccount): Observable<ISellerBankAccount> {
    return this.cosClient.requestWithPrivileges('put', `/internal/payment/provider/bank-account/${bankAccountToUpdate.uuid}`, bankAccountToUpdate);
  }

  public deleteBankAccount(bankAccount: ISellerBankAccount): Observable<void> {
    return this.cosClient.requestWithPrivileges('delete', `/internal/payment/provider/bank-account/${bankAccount.uuid}`);
  }

  /**
   * @deprecated Use {@link getBankAccounts}
   * @param sellerAccount
   */
  public getSellerBankAccountsForAccount(sellerAccount: ISellerAccount | IAccount): Observable<ISellerBankAccount[]> {
    return this.cosClient.requestWithPrivileges('get', `/internal/seller-account/payment/${sellerAccount.uuid}/bank-account`);
  }

  public chargeBuyerForAuction(auction: IAuction, params: IChargeParams): Observable<void> {
    return this.cosClient.requestWithPrivileges('post', `/internal/auction/payment/${auction.uuid}/charge`, params);
  }

  public chargeCosForAuction(auction: IAuction): Observable<void> {
    return this.cosClient.requestWithPrivileges('post', `/internal/auction/payment/${auction.uuid}/charge/cos`, undefined);
  }

  public triggerPayoutToSeller(auction: IAuction): Observable<void> {
    return this.cosClient.requestWithPrivileges('post', `/internal/auction/payment/${auction.uuid}/payout`, undefined);
  }

  public getAuctionPaymentState(auction: IAuction): Observable<IAuctionPaymentState> {
    return this.cosClient.requestWithPrivileges('get', `/internal/auction/payment/${auction.uuid}`);
  }

  public allowBuyNowPayLaterRetry(auctionUuid: string): Observable<IAuctionPaymentState> {
    return this.cosClient.requestWithPrivileges('put', `/internal/auction/payment/${auctionUuid}/allow-buy-now-pay-later-retry`);
  }

  public sendMailFromTemplateAsKam(mail: IMail, recipients: string[], template: EMailTemplate, templateParams?: any): Observable<void> {
    templateParams = templateParams || {};

    return this.cosClient.requestWithPrivileges('post', `/key-account-management/mail/template`, {
      recipients,
      mail,
      template,
      templateParams,
    } as IBroadcastMailRequest);
  }

  public sendSms(phoneNumber: string, smsText: string): Observable<void> {
    return this.cosClient.requestWithPrivileges('post', `/internal/communication/mobile/sms`, {
      recipientPhoneNumbers: [phoneNumber],
      message: smsText,
      template: null,
    } as IMobileNotificationRequest);
  }

  public getTransporterUsersPage(filter: ITransporterUserFilter): Observable<IPage<ITransporterUser>> {
    return this.cosClient.requestV2WithPrivileges('get', `/internal/user-account/transportation-provider?filter=${this.cosClient.encodeParamObject(filter)}`);
  }

  public updateTransporterUserState(transportationProviderUser: ITransporterUser): Observable<void> {
    return this.cosClient.requestWithPrivileges('post', `/key-account-management/transportation-provider/${transportationProviderUser.mailAddress}/state`, {
      // TODO: Introduce model?
      state: transportationProviderUser.state,
    });
  }

  public deleteTransporterUser(user: ITransporterUser): Observable<void> {
    return this.cosClient.requestWithPrivileges('delete', `/key-account-management/transportation-provider/${user.mailAddress}`);
  }

  public updateTransporterUser(userEmail: string, provider: ITransporterUser): Observable<void> {
    return this.cosClient.requestWithPrivileges('post', `/key-account-management/transportation-provider/${userEmail}`, provider);
  }

  public getSellerUser(sellerUuid: string): Observable<IInternalSellerView> {
    return this.cosClient.requestWithPrivileges('get', `/key-account-management/dealership/${sellerUuid}`);
  }

  public updateSellerUser(updateDealershipData: ISellerUser): Observable<void> {
    return this.cosClient.requestWithPrivileges('post', `/key-account-management/dealership/${updateDealershipData.uuid}`, updateDealershipData);
  }

  public getAccessHistoryForAuction(auction: IAuction): Observable<IKAMSalesmanAuctionAccessView[]> {
    return this.cosClient.requestWithPrivileges('get', `/internal/auction/${auction.uuid}/access`);
  }

  public sendMail(mail: IMail, recipients: string[]): Observable<void> {
    return this.cosClient.requestWithPrivileges('post', `/key-account-management/mail`, {
      recipients,
      mail,
    } as IBroadcastMailRequest);
  }

  public getTransportationTasksPage(filter: ITransportationTaskFilterRequest): Observable<IPage<IKAMTransportationTask>> {
    return this.cosClient.requestV2WithPrivileges('get', `/internal/transportation/task?filter=${this.cosClient.encodeParamObject(filter)}`);
  }

  public getTransportationTasksPriceList(taskUuids: string[]): Observable<ITransportationProviderTasksPriceList[]> {
    return this.cosClient.requestWithPrivileges('get', `/internal/transportation/tasks/price-list?taskUuids=${this.cosClient.encodeParamObject(taskUuids)}`);
  }

  public updateTransportationTask(task: ITransportationTask): Observable<void> {
    return this.cosClient.requestWithPrivileges('post', `/internal/transportation/task/${task.uuid}/`, task);
  }

  public requestTransportationPriceForAuction(
    auction: IAuction,
    shouldPersistPrice: boolean = true,
    body: { destinationAddress?: IAccountAddress; originAddress?: IAccountAddress } = {},
  ): Observable<ITransportationTask> {
    return this.cosClient.requestWithPrivileges('put', `/internal/auction/${auction.uuid}/transport/price?shouldPersistPrice=${shouldPersistPrice}`, {
      ...body,
    });
  }

  public rejectTransportationTask(task: IKAMTransportationTask): Observable<void> {
    return this.cosClient.requestWithPrivileges('post', `/internal/transportation/task/${task.uuid}/reject`, task);
  }

  public finishTransportationTask(task: IKAMTransportationTask): Observable<void> {
    return this.cosClient.requestWithPrivileges('post', `/internal/transportation/task/${task.uuid}/finish`, task);
  }

  public getTransportPriceRecalculated(
    transportationTaskId: string,
    shouldPersistPrice: boolean = true,
    body: { destinationAddress?: IAccountAddress; originAddress?: IAccountAddress },
  ): Observable<ITransportationTask> {
    return this.cosClient.requestWithPrivileges(
      'put',
      `/internal/transportation/task/${transportationTaskId}/recalculation?shouldPersistPrice=${shouldPersistPrice}`,
      {
        ...body,
      },
    );
  }

  public rescheduleTransportationTasks(
    taskUuid: string,
    body?: {
      destinationAddress?: IAccountAddress;
      originAddress?: IAccountAddress;
    },
  ): Observable<void> {
    return this.cosClient.requestWithPrivileges('post', `/internal/transportation/task/${taskUuid}/reschedule`, { ...body });
  }

  public getTransportationProviderSchedule(user: ITransporterUser): Observable<ITransportationProviderDaySchedule[]> {
    return this.cosClient.requestWithPrivileges('get', `/internal/transportation/provider/${user.uuid}/schedule`);
  }

  public upsertTransportationProviderSchedule(user: ITransporterUser, daysSchedule: ITransportationProviderDaySchedule[]): Observable<void> {
    return this.cosClient.requestWithPrivileges('post', `/internal/transportation/provider/${user.uuid}/schedule`, daysSchedule);
  }

  public getBuyerUsersPage(filter: IGeneralUserFilter): Observable<IPage<IInternalBuyerView>> {
    return this.cosClient.requestV2WithPrivileges('get', `/internal/user-account/buyer?filter=${this.cosClient.encodeParamObject(filter)}`);
  }

  public resendSalesmanPreregistrationInfoMail(preregisteredBuyerUser: IGeneralUser): Observable<void> {
    const kamUserId: number = this.cosClient.getLastAuthenticationResult().internalUserId;

    return this.cosClient.requestWithPrivileges('post', `/key-account-management/salesman/preregistration/${kamUserId}/${preregisteredBuyerUser.uuid}`, null);
  }

  public getPotentialBuyersBasedOnLocation(auction: IAuction, userUuid?: string): Observable<IInternalBuyerView[]> {
    return this.cosClient.requestWithPrivileges('get', `/internal/target/auction/${auction.uuid}/potential-buyer/_all${userUuid ? '?userId=' + userUuid : ''}`);
  }

  public getPotentialAuctionsBasedOnLocation(buyerUser: IGeneralUser): Observable<IInternalAuctionView[]> {
    return this.cosClient.requestWithPrivileges('get', `/internal/target/salesman/${buyerUser.uuid}/potential-auction/_all`);
  }

  public getPotentialAuctionsBasedOnPreferences(buyerUser: IGeneralUser): Observable<IInternalAuctionView[]> {
    return this.cosClient.requestWithPrivileges('get', `/internal/target/salesman/${buyerUser.uuid}/potential-auction/purchase-preference`);
  }

  public getAuctionMemos(auction: IAuction): Observable<IAuctionMemo[]> {
    return this.cosClient.requestWithPrivileges('get', `/internal/auction/${auction.uuid}/memo`);
  }

  public getDealershipMemos(seller: ISellerUser): Observable<ICustomerMemo[]> {
    return this.cosClient.requestWithPrivileges('get', `/key-account-management/dealership/${seller.uuid}/memo`);
  }

  // TODO: Use IKAMSalesmanMemoView
  public getSalesmanMemosForUser(buyer: IGeneralUser): Observable<ICustomerMemo[]> {
    return this.cosClient.requestWithPrivileges('get', `/key-account-management/salesman/${buyer.uuid}/memo`);
  }

  public getBuyerMemos(filter: IMemoFilterRequest): Observable<IPage<IKAMSalesmanMemoView>> {
    return this.cosClient.requestV2WithPrivileges('get', `/internal/user-account/buyer/memo?filter=${this.cosClient.encodeParamObject(filter)}`);
  }

  public addAuctionMemo(auctionUuid: string, memo: IAuctionMemo): Observable<IAuctionMemo> {
    return this.cosClient.requestWithPrivileges('put', `/internal/auction/${auctionUuid}/memo`, memo);
  }

  public addDealershipMemo(seller: ISellerUser, memo: ICustomerMemo): Observable<void> {
    return this.cosClient.requestWithPrivileges('put', `/key-account-management/dealership/${seller.uuid}/memo`, memo);
  }

  public updateDealershipMemo(seller: ISellerUser, memo: ICustomerMemo): Observable<void> {
    return this.cosClient.requestWithPrivileges('post', `/key-account-management/dealership/${seller.uuid}/memo/${memo.uuid}`, memo);
  }

  public addMemoForBuyerUser(buyerUser: IGeneralUser, memo: ICustomerMemo): Observable<void> {
    return this.cosClient.requestWithPrivileges('put', `/key-account-management/salesman/${buyerUser.uuid}/memo`, memo);
  }

  public updateMemoForBuyer(buyerUser: IGeneralUser, memo: ICustomerMemo): Observable<void> {
    return this.cosClient.requestWithPrivileges('post', `/key-account-management/salesman/${buyerUser.uuid}/memo/${memo.uuid}`, memo);
  }

  public getSellerKeyAccountsFiltered(filter: ISellerUserFilter): Observable<IPage<IInternalSellerView>[]> {
    const encodedParams = this.cosClient.encodeParamObject(filter);

    return this.cosClient.requestV2WithPrivileges('get', `/internal/user-account/seller/my/stats/_all?filter=${encodedParams}`);
  }

  public changePasswordForCustomer(user: ICustomerUser, newPassword: string): Observable<void> {
    const userEndpointPath = {
      [EUserType.SALESMAN]: 'salesman',
      [EUserType.DEALERSHIP]: 'dealership',
      [EUserType.DEALERSHIP_SUPERVISOR]: 'dealership',
      [EUserType.TRANSPORTATION_PROVIDER]: 'transportation-provider',
    }[user.type];

    return this.cosClient.requestWithPrivileges('post', `/key-account-management/${userEndpointPath}/${user.uuid}/password`, {
      currentPassword: null,
      newPassword,
    });
  }

  public getAuctionHistoryForAuction(auction: IAuction): Observable<IInternalAuctionView[]> {
    return this.cosClient.requestWithPrivileges('get', `/internal/auction/${auction.uuid}/previous`);
  }

  public getAuctionsWithSimilarVehicles(auction: IAuction): Observable<IInternalAuctionView[]> {
    return this.cosClient.requestWithPrivileges('get', `/internal/auction/${auction.uuid}/previous/similar-vehicle/_all`);
  }

  public pushBuyerUserNotification(uuid: string, notification: INotification): Observable<void> {
    return this.cosClient.requestWithPrivileges('post', `/internal/target/salesman/${uuid}/user-device/_all/notification`, notification);
  }

  public pushSellerUserNotification(uuid: string, notification: INotification): Observable<void> {
    return this.cosClient.requestWithPrivileges('post', `/internal/target/seller/${uuid}/user-device/_all/notification`, notification);
  }

  public confirmReleaseVehicleForPickup(auction: IAuction): Observable<void> {
    return this.cosClient.requestWithPrivileges('post', `/internal/auction/${auction.uuid}/release`);
  }

  public enableAutomaticReleaseForAuction(auction: IAuction): Observable<void> {
    return this.cosClient.requestWithPrivileges('post', `/internal/auction/${auction.uuid}/enable-automatic-release`);
  }

  public getPaymentTransactionsForSellerUser(sellerUser: ISellerUser): Observable<IPaymentBalanceTransaction[]> {
    if (!sellerUser._fk_uuid_seller_account) {
      return of([]);
    }
    return this.cosClient.requestWithPrivileges('get', `/internal/seller-account/payment/${sellerUser._fk_uuid_seller_account}/transaction/${sellerUser.uuid}`);
  }

  public confirmPickupForAuction(auction: IAuction): Observable<void> {
    return this.cosClient.requestWithPrivileges('post', `/internal/auction/${auction.uuid}/pickup`);
  }

  // TODO: Use Internal User route
  public getSellerUsers(filter: ISellerUserFilter): Observable<IInternalSellerView[]> {
    return this.cosClient.requestWithPrivileges('get', `/key-account-management/dealership?filter=${this.cosClient.encodeParamObject(filter)}`);
  }

  public getSellerUsersCount(filter: ISellerUserFilter): Observable<IResourceCountResult> {
    return this.cosClient.requestWithPrivileges('get', `/key-account-management/dealership/_count?filter=${this.cosClient.encodeParamObject(filter)}`);
  }

  public getAuctionsPage(filter: IAuctionFilter): Observable<IPage<IInternalAuctionView>> {
    filter = {
      ...filter,
      states: Object.values(filter.states ?? {}).some(Boolean) ? filter.states : null,
    };

    return this.cosClient.requestV2WithPrivileges('get', `/internal/auction?filter=${this.cosClient.encodeParamObject(filter)}`);
  }

  public getAuctions(filter: IAuctionFilter): Observable<IInternalAuctionView[]> {
    return this.cosClient.requestWithPrivileges('get', `/internal/auction/_all?filter=${this.cosClient.encodeParamObject(filter)}`);
  }

  public getAuctionBids(filter: IAuctionBidFilter): Observable<IInternalAuctionBidView[]> {
    return this.cosClient.requestWithPrivileges('get', `/internal/auction/_all/bids?filter=${this.cosClient.encodeParamObject(filter)}`);
  }

  public getAuctionTranslated(auctionUuid: string): Observable<IInternalAuctionView> {
    const language = this.translateService.currentLang;
    return this.cosClient.requestWithPrivileges('get', `/internal/auction/${auctionUuid}?language=${language}`);
  }

  public getAuction(auctionUuid: string): Observable<IInternalAuctionView> {
    return this.cosClient.requestWithPrivileges('get', `/internal/auction/${auctionUuid}`);
  }

  public getAuctionTranslations(auctionUuid: string): Observable<IVehicleI18n[]> {
    return this.cosClient.requestWithPrivileges('get', `/internal/auction/${auctionUuid}/vehicle/translations`);
  }

  public updateAuctionTranslations(auctionUuid: string, translations: IVehicleI18n[]): Observable<void> {
    return this.cosClient.requestWithPrivileges('put', `/internal/auction/${auctionUuid}/vehicle/translations`, translations);
  }

  public createVehicleSummarySheet(auction: IAuction): Observable<void> {
    return this.cosClient.requestWithPrivileges('post', `/internal/auction/${auction.uuid}/summary`, undefined);
  }

  public regenerateInspectionReport(auctionUuid: string): Observable<IVehicle> {
    return this.cosClient.requestWithPrivileges('put', `/internal/auction/${auctionUuid}/report`, undefined);
  }

  public createBuyerPurchaseOverviewDocument(auction: IAuction): Observable<void> {
    return this.cosClient.requestWithPrivileges('post', `/internal/auction/${auction.uuid}/buyer-purchase-overview`, undefined);
  }

  public getBidsForAuction(auctionUuid: string): Observable<IInternalAuctionBidView[]> {
    return this.cosClient.requestWithPrivileges('get', `/internal/auction/${auctionUuid}/bids`);
  }

  public getVehicleDataFromVin(vin: string): Observable<IVehicle> {
    return this.cosClient.requestWithPrivileges('get', `/internal/vehicle/${vin}`);
  }

  public refreshVehicleDataFromVin(vin: string): Observable<IVehicle> {
    return this.cosClient.requestWithPrivileges('get', `/internal/vehicle/${vin}/refresh`);
  }

  public getVehicleValuationFromVin(vin: string, vehicle: IVehicle): Observable<IVehicleValuation> {
    return this.cosClient.requestWithPrivileges('post', `/internal/vehicle/${vin}/valuation`, {
      ...vehicle,
      vehicleImages: [],
      attachments: [],
    } as IVehicle);
  }

  public uploadKYCDocument(file: IFile, accountUUID: string, documentType: KYCDocumentType, origin?: string): Observable<IFile> {
    const actualFileName = file.fileName || file['name'];

    file['name'] = actualFileName;
    file.fileName = actualFileName;

    return this.cosClient
      .requestWithPrivileges('put', `/media/document/kyc`, {
        file,
        accountUUID,
        documentType,
        origin,
      })
      .pipe(
        map(res => {
          return {
            mimeType: null,
            rawData: null,
            encoding: null,
            url: res.url,
          } as IFile;
        }),
      );
  }

  public deleteKYCDocument(documentURL: string): Observable<{ message: string }> {
    // TODO: Note that delete with body and headers is not working
    const newParams = new URLSearchParams({ documentURL });
    const paramsString = newParams.toString();

    return this.cosClient.requestWithPrivileges('delete', `/media/document/kyc/?${paramsString}`).pipe(map(res => ({ message: res?.message })));
  }

  public createAuctionForSellerUser(sellerUserId: string, auctionToCreate: IAuction): Observable<IAuction> {
    return this.cosClient.requestWithPrivileges(
      'put',
      `/internal/auction/dealership/${sellerUserId}`,
      auctionToCreate,
      this.cosClient.generateIdempotencyHeader(),
    );
  }

  public getUserProfile(): Observable<IInternalUser> {
    const uuid: string = this.cosClient.getLastAuthenticationResult().internalUserUUID;

    return this.cosClient.requestWithPrivileges('get', `/profile/internal/${uuid}`);
  }

  public getTradeInValuationRequestsPage(filter: ITradeInValuationRequestFilter): Observable<IPage<IKAMTradeInValuationRequestView>> {
    return this.cosClient.requestV2WithPrivileges('get', `/internal/trade-in/valuation?filter=${this.cosClient.encodeParamObject(filter)}`);
  }

  public getGprsPage(filter: IGPRFilter): Observable<IPage<IGPRInternalView>> {
    return this.cosClient.requestWithPrivileges('get', `/internal/gprs?filter=${this.cosClient.encodeParamObject(filter)}`);
  }

  public getGprPricingData(gprUuid: string): Observable<IGPRInternalPricingData> {
    const userUUID = this.cosClient.getLastAuthenticationResult().internalUserUUID;

    if (!userUUID) {
      return throwError({ status: 401 });
    }
    return this.cosClient.requestWithPrivileges('get', `/internal/gprs/${gprUuid}/pricing-data`);
  }

  /**
   * Set a price suggestion or fulfill a GPR.
   */
  public updateOrFulfillGpr(gprUuid: string, gprDashboardData: IGPRFulfillmentResponse): Observable<IGuaranteedPriceRequest> {
    return this.cosClient.requestWithPrivileges('post', `/internal/gprs/${gprUuid}/fulfill`, gprDashboardData);
  }

  /**
   * Reject a GPR.
   */
  public rejectGpr(gprUuid: string, gprDashboardData: IGPRFulfillmentResponse): Observable<IGuaranteedPriceRequest> {
    return this.cosClient.requestWithPrivileges('post', `/internal/gprs/${gprUuid}/reject`, gprDashboardData);
  }

  /**
   * Book a guarantee price and request inspection
   */
  public bookGprAndRequestInspection(gprUuid: string): Observable<IGuaranteedPriceRequest> {
    return this.cosClient.requestWithPrivileges('post', `/internal/gprs/${gprUuid}/book-inspection`);
  }

  public updateTradeInValuationRequest(request: ITradeInValuationRequest): Observable<void> {
    return this.cosClient.requestWithPrivileges('post', `/internal/trade-in/valuation/${request.uuid}`, request);
  }

  public setGPRValue(gprUuid: string, guaranteedPrice: number, calculatedFee: number): Observable<any> {
    return this.cosClient.requestWithPrivileges('put', `/application/gpr/${gprUuid}`, {
      fulfillingInternalUserUuid: this.cosClient.getLastAuthenticationResult().internalUserUUID,
      guaranteedPrice,
      calculatedFee,
    });
  }

  public updateAuction(auction: IInternalAuctionUpdate): Observable<void> {
    AuctionUtils.syncVatReportable(auction);
    return this.cosClient.requestWithPrivileges('post', `/internal/auction/${auction.uuid}`, auction);
  }

  public getCommunicationHistory(buyerUser: IGeneralUser): Observable<any[]> {
    return this.cosClient.requestWithPrivileges('get', `/internal/communication/salesman/${buyerUser.uuid}/history`);
  }

  public sendWhatsApp(buyerUser: IGeneralUser, message: IMobileNotificationRequest): Observable<void> {
    return this.cosClient.requestWithPrivileges('post', `/internal/communication/salesman/${buyerUser.uuid}/whatsapp`, message);
  }

  public consumeWhatsAppCommunicationHistory(buyerUser: IGeneralUser): Observable<any[]> {
    return this.cosClient.requestWithPrivileges(
      'delete',
      `/internal/communication/salesman/${buyerUser.uuid}/history/channel/${ECustomerCommunicationChannel.WHATSAPP}`,
    );
  }

  public updateLastBid(auction: IAuction, lastBid: IAuctionBid): Observable<void> {
    return this.cosClient.requestWithPrivileges('post', `/internal/auction/${auction.uuid}/bids/last`, lastBid);
  }

  public deleteBid(auctionUuid: string, bidUuid: string): Observable<void> {
    return this.cosClient.requestWithPrivileges('delete', `/internal/auction/${auctionUuid}/bids/${bidUuid}`);
  }

  public approveAuctionReview(auctionUuid: string, comment: string, notify: boolean = false, notifyManager: boolean = false): Observable<void> {
    return this.cosClient.requestWithPrivileges('put', `/internal/auction/${auctionUuid}/review/approve?notify=${notify}&notifyManager=${notifyManager}`, {
      reviewComment: comment,
    });
  }

  public rejectAuctionReview(auctionUuid: string, comment: string, notify: boolean = false, notifyManager: boolean = false): Observable<void> {
    return this.cosClient.requestWithPrivileges('put', `/internal/auction/${auctionUuid}/review/reject?notify=${notify}&notifyManager=${notifyManager}`, {
      reviewComment: comment,
    });
  }

  public getParkingLotAuctionList(buyerUser: IGeneralUser): Observable<IInternalAuctionView[]> {
    return this.cosClient.requestWithPrivileges('get', `/internal/auction/parking-lot/${buyerUser.uuid}`);
  }

  public acceptSepaMandateForSeller(sellerAccount: ISellerAccount, iban: string): Observable<void> {
    return this.cosClient.requestWithPrivileges(`post`, `/internal/seller-account/payment/${sellerAccount.uuid}/direct-debit/approval`, {
      iban,
    });
  }

  public getMyAuctions(filter: IAuctionFilter, managerRelation = EManagerRelation.FIELD_SALES_MANAGER): Observable<IPage<IInternalAuctionView>> {
    const encodedParams = this.cosClient.encodeParamObject(filter);

    return this.cosClient.requestV2WithPrivileges('get', `/internal/auction/my?filter=${encodedParams}&managerRelation=${managerRelation}`);
  }

  public getAccount(accountUUID: string, augmentations?: EAccountAugmentation[]): Observable<IInternalAccountView> {
    let requestPath = `/internal/account/${accountUUID}`;

    if (augmentations?.length) {
      const encodedAugmentations = encodeURIComponent(augmentations.join(','));
      requestPath += `?augment=${encodedAugmentations}`;
    }

    return this.cosClient.requestWithPrivileges('get', requestPath);
  }

  public getAccountMemos(accountUUID: string): Observable<ICustomerMemo[]> {
    return this.cosClient.requestWithPrivileges('get', `/internal/account/${accountUUID}/memos`);
  }

  public addMemoForAccount(accountUUID: string, memo: ICustomerMemo): Observable<void> {
    return this.cosClient.requestWithPrivileges('post', `/internal/account/${accountUUID}/memos`, memo);
  }

  public getAccounts(filter: IAccountFilter, augmentations?: EAccountAugmentation[]): Observable<IPage<IInternalAccountView>> {
    const encodedFilter = this.cosClient.encodeParamObject(filter);
    let requestPath = `/internal/account/?filter=${encodedFilter}`;
    if (augmentations?.length) {
      const encodedAugmentations = encodeURIComponent(augmentations.join(','));
      requestPath += `&augment=${encodedAugmentations}`;
    }
    return this.cosClient.requestWithPrivileges('get', requestPath, null, null);
  }

  public getBlacklistedBuyerAccounts(uuid: string): Observable<BlacklistedBuyerAccount[]> {
    return this.cosClient
      .requestWithPrivileges('get', `/internal/auction/account-settings/seller/${uuid}`)
      .pipe(map((res: IAuctionAccountSellerSettings) => res.blacklistedBuyerAccounts));
  }

  public updateBlacklistedBuyerAccounts(sellerAccountUuid: string, blacklistedBuyerAccounts: BlacklistedBuyerAccount[]): Observable<void> {
    const payload: IAuctionAccountSellerSettings = {
      blacklistedBuyerAccounts,
    };
    return this.cosClient.requestWithPrivileges('put', `/internal/auction/account-settings/seller/${sellerAccountUuid}`, payload);
  }

  public countAccounts(filter: IAccountFilter, augmentations?: EAccountAugmentation[]): Observable<IResourceCountResult> {
    const encodedFilter = this.cosClient.encodeParamObject(filter);
    let requestPath = `/internal/account/count?filter=${encodedFilter}`;
    if (augmentations?.length) {
      const encodedAugmentations = encodeURIComponent(augmentations.join(','));
      requestPath += `&augment=${encodedAugmentations}`;
    }
    return this.cosClient.requestWithPrivileges('get', requestPath, null, null);
  }

  public updateAccount(account: IAccount): Observable<IAccount> {
    return this.cosClient.requestWithPrivileges('put', `/internal/account/${account.uuid}`, account);
  }

  public deleteAccount(account: IAccount): Observable<void> {
    return this.cosClient.requestWithPrivileges('delete', `/internal/account/${account.uuid}`);
  }

  public getAccountSettingsSeller(accountUUID: string): Observable<IAccountSettingsSeller> {
    return this.cosClient.requestWithPrivileges('get', `/internal/account/${accountUUID}/settings/seller`);
  }

  public createAccountSettingsSeller(account: IAccount, accountSettingsSeller: IAccountSettingsSeller): Observable<IAccountSettingsSeller> {
    return this.cosClient.requestWithPrivileges('post', `/internal/account/${account.uuid}/settings/seller`, accountSettingsSeller);
  }

  public updateAccountSettingsSeller(accountSettingsSeller: IAccountSettingsSeller): Observable<IAccountSettingsSeller> {
    return this.cosClient.requestWithPrivileges('put', `/internal/account/${accountSettingsSeller._fk_uuid_account}/settings/seller`, accountSettingsSeller);
  }

  public getAccountSettingsBuyer(accountUUID: string): Observable<IAccountSettingsBuyer> {
    return this.cosClient.requestWithPrivileges('get', `/internal/account/${accountUUID}/settings/buyer`);
  }

  public createAccountSettingsBuyer(account: IAccount, accountSettingsBuyer: IAccountSettingsBuyer): Observable<IAccountSettingsBuyer> {
    return this.cosClient.requestWithPrivileges('post', `/internal/account/${account.uuid}/settings/buyer`, accountSettingsBuyer);
  }

  public updateAccountSettingsBuyer(accountSettingsBuyer: IAccountSettingsBuyer): Observable<IAccountSettingsBuyer> {
    return this.cosClient.requestWithPrivileges('put', `/internal/account/${accountSettingsBuyer._fk_uuid_account}/settings/buyer`, accountSettingsBuyer);
  }

  public getSellerAccounts(filter: ISellerAccountFilter): Observable<IPage<IInternalSellerAccountView>> {
    const encodedParams = this.cosClient.encodeParamObject(filter);
    return this.cosClient.requestV2WithPrivileges('get', `/internal/seller-account/_all?filter=${encodedParams}`);
  }

  public getSellerAccount(sellerAccountUUID: string): Observable<IInternalSellerAccountView> {
    return this.cosClient.requestWithPrivileges('get', `/internal/seller-account/${sellerAccountUUID}`);
  }

  public assignAccountParent(child: IAccount, parent: IAccount): Observable<void> {
    return this.cosClient.requestWithPrivileges('put', `/internal/account/${parent.uuid}/child/${child.uuid}`);
  }

  public unAssignAccountParent(child: IAccount, parent: IAccount): Observable<void> {
    return this.cosClient.requestWithPrivileges('delete', `/internal/account/${parent.uuid}/child/${child.uuid}`);
  }

  public getUsersForAccount(account: IAccount): Observable<ICustomerUser[]> {
    return this.cosClient.requestWithPrivileges('get', `/internal/account/${account.uuid}/user`);
  }

  public assignUserToAccount(account: IAccount, user: ICustomerUser): Observable<void> {
    return this.cosClient.requestWithPrivileges('put', `/internal/account/${account.uuid}/user/${user.uuid}`);
  }

  public getAccountKycData(account: IAccount): Observable<ISellerAccountVerificationState> {
    return this.cosClient.requestWithPrivileges('get', `/internal/payment/provider/kyc-state?accountUuid=${account.uuid}`);
  }

  public getRegularBuyersForAccount(account: IAccount): Observable<IGeneralUser[]> {
    return this.cosClient.requestWithPrivileges('get', `/internal/account/${account.uuid}/settings/generaluser/regular-buyer`);
  }

  public addRegularBuyerForAccount(account: IAccount, buyerUser: IGeneralUser): Observable<void> {
    return this.cosClient.requestWithPrivileges('put', `/internal/account/${account.uuid}/settings/generaluser/regular-buyer/${buyerUser.uuid}`);
  }

  public removeRegularBuyerForAccount(account: IAccount, buyerUser: IGeneralUser): Observable<void> {
    return this.cosClient.requestWithPrivileges('delete', `/internal/account/${account.uuid}/settings/generaluser/regular-buyer/${buyerUser.uuid}`);
  }

  public resendSuccessfulAuctionMailToBuyer(auction: IAuction): Observable<void> {
    return this.cosClient.requestWithPrivileges('post', `/internal/auction/${auction.uuid}/mail/buyer/successful-auction`);
  }

  public resendSuccessfulAuctionMailToSeller(auction: IAuction): Observable<void> {
    return this.cosClient.requestWithPrivileges('post', `/internal/auction/${auction.uuid}/mail/seller/successful-auction`);
  }

  public regenerateInvoice(invoiceType: EInvoicedArticle, auction: IAuction): Observable<void> {
    return this.cosClient.requestWithPrivileges('post', `/internal/auction/${auction.uuid}/invoice-types/${invoiceType}/regeneration`);
  }

  public regeneratePickupDocuments(auction: IAuction): Observable<void> {
    return this.cosClient.requestWithPrivileges('put', `/internal/auction/${auction.uuid}/pickup-docs`);
  }

  public regenerateSellerSuccessReport(auction: IAuction): Observable<void> {
    return this.cosClient.requestWithPrivileges('post', `/internal/auction/${auction.uuid}/seller-success-report/regeneration`);
  }

  public refundTaxDeposit(auction: IAuction): Observable<void> {
    return this.cosClient.requestWithPrivileges('post', `/internal/auction/payment/${auction.uuid}/deposit/refund`);
  }

  public publishDraftedAuction(auction: IAuction): Observable<void> {
    return this.cosClient.requestWithPrivileges('post', `/internal/auction/${auction.uuid}/publish`);
  }

  public sendRecommendedAuctionsEmails(buyerUUID: string, recommendedAuctionsEmailRequest: IAuctionRecommendationEmailRequest): Observable<void> {
    return this.cosClient.requestWithPrivileges('post', `/internal/target/salesman/${buyerUUID}/auction-recommendation-email`, recommendedAuctionsEmailRequest);
  }

  public getPotentialAuctionsForBuyerBasedOnScore(buyerUUID: string, filter?: IAuctionFilter): Observable<IPage<IInternalAuctionView>> {
    if (!buyerUUID) {
      return throwError({ status: 401 });
    }

    return this.cosClient.requestWithPrivileges(
      'get',
      `/internal/target/salesman/${buyerUUID}/potential-auction/score` + (filter ? `?filter=${this.cosClient.encodeParamObject(filter)}` : ``),
    );
  }

  public geRunningAuctionsForBuyer(buyerUUID: string, filter?: IAuctionFilter): Observable<IPage<IInternalAuctionView>> {
    if (!buyerUUID) {
      return throwError({ status: 401 });
    }

    return this.cosClient.requestWithPrivileges(
      'get',
      `/internal/user/${buyerUUID}/running-auctions` + (filter ? `?filter=${this.cosClient.encodeParamObject(filter)}` : ``),
    );
  }

  public getInternalUser(internalUserUuid: string): Observable<IInternalUser> {
    if (!internalUserUuid) {
      return throwError({ status: 401 });
    }

    return this.cosClient.requestWithPrivileges('get', `/internal/internal-users/${internalUserUuid}`);
  }

  public getAvailableDatesForAuctions(): Observable<IAuctionExpiryData> {
    return this.cosClient.requestWithPrivileges('get', `/internal/auction/expiration-days/available`);
  }

  public generateAndSendSellerListingReport(sellerUser: ISellerUser, date?: Date): Observable<void> {
    const url: string = date ? `/internal/seller-report/${sellerUser.uuid}?date=${date.toISOString()}` : `/internal/seller-report/${sellerUser.uuid}`;
    return this.cosClient.requestWithPrivileges('post', url, {});
  }

  public createAuctionMistake(mistake: IAuctionMistake): Observable<void> {
    return this.cosClient.requestWithPrivileges('post', `/internal/auction/${mistake._fk_uuid_auction}/mistake`, mistake);
  }

  public getInvoicesForAuction(auction: IAuction): Observable<IInvoice[]> {
    return this.cosClient.requestWithPrivileges('get', `/internal/auction/${auction.uuid}/invoices`);
  }

  public uploadSellerToCosInvoiceForAuction(auction: IAuction, invoiceFile: IFile, comment: string, isValid: boolean): Observable<void> {
    return this.cosClient.requestWithPrivileges(
      'post',
      `/internal/auction/${auction.uuid}/seller-to-cos/invoice?isValid=${isValid}&comment=${CommonUtils.encodeParamObject(comment)}`,
      invoiceFile,
    );
  }

  public updateAuctionCoordinates(auction: IAuction): Observable<void> {
    return this.cosClient.requestWithPrivileges('put', `/internal/auction/${auction.uuid}/coordinates`, null);
  }

  public getInternalUsers(filter: IInternalUserFilterRequest): Observable<IInternalUser[]> {
    return this.cosClient.requestWithPrivileges('get', `/internal/internal-users/?filter=${this.cosClient.encodeParamObject(filter)}`);
  }

  public getAuctionPrebookedServicesForBuyer(buyerUserUuid: string, auctionUuid: string): Observable<IPrebookedService[]> {
    return this.cosClient.requestWithPrivileges('get', `/internal/auction/${auctionUuid}/prebooked-services/${buyerUserUuid}`);
  }

  public getUser(userUUID: string, augmentations?: EGeneralUserAugmentation[]): Observable<IGeneralUser> {
    let requestPath = `/internal/user/${userUUID}`;
    if (augmentations?.length) {
      const encodedAugmentations = encodeURIComponent(augmentations.join(','));
      requestPath += `?augment=${encodedAugmentations}`;
    }
    return this.cosClient.requestWithPrivileges('get', requestPath);
  }

  public getUserSettings(userUUID: string): Observable<IGeneralUserSettings> {
    return this.cosClient.requestWithPrivileges('get', `/internal/user/${userUUID}/settings`);
  }

  public updateUserSettings(userUUID: string, settings: Partial<IGeneralUserSettings>): Observable<IGeneralUserSettings> {
    return this.cosClient.requestWithPrivileges('put', `/internal/user/${userUUID}/settings`, settings);
  }

  public getUsers(filter: IGeneralUserFilter, augmentations?: EGeneralUserAugmentation[]): Observable<IPage<IGeneralUser>> {
    const encodedFilter = this.cosClient.encodeParamObject(filter);
    let requestPath = `/internal/user/?filter=${encodedFilter}`;
    if (augmentations?.length) {
      const encodedAugmentations = encodeURIComponent(augmentations.join(','));
      requestPath += `&augment=${encodedAugmentations}`;
    }
    return this.cosClient.requestWithPrivileges('get', requestPath, null, null);
  }

  public updateUser(data: IGeneralUserUpdateParams): Observable<IGeneralUser> {
    return this.cosClient.requestWithPrivileges('put', `/internal/user/${data.user.uuid}`, data);
  }

  public changePasswordForUser(user: IGeneralUser, newPassword: string): Observable<void> {
    return this.cosClient.requestWithPrivileges('post', `/internal/user/${user.uuid}/password`, {
      currentPassword: null,
      newPassword,
    });
  }

  public getMemosForUser(user: IGeneralUser): Observable<ICustomerMemo[]> {
    return this.cosClient.requestWithPrivileges('get', `/internal/user/${user.uuid}/memos`);
  }

  public addMemoForUser(user: IGeneralUser, memo: ICustomerMemo): Observable<void> {
    return this.cosClient.requestWithPrivileges('post', `/internal/user/${user.uuid}/memos`, memo);
  }

  public logTwoFactorStatusUpdate(userUuid: string, event: string): Observable<void> {
    return this.cosClient.requestWithPrivileges('put', `/internal/user/${userUuid}/two-factor-auth/log/${event}`);
  }

  public downloadPickupDocument(auction: IInternalAuctionView, isSellerSide: boolean): void {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/pdf').set('Cache-control', 'no-store');
    this.http
      .get(isSellerSide ? `${auction.urlToPickupSellerDocument}?v=${Date.now()}` : `${auction.urlToPickupBuyerDocument}?v=${Date.now()}`, {
        headers,
        responseType: 'blob',
      })
      .subscribe((blob: Blob): void => {
        MediaUtils.downloadPDF(blob, `self-pickup-document-${auction.associatedVehicle.vin}.pdf`);
      });
  }

  public getEmailSettings(): Observable<IEmailTemplateSettings[]> {
    return this.cosClient.requestWithPrivileges('get', '/admin/email/settings');
  }

  public saveEmailSettings(settings: IEmailTemplateSettings): Observable<IEmailTemplateSettings> {
    return this.cosClient.requestWithPrivileges(settings.uuid ? 'put' : 'post', `/admin/email/settings/${settings.uuid ?? ''}`, settings);
  }

  public getParkingLotUsers(auctionId: string): Observable<IInternalBuyerWatchingAuctionView[]> {
    return this.cosClient.requestWithPrivileges('get', `/internal/auction/parking-lot/users/${auctionId}`);
  }

  public recreateShippingOrder(auctionId: string, pickupDate: string): Observable<void> {
    return this.cosClient.requestWithPrivileges('post', `/internal/document-shipping/auction/${auctionId}/recreate-document-shipping-order`, {
      date: pickupDate,
    });
  }
}
