import { Component, Input, OnInit } from '@angular/core';
import { ECountryCode } from '@caronsale/cos-models';
import { COS_CONSTANTS } from '@cosCoreServices/constants/cos-constants.service';

export interface ITaxationInfo {
  sellerCountry: ECountryCode;
  buyerCountry: ECountryCode;
  vatIdReportable: boolean;
  isCrossBorderNetSale: boolean;
}

/**
 * Show the type of taxation (regular or differential, listing the applicable paragraph of the seller country's taxation law)
 * Add a note with an info icon if it is a cross-border net sale.
 * The parameters are given via a parameter object instead of individual input values
 */
@Component({
  selector: 'app-taxation-info',
  templateUrl: './taxation-info.component.html',
  styleUrls: ['./taxation-info.component.scss'],
})
export class TaxationInfoComponent implements OnInit {
  @Input()
  public taxationInfo: ITaxationInfo;

  @Input()
  public isRenegotiationCard: boolean;

  public securityDepositKey: string;
  public differentialTaxationKey: string;
  public articleNumber: string;

  public constructor(private constants: COS_CONSTANTS) {}

  public ngOnInit(): void {
    if (this.taxationInfo.buyerCountry === ECountryCode.AT && this.taxationInfo.vatIdReportable) {
      this.securityDepositKey = 'auction.cross-border-net-sale.security-deposit.at';
    } else {
      this.securityDepositKey = 'auction.cross-border-net-sale.security-deposit.de';
    }
    this.differentialTaxationKey = 'auction.vat-taxation-info-for-country.differential.' + this.taxationInfo.sellerCountry.toLowerCase();
    this.articleNumber = this.constants.getDifferentialTaxArticleNumber(this.taxationInfo.sellerCountry);
  }
}
