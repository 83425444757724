import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { LogoOptions } from '@caronsale/enzo';

@Component({
  selector: 'enzo-logo',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: '<ng-content />',
  standalone: true,
  host: {
    '[options]': 'options()',
  },
})
export class EnzoLogoComponent {
  public options = input<LogoOptions>({ kind: 'primary', size: '28' });
}
