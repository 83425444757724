<ng-container *ngIf="taxationInfo && !isRenegotiationCard">
  <span>
    {{ taxationInfo.vatIdReportable | i18nVatTaxation: taxationInfo.sellerCountry | translate: { articleNr: articleNumber } }}
    <enzo-icon *ngIf="!taxationInfo.vatIdReportable" size="small" icon="info-outline" color="purple" [popoverText]="differentialTaxationKey | translate" />
  </span>
  <span *ngIf="taxationInfo.isCrossBorderNetSale" class="cross-border-message" testid="cross-border-text">
    ({{ 'auction.cross-border-net-sale.info-label' | translate }})
    <enzo-icon size="small" icon="info-outline" color="purple" popoverWidth="450px" [popoverTemplate]="crossBorderNetSalePopover" testid="cross-border-icon" />
  </span>
</ng-container>
<ng-template #crossBorderNetSalePopover>
  <enzo-text kind="emphasis-label">{{ 'auction.cross-border-net-sale.info-label' | translate }}</enzo-text>
  <enzo-text kind="label">{{ 'auction.cross-border-net-sale.info-content' | translate: { securityDeposit: (securityDepositKey | translate) } }}</enzo-text>
</ng-template>

<ng-container *ngIf="taxationInfo && isRenegotiationCard">
  <span class="renegotiation-view">
    <enzo-icon *ngIf="!taxationInfo.vatIdReportable" size="small" icon="info-outline" color="purple" [popoverTemplate]="popoverDR" />
    {{ 'auction.renegotiation.extra-fees' | translate }}
  </span>
</ng-container>
<ng-template #popoverDR>
  <enzo-text kind="label">{{ differentialTaxationKey | translate }}</enzo-text>
  <enzo-text *ngIf="taxationInfo.isCrossBorderNetSale" kind="label">
    {{ 'auction.cross-border-net-sale.info-content' | translate: { securityDeposit: (securityDepositKey | translate) } }}
  </enzo-text>
</ng-template>
