import { APP_INITIALIZER, NgModule } from '@angular/core';
import { defineCustomElements } from '@caronsale/enzo/loader';
import { ShowRequiredDirective } from './show-required.directive';
import { EnzoBadgeComponent } from './components/miscellaneous/enzo-badge/enzo-badge.component';
import { EnzoBannerComponent } from './components/miscellaneous/enzo-banner/enzo-banner.component';
import { EnzoButtonComponent } from './components/buttons/enzo-button/enzo-button.component';
import { EnzoCheckboxComponent } from './components/form-elements/enzo-checkbox-toggle/enzo-checkbox.component';
import { EnzoContextComponent } from './components/miscellaneous/enzo-context/enzo-context.component';
import { EnzoFileUploadComponent } from './components/form-elements/enzo-file-upload/enzo-file-upload.component';
import { EnzoFlagComponent } from './components/iconography/enzo-flag/enzo-flag.component';
import { EnzoFloatingIconButtonComponent } from './components/buttons/enzo-floating-icon-button/enzo-floating-icon-button.component';
import { EnzoGhostButtonComponent } from './components/buttons/enzo-ghost-button/enzo-ghost-button.component';
import { EnzoHeaderComponent } from './components/structural/enzo-header/enzo-header.component';
import { EnzoIconComponent } from './components/iconography/enzo-icon/enzo-icon.component';
import { EnzoHeadlineComponent } from './components/typography/enzo-headline/enzo-headline.component';
import { EnzoLanguageSelectComponent } from './components/structural/enzo-header/enzo-language-select/enzo-language-select.component';
import { EnzoLoaderComponent } from './components/miscellaneous/enzo-loader/enzo-loader.component';
import { EnzoLogoComponent } from './components/iconography/enzo-logo/enzo-logo.component';
import { EnzoRadioButtonComponent } from './components/form-elements/enzo-radio-button/enzo-radio-button.component';
import { EnzoRadioButtonGroupComponent } from './components/form-elements/enzo-radio-button-group/enzo-radio-button-group.component';
import { EnzoSelectComponent } from './components/form-elements/enzo-select/enzo-select.component';
import { EnzoTagComponent } from './components/miscellaneous/enzo-tag/enzo-tag.component';
import { EnzoTextComponent } from './components/typography/enzo-text/enzo-text.component';
import { EnzoTextfieldComponent } from './components/form-elements/enzo-textfield/enzo-textfield.component';
import { EnzoToggleComponent } from './components/form-elements/enzo-checkbox-toggle/enzo-toggle.component';

@NgModule({
  imports: [
    EnzoBadgeComponent,
    EnzoBannerComponent,
    EnzoButtonComponent,
    EnzoCheckboxComponent,
    EnzoContextComponent,
    EnzoFileUploadComponent,
    EnzoFlagComponent,
    EnzoFloatingIconButtonComponent,
    EnzoGhostButtonComponent,
    EnzoHeaderComponent,
    EnzoIconComponent,
    EnzoHeadlineComponent,
    EnzoLanguageSelectComponent,
    EnzoLoaderComponent,
    EnzoLogoComponent,
    EnzoRadioButtonComponent,
    EnzoRadioButtonGroupComponent,
    EnzoSelectComponent,
    EnzoTagComponent,
    EnzoTextComponent,
    EnzoTextfieldComponent,
    EnzoToggleComponent,
    ShowRequiredDirective,
  ],
  exports: [
    EnzoBadgeComponent,
    EnzoBannerComponent,
    EnzoButtonComponent,
    EnzoCheckboxComponent,
    EnzoContextComponent,
    EnzoFileUploadComponent,
    EnzoFlagComponent,
    EnzoFloatingIconButtonComponent,
    EnzoGhostButtonComponent,
    EnzoHeaderComponent,
    EnzoIconComponent,
    EnzoHeadlineComponent,
    EnzoLanguageSelectComponent,
    EnzoLoaderComponent,
    EnzoLogoComponent,
    EnzoRadioButtonComponent,
    EnzoRadioButtonGroupComponent,
    EnzoSelectComponent,
    EnzoTagComponent,
    EnzoTextComponent,
    EnzoTextfieldComponent,
    EnzoToggleComponent,
    ShowRequiredDirective,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: () => defineCustomElements,
      multi: true,
    },
  ],
})
export class EnzoComponentsModule {}
