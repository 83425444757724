import { booleanAttribute, Component, computed, ElementRef, input, model, output, Signal, viewChild } from '@angular/core';
import { EnzoBaseValueAccessor } from '../enzo-base-value-accessor';

const FORCE_UPDATE_EVEN_IF_DISABLED = true;
const DO_NOT_UPDATE_WHEN_DISABLED = false;
const PREVENT_ANIMATION = true;
const SHOW_ANIMATION = false;
const PROPAGATE_TO_FROM_CONTROL = true;
const DO_NOT_PROPAGATE = false;

export type EnzoCheckboxToggleInputPayload = {
  isChecked: boolean;
};

@Component({ template: '' })
export class EnzoCheckboxToggleBaseComponent extends EnzoBaseValueAccessor<boolean> {
  public label = input<string>('');
  public description = input<string>('');
  public labelPosition = input<'leading' | 'trailing'>('trailing');

  public checked = model<boolean>(false);
  protected _checked = computed(() => booleanAttribute(this.checked()));

  public disabled = model<boolean>(false);
  protected _disabled = computed(() => booleanAttribute(this.disabled()));

  public checkbox: Signal<ElementRef<HTMLInputElement>> = viewChild.required('checkbox', { read: ElementRef });

  public enzoInput = output<CustomEvent<EnzoCheckboxToggleInputPayload>>();

  public constructor() {
    super();
  }

  // control value accessor interface methods
  public writeValue(value: unknown) {
    this.setValue(booleanAttribute(value), FORCE_UPDATE_EVEN_IF_DISABLED, PREVENT_ANIMATION, DO_NOT_PROPAGATE);
  }

  public setDisabledState(isDisabled: boolean) {
    this.disabled.set(isDisabled);
  }

  public forceFocus() {
    this.el.nativeElement.focus({ preventScroll: true });

    requestAnimationFrame(() => {
      this.el.nativeElement.scrollIntoView({
        block: 'nearest',
        inline: 'nearest',
        behavior: 'smooth',
      });
    });
  }

  public toggleCheck(options: { forceToggle?: boolean; preventAnimation?: boolean } = {}): void {
    this.setValue(!this._checked(), options.forceToggle, options.preventAnimation, PROPAGATE_TO_FROM_CONTROL);
  }

  public check(options: { forceCheck?: boolean; preventAnimation?: boolean } = {}) {
    this.setValue(true, options.forceCheck, options.preventAnimation, PROPAGATE_TO_FROM_CONTROL);
  }

  public uncheck(options: { forceUncheck?: boolean; preventAnimation?: boolean } = {}) {
    this.setValue(false, options.forceUncheck, options.preventAnimation, PROPAGATE_TO_FROM_CONTROL);
  }

  private setValue(newValue: boolean, force: boolean, preventAnimation: boolean, propagateToFormControl: boolean) {
    if (this._disabled() && !force) {
      return;
    }
    if (this._checked() === newValue && !this['_indeterminate']?.()) {
      return;
    }
    this.checked.set(newValue);
    this['indeterminate']?.set(false);
    if (propagateToFormControl) {
      this.propagateChange(newValue);
    }
  }

  public handleChange(event: Event) {
    this.setValue((event.target as HTMLInputElement).checked, DO_NOT_UPDATE_WHEN_DISABLED, SHOW_ANIMATION, PROPAGATE_TO_FROM_CONTROL);
    this.enzoInput.emit(
      new CustomEvent<EnzoCheckboxToggleInputPayload>('enzoInput', {
        detail: {
          isChecked: this._checked(),
        },
      }),
    );
  }
}
