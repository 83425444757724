import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, catchError, EMPTY, filter, map, Observable, of, take } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { AppEnvironment, IAppEnvironment } from '@cosCoreEnvironments/IAppEnvironment';
import { CosCoreClient } from '@cosCoreServices/core-client/cos-core-client.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ECountryCode } from '@cosTypes';
import { ELanguageCode } from '@caronsale/cos-models';

export interface IPhraseConfig {
  phraseProjectId: string;
  phraseAccessToken: string;
  phraseEndpointUrl: string;
  locallyStoredDefaultLanguage: ELanguageCode;
  languageFallback?: ELanguageCode;
  hiddenLanguagesPhraseVariableName?: string;
  sourceBranchName?: string;
}

export type TranslationUrlConfig = { [key in Exclude<ELanguageCode, ELanguageCode.DE | ELanguageCode.SL>]: string };

const LANGUAGE_FALLBACK_DEFAULT = ELanguageCode.EN;

function isELanguageCode(value: any): value is ELanguageCode {
  return Object.values(ELanguageCode).includes(value);
}

const languageToCountryMap = {
  [ELanguageCode.BG]: ECountryCode.BG,
  [ELanguageCode.CS]: ECountryCode.CZ,
  [ELanguageCode.DA]: ECountryCode.DK,
  [ELanguageCode.DE]: ECountryCode.DE,
  [ELanguageCode.EN]: ECountryCode.GB,
  [ELanguageCode.ES]: ECountryCode.ES,
  [ELanguageCode.FR]: ECountryCode.FR,
  [ELanguageCode.HU]: ECountryCode.HU,
  [ELanguageCode.IT]: ECountryCode.IT,
  [ELanguageCode.LT]: ECountryCode.LT,
  [ELanguageCode.NL]: ECountryCode.NL,
  [ELanguageCode.PL]: ECountryCode.PL,
  [ELanguageCode.PT]: ECountryCode.PT,
  [ELanguageCode.RO]: ECountryCode.RO,
  [ELanguageCode.SK]: ECountryCode.SK,
  [ELanguageCode.SV]: ECountryCode.SE,
  [ELanguageCode.TR]: ECountryCode.TR,
  [ELanguageCode.SL]: ECountryCode.SI,
} as const;

@Injectable({
  providedIn: 'root',
})
export class I18nService {
  private languageChangedSubject = new BehaviorSubject<ELanguageCode>(
    // Todo: use null as initial value and add filter(Boolean) to the exported observable.
    //  But first make sure that every component that uses this can actually wait until the .json file is loaded
    isELanguageCode(this.appEnvironment.phraseConfig.locallyStoredDefaultLanguage)
      ? this.appEnvironment.phraseConfig.locallyStoredDefaultLanguage
      : LANGUAGE_FALLBACK_DEFAULT,
  );

  public languageChanged$: Observable<ELanguageCode> = this.languageChangedSubject.asObservable();

  private availableLanguages: ELanguageCode[] = [
    ELanguageCode.BG,
    ELanguageCode.CS,
    ELanguageCode.DA,
    ELanguageCode.DE,
    ELanguageCode.EN,
    ELanguageCode.ES,
    ELanguageCode.FR,
    ELanguageCode.HU,
    ELanguageCode.IT,
    ELanguageCode.LT,
    ELanguageCode.NL,
    ELanguageCode.PL,
    ELanguageCode.PT,
    ELanguageCode.RO,
    ELanguageCode.SK,
    ELanguageCode.SV,
    ELanguageCode.TR,
    // available in Phrase but "hidden"
    // ELanguageCode.SL,
  ];

  public availableLanguages$: Observable<ELanguageCode[]> = new BehaviorSubject<ELanguageCode[]>(this.availableLanguages).asObservable();

  public constructor(
    private translateService: TranslateService,
    @Inject(AppEnvironment) private appEnvironment: IAppEnvironment,
    private cosCoreClient: CosCoreClient,
  ) {
    this.translateService.onLangChange
      .pipe(
        map(langChangeEvent => (isELanguageCode(langChangeEvent.lang) ? langChangeEvent.lang : null)),
        filter(Boolean),
        takeUntilDestroyed(),
      )
      .subscribe(this.languageChangedSubject);

    this.translateService.use(this.getLangToUse()); // will emit onLangChange when the .json file is downloaded
  }

  public getLangToUse(): ELanguageCode {
    const userSelectedLanguage = localStorage.getItem('userSelectedLanguage');
    const browserLocales = (navigator.languages || [navigator.language])
      .map(locale => locale.trim().split(/-|_/)[0])
      .map(lang => (isELanguageCode(lang) ? lang : null))
      .filter(Boolean);

    // the order of importance is:
    // 1. language chosen by user
    // 2. content language set via browser
    // 3. fallback language
    return (
      (isELanguageCode(userSelectedLanguage) && this.availableLanguages.includes(userSelectedLanguage) ? userSelectedLanguage : null) ||
      browserLocales.find(lang => this.availableLanguages.includes(lang)) ||
      this.appEnvironment.phraseConfig.languageFallback ||
      LANGUAGE_FALLBACK_DEFAULT
    );
  }

  // Todo: delete (provided for compatibillity only), please use availableLanguages$ directly.
  public get languages(): Observable<any[]> {
    return this.availableLanguages$;
  }

  public selectLanguage(langCode: string): Observable<void> {
    if (isELanguageCode(langCode) && this.availableLanguages.includes(langCode)) {
      localStorage.setItem('userSelectedLanguage', langCode);

      // use catchError to avoid a sentry error report when not logged in initially
      this.cosCoreClient
        .setLanguage(langCode)
        .pipe(catchError(() => EMPTY))
        .subscribe();

      return this.translateService.use(langCode).pipe(
        catchError(() => of(null)),
        map(() => null),
        take(1),
      );
    }
    return of(null);
  }

  public static getCountryCodeForLanguage(language: ELanguageCode): ECountryCode {
    return (languageToCountryMap[language] as ECountryCode) ?? null;
  }
}
