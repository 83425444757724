import { booleanAttribute, ChangeDetectionStrategy, Component, effect, ElementRef, inject, input, numberAttribute, output } from '@angular/core';
import { Components, EnzoClickPayload, EnzoFileUploadCustomEvent, FileUploadReponse } from '@caronsale/enzo';
import { fromEvent } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { HTMLStencilElement } from '@caronsale/enzo/dist/types/stencil-public-runtime';

@Component({
  selector: 'enzo-file-upload',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: '<ng-content />',
  standalone: true,
  host: {
    '[cancellable]': 'cancellable()',
    '[description]': 'description()',
    '[disabled]': 'disabled()',
    '[dropzoneActionLabel]': 'dropzoneActionLabel()',
    '[dropzoneLabel]': 'dropzoneLabel()',
    '[fakeUploadDuration]': 'fakeUploadDuration()',
    '[fileTypeNotAcceptedLabel]': 'fileTypeNotAcceptedLabel()',
    '[label]': 'label()',
    '[progress]': 'progress() ?? 0',
    '[response]': 'response()',
  },
})
export class EnzoFileUploadComponent {
  private el: Components.EnzoFileUpload & HTMLStencilElement = inject(ElementRef).nativeElement;

  public givenFile = input<File>();
  public label = input<string>('');
  public description = input<string>('');
  public disabled = input(false, { transform: booleanAttribute });
  public mimeTypes = input<string[]>([]);
  public dropzoneLabel = input<string>('');
  public dropzoneActionLabel = input<string>('');
  public fakeUploadDuration = input(0, { transform: numberAttribute });
  public response = input<FileUploadReponse>({
    received: false,
    success: true,
    errorMessage: '',
  });
  public cancellable = input(true, { transform: booleanAttribute });
  public fileTypeNotAcceptedLabel = input<string>('File format not supported');
  public progress = input(0, { transform: numberAttribute });

  public enzoClick = output<EnzoFileUploadCustomEvent<EnzoClickPayload>>();

  public fileSelected = output<EnzoFileUploadCustomEvent<File>>();
  public fileDeleted = output<EnzoFileUploadCustomEvent<File>>();
  public fileUploadCancelled = output<EnzoFileUploadCustomEvent<void>>();

  public constructor() {
    // assure mimeTypes is set before givenFile
    effect(() => {
      this.el.mimeTypes = this.mimeTypes();
      this.el.givenFile = this.givenFile();
    });

    fromEvent(this.el, 'fileSelected')
      .pipe(takeUntilDestroyed())
      .subscribe(event => {
        event.stopImmediatePropagation();
        this.fileSelected.emit(event as EnzoFileUploadCustomEvent<File>);
      });

    fromEvent(this.el, 'fileDeleted')
      .pipe(takeUntilDestroyed())
      .subscribe(event => {
        event.stopImmediatePropagation();
        this.fileDeleted.emit(event as EnzoFileUploadCustomEvent<File>);
      });

    fromEvent(this.el, 'fileUploadCancelled')
      .pipe(takeUntilDestroyed())
      .subscribe(event => {
        event.stopImmediatePropagation();
        this.fileUploadCancelled.emit(event as EnzoFileUploadCustomEvent<void>);
      });
  }
}
