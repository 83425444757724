<mat-spinner *ngIf="loading" color="primary" diameter="40" />
<div class="button-container" *ngIf="!loading">
  <button (click)="loadSettings()" mat-icon-button mat-mini-fab title="{{ 'general.cancel' | translate }}" testid="reload-button">
    <fa-icon icon="redo" />
  </button>
  <button (click)="saveSettings()" color="primary" mat-icon-button mat-mini-fab title="{{ 'general.save' | translate }}" testid="save-button">
    <fa-icon icon="save" />
  </button>
</div>
<hr />
<h3>{{ 'crm.email-management.type' | translate }}</h3>
<div class="types-container">
  <mat-checkbox *ngFor="let templateType of templateTypesMap" [(ngModel)]="templateType.checked">{{ templateType.label }}</mat-checkbox>
</div>
<hr />
<h3>{{ 'crm.email-management.template' | translate }}</h3>

<div class="templates-container">
  <mat-checkbox *ngFor="let templateName of templateNamesMap" [(ngModel)]="templateName.checked">{{ templateName.label }}</mat-checkbox>
</div>
