import { Injectable } from '@angular/core';
import {
  EPrebookedServiceGuaranteeOptionType,
  EPrebookedServiceType,
  EVoucherDiscountType,
  IBuyerAuctionView,
  IPrebookedService,
  IPrebookedServiceGuaranteeOption,
  IVoucherAssignment,
} from '@caronsale/cos-models';
import { PrebookedServicesUtils } from '@cosUtils/business';
import { CrossBorderFeeOrDiscountService } from '@cosCoreServices/cross-border-fee-or-discount/cross-border-fee-or-discount.service';
import { BuyerTransportationService } from '@cosCoreServices/buyer-transportation/buyer-transportation.service';
import { environment } from '@cosCoreEnvironments/environment';
import { roundNumber } from '@cosUtils/general';

export interface IAuctionPrice {
  basePrice: number;
  totalAmount: number;
  voucherDiscount: number;
  feeDetails: {
    crossBorderTaxDiscount: number;
    crossBorderTaxFee: number;
    cosCheckPlusGuaranteePrice: number;
    extendedGuaranteePrice: number;
    transportationPrice: number;
  };
}

export const VAT_FACTOR = environment.VATFactor;

@Injectable({ providedIn: 'root' })
export class AuctionPriceCalculatorService {
  public constructor(
    private crossBorderFeeOrDiscountService: CrossBorderFeeOrDiscountService,
    private buyerTransportationService: BuyerTransportationService,
  ) {}

  public getAuctionPrice(
    bidValue: number,
    auction: IBuyerAuctionView,
    currentPrebookedServices: IPrebookedService[],
    options: {
      selectedVoucher?: IVoucherAssignment;
      includeFeesVAT?: boolean;
    },
  ): IAuctionPrice {
    const { selectedVoucher, includeFeesVAT: addVAT } = { includeFeesVAT: false, ...options };

    const taxValues = this.crossBorderFeeOrDiscountService.getCrossBorderTaxFeeAndDiscount(auction.buyerCrossBorderProcessingAmount);
    const basePrice = bidValue || auction.currentHighestBidValue;

    const crossBorderTaxDiscount = taxValues.crossBorderTaxDiscount;
    const crossBorderTaxFee = taxValues.crossBorderTaxFee;
    const purchaseFee = auction.buyerPurchaseFee;
    const successFee = auction.buyerSuccessFee;
    const { cosCheckPlusGuaranteePrice, extendedGuaranteePrice } = this.getGuaranteePrices(auction, currentPrebookedServices);

    const transportationPrice = this.buyerTransportationService.getTransportationPriceForFeeInfo(auction, currentPrebookedServices);

    const baseVoucherAmount = selectedVoucher?.discountType === EVoucherDiscountType.FIXED && selectedVoucher.amount ? selectedVoucher.amount : 0;

    const feesAmount = roundNumber(
      this.calculateTotal(purchaseFee, successFee, cosCheckPlusGuaranteePrice, transportationPrice, extendedGuaranteePrice, crossBorderTaxFee) *
        (addVAT ? VAT_FACTOR : 1),
      2,
    );

    const applicableVoucherAmount = baseVoucherAmount > feesAmount ? -feesAmount : -baseVoucherAmount;

    const totalAmount = this.calculateTotal(basePrice, feesAmount, applicableVoucherAmount, -crossBorderTaxDiscount);

    return {
      basePrice,
      totalAmount: totalAmount > 0 ? totalAmount : 0, // total amount can't be negative
      voucherDiscount: applicableVoucherAmount,
      feeDetails: { crossBorderTaxDiscount, crossBorderTaxFee, cosCheckPlusGuaranteePrice, extendedGuaranteePrice, transportationPrice },
    };
  }

  private getGuaranteePrices(auction: IBuyerAuctionView, currentPrebookedServices: IPrebookedService[]) {
    const guaranteeService = PrebookedServicesUtils.getService(currentPrebookedServices || auction.prebookedServices, EPrebookedServiceType.GUARANTEE);
    const cosCheckPlusGuaranteeOption: IPrebookedServiceGuaranteeOption = PrebookedServicesUtils.getOption(
      guaranteeService,
      EPrebookedServiceGuaranteeOptionType.COS_CHECK_PLUS,
    );
    const extendedGuaranteeOption: IPrebookedServiceGuaranteeOption = PrebookedServicesUtils.getOption(
      guaranteeService,
      EPrebookedServiceGuaranteeOptionType.EXTENDED,
    );

    return {
      cosCheckPlusGuaranteePrice: this.getGuaranteeOptionPrice(cosCheckPlusGuaranteeOption),
      extendedGuaranteePrice: this.getGuaranteeOptionPrice(extendedGuaranteeOption),
    };
  }

  private getGuaranteeOptionPrice(option: IPrebookedServiceGuaranteeOption) {
    return option?.isSelected ? option.discountPriceNet ?? option.priceNet : 0;
  }

  private calculateTotal(...parts: number[]): number {
    return parts.reduce((acc, part) => acc + (part || 0), 0);
  }
}
