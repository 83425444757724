import { EAuctionState, IAuction, ILocation, Time } from '@caronsale/cos-models';

export class AuctionUtils {
  /**
   * Set default values to allow create drafted auction.
   *
   * @param auctionToCreate
   */
  public static setDefaultDraftAuctionValues(auctionToCreate: IAuction): void {
    auctionToCreate.minimumRequiredAsk = -1;
    auctionToCreate.endingTime = Time.getDateXDaysFromNow(3);

    auctionToCreate.state = EAuctionState.DRAFTED;
  }

  /**
   * @param auction
   * @param location
   */
  public static setLocationForAuction(auction: IAuction, location: ILocation): void {
    auction.locationAddress = location.addressLine;
    auction.locationZip = location.zipCode;
    auction.locationCity = location.city;
    auction.locationCountryCode = location.countryCode;
  }

  /**
   * Used for the migration purposes, should be removed in scope of INV-1077
   * Copies the vat reportable flag from an auction to a vehicle.
   */
  public static syncVatReportable(auction: IAuction): void {
    auction.associatedVehicle.vatIdReportable = auction.isVATReportable;
  }
}
