import { booleanAttribute, ChangeDetectionStrategy, Component, ElementRef, inject, input, output } from '@angular/core';
import { fromEvent } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Components, EnzoClickPayload, EnzoIcon } from '@caronsale/enzo';
import { HTMLStencilElement } from '@caronsale/enzo/dist/types/stencil-public-runtime';

@Component({
  selector: 'enzo-floating-icon-button',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: '<ng-content />',
  standalone: true,
  host: {
    '[disabled]': 'disabled()',
    '[icon]': 'icon()',
  },
})
export class EnzoFloatingIconButtonComponent {
  private el: Components.EnzoFloatingIconButton & HTMLStencilElement = inject(ElementRef).nativeElement;

  public icon = input.required<EnzoIcon['icon']>();
  public disabled = input(false, { transform: booleanAttribute });

  public enzoClick = output<CustomEvent<EnzoClickPayload>>();

  public constructor() {
    fromEvent(this.el, 'enzoClick')
      .pipe(takeUntilDestroyed())
      .subscribe(event => {
        event.stopImmediatePropagation();
        this.enzoClick.emit(new CustomEvent('enzoClick', { detail: {} as EnzoClickPayload }));
      });
  }

  public press(options: { forceClick?: boolean } = {}) {
    if (!this.disabled() || options.forceClick) {
      this.enzoClick.emit(new CustomEvent('enzoClick', { detail: {} as EnzoClickPayload }));
    }
  }
}
