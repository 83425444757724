import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  computed,
  ElementRef,
  inject,
  input,
  model,
  output,
  Signal,
  signal,
  viewChild,
} from '@angular/core';
import { EnzoTextComponent } from '../../typography/enzo-text/enzo-text.component';

export type EnzoInternalSelectRadioButtonPayload = {
  value: any;
};

@Component({
  selector: 'enzo-radio-button',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './enzo-radio-button.component.html',
  styleUrls: ['../enzo-checkbox-toggle-radio-texts.scss', './enzo-radio-button.component.scss'],
  standalone: true,
  imports: [EnzoTextComponent],
  host: {
    '(keydown.enter)': 'radio().nativeElement.click()',
    '[attr.checked]': '_checked()',
    '[attr.disabled]': '_disabled()',
    '[attr.data-label-position]': 'labelPosition()',
    '[attr.data-border]': 'border()',
    '[attr.value]': 'value()',
    '[attr.name]': 'name()',
  },
})
export class EnzoRadioButtonComponent {
  private el = inject(ElementRef).nativeElement;

  public value = input<any>();
  public label = input<string>('');
  public description = input<string>('');
  public labelPosition = input<'leading' | 'trailing'>('trailing');
  public border = input(false, { transform: booleanAttribute });
  protected name = model<string>(); // set by parent radio-button-group

  public checked = model(false);
  protected _checked = computed(() => booleanAttribute(this.checked()));

  public disabled = model(false);
  protected _disabled = computed(() => booleanAttribute(this.disabled()));

  public radio: Signal<ElementRef<HTMLInputElement>> = viewChild.required('radio', { read: ElementRef });

  // the internal event to tell the radio button group that our selection changed
  public internalSelect = output<CustomEvent<EnzoInternalSelectRadioButtonPayload>>();

  // the internal property, set by the radio button group to indicate that we shall be able to receive focus
  // this is set for the currectly selected one or, if none is selected, for the first in the group
  public internalFocusable = signal(false);

  public forceFocus() {
    this.el.focus({ preventScroll: true });

    requestAnimationFrame(() => {
      this.el.scrollIntoView({
        block: 'nearest',
        inline: 'nearest',
        behavior: 'smooth',
      });
    });
  }

  public handleChange(_event: Event) {
    if (!this._checked() && !this._disabled()) {
      this.checked.set(true);
      this.internalSelect.emit(
        new CustomEvent<EnzoInternalSelectRadioButtonPayload>('internalSelect', {
          detail: {
            value: this.value(),
          },
        }),
      );
    }
  }
}
