import { booleanAttribute, ChangeDetectionStrategy, Component, Input, input, output } from '@angular/core';
import { EnzoIcon, EnzoSelectableValueType, EnzoSelectCustomEvent, EnzoSelectInputPayload, ErrorStateMatchStrategy, Option } from '@caronsale/enzo';
import { fromEvent } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ValueAccessor } from '../value-accessor';

@Component({
  selector: 'enzo-select',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: '<ng-content />',
  standalone: true,
  host: {
    '[options]': 'options()',
    '[allowMultiple]': 'allowMultiple()',
    '[hideSearch]': 'hideSearch()',
    '[label]': 'label()',
    '[description]': 'description()',
    '[placeholder]': 'placeholder()',
    '[hintText]': 'hintText()',
    '[hintClickable]': 'hintClickable()',
    '[showClearIcon]': 'showClearIcon()',
    '[icon]': 'icon()',
    '[iconPosition]': 'iconPosition()',
    '[validation]': 'validation()',
    '[selectedOptions]': 'selectedOptions',
    '[value]': 'value',
    '(enzoInput)': '_handleChangeEvent($event)',
    '(touchChanged)': '_handleTouchChangedEvent($event)',
  },
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: EnzoSelectComponent,
      multi: true,
    },
  ],
})
export class EnzoSelectComponent extends ValueAccessor {
  public options = input<Array<Option>>([]);
  public allowMultiple = input(false, { transform: booleanAttribute });
  public hideSearch = input(false, { transform: booleanAttribute });
  public label = input<string>('');
  public description = input<string>('');
  public placeholder = input<string>('');
  public hintText = input<string>('');
  public hintClickable = input(false, { transform: booleanAttribute });
  public showClearIcon = input(false, { transform: booleanAttribute });
  public icon = input<EnzoIcon['icon']>();
  public iconPosition = input<'value' | 'label' | 'none'>('value');
  public validation = input<{ type?: 'angular'; errorStateMatch: ErrorStateMatchStrategy }>({ errorStateMatch: 'onTouch' });

  @Input()
  public get selectedOptions(): Array<EnzoSelectableValueType> {
    return this.el.selectedOptions;
  }

  public set selectedOptions(newValue: Array<EnzoSelectableValueType>) {
    this.el.selectedOptions = newValue;
  }

  @Input()
  public get value(): EnzoSelectableValueType | Array<EnzoSelectableValueType> {
    return this.el.value;
  }

  public set value(newValue: EnzoSelectableValueType | Array<EnzoSelectableValueType>) {
    this.el.value = newValue;
  }

  @Input()
  public get disabled(): boolean {
    return this.el.disabled;
  }

  public set disabled(newValue: boolean) {
    this.el.disabled = newValue;
  }

  public enzoInput = output<EnzoSelectCustomEvent<EnzoSelectInputPayload<EnzoSelectableValueType>>>();
  public hintClick = output<EnzoSelectCustomEvent<void>>();

  public constructor() {
    super();
    fromEvent(this.el, 'enzoInput')
      .pipe(takeUntilDestroyed())
      .subscribe(event => {
        (event as Event).stopImmediatePropagation();
        this.enzoInput.emit(event as EnzoSelectCustomEvent<EnzoSelectInputPayload<EnzoSelectableValueType>>);
      });
    fromEvent(this.el, 'hintClick')
      .pipe(takeUntilDestroyed())
      .subscribe(event => {
        (event as Event).stopImmediatePropagation();
        this.hintClick.emit(event as EnzoSelectCustomEvent<void>);
      });
  }

  public forceFocus() {
    this.el.focus({ preventScroll: true });

    requestAnimationFrame(() => {
      this.el.scrollIntoView({
        block: 'nearest',
        inline: 'nearest',
        behavior: 'smooth',
      });
    });
  }

  public async selectOption(value: EnzoSelectableValueType, options: { forceSelection?: boolean } = {}) {
    return this.el.selectOption(value, options);
  }

  public async deselectOption(value: EnzoSelectableValueType) {
    return this.el.deselectOption(value);
  }

  public async reset() {
    return this.el.reset();
  }

  public _handleChangeEvent({ detail }) {
    super.handleChangeEvent(this.allowMultiple() ? detail.selectedOptions : detail.selectedOption);
  }

  public _handleTouchChangedEvent(event: any) {
    if (event.detail) {
      this.onTouched();
    }
  }

  public writeValue(value: any) {
    Promise.resolve().then(() => (this.el.value = this.lastValue = value));
  }
}
