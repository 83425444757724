<enzo-context [language]="translateService.currentLang" (enzoLanguageChange)="languageChange($event)" />

<div
  class="app-development-banner"
  data-cy="app-root_development-banner"
  *ngIf="!isUIProductionEnvironment() || !isServiceProductionEnvironment()"
  [hidden]="bannerIsHidden"
  (click)="bannerIsHidden = true">
  <h4>This is not a production environment.</h4>
  <p>UI: {{ uiEnvironmentLabel }}</p>
  <p>SERVICE: {{ serviceEnvironmentLabel }}</p>
</div>

<div fxLayout="column">
  <router-outlet />
</div>
