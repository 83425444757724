/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface IRawVehicleData {
  dat: {
    techDetails: any[];
    identification: any[];
  };
  condition: any[];
}

export enum EVehicleCategory {
  Value1 = -1,
  Value0 = 0,
  Value11 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
  Value5 = 5,
  Value6 = 6,
  Value7 = 7,
  Value8 = 8,
  Value9 = 9,
}

export enum ETransmissionType {
  Value1 = -1,
  Value0 = 0,
  Value11 = 1,
}

export enum EUpholsteryType {
  Value1 = -1,
  Value0 = 0,
  Value11 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
}

export enum EFuelType {
  Value1 = -1,
  Value0 = 0,
  Value11 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
  Value5 = 5,
  Value6 = 6,
  Value7 = 7,
}

export enum EVehicleDoors {
  Value1 = -1,
  Value0 = 0,
  Value11 = 1,
  Value2 = 2,
}

export enum EVehicleColor {
  Value1 = -1,
  Value0 = 0,
  Value11 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
  Value5 = 5,
  Value6 = 6,
  Value7 = 7,
  Value8 = 8,
  Value9 = 9,
  Value10 = 10,
  Value112 = 11,
}

export enum EHuReportAvailable {
  Value1 = -1,
  Value0 = 0,
  Value11 = 1,
  Value2 = 2,
}

export enum EVehicleFullServiceHistoryType {
  Value1 = -1,
  Value0 = 0,
  Value11 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
  Value5 = 5,
  Value6 = 6,
}

export enum EVehicleServiceHistoryAvailability {
  Value1 = -1,
  Value0 = 0,
  Value11 = 1,
  Value2 = 2,
}

export enum EEuroNorm {
  Other = 'Other',
  Euro1 = 'Euro 1',
  Euro2 = 'Euro 2',
  Euro3 = 'Euro 3',
  Euro4 = 'Euro 4',
  Euro5 = 'Euro 5',
  Euro5A = 'Euro 5a',
  Euro5B = 'Euro 5b',
  Euro6 = 'Euro 6',
  Euro6B = 'Euro 6b',
  Euro6C = 'Euro 6c',
  Euro6D = 'Euro 6d',
  Euro6DTemp = 'Euro 6d-Temp',
  EuroI = 'Euro I',
  EuroII = 'Euro II',
  EuroIII = 'Euro III',
  EuroIV = 'Euro IV',
  EuroV = 'Euro V',
  EuroVI = 'Euro VI',
}

export enum EVehiclePart {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
  Value5 = 5,
  Value6 = 6,
  Value7 = 7,
  Value8 = 8,
  Value10 = 10,
  Value11 = 11,
  Value12 = 12,
  Value13 = 13,
  Value14 = 14,
  Value15 = 15,
  Value16 = 16,
  Value17 = 17,
  Value18 = 18,
  Value22 = 22,
  Value28 = 28,
  Value31 = 31,
  Value32 = 32,
  Value33 = 33,
  Value34 = 34,
  Value35 = 35,
  Value36 = 36,
  Value37 = 37,
  Value38 = 38,
  Value39 = 39,
  Value40 = 40,
  Value41 = 41,
  Value42 = 42,
  Value43 = 43,
  Value44 = 44,
  Value45 = 45,
  Value46 = 46,
  Value47 = 47,
  Value48 = 48,
  Value49 = 49,
  Value98 = 98,
  Value99 = 99,
}

export enum EVehicleInteriorDamagePart {
  Value50 = 50,
  Value51 = 51,
  Value52 = 52,
  Value53 = 53,
  Value54 = 54,
  Value55 = 55,
  Value56 = 56,
  Value57 = 57,
  Value58 = 58,
  Value59 = 59,
  Value60 = 60,
  Value61 = 61,
  Value62 = 62,
  Value63 = 63,
  Value64 = 64,
  Value65 = 65,
  Value66 = 66,
  Value67 = 67,
  Value68 = 68,
  Value69 = 69,
  Value70 = 70,
  Value71 = 71,
  Value72 = 72,
  Value73 = 73,
  Value74 = 74,
}

export enum EDamageType {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
  Value5 = 5,
  Value6 = 6,
  Value7 = 7,
  Value8 = 8,
  Value9 = 9,
  Value10 = 10,
  Value11 = 11,
  Value12 = 12,
  Value13 = 13,
  Value14 = 14,
  Value15 = 15,
  Value16 = 16,
  Value17 = 17,
  Value18 = 18,
  Value19 = 19,
  Value20 = 20,
  Value41 = 41,
  Value42 = 42,
  Value98 = 98,
}

export enum EDamageInteriorType {
  Value40 = 40,
  Value43 = 43,
  Value44 = 44,
  Value45 = 45,
  Value46 = 46,
  Value47 = 47,
  Value48 = 48,
  Value49 = 49,
  Value50 = 50,
}

export interface IVehicleDamage {
  uuid: string;
  location: EVehiclePart | EVehicleInteriorDamagePart;
  types: (EDamageType | EDamageInteriorType)[];
  description: string;
  urlToImage: string;
  urlToImages: string[];
}

export enum EVehicleReadyToDriveStatus {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
}

export interface IVehicleImage {
  uuid: string;
  /** @format double */
  perspective: number;
  rawData?: any;
  url: string;
}

export interface IVehicleExposeByLanguageMap {
  cs?: string;
  de?: string;
  en?: string;
  fr?: string;
  it?: string;
  nl?: string;
  pl?: string;
  sk?: string;
  tr?: string;
  bg?: string;
  da?: string;
  es?: string;
  hu?: string;
  lt?: string;
  pt?: string;
  ro?: string;
  sv?: string;
  sl?: string;
}

export enum ECountryCode {
  Value = '??',
  CH = 'CH',
  CA = 'CA',
  US = 'US',
  AD = 'AD',
  AT = 'AT',
  BE = 'BE',
  BG = 'BG',
  CY = 'CY',
  CZ = 'CZ',
  DE = 'DE',
  DK = 'DK',
  EE = 'EE',
  ES = 'ES',
  FI = 'FI',
  FR = 'FR',
  GB = 'GB',
  GR = 'GR',
  HR = 'HR',
  HU = 'HU',
  IE = 'IE',
  IS = 'IS',
  IT = 'IT',
  LI = 'LI',
  LT = 'LT',
  LU = 'LU',
  LV = 'LV',
  MC = 'MC',
  MD = 'MD',
  ME = 'ME',
  MK = 'MK',
  MT = 'MT',
  NL = 'NL',
  NO = 'NO',
  PL = 'PL',
  PT = 'PT',
  RO = 'RO',
  RS = 'RS',
  RU = 'RU',
  SE = 'SE',
  SI = 'SI',
  SK = 'SK',
  SM = 'SM',
  TR = 'TR',
  UA = 'UA',
}

export enum EVehicleCreationOrigin {
  Value1 = -1,
  Value0 = 0,
  Value11 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
  Value5 = 5,
  Value6 = 6,
  Value7 = 7,
  Value8 = 8,
  Value9 = 9,
  Value10 = 10,
  Value112 = 11,
  Value12 = 12,
  Value13 = 13,
  Value14 = 14,
  Value15 = 15,
  Value16 = 16,
  Value17 = 17,
}

export enum EVehicleDataSource {
  Value1 = -1,
  Value0 = 0,
  Value11 = 1,
  Value2 = 2,
}

export enum EVehiclePaintLocation {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
  Value5 = 5,
  Value6 = 6,
  Value7 = 7,
  Value8 = 8,
  Value9 = 9,
  Value10 = 10,
  Value11 = 11,
  Value12 = 12,
}

export interface IVehiclePaintState {
  part: EVehiclePaintLocation;
  /** @format double */
  value: number;
  isUnusualValue?: boolean;
}

export enum EVehicleTireSetType {
  Value1 = -1,
  Value0 = 0,
  Value11 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
  Value5 = 5,
  Value6 = 6,
}

export enum EVehicleRimType {
  Value1 = -1,
  Value0 = 0,
  Value11 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
}

export enum EVehicleTireConditionAssessment {
  BAD = 'BAD',
  MEDIUM = 'MEDIUM',
  GOOD = 'GOOD',
  NONE = 'NONE',
}

export interface IVehicleTireDetails {
  /** @format double */
  condition: number;
  conditionAssessment: EVehicleTireConditionAssessment;
}

export interface IVehicleTireSet {
  isInstalled: boolean;
  type: EVehicleTireSetType;
  rim: EVehicleRimType;
  details: IVehicleTireDetails[];
  size: string;
  flawDescription: string;
}

export enum EVehicleDataWarning {
  Value1 = -1,
  Value0 = 0,
  Value11 = 1,
  Value2 = 2,
}

export enum EVehicleTechState {
  Value1 = -1,
  Value0 = 0,
  Value11 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
  Value5 = 5,
  Value6 = 6,
  Value7 = 7,
  Value8 = 8,
  Value9 = 9,
  Value10 = 10,
}

export type AnomalyWithoutSpecification = string[];

export enum EAirConditionerNoiseAnomalySpecification {
  SQUEAK = 'SQUEAK',
  NOISE = 'NOISE',
  HUM = 'HUM',
}

export interface IAirConditionerAnomalies {
  withoutFunction?: AnomalyWithoutSpecification;
  ventilationNoises?: EAirConditionerNoiseAnomalySpecification[];
  insufficientCoolingPower?: AnomalyWithoutSpecification;
  other?: string;
}

export enum EBatteryAttachmentsAnomaly {
  LOOSE = 'LOOSE',
  INADEQUATE = 'INADEQUATE',
  FAULTY = 'FAULTY',
}

export interface IBatteryAnomalies {
  defect?: AnomalyWithoutSpecification;
  batteryIndicatorLightsOn?: AnomalyWithoutSpecification;
  deepDischarge?: AnomalyWithoutSpecification;
  missing?: AnomalyWithoutSpecification;
  anomaliesInAttachment?: EBatteryAttachmentsAnomaly[];
  other?: string;
}

export enum ERearFrontAnomalySpecification {
  REAR = 'REAR',
  FRONT = 'FRONT',
}

export enum EBrakeFluidAnomalySpecification {
  BELOW_MINIMUM = 'BELOW_MINIMUM',
  BIN_EMPTY = 'BIN_EMPTY',
  FLOATING_LOSS = 'FLOATING_LOSS',
  DRIPPING_LOSS = 'DRIPPING_LOSS',
}

export interface IBrakeSystemAnomalies {
  noBrakePressure?: AnomalyWithoutSpecification;
  brakeIndicatorLightsOn?: AnomalyWithoutSpecification;
  brakeNearWornOut?: ERearFrontAnomalySpecification[];
  brakeWornOut?: ERearFrontAnomalySpecification[];
  brakeRusty?: ERearFrontAnomalySpecification[];
  absIndicatorLightsOn?: AnomalyWithoutSpecification;
  handbrakeNotWorking?: AnomalyWithoutSpecification;
  brakeFluidAbnormal?: EBrakeFluidAnomalySpecification[];
  other?: string;
}

export interface IChassisAnomalies {
  suspensionSpringBroken?: ERearFrontAnomalySpecification[];
  espIndicatorLightsOn?: AnomalyWithoutSpecification;
  airSuspensionWithoutFunction?: AnomalyWithoutSpecification;
  unequalChassisLevel?: AnomalyWithoutSpecification;
  vehicleLowered?: AnomalyWithoutSpecification;
  other?: string;
}

export enum EClutchNoiseAnomalySpecification {
  SQUEAK = 'SQUEAK',
  GRINDING = 'GRINDING',
  RATTLING = 'RATTLING',
  VIBRATING = 'VIBRATING',
  CLATTERING = 'CLATTERING',
  CREAKING = 'CREAKING',
}

export enum EClutchPedalAnomalySpecification {
  COMES_EARLY = 'COMES_EARLY',
  COMES_LATE = 'COMES_LATE',
}

export interface IClutchAnomalies {
  clutchNearWorn?: AnomalyWithoutSpecification;
  couplingDefective?: AnomalyWithoutSpecification;
  strikingNoise?: EClutchNoiseAnomalySpecification[];
  clutchPedalAnomalies?: EClutchPedalAnomalySpecification[];
  other?: string;
}

export enum EEngineNoiseAnomalySpecification {
  WHISTLING = 'WHISTLING',
  CRACKING = 'CRACKING',
  CLATTERING = 'CLATTERING',
  BEATING = 'BEATING',
  RATTLING = 'RATTLING',
  VIBRATING = 'VIBRATING',
  GRINDING = 'GRINDING',
  SQUEAKING = 'SQUEAKING',
}

export enum EControlLightsAnomalySpecification {
  CHECK_ENGINE = 'CHECK_ENGINE',
  DIESEL_PARTICULATE_FILTER = 'DIESEL_PARTICULATE_FILTER',
  PREHEATING = 'PREHEATING',
  EPC = 'EPC',
}

export enum EOilAnomalySpecification {
  BELOW_MINIMUM = 'BELOW_MINIMUM',
  BIN_EMPTY = 'BIN_EMPTY',
  OIL_LOSS_NOT_VISIBLE = 'OIL_LOSS_NOT_VISIBLE',
}

export enum EOilLeakAnomalySpecification {
  AT_TURBO_CHARGER = 'AT_TURBO_CHARGER',
  UNDER_CHASSIS = 'UNDER_CHASSIS',
  ON_VALVE_COVER = 'ON_VALVE_COVER',
  ON_CYLINDER_HEAD = 'ON_CYLINDER_HEAD',
  ON_VALVE_COVER_GASKET = 'ON_VALVE_COVER_GASKET',
}

export enum ECoolingWaterAnomalySpecification {
  BELOW_MINIMUM = 'BELOW_MINIMUM',
  BIN_EMPTY = 'BIN_EMPTY',
  FLOATING_LOSS = 'FLOATING_LOSS',
  DRIPPING_LOSS = 'DRIPPING_LOSS',
  OIL_FILM_MUDDY = 'OIL_FILM_MUDDY',
}

export enum EExhaustAnomalySpecification {
  LEAKY = 'LEAKY',
  RECIRCULATION_DEFECT = 'RECIRCULATION_DEFECT',
  LOSS_NOT_VISIBLE = 'LOSS_NOT_VISIBLE',
  DISTINCTIVE_ODOR = 'DISTINCTIVE_ODOR',
  SURFACE_CORRODED = 'SURFACE_CORRODED',
}

export enum EStarterAnomalySpecification {
  DEFECT = 'DEFECT',
  TURNS_BUT_ENGINE_WONT_START = 'TURNS_BUT_ENGINE_WONT_START',
}

export enum EDualMassAnomalySpecification {
  CLATTERING = 'CLATTERING',
  DEFECT = 'DEFECT',
}

export interface IEngineAnomalies {
  strikingNoise?: EEngineNoiseAnomalySpecification[];
  outOfRoundRun?: AnomalyWithoutSpecification;
  doesNotRunBatteryOk?: AnomalyWithoutSpecification;
  visualWarnings?: EControlLightsAnomalySpecification[];
  oilAnomaly?: EOilAnomalySpecification[];
  oilLeak?: EOilLeakAnomalySpecification[];
  coolingWaterAnomaly?: ECoolingWaterAnomalySpecification[];
  exhaustSystemAnomaly?: EExhaustAnomalySpecification[];
  starterAnomaly?: EStarterAnomalySpecification[];
  misfire?: AnomalyWithoutSpecification;
  dualMassAnomaly?: EDualMassAnomalySpecification[];
  other?: string;
}

export enum EPowerSteeringAnomalySpecification {
  WITHOUT_FUNCTION = 'WITHOUT_FUNCTION',
  LIMITED_FUNCTION = 'LIMITED_FUNCTION',
}

export enum ESteeringWheelNoiseAnomalySpecification {
  CRACKING = 'CRACKING',
  CRUNCH = 'CRUNCH',
  CREAKING = 'CREAKING',
  GRINDING = 'GRINDING',
  RESILIENCE = 'RESILIENCE',
  POWER_STEERING_PUMP_NOISE = 'POWER_STEERING_PUMP_NOISE',
}

export interface ISteeringAnomalies {
  steeringIndicatorLightsOn?: AnomalyWithoutSpecification;
  atPowerSteering?: EPowerSteeringAnomalySpecification[];
  noiseWhenUsingSteeringWheel?: ESteeringWheelNoiseAnomalySpecification[];
  steeringGearLeaking?: AnomalyWithoutSpecification;
  stiff?: AnomalyWithoutSpecification;
  other?: string;
}

export enum ETransmissionNoiseAnomalySpecification {
  WHISTLING = 'WHISTLING',
  RATTLING = 'RATTLING',
  CRACKING = 'CRACKING',
  GRINDING = 'GRINDING',
}

export interface ITransmissionAnomalies {
  gearSticks?: AnomalyWithoutSpecification;
  gearboxDamage?: AnomalyWithoutSpecification;
  oilWet?: AnomalyWithoutSpecification;
  gearNotEngageable?: AnomalyWithoutSpecification;
  shiftingDifficult?: AnomalyWithoutSpecification;
  runningNoise?: ETransmissionNoiseAnomalySpecification[];
  other?: string;
}

export interface ITransferCaseAnomalies {
  strikingSounds?: AnomalyWithoutSpecification;
  profileDeviationOnWheels?: AnomalyWithoutSpecification;
  other?: string;
}

export interface IDifferentialAnomalies {
  strikingSounds?: AnomalyWithoutSpecification;
  leakage?: AnomalyWithoutSpecification;
  other?: string;
}

export interface IOtherAnomalies {
  other?: string;
}

export type IVehiclePartAnomalies =
  | IAirConditionerAnomalies
  | IBatteryAnomalies
  | IBrakeSystemAnomalies
  | IChassisAnomalies
  | IClutchAnomalies
  | IEngineAnomalies
  | ISteeringAnomalies
  | ITransmissionAnomalies
  | ITransferCaseAnomalies
  | IDifferentialAnomalies
  | IOtherAnomalies;

export interface IVehicleTechState {
  uuid: string;
  part: EVehiclePart;
  state: EVehicleTechState;
  anomalies?: IVehiclePartAnomalies;
}

export enum EAirCondition {
  Value1 = -1,
  Value0 = 0,
  Value11 = 1,
  Value2 = 2,
}

export enum ECoupling {
  Value1 = -1,
  Value0 = 0,
  Value11 = 1,
  Value2 = 2,
  Value3 = 3,
}

export enum EParkingAssistance {
  Value1 = -1,
  Value0 = 0,
  Value11 = 1,
  Value2 = 2,
  Value3 = 3,
}

export enum EHeadlights {
  Value1 = -1,
  Value0 = 0,
  Value11 = 1,
  Value2 = 2,
  Value3 = 3,
}

export enum EVehicleHeater {
  Value1 = -1,
  Value0 = 0,
  Value11 = 1,
  Value2 = 2,
}

export interface IVehicleFieldConfirmationStatusMap {
  euroNorm?: EEuroNorm;
  /** @format double */
  numSeats?: number;
  category?: EVehicleCategory;
  ac?: EAirCondition;
  coupling?: ECoupling;
  /** @format double */
  navigation?: number;
  parkingAssistance?: EParkingAssistance;
  headlights?: EHeadlights;
  /** @format double */
  sunRoof?: number;
  vehicleHeater?: EVehicleHeater;
  /** @format double */
  sportPackage?: number;
  fuelType?: EFuelType;
  transmission?: ETransmissionType;
  upholstery?: EUpholsteryType;
  doors?: EVehicleDoors;
  /** @format double */
  engineSizeInCcm?: number;
  /** @format double */
  enginePowerInHp?: number;
}

export interface ITestCycle {
  /** @format double */
  nedc?: number;
  /** @format double */
  wltp?: number;
}

export type IFuelConsumption = ITestCycle;

export type ICo2Emission = ITestCycle;

export enum EVehicleCommercialUsage {
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
}

export enum ELanguageCode {
  Cs = 'cs',
  De = 'de',
  En = 'en',
  Fr = 'fr',
  It = 'it',
  Nl = 'nl',
  Pl = 'pl',
  Sk = 'sk',
  Tr = 'tr',
  Bg = 'bg',
  Da = 'da',
  Es = 'es',
  Hu = 'hu',
  Lt = 'lt',
  Pt = 'pt',
  Ro = 'ro',
  Sv = 'sv',
  Sl = 'sl',
}

export enum ELockingWheelNut {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
}

export enum EVehicleEquipmentType {
  Value0 = 0,
  Value1 = 1,
}

export enum EVehicleEquipmentGroup {
  TRANSMISSION = 'TRANSMISSION',
  SAFETY_COMFORT = 'SAFETY_COMFORT',
  AXLES_CHASSIS = 'AXLES_CHASSIS',
  EXTERIOR = 'EXTERIOR',
  INTERIOR = 'INTERIOR',
  LIGHTING = 'LIGHTING',
  MULTIMEDIA = 'MULTIMEDIA',
  ENGINE = 'ENGINE',
}

export interface IVehicleEquipmentData {
  uuid: string;
  externalId: string;
  externalGroup: string;
  description: string;
  type: EVehicleEquipmentType;
  group: EVehicleEquipmentGroup | null;
}

export enum EVehicleEquipmentCategory {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
  Value5 = 5,
  Value6 = 6,
  Value7 = 7,
}

export interface IVehicleEquipmentHighlightData {
  uuid: string;
  externalId: string;
  /** @format double */
  rank: number;
  description: string;
  category: EVehicleEquipmentCategory;
}

export interface IVehicleLineByLanguageMap {
  cs?: string;
  de?: string;
  en?: string;
  fr?: string;
  it?: string;
  nl?: string;
  pl?: string;
  sk?: string;
  tr?: string;
  bg?: string;
  da?: string;
  es?: string;
  hu?: string;
  lt?: string;
  pt?: string;
  ro?: string;
  sv?: string;
  sl?: string;
}

export enum EVehicleExternalReportType {
  ALPHAVEHICLEVALUATIONREPORT = 'ALPHA-VEHICLE-VALUATION-REPORT',
  ALPHALEASINGVALUATIONREPORT = 'ALPHA-LEASING-VALUATION-REPORT',
  ALPHADEALERVEHICLEVALUATIONREPORT = 'ALPHA-DEALER-VEHICLE-VALUATION-REPORT',
  ALPHAVEHICLEVALUATIONWITHOUTDAMAGEPRICESREPORT = 'ALPHA-VEHICLE-VALUATION-WITHOUT-DAMAGE-PRICES-REPORT',
  ALPHA_MARKETPLACE_CONDITION_REPORT = 'ALPHA_MARKETPLACE_CONDITION_REPORT',
  COS = 'COS',
  ALPHA_CONTROLLER = 'ALPHA_CONTROLLER',
  TUV_SUD = 'TUV_SUD',
  DEKRA = 'DEKRA',
  TUV_RHEINLAND = 'TUV_RHEINLAND',
  TUV_NORD = 'TUV_NORD',
  GTU = 'GTU',
  HUSGES = 'HUSGES',
  KUS = 'KUS',
  VFKS = 'VFKS',
  MACADAM = 'MACADAM',
  OTHER = 'OTHER',
}

export interface IVehicleExternalReport {
  url: string;
  type: EVehicleExternalReportType;
}

export enum EVehicleObd2ReadingType {
  PERMANENT = 'PERMANENT',
  TEMPORARY = 'TEMPORARY',
  CONFIRMED = 'CONFIRMED',
}

export interface IVehicleObd2Reading {
  code: string;
  description: string;
  type: EVehicleObd2ReadingType;
}

export enum EVehicleCountOfDrivenAxles {
  UNKNOWN = 'UNKNOWN',
  ONE = 'ONE',
  TWO = 'TWO',
}

export interface IVehicle {
  /** @format double */
  id?: number;
  uuid: string;
  /** @format date-time */
  createdAt?: string;
  /** @format date-time */
  updatedAt?: string;
  /** @format date-time */
  deletedAt?: string;
  vin: string;
  make: string;
  makeInternalDatReference?: string;
  model: string;
  modelInternalDatReference?: string;
  /** @format double */
  cleanMakeId: number;
  cleanMake: string;
  /** @format double */
  cleanModelId: number;
  cleanModel: string;
  /** @format double */
  cleanGroupId: number;
  cleanGroup: string;
  datBaseModelRaw: string;
  ez: string;
  /** @format double */
  mileageInKm: number;
  isOdometerInMiles: boolean;
  category: EVehicleCategory;
  transmission: ETransmissionType;
  upholstery: EUpholsteryType;
  /** @format double */
  numSeats: number;
  fuelType: EFuelType;
  doors: EVehicleDoors;
  /** @format double */
  engineSizeInCcm: number;
  /** @format double */
  enginePowerInHp: number;
  /** @format double */
  secondaryEnginePowerInHp: number;
  /** @format double */
  combinedEnginePowerInHp: number;
  /** @format double */
  totalRangeInKm: number;
  /** @format double */
  batteryRangeInKm: number;
  /** @format double */
  batteryCapacityInKwh: number;
  urlToBatteryConditionCertificate: string | null;
  /** @format double */
  quickChargeInHours: number;
  /** @format double */
  normalChargeInHours: number;
  chargerPlugType: string;
  energyEfficiencyClass: string;
  batteryConstructionType: string;
  /** @format double */
  batteryVoltage: number;
  isPluginSystem: boolean;
  /** @format double */
  energyConsumption: number;
  hasRentalBattery: boolean;
  bodyColorCode: EVehicleColor;
  /** @format double */
  dimensionWidthInCm: number;
  /** @format double */
  dimensionHeightInCm: number;
  /** @format double */
  dimensionLengthInCm: number;
  /** @format double */
  unloadedWeightInKg: number;
  lastHu: string;
  huReportExists: boolean;
  huReportAvailable: EHuReportAvailable;
  urlsToHuReport: string[];
  /** @format double */
  numPreOwners: number;
  /** @format double */
  numKeys: number;
  vatIdReportable: boolean;
  fullServiceHistoryType: EVehicleFullServiceHistoryType;
  serviceHistoryAvailability: EVehicleServiceHistoryAvailability;
  urlsToServiceHistoryDocument: string[];
  hasMaintenanceBook: boolean;
  urlsToMaintenanceBook: string[];
  isReimportedVehicle: boolean;
  euroNorm: EEuroNorm;
  hadAccident: boolean;
  accidentDescription: string;
  hasDamages: boolean;
  damagesDescription: string;
  damages: IVehicleDamage[];
  additionalInfo: string;
  readyToDrive: EVehicleReadyToDriveStatus;
  readyToDriveDetails: string;
  vehicleImages: IVehicleImage[];
  urlToMotorSound?: string;
  urlToAttachment1: string | null;
  urlToAttachment2: string | null;
  urlToAttachment3: string | null;
  urlToVehicleSummarySheet: string;
  isRegistrationDocumentAvailable: boolean;
  urlToRegistrationDocument: string;
  urlsToRegistrationDocument: string[];
  isRegistrationDocumentPart2Available: boolean;
  urlsToRegistrationDocumentPart2: string[];
  urlsToOtherDocuments: string[];
  urlsToMotorRecordings: string[];
  urlsByLanguageToExpose?: IVehicleExposeByLanguageMap;
  /** @format double */
  estimatedValue: number;
  lastServiceInspectionDate: string;
  /** @format double */
  lastServiceInspectionMileage: number;
  isCocDocumentAvailable: boolean;
  urlsToCocDocument: string[];
  isDataExcerptAvailable: boolean;
  urlsToDataExcerpt: string[];
  countryOfLastRegistration: ECountryCode;
  origin: EVehicleCreationOrigin;
  dataSource: EVehicleDataSource;
  paintState: IVehiclePaintState[];
  tires: IVehicleTireSet[];
  dataWarnings: EVehicleDataWarning[];
  inspectionUuid: string;
  technicalState: IVehicleTechState[];
  fieldsConfirmationStatus: IVehicleFieldConfirmationStatusMap;
  licensePlate: string;
  fuelConsumption: IFuelConsumption;
  co2Emission: ICo2Emission;
  commercialUsage: EVehicleCommercialUsage[];
  isVehicleClassN1: boolean;
  isIntendedUse01: boolean;
  hasEndorsements: boolean;
  sourceLanguage: ELanguageCode;
  isRollable: boolean;
  ac: EAirCondition;
  /** @format double */
  navigation: number;
  headlights: EHeadlights;
  coupling: ECoupling;
  vehicleHeater: EVehicleHeater;
  parkingAssistance: EParkingAssistance;
  /** @format double */
  sunRoof: number;
  /** @format double */
  sportPackage: number;
  isToolkitIncluded: boolean;
  lockingWheelNut: ELockingWheelNut;
  hasAnimalSmell: boolean;
  hasSmokeSmell: boolean;
  equipmentData: IVehicleEquipmentData[];
  equipmentHighlights: IVehicleEquipmentHighlightData[];
  linesByLanguage?: IVehicleLineByLanguageMap;
  externalLineCode?: string;
  externalReports?: IVehicleExternalReport[];
  obd2Readings?: IVehicleObd2Reading[];
  obd2ReadingsFailure?: boolean;
  countOfDrivenAxles: EVehicleCountOfDrivenAxles;
}

export interface IVehicleValuation {
  /** @format double */
  originalPrice: number;
  /** @format double */
  purchasePrice: number;
  /** @format double */
  basePrice: number;
  /** @format double */
  salesPrice: number;
  /** @format double */
  equipmentPrice: number;
}

export interface IVehicleEquipmentDataI18NMapping {
  uuid: string;
  _fk_uuid_vehicle: string;
  language: ELanguageCode;
  data: IVehicleEquipmentData[];
  highlights: IVehicleEquipmentHighlightData[];
  /** Transient field coming from vehicle service. */
  vehicleUuid?: string;
}

export interface IDATOption {
  label: string;
  internalReference: string;
}

export type IVehicleMake = IDATOption;

export enum EVehicleType {
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
  Value5 = 5,
}

export interface IVehicleBaseModel {
  label: string;
  internalReference: string;
  type?: EVehicleType;
}

export type IVehicleSubModel = IDATOption;

export type IVehicleEngineOption = IDATOption;

export type IVehicleGearingOption = IDATOption;

export type IVehicleBodyOption = IDATOption;

export type IVehicleEquipmentOption = IDATOption;

export type IVehicleTypeOfDriveOption = IDATOption;

export type IVehicleWheelBaseOption = IDATOption;

/**
 * The status of a GPR.
 * This status tracks a GPR through every stage of its creation, submission and fulfillment process.
 */
export enum EGPRStatus {
  Value1 = -1,
  Value0 = 0,
  Value11 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
  Value5 = 5,
  Value6 = 6,
  Value7 = 7,
  Value8 = 8,
  Value9 = 9,
  Value10 = 10,
  Value112 = 11,
  Value12 = 12,
  Value13 = 13,
  Value14 = 14,
  Value15 = 15,
  Value16 = 16,
}

/** The possible reasons that a GPR could be rejected. */
export enum EGPRRejectionReason {
  Value1 = -1,
  Value11 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
  Value5 = 5,
}

/** The different possible basic taxation types for vehicles/auctions. */
export enum ETaxationTypes {
  Regelbesteuert = 'Regelbesteuert',
  Differenzbesteuert = 'Differenzbesteuert',
}

/** The possible special equipment for a given vehicle in a GPR. */
export interface IGPRSpecialEquipment {
  climateControl?: boolean;
  cruiseControl?: boolean;
  navigationSystem?: boolean;
  allWheelDrive?: boolean;
  trailerHitch?: boolean;
  parkingAssistance?: boolean;
  multifunctionSteeringWheel?: boolean;
  sunRoof?: boolean;
  adaptiveCorneringLights?: boolean;
  ledHeadlights?: boolean;
  auxiliaryHeating?: boolean;
  other?: string;
  gpCheckboxesComment?: string;
}

/** The possible interior equipment for a given vehicle in a GPR. */
export interface IGPRInteriorEquipment {
  seatHeating?: boolean;
  foldablePassengerSeat?: boolean;
  eletricSeatAdjustment?: boolean;
  heatedSteeringWheel?: boolean;
  chargingStationForPhones?: boolean;
  speakerPhone?: boolean;
  parkingHeater?: boolean;
  leatherInterior?: boolean;
  partlyLeatherInterior?: boolean;
  alcantaraInterior?: boolean;
  other?: string;
  gpCheckboxesComment?: string;
}

/** The description of a damage for a vehicle in a GPR. */
export interface IGPRVisibleDamage {
  /** The description about the visible damage translated (it will always be on DE). */
  description?: string;
  /** The original description inputted by the user about the visible damage in the user chosen language. */
  descriptionOriginal?: string;
  imageURLs?: string[];
}

/** The vehicle data for a GPR. */
export interface IGPRVehicleData {
  vin: string;
  make?: string;
  model?: string;
  modelVariant?: string;
  vehicleType?: EVehicleType;
  category?: EVehicleCategory;
  transmission?: ETransmissionType;
  fuelType?: EFuelType;
  /** @format double */
  mileageInKm?: number;
  firstRegistration?: string;
  bodyColorCode?: EVehicleColor;
  /** @format double */
  engineSizeInCcm?: number;
  /** @format double */
  enginePowerInHp?: number;
  /** @format double */
  numPreOwners?: number;
  numDoors?: EVehicleDoors;
  /** @format double */
  numSeats?: number;
  /** @format double */
  numTireSets?: number;
  emissionClass?: EEuroNorm;
  /** The different possible basic taxation types for vehicles/auctions. */
  taxationType?: ETaxationTypes;
  nonSmokingVehicle?: boolean;
  checkbookMaintained?: boolean;
  noSpecialEquipmentData?: boolean;
  /** The possible special equipment for a given vehicle in a GPR. */
  specialEquipment?: IGPRSpecialEquipment;
  /** The comment about the special equipment translated (it will always be on DE). */
  specialEquipmentComment?: string;
  /** The original comment inputted by the user about the special equipment in the user chosen language. */
  specialEquipmentCommentOriginal?: string;
  noInteriorEquipmentData?: boolean;
  /** The possible interior equipment for a given vehicle in a GPR. */
  interiorEquipment?: IGPRInteriorEquipment;
  /** The comment about the interior equipment translated (it will always be on DE). */
  interiorEquipmentComment?: string;
  /** The original comment inputted by the user about the interior equipment in the user chosen language. */
  interiorEquipmentCommentOriginal?: string;
  vehicleImageURLs?: string[];
  noTechnicalDamagesData?: boolean;
  /** @format double */
  technicalDamageRepairCostEstimate?: number;
  /** The comment about the technical damages translated (it will always be on DE). */
  technicalDamageComment?: string;
  /** The original comment inputted by the user about the technical damages in the user chosen language. */
  technicalDamageCommentOriginal?: string;
  /** A flag that allows the user to skip the step to describe technical damages if they are already inside the report. */
  skipTechnicalDamages?: boolean;
  noVisibleDamagesData?: boolean;
  visibleDamages?: IGPRVisibleDamage[];
  /** The comment about the visible damages translated (it will always be on DE). */
  visibleDamageComment?: string;
  /** The original comment inputted by the user about the visible damages in the user chosen language. */
  visibleDamageCommentOriginal?: string;
  /** A flag that allows the user to skip the step to describe visible damages if they are already inside the report. */
  skipVisibleDamages?: boolean;
  noReportData?: boolean;
  reportURL?: string;
  vehicleTitleURL?: string;
  vehicleRegistrationURL?: string;
  /** A flag that allows the user to skip uploading the vehicle images if they are already inside the report. */
  skipVehicleImage?: boolean;
  /** Flag used to determine if the vehicle is NoVa taxed. Only applies to vehicles being sold from and within Austria. */
  noVaTaxed?: boolean;
}

/** Identify origins from GPR request */
export enum EGPRIntegrationOrigin {
  ALPHA_ONLINE = 'ALPHA_ONLINE',
  SELLER = 'SELLER',
}

/**
 * Object description of the GPR object.
 * Most fields are optional because GPRs can be created in a draft state with minimal data and edited from there on.
 * Validation of completion, therefore, takes place upon submission rather than object creation.
 */
export interface IGuaranteedPriceRequest {
  /**
   * @deprecated
   * @format double
   */
  id: number;
  uuid: string;
  /**
   * Indicating the time when the entity has been created, i.e. when the entity was
   * persisted to the database.
   * @format date-time
   */
  createdAt?: string;
  /**
   * Indicating the last time a value of the entity has been changed (updated).
   * @format date-time
   */
  updatedAt?: string;
  /**
   * Indicating the time when the entity has been (softly) deleted.
   * "null" if entry is not deleted.
   * @format date-time
   */
  deletedAt?: string;
  /** If an auction is created in association with a GPR, the uuid of the auction is linked here. */
  _fk_uuid_auction?: string;
  /** The UUID of the creating seller user. */
  _fk_uuid_sellerUser?: string;
  /** The UUID of the creating seller account. */
  _fk_uuid_sellerAccount?: string;
  /** The UUID of the internal user who fulfilled this GPR. */
  _fk_uuid_fulfillingInternalUser?: string;
  /** The UUID of the principal pricer who filled in the principalPriceSuggestion */
  _fk_uuid_principalPricerInternalUser?: string;
  /** The UUID of the secondary pricer who filled in the secndaryPriceSuggestion */
  _fk_uuid_secondaryPricerInternalUser?: string;
  /** The current status of the GPR. */
  status?: EGPRStatus;
  /**
   * The datetime that the GPR was submitted at (completed and sent for review).
   * @format date-time
   */
  submittedAt?: string;
  /**
   * The datetime that the GPR was fulfilled at (when the guaranteed price was sent back).
   * @format date-time
   */
  fulfilledAt?: string;
  /**
   * The date at which a GPR was rejected at. If null, this means the GPR has not been rejected.
   * @format date-time
   */
  rejectedAt?: string;
  /**
   * The date at which the principal pricer suggested a price (without immediately fulfilling the GPR)
   * @format date-time
   */
  principalGuaranteedPriceSuggestedAt?: string;
  /**
   * The date at which a secondary pricer suggested a price
   * @format date-time
   */
  secondaryGuaranteedPriceSuggestedAt?: string;
  /** The supplied reason that the GPR was rejected, if they were rejected. */
  rejectionReason?: EGPRRejectionReason;
  /** The comment that allows the price team to explain their decision when rejecting the GPR. */
  rejectionReasonComment?: string;
  /** The comment that allows the price team to explain the pricing decision when fulfilling the GPR. */
  fulfillmentReasonComment?: string;
  /**
   * The latest datetime a fulfilled GPR can be booked.
   * Calculated as GPR_UNBOOKED_VALIDITY_DURATION_DAYS after fulfilledAt or extendedAt.
   * @format date-time
   */
  bookableUntil?: string;
  /**
   * The datetime that an inspector was booked for a GPR.
   * This denotes an acceptance of the GP.
   * @format date-time
   */
  inspectorBookedAt?: string;
  /**
   * The datetime that the GPR was extended at (when the data team booked it after the expiration date).
   * @format date-time
   */
  extendedAt?: string;
  /**
   * The datetime that the GPR was refreshed at (when the seller refresh a rejected/expired GPR back to the submit state).
   * @format date-time
   */
  refreshedAt?: string;
  /**
   * The number of days that the GPR is valid for.
   * Counted from the `fulfilledAt` timestamp.
   * @format double
   */
  daysValidFor?: number;
  /**
   * The guaranteed price that the principal pricer would choose (while waiting for the secondary)
   * Usually the secondary price comes first, and the principal pricer just enters a final price.
   * But in case there is no secondary price yet, he can store his suggestion here
   * @format double
   */
  principalGuaranteedPriceSuggestion?: number;
  /**
   * A suggestion for the guaranteed price made by the secondary pricer.
   * @format double
   */
  secondaryGuaranteedPriceSuggestion?: number;
  /**
   * The final guaranteed price which was calculated by COS.
   * @format double
   */
  guaranteedPrice?: number;
  /**
   * The fee which will be paid by the user should they accept the GP and book an inspector.
   * @format double
   */
  calculatedFee?: number;
  /** The data of the vehicle desired to be guaranteed. */
  vehicleData: IGPRVehicleData;
  /** The URL for the document that is created after the GPR is fulfilled. */
  documentReportURL?: string;
  /**
   * Fields that serve to improve the inspection process after request for the internal users,
   * a seller user should be able to select date of inspection and the location of the vehicle.
   * Some sellers also do have regular appointments, in this case they do not have to select a date.
   * @format date-time
   */
  appointmentDate?: string;
  selectedLocation?: string;
  /**
   * A flag in which says if the GPR can be refreshed.
   * A GPR can be refreshed when it was rejected or expired.
   */
  isRefreshAllowed?: boolean;
  /**
   * Property to identify the origin of the request to create the GPR.
   * Used when integrating with third-party systems.
   */
  origin?: EGPRIntegrationOrigin;
}

/**
 * The structure of the response from the data fulfillment for GPRs.
 * This is the answer which contains the calculated GPR values which are determined by COS personnel.
 */
export interface IGPRFulfillmentResponse {
  /**
   * The final guaranteed price which was calculated by COS.
   * @format double
   */
  guaranteedPrice?: number;
  /**
   * The fee which will be paid by the user should they accept the GP and book an inspector.
   * @format double
   */
  calculatedFee?: number;
  /** The comment that allows the price team to explain the pricing decision when fulfilling the GPR. */
  fulfillmentReasonComment?: string;
  /** The supplied reason that the GPR was rejected, if they were rejected. */
  rejectionReason?: EGPRRejectionReason;
  /** The comment that allows the price team to explain their decision when rejecting the GPR. */
  rejectionReasonComment?: string;
  /**
   * The guaranteed price that the principal pricer would choose (while waiting for the secondary)
   * Usually the secondary price comes first, and the principal pricer just enters a final price.
   * But in case there is no secondary price yet, he can store his suggestion here
   * @format double
   */
  principalGuaranteedPriceSuggestion?: number;
  /**
   * A suggestion for the guaranteed price made by the secondary pricer.
   * @format double
   */
  secondaryGuaranteedPriceSuggestion?: number;
  fulfillingInternalUserUuid?: string;
  rejected?: boolean;
}

export enum EMailTemplate {
  DEMO_REQUESTED_SELLER = 'DEMO_REQUESTED_SELLER',
  DEMO_REQUESTED_SELLER_INTERNAL = 'DEMO_REQUESTED_SELLER_INTERNAL',
  TEST_ACCOUNT_DEALERSHIP_WITH_TRADE_IN_WIDGET = 'TEST_ACCOUNT_DEALERSHIP_WITH_TRADE_IN_WIDGET',
  TEST_ACCOUNT_DEALERSHIP_WITHOUT_TRADE_IN_WIDGET = 'TEST_ACCOUNT_DEALERSHIP_WITHOUT_TRADE_IN_WIDGET',
  INTERNAL_AUCTION_RENEGOTIATION_TIMEOUT_FOR_SELLER = 'INTERNAL_AUCTION_RENEGOTIATION_TIMEOUT_FOR_SELLER',
  INTERNAL_AUCTION_RENEGOTIATION_TIMEOUT_FOR_BUYER = 'INTERNAL_AUCTION_RENEGOTIATION_TIMEOUT_FOR_BUYER',
  INTERNAL_INSPECTOR_REQUESTED_FOR_TRADE_IN_VALUATION_BY_SELLER = 'INTERNAL_INSPECTOR_REQUESTED_FOR_TRADE_IN_VALUATION_BY_SELLER',
  INTERNAL_INSPECTOR_REQUESTED_BY_USER = 'INTERNAL_INSPECTOR_REQUESTED_BY_USER',
  INTERNAL_INSPECTOR_REQUEST_CHANGED_BY_USER = 'INTERNAL_INSPECTOR_REQUEST_CHANGED_BY_USER',
  INTERNAL_INSPECTOR_REQUESTED_FOR_GPR_BY_SELLER = 'INTERNAL_INSPECTOR_REQUESTED_FOR_GPR_BY_SELLER',
  INTERNAL_AUCTION_CREATED_BY_INSPECTOR = 'INTERNAL_AUCTION_CREATED_BY_INSPECTOR',
  SELLER_AUCTION_SUCCESSFUL_CLASSIC_INVOICING = 'SELLER_AUCTION_SUCCESSFUL_CLASSIC_INVOICING',
  SELLER_AUCTION_SUCCESSFUL_COS_INVOICING = 'SELLER_AUCTION_SUCCESSFUL_COS_INVOICING',
  SELLER_AUCTION_SUCCESSFUL_COS_NO_INVOICING = 'SELLER_AUCTION_SUCCESSFUL_COS_NO_INVOICING',
  SELLER_AUCTION_SUCCESSFUL_COS_NO_INVOICING_WITHOUT_DOCUMENT_SHIPPING = 'SELLER_AUCTION_SUCCESSFUL_COS_NO_INVOICING_WITHOUT_DOCUMENT_SHIPPING',
  SELLER_AUCTION_SUCCESSFUL_COS_INVOICING_BUYER = 'SELLER_AUCTION_SUCCESSFUL_COS_INVOICING_BUYER',
  SELLER_AUCTION_SUCCESSFUL_COS_INVOICING_BUYER_WITHOUT_DOCUMENT_SHIPPING = 'SELLER_AUCTION_SUCCESSFUL_COS_INVOICING_BUYER_WITHOUT_DOCUMENT_SHIPPING',
  SELLER_INSTANT_PURCHASE_AUCTION_UNSUCCESSFUL = 'SELLER_INSTANT_PURCHASE_AUCTION_UNSUCCESSFUL',
  SELLER_PAYOUT_SUCCESSFUL = 'SELLER_PAYOUT_SUCCESSFUL',
  SELLER_PAYOUT_SUCCESSFUL_WITHOUT_DOCUMENT_SHIPPING = 'SELLER_PAYOUT_SUCCESSFUL_WITHOUT_DOCUMENT_SHIPPING',
  SELLER_PAYOUT_SUCCESSFUL_WITH_EXPRESS_PICKUP = 'SELLER_PAYOUT_SUCCESSFUL_WITH_EXPRESS_PICKUP',
  SELLER_PAYOUT_SUCCESSFUL_WITH_EXPRESS_PICKUP_WITHOUT_DOCUMENT_SHIPPING = 'SELLER_PAYOUT_SUCCESSFUL_WITH_EXPRESS_PICKUP_WITHOUT_DOCUMENT_SHIPPING',
  SELLER_AUCTION_ENDED_BELOW_MINIMUM_ASK = 'SELLER_AUCTION_ENDED_BELOW_MINIMUM_ASK',
  DEALERSHIP_INCOMING_TRADE_IN_APPOINTMENT = 'DEALERSHIP_INCOMING_TRADE_IN_APPOINTMENT',
  DEALERSHIP_NEWSLETTER = 'DEALERSHIP_NEWSLETTER',
  TRADE_IN_VALUATION = 'TRADE_IN_VALUATION',
  FAILED_TRADE_IN_VALUATION = 'FAILED_TRADE_IN_VALUATION',
  SELLER_AUCTION_REVIEW_REJECTED = 'SELLER_AUCTION_REVIEW_REJECTED',
  SELLER_AUCTION_REVIEW_APPROVED = 'SELLER_AUCTION_REVIEW_APPROVED',
  SELLER_PREREGISTERED_ACCOUNT_CREATED = 'SELLER_PREREGISTERED_ACCOUNT_CREATED',
  SELLER_AUCTION_LISTING_REPORT = 'SELLER_AUCTION_LISTING_REPORT',
  SELLER_TRANSPORTATION_TASK_BOOKED = 'SELLER_TRANSPORTATION_TASK_BOOKED',
  SELLER_INCOMING_TRADE_IN_APPOINTMENT_WITH_VALUATION = 'SELLER_INCOMING_TRADE_IN_APPOINTMENT_WITH_VALUATION',
  SELLER_INCOMING_TRADE_IN_APPOINTMENT_WITHOUT_VALUATION = 'SELLER_INCOMING_TRADE_IN_APPOINTMENT_WITHOUT_VALUATION',
  SELLER_AUCTION_RENEGOTIATION_INTERMEDIATE_ROUND_ENDED = 'SELLER_AUCTION_RENEGOTIATION_INTERMEDIATE_ROUND_ENDED',
  SELLER_AUCTION_RENEGOTIATION_FINAL_ROUND_ENDED = 'SELLER_AUCTION_RENEGOTIATION_FINAL_ROUND_ENDED',
  SELLER_AUCTION_STARTING_PAYMENT_REMINDER_PROCESS = 'SELLER_AUCTION_STARTING_PAYMENT_REMINDER_PROCESS',
  SELLER_PREREGISTERED_ACCOUNT_CREATED_NO_PASSWORD = 'SELLER_PREREGISTERED_ACCOUNT_CREATED_NO_PASSWORD',
  SELLER_AUCTION_RENEGOTIATION_TIMEOUT = 'SELLER_AUCTION_RENEGOTIATION_TIMEOUT',
  SELLER_AUCTION_DRAFT_REVIEW = 'SELLER_AUCTION_DRAFT_REVIEW',
  SELLER_SHIPPING_ORDER_PREPARE_DOCUMENTS = 'SELLER_SHIPPING_ORDER_PREPARE_DOCUMENTS',
  SELLER_SHIPPING_ORDER_SHIP_DOCUMENTS = 'SELLER_SHIPPING_ORDER_SHIP_DOCUMENTS',
  SELLER_SHIPPING_ORDER_SHIP_DOCUMENTS_WITH_EXPRESS_PICKUP = 'SELLER_SHIPPING_ORDER_SHIP_DOCUMENTS_WITH_EXPRESS_PICKUP',
  SELLER_SHIPPING_ORDER_SHIP_DOCUMENTS_WITHOUT_DOCUMENT_SHIPPING = 'SELLER_SHIPPING_ORDER_SHIP_DOCUMENTS_WITHOUT_DOCUMENT_SHIPPING',
  SELLER_SHIPPING_ORDER_SHIP_DOCUMENTS_WITH_EXPRESS_PICKUP_WITHOUT_DOCUMENT_SHIPPING = 'SELLER_SHIPPING_ORDER_SHIP_DOCUMENTS_WITH_EXPRESS_PICKUP_WITHOUT_DOCUMENT_SHIPPING',
  SELLER_SHIPPING_ORDER_RECEIVED_DOCUMENTS = 'SELLER_SHIPPING_ORDER_RECEIVED_DOCUMENTS',
  SELLER_INVOICE_MISSING_REMINDER = 'SELLER_INVOICE_MISSING_REMINDER',
  SELLER_SHIPPING_ORDER_DOCUMENTS_MISSING = 'SELLER_SHIPPING_ORDER_DOCUMENTS_MISSING',
  SELLER_TRANSFER_VEHICLE_OWNERSHIP_REMINDER = 'SELLER_TRANSFER_VEHICLE_OWNERSHIP_REMINDER',
  SELLER_GUARANTEED_PRICE_REQUEST_FULFILLED = 'SELLER_GUARANTEED_PRICE_REQUEST_FULFILLED',
  SELLER_GUARANTEED_PRICE_REQUEST_BOOKED = 'SELLER_GUARANTEED_PRICE_REQUEST_BOOKED',
  SELLER_GUARANTEED_PRICE_REQUEST_REJECTED = 'SELLER_GUARANTEED_PRICE_REQUEST_REJECTED',
  SELLER_AUCTION_READY_TO_REINSERT = 'SELLER_AUCTION_READY_TO_REINSERT',
  SELLER_AUCTION_READY_TO_REINSERT_HALFWAY_THROUGH_PERIOD = 'SELLER_AUCTION_READY_TO_REINSERT_HALFWAY_THROUGH_PERIOD',
  SELLER_AUCTION_READY_TO_REINSERT_TO_BE_DISABLED = 'SELLER_AUCTION_READY_TO_REINSERT_TO_BE_DISABLED',
  SELLER_CONDITIONAL_PAYOUTS_NO_INVOICE_REMINDER = 'SELLER_CONDITIONAL_PAYOUTS_NO_INVOICE_REMINDER',
  SELLER_INSPECTOR_REQUESTED = 'SELLER_INSPECTOR_REQUESTED',
  MORNING_BRIEFING = 'MORNING_BRIEFING',
  EXPIRING_AUCTION_REMINDER = 'EXPIRING_AUCTION_REMINDER',
  FIRST_CONTACT_SALESMAN = 'FIRST_CONTACT_SALESMAN',
  COLD_ACQUISITION_SALESMAN = 'COLD_ACQUISITION_SALESMAN',
  BUYER_AUCTION_PURCHASE_OVERVIEW = 'BUYER_AUCTION_PURCHASE_OVERVIEW',
  BUYER_FINAL_WELCOME_TO_COS = 'BUYER_FINAL_WELCOME_TO_COS',
  BUYER_DOCUMENT_CHECK_STARTING = 'BUYER_DOCUMENT_CHECK_STARTING',
  BUYER_CONFIRM_EMAIL_ADDRESS = 'BUYER_CONFIRM_EMAIL_ADDRESS',
  BUYER_TEST_ACCOUNT_CREATED = 'BUYER_TEST_ACCOUNT_CREATED',
  BUYER_TEST_ACCOUNT_CREATED_NO_PASSWORD = 'BUYER_TEST_ACCOUNT_CREATED_NO_PASSWORD',
  BUYER_OUTBID_ON_AUCTION = 'BUYER_OUTBID_ON_AUCTION',
  BUYER_AUCTION_SUCCESSFUL = 'BUYER_AUCTION_SUCCESSFUL',
  BUYER_AUCTION_SUCCESSFUL_WITH_EXTERNAL_FULFILLMENT = 'BUYER_AUCTION_SUCCESSFUL_WITH_EXTERNAL_FULFILLMENT',
  BUYER_AUCTION_SUCCESSFUL_WITH_INVOICES = 'BUYER_AUCTION_SUCCESSFUL_WITH_INVOICES',
  BUYER_AUCTION_SUCCESSFUL_WITHOUT_DOCUMENT_SHIPPING = 'BUYER_AUCTION_SUCCESSFUL_WITHOUT_DOCUMENT_SHIPPING',
  BUYER_AUCTION_SUCCESSUL_CLASSIC_INVOICE = 'BUYER_AUCTION_SUCCESSUL_CLASSIC_INVOICE',
  BUYER_AUCTION_SUCCESSUL_CLASSIC_INVOICE_WITHOUT_DOCUMENT_SHIPPING = 'BUYER_AUCTION_SUCCESSUL_CLASSIC_INVOICE_WITHOUT_DOCUMENT_SHIPPING',
  BUYER_AUCTION_ENDED_BELOW_MINIMUM_ASK = 'BUYER_AUCTION_ENDED_BELOW_MINIMUM_ASK',
  BUYER_AUCTION_END_NON_EXCLUSIVE = 'BUYER_AUCTION_END_NON_EXCLUSIVE',
  BUYER_AUCTION_DISABLED_NON_EXCLUSIVE = 'BUYER_AUCTION_DISABLED_NON_EXCLUSIVE',
  BUYER_AUCTION_REJECTED_BELOW_MINIMUM_ASK = 'BUYER_AUCTION_REJECTED_BELOW_MINIMUM_ASK',
  BUYERAUCTION2DAYPAYMENTREMINDERNOTCOSPAY = 'BUYER_AUCTION_2_DAY_PAYMENT_REMINDER_NOT_COSPAY',
  BUYERAUCTION2DAYPAYMENTREMINDERWITHCOSPAY = 'BUYER_AUCTION_2_DAY_PAYMENT_REMINDER_WITH_COSPAY',
  BUYER_AUCTION_PAYMENT_CONFIRMATION = 'BUYER_AUCTION_PAYMENT_CONFIRMATION',
  BUYER_AUCTION_PAYMENT_TRIGGERED_INVOICE_DOWNLOAD_INFO = 'BUYER_AUCTION_PAYMENT_TRIGGERED_INVOICE_DOWNLOAD_INFO',
  BUYER_ADDED_TO_SELLER_REGULAR_BUYERS = 'BUYER_ADDED_TO_SELLER_REGULAR_BUYERS',
  BUYER_TRANSPORTATION_TASK_BOOKED = 'BUYER_TRANSPORTATION_TASK_BOOKED',
  BUYER_TRANSPORTATION_TASK_VEHICLE_PICKED_UP = 'BUYER_TRANSPORTATION_TASK_VEHICLE_PICKED_UP',
  BUYERPICKUPREMINDER1 = 'BUYER_PICKUP_REMINDER_1',
  BUYERPICKUPREMINDER2 = 'BUYER_PICKUP_REMINDER_2',
  BUYERAUCTIONENTERING24HOURINSTANTPURCHASE = 'BUYER_AUCTION_ENTERING_24_HOUR_INSTANT_PURCHASE',
  BUYER_RECOMMENDED_AUCTIONS = 'BUYER_RECOMMENDED_AUCTIONS',
  BUYER_LOST_AUCTION = 'BUYER_LOST_AUCTION',
  BUYER_AUCTION_DEACTIVATED = 'BUYER_AUCTION_DEACTIVATED',
  BUYER_AUCTION_NOT_SUCCESSFUL = 'BUYER_AUCTION_NOT_SUCCESSFUL',
  BUYER_AUCTION_LAST_OFFER_SET = 'BUYER_AUCTION_LAST_OFFER_SET',
  BUYER_AUCTION_RENEGOTIATION_INTERMEDIATE_ROUND_ENDED = 'BUYER_AUCTION_RENEGOTIATION_INTERMEDIATE_ROUND_ENDED',
  BUYER_AUCTION_RENEGOTIATION_FINAL_ROUND_ENDED = 'BUYER_AUCTION_RENEGOTIATION_FINAL_ROUND_ENDED',
  BUYER_AUCTION_RENEGOTIATION_TIMEOUT = 'BUYER_AUCTION_RENEGOTIATION_TIMEOUT',
  BUYER_SHIPPING_ORDER_DOCUMENTS_SCANNED = 'BUYER_SHIPPING_ORDER_DOCUMENTS_SCANNED',
  BUYER_SHIPPING_ORDER_IN_TRANSIT = 'BUYER_SHIPPING_ORDER_IN_TRANSIT',
  BUYERAUCTION5DAYPAYMENTREMINDER = 'BUYER_AUCTION_5_DAY_PAYMENT_REMINDER',
  BUYER_AUCTION_SAVED_SEARCH_NEW_RESULTS = 'BUYER_AUCTION_SAVED_SEARCH_NEW_RESULTS',
  PREREGISTERED_USER_AUCTION_SAVED_SEARCH_NEW_RESULTS = 'PREREGISTERED_USER_AUCTION_SAVED_SEARCH_NEW_RESULTS',
  BUYER_SEND_CONFIRMATION_OF_ARRIVAL_DOCUMENT = 'BUYER_SEND_CONFIRMATION_OF_ARRIVAL_DOCUMENT',
  BUYER_EXPIRING_PARKED_AUCTIONS = 'BUYER_EXPIRING_PARKED_AUCTIONS',
  BUYER_SIGNUP_CONFIRMATION = 'BUYER_SIGNUP_CONFIRMATION',
  BUYER_SIGNUP_CONFIRMATION_REMINDER = 'BUYER_SIGNUP_CONFIRMATION_REMINDER',
  BUYER_SIGNUP_DUPLICATE = 'BUYER_SIGNUP_DUPLICATE',
  BUYER_AUCTION_BNPL_DECLINED = 'BUYER_AUCTION_BNPL_DECLINED',
  BUYER_AUCTION_BNPL_ACCEPTED = 'BUYER_AUCTION_BNPL_ACCEPTED',
  BUYER_AUCTION_BNPL_APPROVED = 'BUYER_AUCTION_BNPL_APPROVED',
  BUYERBNPL5DAYPAYMENTREMINDER = 'BUYER_BNPL_5_DAY_PAYMENT_REMINDER',
  BUYER_BNPL_ACCESS_ENABLED = 'BUYER_BNPL_ACCESS_ENABLED',
  BUYER_BNPL_PAYMENT_RECEIVED = 'BUYER_BNPL_PAYMENT_RECEIVED',
  RESET_PASSWORD = 'RESET_PASSWORD',
}

export enum ECustomerMessageType {
  TRANSACTIONAL = 'TRANSACTIONAL',
  MARKETING = 'MARKETING',
  PERSONAL = 'PERSONAL',
}

export interface IEmailTemplateSettings {
  /** Unique id for e-mail template settings */
  uuid: string;
  /** Enum value for the current e-mail template settings */
  emailTemplate: EMailTemplate;
  /** Name of the e-mail template */
  name: string;
  /** E-mail template category. Transactional or marketing */
  templateType: ECustomerMessageType;
  /** E-mail template external unique ID */
  externalId: string;
  /** Flag to enable or disable sending e-mail using the e-mail template */
  isActive: boolean;
  /**
   * Group id used for e-mail template unsubscription
   * @format double
   */
  unsubscribeGroupId?: number;
  /** Used for define country-specific template settings */
  countryCode?: ECountryCode;
}

/** Parameters used on test e-mail sending to decide which fields should be included on the e-mail payload */
export interface ITestMailPayloadParams {
  includesAuction?: boolean;
  includesAssociatedVehicle?: boolean;
  includesBuyer?: boolean;
  includesSeller?: boolean;
  includesAuctionList?: boolean;
}

export interface ITestMailSendRequest {
  recipientMailAddresses: string[];
  sendingMailAddress: string;
  template: EMailTemplate;
  templateParams?: any;
  ccMailAddresses?: string[];
  localeCode?: string;
  countryCode?: ECountryCode;
  /** Parameters used on test e-mail sending to decide which fields should be included on the e-mail payload */
  payloadParams: ITestMailPayloadParams;
}

/** A bank account to use for funding an account. */
export interface IFundingBankAccount {
  /** The account holder's name. */
  accountHolderName: string;
  /** The BIC/SWIFT code for the bank account. */
  bic: string;
  /** The country the bank account is in. */
  country: string;
  /** The iban for the bank account. */
  iban: string;
}

/** The possible types of an item in the payment overview. */
export enum EPaymentOverviewItemType {
  Order = 'order',
  Invoice = 'invoice',
}

/** The possible types of an order in the payment overview. Currently only auctions are supported. */
export enum EPaymentOverviewOrderType {
  Auction = 'auction',
  Other = 'other',
}

export enum EInvoiceStatus {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
}

/** Values indicating different articles (products) that invoices can be created for in COS. */
export enum EInvoicedArticle {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
  Value5 = 5,
  Value6 = 6,
  Value7 = 7,
  Value8 = 8,
  Value9 = 9,
  Value10 = 10,
  Value11 = 11,
  Value12 = 12,
  Value13 = 13,
  Value14 = 14,
  Value15 = 15,
  Value16 = 16,
  Value17 = 17,
  Value18 = 18,
  Value20 = 20,
  Value21 = 21,
  Value22 = 22,
  Value23 = 23,
  Value24 = 24,
  Value25 = 25,
  Value26 = 26,
  Value27 = 27,
  Value28 = 28,
  Value29 = 29,
  Value30 = 30,
  Value31 = 31,
  Value32 = 32,
  Value10000 = 10000,
  Value99999 = 99999,
}

/**
 * A simplified view containing only the most essential information of an invoice.
 *
 * Mostly used to give an overview of the status and amount that needs to be paid.
 */
export interface IAbridgedInvoice {
  uuid: string;
  /**
   * Indicating the time when the entity has been created, i.e. when the entity was
   * persisted to the database.
   * @format date-time
   */
  createdAt?: string;
  /** Indicates the current status of the invoice */
  status: EInvoiceStatus;
  /** Indicating the article that is invoiced with this invoice. */
  article: EInvoicedArticle;
  /**
   * Flag indicating whether invoice is paid
   * (i.e. actually paid or manually marked as paid).
   */
  isPaid: boolean;
  /**
   * The gross amount (total) sum of the invoice.
   *
   * Even if the invoice is paid, grossAmount will still be the original (total)
   * amount of the invoice.
   * @format double
   */
  grossAmount: number;
  /**
   * The amount that the customer has to pay.
   * This is a dynamic value. If the invoice is paid, this is reduced to 0.
   * @format double
   */
  amountDue: number;
  /**
   * The date when the invoice is due.
   * @format date-time
   */
  dueDate: string;
  /** Invoice reference that is shown to the customer. */
  referenceId: string;
  /** The URL to access the invoice PDF. */
  urlToPdf: string;
  /**
   * Reference to external payment provider (such as Stripe).
   * Could contain IDs for invoice objects in the third-party system.
   */
  externalPaymentReference: string;
  /** UUID of the account that this invoice is associated with. */
  accountUuid: string;
  /**
   * UUID of the associated auction.
   * "null" if no auction is associated with this invoice.
   */
  _fk_uuid_auction: string;
  /** URL which stores the eletronic invoice. */
  urlToElectronicInvoice: string;
  /**
   * Indicating the last time a value of the entity has been changed (updated).
   * @format date-time
   */
  updatedAt?: string;
  /**
   * Indicating the time when the entity has been (softly) deleted.
   * "null" if entry is not deleted.
   * @format date-time
   */
  deletedAt?: string;
}

/** The possible statuses an order can be in. */
export enum EOrderStatus {
  Paid = 'paid',
  Unpaid = 'unpaid',
  Overdue = 'overdue',
  BuyNowPayLater = 'buy-now-pay-later',
}

/** From T, pick a set of properties whose keys are in the union K */
export interface PickIAuctionPurchaseConfirmedAt {
  /**
   * Date+time when the auction is "confirmed to be sold" by the seller (or by an admin).
   * If an auction is ending equal or above the minimum required ask, the "purchaseConfirmedAt"
   * will automatically be set and will be equal to the "endingTime".
   *
   * If an auction is ending below the minimum ask (CLOSED_UNDER_MINIMUM_ASK), the "purchaseConfirmedAt" timestamp
   * is only set if the auction is then manually "confirmed" by the seller or an admin.
   *
   * If an auction is never "successful", the "purchaseConfirmedAt" will remain "null".
   *
   * This value was introduced in the course of 2019. For all previous auctions, the endingTime was set
   * as the "purchaseConfirmedAt" retrospectively.
   * @format date-time
   */
  purchaseConfirmedAt: string;
}

/**
 * Types of deposits you can have for an order.
 *
 * A deposit is an amount that will be later refunded to the customer.
 */
export enum EOrderDepositType {
  VAT = 'VAT',
  EXPORT_DISCOUNT = 'EXPORT_DISCOUNT',
}

/**
 * Describes a deposit for an order created on the P&I service.
 *
 * An order can have multiple deposits.
 */
export interface IOrderDeposit {
  uuid: string;
  /**
   * Indicating the time when the entity has been created, i.e. when the entity was
   * persisted to the database.
   * @format date-time
   */
  createdAt?: string;
  /**
   * Indicating the last time a value of the entity has been changed (updated).
   * @format date-time
   */
  updatedAt?: string;
  /**
   * Indicating the time when the entity has been (softly) deleted.
   * "null" if entry is not deleted.
   * @format date-time
   */
  deletedAt?: string;
  /** @format double */
  amount: number;
  /**
   * Types of deposits you can have for an order.
   *
   * A deposit is an amount that will be later refunded to the customer.
   */
  depositType: EOrderDepositType;
  refundReference?: string;
  chargeReference?: string;
}

/**
 * An order in the payment overview. This is a view of an {@link IOrder} with additional data for the payment overview, such as invoice data, amount due,
 * transfer reference and auction data, in case the order is related to an auction.
 */
export interface IPaymentOverviewOrder {
  uuid: string;
  paymentRequestUrl?: string;
  correlationId: string;
  deposits: IOrderDeposit[];
  /**
   * Indicating the time when the entity has been created, i.e. when the entity was
   * persisted to the database.
   * @format date-time
   */
  createdAt?: string;
  /** The possible types of an order in the payment overview. Currently only auctions are supported. */
  type: EPaymentOverviewOrderType;
  title: string;
  invoices: IAbridgedInvoice[];
  /** @format double */
  amountDue: number;
  /** The possible statuses an order can be in. */
  status: EOrderStatus;
  transferReference: string;
  auction?: PickIAuctionPurchaseConfirmedAt & {
    externalId: string;
  };
}

/** An item in the payment overview. Can be either an order or a standalone invoice (see note on related invoices in {@link IPaymentOverview}). */
export interface IPaymentOverviewItem {
  /** Unique identifier for the item. Matches the UUID inside the `data` object. */
  uuid: string;
  /** The possible types of an item in the payment overview. */
  type: EPaymentOverviewItemType;
  data: IPaymentOverviewOrder | IAbridgedInvoice;
}

/**
 * The payment overview contains all the orders that are associated with a customer, including
 * additional data such as payment details and invoices.
 *
 * This interface supports pagination.
 *
 * ### Technical note
 *
 * We currently only create order for auction purchases, and we don't include all invoice types inside the order.
 * For example, transportation and booked services are never included in the order for an auction, because they can be purchased after
 * the auction ends, but we also never create an order for those types of purchases.
 * However, we want to show all those invoices to the customer when they open their payment overview.
 *
 * To work around this limitation, we've introduced the concept of related invoices. These are invoices that are not part of the order,
 * but share the same correlation ID as the order. This allows us to group all invoices related to a purchase together.
 *
 * There are some implications that come with this workaround, related to pagination:
 * - We don't know how many related invoices there are, so we can't calculate the total number of items in the payment overview.
 * - Pagination is based on orders, the page size is fixed and will always fetch the same amount of orders.
 * However, the items array can be bigger than the page size, and each page will have a different size in the end.
 */
export interface IPaymentOverview {
  items: IPaymentOverviewItem[];
  /** @format double */
  total: number;
  /** @format double */
  page: number;
  /** @format double */
  pageSize?: number;
  accountUuid: string;
  /** A bank account to use for funding an account. */
  paymentDetails: IFundingBankAccount;
}

export enum PickupSlotDay {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
  Value5 = 5,
  Value6 = 6,
}

export interface IPickupSlot {
  uuid: string;
  /**
   * Indicating the time when the entity has been created, i.e. when the entity was
   * persisted to the database.
   * @format date-time
   */
  createdAt?: string;
  /**
   * Indicating the last time a value of the entity has been changed (updated).
   * @format date-time
   */
  updatedAt?: string;
  /**
   * Indicating the time when the entity has been (softly) deleted.
   * "null" if entry is not deleted.
   * @format date-time
   */
  deletedAt?: string;
  _fk_uuid_account: string;
  day: PickupSlotDay;
  /** @format double */
  firstPeriodStartHour: number;
  /** @format double */
  firstPeriodStartMinute: number;
  /** @format double */
  firstPeriodEndHour: number;
  /** @format double */
  firstPeriodEndMinute: number;
  /** @format double */
  secondPeriodStartHour: number;
  /** @format double */
  secondPeriodStartMinute: number;
  /** @format double */
  secondPeriodEndHour: number;
  /** @format double */
  secondPeriodEndMinute: number;
}

export enum EAddressType {
  BUSINESS = 'BUSINESS',
  OWNER = 'OWNER',
  DOCUMENT_SHIPPING = 'DOCUMENT_SHIPPING',
  BILLING = 'BILLING',
  LOGISTICS = 'LOGISTICS',
  DOCUMENT_PICKUP = 'DOCUMENT_PICKUP',
  VEHICLE_DELIVERY = 'VEHICLE_DELIVERY',
}

export interface IAccountAddressTypeSettings {
  uuid: string;
  /**
   * Indicating the time when the entity has been created, i.e. when the entity was
   * persisted to the database.
   * @format date-time
   */
  createdAt?: string;
  /**
   * Indicating the last time a value of the entity has been changed (updated).
   * @format date-time
   */
  updatedAt?: string;
  /**
   * Indicating the time when the entity has been (softly) deleted.
   * "null" if entry is not deleted.
   * @format date-time
   */
  deletedAt?: string;
  /** Current version vector */
  versionVector: number[];
  /** Indicates this update comes from a remote system */
  isRemoteUpdate: boolean;
  _fk_uuid_accountAddress: string;
  addressType: EAddressType;
  isDefault: boolean;
  contactFirstName?: string;
  contactLastName?: string;
  contactEmail?: string;
  contactPhoneNumber?: string;
}

export interface IAccountAddress {
  uuid: string;
  /**
   * Indicating the time when the entity has been created, i.e. when the entity was
   * persisted to the database.
   * @format date-time
   */
  createdAt?: string;
  /**
   * Indicating the last time a value of the entity has been changed (updated).
   * @format date-time
   */
  updatedAt?: string;
  /**
   * Indicating the time when the entity has been (softly) deleted.
   * "null" if entry is not deleted.
   * @format date-time
   */
  deletedAt?: string;
  addressLine?: string;
  zipCode?: string;
  city: string;
  countryCode: ECountryCode;
  /** Add extra comments do the location (e.g., pick-up instruction in transportation tasks). */
  comment?: string;
  /** Language of the contact person at the location. */
  contactLanguage?: string;
  /**
   * GPS coordinates of the auction (can be used for e.g. calculating distances between auctions and buyers).
   * @format double
   */
  locationGeoLat?: number;
  /** @format double */
  locationGeoLon?: number;
  /** Current version vector */
  versionVector: number[];
  /** Indicates this update comes from a remote system */
  isRemoteUpdate: boolean;
  addressHash: string;
  _fk_uuid_account: string;
  addressTypes: EAddressType[];
  typesSettings?: IAccountAddressTypeSettings[];
  label?: string;
  contactFirstName?: string;
  contactLastName?: string;
  contactEmail?: string;
  contactPhoneNumber?: string;
}

export enum EAuctionState {
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
  Value5 = 5,
  Value6 = 6,
  Value7 = 7,
  Value8 = 8,
  Value9 = 9,
  Value10 = 10,
}

/**
 * These are the possible reasons that an auction could be rejected during
 * the renegotiation process. These are meant to be used as categories of
 * rejection rationals, with the details (if applicable) being supplied
 * by a separate 'auctionRejectionNote' metric.
 */
export enum EAuctionRejectionReason {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
  Value5 = 5,
  Value6 = 6,
}

export enum EAuctionDirectUploadRejectionReason {
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
  Value5 = 5,
  Value6 = 6,
  Value7 = 7,
  Value8 = 8,
  Value9 = 9,
}

/** @deprecated */
export enum EAuctionBuyerComplaint {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
  Value5 = 5,
}

/**
 * Reasons why an auction is rejected during the review process ("Parkhaus").
 *
 * This reason is automatically set to UNKNOWN for all auctions and is then manually adjusted by
 * an internal review agent (in case the auction gets rejected) before it is sent back to the responsible
 * SellerUser.
 */
export enum EAuctionReviewReason {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
  Value5 = 5,
  Value6 = 6,
  Value7 = 7,
  Value8 = 8,
  Value9 = 9,
  Value13 = 13,
  Value14 = 14,
  Value15 = 15,
  Value17 = 17,
  Value18 = 18,
  Value20 = 20,
  Value21 = 21,
  Value22 = 22,
  Value23 = 23,
  Value24 = 24,
  Value25 = 25,
  Value26 = 26,
  Value50 = 50,
  Value51 = 51,
  Value52 = 52,
  Value53 = 53,
  Value99 = 99,
}

/**
 * Indicating the process that should be used to pay
 * the purchase volume (auction volume, i.e. the "currentHighestBidValue" if the auction is successful)
 * from the buyer to the seller.
 *
 * If a SellerUser/SellerAccount is enabled for external payment, the payment process can be set to EXTERNAL_COS_INVOICE
 * or EXTERNAL_NO_INVOICE on auction creation.
 * @deprecated
 */
export enum EAuctionPaymentProcess {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
}

export enum EAuctionType {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
}

export enum EShippingOrderProvider {
  PS_TEAM = 'PS_TEAM',
  GO = 'GO',
  KROSCHKE = 'KROSCHKE',
}

/** Document shipping provider data. */
export interface IDocumentShippingProvider {
  addressLine?: string;
  zipCode?: string;
  city: string;
  countryCode: ECountryCode;
  /** Add extra comments do the location (e.g., pick-up instruction in transportation tasks). */
  comment?: string;
  /** Language of the contact person at the location. */
  contactLanguage?: string;
  businessName: string;
  name: EShippingOrderProvider;
}

/** Document shipping data. */
export interface IAuctionDocumentShippingOrder {
  status: string;
  documents: {
    trackingId: string;
    trackingUrl: string;
    url: string;
    type: string;
  }[];
  /** Document shipping provider data. */
  provider: IDocumentShippingProvider;
  statusHistory: {
    /** @format date-time */
    updatedAt: string;
    status: string;
  }[];
}

/** Stores info about specific field with the original and initial value and confirmation status. */
export interface IFieldConfirmationStatus {
  /** reviewed value */
  value: any;
  /** initial value */
  initValue: any;
  /** confirmation flag */
  confirmed: boolean;
}

/** Represents Auction's field confirmation status object. */
export interface IAuctionFieldConfirmationStatusMap {
  /** Stores info about specific field with the original and initial value and confirmation status. */
  label?: IFieldConfirmationStatus;
}

/** Indicates how the auction is uploaded to the system */
export enum EAuctionUploadMethod {
  Value1 = -1,
  Value0 = 0,
  Value11 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
  Value5 = 5,
  Value6 = 6,
  Value7 = 7,
  Value8 = 8,
  Value9 = 9,
  Value10 = 10,
  Value112 = 11,
  Value12 = 12,
  Value13 = 13,
  Value14 = 14,
  Value15 = 15,
  Value16 = 16,
}

export enum EPrebookedServiceType {
  Transport = 'transport',
  Guarantee = 'guarantee',
  BuyNowPayLater = 'buyNowPayLater',
}

export enum EPrebookedServiceTransportOptionTypeSELFPICKUP {
  SelfPickup = 'selfPickup',
}

export enum EPrebookedServiceTransportOptionTypeSTANDARD {
  Standard = 'standard',
}

export enum EPrebookedServiceTransportOptionTypeEXPRESS {
  Express = 'express',
}

/** Transport service options. */
export type PrebookedServiceTransportOptionType =
  | EPrebookedServiceTransportOptionTypeSELFPICKUP
  | EPrebookedServiceTransportOptionTypeSTANDARD
  | EPrebookedServiceTransportOptionTypeEXPRESS;

export interface IBasicPrebookedServiceOptionPrebookedServiceTransportOptionType {
  /**
   * Price per option.
   * Can be predefined for the option or dynamic (e.g. transportation will depend on the distance and other criteria).
   * @format double
   */
  price?: number;
  /**
   * Price per option (net).
   * @format double
   */
  priceNet?: number;
  /**
   * Discount price per option.
   * Can be predefined for the option or dynamic (e.g. transportation will depend on the distance and other criteria).
   * @format double
   */
  discountPrice?: number;
  /**
   * Discount price per option (net).
   * @format double
   */
  discountPriceNet?: number;
  /** Option type, like selfPickup, standard etc. */
  type: PrebookedServiceTransportOptionType;
  /** Indicates if the option is selected. */
  isSelected: boolean;
  /** Indicates if the options was selected after the auction expired. */
  selectedAfterExpiration: boolean;
  /** True when can be selected. */
  isEnabled: boolean;
  /** The reason why the option is not available. */
  disableReason?: string;
}

/** Transport option. */
export type IPrebookedServiceTransportOption = IBasicPrebookedServiceOptionPrebookedServiceTransportOptionType;

export enum EPrebookedServiceGuaranteeOptionTypeSTANDARD {
  Standard = 'standard',
}

export enum EPrebookedServiceGuaranteeOptionTypeCOSCHECKPLUS {
  CosCheckPlus = 'cosCheckPlus',
}

export enum EPrebookedServiceGuaranteeOptionTypeEXTENDED {
  Extended = 'extended',
}

/**
 * Guarantee service options.
 * 1. standard - as it's provided now, for free
 * 2. cosCheckPlus - covers mechanical parts and some more
 * 3. extended - allows to complain in extended period of time
 */
export type PrebookedServiceGuaranteeOptionType =
  | EPrebookedServiceGuaranteeOptionTypeSTANDARD
  | EPrebookedServiceGuaranteeOptionTypeCOSCHECKPLUS
  | EPrebookedServiceGuaranteeOptionTypeEXTENDED;

export interface IBasicPrebookedServiceOptionPrebookedServiceGuaranteeOptionType {
  /**
   * Price per option.
   * Can be predefined for the option or dynamic (e.g. transportation will depend on the distance and other criteria).
   * @format double
   */
  price?: number;
  /**
   * Price per option (net).
   * @format double
   */
  priceNet?: number;
  /**
   * Discount price per option.
   * Can be predefined for the option or dynamic (e.g. transportation will depend on the distance and other criteria).
   * @format double
   */
  discountPrice?: number;
  /**
   * Discount price per option (net).
   * @format double
   */
  discountPriceNet?: number;
  /** Option type, like selfPickup, standard etc. */
  type: PrebookedServiceGuaranteeOptionType;
  /** Indicates if the option is selected. */
  isSelected: boolean;
  /** Indicates if the options was selected after the auction expired. */
  selectedAfterExpiration: boolean;
  /** True when can be selected. */
  isEnabled: boolean;
  /** The reason why the option is not available. */
  disableReason?: string;
}

/**
 * Guarantee option.
 * Not used at the moment.
 */
export type IPrebookedServiceGuaranteeOption = IBasicPrebookedServiceOptionPrebookedServiceGuaranteeOptionType;

export enum EPrebookedServiceBNPLOptionTypePAYIN60DAYS {
  PayIn60Days = 'payIn60Days',
}

export enum EPrebookedServiceBNPLOptionTypePAYNOW {
  PayNow = 'payNow',
}

export type PrebookedServiceBNPLOptionType = EPrebookedServiceBNPLOptionTypePAYIN60DAYS | EPrebookedServiceBNPLOptionTypePAYNOW;

export interface IBasicPrebookedServiceOptionPrebookedServiceBNPLOptionType {
  /**
   * Price per option.
   * Can be predefined for the option or dynamic (e.g. transportation will depend on the distance and other criteria).
   * @format double
   */
  price?: number;
  /**
   * Price per option (net).
   * @format double
   */
  priceNet?: number;
  /**
   * Discount price per option.
   * Can be predefined for the option or dynamic (e.g. transportation will depend on the distance and other criteria).
   * @format double
   */
  discountPrice?: number;
  /**
   * Discount price per option (net).
   * @format double
   */
  discountPriceNet?: number;
  /** Option type, like selfPickup, standard etc. */
  type: PrebookedServiceBNPLOptionType;
  /** Indicates if the option is selected. */
  isSelected: boolean;
  /** Indicates if the options was selected after the auction expired. */
  selectedAfterExpiration: boolean;
  /** True when can be selected. */
  isEnabled: boolean;
  /** The reason why the option is not available. */
  disableReason?: string;
}

export type IPrebookedServiceBNPLOption = IBasicPrebookedServiceOptionPrebookedServiceBNPLOptionType;

export type IPrebookedServiceOption = IPrebookedServiceTransportOption | IPrebookedServiceGuaranteeOption | IPrebookedServiceBNPLOption;

/**
 * Used as augmented prebooked services config and user selection which will be shown in the auction.
 * Should display all possible options which can be booked after the auction finishes.
 */
export interface IPrebookedService {
  /** Type of the service. */
  type: EPrebookedServiceType;
  /** Indicates if the service is enabled or not. */
  isEnabled: boolean;
  /** Available options for the service. */
  options: IPrebookedServiceOption[];
}

export enum EAuctionFulfillmentCriteriaType {
  DOMESTIC = 'DOMESTIC',
  CROSS_BORDER = 'CROSS_BORDER',
}

export enum EAuctionFulfillmentType {
  EXTERNAL = 'EXTERNAL',
  INTERNAL = 'INTERNAL',
}

export interface IAuctionFulfillmentCriteria {
  groups?: string[];
  type: EAuctionFulfillmentCriteriaType;
  fulfillment: EAuctionFulfillmentType;
}

export enum EAuctionLookAndFeelId {
  DEFAULT = 'DEFAULT',
  MERCEDES_BENZ = 'MERCEDES_BENZ',
}

/** Defines what type of additional tax (or the absence of such a tax) applies to an auction. */
export enum EAdditionalTaxType {
  Value1 = -1,
  Value0 = 0,
  Value11 = 1,
  Value2 = 2,
  Value99999 = 99999,
}

/** Enums to identify what type of user created the auction */
export enum EAuctionCreatorType {
  Value1 = -1,
  Value0 = 0,
  Value11 = 1,
}

/** Field data related to the renegotiation round process. */
export interface IRenegotiationRoundData {
  /** @format double */
  totalRounds: number;
  buyer?: {
    /** @format double */
    maxOffer: number;
    /** @format double */
    minOffer: number;
    /** @format double */
    currentRound: number;
  };
  seller?: {
    /** @format double */
    maxOffer: number;
    /** @format double */
    minOffer: number;
    /** @format double */
    currentRound: number;
  };
}

export enum EAuctionBelowMinAskBehaviour {
  START_DIGITAL_RENEGOTIATION = 'START_DIGITAL_RENEGOTIATION',
  WAIT_FOR_MANUAL_ACTION = 'WAIT_FOR_MANUAL_ACTION',
  DISABLE_CLONE_AND_START = 'DISABLE_CLONE_AND_START',
}

/**
 * The auction model, which is a central element in the data model, referencing and referenced by most other
 * entities in the model.
 *
 * TODO
 */
export interface IAuction {
  /**
   * @deprecated
   * @format double
   */
  id: number;
  uuid: string;
  /**
   * Indicating the time when the entity has been created, i.e. when the entity was
   * persisted to the database.
   * @format date-time
   */
  createdAt?: string;
  /**
   * Indicating the last time a value of the entity has been changed (updated).
   * @format date-time
   */
  updatedAt?: string;
  /**
   * Indicating the time when the entity has been (softly) deleted.
   * "null" if entry is not deleted.
   * @format date-time
   */
  deletedAt?: string;
  /** Stores which type of additional tax applies to the auction if any. */
  additionalTaxType: EAdditionalTaxType;
  /**
   * Stores the value of the additional tax that might apply to an auction.
   * @format double
   */
  additionalTaxValue: number;
  /**
   * Stores the export discount for taxes.
   * @format double
   */
  additionalTaxExportDiscount: number;
  /**
   * The state of the auction, indicating whether an auction is not yet published (DRAFTED), running (ACTIVE),
   * closed successfully (CLOSED_WAITING_FOR_PAYMENT, CLOSED_WAITING_FOR_PICKUP, FINISHED), failed (CLOSED_NO_BIDS,
   * CLOSED_UNDER_MINIMUM_ASK) or disabled (DISABLED).
   *
   * Depending on the state, different timestamps are available per auction.
   */
  state: EAuctionState;
  /**
   * The exact date+time when the auction is expiring/ending.
   * @format date-time
   */
  endingTime: string;
  /**
   * The remaining number of seconds that this auction will be in the state ACTIVE (running).
   *
   * This is a dynamic, non-persisted field, calculated on every retrieval of the auction data.
   * @format double
   */
  remainingTimeInSeconds: number;
  /**
   * The remaining number of seconds that this auction is available for instant purchase
   * (usually the first 24 hours after the auction is started).
   *
   * This is a dynamic, non-persisted field, calculated on every retrieval of the auction data.
   * @format double
   */
  remainingTimeForInstantPurchaseInSeconds: number;
  /**
   * Date+time indicating until when an auction is available for instant purchasing.
   * By default, this is 24 hours after the start of an auction.
   * @format date-time
   */
  instantPurchasePossibleUntil: string;
  _fk_uuid_highestBiddingBuyerUser: string;
  /**
   * Value that is indicating the current highest bid value on the auction.
   * This is of course a dynamic field, that will be incremented on every new bid placed on the auction.
   * @format double
   */
  currentHighestBidValue: number;
  /** @format double */
  currentHighestBidValueNet: number;
  /**
   * The minimal price the seller user wants to reach for this specific auction.
   * If an auction ends above that price, the auction is successful right away
   * (switching to state CLOSED_WAITING_FOR_PAYMENT).
   *
   * If the auction ends below the minimum required ask, the auction will switch to state CLOSED_UNDER_MINIMUM_ASK,
   * which then requires the seller's explicit "confirmation" or "rejection" of the highest bid value.
   * @format double
   */
  minimumRequiredAsk: number;
  /** @format double */
  minimumRequiredAskNet: number;
  /**
   * Number of bids placed on an auction.
   * @format double
   */
  numBids: number;
  /** @format double */
  buyerRecommendationScore?: number;
  /** Was this auction created with the help of an inspection done or commissioned by CarOnSale? */
  inspectionWasCommissionedByCos: boolean;
  /** By which type of user was this auction created. */
  creatorType: EAuctionCreatorType;
  /** UUID of the creating internal user if there is one. */
  _fk_uuid_creatingInternalUser: string;
  /** UUID of the creating seller user if there is one. */
  _fk_uuid_creatingSellerUser: string;
  /**
   * Indicates if the auction was created for testing purposes and
   * should be handled differently: it should not be ended automatically,
   * so the seller should decide would he like to sell it or not.
   */
  isTest: boolean;
  /** Indicates if minimum required ask should be displayed for buyers. */
  displayMinAsk: boolean;
  /**
   * GPS coordinates of the auction (can be used for e.g. calculating distances between auctions and buyers).
   * @format double
   */
  locationGeoLat: number;
  /** @format double */
  locationGeoLon: number;
  /** @format double */
  counterOfferByBuyer: number;
  /** @format double */
  counterOfferByBuyerNet: number;
  /** @format double */
  lastOfferBySeller: number;
  /** @format double */
  lastOfferBySellerNet: number;
  /**
   * A value denoting the previous renegotiation offer given by a buyer user.
   * @format double
   */
  previousCounterOfferByBuyer: number;
  /** @format double */
  previousCounterOfferByBuyerNet: number;
  /**
   * A value denoting the previous renegotiation offer given by a seller user.
   * This is required in order to properly display the right information during the renegotiation process.
   * @format double
   */
  previousLastOfferBySeller: number;
  /** @format double */
  previousLastOfferBySellerNet: number;
  /** A flag for if the seller user connected to the auction has decided not to participate in the renegotiation any further. */
  renegotiationStoppedBySeller: boolean;
  /** A flag for if the highest bidding buyer user connected to the auction has decided not to participate in the renegotiation any further. */
  renegotiationStoppedByBuyer: boolean;
  /** A fag indicating if the buyer's withdrawal from the renegotiation process was done automatically or not. */
  wasRenegotiationStoppageByBuyerAutomatic: boolean;
  /** A fag indicating if the seller's withdrawal from the renegotiation process was done automatically or not. */
  wasRenegotiationStoppageBySellerAutomatic: boolean;
  /**
   * The total number of offer rounds a buyer has participated in for an auction during the renegotiation phase.
   * @format double
   */
  numBuyerRenegotiationOfferRounds: number;
  /**
   * The total number of offer rounds a seller has participated in for an auction during the renegotiation phase.
   * @format double
   */
  numSellerRenegotiationOfferRounds: number;
  /**
   * The calculated renegotiation midpoint value of the auction.
   * This is used once the renegotiation offers get too close for the offer process to continue.
   * This value will be undefined unless the auction is ready to be purchased for such a value.
   * @format double
   */
  renegotiationMidpointValue: number;
  /** @format double */
  renegotiationMidpointValueNet: number;
  /**
   * Indicates that the highest bidding buyer user for an auction in renegotiation is willing to accept the midpoint of their offer and
   * the offer of the seller for the same auction. This is used when the renegotiation offers get very close during the renegotiation process.
   */
  buyerAcceptedRenegotiationMidpoint: boolean;
  /**
   * Indicates that the seller of an auction is willing to accept the midpoint of their offer and the offer of the highest bidding
   * buyer user for the same auction. This is used when the renegotiation offers get very close during the renegotiation process.
   */
  sellerAcceptedRenegotiationMidpoint: boolean;
  /**
   * This is the datetime object denoting when the most recent renegotiation round concluded at.
   * @format date-time
   */
  lastRenegotiationRoundEndedAt: string;
  /**
   * The number of seconds until the current renegotiation round times out.
   * @format double
   */
  numSecondsUntilRenegotiationTimeout: number;
  /**
   * The UUID of the external case tracking the renegotiation of the auction.
   * We track this to enable case updates as the auction moves through renegotiation.
   */
  externalRenegotiationTicketUuid: string;
  /**
   * The calculated renegotiation fair value of the auction.
   * This is used once the renegotiation starts based on the auction GPR or MPR so we know
   * the fair value that both seller and buyer should agree upon.
   * @format double
   */
  renegotiationFairValue: number;
  /**
   * Value that is indicating the highest bid value on the auction when it ended.
   * This value should not change for a given auction since it supposed to be a historical record.
   * @format double
   */
  highestBidValueAtEndingTime: number;
  /** @format double */
  highestBidValueAtEndingTimeNet: number;
  /** Field data related to the renegotiation round process. */
  renegotiationRoundData?: IRenegotiationRoundData;
  /**
   * Indicates when manual renegotiation was finished via Salesforce.
   * @format date-time
   */
  finishedManuallyAt?: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing service (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the transfer of funds
   * for the vat deposit from COS to the deposit account (only relevant for cross border transactions).
   * This deposit is refunded by COS once the buyer provides the necessary documentation for a cross border transaction.
   */
  thirdPartyVATDepositTransferReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing service (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the transfer of funds
   * for the additional tax deposit from COS to the deposit account (only relevant for cross border transactions).
   * This deposit is refunded by COS once the buyer provides the necessary documentation for a cross border transaction.
   */
  thirdPartyAdditionalTaxDepositTransferReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing system (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the transfer of funds
   * for the auction additional taxes (e.g country specific luxury taxes): from COS to the seller.
   */
  thirdPartyAdditionalTaxTransferReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing service (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the transfer of funds
   * for the VAT: from COS to the seller (only relevant for cross border transactions for now).
   * COS is advancing the vat amount to the seller.
   */
  thirdPartyVatTransferReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing service (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the charge of funds
   * for the auction volume from the buyer to COS.
   */
  thirdPartyVolumeChargeReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing service (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the charge of funds
   * for the auction deposit from the buyer to COS (only relevant for cross border transactions for now).
   * This deposit is refunded by COS once the buyer provides the necessary documentation for a cross border transaction.
   */
  thirdPartyVATDepositChargeReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing service (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the charge of funds
   * for the auction additional tax (e.g country specific luxury tax) from the buyer to COS.
   */
  thirdPartyAdditionalTaxChargeReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing system (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the refund of an
   * additional tax deposit (only used for cross-border transactions currently).
   */
  thirdPartyAdditionalTaxRefundReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing system (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the payout of funds.
   */
  thirdPartyPayoutReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing system (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the refund of a
   * deposit (only used for cross-border transactions currently).
   */
  thirdPartyVATDepositRefundReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing system (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the refund of a
   * transfer to the deposit account owned by cos (only used for cross-border transactions currently).
   */
  thirdPartyVATDepositReversalReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing system (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the refund of the
   * additional tax transfer to the deposit account owned by cos (only used for cross-border transactions).
   */
  thirdPartyAdditionalTaxDepositReversalReference: string;
  /**
   * Reference to the invoice for the success fee issued to the seller for a sold vehicle (see ESellerFeeInvoicingMode.INVOICE_ON_SUCCESS).
   * The reference identifies the invoice issued to the seller in an external invoicing system (e.g. Stripe).
   *
   * This fee is not always charged, therefore this reference is optional (can be null).
   */
  sellerSuccessFeeInvoiceReference: string;
  /**
   * Reference to the invoice for the listing fee issued to the seller for a listed vehicle (see ESellerFeeInvoicingMode.INVOICE_ON_LISTING).
   * The reference identifies the invoice issued to the seller in an external invoicing system (e.g. Stripe).
   *
   * This fee is not always charged, therefore this reference is optional (can be null).
   */
  sellerListingFeeInvoiceReference: string;
  /**
   * Reference to the invoice for the standing fee issued to the buyer for a purchased vehicle picked up too late.
   *
   * This fee is not always charged, therefore this reference is optional (can be null).
   */
  buyerStandingFeeInvoiceReference: string;
  /**
   * Reference to a resource in an external invoicing system (such as Stripe).
   * References our own transaction fee invoice.
   */
  invoiceReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing system (such as Stripe).
   * This is only non-null, if external payment is allowed for this auction.
   */
  thirdPartyInvoiceReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing system.
   * This is only non-null, if external payment is allowed for this auction and it had to be cancelled.
   */
  thirdPartyCorrectionInvoiceReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing system (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the transfer of funds
   * for the auction volume: from COS to the seller.
   */
  thirdPartyTransferReference: string;
  /**
   * Reference of an invoice on an external invoicing system.
   * This is invoice is used to charge booked services (like guarantee) after the auction expires.
   */
  bookedServicesInvoiceReference: string;
  /**
   * Date-time when the auction was reauctioned and is now waiting for buyer payment until the new auction gets paid.
   * @format date-time
   */
  reauctionedWaitingForBuyerPaymentAt: string;
  /**
   * Date-time when the first reauction of this auction has expired.
   * We need to keep track of this so we can block the original buyer of seeing the original auction.
   * @format date-time
   */
  firstReauctionExpiredAt: string;
  /** Flag that indicates if this auction is a clonned reauction. */
  isReauction: boolean;
  /**
   * The highest bid value that we show to the seller when we merge the reauction with the original auction.
   * This is done so the seller see the amount that cos pay them and the buyer sees the amount that they paid cos.
   * @format double
   */
  sellerViewHighestBidValue: number;
  /**
   * The purchase confirmed at that we show to the seller when we merge the reauction with the original auction.
   * This is done so the seller see the date when the original auction was confirmed and the buyer sees the date that the reauction was confirmed.
   * @format date-time
   */
  sellerViewPurchaseConfirmedAt: string;
  /**
   * Date-time when the reauction was merged back at the original auction.
   * @format date-time
   */
  reauctionedMergedAt: string;
  /** Indicates wherever an auction was purchase using the Buy Now Pay Later feature. */
  wasPurchasedUsingBuyNowPayLater: boolean;
  /**
   * The timestamp at which the buyer has confirmed his choice to use Buy Now Pay Later for the auction.
   * @format date-time
   */
  buyNowPayLaterConfirmedAt: string;
  /** The IP address which confirmed the Buy Now Pay Later choice for the auction. */
  buyNowPayLaterConfirmationIPAddress: string;
  /** Reference to the Buy Now Pay Later Order on the PNI Service. */
  buyNowPayLaterOrderReference: string;
  /** Reference of the invoice for buy now pay later provider fee on an external invoicing system. */
  buyNowPayLaterInvoiceReference: string;
  /** The payment link reference */
  paymentLinkReference: string;
  /** Reference to the payment order on the P&I service */
  paymentOrderReference: string;
  isInternalFulfillment: boolean;
  belowMinAskBehaviour: EAuctionBelowMinAskBehaviour;
  /** The label of the auction, consisting of the vehicle make, model and date of first registration (EZ). */
  label: string;
  /**
   * Date+time when the auction is started.
   * When an auction is started right away (without drafting), the "createdAt" and "startedAt" should have
   * (roughly) the same values.
   * @format date-time
   */
  startedAt: string;
  /**
   * Date+time when the auction is "confirmed to be sold" by the seller (or by an admin).
   * If an auction is ending equal or above the minimum required ask, the "purchaseConfirmedAt"
   * will automatically be set and will be equal to the "endingTime".
   *
   * If an auction is ending below the minimum ask (CLOSED_UNDER_MINIMUM_ASK), the "purchaseConfirmedAt" timestamp
   * is only set if the auction is then manually "confirmed" by the seller or an admin.
   *
   * If an auction is never "successful", the "purchaseConfirmedAt" will remain "null".
   *
   * This value was introduced in the course of 2019. For all previous auctions, the endingTime was set
   * as the "purchaseConfirmedAt" retrospectively.
   * @format date-time
   */
  purchaseConfirmedAt: string;
  /**
   * Date-time when the auction has definitely been rejected by a seller or an admin, after the auction
   * ended below minimum required ask.
   *
   * This value is only available since 2019/07/29. All auctions rejected before that date have the value "null".
   * @format date-time
   */
  purchaseRejectedAt: string;
  /** Indicates the reason that an auction failed the renegotiation process. */
  rejectionReason: EAuctionRejectionReason;
  /**
   * Elaborates on the rejection reason to provide further context to why an
   * auction was rejected during the renegotiation process.
   */
  rejectionReasonNote: string;
  /**
   * The name of the competitor that the auction was sold to when the reject chosen reason was
   * VEHICLE_SOLD_ON_DIFFERENT_PLATFORM
   */
  rejectionReasonCompetitorName: string;
  /** In case if there was a direct upload requested but didn't succeed the reason should be stored. */
  directUploadRejectionReasons: EAuctionDirectUploadRejectionReason[];
  /**
   * Value that is indicating initial bid value auction should be started with.
   * @format double
   */
  startingBidValue: number;
  /** @format double */
  startingBidValueNet: number;
  /**
   * Value that can be manually set in case edge-cases apply and the payout amount is disconnected from the auction current highest bid.
   * This value can only be adjusted by internal users.
   * @format double
   */
  overridePayoutValue: number;
  /**
   * If an auction is ending below the minimum required ask, the "originalMinimumRequiredAsk" will be set to the
   * "minimumRequiredAsk" value before it is set to "currentHighestBidValue".
   * @format double
   */
  originalMinimumRequiredAsk: number;
  /** @format double */
  originalMinimumRequiredAskNet: number;
  /**
   * The purchase price for the vehicle that is auctioned.
   * This is set manually by the seller, and is mostly used to analyze the "real" margin that a seller
   * makes with an auction.
   * @format double
   */
  purchasePrice: number;
  /** @format double */
  purchasePriceNet: number;
  /**
   * The full object of the vehicle that is on auction.
   * This is augmented on every auction retrieval, based on _fk_uuid_vehicle.
   */
  associatedVehicle: IVehicle;
  /** Indicates whether the auction process is rated by the SellerUser. */
  isRatedByDealership: boolean;
  /** Indicates whether the auction process is rated by the BuyerUser. */
  isRatedByBuyer: boolean;
  /**
   * Indicates whether the BuyerUser has marked this auction as paid (payment of the auction volume).
   * This is done manually by the BuyerUser, so this value is not indicating that the actual payment has really
   * reached the seller.
   */
  isPaidByBuyer: boolean;
  /**
   * Timestamp when the BuyerUser has marked this auction as "paid" (i.e. set the "isPaidByBuyer").
   * @format date-time
   */
  outgoingPaymentConfirmedAt: string;
  /**
   * Timestamp when the SellerUser has confirmed that the payment has reached the seller bank account.
   * @format date-time
   */
  incomingPaymentConfirmedAt: string;
  /**
   * Timestamp when the auction state has changed from "CLOSED_WAITING_FOR_PAYMENT" to "CLOSED_WAITING_FOR_PICKUP".
   * @format date-time
   */
  pickupConfirmedAt: string;
  /** Physical address of where the vehicle on auction is located (a.k.a. pickup address). */
  locationCountryCode: ECountryCode;
  locationAddress: string;
  locationCity: string;
  locationZip: string;
  /** Physical address of where the vehicle on auction was inspected (a.k.a. inspection address). */
  inspectionLocationCountryCode: ECountryCode;
  inspectionLocationAddress: string;
  inspectionLocationCity: string;
  inspectionLocationZip: string;
  /**
   * Indicating to which bank account the BuyerUser has to transfer the auction volume to.
   * @deprecated
   */
  sellerIban: string;
  /**
   * URL to the seller invoice for the given auction, manually uploaded by the SellerUser, after
   * the auction has ended successfully.
   */
  urlToInvoice: string;
  /** URL for the cancelled invoice in case it was cancelled. */
  urlToCorrectionInvoice: string;
  /**
   * Flag indicating whether an auction has an "hot bid" phase in the last 20 seconds before expiration.
   * @deprecated
   */
  hotBid: boolean;
  /**
   * Seller's instant purchase price for the vehicle.
   * For the first 24 hours after the auction is started, the vehicle will only be available in "instant purchase"
   * mode (bidding is not allowed in that time, only purchasing right away).
   *
   * After 24 hours, if nobody purchased the vehicle, it is going on auction, just like all other auctions.
   * @format double
   */
  instantPurchasePrice: number;
  /** @format double */
  instantPurchasePriceNet: number;
  /**
   * Flag indicating that instant purchase is enabled for that auction.
   * (Implies that "instantPurchasePrice" is set)
   */
  allowInstantPurchase: boolean;
  /**
   * Flag indicating that an auction "ended" with an instant purchase, resp.
   * did never go on auction.
   */
  didEndWithInstantPurchase: boolean;
  /**
   * Internal counter, incrementing on every "restart" of an auction.
   * Starts with 0.
   *
   * e.g. count of 2 means that the auction has been restarted 2 times, leading to 3 auction objects in the
   * database.
   * @format double
   */
  auctioningIterations: number;
  /**
   * Indicating the strategic priority of an auction for CarOnSale.de
   * The higher the number, the more "relevant" the auction is.
   * @format double
   */
  priority: number;
  /** @deprecated */
  advertisementHtmlContent: string;
  /** @deprecated */
  buyerComplaint: EAuctionBuyerComplaint;
  /**
   * Custom field that is not used internally.
   * Allows sellers to store e.g. a reference ID to their own vehicle management system.
   */
  internalReferenceText: string;
  _fk_uuid_vehicle: string;
  _fk_uuid_sellerUser: string;
  /** This is a URL that redirects to an external invoice payment site (such as provided by Stripe). */
  urlToPaymentSite: string;
  /**
   * A flag indicating if an auction needs to be reviewed by an internal review agent (for quality assurance)
   * If true, an auction is currently under review by CarOnSale, and cannot be edited by the owning seller user.
   */
  needsReview: boolean;
  /** The reasons why an auction was rejected during the review. */
  reviewReasons: EAuctionReviewReason[];
  /**
   * Reasons why an auction is rejected during the review process ("Parkhaus").
   *
   * This reason is automatically set to UNKNOWN for all auctions and is then manually adjusted by
   * an internal review agent (in case the auction gets rejected) before it is sent back to the responsible
   * SellerUser.
   * @deprecated
   */
  reviewReason: EAuctionReviewReason;
  /** Specifies reason when "reviewReasons" contains EAuctionReviewReason.OTHER. */
  reviewReasonDetails: string;
  /** The comment given by an internal review agent on rejecting an auction under review. */
  reviewComment: string;
  /** Flag indicating if the auction can be published without review. */
  canSkipReview?: boolean;
  /**
   * Random PIN code that is generated when an auction is ready to be picked up or in advance before COSCheck inspection.
   * This PIN code is printed out on the hangar in the vehicle or handed to the seller of the auction, which can then forward it to
   * the buyer to confirm the pickup.
   */
  pickupPinCode: string;
  /**
   * Random UUID that is used to confirm vehicle pickup via scanning QR code.
   * It will be printed on the hangar or in the pickup document available for sellers.
   */
  pickupPinUuid: string;
  /**
   * Timestamp when PIN code was entered by the buyer.
   * Allows to determine how many auctions were self-picked up.
   * @format date-time
   */
  pickupPinCodeEnteredAt: string;
  /**
   * Timestamp when PIN UUID was entered by the buyer via scanning QR code.
   * Allows to determine how many auctions were self-picked up.
   * @format date-time
   */
  pickupPinUuidEnteredAt: string;
  /** Flag is indicating if pickup was confirmed with PIN using new process (via pin.caronsale.de) */
  pickupConfirmedWithNewPin: boolean;
  /** URL to the pickup information document for the BuyerUser. */
  urlToPickupBuyerDocument: string;
  /** URL to the pickup information document for the SellerUser (containing the "pickupPinCode"). */
  urlToPickupSellerDocument: string;
  /** URL to the success report document for the SellerUser. */
  urlToSellerSuccessReport: string;
  /** The payment process that is used to pay money for the purchase volume (auction volume). */
  paymentProcess: EAuctionPaymentProcess;
  /**
   * Referencing the bank account to which the payment for the purchase volume should be paid out.
   * This is null, if the paymentProcess === "CLASSIC".
   *
   * It is only used, when external payment (COS Payment) is enabled.
   */
  _fk_uuid_associatedBankAccount: string;
  /**
   * Indicates how often an auction has been reviewed.
   * This is incremented every time an auction is rejected after a review.
   * @format double
   */
  draftReviewIterations: number;
  /**
   * Timestamp when an admin resets a time window for a possibility to complain
   * @format date-time
   */
  complaintTimeWindowExtendedAt: string;
  /**
   * Flag indicates if this auction is suspected to have been paid in cash (only for auctions that use the external
   * invoice process), this means that no corresponding transfer exists on Stripe and the buyer confirmed the pickup
   * through the seller pin code.
   */
  isSuspectedCashPayment: boolean;
  /**
   * Timestamp when the auction was sent to review.
   * @format date-time
   */
  reviewStartedAt: string;
  /**
   * Timestamp when the auction QA process actually started (i.e. when Parkhaus started reviewing the auction).
   * @format date-time
   */
  qaStartedAt: string;
  /**
   * Timestamp when the auction was ready to be reinserted (a.k.a. restarted).
   * @format date-time
   */
  readyToBeReinsertedSince: string;
  /** The reference to the original auction from which current one was cloned. */
  _fk_uuid_clonedFrom: string;
  /** Auction type. */
  type: EAuctionType;
  /** Indicates whether the rejection wait period for this auction is over and thus can be rejected by the seller. */
  isRejectionWaitPeriodOver: boolean;
  /** Reference to invoice object for the external Billomat invoicing provider. */
  thirdPartyVehiclePurchaseInvoiceReference: string;
  /**
   * URL to the seller made invoice invoicing CoS for the given auction, manually uploaded to the crm by an internal user, after
   * the auction has ended successfully.
   */
  sellerToCosInvoiceUrl: string;
  /**
   * Timestamp for when the sellerToCosInvoiceUrl was last updated.
   * @format date-time
   */
  sellerToCosInvoiceUrlLastUpdatedAt: string;
  /**
   * Timestamp for when the sellerToCosInvoiceUrl is first uploaded.
   * @format date-time
   */
  sellerToCosInvoiceUrlFirstUploadedAt: string;
  /** Flag indicating wherever the uploaded seller to cos invoice was verified by an internal user. */
  sellerToCosInvoiceIsValid: boolean;
  /** The comment given by an internal user on the provided seller to cos invoice. */
  sellerToCosInvoiceComment: string;
  /** Shows if auction is marked as 'live', so, it will be slotted during starting and expire in less than 24 hours. */
  isLive: boolean;
  /** Shows if auction was already slotted via the slotting algorithm. */
  isSlotted: boolean;
  /**
   * The exact date+time when the auction was slotted to end.
   * @format date-time
   */
  slottedEndingTime: string;
  /** Indicating if transportation should be disabled manually. */
  isTransportationDisabledManually: boolean;
  /** Optional pickup instructions that will be communicated to the buyer. */
  pickupInstructions: string;
  /** Indicating wherever the seller should be charged a success/listing fee. */
  preventSellerFactoring: boolean;
  /** Indicating if buyer success fee should be applied or not. */
  applyBuyerSuccessFee: boolean;
  /** Info from document shipping service about the order, like status and links to the documents. */
  documentShippingOrder: IAuctionDocumentShippingOrder;
  /** Info about un-/confirmed auction data points. */
  fieldsConfirmationStatus: IAuctionFieldConfirmationStatusMap;
  /**
   * Whether the "Value Added Tax is reportable", a special tax condition in the EU.
   * If this is true, it means that the vehicle is "Regelbesteuert", meaning that the invoice for the vehicle
   * (either invoiced directly from seller to buyer - or from COS Payment to buyer) needs to include
   * the VAT (in Germany e.g. 19%) in the invoice.
   *
   * If this is false, the invoice does not need to have explicit VAT.
   */
  isVATReportable: boolean;
  /** Indicates how the auction was uploaded to the marketplace. */
  uploadMethod: EAuctionUploadMethod;
  /** Prebooked services available for the auction, like transport, warranty. */
  prebookedServices: IPrebookedService[];
  /** Indicates if cosCheckPlus guarantee can be booked for this auction. */
  isCosCheckPlusGuaranteeEnabled: boolean;
  /** Indicates if extended guarantee can be booked for this auction. */
  isExtendedGuaranteeEnabled: boolean;
  /** URL to the auction vehicle cover letter for sellers */
  urlToCoverLetter: string;
  /**
   * Timestamp when the automatic pickup approval was requested for auction
   * @format date-time
   */
  autoPickupOnPaymentRequestedAt: string;
  /**
   * This means that the auctions is using complimentary vehicle feature.
   *
   * Note: This is marked as true when the seller uses one of his complimentary vehicles to pay for the fees.
   */
  isSellerFeeComplimentary: boolean;
  /** Flag when standing fee is enabled to the auction and we'll need to extra charge a buyer as a penalty. */
  isStandingFeeEnabled: boolean;
  _fk_uuid_auctionRoom: string;
  /**
   * An auction can have a list of fulfillment criteria that impact how the
   * auction will be fulfilled depending on the buyer that wins it.
   */
  fulfillmentCriteria?: IAuctionFulfillmentCriteria[];
  /**
   * The auction marked with this flag won't start automatically after review.
   *
   * Instead, it will transition to state: READY_TO_BE_PUBLISHED.
   *
   * Default: false
   */
  shouldWaitToBePublishedAfterReview: boolean;
  /** A flag that marks if an auction is a selective ware or not. */
  isSelectiveWare: boolean;
  /** The id for the look and feel applied to the auction. */
  lookAndFeelId: EAuctionLookAndFeelId;
  /** A flag that marks if an auction will use the pickup qr code. */
  isQRCodeAvailable: boolean;
  /**
   * Indicates whether the highest bid should be transferred if this auction gets cloned.
   * Defaults to `false` if not specified.
   */
  shouldTransferHighestBid?: boolean;
}

export interface IAuctionReviewAssignmentAugmented {
  uuid: string;
  /** The UUID of the auction which is to be reviewed on Parkhaus. */
  _fk_uuid_auction: string;
  /** The UUID of the reviewer that is assigned to the auction. */
  _fk_uuid_reviewer: string;
  /**
   * The date and time when the reviewer was assigned to the auction.
   * @format date-time
   */
  assignedAt: string;
  /**
   * The date and time when the reviewer was unassigned from the auction
   * (if the reviewer was unassigned).
   * @format date-time
   */
  unassignedAt: string | null;
  /** The UUID of the auction review event that is associated with the assignment. */
  _fk_uuid_auction_review: string | null;
  reviewer: {
    mailAddress: string;
    lastName: string;
    firstName: string;
    uuid: string;
  };
}

export type IResultMapIAuctionReviewAssignmentAugmented = Record<string, IAuctionReviewAssignmentAugmented>;

export interface IAuctionReview {
  needsReview: boolean;
  reviewReasons: EAuctionReviewReason[];
  reviewReasonDetails: string;
}

/** Granular permissions for user */
export enum EGeneralUserPermission {
  SEE_ALL_ASSOCIATED_AUCTIONS = 'SEE_ALL_ASSOCIATED_AUCTIONS',
  SEE_DIRECTLY_ASSOCIATED_AUCTIONS = 'SEE_DIRECTLY_ASSOCIATED_AUCTIONS',
  SEE_TRADE_IN_VALUATION_BUY_PRICE = 'SEE_TRADE_IN_VALUATION_BUY_PRICE',
  SEE_TRADE_IN_VALUATION_SELL_PRICE = 'SEE_TRADE_IN_VALUATION_SELL_PRICE',
  DIRECT_AUCTION_UPLOAD = 'DIRECT_AUCTION_UPLOAD',
  TRADE_IN_VALUATION_REQUESTS = 'TRADE_IN_VALUATION_REQUESTS',
  GUARANTEED_PRICE_REQUESTS = 'GUARANTEED_PRICE_REQUESTS',
  SEE_TRADE_INS = 'SEE_TRADE_INS',
  MANAGE_TRADE_INS = 'MANAGE_TRADE_INS',
  SEE_TRADE_IN_REQUESTS = 'SEE_TRADE_IN_REQUESTS',
  CREATE_AUCTIONS = 'CREATE_AUCTIONS',
  CONFIRM_REJECT_BELOW_MIN = 'CONFIRM_REJECT_BELOW_MIN',
  SEE_PURCHASE_PRICES = 'SEE_PURCHASE_PRICES',
  SEE_CLOSED_AUCTIONS = 'SEE_CLOSED_AUCTIONS',
  CHANGE_ACCOUNT_DATA = 'CHANGE_ACCOUNT_DATA',
  BOOK_GUARANTEED_PRICE = 'BOOK_GUARANTEED_PRICE',
  MANAGE_GUARANTEED_PRICE = 'MANAGE_GUARANTEED_PRICE',
}

/**
 * Valuation preference (set by the User) for valuating trade-in leads (see ITradeIn)
 * initially (after submitting the data through e.g. scholzkauftdeinauto.de)
 */
export enum EDealershipTradeInValuationPreference {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
}

/**
 * Types of notifications.
 * These are notifications with dynamic content such as vehicle labels, numbers, etc.
 *
 * {@link https://caronsale.atlassian.net/wiki/spaces/CARONSALE/pages/1100972236/List+of+all+existing+push+notifications+and+their+triggers|Documentation}
 */
export enum ENotificationType {
  TEST = 'TEST',
  INFORM_BUYER_ABOUT_CARGO_DELIVERY = 'INFORM_BUYER_ABOUT_CARGO_DELIVERY',
  INFORM_BUYER_ABOUT_CARGO_PICKUP = 'INFORM_BUYER_ABOUT_CARGO_PICKUP',
  INFORM_BUYER_ABOUT_GUARANTEE_AVAILABLE_TO_BOOK = 'INFORM_BUYER_ABOUT_GUARANTEE_AVAILABLE_TO_BOOK',
  INFORM_BUYER_ABOUT_INSTANT_PURCHASE = 'INFORM_BUYER_ABOUT_INSTANT_PURCHASE',
  INFORM_BUYER_ABOUT_LAST_OFFER_BY_SELLER = 'INFORM_BUYER_ABOUT_LAST_OFFER_BY_SELLER',
  INFORM_BUYER_ABOUT_NEW_SAVED_AUCTION_SEARCH_RESULTS = 'INFORM_BUYER_ABOUT_NEW_SAVED_AUCTION_SEARCH_RESULTS',
  INFORM_BUYER_ABOUT_OUTBID = 'INFORM_BUYER_ABOUT_OUTBID',
  INFORM_BUYER_ABOUT_PARKED_AUCTION_EXPIRING = 'INFORM_BUYER_ABOUT_PARKED_AUCTION_EXPIRING',
  INFORM_BUYER_ABOUT_RENEGOTIATION_EXPIRY = 'INFORM_BUYER_ABOUT_RENEGOTIATION_EXPIRY',
  INFORM_BUYER_ABOUT_RENEGOTIATION_INTERMEDIATE_ROUND = 'INFORM_BUYER_ABOUT_RENEGOTIATION_INTERMEDIATE_ROUND',
  INFORM_BUYER_ABOUT_RENEGOTIATION_LAST_ROUND = 'INFORM_BUYER_ABOUT_RENEGOTIATION_LAST_ROUND',
  INFORM_BUYER_ABOUT_RENEGOTIATION_STARTING = 'INFORM_BUYER_ABOUT_RENEGOTIATION_STARTING',
  INFORM_BUYER_ABOUT_SUCCESSFUL_AUCTION = 'INFORM_BUYER_ABOUT_SUCCESSFUL_AUCTION',
  INFORM_SELLER_ABOUT_AUCTION_READY_TO_REINSERT = 'INFORM_SELLER_ABOUT_AUCTION_READY_TO_REINSERT',
  INFORM_SELLER_ABOUT_AUCTION_READY_TO_REINSERT_HALFWAY_THROUGH_PERIOD = 'INFORM_SELLER_ABOUT_AUCTION_READY_TO_REINSERT_HALFWAY_THROUGH_PERIOD',
  INFORM_SELLER_ABOUT_AUCTION_READY_TO_REINSERT_TO_BE_DISABLED = 'INFORM_SELLER_ABOUT_AUCTION_READY_TO_REINSERT_TO_BE_DISABLED',
  INFORM_SELLER_ABOUT_BOOKED_TRANSPORT = 'INFORM_SELLER_ABOUT_BOOKED_TRANSPORT',
  INFORM_SELLER_ABOUT_CARRIER_PICKUP = 'INFORM_SELLER_ABOUT_CARRIER_PICKUP',
  INFORM_SELLER_ABOUT_EXPIRING_AUCTIONS = 'INFORM_SELLER_ABOUT_EXPIRING_AUCTIONS',
  INFORM_SELLER_ABOUT_GPR_FULFILLMENT = 'INFORM_SELLER_ABOUT_GPR_FULFILLMENT',
  INFORM_SELLER_ABOUT_RENEGOTIATION_EXPIRY = 'INFORM_SELLER_ABOUT_RENEGOTIATION_EXPIRY',
  INFORM_SELLER_ABOUT_RENEGOTIATION_INTERMEDIATE_ROUND = 'INFORM_SELLER_ABOUT_RENEGOTIATION_INTERMEDIATE_ROUND',
  INFORM_SELLER_ABOUT_RENEGOTIATION_LAST_ROUND = 'INFORM_SELLER_ABOUT_RENEGOTIATION_LAST_ROUND',
  INFORM_SELLER_ABOUT_RENEGOTIATION_STARTING = 'INFORM_SELLER_ABOUT_RENEGOTIATION_STARTING',
  INFORM_SELLER_ABOUT_SUCCESSFUL_AUCTION = 'INFORM_SELLER_ABOUT_SUCCESSFUL_AUCTION',
  INFORM_SELLER_ABOUT_SUCCESSFUL_PAYOUT = 'INFORM_SELLER_ABOUT_SUCCESSFUL_PAYOUT',
}

/** Interface that contains notification preferences */
export interface INotificationPreferences {
  /** List of notifications that the user unsubscribed from and should not receive */
  unsubscribeList?: ENotificationType[];
}

/** Interface that contains users notification preferences */
export interface IUserNotificationPreferences {
  disabledEmailTemplatesNotifications?: EMailTemplate[];
  disabledEmailTypesNotifications?: ECustomerMessageType[];
  /** The users push notificaiton preferences */
  pushNotifications?: INotificationPreferences;
}

export enum EUserState {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
}

/** @deprecated */
export enum EUserType {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
}

/**
 * The description of a seller user (i.e. user types putting vehicles for sale onto the platform).
 * Previously referred to as dealership users.
 * @deprecated
 */
export interface ISellerUser {
  /**
   * @deprecated
   * @format double
   */
  id: number;
  uuid: string;
  /**
   * Indicating the time when the entity has been created, i.e. when the entity was
   * persisted to the database.
   * @format date-time
   */
  createdAt?: string;
  /**
   * Indicating the last time a value of the entity has been changed (updated).
   * @format date-time
   */
  updatedAt?: string;
  /**
   * Indicating the time when the entity has been (softly) deleted.
   * "null" if entry is not deleted.
   * @format date-time
   */
  deletedAt?: string;
  /** The userId generated by the IdP */
  userId?: string;
  /** The state of the user. */
  state: EUserState;
  /** The mail address of the user. */
  mailAddress: string;
  /** The mail address which is using for login. */
  loginMailAddress?: string;
  /** The password of the user. */
  password?: string;
  /** The privilege string, indicating what the user is allowed to do. */
  privileges: string;
  /** The user type. */
  type?: EUserType;
  preferredLanguage: string;
  /**
   * Whether the customer wants to be addressed with honorific form
   * (e.g. "Sie" instead of "Du" in German)
   */
  honorific: boolean;
  /** The first name of the customer. */
  firstName: string;
  /** The last name of the customer. */
  lastName: string;
  /**
   * Multiple slots for phone numbers.
   * "null" if a slot is not used.
   */
  phoneNumber: string;
  phoneNumber2: string;
  phoneNumber3: string;
  /**
   * The referral code that was used by the customer when registering to COS.
   * "null" if no referral code has been used.
   */
  referralCode: string;
  isAllowedToSeeTradeIns: boolean;
  isAllowedToManageTradeIns: boolean;
  isAllowedToSeeTradeInRequests: boolean;
  isAllowedToSeeTradeInValuationSellPrice: boolean;
  isAllowedToSeeTradeInValuationBuyPrice: boolean;
  isAllowedToCreateAuctions: boolean;
  isAllowedToConfirmRejectBelowMin: boolean;
  isAllowedToSeePurchasePrices: boolean;
  isAllowedToSeeClosedAuctions: boolean;
  isAllowedToSeeDirectlyAssociatedAuctions: boolean;
  isAllowedToSeeAllAssociatedAuctions: boolean;
  isAllowedToChangeAccountData: boolean;
  /** Current version vector */
  versionVector: number[];
  /** Indicates this update comes from a remote system */
  isRemoteUpdate: boolean;
  /** Same as {@link IGeneralUser.permissions }, added here for compatibility after migrating Seller Users to General Users */
  permissions: EGeneralUserPermission[];
  /**
   * If this user is enabled for trade-in platform features, this is the preference with
   * which he wants incoming trade-in leads to be valuated.
   */
  tradeInValuationPreference: EDealershipTradeInValuationPreference;
  /** Flag indicating whether the seller user is allowed to make trade-in valuation requests (market price requests). */
  allowTradeInValuationRequests: boolean;
  /** Flag indicating whether the seller user is allowed to make guaranteed price requests. */
  allowGuaranteedPriceRequests: boolean;
  /**
   * The default value of this seller's margin in the Trade In Valuation (Market Price Request) view.
   * @format double
   */
  defaultTradeInValuationMargin: number;
  /** Reference to the SellerAccount to which the seller user is assigned to. */
  _fk_uuid_seller_account: string;
  /** Indicates whether the user itself should be used as the logistics contact for its own auctions. */
  isLogisticsContactForOwnAuctions: boolean;
  /** Same as {@link IGeneralUserSettings.notificationPreferences }, added here for compatibility after migrating Seller Users to General Users */
  notificationPreferences: IUserNotificationPreferences;
}

/**
 * This state is calculated continuously. The definition of "active" behavior is:
 * @deprecated
 */
export enum ESalesmanBehaviorClass {
  Value0 = 0,
  Value1 = 1,
}

/**
 * A rating given for an auction.
 * Can involve a buyer and/or a seller.
 */
export interface IRating {
  /**
   * @deprecated
   * @format double
   */
  id: number;
  uuid: string;
  /**
   * Rating value (usually between 1 and 5)
   * @format double
   */
  value: number;
  /** Additional text for the rating. */
  textFeedback: string;
  /** The associated auction */
  _fk_uuid_auction: string;
  /** The associated seller user */
  _fk_uuid_sellerUser: string;
  /** The associated buyer user */
  _fk_uuid_buyerUser: string;
  /**
   * Timestamp when the rating has been created.
   * @format date-time
   */
  createdAt?: string;
  /**
   * The auction model, which is a central element in the data model, referencing and referenced by most other
   * entities in the model.
   *
   * TODO
   */
  associatedAuction: IAuction;
  /**
   * The description of a seller user (i.e. user types putting vehicles for sale onto the platform).
   * Previously referred to as dealership users.
   */
  associatedDealership: ISellerUser;
  associatedSalesman: IBuyerUser;
}

export enum EAuctionDetailViewSection {
  GALLERY_AND_BIDDING_WIDGET = 'GALLERY_AND_BIDDING_WIDGET',
  VEHICLE_CONDITION = 'VEHICLE_CONDITION',
  VEHICLE_ADDITIONAL_INFO = 'VEHICLE_ADDITIONAL_INFO',
  VEHICLE_DATA = 'VEHICLE_DATA',
  VEHICLE_DOCUMENTS = 'VEHICLE_DOCUMENTS',
  EQUIPMENT = 'EQUIPMENT',
}

export interface IAuctionDetailViewPreferences {
  /** Section display order in the auction detail view */
  sectionOrder?: EAuctionDetailViewSection[];
}

export enum EBuyerBiddingProhibitionReason {
  Value1 = -1,
  Value0 = 0,
  Value11 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
  Value5 = 5,
}

export interface IFile {
  mimeType: string;
  encoding: string;
  fileName?: string;
  rawData: any;
  url: string;
}

/** @deprecated */
export interface IBuyerUser {
  /**
   * @deprecated
   * @format double
   */
  id: number;
  uuid: string;
  /**
   * Indicating the time when the entity has been created, i.e. when the entity was
   * persisted to the database.
   * @format date-time
   */
  createdAt?: string;
  /**
   * Indicating the last time a value of the entity has been changed (updated).
   * @format date-time
   */
  updatedAt?: string;
  /**
   * Indicating the time when the entity has been (softly) deleted.
   * "null" if entry is not deleted.
   * @format date-time
   */
  deletedAt?: string;
  /** The userId generated by the IdP */
  userId?: string;
  /** The state of the user. */
  state: EUserState;
  /** The mail address of the user. */
  mailAddress: string;
  /** The mail address which is using for login. */
  loginMailAddress?: string;
  /** The password of the user. */
  password?: string;
  /** The privilege string, indicating what the user is allowed to do. */
  privileges: string;
  /** The user type. */
  type?: EUserType;
  preferredLanguage: string;
  /**
   * Whether the customer wants to be addressed with honorific form
   * (e.g. "Sie" instead of "Du" in German)
   */
  honorific: boolean;
  /** The first name of the customer. */
  firstName: string;
  /** The last name of the customer. */
  lastName: string;
  /**
   * Multiple slots for phone numbers.
   * "null" if a slot is not used.
   */
  phoneNumber: string;
  phoneNumber2: string;
  phoneNumber3: string;
  /**
   * The referral code that was used by the customer when registering to COS.
   * "null" if no referral code has been used.
   */
  referralCode: string;
  addressLine?: string;
  zipCode?: string;
  city: string;
  countryCode: ECountryCode;
  /** Add extra comments do the location (e.g., pick-up instruction in transportation tasks). */
  comment?: string;
  /** Language of the contact person at the location. */
  contactLanguage?: string;
  /** Current version vector */
  versionVector: number[];
  /** Indicates this update comes from a remote system */
  isRemoteUpdate: boolean;
  /**
   * GPS coordinates of the auction (can be used for e.g. calculating distances between auctions and buyers).
   * @format double
   */
  locationGeoLat: number;
  /** @format double */
  locationGeoLon: number;
  /** Reference to the account this user belongs to */
  _fk_uuid_account: string;
  /** The legal business name of the buyer business. */
  businessName: string;
  /**
   * Timestamp of when the buyer has been called for the last time
   * (i.e. when the last call has been registered for this buyer)
   * @format date-time
   */
  lastCalledAt: string;
  /**
   * A flag indicating whether the buyer is not allowed to bid on auctions.
   *
   * This can be set manually by admins (e.g. when a buyer is not paying his invoices).
   * Also for all "test accounts" (i.e. preregistered accounts), bidding is always prohibited
   * since the creation.
   */
  biddingIsProhibited: boolean;
  /**
   * A flag indicating whether this buyer is a "test account" (i.e. preregistered account).
   * This is set to "true" when the buyer is created as a test account (e.g. through the call center).
   */
  isPreregisteredAccount: boolean;
  /**
   * Timestamp when the buyer user has been fully registered (i.e. when the buyer is turning from a
   * preregistered user into a fully registered user).
   *
   * A buyer can only be "fully registered" by an admin.
   * @format date-time
   */
  fullyRegisteredAt: string;
  /** @deprecated */
  interestedInSellingVehicles: boolean;
  /**
   * The last "behavior class" (i.e. whether the buyer is "active" or "inactive") that was calculated for the buyer.
   *
   * Behavior is calculated on a frequent basis.
   *
   * Based on his behavior class, the pusher (ACTIVE behavior) resp. activator (INACTIVE behavior)
   * is responsible for the buyer.
   */
  lastBehaviorClass: ESalesmanBehaviorClass;
  /** @format double */
  averageRating?: number;
  /** @format double */
  numRatings?: number;
  ratings?: IRating[];
  /** Auction detail view preferences */
  auctionDetailViewPreferences?: IAuctionDetailViewPreferences;
  personalReferralCodeToShare: string;
  /** A reference to an external payment account (e.g. to a Stripe customer object). */
  externalPaymentId: string;
  /**
   * URL to the template document for the power of attorney document for transportation jobs.
   * This template document is generated automatically for every buyer.
   */
  urlToPowerOfAttorneyDocumentFile: string;
  /**
   * URL to the signed document for the power of attorney document for transportation jobs.
   * This is only set after the buyer has uploaded the signed document (either manually uploaded
   * or automatically after signing through HelloSign)
   */
  urlToSignedPowerOfAttorneyDocumentFile: string;
  /**
   * When the signed power of attorney document for transportation has been uploaded.
   * @format date-time
   */
  signedPowerOfAttorneyDocumentUploadDate: string;
  /**
   * Indicating whether the VAT ID has been verified.
   * This is mostly done for VAT IDs from non-German countries.
   *
   * Verification is usually done automatically through the BZST web service.
   */
  isVatIdVerified: boolean;
  /** Stores a reason why a buyer was blocked. */
  biddingProhibitionReason: EBuyerBiddingProhibitionReason;
  /** Reference to responsible field sales manager user. */
  _fk_uuid_internalUser: string;
  /** Reference to the vat id object from the external payment provider Stripe. */
  vatIdExternalReference: string;
  /**
   * Adjustment value that is offset from the total balance value
   * @format double
   */
  balanceAdjustmentValue: number;
  /** Reference to seller user object for the external Billomat invoicing provider. */
  thirdPartyInvoicingProviderReference: string;
  /** Flag indicating if A buyer can view and purchase cross-border net sale auctions. */
  allowCrossBorderNetSale: boolean;
  /** The VAT ID of the customer. */
  vatId: string;
  /** The tax ID (different from the VAT ID) of the customer. */
  taxId: string;
  /**
   * The URL to the ID document of the customer
   * (required for verification to become a fully registered buyer user).
   */
  urlToIdDocument: string;
  /** @deprecated */
  idDocument: IFile;
  /**
   * The URL to the business registration document
   * (required for verification to become a fully registered buyer user).
   */
  urlToBusinessRegistrationDocument: string;
  /** @deprecated */
  businessRegistrationDocument: IFile;
  /** Same as {@link IGeneralUserSettings.notificationPreferences }, added here for compatibility after migrating Buyer Users to General Users */
  notificationPreferences: IUserNotificationPreferences;
  /**
   * Same as {@link IGeneralUserSettings.enabledForLoyaltyProgramAt }, added here for compatibility after migrating Buyer Users to General Users
   * @format date-time
   */
  enabledForLoyaltyProgramAt: string;
  /**
   * Same as {@link IGeneralUserSettings.loyaltyProgramOptedIntoAt }, added here for compatibility after migrating Buyer Users to General Users
   * @format date-time
   */
  loyaltyProgramOptedIntoAt: string;
}

/** List of the COS services that an account can be enabled for (see {@link IAccount.enabledServices}) */
export enum EAccountService {
  MARKETPLACE_SELLER = 'MARKETPLACE_SELLER',
  MARKETPLACE_BUYER = 'MARKETPLACE_BUYER',
}

export enum EUserLookAndFeelId {
  DEFAULT = 'DEFAULT',
  MERCEDES_BENZ = 'MERCEDES_BENZ',
}

/** Account Business Type */
export enum EBusinessType {
  OTHERS = 'OTHERS',
  LICENSED = 'LICENSED',
  LEASING = 'LEASING',
  MOBILITY_PROVIDER = 'MOBILITY_PROVIDER',
  TRADER = 'TRADER',
  FLEET_OPERATOR = 'FLEET_OPERATOR',
  CERTIFIED_DEALER = 'CERTIFIED_DEALER',
  PRIVATE = 'PRIVATE',
  BANK = 'BANK',
  OEM = 'OEM',
  INDEPENDENT_TRADER = 'INDEPENDENT_TRADER',
  REPAIR_SHOP = 'REPAIR_SHOP',
}

/**
 * Seller Business Type
 * @deprecated
 */
export enum ESellerBusinessType {
  Value1 = -1,
  Value0 = 0,
  Value11 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
  Value5 = 5,
  Value6 = 6,
  Value7 = 7,
  Value8 = 8,
  Value9 = 9,
  Value10 = 10,
}

/** The mode in which sellers are invoiced the seller fee. */
export enum ESellerFeeInvoicingMode {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
}

/** Types of Stripe connected account business types. */
export enum EStripeBusinessType {
  Company = 'company',
  Individual = 'individual',
  NonProfit = 'non_profit',
}

/**
 * The mode in which buyers and sellers are invoiced for the auction.
 * @deprecated
 */
export enum ESellerPurchaseInvoicingMode {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
}

export enum ESellerFeeInvoicingFrequency {
  Value0 = 0,
  Value1 = 1,
}

export enum EPrePaymentCategory {
  TOP_PRIORITY = 'TOP_PRIORITY',
  PRIORITY = 'PRIORITY',
  IMPORTANT = 'IMPORTANT',
  OTHER = 'OTHER',
  NO_PREPAYMENT = 'NO_PREPAYMENT',
}

/** Represents the weekly auction slotting schedule. */
export interface ISlottingSchedule {
  '1': boolean[];
  '2': boolean[];
  '3': boolean[];
  '4': boolean[];
  '5': boolean[];
}

/** Slotting schedule on specific days. */
export interface ISlottingDays {
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
}

/**
 * All accounts ({@link IAccount }) enabled for Selling ({@link EAccountService.MARKETPLACE_SELLER }) need this accessory entity ({@link IAccountSettingsSeller})
 * that contains all settings that are specific to the selling activity
 */
export interface IAccountSettingsSeller {
  uuid: string;
  /**
   * Indicating the time when the entity has been created, i.e. when the entity was
   * persisted to the database.
   * @format date-time
   */
  createdAt?: string;
  /**
   * Indicating the last time a value of the entity has been changed (updated).
   * @format date-time
   */
  updatedAt?: string;
  /**
   * Indicating the time when the entity has been (softly) deleted.
   * "null" if entry is not deleted.
   * @format date-time
   */
  deletedAt?: string;
  /** Current version vector */
  versionVector: number[];
  /** Indicates this update comes from a remote system */
  isRemoteUpdate: boolean;
  /** Optional first name of the person that should receive a copy of all logistic-related communication. */
  logisticsContactFirstName: string;
  /** Optional last name of the person that should receive a copy of all logistic-related communication. */
  logisticsContactLastName: string;
  /** Optional business name of the person that should receive a copy of all logistic-related communication. */
  logisticsContactBusinessName: string;
  /** Optional phone number of the person that should receive a copy of all logistic-related communication. */
  logisticsContactPhoneNumber: string;
  /** Optional email address of the person that should receive a copy of all logistic-related communication. */
  logisticsContactMailAddress: string;
  /** Optional email addresses of the people that should receive a copy of all logistic-related communication separated by comma ','. */
  logisticsContactCcMail: string;
  /** A flag indicating whether transportation is enforced for auctions owned by this Account. */
  enforceTransportation: boolean;
  /** Controls whether the automatic pickup approval is enabled (default = true). */
  autoPickupOnPaymentReceivedBySeller: boolean;
  /**
   * Flag indicating if pickups should be immediately
   * possible on money coming to COSPay.
   *
   * When this is set to "true", auctions in "CLOSED_WAITING_FOR_PAYMENT"
   * will be automatically set to "CLOSED_WAITING_FOR_PICKUP" as soon as
   * an incoming payment is detected by COSPay.
   */
  autoPickupOnPaymentReceivedByCos: boolean;
  /** Flag indicating whether the document shipping process has to be activated for this account */
  isDocumentShippingEnabled: boolean;
  /** Flag indicating whether the document shipping process has to be activated for this account and its regular buyers. */
  isDocumentShippingEnabledForRegularBuyers: boolean;
  /** Flag which indicates whether standings costs can be applied to buyers of auctions by this account */
  shouldApplyStandingCosts: boolean;
  /**
   *
   * Optional pickup instructions that will be communicated to the buyer
   */
  pickupInstructions?: string;
  /** Flag which indicates if a vehicle is handled via logistics compound */
  isCompoundEnabled: boolean;
  /** Flag which indicates if a CRM user enabled this account to participate in auction rooms */
  auctionRoomsEnabled: boolean;
  /** Flag which indicates that vehicles from this seller can only be picked up after a delay of several working days */
  delayedPickupEnabled?: boolean;
  /** Preferable document shipping provider for the seller */
  documentShippingProviderName: EShippingOrderProvider;
  /** Start time for document pickup (e.g 08:00) */
  documentPickupStartTime: string;
  /** End time for document pickup (e.g 17:00) */
  documentPickupEndTime: string;
  /**
   * Days to delay the document pickup due preparations
   * @format double
   */
  documentPickupDelayDays: number;
  /** Notes related to the document pickup */
  documentPickupNote: string;
  /** Reference to stripe account entity */
  stripeConnectedAccountId: string;
  /** Reference to the tax registration document in Stripe */
  stripeFileIdTaxRegistrationDocument: string;
  /**
   * Reference to the direct debit handle (e.g. the Stripe source for direct debit)
   *
   * If this is null, it means that no direct debit handle (i.e. source) has been created and therefore the account
   * has not yet approved the direct debit mandate (SEPA mandate).
   */
  stripeDirectDebitSourceId: string;
  /** Reference to company the owner in Stripe */
  stripePersonIdOwner: string;
  /** References to the company owner identity verification documents used in the Stripe KYC process */
  stripeFileIdOwnerDocumentFront: string;
  stripeFileIdOwnerDocumentBack: string;
  stripeFileIdOwnerAdditionalDocument: string;
  /** Legal business type of the legal entity for Stripe */
  stripeBusinessType: EStripeBusinessType;
  /** Flag that indicates if this account is activated to use our payment service: CosPay */
  cosPayEnabled: boolean;
  /** Is true when the account has been kyc checked by Stripe */
  stripePayoutsEnabled: boolean;
  /** Flag indicating if payouts are to be blocked to account. When the flag indicates true no payouts will be processed */
  blockPayouts: boolean;
  /** Is true when KYC needed information is under review */
  kycCheckInProgress: boolean;
  /**
   * Is true when KYC needed information is incomplete, i.e., there are pendencies to be solved
   * (meaning one or more fields are currently due)
   */
  kycCheckPendencies: boolean;
  /**
   * Timestamp indicating when the kyc checked was approved. Should be null if not kyc checked.
   * @format date-time
   */
  kycCheckedAt: string;
  /**
   * Sometimes there are additional requirements after a connected account passes a certain volume
   * When this happens a deadline is defined, if the requested information is not collected or updated until that date,
   * payouts will be disabled.
   * @format date-time
   */
  kycExpirationDate: string;
  /** Indicates how auction is invoiced to the sellers and buyers */
  purchaseInvoicingMode: ESellerPurchaseInvoicingMode;
  /**
   * Timestamp the purchase invoicing mode was last updated at
   * @format date-time
   */
  purchaseInvoicingModeUpdatedAt: string;
  /** Flag being true indicates that the payout is only enabled when the Seller to COS invoice has been received */
  conditionalPayoutsEnabled: boolean;
  /** Flag being true indicates that we'll automatically payout money to the seller from our balance, BEFORE receiving the money from the buyer */
  paymentInAdvanceEnabled: boolean;
  /** Indicates if the seller is enabled to use the Instant Payouts feature from Stripe. */
  stripeInstantPayoutsEnabled: boolean;
  /** Indicates if the bulk invoices are enabled or not */
  sellerFeeInvoicingFrequency: ESellerFeeInvoicingFrequency;
  /**
   * Setting to enable a distinct fee below a certain auctionHighestBid threshold.
   *
   * Note: (Only for seller success fee)
   */
  enableSuccessFeeThreshold: boolean;
  /**
   * Auction bid threshold value
   * @format double
   */
  successFeeThreshold: number;
  /**
   * Fee below threshold value
   * @format double
   */
  successFeeAmountWhenBelowThreshold: number;
  /** Has free amount of vehicles enabled */
  complimentaryVehiclesEnabled: boolean;
  /**
   * Amount of free vehicles remaining
   * @format double
   */
  remainingComplimentaryVehicles: number;
  prePaymentCategory: EPrePaymentCategory;
  /** Stores the auction schedule configuration. */
  auctionSlottingSchedule: ISlottingSchedule;
  /** Stores the new auction schedule. */
  auctionSlottingDays: ISlottingDays;
  /** Flag indicating that all auctions from this seller should be test auctions per default */
  shouldMarkAllAuctionsAsTest: boolean;
  /** A flag indicating if any vehicles owned by members of this account should have their minimum required asks displayed to buyers. */
  revealMinAskOnAuctions: boolean;
  /** Reference to the account these settings are for */
  _fk_uuid_account: string;
  /**
   * The business type of the account enabled for selling
   * Most are "LICENSED" sellers
   * @deprecated
   */
  dealershipType: ESellerBusinessType;
  /** Flag indicating if seller's net auctions can be sold to foreign buyers. */
  allowCrossBorderNetSale: boolean;
  /**
   * Indicating when sellers are invoiced seller fees.
   * (see ESellerFeeInvoicingMode)
   *
   * The default mode to invoice the (seller) fee is
   *
   * Default is NONE.
   */
  feeInvoicingMode: ESellerFeeInvoicingMode;
  /**
   * The success fee (in EUR by default) that is charged for a seller per successfully transacted vehicle.
   *
   * This is only relevant when the IAccountSettingsSeller.feeInvoicingMode = ESellerFeeInvoicingMode.INVOICE_ON_SUCCESS
   *
   * Default fee is 0.
   * @format double
   */
  successFeePerVehicle: number;
  /**
   * The listing fee (in EUR by default) that is charged for a seller per (initially) listed vehicle.
   *
   * This is only relevant when the IAccountSettingsSeller.feeInvoicingMode = ESellerFeeInvoicingMode.INVOICE_ON_LISTING
   *
   * Default fee is 0
   * @format double
   */
  listingFeePerVehicle: number;
  /**
   * This flag indicates whether a seller "success" fee (see {@link ESellerFeeInvoicingMode}) should be charged when a
   * sale is made to a regular buyer. Some sellers have special contract conditions, where "regular buyer discounts"
   * are also not charged on the seller side.
   *
   * This is only relevant when the "feeInvoicingMode" = {@link ESellerFeeInvoicingMode.INVOICE_ON_SUCCESS}
   *
   * Default is false.
   *
   * TODO: This value is not yet used/relevant for the system.
   */
  shouldNotInvoiceSuccessFeeForRegularBuyers: boolean;
  /** Flag indicating if manual creation of auctions are allowed for the seller users of this account */
  isManualAuctionCreationForbidden: boolean;
  /**
   * @deprecated
   * @format double
   */
  regularBuyerDiscountRate: number;
  /**
   * A fee that should be charged on top of the buyer purchase fee invoice.
   * This is used to charge the buy-side for fees that we would usually charge on the sell-side.
   * In some cases, this is not possible, so we need to charge it on the buy-side.
   * @format double
   */
  buyerSuccessFeePerVehicleNet: number;
  /**
   * Was a CosBox (locker that Cos provides which contains documents and keys for vehicles ready to pick-up)
   * deployed to this account.
   */
  isCosBoxDeployed: boolean;
}

export interface IPrebookedServiceOptionBasicConfigPrebookedServiceTransportOptionType {
  /**
   * Price per option.
   * Can be predefined for the option or dynamic (e.g. transportation will depend on the distance and other criteria).
   * @format double
   */
  price?: number;
  /**
   * Price per option (net).
   * @format double
   */
  priceNet?: number;
  /**
   * Discount price per option.
   * Can be predefined for the option or dynamic (e.g. transportation will depend on the distance and other criteria).
   * @format double
   */
  discountPrice?: number;
  /**
   * Discount price per option (net).
   * @format double
   */
  discountPriceNet?: number;
  /** Option type, like selfPickup, standard etc. */
  type: PrebookedServiceTransportOptionType;
  /** True when can be selected. */
  isEnabled: boolean;
  /** The reason why the option is not available. */
  disableReason?: string;
}

/** Transport config option. */
export type IPrebookedServiceConfigTransportOption = IPrebookedServiceOptionBasicConfigPrebookedServiceTransportOptionType;

export interface IPrebookedServiceOptionBasicConfigPrebookedServiceGuaranteeOptionType {
  /**
   * Price per option.
   * Can be predefined for the option or dynamic (e.g. transportation will depend on the distance and other criteria).
   * @format double
   */
  price?: number;
  /**
   * Price per option (net).
   * @format double
   */
  priceNet?: number;
  /**
   * Discount price per option.
   * Can be predefined for the option or dynamic (e.g. transportation will depend on the distance and other criteria).
   * @format double
   */
  discountPrice?: number;
  /**
   * Discount price per option (net).
   * @format double
   */
  discountPriceNet?: number;
  /** Option type, like selfPickup, standard etc. */
  type: PrebookedServiceGuaranteeOptionType;
  /** True when can be selected. */
  isEnabled: boolean;
  /** The reason why the option is not available. */
  disableReason?: string;
}

/** Guarantee config option. */
export type IPrebookedServiceConfigGuaranteeOption = IPrebookedServiceOptionBasicConfigPrebookedServiceGuaranteeOptionType;

export interface IPrebookedServiceOptionBasicConfigPrebookedServiceBNPLOptionType {
  /**
   * Price per option.
   * Can be predefined for the option or dynamic (e.g. transportation will depend on the distance and other criteria).
   * @format double
   */
  price?: number;
  /**
   * Price per option (net).
   * @format double
   */
  priceNet?: number;
  /**
   * Discount price per option.
   * Can be predefined for the option or dynamic (e.g. transportation will depend on the distance and other criteria).
   * @format double
   */
  discountPrice?: number;
  /**
   * Discount price per option (net).
   * @format double
   */
  discountPriceNet?: number;
  /** Option type, like selfPickup, standard etc. */
  type: PrebookedServiceBNPLOptionType;
  /** True when can be selected. */
  isEnabled: boolean;
  /** The reason why the option is not available. */
  disableReason?: string;
}

export type IPrebookedServiceConfigBNPLOption = IPrebookedServiceOptionBasicConfigPrebookedServiceBNPLOptionType;

export type IPrebookedServiceConfigOption = IPrebookedServiceConfigTransportOption | IPrebookedServiceConfigGuaranteeOption | IPrebookedServiceConfigBNPLOption;

/** Represents global config for prebooked services with available options. */
export interface IPrebookedServiceConfig {
  /** Type of the service. */
  type: EPrebookedServiceType;
  /** Indicates if the service is enabled or not. */
  isEnabled: boolean;
  options: IPrebookedServiceConfigOption[];
  disableAfterAuctionExpiration: boolean;
}

export enum EMailboxDeliveryPreference {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
}

/**
 * All accounts ({@link IAccount }) enabled for Buying ({@link EAccountService.MARKETPLACE_BUYER }) need this accessory entity ({@link IAccountSettingsBuyer})
 * that contains all settings that are specific to the buying activity
 */
export interface IAccountSettingsBuyer {
  uuid: string;
  /**
   * Indicating the time when the entity has been created, i.e. when the entity was
   * persisted to the database.
   * @format date-time
   */
  createdAt?: string;
  /**
   * Indicating the last time a value of the entity has been changed (updated).
   * @format date-time
   */
  updatedAt?: string;
  /**
   * Indicating the time when the entity has been (softly) deleted.
   * "null" if entry is not deleted.
   * @format date-time
   */
  deletedAt?: string;
  /** Current version vector */
  versionVector: number[];
  /** Indicates this update comes from a remote system */
  isRemoteUpdate: boolean;
  /**
   * URL to the template document for the power of attorney document for transportation jobs.
   * This template document is generated automatically for every account enabled for buying.
   */
  urlToPowerOfAttorneyDocumentFile: string;
  /**
   * URL to the signed document for the power of attorney document for transportation jobs.
   * This is only set after a user of the Account has uploaded the signed document (either manually uploaded
   * or automatically after signing through HelloSign)
   */
  urlToSignedPowerOfAttorneyDocumentFile: string;
  /**
   * When the signed power of attorney document for transportation was uploaded.
   * @format date-time
   */
  signedPowerOfAttorneyDocumentUploadDate: string;
  /**
   * Timestamp when the associated account has requested to be KYC checked.
   *
   * This is null if a buyer has not requested the KYC yet.
   * This will also be reset to null if the KYC for the account is rejected.
   * @format date-time
   */
  kycRequestedAt: string;
  /**
   * Indicating whether the buyer is allowed to see minimum ask prices of ALL auctions.
   * This is configured on a per buyer basis.
   *
   * If this is true, the buyer should see all available minimum ask prices for auctions.
   *
   * If this is false, the buyer can still see minimum ask prices for auctions with sellers configured to display the min ask prices.
   */
  isAllowedToSeeMinAskPrices: boolean;
  /**
   * A flag indicating whether the account is not allowed to bid on auctions.
   *
   * This can be set manually by admins (e.g. when a buyer is not paying his invoices).
   * Also, for all "test accounts" (i.e. preregistered accounts), bidding is always prohibited
   * since the creation.
   */
  isBiddingProhibited: boolean;
  /** Stores a reason why the account was blocked. */
  biddingProhibitionReason: EBuyerBiddingProhibitionReason;
  /** Flag when the buyer is limited in the amount of vehicle they can buy, for example a first buyer. */
  hasLimitedAmountOfPurchases: boolean;
  /** Reference to the account these settings are for */
  _fk_uuid_account: string;
  /** Flag indicating if this account can view and purchase cross-border net sale auctions. */
  allowCrossBorderNetPurchase: boolean;
  /**
   * The last "behavior class" (i.e. whether the account is "active" or "inactive" in buying) that was calculated
   *
   * Behavior is calculated on a frequent basis.
   * @deprecated
   */
  lastBehaviorClass: ESalesmanBehaviorClass;
  /**
   * Adjustment value that is offset from the total balance value
   * @format double
   */
  balanceManualOffset: number;
  /** Config of all available prebooked services for the Account. */
  prebookedServicesConfig: IPrebookedServiceConfig[];
  /** Flag that determine if the buyer has buy now, pay later feature enabled */
  allowBuyNowPayLater: boolean;
  /** Flag when standing fee is enabled for the buyer and we'll need to extra charge them for some penalty. */
  isStandingFeeEnabled: boolean;
  /** Flag that indicates if the buyer account is marked as Premium */
  isPremium: boolean;
  /** Delivery preferences of a mailbox delivery for document shipping */
  mailboxDeliveryPreference: EMailboxDeliveryPreference;
  /** Flag enables invoice and the vehicle report to be merged into one PDF */
  isEnabledAttachVehicleReport: boolean;
  /** Flag enables invoice to be attached on the auction e-mail */
  isEnableAttachInvoiceToAuctionEmail: boolean;
  /** Notes related to the document delivery */
  documentDeliveryNote: string;
}

/**
 * An account represents a company (or a branch) that has a relationship with COS. In order to mimic the structure of big company groups, an account can own
 * other accounts (1 <-> N).
 */
export interface IAccount {
  uuid: string;
  /**
   * Indicating the time when the entity has been created, i.e. when the entity was
   * persisted to the database.
   * @format date-time
   */
  createdAt?: string;
  /**
   * Indicating the last time a value of the entity has been changed (updated).
   * @format date-time
   */
  updatedAt?: string;
  /**
   * Indicating the time when the entity has been (softly) deleted.
   * "null" if entry is not deleted.
   * @format date-time
   */
  deletedAt?: string;
  addressLine?: string;
  zipCode?: string;
  city: string;
  countryCode: ECountryCode;
  /** Add extra comments do the location (e.g., pick-up instruction in transportation tasks). */
  comment?: string;
  /** Language of the contact person at the location. */
  contactLanguage?: string;
  /** Current version vector */
  versionVector: number[];
  /** Indicates this update comes from a remote system */
  isRemoteUpdate: boolean;
  /** First name of the/an owner (CEO for example) of the legal entity. */
  ownerFirstName: string;
  /** Last name of the owner/an (CEO for example) of the legal entity. */
  ownerLastName: string;
  /**
   * Day of birth of the owner of the legal entity.
   * @format double
   */
  ownerBirthDay: number;
  /**
   * Month of birth of the owner of the legal entity.
   * @format double
   */
  ownerBirthMonth: number;
  /**
   * Year of birth of the owner of the legal entity.
   * @format double
   */
  ownerBirthYear: number;
  /** Email address of the owner of the legal entity. */
  ownerEmail: string;
  /** Phone number of the owner of the legal entity. */
  ownerPhoneNumber: string;
  /**
   * URL's to uploaded versions of identity verification documents for the owner of the legal entity
   * that can be used for the KYC (Know Your Customer) process
   */
  ownerUrlToIdDocumentFront: string;
  ownerUrlToIdDocumentBack: string;
  ownerUrlToAdditionalDocument: string;
  /** Email address that will be used to invoice the legal entity */
  invoicingMailAddress: string;
  /** Flag indicating if a separate address (from the main account address) should be used for billing */
  useSeparateBillingInfo: boolean;
  /** Name used for billing */
  billingName: string;
  /** Vat id used for billing */
  billingVatId: string;
  /**
   * All accounts ({@link IAccount }) enabled for Selling ({@link EAccountService.MARKETPLACE_SELLER }) need this accessory entity ({@link IAccountSettingsSeller})
   * that contains all settings that are specific to the selling activity
   */
  settingsSeller?: IAccountSettingsSeller;
  /**
   * All accounts ({@link IAccount }) enabled for Buying ({@link EAccountService.MARKETPLACE_BUYER }) need this accessory entity ({@link IAccountSettingsBuyer})
   * that contains all settings that are specific to the buying activity
   */
  settingsBuyer?: IAccountSettingsBuyer;
  addresses?: IAccountAddress[];
  /**
   * GPS coordinates of the auction (can be used for e.g. calculating distances between auctions and buyers).
   * @format double
   */
  locationGeoLat: number;
  /** @format double */
  locationGeoLon: number;
  /**
   * A flag indicating whether this is a "test account" (i.e. preregistered account).
   * This is set to "true" when the account is created as a test account (e.g. through the call center).
   */
  isPreregisteredAccount: boolean;
  /**
   * Timestamp when the account has been fully registered (i.e. when the account is turning from a
   * preregistered to fully a registered account).
   * @format date-time
   */
  fullyRegisteredAt: string;
  /** Reference to stripe customer entity of this Account */
  stripeCustomerId: string;
  /** Reference to the vat id object from Stripe. */
  stripeVatId: string;
  /** Reference to account object for the Billomat invoicing provider. */
  billomatClientId: string;
  /** Reference to account object for the Zoho invoicing provider. */
  zohoCustomerId: string;
  /** Reference to the address object for the Zoho invoicing provider. */
  zohoAddressId: string;
  /** List of services that this account is enabled for */
  enabledServices: EAccountService[];
  /** Reference to the parent account (N <-> 1) */
  _fk_uuid_parentAccount: string;
  /** Reference to the primary internal user assigned to this account */
  _fk_uuid_assignedInternalUser1: string;
  /** Reference to the secondary internal user assigned to this account */
  _fk_uuid_assignedInternalUser2: string;
  /** Reference to the tertiary internal user assigned to this account */
  _fk_uuid_assignedInternalUser3: string;
  /** The name of the legal entity or branch */
  name: string;
  /** Url to the homepage of the company */
  urlToHomePage: string;
  /** Url to the image of the company */
  urlToImage: string;
  /** Contact phone number for the legal entity or branch */
  phoneNumber: string;
  /** Value Added Tax Id of the legal entity */
  vatId: string;
  /**
   * Indicating whether the VAT ID has been verified
   * This is mostly done for VAT IDs from non-German countries
   *
   * Verification is usually done automatically through external APIs
   */
  isVatIdVerified: boolean;
  /** Tax Id of the legal entity (different from the Vat Id) */
  taxId: string;
  /**
   * Indicates whether the account is an independent legal entity (i.e. a business).
   * In some cases, e.g. a subsidiary (location) of a business is also modelled as an Account,
   * even though it is not an independent business/legal entity.
   *
   * This information is relevant for COSPay, so COSPay knows that it has to check for parent accounts, when
   * checking if a user belongs to a KYC-checked Account (only legal entities are KYC-checked)
   */
  isLegalEntity: boolean;
  /** URL to an uploaded version of the business registration document */
  urlToBusinessRegistrationDocument: string;
  /**
   * Start and end of the validity of a tax registration document, that is legally required in the EU
   * @format date-time
   */
  taxRegistrationDocumentValidFrom: string;
  /** @format date-time */
  taxRegistrationDocumentValidUntil: string;
  /** German tax registration number */
  taxRegistrationNumber: string;
  /** URL to an uploaded version of a tax registration document, that is legally required in the germany §22 */
  urlToTaxCertificateDocument: string;
  /**
   * Timestamp indicating when the tax certificate document was uploaded. Should be null if not present
   * @format date-time
   */
  taxCertificateUploadedAt: string;
  /** Flag indicating if the Account is strategic partner */
  isStrategicPartner: boolean;
  /** Account group's UUID linked to the account */
  groups: string[];
  /** lookAndFeelIds linked to the account */
  lookAndFeelIds?: EUserLookAndFeelId[];
  /**
   * The business type of the account enabled for buying/selling
   * Most are "LICENSED"
   */
  dealershipType: EBusinessType;
  /**
   * Represents the estimated number of yearly B2B purchases made by the account.
   * This field is collected during the company detail step of the sign-up funnel
   * to help the call center and marketing team evaluate user potential.
   *
   * Example values:
   * - "0 - 5"
   * - "5 - 10"
   * - "10 - 20"
   * - "20 - 50"
   * - "50 - 100"
   * - "100+"
   */
  yearlyB2BPurchases?: string;
}

export interface AuctionStatesFilter {
  '1'?: boolean;
  '2'?: boolean;
  '3'?: boolean;
  '4'?: boolean;
  '5'?: boolean;
  '6'?: boolean;
  '7'?: boolean;
  '8'?: boolean;
  '9'?: boolean;
  '10'?: boolean;
}

export interface IVehicleFilterMakeAndModelPair {
  make: string;
  model: string;
}

/** Filter object which can used when obtaining vehicles in/from the COS backend. */
export interface IVehicleFilter {
  search?: string;
  /** @format double */
  offset?: number;
  /** @format double */
  limit?: number;
  includeDeleted?: boolean;
  sortBy?: {
    nullsLast?: boolean;
    desc: boolean;
    field: string;
  }[];
  uuids?: string[];
  vin?: string;
  /** @deprecated */
  make?: string;
  makes?: string[];
  model?: string;
  makeAndModelPairs?: IVehicleFilterMakeAndModelPair[];
  ezFrom?: string;
  ezTo?: string;
  ezMonth?: string;
  ezYear?: string;
  /** @format double */
  hpFrom?: number;
  /** @format double */
  hpTo?: number;
  /** @format double */
  mileageFrom?: number;
  /** @format double */
  mileageTo?: number;
  fuelTypes?: EFuelType[];
  transmission?: ETransmissionType[];
  headlights?: EHeadlights[];
  upholstery?: EUpholsteryType[];
  navigation?: number[];
  sunRoof?: number[];
  sportPackage?: number[];
  vehicleHeater?: EVehicleHeater[];
  parkingAssistance?: EParkingAssistance[];
  categories?: EVehicleCategory[];
  datBaseModelRaw?: string[];
  /** @deprecated */
  vatIdReportable?: boolean;
  fullServiceHistory?: boolean;
  readyToDrive?: EVehicleReadyToDriveStatus[];
  hasDamages?: boolean;
  hadAccident?: boolean;
  isReimportedVehicle?: boolean;
  isHuValid?: boolean;
  /** @format double */
  numPreOwners?: number;
  doors?: EVehicleDoors[];
  /** @format double */
  numberOfSeats?: number;
  euroNorm?: EEuroNorm[];
  bodyColorCode?: EVehicleColor[];
  origins?: EVehicleCreationOrigin[];
}

/** The filter allows to filter by UUID keys. */
export interface IUuidFilter {
  uuids?: string[];
}

/**
 * Parameters for filtering of seller account objects.
 * @deprecated
 */
export interface ISellerAccountFilter {
  search?: string;
  /** @format double */
  offset?: number;
  /** @format double */
  limit?: number;
  includeDeleted?: boolean;
  sortBy?: {
    nullsLast?: boolean;
    desc: boolean;
    field: string;
  }[];
  /** Filter seller accounts that match the given name. */
  name?: string;
  /** Filter seller accounts that match the given homepage url. */
  urlToHomePage?: string;
  /** Filter seller accounts that match the given Value Added Tax Id. */
  vatId?: string;
  /** Filter seller accounts that match the given Tax Id. */
  taxId?: string;
  /** Filter seller accounts that match the given city of the legal entity. */
  city?: string;
  /** Filter seller accounts that match the given zip code of the legal entity. */
  zipCode?: string;
  /** Filter seller accounts that match one of the given country codes supplied. */
  countryCodes?: ECountryCode[];
  /**
   * Filter seller accounts that match one the given UUID's (Universally Unique Identifier) as
   * their own UUID.
   */
  uuids?: string[];
  /** Filter seller accounts whose parent match one of the given uuids. */
  withParents?: {
    uuids?: string[];
  };
  /** Filter seller accounts whose children match one of the given uuids. */
  withChildren?: {
    uuids?: string[];
  };
  /** Filter seller accounts that have a parent. */
  havingParent?: boolean;
  /** Filter seller accounts that have children. */
  havingChildren?: boolean;
  /** Filter seller accounts that are fully kycChecked (accounts that have the payoutsEnabled flag set to true). */
  isKycChecked?: boolean;
  /**
   * Filter seller accounts that have one or more fields that are currently in the process of being reviewed
   * by the payment service provider (accounts that have the kycCheckPending flag set to true).
   */
  isKycCheckPending?: boolean;
  /** Filter seller accounts that have on or more fields that are currently due for the kyc check. */
  isKycCheckIncomplete?: boolean;
  /** Filter seller accounts that have a deadline defined to update or complete the connected account data. */
  kycCheckExpiresSoon?: boolean;
  /** Filter seller accounts that match one of the given mail addresses as their invoice mail address. */
  invoicingMailAddresses?: string[];
  /**
   * Filter seller accounts that are linked to an existing external payment account
   * (if the seller account is not a legal entity, the parent account should be checked).
   */
  hasConnectedPaymentAccount?: boolean;
  /** Filter accounts that are legal entities. */
  isLegalEntity?: boolean;
  /** Filter seller accounts that have a field sales manager assigned. */
  havingFieldSalesManagerAssigned?: boolean;
  /** Filter seller accounts that have a inside sales manager assigned. */
  havingInsideSalesManagerAssigned?: boolean;
  /** Filter seller accounts that are assigned to the given field sales managers. */
  assignedToFieldSalesManagers?: {
    uuids?: string[];
  };
  /** Filter seller accounts that are assigned to the given inside sales managers. */
  assignedToInsideSalesManagers?: {
    uuids?: string[];
  };
  /** Filter seller accounts that have a tax certificate attached to them. */
  hasTaxCertificate?: boolean;
  /** Filter seller accounts that will need a new kyc check soon. */
  hasKycCheckDueSoon?: boolean;
  /**
   * Filter seller account that have been kyc checked from and to a given date.
   * @format date-time
   */
  kycCheckedFrom?: string;
  /** @format date-time */
  kycCheckedTo?: string;
  /**
   * Filter seller account that have had a kyc check from and to a given date.
   * @format date-time
   */
  taxCertificateUploadedFrom?: string;
  /** @format date-time */
  taxCertificateUploadedTo?: string;
  /** Filter seller accounts marked as strategic partners. */
  isStrategicPartner?: boolean;
  /**
   * Filter by the business type of the seller.
   * Most sellers are {@link ESellerBusinessType.LICENSED}.
   */
  sellerTypes?: ESellerBusinessType[];
}

/** Filter object which can used when obtaining auctions in/from the COS backend. */
export interface IAuctionFilter {
  search?: string;
  /** @format double */
  offset?: number;
  /** @format double */
  limit?: number;
  includeDeleted?: boolean;
  sortBy?: {
    nullsLast?: boolean;
    desc: boolean;
    field: string;
  }[];
  distance?: {
    /**
     * Current location longitude in degrees.
     * @format double
     */
    lon?: number;
    /**
     * Current location latitude in degrees.
     * @format double
     */
    lat?: number;
    /**
     * Searching for an auction in the desired radius in Km.
     * @format double
     */
    radius: number;
  };
  /**
   * Filter auction for given IDs.
   * If array is empty, no ID filter is applied (everything will be returned that is matching other filters)
   * @deprecated
   */
  ids?: number[];
  /**
   * Filter auction for given UUIDs.
   * If array is empty, no UUID filter is applied (everything will be returned that is matching other filters)
   */
  uuids?: string[];
  /** UUIDs of auctions that should be excluded in the search. */
  notUuids?: string[];
  /**
   * Filter auctions for external IDs.
   * If filtered for both "ids" and "externalIds", ID filter is joined.
   */
  externalIds?: string[];
  states?: AuctionStatesFilter;
  locationQuery?: string;
  locationZipCodeQuery?: string;
  /** Filter object which can used when obtaining vehicles in/from the COS backend. */
  vehicleSearchQuery?: IVehicleFilter;
  /** Filter only auctions owned by the specified seller users. */
  owners?: IUuidFilter;
  /** Filter only auctions NOT owned by the specified users. */
  ownersBlacklist?: IUuidFilter;
  /** Filter auctions by internal users who have created auctions. */
  internalCreators?: IUuidFilter;
  /** The filter allows to filter by UUID keys. */
  watchingBuyers?: IUuidFilter;
  /** The filter allows to filter by UUID keys. */
  highestBidders?: IUuidFilter;
  /** Parameters for filtering of seller account objects. */
  sellerAccounts?: ISellerAccountFilter;
  /** The filter allows to filter by UUID keys. */
  onlyForRegularBuyers?: IUuidFilter;
  /**
   * Countries from which auctions should be included in the search.
   * The `excludedCountries` filter takes precedence for any conflicts.
   */
  includeCountries?: ECountryCode[];
  /**
   * Countries from which auctions should be excluded in the search.
   * This filter takes precedence for any conflicts over the `includedCountries` filter.
   */
  excludeCountries?: ECountryCode[];
  /**
   * Filter for auctions that ended after/before the given timestamp.
   * @format date-time
   */
  endingTimeAfter?: string;
  /** @format date-time */
  endingTimeBefore?: string;
  /**
   * Filter for auctions that started after/before the given timestamp.
   * @format date-time
   */
  startedAfter?: string;
  /** @format date-time */
  startedBefore?: string;
  /**
   * Filter for auctions that created after/before the given timestamp.
   * @format date-time
   */
  createdAfter?: string;
  /** @format date-time */
  createdBefore?: string;
  /**
   * Filter for auctions that started after/before the given timestamp.
   * @format date-time
   */
  purchaseConfirmedAfter?: string;
  /** @format date-time */
  purchaseConfirmedBefore?: string;
  withInstantPurchaseActive?: boolean;
  havingTransportationTaskBooked?: boolean;
  isConfirmedBelowMinAsk?: boolean;
  /** This signifies if the car will end the auction successfully and be released immediately. */
  isMinAskReached?: boolean;
  /** @format double */
  minAskFrom?: number;
  /** @format double */
  minAskTo?: number;
  /** @format double */
  minAskFromNet?: number;
  /** @format double */
  minAskToNet?: number;
  /**
   * This range filter the current price of the auction (it first check the currentHighestBidValue and if it
   * doesnt exist yet then look at the startingBidValue or 0).
   * @format double
   */
  currentPriceFrom?: number;
  /** @format double */
  currentPriceTo?: number;
  withoutReferencedInvoices?: boolean;
  referencedInvoices?: string[];
  /**
   * Filter auctions that are in review (true) or are not in review (false).
   *
   * If undefined or null, filter is not applied.
   */
  needsReview?: boolean;
  /**
   * Filter auctions that are having reviewComment stored.
   *
   * Required for distinguishing between actual approved and rejected review.
   */
  hasReviewComment?: boolean;
  /**
   * Filter auctions with the given payment processes.
   *
   * If null or empty, no payment process filter is applied.
   */
  paymentProcesses?: EAuctionPaymentProcess[];
  /**
   * Filter auctions that have a confirmed payment from the buyer.
   *
   * (i.e. the thirdPartyVolumeChargeReference is not null)
   */
  hasIncomingPayment?: boolean;
  /**
   * Filter only auctions (not) having a transfer associated with them
   * (i.e. the thirdPartyTransferReference is not null)
   */
  hasTransfer?: boolean;
  /**
   * Filter only auctions (not) having a payout associated with them
   * (i.e. the thirdPartyPayoutReference is not null)
   */
  hasPayout?: boolean;
  /**
   * Filter only auctions which seller accounts have "express pickup"
   * enabled.
   */
  hasExpressPickup?: boolean;
  /** Filter only auction that were marked as suspected cash payments. */
  isSuspectedToHaveBeenPaidInCash?: boolean;
  /** Filter auctions with reported mistakes. */
  hasMistakes?: boolean;
  /** Filter live auctions. */
  isLive?: boolean;
  /** If set to true then filter for auctions where 'allowCrossBorderNetSale' set to true as well. */
  displayCrossBorderNetAuctions?: boolean;
  /**
   * Country of origin of the buyer (this will affect which auctions can be displayed based on legal conditions)
   * Should not be set by user.
   */
  buyerCountryCode?: ECountryCode;
  /** Filter only auctions which has a GPR linked to it */
  hasGPR?: boolean;
  /** Filter only auctions which has a third party vehicle purchase invoice linked to it */
  hasThirdPartyVehiclePurchaseInvoice?: boolean;
  /** Filter only auctions associated with the given vehicle invoice references */
  thirdPartyVehiclePurchaseInvoiceReferences?: string[];
  /** Filter only auctions which were created for testing purposes */
  isTest?: boolean;
  /** Filter only auctions which reached the max reinsertions allowed (defined by the {@link MAX_REINSERTIONS } env variable) */
  reachedMaxReinsertions?: boolean;
  /** Filter for auctions contained in the specified auction room. */
  auctionRoomUuid?: string;
  /** Filter auctions by VAT reportable status. */
  isVATReportable?: boolean;
  /**
   * Filter for auctions that were released after/before the given timestamp.
   * @format date-time
   */
  releasedAfter?: string;
  /** @format date-time */
  releasedBefore?: string;
  /**
   * Filter only auctions which seller accounts have conditional payouts
   * enabled.
   */
  hasConditionalPayouts?: boolean;
  /**
   * Filter only auctions which seller accounts are compound
   * enabled.
   */
  hasCompound?: boolean;
  /** Filter auctions that are waiting for its cloned auction for the buyer payment. */
  hideOriginalReauctionedWaitingForBuyerPayment?: boolean;
  /** Filter for auctions that have been unparked but buyer has placed at least one bid on it. */
  showUnparkedWithActivity?: boolean;
  /** Filter for auctions that have been visited by the buyer */
  visited?: boolean;
  /** Filter auctions that are clonned reauctions. */
  isReauction?: boolean;
  /** Filter auctions by the uuid of the original auction that it were cloned from. */
  clonedFrom?: IUuidFilter;
  /** Filter auctions by number of times it has been clonned for reauction. */
  auctioningIterations?: number[];
  /** Filter auctions by the group of the seller user. */
  sellerGroups?: string[];
  /**
   * Filter only auctions (not) having renegotiation
   * (i.e. the _fk_uuid_renegotiation is not null)
   */
  hasRenegotiation?: boolean;
  /**
   * Filter auctions that are in auction rooms that have
   * one of the selected advertisement types.
   */
  advertisementTypes?: string[];
  /** Filters auctions where the seller is a strategic partner or where the buyer is a regularBuyer. */
  hasDiscounts?: boolean;
}

/**
 * Extension of the {@link IGeneralUser } that contains extra settings or
 * preferences for the User that is not part of the basic User data
 */
export interface IGeneralUserSettings {
  uuid: string;
  /**
   * Indicating the time when the entity has been created, i.e. when the entity was
   * persisted to the database.
   * @format date-time
   */
  createdAt?: string;
  /**
   * Indicating the last time a value of the entity has been changed (updated).
   * @format date-time
   */
  updatedAt?: string;
  /**
   * Indicating the time when the entity has been (softly) deleted.
   * "null" if entry is not deleted.
   * @format date-time
   */
  deletedAt?: string;
  /** Current version vector */
  versionVector: number[];
  /** Indicates this update comes from a remote system */
  isRemoteUpdate: boolean;
  preferredLanguage: string;
  /** @deprecated */
  auctionDetailViewPreferences?: IAuctionDetailViewPreferences;
  /**
   * If this user is enabled for trade-in platform features, this is the preference with
   * which he wants incoming trade-in leads to be valuated.
   */
  tradeInValuationPreference: EDealershipTradeInValuationPreference;
  /**
   * The default value of this user's margin in the Trade In Valuation (Market Price Request) view.
   * @format double
   */
  defaultTradeInValuationMargin: number;
  /** Indicates whether the user itself should be used as the logistics contact for its own auctions. */
  isLogisticsContactForOwnAuctions: boolean;
  /** Reference to the user these settings belongs to */
  _fk_uuid_user: string;
  /** Interface that contains users notification preferences */
  notificationPreferences: IUserNotificationPreferences;
  /**
   * Timestamp for when a CRM user enabled this user to participate on the Loyalty Program
   * @format date-time
   */
  enabledForLoyaltyProgramAt: string;
  /**
   * Timestamp for when the user opted in for the Loyalty Program
   * @format date-time
   */
  loyaltyProgramOptedIntoAt: string;
  /** The last search filter that the user has used */
  lastSearch?: IAuctionFilter;
}

export enum UserAdditionalEmailRecipientRole {
  SELLER = 'SELLER',
  HEAD_OF_SALES = 'HEAD_OF_SALES',
  BRANCH_DIRECTOR = 'BRANCH_DIRECTOR',
  CEO = 'CEO',
  GENERAL_MANAGER = 'GENERAL_MANAGER',
  USED_CARS_COORDINATOR = 'USED_CARS_COORDINATOR',
  DISPOSITION = 'DISPOSITION',
  ACCOUNTING = 'ACCOUNTING',
  MANAGEMENT_ASSISTANCE = 'MANAGEMENT_ASSISTANCE',
  OTHERS = 'OTHERS',
}

export interface IUserAdditionalEmailRecipient {
  uuid: string;
  /**
   * Indicating the time when the entity has been created, i.e. when the entity was
   * persisted to the database.
   * @format date-time
   */
  createdAt?: string;
  /**
   * Indicating the last time a value of the entity has been changed (updated).
   * @format date-time
   */
  updatedAt?: string;
  /**
   * Indicating the time when the entity has been (softly) deleted.
   * "null" if entry is not deleted.
   * @format date-time
   */
  deletedAt?: string;
  /** Current version vector */
  versionVector: number[];
  /** Indicates this update comes from a remote system */
  isRemoteUpdate: boolean;
  /** Email address to where user's e-mails should be sent to. */
  email: string;
  /** Name of the recipient which the e-mail belongs to */
  recipientName: string;
  /** The recipient role in the user's company */
  recipientRole: UserAdditionalEmailRecipientRole;
  /** Reference to the user this additional e-mail belongs to */
  _fk_uuid_user: string;
}

/**
 * This is the new user structure, meant to replace our old fragmented user structure with Sellers, Buyers and etc.
 * A user is someone that is connected to a company and uses our platform for an activity enabled for that account.
 * As we're a B2B platform, our focus is always the account/business, the user is just someone acting in the name of an account.
 */
export interface IGeneralUser {
  uuid: string;
  /** The mail address of the user */
  mailAddress: string;
  /**
   * Indicating the time when the entity has been created, i.e. when the entity was
   * persisted to the database.
   * @format date-time
   */
  createdAt?: string;
  /**
   * Indicating the last time a value of the entity has been changed (updated).
   * @format date-time
   */
  updatedAt?: string;
  /**
   * Indicating the time when the entity has been (softly) deleted.
   * "null" if entry is not deleted.
   * @format date-time
   */
  deletedAt?: string;
  /** Current version vector */
  versionVector: number[];
  /** Indicates this update comes from a remote system */
  isRemoteUpdate: boolean;
  /** The mail address which is using for login */
  loginMailAddress?: string;
  /** The user id generated by the IdP (identity provider) */
  idpUserId?: string;
  /** Reference to the account this user belongs to */
  _fk_uuid_account: string;
  /**
   * Whether the user wants to be addressed with honorific form
   * (e.g. "Sie" instead of "Du" in German)
   */
  honorific: boolean;
  /** The first name of the user */
  firstName: string;
  /** The last name of the user */
  lastName: string;
  /** Url to the user profile picture */
  urlToProfilePicture: string;
  /** Slot 1 for the phone number of the user */
  phoneNumber: string;
  /** Slot 2 for the phone number of the user */
  phoneNumber2: string;
  /** Slot 3 for the phone number of the user */
  phoneNumber3: string;
  /** The state of the user. */
  state: EUserState;
  /** Referral code this user can share with friends to register in the platform */
  personalReferralCodeToShare: string;
  /**
   * The referral code that was used by the user when registering to COS.
   * "null" if no referral code has been used.
   */
  referralCode: string;
  /**
   * The roles this user plays (check {@link GENERAL_USER_ROLES})
   *
   * This can also be seen as a set of permissions
   */
  roles: string[];
  /** List of permissions indicating what the user is allowed to do. */
  permissions: EGeneralUserPermission[];
  /** Data for the account this user belongs to. Optional augmentation (see: {@link EGeneralUserAugmentation.ACCOUNT}). */
  account?: IAccount;
  /** The GeneralUserSettings data that contains extra settings and preferences. Optional augmentation (see: {@link EGeneralUserAugmentation.SETTINGS}). */
  settings?: IGeneralUserSettings;
  /** Additional e-mail recipients for e-mails sent to the user */
  additionalEmailRecipients?: IUserAdditionalEmailRecipient[];
  /**
   * Timestamp when the user went completed the self-sign up process by confirmation it's e-mail address.
   * @format date-time
   */
  mailAddressConfirmedAt?: string;
  /** Whether Two-Factor Authentication is enabled for this user */
  hasTwoFactorAuth?: boolean;
}

export enum ETransportationTaskState {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
  Value5 = 5,
  Value6 = 6,
  Value7 = 7,
}

export enum ETransportationEnforceReason {
  SELLER_CONFIG = 'SELLER_CONFIG',
  CROSS_BORDER_NET_SALE = 'CROSS_BORDER_NET_SALE',
  DUTCH_NET_EXPORT_DISCOUNT = 'DUTCH_NET_EXPORT_DISCOUNT',
}

/**
 * A (potential) transportation task, which is the task to transport a vehicle from a seller
 * (or another location) to a buyer.
 */
export interface ITransportationTask {
  /**
   * @deprecated
   * @format double
   */
  id: number;
  uuid: string;
  /**
   * Indicating the time when the entity has been created, i.e. when the entity was
   * persisted to the database.
   * @format date-time
   */
  createdAt?: string;
  /**
   * Indicating the last time a value of the entity has been changed (updated).
   * @format date-time
   */
  updatedAt?: string;
  /**
   * Indicating the time when the entity has been (softly) deleted.
   * "null" if entry is not deleted.
   * @format date-time
   */
  deletedAt?: string;
  /**
   * Timestamp when the transportation task is booked (i.e. the buyer is committing to
   * the transport - "purchasing it").
   *
   * This timestamp indicates when the "state" changed from PROPOSED_PRICE to REQUESTED_JOB.
   * @format date-time
   */
  bookedAt: string;
  /**
   * Timestamp when the transportation task is assigned to a carrier (transportation provider)
   * @format date-time
   */
  assignedAt: string;
  /**
   * Timestamp when the transportation task is picked up by a carrier (transportation provider)
   * @format date-time
   */
  pickedUpAt: string;
  /**
   * Timestamp when the transportation task is delivered by a carrier (transportation provider)
   * @format date-time
   */
  deliveredAt: string;
  /**
   * Timestamp when the transportation task has a proposed price
   * @format date-time
   */
  proposedPriceAt: string;
  /**
   * The earliest date at which the vehicle can be picked up.
   * @format date-time
   */
  earliestPickupDate: string;
  /**
   * The current state of the transportation task.
   *
   * A task with state REQUESTED_JOB, IN_PROGRESS or FINISHED can be considered to be "booked", which
   * means that the buyer has to pay the "netPrice" (+ taxes) for the transportation to COS.
   *
   * Tasks in "booked" states will be forwarded to the transportation planning system, deploying jobs
   * to the freelancing providers.
   */
  state: ETransportationTaskState;
  /**
   * The net price for the transportation task. This price is based on the "distanceInKm" from seller to buyer, so it
   * is individual per requesting buyer. The given price is calculated based on buckets for distance ranges.
   *
   * If a task is not yet in "booked" state, this is the price that buyers which requested a price for a
   * transportation will see as the price that it WOULD cost.
   *
   * If the transportation gets "booked", this price is used to invoice the buyer for the transportation costs.
   * If a buyer books within a certain time span after the auction purchase has been confirmed (normally 24 hours), the
   * "discountedNetPrice" will replace the "netPrice", so the buyer gets a discount (normally -10%) on the price, in
   * order to incentivize asap transportation booking.
   * @format double
   */
  netPrice: number;
  /**
   * This price is calculated on a buyer requesting a transportation price.
   * It is based on the "netPrice" with an applied discount.
   *
   * If the task is "booked" within a certain period of time after the auction purchase has been confirmed,
   * the "discountedNetPrice" replaces the "netPrice".
   * @format double
   */
  discountedNetPrice: number;
  /**
   * The "netPrice" + tax
   * @format double
   */
  grossPrice: number;
  /**
   * An internal price, which should indicate what the actual price for the transportation task is for COS.
   * This price is updated manually, since the actual price COS receives from its providers can differ based on
   * different factors.
   * @format double
   */
  internalNetPrice: number;
  /**
   * The distance in km calculated for the (driving) route from seller to buyer.
   * It is calculated via the Google Maps Distance Matrix API.
   *
   * The "distanceInKm" is the base for calculating a "netPrice".
   * @format double
   */
  distanceInKm: number;
  /**
   * The profit rate applied on the net price.
   * @format double
   */
  profitRate: number;
  /**
   * The discount rate applied on the net price.
   * @format double
   */
  discountRate: number;
  /** TODO: Ivan */
  urlToHandoverProtocolDocument: string;
  /**
   * Links the signed pickup authorization document
   * Allows transportation provider to pick up the vehicle
   */
  urlToSignedPickUpAuthorizationDocument: string;
  /** The from location address hash. */
  fromLocationAddressHash: string;
  /** The from location address line. */
  fromLocationAddressLine: string;
  /** The from location zip code. */
  fromLocationZipCode: string;
  /** The from location city. */
  fromLocationCity: string;
  /** The from location country code. */
  fromLocationCountryCode: ECountryCode;
  /** The to location address hash. */
  toLocationAddressHash: string;
  /** The to location address line. */
  toLocationAddressLine: string;
  /** The to location zip code. */
  toLocationZipCode: string;
  /** The to location city. */
  toLocationCity: string;
  /** The to location country code. */
  toLocationCountryCode: ECountryCode;
  /** The auction for which the transportation has been booked. */
  _fk_uuid_auction: string;
  /** The buyer which booked the transportation task. */
  _fk_uuid_buyerUser: string;
  /**
   * The transportation provider which is responsible for transporting the vehicle.
   *
   * This is manually set by admins after the transportation task has been booked.
   * Otherwise this is "null".
   */
  _fk_uuid_transportationProvider: string;
  /** @format double */
  _fk_transportationProvider: number;
  /**
   * The transportation price provider which is responsible for calculate transportation price for the vehicle.
   *
   * This is automatically set whenever the transportation price is requested.
   * Otherwise this is "null".
   */
  _fk_uuid_transportationPriceProvider: string;
  /** An external reference/key to an invoice in an external invoicing system like e.g. Stripe. */
  invoiceReference: string;
  /** A note for the transportation task. Usually used for non-COS tasks. */
  note: string;
  /**
   * Estimated date when a car will be picked up from the seller.
   * @format date-time
   */
  estimatedPickupDate: string;
  /**
   * Estimated date when a car will be delivered to the buyer.
   * @format date-time
   */
  estimatedDeliveryDate: string;
  /** Any extra information that can be shared with the carriers. */
  additionalInformation: string;
  /** Id used in the calculation of the transportation price */
  calculationId: string;
  /**
   * Minimum amount of days to pickup the vehicle
   * @format double
   */
  minDaysToPickup: number;
  /**
   * Maximum amount of days to pickup the vehicle
   * @format double
   */
  maxDaysToPickup: number;
  /** UUID of the internal user who booked the transportation task or null if it was booked by the buyer themselves. */
  bookedByInternalUserUuid: string;
  /** Auction has transportation enforced. */
  enforceTransportation: boolean;
  /** Reason the transportation is enforced for this auction. */
  enforceReason: ETransportationEnforceReason;
  /** Auction is considered a cross-border net transaction. */
  isCrossBorderNetSale: boolean;
  /**
   * Expiration date for the transportation task. If set, the task is allowed to be deleted
   * from the database after this date, unless it's been booked successfully.
   * @format date-time
   */
  expiresAt?: string;
}

/** The state of an auction payment. */
export enum EAuctionPaymentState {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
}

/**
 * Data structure containing all relevant information about the
 * payment state of an auction (i.e. the auction volume).
 */
export interface IAuctionPaymentState {
  /**
   * The current payment state of the auction.
   * This is determined on whether a transfer and/or a payout are available for the auction.
   */
  state: EAuctionPaymentState;
  /**
   * A timestamp when the charge has been triggered from the Buyer's balance to COS's balances.
   * @format date-time
   */
  incomingPaymentAt: string;
  /**
   * A timestamp when the transfer has been triggered to the seller.
   * It's possible for this reference to exist even tho the buyer hasn't
   * paid yet (e.g payment in advance process).
   *
   * (not to be confused with the payout bank transfer to the sellers bank account)
   * @format date-time
   */
  outgoingPaymentAt: string;
  /**
   * A timestamp when the VAT refund was triggered to the buyer.
   * @format date-time
   */
  refundedVatDepositAt: string;
  /**
   * A timestamp when an additional tax refund was triggered to the buyer (can be partial or full amount).
   * @format date-time
   */
  refundedAdditionalTaxAt: string;
  /**
   * The amount of additional tax that was refunded.
   * @format double
   */
  refundedAdditionalTaxAmount: number;
  /**
   * A timestamp when the payout to the seller account's bank account has been triggered.
   * @format date-time
   */
  payoutTriggeredAt: string;
  /**
   * The date when the payout is expected to arrive at the seller account's bank account.
   * @format date-time
   */
  expectedPayoutPaidAt: string;
  /** Whether the payout failed. This is always true, except if the payout has actually failed. */
  payoutFailed: boolean;
}

/** A bank account for a seller account. */
export interface ISellerBankAccount {
  uuid: string;
  /**
   * Indicating the time when the entity has been created, i.e. when the entity was
   * persisted to the database.
   * @format date-time
   */
  createdAt?: string;
  /**
   * Indicating the last time a value of the entity has been changed (updated).
   * @format date-time
   */
  updatedAt?: string;
  /**
   * Indicating the time when the entity has been (softly) deleted.
   * "null" if entry is not deleted.
   * @format date-time
   */
  deletedAt?: string;
  /** A label (alias) to identify the label. */
  label: string;
  /** The bank account number (IBAN) for the bank account. */
  iban: string;
  /** The seller account owning this bank account. */
  _fk_uuid_sellerAccount: string;
  /**
   * A reference to an external bank account object in an external payment system such as Stripe.
   * It is important to ensure that the IBAN in the bank account object is matching to the IBAN that
   * the external account is pointing to.
   */
  externalAccountReference: string;
}

/** How sellers see auctions. */
export interface ISellerAuctionView {
  /**
   * @deprecated
   * @format double
   */
  id: number;
  uuid: string;
  /**
   * Indicating the time when the entity has been created, i.e. when the entity was
   * persisted to the database.
   * @format date-time
   */
  createdAt?: string;
  /**
   * Indicating the last time a value of the entity has been changed (updated).
   * @format date-time
   */
  updatedAt?: string;
  /**
   * Indicating the time when the entity has been (softly) deleted.
   * "null" if entry is not deleted.
   * @format date-time
   */
  deletedAt?: string;
  /** Stores which type of additional tax applies to the auction if any. */
  additionalTaxType: EAdditionalTaxType;
  /**
   * Stores the value of the additional tax that might apply to an auction.
   * @format double
   */
  additionalTaxValue: number;
  /**
   * Stores the export discount for taxes.
   * @format double
   */
  additionalTaxExportDiscount: number;
  /**
   * The state of the auction, indicating whether an auction is not yet published (DRAFTED), running (ACTIVE),
   * closed successfully (CLOSED_WAITING_FOR_PAYMENT, CLOSED_WAITING_FOR_PICKUP, FINISHED), failed (CLOSED_NO_BIDS,
   * CLOSED_UNDER_MINIMUM_ASK) or disabled (DISABLED).
   *
   * Depending on the state, different timestamps are available per auction.
   */
  state: EAuctionState;
  /**
   * The exact date+time when the auction is expiring/ending.
   * @format date-time
   */
  endingTime: string;
  /**
   * The remaining number of seconds that this auction will be in the state ACTIVE (running).
   *
   * This is a dynamic, non-persisted field, calculated on every retrieval of the auction data.
   * @format double
   */
  remainingTimeInSeconds: number;
  /**
   * The remaining number of seconds that this auction is available for instant purchase
   * (usually the first 24 hours after the auction is started).
   *
   * This is a dynamic, non-persisted field, calculated on every retrieval of the auction data.
   * @format double
   */
  remainingTimeForInstantPurchaseInSeconds: number;
  /**
   * Date+time indicating until when an auction is available for instant purchasing.
   * By default, this is 24 hours after the start of an auction.
   * @format date-time
   */
  instantPurchasePossibleUntil: string;
  _fk_uuid_highestBiddingBuyerUser: string;
  /**
   * Value that is indicating the current highest bid value on the auction.
   * This is of course a dynamic field, that will be incremented on every new bid placed on the auction.
   * @format double
   */
  currentHighestBidValue: number;
  /** @format double */
  currentHighestBidValueNet: number;
  /**
   * The minimal price the seller user wants to reach for this specific auction.
   * If an auction ends above that price, the auction is successful right away
   * (switching to state CLOSED_WAITING_FOR_PAYMENT).
   *
   * If the auction ends below the minimum required ask, the auction will switch to state CLOSED_UNDER_MINIMUM_ASK,
   * which then requires the seller's explicit "confirmation" or "rejection" of the highest bid value.
   * @format double
   */
  minimumRequiredAsk: number;
  /** @format double */
  minimumRequiredAskNet: number;
  /**
   * Number of bids placed on an auction.
   * @format double
   */
  numBids: number;
  /** @format double */
  buyerRecommendationScore?: number;
  /** Was this auction created with the help of an inspection done or commissioned by CarOnSale? */
  inspectionWasCommissionedByCos: boolean;
  /** By which type of user was this auction created. */
  creatorType: EAuctionCreatorType;
  /** UUID of the creating internal user if there is one. */
  _fk_uuid_creatingInternalUser: string;
  /** UUID of the creating seller user if there is one. */
  _fk_uuid_creatingSellerUser: string;
  /**
   * Indicates if the auction was created for testing purposes and
   * should be handled differently: it should not be ended automatically,
   * so the seller should decide would he like to sell it or not.
   */
  isTest: boolean;
  /** Indicates if minimum required ask should be displayed for buyers. */
  displayMinAsk: boolean;
  /**
   * GPS coordinates of the auction (can be used for e.g. calculating distances between auctions and buyers).
   * @format double
   */
  locationGeoLat: number;
  /** @format double */
  locationGeoLon: number;
  /** @format double */
  counterOfferByBuyer: number;
  /** @format double */
  counterOfferByBuyerNet: number;
  /** @format double */
  lastOfferBySeller: number;
  /** @format double */
  lastOfferBySellerNet: number;
  /**
   * A value denoting the previous renegotiation offer given by a buyer user.
   * @format double
   */
  previousCounterOfferByBuyer: number;
  /** @format double */
  previousCounterOfferByBuyerNet: number;
  /**
   * A value denoting the previous renegotiation offer given by a seller user.
   * This is required in order to properly display the right information during the renegotiation process.
   * @format double
   */
  previousLastOfferBySeller: number;
  /** @format double */
  previousLastOfferBySellerNet: number;
  /** A flag for if the seller user connected to the auction has decided not to participate in the renegotiation any further. */
  renegotiationStoppedBySeller: boolean;
  /** A flag for if the highest bidding buyer user connected to the auction has decided not to participate in the renegotiation any further. */
  renegotiationStoppedByBuyer: boolean;
  /** A fag indicating if the buyer's withdrawal from the renegotiation process was done automatically or not. */
  wasRenegotiationStoppageByBuyerAutomatic: boolean;
  /** A fag indicating if the seller's withdrawal from the renegotiation process was done automatically or not. */
  wasRenegotiationStoppageBySellerAutomatic: boolean;
  /**
   * The total number of offer rounds a buyer has participated in for an auction during the renegotiation phase.
   * @format double
   */
  numBuyerRenegotiationOfferRounds: number;
  /**
   * The total number of offer rounds a seller has participated in for an auction during the renegotiation phase.
   * @format double
   */
  numSellerRenegotiationOfferRounds: number;
  /**
   * The calculated renegotiation midpoint value of the auction.
   * This is used once the renegotiation offers get too close for the offer process to continue.
   * This value will be undefined unless the auction is ready to be purchased for such a value.
   * @format double
   */
  renegotiationMidpointValue: number;
  /** @format double */
  renegotiationMidpointValueNet: number;
  /**
   * Indicates that the highest bidding buyer user for an auction in renegotiation is willing to accept the midpoint of their offer and
   * the offer of the seller for the same auction. This is used when the renegotiation offers get very close during the renegotiation process.
   */
  buyerAcceptedRenegotiationMidpoint: boolean;
  /**
   * Indicates that the seller of an auction is willing to accept the midpoint of their offer and the offer of the highest bidding
   * buyer user for the same auction. This is used when the renegotiation offers get very close during the renegotiation process.
   */
  sellerAcceptedRenegotiationMidpoint: boolean;
  /**
   * This is the datetime object denoting when the most recent renegotiation round concluded at.
   * @format date-time
   */
  lastRenegotiationRoundEndedAt: string;
  /**
   * The number of seconds until the current renegotiation round times out.
   * @format double
   */
  numSecondsUntilRenegotiationTimeout: number;
  /**
   * The UUID of the external case tracking the renegotiation of the auction.
   * We track this to enable case updates as the auction moves through renegotiation.
   */
  externalRenegotiationTicketUuid: string;
  /**
   * The calculated renegotiation fair value of the auction.
   * This is used once the renegotiation starts based on the auction GPR or MPR so we know
   * the fair value that both seller and buyer should agree upon.
   * @format double
   */
  renegotiationFairValue: number;
  /**
   * Value that is indicating the highest bid value on the auction when it ended.
   * This value should not change for a given auction since it supposed to be a historical record.
   * @format double
   */
  highestBidValueAtEndingTime: number;
  /** @format double */
  highestBidValueAtEndingTimeNet: number;
  /** Field data related to the renegotiation round process. */
  renegotiationRoundData?: IRenegotiationRoundData;
  /**
   * Indicates when manual renegotiation was finished via Salesforce.
   * @format date-time
   */
  finishedManuallyAt?: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing service (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the transfer of funds
   * for the vat deposit from COS to the deposit account (only relevant for cross border transactions).
   * This deposit is refunded by COS once the buyer provides the necessary documentation for a cross border transaction.
   */
  thirdPartyVATDepositTransferReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing service (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the transfer of funds
   * for the additional tax deposit from COS to the deposit account (only relevant for cross border transactions).
   * This deposit is refunded by COS once the buyer provides the necessary documentation for a cross border transaction.
   */
  thirdPartyAdditionalTaxDepositTransferReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing system (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the transfer of funds
   * for the auction additional taxes (e.g country specific luxury taxes): from COS to the seller.
   */
  thirdPartyAdditionalTaxTransferReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing service (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the transfer of funds
   * for the VAT: from COS to the seller (only relevant for cross border transactions for now).
   * COS is advancing the vat amount to the seller.
   */
  thirdPartyVatTransferReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing service (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the charge of funds
   * for the auction volume from the buyer to COS.
   */
  thirdPartyVolumeChargeReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing service (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the charge of funds
   * for the auction deposit from the buyer to COS (only relevant for cross border transactions for now).
   * This deposit is refunded by COS once the buyer provides the necessary documentation for a cross border transaction.
   */
  thirdPartyVATDepositChargeReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing service (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the charge of funds
   * for the auction additional tax (e.g country specific luxury tax) from the buyer to COS.
   */
  thirdPartyAdditionalTaxChargeReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing system (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the refund of an
   * additional tax deposit (only used for cross-border transactions currently).
   */
  thirdPartyAdditionalTaxRefundReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing system (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the payout of funds.
   */
  thirdPartyPayoutReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing system (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the refund of a
   * deposit (only used for cross-border transactions currently).
   */
  thirdPartyVATDepositRefundReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing system (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the refund of a
   * transfer to the deposit account owned by cos (only used for cross-border transactions currently).
   */
  thirdPartyVATDepositReversalReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing system (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the refund of the
   * additional tax transfer to the deposit account owned by cos (only used for cross-border transactions).
   */
  thirdPartyAdditionalTaxDepositReversalReference: string;
  /**
   * Reference to the invoice for the success fee issued to the seller for a sold vehicle (see ESellerFeeInvoicingMode.INVOICE_ON_SUCCESS).
   * The reference identifies the invoice issued to the seller in an external invoicing system (e.g. Stripe).
   *
   * This fee is not always charged, therefore this reference is optional (can be null).
   */
  sellerSuccessFeeInvoiceReference: string;
  /**
   * Reference to the invoice for the listing fee issued to the seller for a listed vehicle (see ESellerFeeInvoicingMode.INVOICE_ON_LISTING).
   * The reference identifies the invoice issued to the seller in an external invoicing system (e.g. Stripe).
   *
   * This fee is not always charged, therefore this reference is optional (can be null).
   */
  sellerListingFeeInvoiceReference: string;
  /**
   * Reference to the invoice for the standing fee issued to the buyer for a purchased vehicle picked up too late.
   *
   * This fee is not always charged, therefore this reference is optional (can be null).
   */
  buyerStandingFeeInvoiceReference: string;
  /**
   * Reference to a resource in an external invoicing system (such as Stripe).
   * References our own transaction fee invoice.
   */
  invoiceReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing system (such as Stripe).
   * This is only non-null, if external payment is allowed for this auction.
   */
  thirdPartyInvoiceReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing system.
   * This is only non-null, if external payment is allowed for this auction and it had to be cancelled.
   */
  thirdPartyCorrectionInvoiceReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing system (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the transfer of funds
   * for the auction volume: from COS to the seller.
   */
  thirdPartyTransferReference: string;
  /**
   * Reference of an invoice on an external invoicing system.
   * This is invoice is used to charge booked services (like guarantee) after the auction expires.
   */
  bookedServicesInvoiceReference: string;
  /**
   * Date-time when the auction was reauctioned and is now waiting for buyer payment until the new auction gets paid.
   * @format date-time
   */
  reauctionedWaitingForBuyerPaymentAt: string;
  /**
   * Date-time when the first reauction of this auction has expired.
   * We need to keep track of this so we can block the original buyer of seeing the original auction.
   * @format date-time
   */
  firstReauctionExpiredAt: string;
  /** Flag that indicates if this auction is a clonned reauction. */
  isReauction: boolean;
  /**
   * The highest bid value that we show to the seller when we merge the reauction with the original auction.
   * This is done so the seller see the amount that cos pay them and the buyer sees the amount that they paid cos.
   * @format double
   */
  sellerViewHighestBidValue: number;
  /**
   * The purchase confirmed at that we show to the seller when we merge the reauction with the original auction.
   * This is done so the seller see the date when the original auction was confirmed and the buyer sees the date that the reauction was confirmed.
   * @format date-time
   */
  sellerViewPurchaseConfirmedAt: string;
  /**
   * Date-time when the reauction was merged back at the original auction.
   * @format date-time
   */
  reauctionedMergedAt: string;
  /** Indicates wherever an auction was purchase using the Buy Now Pay Later feature. */
  wasPurchasedUsingBuyNowPayLater: boolean;
  /**
   * The timestamp at which the buyer has confirmed his choice to use Buy Now Pay Later for the auction.
   * @format date-time
   */
  buyNowPayLaterConfirmedAt: string;
  /** The IP address which confirmed the Buy Now Pay Later choice for the auction. */
  buyNowPayLaterConfirmationIPAddress: string;
  /** Reference to the Buy Now Pay Later Order on the PNI Service. */
  buyNowPayLaterOrderReference: string;
  /** Reference of the invoice for buy now pay later provider fee on an external invoicing system. */
  buyNowPayLaterInvoiceReference: string;
  /** The payment link reference */
  paymentLinkReference: string;
  /** Reference to the payment order on the P&I service */
  paymentOrderReference: string;
  isInternalFulfillment: boolean;
  belowMinAskBehaviour: EAuctionBelowMinAskBehaviour;
  /** The label of the auction, consisting of the vehicle make, model and date of first registration (EZ). */
  label: string;
  /**
   * Date+time when the auction is started.
   * When an auction is started right away (without drafting), the "createdAt" and "startedAt" should have
   * (roughly) the same values.
   * @format date-time
   */
  startedAt: string;
  /**
   * Date+time when the auction is "confirmed to be sold" by the seller (or by an admin).
   * If an auction is ending equal or above the minimum required ask, the "purchaseConfirmedAt"
   * will automatically be set and will be equal to the "endingTime".
   *
   * If an auction is ending below the minimum ask (CLOSED_UNDER_MINIMUM_ASK), the "purchaseConfirmedAt" timestamp
   * is only set if the auction is then manually "confirmed" by the seller or an admin.
   *
   * If an auction is never "successful", the "purchaseConfirmedAt" will remain "null".
   *
   * This value was introduced in the course of 2019. For all previous auctions, the endingTime was set
   * as the "purchaseConfirmedAt" retrospectively.
   * @format date-time
   */
  purchaseConfirmedAt: string;
  /**
   * Date-time when the auction has definitely been rejected by a seller or an admin, after the auction
   * ended below minimum required ask.
   *
   * This value is only available since 2019/07/29. All auctions rejected before that date have the value "null".
   * @format date-time
   */
  purchaseRejectedAt: string;
  /** Indicates the reason that an auction failed the renegotiation process. */
  rejectionReason: EAuctionRejectionReason;
  /**
   * Elaborates on the rejection reason to provide further context to why an
   * auction was rejected during the renegotiation process.
   */
  rejectionReasonNote: string;
  /**
   * The name of the competitor that the auction was sold to when the reject chosen reason was
   * VEHICLE_SOLD_ON_DIFFERENT_PLATFORM
   */
  rejectionReasonCompetitorName: string;
  /** In case if there was a direct upload requested but didn't succeed the reason should be stored. */
  directUploadRejectionReasons: EAuctionDirectUploadRejectionReason[];
  /**
   * Value that is indicating initial bid value auction should be started with.
   * @format double
   */
  startingBidValue: number;
  /** @format double */
  startingBidValueNet: number;
  /**
   * Value that can be manually set in case edge-cases apply and the payout amount is disconnected from the auction current highest bid.
   * This value can only be adjusted by internal users.
   * @format double
   */
  overridePayoutValue: number;
  /**
   * If an auction is ending below the minimum required ask, the "originalMinimumRequiredAsk" will be set to the
   * "minimumRequiredAsk" value before it is set to "currentHighestBidValue".
   * @format double
   */
  originalMinimumRequiredAsk: number;
  /** @format double */
  originalMinimumRequiredAskNet: number;
  /**
   * The purchase price for the vehicle that is auctioned.
   * This is set manually by the seller, and is mostly used to analyze the "real" margin that a seller
   * makes with an auction.
   * @format double
   */
  purchasePrice: number;
  /** @format double */
  purchasePriceNet: number;
  /**
   * The full object of the vehicle that is on auction.
   * This is augmented on every auction retrieval, based on _fk_uuid_vehicle.
   */
  associatedVehicle: IVehicle;
  /** Indicates whether the auction process is rated by the SellerUser. */
  isRatedByDealership: boolean;
  /** Indicates whether the auction process is rated by the BuyerUser. */
  isRatedByBuyer: boolean;
  /**
   * Indicates whether the BuyerUser has marked this auction as paid (payment of the auction volume).
   * This is done manually by the BuyerUser, so this value is not indicating that the actual payment has really
   * reached the seller.
   */
  isPaidByBuyer: boolean;
  /**
   * Timestamp when the BuyerUser has marked this auction as "paid" (i.e. set the "isPaidByBuyer").
   * @format date-time
   */
  outgoingPaymentConfirmedAt: string;
  /**
   * Timestamp when the SellerUser has confirmed that the payment has reached the seller bank account.
   * @format date-time
   */
  incomingPaymentConfirmedAt: string;
  /**
   * Timestamp when the auction state has changed from "CLOSED_WAITING_FOR_PAYMENT" to "CLOSED_WAITING_FOR_PICKUP".
   * @format date-time
   */
  pickupConfirmedAt: string;
  /** Physical address of where the vehicle on auction is located (a.k.a. pickup address). */
  locationCountryCode: ECountryCode;
  locationAddress: string;
  locationCity: string;
  locationZip: string;
  /** Physical address of where the vehicle on auction was inspected (a.k.a. inspection address). */
  inspectionLocationCountryCode: ECountryCode;
  inspectionLocationAddress: string;
  inspectionLocationCity: string;
  inspectionLocationZip: string;
  /**
   * Indicating to which bank account the BuyerUser has to transfer the auction volume to.
   * @deprecated
   */
  sellerIban: string;
  /**
   * URL to the seller invoice for the given auction, manually uploaded by the SellerUser, after
   * the auction has ended successfully.
   */
  urlToInvoice: string;
  /** URL for the cancelled invoice in case it was cancelled. */
  urlToCorrectionInvoice: string;
  /**
   * Flag indicating whether an auction has an "hot bid" phase in the last 20 seconds before expiration.
   * @deprecated
   */
  hotBid: boolean;
  /**
   * Seller's instant purchase price for the vehicle.
   * For the first 24 hours after the auction is started, the vehicle will only be available in "instant purchase"
   * mode (bidding is not allowed in that time, only purchasing right away).
   *
   * After 24 hours, if nobody purchased the vehicle, it is going on auction, just like all other auctions.
   * @format double
   */
  instantPurchasePrice: number;
  /** @format double */
  instantPurchasePriceNet: number;
  /**
   * Flag indicating that instant purchase is enabled for that auction.
   * (Implies that "instantPurchasePrice" is set)
   */
  allowInstantPurchase: boolean;
  /**
   * Flag indicating that an auction "ended" with an instant purchase, resp.
   * did never go on auction.
   */
  didEndWithInstantPurchase: boolean;
  /**
   * Internal counter, incrementing on every "restart" of an auction.
   * Starts with 0.
   *
   * e.g. count of 2 means that the auction has been restarted 2 times, leading to 3 auction objects in the
   * database.
   * @format double
   */
  auctioningIterations: number;
  /**
   * Indicating the strategic priority of an auction for CarOnSale.de
   * The higher the number, the more "relevant" the auction is.
   * @format double
   */
  priority: number;
  /** @deprecated */
  advertisementHtmlContent: string;
  /** @deprecated */
  buyerComplaint: EAuctionBuyerComplaint;
  /**
   * Custom field that is not used internally.
   * Allows sellers to store e.g. a reference ID to their own vehicle management system.
   */
  internalReferenceText: string;
  _fk_uuid_vehicle: string;
  _fk_uuid_sellerUser: string;
  /** This is a URL that redirects to an external invoice payment site (such as provided by Stripe). */
  urlToPaymentSite: string;
  /**
   * A flag indicating if an auction needs to be reviewed by an internal review agent (for quality assurance)
   * If true, an auction is currently under review by CarOnSale, and cannot be edited by the owning seller user.
   */
  needsReview: boolean;
  /** The reasons why an auction was rejected during the review. */
  reviewReasons: EAuctionReviewReason[];
  /**
   * Reasons why an auction is rejected during the review process ("Parkhaus").
   *
   * This reason is automatically set to UNKNOWN for all auctions and is then manually adjusted by
   * an internal review agent (in case the auction gets rejected) before it is sent back to the responsible
   * SellerUser.
   * @deprecated
   */
  reviewReason: EAuctionReviewReason;
  /** Specifies reason when "reviewReasons" contains EAuctionReviewReason.OTHER. */
  reviewReasonDetails: string;
  /** The comment given by an internal review agent on rejecting an auction under review. */
  reviewComment: string;
  /** Flag indicating if the auction can be published without review. */
  canSkipReview?: boolean;
  /**
   * Random PIN code that is generated when an auction is ready to be picked up or in advance before COSCheck inspection.
   * This PIN code is printed out on the hangar in the vehicle or handed to the seller of the auction, which can then forward it to
   * the buyer to confirm the pickup.
   */
  pickupPinCode: string;
  /**
   * Random UUID that is used to confirm vehicle pickup via scanning QR code.
   * It will be printed on the hangar or in the pickup document available for sellers.
   */
  pickupPinUuid: string;
  /**
   * Timestamp when PIN code was entered by the buyer.
   * Allows to determine how many auctions were self-picked up.
   * @format date-time
   */
  pickupPinCodeEnteredAt: string;
  /**
   * Timestamp when PIN UUID was entered by the buyer via scanning QR code.
   * Allows to determine how many auctions were self-picked up.
   * @format date-time
   */
  pickupPinUuidEnteredAt: string;
  /** Flag is indicating if pickup was confirmed with PIN using new process (via pin.caronsale.de) */
  pickupConfirmedWithNewPin: boolean;
  /** URL to the pickup information document for the BuyerUser. */
  urlToPickupBuyerDocument: string;
  /** URL to the pickup information document for the SellerUser (containing the "pickupPinCode"). */
  urlToPickupSellerDocument: string;
  /** URL to the success report document for the SellerUser. */
  urlToSellerSuccessReport: string;
  /** The payment process that is used to pay money for the purchase volume (auction volume). */
  paymentProcess: EAuctionPaymentProcess;
  /**
   * Referencing the bank account to which the payment for the purchase volume should be paid out.
   * This is null, if the paymentProcess === "CLASSIC".
   *
   * It is only used, when external payment (COS Payment) is enabled.
   */
  _fk_uuid_associatedBankAccount: string;
  /**
   * Indicates how often an auction has been reviewed.
   * This is incremented every time an auction is rejected after a review.
   * @format double
   */
  draftReviewIterations: number;
  /**
   * Timestamp when an admin resets a time window for a possibility to complain
   * @format date-time
   */
  complaintTimeWindowExtendedAt: string;
  /**
   * Flag indicates if this auction is suspected to have been paid in cash (only for auctions that use the external
   * invoice process), this means that no corresponding transfer exists on Stripe and the buyer confirmed the pickup
   * through the seller pin code.
   */
  isSuspectedCashPayment: boolean;
  /**
   * Timestamp when the auction was sent to review.
   * @format date-time
   */
  reviewStartedAt: string;
  /**
   * Timestamp when the auction QA process actually started (i.e. when Parkhaus started reviewing the auction).
   * @format date-time
   */
  qaStartedAt: string;
  /**
   * Timestamp when the auction was ready to be reinserted (a.k.a. restarted).
   * @format date-time
   */
  readyToBeReinsertedSince: string;
  /** The reference to the original auction from which current one was cloned. */
  _fk_uuid_clonedFrom: string;
  /** Auction type. */
  type: EAuctionType;
  /** Indicates whether the rejection wait period for this auction is over and thus can be rejected by the seller. */
  isRejectionWaitPeriodOver: boolean;
  /** Reference to invoice object for the external Billomat invoicing provider. */
  thirdPartyVehiclePurchaseInvoiceReference: string;
  /**
   * URL to the seller made invoice invoicing CoS for the given auction, manually uploaded to the crm by an internal user, after
   * the auction has ended successfully.
   */
  sellerToCosInvoiceUrl: string;
  /**
   * Timestamp for when the sellerToCosInvoiceUrl was last updated.
   * @format date-time
   */
  sellerToCosInvoiceUrlLastUpdatedAt: string;
  /**
   * Timestamp for when the sellerToCosInvoiceUrl is first uploaded.
   * @format date-time
   */
  sellerToCosInvoiceUrlFirstUploadedAt: string;
  /** Flag indicating wherever the uploaded seller to cos invoice was verified by an internal user. */
  sellerToCosInvoiceIsValid: boolean;
  /** The comment given by an internal user on the provided seller to cos invoice. */
  sellerToCosInvoiceComment: string;
  /** Shows if auction is marked as 'live', so, it will be slotted during starting and expire in less than 24 hours. */
  isLive: boolean;
  /** Shows if auction was already slotted via the slotting algorithm. */
  isSlotted: boolean;
  /**
   * The exact date+time when the auction was slotted to end.
   * @format date-time
   */
  slottedEndingTime: string;
  /** Indicating if transportation should be disabled manually. */
  isTransportationDisabledManually: boolean;
  /** Optional pickup instructions that will be communicated to the buyer. */
  pickupInstructions: string;
  /** Indicating wherever the seller should be charged a success/listing fee. */
  preventSellerFactoring: boolean;
  /** Indicating if buyer success fee should be applied or not. */
  applyBuyerSuccessFee: boolean;
  /** Info from document shipping service about the order, like status and links to the documents. */
  documentShippingOrder: IAuctionDocumentShippingOrder;
  /** Info about un-/confirmed auction data points. */
  fieldsConfirmationStatus: IAuctionFieldConfirmationStatusMap;
  /**
   * Whether the "Value Added Tax is reportable", a special tax condition in the EU.
   * If this is true, it means that the vehicle is "Regelbesteuert", meaning that the invoice for the vehicle
   * (either invoiced directly from seller to buyer - or from COS Payment to buyer) needs to include
   * the VAT (in Germany e.g. 19%) in the invoice.
   *
   * If this is false, the invoice does not need to have explicit VAT.
   */
  isVATReportable: boolean;
  /** Indicates how the auction was uploaded to the marketplace. */
  uploadMethod: EAuctionUploadMethod;
  /** Prebooked services available for the auction, like transport, warranty. */
  prebookedServices: IPrebookedService[];
  /** Indicates if cosCheckPlus guarantee can be booked for this auction. */
  isCosCheckPlusGuaranteeEnabled: boolean;
  /** Indicates if extended guarantee can be booked for this auction. */
  isExtendedGuaranteeEnabled: boolean;
  /** URL to the auction vehicle cover letter for sellers */
  urlToCoverLetter: string;
  /**
   * Timestamp when the automatic pickup approval was requested for auction
   * @format date-time
   */
  autoPickupOnPaymentRequestedAt: string;
  /**
   * This means that the auctions is using complimentary vehicle feature.
   *
   * Note: This is marked as true when the seller uses one of his complimentary vehicles to pay for the fees.
   */
  isSellerFeeComplimentary: boolean;
  /** Flag when standing fee is enabled to the auction and we'll need to extra charge a buyer as a penalty. */
  isStandingFeeEnabled: boolean;
  _fk_uuid_auctionRoom: string;
  /**
   * An auction can have a list of fulfillment criteria that impact how the
   * auction will be fulfilled depending on the buyer that wins it.
   */
  fulfillmentCriteria?: IAuctionFulfillmentCriteria[];
  /**
   * The auction marked with this flag won't start automatically after review.
   *
   * Instead, it will transition to state: READY_TO_BE_PUBLISHED.
   *
   * Default: false
   */
  shouldWaitToBePublishedAfterReview: boolean;
  /** A flag that marks if an auction is a selective ware or not. */
  isSelectiveWare: boolean;
  /** The id for the look and feel applied to the auction. */
  lookAndFeelId: EAuctionLookAndFeelId;
  /** A flag that marks if an auction will use the pickup qr code. */
  isQRCodeAvailable: boolean;
  /**
   * Indicates whether the highest bid should be transferred if this auction gets cloned.
   * Defaults to `false` if not specified.
   */
  shouldTransferHighestBid?: boolean;
  /**
   * Indicates the amount of VAT tax that applies to the auction at current highest bid.
   * @format double
   */
  vatAmount: number;
  /**
   * VAT rate that applies to this auction.
   * @format double
   */
  vatRate: number;
  /** The rating of the seller to the buyer of the auction. */
  rating: IRating;
  /** Contact to the buyer of the auction. */
  buyerContact: IGeneralUser;
  /**
   * The transportation task associated with the auction.
   * Null if no transportation is booked.
   */
  transportationTask: ITransportationTask;
  /** Payment state of the auction. */
  paymentState: IAuctionPaymentState;
  /**
   * The bank account to which the payment should be paid out.
   *
   * "null", if payment process is classic.
   */
  targetSellerBankAccount: ISellerBankAccount;
  /**
   * Transporter company name
   * Null if no transportation is booked
   */
  transportationProviderCompanyName: string;
  /**
   * Number of times the auction has been parked by buyers.
   * @format double
   */
  timesParked: number;
  /**
   * Number of unique bidders.
   * @format double
   */
  uniqueBidders: number;
  /**
   * Number of views per auction.
   * @format double
   */
  views: number;
}

export enum EAnnotatedItemType {
  AUCTION = 'AUCTION',
}

export interface IUserNote {
  uuid: string;
  _fk_uuid_user: string;
  _fk_uuid_annotated_item: string;
  annotated_item_type: EAnnotatedItemType;
  content: string;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  modifiedAt: string;
}

export interface IUserNoteCreationParams {
  content: string;
}

/** From T, pick a set of properties whose keys are in the union K */
export interface PickIGeneralUserUuidOrFirstNameOrLastNameOrMailAddress {
  uuid: string;
  /** The first name of the user */
  firstName: string;
  /** The last name of the user */
  lastName: string;
  /** The mail address of the user */
  mailAddress: string;
}

/** Represents the basic information of a general user. */
export type IGeneralUserBasicInfo = PickIGeneralUserUuidOrFirstNameOrLastNameOrMailAddress;

/** Represents an account with assigned internal users. */
export type IAccountWithAssignedInternalUsers = IAccount & {
  /** The second assigned internal user. */
  assignedInternalUser2?: IGeneralUserBasicInfo;
  /** The first assigned internal user. */
  assignedInternalUser1?: IGeneralUserBasicInfo;
};

/**
 * Types of Stripe connected account business types.
 * @deprecated
 */
export enum ELegalBusinessType {
  Company = 'company',
  Individual = 'individual',
  NonProfit = 'non_profit',
}

/**
 * A seller account is a legal entity, it can be a dealership or any other legal entity that owns
 * dealerships. In order to mimic the structure of big dealership groups, a seller account can own
 * another seller account (1 <-> N).
 * @deprecated
 */
export interface ISellerAccount {
  /**
   * @deprecated
   * @format double
   */
  id: number;
  uuid: string;
  /**
   * Indicating the time when the entity has been created, i.e. when the entity was
   * persisted to the database.
   * @format date-time
   */
  createdAt?: string;
  /**
   * Indicating the last time a value of the entity has been changed (updated).
   * @format date-time
   */
  updatedAt?: string;
  /**
   * Indicating the time when the entity has been (softly) deleted.
   * "null" if entry is not deleted.
   * @format date-time
   */
  deletedAt?: string;
  addressLine?: string;
  zipCode?: string;
  city: string;
  countryCode: ECountryCode;
  /** Add extra comments do the location (e.g., pick-up instruction in transportation tasks). */
  comment?: string;
  /** Language of the contact person at the location. */
  contactLanguage?: string;
  /** Current version vector */
  versionVector: number[];
  /** Indicates this update comes from a remote system */
  isRemoteUpdate: boolean;
  /** The name (i.e. the name of the legal entity). */
  name: string;
  /** Url to the homepage of the legal entity. */
  urlToHomePage: string;
  /** Url to the image of the legal entity. */
  urlToImage: string;
  /** Contact phone number for the legal entity. */
  phoneNumber: string;
  /** Value Added Tax Id of the legal entity. */
  vatId: string;
  /** Reference to the vat id object from the external payment provider Stripe. */
  vatIdExternalReference: string;
  /**
   * Reference to the direct debit handle (e.g. the Stripe source for direct debit) for this particular
   * seller account.
   *
   * If this is null, it means that no direct debit handle (i.e. source) has been created and therefore the seller
   * has not yet approved the direct debit mandate (SEPA mandate).
   */
  externalDirectDebitReference: string;
  /**
   * Indicating when sellers are invoiced seller fees.
   * (see ESellerFeeInvoicingMode)
   *
   * The default mode to invoice the (seller) fee is
   *
   * Default is NONE.
   */
  feeInvoicingMode: ESellerFeeInvoicingMode;
  /**
   * The success fee (in EUR by default) that is charged for a seller per successfully transacted vehicle.
   *
   * This is only relevant when the "feeInvoicingMode" = ESellerFeeInvoicingMode.INVOICE_ON_SUCCESS
   *
   * Default fee is 0.
   * @format double
   */
  successFeePerVehicle: number;
  /**
   * The listing fee (in EUR by default) that is charged for a seller per (initially) listed vehicle.
   *
   * This is only relevant when the "feeInvoicingMode" = ESellerFeeInvoicingMode.INVOICE_ON_LISTING
   *
   * Default fee is 0
   * @format double
   */
  listingFeePerVehicle: number;
  /**
   * This flag indicates whether a seller "success" fee (see ESellerFeeInvoicingMode) should be charged when a
   * sale is made to a regular buyer. Some sellers have special contract conditions, where "regular buyer discounts"
   * are also not charged on the seller side.
   *
   * This is only relevant when the "feeInvoicingMode" = ESellerFeeInvoicingMode.INVOICE_ON_SUCCESS
   *
   * Default is false.
   *
   * TODO: This value is not yet used/relevant for the system.
   */
  shouldNotInvoiceSuccessFeeForRegularBuyers: boolean;
  /** Indicates how auction is invoiced to the seller and buyer. */
  purchaseInvoicingMode: ESellerPurchaseInvoicingMode;
  /**
   * Timestamp the purchase invoicing mode was last updated at.
   * @format date-time
   */
  purchaseInvoicingModeUpdatedAt: string;
  /** Tax Id of the legal entity (different from the Vat Id). */
  taxId: string;
  /**
   * Indicates whether the seller account is an independent legal entity (i.e. a business).
   * Most SellerAccounts are legal entities, but in some cases, e.g. a subsidiary (location) of a business
   * is also modelled as a SellerAccount, even though it is not an independent business/legal entity.
   *
   * This information is relevant for COSPay, so COSPay knows that it has to check for parent accounts, when
   * checking if a SellerUser belongs to a KYC-checked SellerAccount (only legal entities are KYC-checked).
   */
  isLegalEntity: boolean;
  /** Email address that will be used to invoice the legal entity. */
  invoicingMailAddress: string;
  /**
   * Flag that indicates if this account is activated to use an external payment service (i.e. CarOnPay)
   * to facilitate payments.
   */
  isExternalPaymentEnabled: boolean;
  /**
   * References to external payment provider (such as Stripe).
   * Could contain IDs for invoice objects in the third-party system.
   */
  externalPaymentSellerId: string;
  externalPaymentId: string;
  /** First name of the/an owner (CEO for example) of the legal entity. */
  ownerFirstName: string;
  /** Last name of the owner/an (CEO for example) of the legal entity. */
  ownerLastName: string;
  /**
   * Date of birth of the owner of the legal entity.
   * @deprecated
   * @format date-time
   */
  ownerBirthDate: string;
  /**
   * Day of birth of the owner of the legal entity.
   * @format double
   */
  ownerBirthDay: number;
  /**
   * Month of birth of the owner of the legal entity.
   * @format double
   */
  ownerBirthMonth: number;
  /**
   * Year of birth of the owner of the legal entity.
   * @format double
   */
  ownerBirthYear: number;
  /** Reference to external payment provider (such as Stripe) for the owner. */
  ownerExternalReference: string;
  /** Email address of the owner of the legal entity. */
  ownerEmail: string;
  /** Phone number of the owner of the legal entity. */
  ownerPhoneNumber: string;
  /**
   * URL's to uploaded versions of identity verification documents for the owner of the legal entity
   * that can be used for the KYC (Know Your Customer) process for external payment service (such as CarOnPay).
   */
  ownerUrlToIdDocumentFront: string;
  ownerUrlToIdDocumentBack: string;
  ownerUrlToAdditionalDocument: string;
  /**
   * References to the owners of the legal entity's identity verification document used in the KYC process
   * for external payment providers (such as Stripe).
   */
  ownerIdDocumentFrontExternalReference: string;
  ownerIdDocumentBackExternalReference: string;
  ownerAdditionalDocumentExternalReference: string;
  /**
   * URL to an uploaded version of a tax registration document, that is legally required in the EU. This
   * document has to be provided or retrieved from the seller themselves.
   */
  urlToTaxRegistrationDocument: string;
  /** Reference to a tax registration document for an external payment provider (such as Stripe). */
  taxRegistrationDocumentExternalReference: string;
  /**
   * Start and end of the validity of an tax registration document, that is legally required in the EU.
   * @format date-time
   */
  taxRegistrationDocumentValidFrom: string;
  /** @format date-time */
  taxRegistrationDocumentValidUntil: string;
  /** German tax registration number. */
  taxRegistrationNumber: string;
  /**
   * URL to an uploaded version of a tax registration document, that is legally required in the germany §22. This
   * document has to be provided or retrieved from the seller themselves.
   */
  urlToTaxCertificateDocument: string;
  /** Legal business type of the legal entity for external payment service (such as CarOnPay). */
  legalBusinessType: ELegalBusinessType;
  /** Is true when the seller account has been kyc checked by the payment service provider. */
  payoutsEnabled: boolean;
  /** Reference to the parent seller account. */
  _fk_uuid_parentAccount: string;
  /** Reference to responsible field sales manager user. */
  _fk_uuid_internalUser: string;
  /** Reference to responsible inside sales manager user. */
  _fk_uuid_internalUserInsideSales: string;
  /** Is true when information is under review by the payment service provider. */
  kycCheckPending: boolean;
  /**
   * Is true when information that was provided to the payment service provider is incomplete.
   * (meaning one or more fields are currently due).
   */
  kycCheckIncomplete: boolean;
  /**
   * Flag indicating if pickups should be immediately
   * possible on money coming to COSPay.
   *
   * When this is set to "true", auctions in "CLOSED_WAITING_FOR_PAYMENT"
   * will be automatically set to "CLOSED_WAITING_FOR_PICKUP" as soon as
   * an incoming payment is detected by COSPay.
   */
  autoPickupOnPaymentReceivedByCos: boolean;
  /**
   * Flag indicating if manual creation of auctions are allowed for the seller users
   * being owned by the given seller account.
   */
  isManualAuctionCreationForbidden: boolean;
  /**
   * Timestamp indicating when the kyc checked was approved by Stripe (payoutsEnabled = true). Should be null if
   * not kyc checked.
   * @format date-time
   */
  kycCheckedAt: string;
  /**
   * Timestamp indicating when the tax certificate document was uploaded. Should be null if not present.
   * @format date-time
   */
  taxCertificateUploadedAt: string;
  /**
   * Sometimes the payment provider requests additional requirements after a connected account passes a certain volume,
   * When this happens a deadline is defined, if the requested information is not collected or updated until that date,
   * payouts will be disabled.
   * @format date-time
   */
  kycExpirationDate: string;
  /** Controls whether the automatic pickup approval is enabled (default = true). */
  autoPickupOnPaymentReceivedBySeller: boolean;
  /** Flag indicating if the seller account is strategic partner or not. */
  isStrategicPartner: boolean;
  /**
   * Flag indicating if payouts are to be blocked to this seller. When the flag indicates true no payouts
   * to the seller will be processed.
   */
  blockPayouts: boolean;
  /**
   * Flag indicating if a separate address (from the main seller account addressed used for the kyc check)
   * should be used for billing.
   */
  useSeparateBillingInfo: boolean;
  /** Name used for billing. */
  billingName: string;
  /** Vat id used for billing. */
  billingVatId: string;
  /** Flag indicating if seller's net auctions can be sold to foreign buyers. */
  allowCrossBorderNetSale: boolean;
  /**
   * Regular buyer discount rate from 0 to 1 (default is 1, a full discount on the commission).
   * @format double
   */
  regularBuyerDiscountRate: number;
  /** A flag indicating if any vehicles owned by members of this seller account should have their minimum required asks displayed to buyers. */
  revealMinAskOnAuctions: boolean;
  /** Stores the auction schedule configuration. */
  scheduleConfig: ISlottingSchedule;
  /**
   * A fee that should be charged on top of the buyer purchase fee invoice.
   * This is used to charge the buy-side for fees that we would usually charge on the sell-side.
   * In some cases, this is not possible, so we need to charge it on the buy-side.
   * @format double
   */
  buyerSuccessFeePerVehicleNet: number;
  /**
   * Flag indicating wherever the flash sale process should be used for all auctions created by subordinate
   * seller users for this seller account.
   * @deprecated
   */
  useFlashSaleProcess: boolean;
  /** Flag indicating wherever the document shipping process have to be activated for certain kind of user. */
  isDocumentShippingEnabled: boolean;
  /** Flag indicating wherever the document shipping process have to be activated for certain kind of user and your regular buyers. */
  isDocumentShippingEnabledForRegularBuyers: boolean;
  /** Flag which indicates wherever standings costs can be applied to buyers of auctions by this seller. */
  shouldApplyStandingCosts: boolean;
  /** Optional first name of the person that should receive a copy of all logistic-related communication. */
  logisticsContactFirstName: string;
  /** Optional last name of the person that should receive a copy of all logistic-related communication. */
  logisticsContactLastName: string;
  /** Optional business name of the person that should receive a copy of all logistic-related communication. */
  logisticsContactBusinessName: string;
  /** Optional phone number of the person that should receive a copy of all logistic-related communication. */
  logisticsContactPhoneNumber: string;
  /** Optional email address of the person that should receive a copy of all logistic-related communication. */
  logisticsContactMailAddress: string;
  /** Optional email addresses of the people that should receive a copy of all logistic-related communication separated by comma ','. */
  logisticsContactCcMail: string;
  /** Optional pickup instructions that will be communicated to the buyer. */
  pickupInstructions: string;
  /**
   * A flag indicating whether transportation is enforced for auctions owned by this SellerUser.
   *
   * This flag will be moved to the SellerAccount eventually, making this field deprecated.
   */
  enforceTransportation: boolean;
  /**
   * Was a CosBox (locker that Cos provides which contains documents and keys for vehicles ready to pick-up)
   * deployed to this seller.
   */
  isCosBoxDeployed: boolean;
  /**
   * The business type of the SellerUser.
   * Most SellerUsers are "LICENSED" sellers.
   */
  dealershipType: ESellerBusinessType;
  /** Flag being true indicates that the payout is only enabled when the Seller to COS invoice has been received */
  conditionalPayoutsEnabled: boolean;
  /** Flag which indicates if a vehicle is handled via logistics compound */
  isCompoundEnabled: boolean;
  /** Flag which indicates if a CRM user enabled this account to participate in auction rooms */
  auctionRoomsEnabled: boolean;
}

export interface IAuctionMemo {
  /**
   * @deprecated
   * @format double
   */
  id: number;
  uuid: string;
  /**
   * Indicating the time when the entity has been created, i.e. when the entity was
   * persisted to the database.
   * @format date-time
   */
  createdAt?: string;
  /**
   * Indicating the last time a value of the entity has been changed (updated).
   * @format date-time
   */
  updatedAt?: string;
  /**
   * Indicating the time when the entity has been (softly) deleted.
   * "null" if entry is not deleted.
   * @format date-time
   */
  deletedAt?: string;
  _fk_uuid_creator: string;
  content: string;
  /**
   * This is the new user structure, meant to replace our old fragmented user structure with Sellers, Buyers and etc.
   * A user is someone that is connected to a company and uses our platform for an activity enabled for that account.
   * As we're a B2B platform, our focus is always the account/business, the user is just someone acting in the name of an account.
   */
  associatedCreator: IGeneralUser;
  _fk_uuid_auction: string;
  urlToImage1: string;
  urlToImage2: string;
  urlToImage3: string;
}

export interface IVehicleConfiguration {
  make: IVehicleMake;
  baseModel: IVehicleBaseModel;
  subModel: IVehicleSubModel;
  bodyOption: IVehicleBodyOption;
  engineOption: IVehicleEngineOption;
  gearingOption: IVehicleGearingOption;
  equipmentLineOption: IVehicleGearingOption;
  typeOfDriveOption: IVehicleTypeOfDriveOption;
  wheelBaseOption: IVehicleWheelBaseOption;
  /** @format double */
  mileageInKm: number;
  ez: string;
  fuelType: EFuelType;
  /** @format double */
  enginePowerInHp?: number;
  vehicleType?: EVehicleType;
}

/** Enum that has all the creation origins for the MPR (or TradeInValuationRequest) */
export enum EMarketPriceRequestOrigin {
  SELLER = 'SELLER',
  AUCTION = 'AUCTION',
  INSPECTION = 'INSPECTION',
  GPR = 'GPR',
}

/** Also referred to as a Market Price Request. */
export interface ITradeInValuationRequest {
  /**
   * @deprecated
   * @format double
   */
  id: number;
  uuid: string;
  /**
   * Indicating the time when the entity has been created, i.e. when the entity was
   * persisted to the database.
   * @format date-time
   */
  createdAt?: string;
  /**
   * Indicating the last time a value of the entity has been changed (updated).
   * @format date-time
   */
  updatedAt?: string;
  /**
   * Indicating the time when the entity has been (softly) deleted.
   * "null" if entry is not deleted.
   * @format date-time
   */
  deletedAt?: string;
  /** @format double */
  maxPrice: number;
  /** @format double */
  minPrice: number;
  /** @format date-time */
  requestedAt: string;
  /** @format date-time */
  valuatedAt: string;
  serializedVehicleConfiguration: string;
  vehicleConfiguration: IVehicleConfiguration;
  comment: string;
  _fk_uuid_sellerUser: string;
  vin: string;
  /** @format double */
  datPrice: number;
  /** @format double */
  b2bPrice: number;
  /** @format double */
  b2bMinPrice: number;
  /** @format double */
  b2bMaxPrice: number;
  /** @format double */
  b2cPrice: number;
  /** @format double */
  b2cMinPrice: number;
  /** @format double */
  b2cMaxPrice: number;
  highUncertainty: boolean;
  /**
   * Denotes if the customer who requested the trade in valuation (market price request) felt that it was useful to them.
   * Will be unset (null/undefined) if no feedback has been given and either false or true otherwise depending on if the
   * feedback was negative or positive respectively.
   */
  positiveCustomerFeedback: boolean;
  /**
   * A flag indicating if and when the customer requested an inspector from the MPR UI.
   * If this flag is populated, that means we already sent an internal email communicating the request.
   * @format date-time
   */
  inspectorRequestedAt: string;
  /** The creation origin of the MPR (or TradeInValuationRequest) */
  origin: EMarketPriceRequestOrigin;
  /**
   * The UUID of any auction which is created after this MPR was made.
   * This relationship is used for seeing how many auctions are getting created as a result or partial result of an MPR having been executed.
   */
  _fk_uuid_auction: string;
  /** The UUID of the Guaranteed Price Request that is associated with this MPR, if it exists. */
  _fk_uuid_guaranteedPriceRequest: string;
  /**
   * The UUID of the request to the pricing service.
   * This is a unique identifier which can tie together a given MPR object
   * with the request sent to the pricing service to populate it with data.
   * This is often useful for forensics/troubleshooting.
   */
  estimationRequestUuid?: string;
  /**
   * Fields that serve to improve the inspection process after request for the internal users,
   * a seller user should be able to select date of inspection and the location of the vehicle.
   * Some sellers also do have regular appointments, in this case they do not have to select a date.
   * @format double
   */
  minimumRequiredAsk?: number;
  /** @format date-time */
  appointmentDate?: string;
  selectedLocation?: string;
}

/** Field data relating to an auctions renegotiation state and the renegotiation process in general. */
export interface IRenegotiation {
  /** @format double */
  counterOfferByBuyer: number;
  /** @format double */
  counterOfferByBuyerNet: number;
  /** @format double */
  lastOfferBySeller: number;
  /** @format double */
  lastOfferBySellerNet: number;
  uuid: string;
  /**
   * Indicating the time when the entity has been created, i.e. when the entity was
   * persisted to the database.
   * @format date-time
   */
  createdAt?: string;
  /**
   * Indicating the last time a value of the entity has been changed (updated).
   * @format date-time
   */
  updatedAt?: string;
  /**
   * Indicating the time when the entity has been (softly) deleted.
   * "null" if entry is not deleted.
   * @format date-time
   */
  deletedAt?: string;
  /**
   * A value denoting the previous renegotiation offer given by a buyer user.
   * @format double
   */
  previousCounterOfferByBuyer: number;
  /** @format double */
  previousCounterOfferByBuyerNet: number;
  /**
   * A value denoting the previous renegotiation offer given by a seller user.
   * This is required in order to properly display the right information during the renegotiation process.
   * @format double
   */
  previousLastOfferBySeller: number;
  /** @format double */
  previousLastOfferBySellerNet: number;
  /** A flag for if the seller user connected to the auction has decided not to participate in the renegotiation any further. */
  renegotiationStoppedBySeller: boolean;
  /** A flag for if the highest bidding buyer user connected to the auction has decided not to participate in the renegotiation any further. */
  renegotiationStoppedByBuyer: boolean;
  /** A fag indicating if the buyer's withdrawal from the renegotiation process was done automatically or not. */
  wasRenegotiationStoppageByBuyerAutomatic: boolean;
  /** A fag indicating if the seller's withdrawal from the renegotiation process was done automatically or not. */
  wasRenegotiationStoppageBySellerAutomatic: boolean;
  /**
   * The total number of offer rounds a buyer has participated in for an auction during the renegotiation phase.
   * @format double
   */
  numBuyerRenegotiationOfferRounds: number;
  /**
   * The total number of offer rounds a seller has participated in for an auction during the renegotiation phase.
   * @format double
   */
  numSellerRenegotiationOfferRounds: number;
  /**
   * The calculated renegotiation midpoint value of the auction.
   * This is used once the renegotiation offers get too close for the offer process to continue.
   * This value will be undefined unless the auction is ready to be purchased for such a value.
   * @format double
   */
  renegotiationMidpointValue: number;
  /** @format double */
  renegotiationMidpointValueNet: number;
  /**
   * Indicates that the highest bidding buyer user for an auction in renegotiation is willing to accept the midpoint of their offer and
   * the offer of the seller for the same auction. This is used when the renegotiation offers get very close during the renegotiation process.
   */
  buyerAcceptedRenegotiationMidpoint: boolean;
  /**
   * Indicates that the seller of an auction is willing to accept the midpoint of their offer and the offer of the highest bidding
   * buyer user for the same auction. This is used when the renegotiation offers get very close during the renegotiation process.
   */
  sellerAcceptedRenegotiationMidpoint: boolean;
  /**
   * This is the datetime object denoting when the most recent renegotiation round concluded at.
   * @format date-time
   */
  lastRenegotiationRoundEndedAt: string;
  /**
   * The number of seconds until the current renegotiation round times out.
   * @format double
   */
  numSecondsUntilRenegotiationTimeout: number;
  /**
   * The UUID of the external case tracking the renegotiation of the auction.
   * We track this to enable case updates as the auction moves through renegotiation.
   */
  externalRenegotiationTicketUuid: string;
  /**
   * The calculated renegotiation fair value of the auction.
   * This is used once the renegotiation starts based on the auction GPR or MPR so we know
   * the fair value that both seller and buyer should agree upon.
   * @format double
   */
  renegotiationFairValue: number;
  /**
   * Value that is indicating the highest bid value on the auction when it ended.
   * This value should not change for a given auction since it supposed to be a historical record.
   * @format double
   */
  highestBidValueAtEndingTime: number;
  /** @format double */
  highestBidValueAtEndingTimeNet: number;
  /** Field data related to the renegotiation round process. */
  renegotiationRoundData?: IRenegotiationRoundData;
  /**
   * Indicates when manual renegotiation was finished via Salesforce.
   * @format date-time
   */
  finishedManuallyAt?: string;
}

/** State of a Buy Now Pay Later order. */
export enum EBuyNowPayLaterOrderStatus {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  DECLINED = 'DECLINED',
  COMPLETE = 'COMPLETE',
}

/** Reason for the decline of a Buy Now Pay Later order. */
export enum EBuyNowPayLaterDeclineReason {
  RISK_POLICY = 'RISK_POLICY',
  DEBTOR_NOT_IDENTIFIED = 'DEBTOR_NOT_IDENTIFIED',
  DEBTOR_ADDRESS = 'DEBTOR_ADDRESS',
  RISK_SCORING_FAILED = 'RISK_SCORING_FAILED',
  DEBTOR_LIMIT_EXCEEDED = 'DEBTOR_LIMIT_EXCEEDED',
}

export interface IBuyNowPayLaterOrderState {
  /** The status for the order state */
  status: EBuyNowPayLaterOrderStatus;
  /** An description about the state */
  description: string;
  /** The decline reason if the state is EBuyNowPayLaterOrderStatus.DECLINED */
  declineReason?: EBuyNowPayLaterDeclineReason;
  /**
   * The creation date of the state
   * @format date-time
   */
  createdAt: string;
}

/** Represents the amount details in a BuyNowPayLater billing data. */
export interface IBuyNowPayLaterAmount {
  /**
   * The net amount.
   * @format double
   */
  net: number;
  /**
   * The gross amount.
   * @format double
   */
  gross: number;
  /**
   * The tax amount.
   * @format double
   */
  tax: number;
}

/** Auctions as viewed by internal users. */
export interface IInternalAuctionView {
  /**
   * @deprecated
   * @format double
   */
  id: number;
  uuid: string;
  /**
   * Indicating the time when the entity has been created, i.e. when the entity was
   * persisted to the database.
   * @format date-time
   */
  createdAt?: string;
  /**
   * Indicating the last time a value of the entity has been changed (updated).
   * @format date-time
   */
  updatedAt?: string;
  /**
   * Indicating the time when the entity has been (softly) deleted.
   * "null" if entry is not deleted.
   * @format date-time
   */
  deletedAt?: string;
  /** Stores which type of additional tax applies to the auction if any. */
  additionalTaxType: EAdditionalTaxType;
  /**
   * Stores the value of the additional tax that might apply to an auction.
   * @format double
   */
  additionalTaxValue: number;
  /**
   * Stores the export discount for taxes.
   * @format double
   */
  additionalTaxExportDiscount: number;
  /**
   * The state of the auction, indicating whether an auction is not yet published (DRAFTED), running (ACTIVE),
   * closed successfully (CLOSED_WAITING_FOR_PAYMENT, CLOSED_WAITING_FOR_PICKUP, FINISHED), failed (CLOSED_NO_BIDS,
   * CLOSED_UNDER_MINIMUM_ASK) or disabled (DISABLED).
   *
   * Depending on the state, different timestamps are available per auction.
   */
  state: EAuctionState;
  /**
   * The exact date+time when the auction is expiring/ending.
   * @format date-time
   */
  endingTime: string;
  /**
   * The remaining number of seconds that this auction will be in the state ACTIVE (running).
   *
   * This is a dynamic, non-persisted field, calculated on every retrieval of the auction data.
   * @format double
   */
  remainingTimeInSeconds: number;
  /**
   * The remaining number of seconds that this auction is available for instant purchase
   * (usually the first 24 hours after the auction is started).
   *
   * This is a dynamic, non-persisted field, calculated on every retrieval of the auction data.
   * @format double
   */
  remainingTimeForInstantPurchaseInSeconds: number;
  /**
   * Date+time indicating until when an auction is available for instant purchasing.
   * By default, this is 24 hours after the start of an auction.
   * @format date-time
   */
  instantPurchasePossibleUntil: string;
  _fk_uuid_highestBiddingBuyerUser: string;
  /**
   * Value that is indicating the current highest bid value on the auction.
   * This is of course a dynamic field, that will be incremented on every new bid placed on the auction.
   * @format double
   */
  currentHighestBidValue: number;
  /** @format double */
  currentHighestBidValueNet: number;
  /**
   * The minimal price the seller user wants to reach for this specific auction.
   * If an auction ends above that price, the auction is successful right away
   * (switching to state CLOSED_WAITING_FOR_PAYMENT).
   *
   * If the auction ends below the minimum required ask, the auction will switch to state CLOSED_UNDER_MINIMUM_ASK,
   * which then requires the seller's explicit "confirmation" or "rejection" of the highest bid value.
   * @format double
   */
  minimumRequiredAsk: number;
  /** @format double */
  minimumRequiredAskNet: number;
  /**
   * Number of bids placed on an auction.
   * @format double
   */
  numBids: number;
  /** @format double */
  buyerRecommendationScore?: number;
  /** Was this auction created with the help of an inspection done or commissioned by CarOnSale? */
  inspectionWasCommissionedByCos: boolean;
  /** By which type of user was this auction created. */
  creatorType: EAuctionCreatorType;
  /** UUID of the creating internal user if there is one. */
  _fk_uuid_creatingInternalUser: string;
  /** UUID of the creating seller user if there is one. */
  _fk_uuid_creatingSellerUser: string;
  /**
   * Indicates if the auction was created for testing purposes and
   * should be handled differently: it should not be ended automatically,
   * so the seller should decide would he like to sell it or not.
   */
  isTest: boolean;
  /** Indicates if minimum required ask should be displayed for buyers. */
  displayMinAsk: boolean;
  /**
   * GPS coordinates of the auction (can be used for e.g. calculating distances between auctions and buyers).
   * @format double
   */
  locationGeoLat: number;
  /** @format double */
  locationGeoLon: number;
  /** @format double */
  counterOfferByBuyer: number;
  /** @format double */
  counterOfferByBuyerNet: number;
  /** @format double */
  lastOfferBySeller: number;
  /** @format double */
  lastOfferBySellerNet: number;
  /**
   * A value denoting the previous renegotiation offer given by a buyer user.
   * @format double
   */
  previousCounterOfferByBuyer: number;
  /** @format double */
  previousCounterOfferByBuyerNet: number;
  /**
   * A value denoting the previous renegotiation offer given by a seller user.
   * This is required in order to properly display the right information during the renegotiation process.
   * @format double
   */
  previousLastOfferBySeller: number;
  /** @format double */
  previousLastOfferBySellerNet: number;
  /** A flag for if the seller user connected to the auction has decided not to participate in the renegotiation any further. */
  renegotiationStoppedBySeller: boolean;
  /** A flag for if the highest bidding buyer user connected to the auction has decided not to participate in the renegotiation any further. */
  renegotiationStoppedByBuyer: boolean;
  /** A fag indicating if the buyer's withdrawal from the renegotiation process was done automatically or not. */
  wasRenegotiationStoppageByBuyerAutomatic: boolean;
  /** A fag indicating if the seller's withdrawal from the renegotiation process was done automatically or not. */
  wasRenegotiationStoppageBySellerAutomatic: boolean;
  /**
   * The total number of offer rounds a buyer has participated in for an auction during the renegotiation phase.
   * @format double
   */
  numBuyerRenegotiationOfferRounds: number;
  /**
   * The total number of offer rounds a seller has participated in for an auction during the renegotiation phase.
   * @format double
   */
  numSellerRenegotiationOfferRounds: number;
  /**
   * The calculated renegotiation midpoint value of the auction.
   * This is used once the renegotiation offers get too close for the offer process to continue.
   * This value will be undefined unless the auction is ready to be purchased for such a value.
   * @format double
   */
  renegotiationMidpointValue: number;
  /** @format double */
  renegotiationMidpointValueNet: number;
  /**
   * Indicates that the highest bidding buyer user for an auction in renegotiation is willing to accept the midpoint of their offer and
   * the offer of the seller for the same auction. This is used when the renegotiation offers get very close during the renegotiation process.
   */
  buyerAcceptedRenegotiationMidpoint: boolean;
  /**
   * Indicates that the seller of an auction is willing to accept the midpoint of their offer and the offer of the highest bidding
   * buyer user for the same auction. This is used when the renegotiation offers get very close during the renegotiation process.
   */
  sellerAcceptedRenegotiationMidpoint: boolean;
  /**
   * This is the datetime object denoting when the most recent renegotiation round concluded at.
   * @format date-time
   */
  lastRenegotiationRoundEndedAt: string;
  /**
   * The number of seconds until the current renegotiation round times out.
   * @format double
   */
  numSecondsUntilRenegotiationTimeout: number;
  /**
   * The UUID of the external case tracking the renegotiation of the auction.
   * We track this to enable case updates as the auction moves through renegotiation.
   */
  externalRenegotiationTicketUuid: string;
  /**
   * The calculated renegotiation fair value of the auction.
   * This is used once the renegotiation starts based on the auction GPR or MPR so we know
   * the fair value that both seller and buyer should agree upon.
   * @format double
   */
  renegotiationFairValue: number;
  /**
   * Value that is indicating the highest bid value on the auction when it ended.
   * This value should not change for a given auction since it supposed to be a historical record.
   * @format double
   */
  highestBidValueAtEndingTime: number;
  /** @format double */
  highestBidValueAtEndingTimeNet: number;
  /** Field data related to the renegotiation round process. */
  renegotiationRoundData?: IRenegotiationRoundData;
  /**
   * Indicates when manual renegotiation was finished via Salesforce.
   * @format date-time
   */
  finishedManuallyAt?: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing service (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the transfer of funds
   * for the vat deposit from COS to the deposit account (only relevant for cross border transactions).
   * This deposit is refunded by COS once the buyer provides the necessary documentation for a cross border transaction.
   */
  thirdPartyVATDepositTransferReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing service (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the transfer of funds
   * for the additional tax deposit from COS to the deposit account (only relevant for cross border transactions).
   * This deposit is refunded by COS once the buyer provides the necessary documentation for a cross border transaction.
   */
  thirdPartyAdditionalTaxDepositTransferReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing system (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the transfer of funds
   * for the auction additional taxes (e.g country specific luxury taxes): from COS to the seller.
   */
  thirdPartyAdditionalTaxTransferReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing service (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the transfer of funds
   * for the VAT: from COS to the seller (only relevant for cross border transactions for now).
   * COS is advancing the vat amount to the seller.
   */
  thirdPartyVatTransferReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing service (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the charge of funds
   * for the auction volume from the buyer to COS.
   */
  thirdPartyVolumeChargeReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing service (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the charge of funds
   * for the auction deposit from the buyer to COS (only relevant for cross border transactions for now).
   * This deposit is refunded by COS once the buyer provides the necessary documentation for a cross border transaction.
   */
  thirdPartyVATDepositChargeReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing service (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the charge of funds
   * for the auction additional tax (e.g country specific luxury tax) from the buyer to COS.
   */
  thirdPartyAdditionalTaxChargeReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing system (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the refund of an
   * additional tax deposit (only used for cross-border transactions currently).
   */
  thirdPartyAdditionalTaxRefundReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing system (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the payout of funds.
   */
  thirdPartyPayoutReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing system (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the refund of a
   * deposit (only used for cross-border transactions currently).
   */
  thirdPartyVATDepositRefundReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing system (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the refund of a
   * transfer to the deposit account owned by cos (only used for cross-border transactions currently).
   */
  thirdPartyVATDepositReversalReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing system (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the refund of the
   * additional tax transfer to the deposit account owned by cos (only used for cross-border transactions).
   */
  thirdPartyAdditionalTaxDepositReversalReference: string;
  /**
   * Reference to the invoice for the success fee issued to the seller for a sold vehicle (see ESellerFeeInvoicingMode.INVOICE_ON_SUCCESS).
   * The reference identifies the invoice issued to the seller in an external invoicing system (e.g. Stripe).
   *
   * This fee is not always charged, therefore this reference is optional (can be null).
   */
  sellerSuccessFeeInvoiceReference: string;
  /**
   * Reference to the invoice for the listing fee issued to the seller for a listed vehicle (see ESellerFeeInvoicingMode.INVOICE_ON_LISTING).
   * The reference identifies the invoice issued to the seller in an external invoicing system (e.g. Stripe).
   *
   * This fee is not always charged, therefore this reference is optional (can be null).
   */
  sellerListingFeeInvoiceReference: string;
  /**
   * Reference to the invoice for the standing fee issued to the buyer for a purchased vehicle picked up too late.
   *
   * This fee is not always charged, therefore this reference is optional (can be null).
   */
  buyerStandingFeeInvoiceReference: string;
  /**
   * Reference to a resource in an external invoicing system (such as Stripe).
   * References our own transaction fee invoice.
   */
  invoiceReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing system (such as Stripe).
   * This is only non-null, if external payment is allowed for this auction.
   */
  thirdPartyInvoiceReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing system.
   * This is only non-null, if external payment is allowed for this auction and it had to be cancelled.
   */
  thirdPartyCorrectionInvoiceReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing system (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the transfer of funds
   * for the auction volume: from COS to the seller.
   */
  thirdPartyTransferReference: string;
  /**
   * Reference of an invoice on an external invoicing system.
   * This is invoice is used to charge booked services (like guarantee) after the auction expires.
   */
  bookedServicesInvoiceReference: string;
  /**
   * Date-time when the auction was reauctioned and is now waiting for buyer payment until the new auction gets paid.
   * @format date-time
   */
  reauctionedWaitingForBuyerPaymentAt: string;
  /**
   * Date-time when the first reauction of this auction has expired.
   * We need to keep track of this so we can block the original buyer of seeing the original auction.
   * @format date-time
   */
  firstReauctionExpiredAt: string;
  /** Flag that indicates if this auction is a clonned reauction. */
  isReauction: boolean;
  /**
   * The highest bid value that we show to the seller when we merge the reauction with the original auction.
   * This is done so the seller see the amount that cos pay them and the buyer sees the amount that they paid cos.
   * @format double
   */
  sellerViewHighestBidValue: number;
  /**
   * The purchase confirmed at that we show to the seller when we merge the reauction with the original auction.
   * This is done so the seller see the date when the original auction was confirmed and the buyer sees the date that the reauction was confirmed.
   * @format date-time
   */
  sellerViewPurchaseConfirmedAt: string;
  /**
   * Date-time when the reauction was merged back at the original auction.
   * @format date-time
   */
  reauctionedMergedAt: string;
  /** Indicates wherever an auction was purchase using the Buy Now Pay Later feature. */
  wasPurchasedUsingBuyNowPayLater: boolean;
  /**
   * The timestamp at which the buyer has confirmed his choice to use Buy Now Pay Later for the auction.
   * @format date-time
   */
  buyNowPayLaterConfirmedAt: string;
  /** The IP address which confirmed the Buy Now Pay Later choice for the auction. */
  buyNowPayLaterConfirmationIPAddress: string;
  /** Reference to the Buy Now Pay Later Order on the PNI Service. */
  buyNowPayLaterOrderReference: string;
  /** Reference of the invoice for buy now pay later provider fee on an external invoicing system. */
  buyNowPayLaterInvoiceReference: string;
  /** The payment link reference */
  paymentLinkReference: string;
  /** Reference to the payment order on the P&I service */
  paymentOrderReference: string;
  isInternalFulfillment: boolean;
  belowMinAskBehaviour: EAuctionBelowMinAskBehaviour;
  /** The label of the auction, consisting of the vehicle make, model and date of first registration (EZ). */
  label: string;
  /**
   * Date+time when the auction is started.
   * When an auction is started right away (without drafting), the "createdAt" and "startedAt" should have
   * (roughly) the same values.
   * @format date-time
   */
  startedAt: string;
  /**
   * Date+time when the auction is "confirmed to be sold" by the seller (or by an admin).
   * If an auction is ending equal or above the minimum required ask, the "purchaseConfirmedAt"
   * will automatically be set and will be equal to the "endingTime".
   *
   * If an auction is ending below the minimum ask (CLOSED_UNDER_MINIMUM_ASK), the "purchaseConfirmedAt" timestamp
   * is only set if the auction is then manually "confirmed" by the seller or an admin.
   *
   * If an auction is never "successful", the "purchaseConfirmedAt" will remain "null".
   *
   * This value was introduced in the course of 2019. For all previous auctions, the endingTime was set
   * as the "purchaseConfirmedAt" retrospectively.
   * @format date-time
   */
  purchaseConfirmedAt: string;
  /**
   * Date-time when the auction has definitely been rejected by a seller or an admin, after the auction
   * ended below minimum required ask.
   *
   * This value is only available since 2019/07/29. All auctions rejected before that date have the value "null".
   * @format date-time
   */
  purchaseRejectedAt: string;
  /** Indicates the reason that an auction failed the renegotiation process. */
  rejectionReason: EAuctionRejectionReason;
  /**
   * Elaborates on the rejection reason to provide further context to why an
   * auction was rejected during the renegotiation process.
   */
  rejectionReasonNote: string;
  /**
   * The name of the competitor that the auction was sold to when the reject chosen reason was
   * VEHICLE_SOLD_ON_DIFFERENT_PLATFORM
   */
  rejectionReasonCompetitorName: string;
  /** In case if there was a direct upload requested but didn't succeed the reason should be stored. */
  directUploadRejectionReasons: EAuctionDirectUploadRejectionReason[];
  /**
   * Value that is indicating initial bid value auction should be started with.
   * @format double
   */
  startingBidValue: number;
  /** @format double */
  startingBidValueNet: number;
  /**
   * Value that can be manually set in case edge-cases apply and the payout amount is disconnected from the auction current highest bid.
   * This value can only be adjusted by internal users.
   * @format double
   */
  overridePayoutValue: number;
  /**
   * If an auction is ending below the minimum required ask, the "originalMinimumRequiredAsk" will be set to the
   * "minimumRequiredAsk" value before it is set to "currentHighestBidValue".
   * @format double
   */
  originalMinimumRequiredAsk: number;
  /** @format double */
  originalMinimumRequiredAskNet: number;
  /**
   * The purchase price for the vehicle that is auctioned.
   * This is set manually by the seller, and is mostly used to analyze the "real" margin that a seller
   * makes with an auction.
   * @format double
   */
  purchasePrice: number;
  /** @format double */
  purchasePriceNet: number;
  /**
   * The full object of the vehicle that is on auction.
   * This is augmented on every auction retrieval, based on _fk_uuid_vehicle.
   */
  associatedVehicle: IVehicle;
  /** Indicates whether the auction process is rated by the SellerUser. */
  isRatedByDealership: boolean;
  /** Indicates whether the auction process is rated by the BuyerUser. */
  isRatedByBuyer: boolean;
  /**
   * Indicates whether the BuyerUser has marked this auction as paid (payment of the auction volume).
   * This is done manually by the BuyerUser, so this value is not indicating that the actual payment has really
   * reached the seller.
   */
  isPaidByBuyer: boolean;
  /**
   * Timestamp when the BuyerUser has marked this auction as "paid" (i.e. set the "isPaidByBuyer").
   * @format date-time
   */
  outgoingPaymentConfirmedAt: string;
  /**
   * Timestamp when the SellerUser has confirmed that the payment has reached the seller bank account.
   * @format date-time
   */
  incomingPaymentConfirmedAt: string;
  /**
   * Timestamp when the auction state has changed from "CLOSED_WAITING_FOR_PAYMENT" to "CLOSED_WAITING_FOR_PICKUP".
   * @format date-time
   */
  pickupConfirmedAt: string;
  /** Physical address of where the vehicle on auction is located (a.k.a. pickup address). */
  locationCountryCode: ECountryCode;
  locationAddress: string;
  locationCity: string;
  locationZip: string;
  /** Physical address of where the vehicle on auction was inspected (a.k.a. inspection address). */
  inspectionLocationCountryCode: ECountryCode;
  inspectionLocationAddress: string;
  inspectionLocationCity: string;
  inspectionLocationZip: string;
  /**
   * Indicating to which bank account the BuyerUser has to transfer the auction volume to.
   * @deprecated
   */
  sellerIban: string;
  /**
   * URL to the seller invoice for the given auction, manually uploaded by the SellerUser, after
   * the auction has ended successfully.
   */
  urlToInvoice: string;
  /** URL for the cancelled invoice in case it was cancelled. */
  urlToCorrectionInvoice: string;
  /**
   * Flag indicating whether an auction has an "hot bid" phase in the last 20 seconds before expiration.
   * @deprecated
   */
  hotBid: boolean;
  /**
   * Seller's instant purchase price for the vehicle.
   * For the first 24 hours after the auction is started, the vehicle will only be available in "instant purchase"
   * mode (bidding is not allowed in that time, only purchasing right away).
   *
   * After 24 hours, if nobody purchased the vehicle, it is going on auction, just like all other auctions.
   * @format double
   */
  instantPurchasePrice: number;
  /** @format double */
  instantPurchasePriceNet: number;
  /**
   * Flag indicating that instant purchase is enabled for that auction.
   * (Implies that "instantPurchasePrice" is set)
   */
  allowInstantPurchase: boolean;
  /**
   * Flag indicating that an auction "ended" with an instant purchase, resp.
   * did never go on auction.
   */
  didEndWithInstantPurchase: boolean;
  /**
   * Internal counter, incrementing on every "restart" of an auction.
   * Starts with 0.
   *
   * e.g. count of 2 means that the auction has been restarted 2 times, leading to 3 auction objects in the
   * database.
   * @format double
   */
  auctioningIterations: number;
  /**
   * Indicating the strategic priority of an auction for CarOnSale.de
   * The higher the number, the more "relevant" the auction is.
   * @format double
   */
  priority: number;
  /** @deprecated */
  advertisementHtmlContent: string;
  /** @deprecated */
  buyerComplaint: EAuctionBuyerComplaint;
  /**
   * Custom field that is not used internally.
   * Allows sellers to store e.g. a reference ID to their own vehicle management system.
   */
  internalReferenceText: string;
  _fk_uuid_vehicle: string;
  _fk_uuid_sellerUser: string;
  /** This is a URL that redirects to an external invoice payment site (such as provided by Stripe). */
  urlToPaymentSite: string;
  /**
   * A flag indicating if an auction needs to be reviewed by an internal review agent (for quality assurance)
   * If true, an auction is currently under review by CarOnSale, and cannot be edited by the owning seller user.
   */
  needsReview: boolean;
  /** The reasons why an auction was rejected during the review. */
  reviewReasons: EAuctionReviewReason[];
  /**
   * Reasons why an auction is rejected during the review process ("Parkhaus").
   *
   * This reason is automatically set to UNKNOWN for all auctions and is then manually adjusted by
   * an internal review agent (in case the auction gets rejected) before it is sent back to the responsible
   * SellerUser.
   * @deprecated
   */
  reviewReason: EAuctionReviewReason;
  /** Specifies reason when "reviewReasons" contains EAuctionReviewReason.OTHER. */
  reviewReasonDetails: string;
  /** The comment given by an internal review agent on rejecting an auction under review. */
  reviewComment: string;
  /** Flag indicating if the auction can be published without review. */
  canSkipReview?: boolean;
  /**
   * Random PIN code that is generated when an auction is ready to be picked up or in advance before COSCheck inspection.
   * This PIN code is printed out on the hangar in the vehicle or handed to the seller of the auction, which can then forward it to
   * the buyer to confirm the pickup.
   */
  pickupPinCode: string;
  /**
   * Random UUID that is used to confirm vehicle pickup via scanning QR code.
   * It will be printed on the hangar or in the pickup document available for sellers.
   */
  pickupPinUuid: string;
  /**
   * Timestamp when PIN code was entered by the buyer.
   * Allows to determine how many auctions were self-picked up.
   * @format date-time
   */
  pickupPinCodeEnteredAt: string;
  /**
   * Timestamp when PIN UUID was entered by the buyer via scanning QR code.
   * Allows to determine how many auctions were self-picked up.
   * @format date-time
   */
  pickupPinUuidEnteredAt: string;
  /** Flag is indicating if pickup was confirmed with PIN using new process (via pin.caronsale.de) */
  pickupConfirmedWithNewPin: boolean;
  /** URL to the pickup information document for the BuyerUser. */
  urlToPickupBuyerDocument: string;
  /** URL to the pickup information document for the SellerUser (containing the "pickupPinCode"). */
  urlToPickupSellerDocument: string;
  /** URL to the success report document for the SellerUser. */
  urlToSellerSuccessReport: string;
  /** The payment process that is used to pay money for the purchase volume (auction volume). */
  paymentProcess: EAuctionPaymentProcess;
  /**
   * Referencing the bank account to which the payment for the purchase volume should be paid out.
   * This is null, if the paymentProcess === "CLASSIC".
   *
   * It is only used, when external payment (COS Payment) is enabled.
   */
  _fk_uuid_associatedBankAccount: string;
  /**
   * Indicates how often an auction has been reviewed.
   * This is incremented every time an auction is rejected after a review.
   * @format double
   */
  draftReviewIterations: number;
  /**
   * Timestamp when an admin resets a time window for a possibility to complain
   * @format date-time
   */
  complaintTimeWindowExtendedAt: string;
  /**
   * Flag indicates if this auction is suspected to have been paid in cash (only for auctions that use the external
   * invoice process), this means that no corresponding transfer exists on Stripe and the buyer confirmed the pickup
   * through the seller pin code.
   */
  isSuspectedCashPayment: boolean;
  /**
   * Timestamp when the auction was sent to review.
   * @format date-time
   */
  reviewStartedAt: string;
  /**
   * Timestamp when the auction QA process actually started (i.e. when Parkhaus started reviewing the auction).
   * @format date-time
   */
  qaStartedAt: string;
  /**
   * Timestamp when the auction was ready to be reinserted (a.k.a. restarted).
   * @format date-time
   */
  readyToBeReinsertedSince: string;
  /** The reference to the original auction from which current one was cloned. */
  _fk_uuid_clonedFrom: string;
  /** Auction type. */
  type: EAuctionType;
  /** Indicates whether the rejection wait period for this auction is over and thus can be rejected by the seller. */
  isRejectionWaitPeriodOver: boolean;
  /** Reference to invoice object for the external Billomat invoicing provider. */
  thirdPartyVehiclePurchaseInvoiceReference: string;
  /**
   * URL to the seller made invoice invoicing CoS for the given auction, manually uploaded to the crm by an internal user, after
   * the auction has ended successfully.
   */
  sellerToCosInvoiceUrl: string;
  /**
   * Timestamp for when the sellerToCosInvoiceUrl was last updated.
   * @format date-time
   */
  sellerToCosInvoiceUrlLastUpdatedAt: string;
  /**
   * Timestamp for when the sellerToCosInvoiceUrl is first uploaded.
   * @format date-time
   */
  sellerToCosInvoiceUrlFirstUploadedAt: string;
  /** Flag indicating wherever the uploaded seller to cos invoice was verified by an internal user. */
  sellerToCosInvoiceIsValid: boolean;
  /** The comment given by an internal user on the provided seller to cos invoice. */
  sellerToCosInvoiceComment: string;
  /** Shows if auction is marked as 'live', so, it will be slotted during starting and expire in less than 24 hours. */
  isLive: boolean;
  /** Shows if auction was already slotted via the slotting algorithm. */
  isSlotted: boolean;
  /**
   * The exact date+time when the auction was slotted to end.
   * @format date-time
   */
  slottedEndingTime: string;
  /** Indicating if transportation should be disabled manually. */
  isTransportationDisabledManually: boolean;
  /** Optional pickup instructions that will be communicated to the buyer. */
  pickupInstructions: string;
  /** Indicating wherever the seller should be charged a success/listing fee. */
  preventSellerFactoring: boolean;
  /** Indicating if buyer success fee should be applied or not. */
  applyBuyerSuccessFee: boolean;
  /** Info from document shipping service about the order, like status and links to the documents. */
  documentShippingOrder: IAuctionDocumentShippingOrder;
  /** Info about un-/confirmed auction data points. */
  fieldsConfirmationStatus: IAuctionFieldConfirmationStatusMap;
  /**
   * Whether the "Value Added Tax is reportable", a special tax condition in the EU.
   * If this is true, it means that the vehicle is "Regelbesteuert", meaning that the invoice for the vehicle
   * (either invoiced directly from seller to buyer - or from COS Payment to buyer) needs to include
   * the VAT (in Germany e.g. 19%) in the invoice.
   *
   * If this is false, the invoice does not need to have explicit VAT.
   */
  isVATReportable: boolean;
  /** Indicates how the auction was uploaded to the marketplace. */
  uploadMethod: EAuctionUploadMethod;
  /** Prebooked services available for the auction, like transport, warranty. */
  prebookedServices: IPrebookedService[];
  /** Indicates if cosCheckPlus guarantee can be booked for this auction. */
  isCosCheckPlusGuaranteeEnabled: boolean;
  /** Indicates if extended guarantee can be booked for this auction. */
  isExtendedGuaranteeEnabled: boolean;
  /** URL to the auction vehicle cover letter for sellers */
  urlToCoverLetter: string;
  /**
   * Timestamp when the automatic pickup approval was requested for auction
   * @format date-time
   */
  autoPickupOnPaymentRequestedAt: string;
  /**
   * This means that the auctions is using complimentary vehicle feature.
   *
   * Note: This is marked as true when the seller uses one of his complimentary vehicles to pay for the fees.
   */
  isSellerFeeComplimentary: boolean;
  /** Flag when standing fee is enabled to the auction and we'll need to extra charge a buyer as a penalty. */
  isStandingFeeEnabled: boolean;
  _fk_uuid_auctionRoom: string;
  /**
   * An auction can have a list of fulfillment criteria that impact how the
   * auction will be fulfilled depending on the buyer that wins it.
   */
  fulfillmentCriteria?: IAuctionFulfillmentCriteria[];
  /**
   * The auction marked with this flag won't start automatically after review.
   *
   * Instead, it will transition to state: READY_TO_BE_PUBLISHED.
   *
   * Default: false
   */
  shouldWaitToBePublishedAfterReview: boolean;
  /** A flag that marks if an auction is a selective ware or not. */
  isSelectiveWare: boolean;
  /** The id for the look and feel applied to the auction. */
  lookAndFeelId: EAuctionLookAndFeelId;
  /** A flag that marks if an auction will use the pickup qr code. */
  isQRCodeAvailable: boolean;
  /**
   * Indicates whether the highest bid should be transferred if this auction gets cloned.
   * Defaults to `false` if not specified.
   */
  shouldTransferHighestBid?: boolean;
  /** A flag that represents either or not if the auction was paid externally. */
  wasExternallyPaid?: boolean;
  /** A flag that represents either or not if the auction was delivered externally. */
  wasExternallyPickedUp?: boolean;
  /** Confirm to remove auction from auction room when changing auction ending time. */
  removeFromAuctionRoom?: boolean;
  /**
   * Indicates the amount of VAT tax that applies to the auction at current highest bid.
   * @format double
   */
  vatAmount: number;
  /**
   * VAT rate that applies to this auction.
   * @format double
   */
  vatRate: number;
  /** The state of the buy now pay later order */
  buyNowPayLaterOrderState: IBuyNowPayLaterOrderState;
  /** The value of the buy now pay later fee */
  buyNowPayLaterFee: IBuyNowPayLaterAmount;
  /** The link to the order within the buy now pay later provider. */
  buyNowPayLaterProviderOrderUrl: string;
  recommendationId?: string;
  /** The seller user that is owning this auction. */
  seller: ISellerUser;
  /** The account associated with the seller of this auction. */
  account: IAccountWithAssignedInternalUsers;
  /**
   * The associated SellerAccount for this auction.
   * "null" if no SellerAccount is assigned to the seller user.
   * @deprecated
   */
  sellerAccount: ISellerAccount;
  /**
   * The bank account to which the payment should be paid out.
   *
   * "null", if payment process is classic.
   */
  targetSellerBankAccount: ISellerBankAccount;
  /** The current highest bidder for this auction. */
  highestBidder: IGeneralUser;
  /** The transportation job that has been requested for this auction (only if really booked) */
  requestedTransportationTask: ITransportationTask;
  /** This flag indicates whether transport has to be booked through COS (no self-pickup possible) */
  enforceTransportation: boolean;
  /** Containing recent memos for this auction. */
  memos: IAuctionMemo[];
  /**
   * Value of the highest bidding agent currently set.
   * (Always belonging to the current highest bidder)
   * @format double
   */
  highestBiddingAgentValue: number;
  /** @format double */
  highestBiddingAgentValueNet: number;
  /**
   * The current state of the payment (only available for auctions with COS-Payment).
   *
   * This is null, if the payment process is classic.
   */
  externalPaymentState: IAuctionPaymentState;
  /**
   *
   * Url to pick up authorization document for associated transportation task
   */
  urlToSignedPickUpAuthorizationDocument: string;
  /**
   * Number of days from when pickup will be possible.
   * "null" if not available.
   * @format double
   */
  pickupPossibleInDays: number;
  /**
   * Indicates whether the current highest bidder is assigned as a regular buyer to the seller account that
   * is owning that auction.
   *
   * Either true or false, but not null.
   */
  isHighestBidderRegularBuyer: boolean;
  /**
   * An internal user who created an auction, can be an inspector or any other internal user.
   * Filled in from '_fk_uuid_creatingInternalUser' value.
   */
  creatingInternalUser: IGeneralUser;
  /**
   * Indicates whether cross-border net sale is possible for the auction
   * (i.e. the vehicle is "regelbesteuert" and foreign buyers are allowed to purchase it).
   */
  isCrossBorderNetSalePossible: boolean;
  /**
   * Indicates whether the auction actually ended as a cross-border net sale transaction
   * (i.e. the vehicle is "regelbesteuert" and was purchased by a foreign buyer).
   *
   * This is always false for auctions that are NOT closed successfully.
   */
  isCrossBorderNetSaleTransaction: boolean;
  /**
   * Buyer purchase fee that is charged for this auction on success.
   * This is the variable transaction fee, which is calculated based on bucket pricing.
   *
   * This value is augmented, calculated dynamically based on the highest bid value.
   * @format double
   */
  buyerPurchaseFee: number;
  /**
   * Buyer success fee that is charged for this auction on success.
   *
   * This value is augmented, based on the (static) fee that is configured on the seller account.
   * @format double
   */
  buyerSuccessFee: number;
  /**
   * Buyer ExportHandlingFee is charged for cross-border sales
   *
   * It is dependent on the country of the seller.
   *
   * e.g. DE -> 49, NL,FR,AU -> 19 (August '22)
   * @format double
   */
  buyerExportHandlingFee: number;
  /**
   * The GPR associated with the auction.
   *
   * This value is augmented, it can be null.
   */
  gpr: IGuaranteedPriceRequest;
  /**
   * The MPR associated with the auction.
   *
   * This value is augmented, it can be null.
   */
  mpr: ITradeInValuationRequest;
  /** Flag that is marked as true when the auction is enabled for express pickup. */
  isEnabledForExpressPickup: boolean;
  /** Flag that is marked as true when the auction is enabled for automatic release. */
  isEnabledForAutomaticRelease: boolean;
  /** Identify whether the auction vehicle needs to be pickup in the compound */
  isCompoundPickup: boolean;
  /**
   * This flag is showing a availability for transportation (the auction doesn't have to be closed). True by default,
   * false if a vehicle is not movable and the seller is not transport only.
   */
  isTransportationAvailable: boolean;
  /** The external Id of the original auction that this one is a reauction of. */
  originalAuctionExternalId: string;
  /** The external Id of the reauction that this one is the original auction of. */
  reauctionExternalId: string;
  /** Renegotiation augmented from external table */
  renegotiation?: IRenegotiation;
}

/** Transportation task augmented with auction, buyer, seller and vehicle information. */
export interface IKAMTransportationTask {
  /**
   * @deprecated
   * @format double
   */
  id: number;
  uuid: string;
  /**
   * Indicating the time when the entity has been created, i.e. when the entity was
   * persisted to the database.
   * @format date-time
   */
  createdAt?: string;
  /**
   * Indicating the last time a value of the entity has been changed (updated).
   * @format date-time
   */
  updatedAt?: string;
  /**
   * Indicating the time when the entity has been (softly) deleted.
   * "null" if entry is not deleted.
   * @format date-time
   */
  deletedAt?: string;
  /**
   * Timestamp when the transportation task is booked (i.e. the buyer is committing to
   * the transport - "purchasing it").
   *
   * This timestamp indicates when the "state" changed from PROPOSED_PRICE to REQUESTED_JOB.
   * @format date-time
   */
  bookedAt: string;
  /**
   * Timestamp when the transportation task is assigned to a carrier (transportation provider)
   * @format date-time
   */
  assignedAt: string;
  /**
   * Timestamp when the transportation task is picked up by a carrier (transportation provider)
   * @format date-time
   */
  pickedUpAt: string;
  /**
   * Timestamp when the transportation task is delivered by a carrier (transportation provider)
   * @format date-time
   */
  deliveredAt: string;
  /**
   * Timestamp when the transportation task has a proposed price
   * @format date-time
   */
  proposedPriceAt: string;
  /**
   * The earliest date at which the vehicle can be picked up.
   * @format date-time
   */
  earliestPickupDate: string;
  /**
   * The current state of the transportation task.
   *
   * A task with state REQUESTED_JOB, IN_PROGRESS or FINISHED can be considered to be "booked", which
   * means that the buyer has to pay the "netPrice" (+ taxes) for the transportation to COS.
   *
   * Tasks in "booked" states will be forwarded to the transportation planning system, deploying jobs
   * to the freelancing providers.
   */
  state: ETransportationTaskState;
  /**
   * The net price for the transportation task. This price is based on the "distanceInKm" from seller to buyer, so it
   * is individual per requesting buyer. The given price is calculated based on buckets for distance ranges.
   *
   * If a task is not yet in "booked" state, this is the price that buyers which requested a price for a
   * transportation will see as the price that it WOULD cost.
   *
   * If the transportation gets "booked", this price is used to invoice the buyer for the transportation costs.
   * If a buyer books within a certain time span after the auction purchase has been confirmed (normally 24 hours), the
   * "discountedNetPrice" will replace the "netPrice", so the buyer gets a discount (normally -10%) on the price, in
   * order to incentivize asap transportation booking.
   * @format double
   */
  netPrice: number;
  /**
   * This price is calculated on a buyer requesting a transportation price.
   * It is based on the "netPrice" with an applied discount.
   *
   * If the task is "booked" within a certain period of time after the auction purchase has been confirmed,
   * the "discountedNetPrice" replaces the "netPrice".
   * @format double
   */
  discountedNetPrice: number;
  /**
   * The "netPrice" + tax
   * @format double
   */
  grossPrice: number;
  /**
   * An internal price, which should indicate what the actual price for the transportation task is for COS.
   * This price is updated manually, since the actual price COS receives from its providers can differ based on
   * different factors.
   * @format double
   */
  internalNetPrice: number;
  /**
   * The distance in km calculated for the (driving) route from seller to buyer.
   * It is calculated via the Google Maps Distance Matrix API.
   *
   * The "distanceInKm" is the base for calculating a "netPrice".
   * @format double
   */
  distanceInKm: number;
  /**
   * The profit rate applied on the net price.
   * @format double
   */
  profitRate: number;
  /**
   * The discount rate applied on the net price.
   * @format double
   */
  discountRate: number;
  /** TODO: Ivan */
  urlToHandoverProtocolDocument: string;
  /**
   * Links the signed pickup authorization document
   * Allows transportation provider to pick up the vehicle
   */
  urlToSignedPickUpAuthorizationDocument: string;
  /** The from location address hash. */
  fromLocationAddressHash: string;
  /** The from location address line. */
  fromLocationAddressLine: string;
  /** The from location zip code. */
  fromLocationZipCode: string;
  /** The from location city. */
  fromLocationCity: string;
  /** The from location country code. */
  fromLocationCountryCode: ECountryCode;
  /** The to location address hash. */
  toLocationAddressHash: string;
  /** The to location address line. */
  toLocationAddressLine: string;
  /** The to location zip code. */
  toLocationZipCode: string;
  /** The to location city. */
  toLocationCity: string;
  /** The to location country code. */
  toLocationCountryCode: ECountryCode;
  /** The auction for which the transportation has been booked. */
  _fk_uuid_auction: string;
  /** The buyer which booked the transportation task. */
  _fk_uuid_buyerUser: string;
  /**
   * The transportation provider which is responsible for transporting the vehicle.
   *
   * This is manually set by admins after the transportation task has been booked.
   * Otherwise this is "null".
   */
  _fk_uuid_transportationProvider: string;
  /** @format double */
  _fk_transportationProvider: number;
  /**
   * The transportation price provider which is responsible for calculate transportation price for the vehicle.
   *
   * This is automatically set whenever the transportation price is requested.
   * Otherwise this is "null".
   */
  _fk_uuid_transportationPriceProvider: string;
  /** An external reference/key to an invoice in an external invoicing system like e.g. Stripe. */
  invoiceReference: string;
  /** A note for the transportation task. Usually used for non-COS tasks. */
  note: string;
  /**
   * Estimated date when a car will be picked up from the seller.
   * @format date-time
   */
  estimatedPickupDate: string;
  /**
   * Estimated date when a car will be delivered to the buyer.
   * @format date-time
   */
  estimatedDeliveryDate: string;
  /** Any extra information that can be shared with the carriers. */
  additionalInformation: string;
  /** Id used in the calculation of the transportation price */
  calculationId: string;
  /**
   * Minimum amount of days to pickup the vehicle
   * @format double
   */
  minDaysToPickup: number;
  /**
   * Maximum amount of days to pickup the vehicle
   * @format double
   */
  maxDaysToPickup: number;
  /** UUID of the internal user who booked the transportation task or null if it was booked by the buyer themselves. */
  bookedByInternalUserUuid: string;
  /** Auction has transportation enforced. */
  enforceTransportation: boolean;
  /** Reason the transportation is enforced for this auction. */
  enforceReason: ETransportationEnforceReason;
  /** Auction is considered a cross-border net transaction. */
  isCrossBorderNetSale: boolean;
  /**
   * Expiration date for the transportation task. If set, the task is allowed to be deleted
   * from the database after this date, unless it's been booked successfully.
   * @format date-time
   */
  expiresAt?: string;
  /** Auctions as viewed by internal users. */
  associatedAuction: IInternalAuctionView;
  /**
   * This is the new user structure, meant to replace our old fragmented user structure with Sellers, Buyers and etc.
   * A user is someone that is connected to a company and uses our platform for an activity enabled for that account.
   * As we're a B2B platform, our focus is always the account/business, the user is just someone acting in the name of an account.
   */
  associatedBuyer: IGeneralUser;
}

export interface IInternalTransportationTaskMemo {
  /**
   * @deprecated
   * @format double
   */
  id: number;
  uuid: string;
  /**
   * Indicating the time when the entity has been created, i.e. when the entity was
   * persisted to the database.
   * @format date-time
   */
  createdAt?: string;
  /**
   * Indicating the last time a value of the entity has been changed (updated).
   * @format date-time
   */
  updatedAt?: string;
  /**
   * Indicating the time when the entity has been (softly) deleted.
   * "null" if entry is not deleted.
   * @format date-time
   */
  deletedAt?: string;
  _fk_uuid_creator: string;
  content: string;
  /**
   * This is the new user structure, meant to replace our old fragmented user structure with Sellers, Buyers and etc.
   * A user is someone that is connected to a company and uses our platform for an activity enabled for that account.
   * As we're a B2B platform, our focus is always the account/business, the user is just someone acting in the name of an account.
   */
  associatedCreator: IGeneralUser;
  _fk_uuid_transportationTask: string;
}

export interface ILocation {
  addressLine?: string;
  zipCode?: string;
  city: string;
  countryCode: ECountryCode;
  /** Add extra comments do the location (e.g., pick-up instruction in transportation tasks). */
  comment?: string;
  /** Language of the contact person at the location. */
  contactLanguage?: string;
}

export interface IAdminTransportationTaskView {
  /**
   * @deprecated
   * @format double
   */
  id: number;
  uuid: string;
  /**
   * Indicating the time when the entity has been created, i.e. when the entity was
   * persisted to the database.
   * @format date-time
   */
  createdAt?: string;
  /**
   * Indicating the last time a value of the entity has been changed (updated).
   * @format date-time
   */
  updatedAt?: string;
  /**
   * Indicating the time when the entity has been (softly) deleted.
   * "null" if entry is not deleted.
   * @format date-time
   */
  deletedAt?: string;
  /**
   * Timestamp when the transportation task is booked (i.e. the buyer is committing to
   * the transport - "purchasing it").
   *
   * This timestamp indicates when the "state" changed from PROPOSED_PRICE to REQUESTED_JOB.
   * @format date-time
   */
  bookedAt: string;
  /**
   * Timestamp when the transportation task is assigned to a carrier (transportation provider)
   * @format date-time
   */
  assignedAt: string;
  /**
   * Timestamp when the transportation task is picked up by a carrier (transportation provider)
   * @format date-time
   */
  pickedUpAt: string;
  /**
   * Timestamp when the transportation task is delivered by a carrier (transportation provider)
   * @format date-time
   */
  deliveredAt: string;
  /**
   * Timestamp when the transportation task has a proposed price
   * @format date-time
   */
  proposedPriceAt: string;
  /**
   * The earliest date at which the vehicle can be picked up.
   * @format date-time
   */
  earliestPickupDate: string;
  /**
   * The current state of the transportation task.
   *
   * A task with state REQUESTED_JOB, IN_PROGRESS or FINISHED can be considered to be "booked", which
   * means that the buyer has to pay the "netPrice" (+ taxes) for the transportation to COS.
   *
   * Tasks in "booked" states will be forwarded to the transportation planning system, deploying jobs
   * to the freelancing providers.
   */
  state: ETransportationTaskState;
  /**
   * The net price for the transportation task. This price is based on the "distanceInKm" from seller to buyer, so it
   * is individual per requesting buyer. The given price is calculated based on buckets for distance ranges.
   *
   * If a task is not yet in "booked" state, this is the price that buyers which requested a price for a
   * transportation will see as the price that it WOULD cost.
   *
   * If the transportation gets "booked", this price is used to invoice the buyer for the transportation costs.
   * If a buyer books within a certain time span after the auction purchase has been confirmed (normally 24 hours), the
   * "discountedNetPrice" will replace the "netPrice", so the buyer gets a discount (normally -10%) on the price, in
   * order to incentivize asap transportation booking.
   * @format double
   */
  netPrice: number;
  /**
   * This price is calculated on a buyer requesting a transportation price.
   * It is based on the "netPrice" with an applied discount.
   *
   * If the task is "booked" within a certain period of time after the auction purchase has been confirmed,
   * the "discountedNetPrice" replaces the "netPrice".
   * @format double
   */
  discountedNetPrice: number;
  /**
   * The "netPrice" + tax
   * @format double
   */
  grossPrice: number;
  /**
   * An internal price, which should indicate what the actual price for the transportation task is for COS.
   * This price is updated manually, since the actual price COS receives from its providers can differ based on
   * different factors.
   * @format double
   */
  internalNetPrice: number;
  /**
   * The distance in km calculated for the (driving) route from seller to buyer.
   * It is calculated via the Google Maps Distance Matrix API.
   *
   * The "distanceInKm" is the base for calculating a "netPrice".
   * @format double
   */
  distanceInKm: number;
  /**
   * The profit rate applied on the net price.
   * @format double
   */
  profitRate: number;
  /**
   * The discount rate applied on the net price.
   * @format double
   */
  discountRate: number;
  /** TODO: Ivan */
  urlToHandoverProtocolDocument: string;
  /**
   * Links the signed pickup authorization document
   * Allows transportation provider to pick up the vehicle
   */
  urlToSignedPickUpAuthorizationDocument: string;
  /** The from location address hash. */
  fromLocationAddressHash: string;
  /** The from location address line. */
  fromLocationAddressLine: string;
  /** The from location zip code. */
  fromLocationZipCode: string;
  /** The from location city. */
  fromLocationCity: string;
  /** The from location country code. */
  fromLocationCountryCode: ECountryCode;
  /** The to location address hash. */
  toLocationAddressHash: string;
  /** The to location address line. */
  toLocationAddressLine: string;
  /** The to location zip code. */
  toLocationZipCode: string;
  /** The to location city. */
  toLocationCity: string;
  /** The to location country code. */
  toLocationCountryCode: ECountryCode;
  /** The auction for which the transportation has been booked. */
  _fk_uuid_auction: string;
  /** The buyer which booked the transportation task. */
  _fk_uuid_buyerUser: string;
  /**
   * The transportation provider which is responsible for transporting the vehicle.
   *
   * This is manually set by admins after the transportation task has been booked.
   * Otherwise this is "null".
   */
  _fk_uuid_transportationProvider: string;
  /** @format double */
  _fk_transportationProvider: number;
  /**
   * The transportation price provider which is responsible for calculate transportation price for the vehicle.
   *
   * This is automatically set whenever the transportation price is requested.
   * Otherwise this is "null".
   */
  _fk_uuid_transportationPriceProvider: string;
  /** An external reference/key to an invoice in an external invoicing system like e.g. Stripe. */
  invoiceReference: string;
  /** A note for the transportation task. Usually used for non-COS tasks. */
  note: string;
  /**
   * Estimated date when a car will be picked up from the seller.
   * @format date-time
   */
  estimatedPickupDate: string;
  /**
   * Estimated date when a car will be delivered to the buyer.
   * @format date-time
   */
  estimatedDeliveryDate: string;
  /** Any extra information that can be shared with the carriers. */
  additionalInformation: string;
  /** Id used in the calculation of the transportation price */
  calculationId: string;
  /**
   * Minimum amount of days to pickup the vehicle
   * @format double
   */
  minDaysToPickup: number;
  /**
   * Maximum amount of days to pickup the vehicle
   * @format double
   */
  maxDaysToPickup: number;
  /** UUID of the internal user who booked the transportation task or null if it was booked by the buyer themselves. */
  bookedByInternalUserUuid: string;
  /** Auction has transportation enforced. */
  enforceTransportation: boolean;
  /** Reason the transportation is enforced for this auction. */
  enforceReason: ETransportationEnforceReason;
  /** Auction is considered a cross-border net transaction. */
  isCrossBorderNetSale: boolean;
  /**
   * Expiration date for the transportation task. If set, the task is allowed to be deleted
   * from the database after this date, unless it's been booked successfully.
   * @format date-time
   */
  expiresAt?: string;
  sellerCompanyName: string;
  buyerCompanyName: string;
  vehicle: IVehicle;
  pickupLocation: ILocation;
  dropoffLocation: ILocation;
  isReadyToPickup: boolean;
  urlToSignedPowerOfAttorneyDocument: string;
  memo: IInternalTransportationTaskMemo;
}

export interface ITransportationTaskMemo {
  /**
   * @deprecated
   * @format double
   */
  id: number;
  uuid: string;
  /**
   * Indicating the time when the entity has been created, i.e. when the entity was
   * persisted to the database.
   * @format date-time
   */
  createdAt?: string;
  /**
   * Indicating the last time a value of the entity has been changed (updated).
   * @format date-time
   */
  updatedAt?: string;
  /**
   * Indicating the time when the entity has been (softly) deleted.
   * "null" if entry is not deleted.
   * @format date-time
   */
  deletedAt?: string;
  content: string;
  _fk_uuid_transportationTask: string;
  _fk_uuid_transportationProvider: string;
}

export interface ITransportationTaskActivity {
  /**
   * @deprecated
   * @format double
   */
  id: number;
  uuid: string;
  /**
   * Indicating the time when the entity has been created, i.e. when the entity was
   * persisted to the database.
   * @format date-time
   */
  createdAt?: string;
  /**
   * Indicating the last time a value of the entity has been changed (updated).
   * @format date-time
   */
  updatedAt?: string;
  /**
   * Indicating the time when the entity has been (softly) deleted.
   * "null" if entry is not deleted.
   * @format date-time
   */
  deletedAt?: string;
  previousState: ETransportationTaskState;
  state: ETransportationTaskState;
  /** @format double */
  _fk_associatedTransportationTask: number;
  _fk_uuid_transportationTask: string;
}

export interface ITransportationProviderTaskView {
  /**
   * @deprecated
   * @format double
   */
  id: number;
  uuid: string;
  /**
   * Indicating the time when the entity has been created, i.e. when the entity was
   * persisted to the database.
   * @format date-time
   */
  createdAt?: string;
  /**
   * Indicating the last time a value of the entity has been changed (updated).
   * @format date-time
   */
  updatedAt?: string;
  /**
   * Indicating the time when the entity has been (softly) deleted.
   * "null" if entry is not deleted.
   * @format date-time
   */
  deletedAt?: string;
  /**
   * Timestamp when the transportation task is booked (i.e. the buyer is committing to
   * the transport - "purchasing it").
   *
   * This timestamp indicates when the "state" changed from PROPOSED_PRICE to REQUESTED_JOB.
   * @format date-time
   */
  bookedAt: string;
  /**
   * Timestamp when the transportation task is assigned to a carrier (transportation provider)
   * @format date-time
   */
  assignedAt: string;
  /**
   * Timestamp when the transportation task is picked up by a carrier (transportation provider)
   * @format date-time
   */
  pickedUpAt: string;
  /**
   * Timestamp when the transportation task is delivered by a carrier (transportation provider)
   * @format date-time
   */
  deliveredAt: string;
  /**
   * Timestamp when the transportation task has a proposed price
   * @format date-time
   */
  proposedPriceAt: string;
  /**
   * The earliest date at which the vehicle can be picked up.
   * @format date-time
   */
  earliestPickupDate: string;
  /**
   * The current state of the transportation task.
   *
   * A task with state REQUESTED_JOB, IN_PROGRESS or FINISHED can be considered to be "booked", which
   * means that the buyer has to pay the "netPrice" (+ taxes) for the transportation to COS.
   *
   * Tasks in "booked" states will be forwarded to the transportation planning system, deploying jobs
   * to the freelancing providers.
   */
  state: ETransportationTaskState;
  /**
   * The net price for the transportation task. This price is based on the "distanceInKm" from seller to buyer, so it
   * is individual per requesting buyer. The given price is calculated based on buckets for distance ranges.
   *
   * If a task is not yet in "booked" state, this is the price that buyers which requested a price for a
   * transportation will see as the price that it WOULD cost.
   *
   * If the transportation gets "booked", this price is used to invoice the buyer for the transportation costs.
   * If a buyer books within a certain time span after the auction purchase has been confirmed (normally 24 hours), the
   * "discountedNetPrice" will replace the "netPrice", so the buyer gets a discount (normally -10%) on the price, in
   * order to incentivize asap transportation booking.
   * @format double
   */
  netPrice: number;
  /**
   * This price is calculated on a buyer requesting a transportation price.
   * It is based on the "netPrice" with an applied discount.
   *
   * If the task is "booked" within a certain period of time after the auction purchase has been confirmed,
   * the "discountedNetPrice" replaces the "netPrice".
   * @format double
   */
  discountedNetPrice: number;
  /**
   * The "netPrice" + tax
   * @format double
   */
  grossPrice: number;
  /**
   * An internal price, which should indicate what the actual price for the transportation task is for COS.
   * This price is updated manually, since the actual price COS receives from its providers can differ based on
   * different factors.
   * @format double
   */
  internalNetPrice: number;
  /**
   * The distance in km calculated for the (driving) route from seller to buyer.
   * It is calculated via the Google Maps Distance Matrix API.
   *
   * The "distanceInKm" is the base for calculating a "netPrice".
   * @format double
   */
  distanceInKm: number;
  /**
   * The profit rate applied on the net price.
   * @format double
   */
  profitRate: number;
  /**
   * The discount rate applied on the net price.
   * @format double
   */
  discountRate: number;
  /** TODO: Ivan */
  urlToHandoverProtocolDocument: string;
  /**
   * Links the signed pickup authorization document
   * Allows transportation provider to pick up the vehicle
   */
  urlToSignedPickUpAuthorizationDocument: string;
  /** The from location address hash. */
  fromLocationAddressHash: string;
  /** The from location address line. */
  fromLocationAddressLine: string;
  /** The from location zip code. */
  fromLocationZipCode: string;
  /** The from location city. */
  fromLocationCity: string;
  /** The from location country code. */
  fromLocationCountryCode: ECountryCode;
  /** The to location address hash. */
  toLocationAddressHash: string;
  /** The to location address line. */
  toLocationAddressLine: string;
  /** The to location zip code. */
  toLocationZipCode: string;
  /** The to location city. */
  toLocationCity: string;
  /** The to location country code. */
  toLocationCountryCode: ECountryCode;
  /** The auction for which the transportation has been booked. */
  _fk_uuid_auction: string;
  /** The buyer which booked the transportation task. */
  _fk_uuid_buyerUser: string;
  /**
   * The transportation provider which is responsible for transporting the vehicle.
   *
   * This is manually set by admins after the transportation task has been booked.
   * Otherwise this is "null".
   */
  _fk_uuid_transportationProvider: string;
  /** @format double */
  _fk_transportationProvider: number;
  /**
   * The transportation price provider which is responsible for calculate transportation price for the vehicle.
   *
   * This is automatically set whenever the transportation price is requested.
   * Otherwise this is "null".
   */
  _fk_uuid_transportationPriceProvider: string;
  /** An external reference/key to an invoice in an external invoicing system like e.g. Stripe. */
  invoiceReference: string;
  /** A note for the transportation task. Usually used for non-COS tasks. */
  note: string;
  /**
   * Estimated date when a car will be picked up from the seller.
   * @format date-time
   */
  estimatedPickupDate: string;
  /**
   * Estimated date when a car will be delivered to the buyer.
   * @format date-time
   */
  estimatedDeliveryDate: string;
  /** Any extra information that can be shared with the carriers. */
  additionalInformation: string;
  /** Id used in the calculation of the transportation price */
  calculationId: string;
  /**
   * Minimum amount of days to pickup the vehicle
   * @format double
   */
  minDaysToPickup: number;
  /**
   * Maximum amount of days to pickup the vehicle
   * @format double
   */
  maxDaysToPickup: number;
  /** UUID of the internal user who booked the transportation task or null if it was booked by the buyer themselves. */
  bookedByInternalUserUuid: string;
  /** Auction has transportation enforced. */
  enforceTransportation: boolean;
  /** Reason the transportation is enforced for this auction. */
  enforceReason: ETransportationEnforceReason;
  /** Auction is considered a cross-border net transaction. */
  isCrossBorderNetSale: boolean;
  /**
   * Expiration date for the transportation task. If set, the task is allowed to be deleted
   * from the database after this date, unless it's been booked successfully.
   * @format date-time
   */
  expiresAt?: string;
  sellerCompanyName: string;
  buyerCompanyName: string;
  vehicle: IVehicle;
  pickupLocation: ILocation;
  dropoffLocation: ILocation;
  isReadyToPickup: boolean;
  urlToSignedPowerOfAttorneyDocument: string;
  memo: ITransportationTaskMemo;
  salesmanPhoneNumber: string;
  salesmanFirstName: string;
  salesmanLastName: string;
  salesmanMailAddress: string;
  dealershipPhoneNumber: string;
  dealershipFirstName: string;
  dealershipLastName: string;
  dealershipMailAddress: string;
  pickupAuthorizationDocumentUrl: string;
  /** @format date-time */
  pickupDueDate: string;
  auctionState: EAuctionState;
  lastTaskActivity: ITransportationTaskActivity;
}

export enum EDayOfWeek {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
  Value5 = 5,
  Value6 = 6,
}

export interface ITransportationProviderDaySchedule {
  /**
   * @deprecated
   * @format double
   */
  id: number;
  uuid: string;
  /**
   * Indicating the time when the entity has been created, i.e. when the entity was
   * persisted to the database.
   * @format date-time
   */
  createdAt?: string;
  /**
   * Indicating the last time a value of the entity has been changed (updated).
   * @format date-time
   */
  updatedAt?: string;
  /**
   * Indicating the time when the entity has been (softly) deleted.
   * "null" if entry is not deleted.
   * @format date-time
   */
  deletedAt?: string;
  from: string;
  to: string;
  dayOfWeek: EDayOfWeek;
  /** @format double */
  _fk_transportationProvider: number;
  _fk_uuid_transportationProvider: string;
}

export interface ITransportationProviderActivityData {
  /** @format double */
  numTransportations: number;
  /** @format double */
  numTransportationsVisit: number;
  /** @format double */
  numSuccessfulTransportations: number;
}

export interface ITransportationProviderActivitySummary {
  total: ITransportationProviderActivityData;
  lastDays: Record<string, ITransportationProviderActivityData>;
}

/** User for transporters registered to CarOnSale. */
export interface ITransporterUser {
  addressLine?: string;
  zipCode?: string;
  city: string;
  countryCode: ECountryCode;
  /** Add extra comments do the location (e.g., pick-up instruction in transportation tasks). */
  comment?: string;
  /** Language of the contact person at the location. */
  contactLanguage?: string;
  /**
   * @deprecated
   * @format double
   */
  id: number;
  uuid: string;
  /**
   * Indicating the time when the entity has been created, i.e. when the entity was
   * persisted to the database.
   * @format date-time
   */
  createdAt?: string;
  /**
   * Indicating the last time a value of the entity has been changed (updated).
   * @format date-time
   */
  updatedAt?: string;
  /**
   * Indicating the time when the entity has been (softly) deleted.
   * "null" if entry is not deleted.
   * @format date-time
   */
  deletedAt?: string;
  /** The userId generated by the IdP */
  userId?: string;
  /** The state of the user. */
  state: EUserState;
  /** The mail address of the user. */
  mailAddress: string;
  /** The mail address which is using for login. */
  loginMailAddress?: string;
  /** The password of the user. */
  password?: string;
  /** The privilege string, indicating what the user is allowed to do. */
  privileges: string;
  /** The user type. */
  type?: EUserType;
  preferredLanguage: string;
  /**
   * Whether the customer wants to be addressed with honorific form
   * (e.g. "Sie" instead of "Du" in German)
   */
  honorific: boolean;
  /** The first name of the customer. */
  firstName: string;
  /** The last name of the customer. */
  lastName: string;
  /**
   * Multiple slots for phone numbers.
   * "null" if a slot is not used.
   */
  phoneNumber: string;
  phoneNumber2: string;
  phoneNumber3: string;
  /**
   * The referral code that was used by the customer when registering to COS.
   * "null" if no referral code has been used.
   */
  referralCode: string;
  businessName: string;
  /** @format double */
  capacity: number;
  activity?: ITransportationProviderActivitySummary;
  urlToInsuranceDocument: string;
  insuranceDocument: IFile;
  licenseNumber: string;
  licenseExpirationDate: string;
  /** The VAT ID of the customer. */
  vatId: string;
  /** The tax ID (different from the VAT ID) of the customer. */
  taxId: string;
  /**
   * The URL to the ID document of the customer
   * (required for verification to become a fully registered buyer user).
   */
  urlToIdDocument: string;
  /** @deprecated */
  idDocument: IFile;
  /**
   * The URL to the business registration document
   * (required for verification to become a fully registered buyer user).
   */
  urlToBusinessRegistrationDocument: string;
  /** @deprecated */
  businessRegistrationDocument: IFile;
}

export interface ITransportationProviderTaskPrice {
  /** User for transporters registered to CarOnSale. */
  transportationProvider: ITransporterUser;
  /** @format double */
  price: number;
}

export interface ITransportationProviderTasksPriceList {
  /** @format double */
  taskId: number;
  providersTaskPrice: ITransportationProviderTaskPrice[];
}

export enum ETransportationProviderRateType {
  Value0 = 0,
  Value1 = 1,
}

export interface ITransportationProviderRate {
  /**
   * @deprecated
   * @format double
   */
  id: number;
  uuid: string;
  /**
   * Indicating the time when the entity has been created, i.e. when the entity was
   * persisted to the database.
   * @format date-time
   */
  createdAt?: string;
  /**
   * Indicating the last time a value of the entity has been changed (updated).
   * @format date-time
   */
  updatedAt?: string;
  /**
   * Indicating the time when the entity has been (softly) deleted.
   * "null" if entry is not deleted.
   * @format date-time
   */
  deletedAt?: string;
  type: ETransportationProviderRateType;
  /** @format double */
  toKm: number;
  /** @format double */
  fromKm: number;
  /** @format double */
  price: number;
  /** @format double */
  _fk_associatedTransportationProvider: number;
  _fk_uuid_transportationProvider: string;
}

/** From T, pick a set of properties whose keys are in the union K */
export interface PickITransportationTaskAdditionalInformation {
  /** Any extra information that can be shared with the carriers. */
  additionalInformation: string;
}

export enum ETourType {
  Value0 = 0,
  Value1 = 1,
}

export interface ITransportationProviderVehicle {
  /**
   * @deprecated
   * @format double
   */
  id: number;
  uuid: string;
  /**
   * Indicating the time when the entity has been created, i.e. when the entity was
   * persisted to the database.
   * @format date-time
   */
  createdAt?: string;
  /**
   * Indicating the last time a value of the entity has been changed (updated).
   * @format date-time
   */
  updatedAt?: string;
  /**
   * Indicating the time when the entity has been (softly) deleted.
   * "null" if entry is not deleted.
   * @format date-time
   */
  deletedAt?: string;
  licensePlate: string;
  make: string;
  model: string;
  /** @format double */
  maxNumberOfPackages: number;
  /** @format double */
  weightLimit: number;
  /** @format double */
  maxPackageLength: number;
  vehicleImageUrl: string;
  typeOfTour: ETourType;
  /** @format double */
  _fk_transportationProvider: number;
  _fk_uuid_transportationProvider: string;
}

export interface IPageIKAMTransportationTask {
  items: IKAMTransportationTask[];
  /** @format double */
  total: number;
  /** @format double */
  page: number;
  /** @format double */
  pageSize?: number;
}

export interface PageITransportationTask {
  items: ITransportationTask[];
  /** @format double */
  total: number;
  /** @format double */
  page: number;
  /** @format double */
  pageSize?: number;
}

/**
 * Inform that a new resource was created. Server will
 * add a Location header and set status to 201
 */
export interface ReturnNewResourceIFile {
  location: string;
  /** @format double */
  statusCode: number;
  /** the body to be sent */
  body: IFile;
}

/**
 * Inform that the request was accepted but is not completed.
 * A Location header should inform the location where the user
 * can monitor his request processing status.
 */
export interface ReturnRequestAcceptedJobIdString {
  location: string;
  /** @format double */
  statusCode: number;
  /** the body to be sent */
  body: {
    jobId: string;
  };
}

export enum KYCDocumentType {
  IdDocumentFront = 'idDocumentFront',
  IdDocumentBack = 'idDocumentBack',
  BusinessRegistrationDocument = 'businessRegistrationDocument',
}

export enum EDeviceType {
  Mobile = 'mobile',
  Desktop = 'desktop',
  Unknown = 'unknown',
}

export interface ICloudinarySignature {
  signature: string;
  cloudName: string;
  cloudApiKey: string;
}

/**
 * An invoice that is issued by COS to a third party.
 *
 * There are "classic" invoices (invoices created and managed by the "classic" invoicing engine, an outdated in-house solution
 * that is not used anymore since 31.12.2019) and "external" invoices that are managed by third-party systems like Stripe.
 */
export interface IInvoice {
  uuid: string;
  /**
   * Indicating the time when the entity has been created, i.e. when the entity was
   * persisted to the database.
   * @format date-time
   */
  createdAt?: string;
  /**
   * Indicating the last time a value of the entity has been changed (updated).
   * @format date-time
   */
  updatedAt?: string;
  /**
   * Indicating the time when the entity has been (softly) deleted.
   * "null" if entry is not deleted.
   * @format date-time
   */
  deletedAt?: string;
  /** Indicates the current status of the invoice */
  status: EInvoiceStatus;
  /** Indicating the article that is invoiced with this invoice. */
  article: EInvoicedArticle;
  /**
   * Flag indicating whether invoice is paid
   * (i.e. actually paid or manually marked as paid).
   */
  isPaid: boolean;
  /**
   * The net amount (subtotal) sum of the invoice.
   *
   * Initially, this was the net price per unit. After switching to Stripe, this is
   * the full net price for the full invoice.
   * @format double
   */
  netAmount: number;
  /**
   * The gross amount (total) sum of the invoice.
   *
   * Even if the invoice is paid, grossAmount will still be the original (total)
   * amount of the invoice.
   * @format double
   */
  grossAmount: number;
  /**
   * The amount that the customer has to pay.
   * This is a dynamic value. If the invoice is paid, this is reduced to 0.
   * @format double
   */
  amountDue: number;
  /**
   * The tax amount that is put on this invoice.
   *
   * If a buyer is "reverse"-charged, this value is 0.
   * @format double
   */
  taxAmount: number;
  /**
   * The sum of all discounts (pre-tax) that is applied to this invoice.
   *
   * If there are no discounts applied, this is 0.
   * @format double
   */
  discountedAmount: number;
  /**
   * Balance that is applied to this invoice.
   *
   * Balance can either be negative (credit, i.e. reducing the amount to pay) or
   * positive (debit, i.e. increasing the amount to pay).
   *
   * If no balance is applied, this is 0.
   * @format double
   */
  appliedBalanceAmount: number;
  /**
   * Amount of the amount due that is already paid.
   *
   * This is either 0 (not paid) or the value of "amountDue" (paid).
   * @format double
   */
  paidAmount: number;
  /**
   * The date when the invoice is due.
   * @format date-time
   */
  dueDate: string;
  /**
   * Date of invoice invocation. In most cases equal to "createdAt" value.
   * @format date-time
   */
  invokedAt: string;
  /**
   * Date of "delivery" of the invoiced article.
   * Mostly relevant for seller-related articles, since invoices
   * are usually created weeks after the delivery has happened.
   * @format date-time
   */
  deliveredAt: string;
  /**
   * Time+Date when the invoice has been marked as "paid".
   * @format date-time
   */
  paidAt: string;
  /** Invoice reference that is shown to the customer. */
  referenceId: string;
  /** The URL to access the invoice PDF. */
  urlToPdf: string;
  /** The URL to access an online form/invoice to trigger a payment (e.g. Stripe Hosted Invoices). */
  urlToPaymentSite: string;
  /**
   * Reference to external payment provider (such as Stripe).
   * Could contain IDs for invoice objects in the third-party system.
   */
  externalPaymentReference: string;
  /** The bank account number (IBAN) where the payment for the invoice is expected to be made to. */
  targetBankAccount: string;
  /** UUID of the account that this invoice is associated with. */
  accountUuid: string;
  /**
   * The auction model, which is a central element in the data model, referencing and referenced by most other
   * entities in the model.
   *
   * TODO
   */
  associatedAuction?: IAuction;
  /**
   * UUID of the associated auction.
   * "null" if no auction is associated with this invoice.
   */
  _fk_uuid_auction: string;
  /**
   * UUID of the associated buyer.
   * "null" if no buyer is associated with this invoice.
   */
  _fk_uuid_buyerUser: string;
  /**
   * UUID of the associated buyer.
   * "null" if no seller is associated with this invoice.
   */
  _fk_uuid_sellerUser: string;
  /** URL which stores the eletronic invoice. */
  urlToElectronicInvoice: string;
}

/** A monetary currency. */
export enum ECurrency {
  USD = 'USD',
  EUR = 'EUR',
  CHF = 'CHF',
}

/** The reason why a credit note is issued for an invoice. */
export enum EInvoiceCreditNoteReason {
  Value1 = -1,
  Value0 = 0,
  Value11 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
  Value5 = 5,
  Value6 = 6,
  Value7 = 7,
  Value10 = 10,
  Value112 = 11,
  Value12 = 12,
  Value13 = 13,
  Value14 = 14,
  Value19 = 19,
  Value20 = 20,
  Value21 = 21,
  Value22 = 22,
  Value23 = 23,
  Value24 = 24,
  Value25 = 25,
  Value26 = 26,
  Value29 = 29,
  Value30 = 30,
  Value31 = 31,
  Value32 = 32,
  Value33 = 33,
  Value34 = 34,
  Value39 = 39,
  Value40 = 40,
  Value41 = 41,
  Value42 = 42,
  Value43 = 43,
  Value44 = 44,
  Value45 = 45,
  Value46 = 46,
  Value47 = 47,
  Value48 = 48,
  Value49 = 49,
  Value59 = 59,
  Value63 = 63,
  Value72 = 72,
  Value73 = 73,
  Value74 = 74,
  Value75 = 75,
  Value76 = 76,
  Value77 = 77,
  Value78 = 78,
  Value79 = 79,
  Value80 = 80,
  Value81 = 81,
  Value999 = 999,
  Value1000 = 1000,
  Value99999 = 99999,
}

/** An invoice credit note, as seen by a buyer. */
export interface IBuyerInvoiceCreditNoteView {
  /**
   * Indicates when the credit note has been created/issued.
   * @format date-time
   */
  createdAt?: string;
  /** Unique reference ID for the credit note. */
  referenceId: string;
  /**
   * Unique reference number for the credit note.
   * "null" if no reference number is available.
   */
  referenceNumber: string;
  /**
   * Amount that is credited for the invoice.
   * @format double
   */
  amount: number;
  /** Currency of the credited amount. */
  currency: ECurrency;
  /** Indicates whether the credit note has been refunded to the original bank account. */
  isRefunded: boolean;
  /** The reason why the credit note has been issued. */
  reason: EInvoiceCreditNoteReason;
  /** Unique reference ID for the invoice linked to the credit note. */
  invoiceReferenceId: string;
  /**
   * Unique reference number for the invoice linked to the credit note.
   * "null" if no invoice is associated.
   */
  invoiceReferenceNumber: string;
  /**
   * Small description of the credit note purpose.
   * "null" if no description is available.
   */
  description: string;
  /** The URL to access the credit note PDF. */
  urlToPdf: string;
  /**
   * The auction associated with the credit note.
   * "null" if no auction is associated.
   */
  _fk_uuid_auction: string;
  /** The auction associated with the credit note (null if no auction is associated) */
  associatedAuction: IAuction;
}

export interface IPageIInvoice {
  items: IInvoice[];
  /** @format double */
  total: number;
  /** @format double */
  page: number;
  /** @format double */
  pageSize?: number;
}

/** The basic filter allowing to filter pages (offset, limit) and sort. */
export interface IGenericFilter {
  search?: string;
  /** @format double */
  offset?: number;
  /** @format double */
  limit?: number;
  includeDeleted?: boolean;
  sortBy?: {
    nullsLast?: boolean;
    desc: boolean;
    field: string;
  }[];
}

export interface IPageIBuyerInvoiceCreditNoteView {
  items: IBuyerInvoiceCreditNoteView[];
  /** @format double */
  total: number;
  /** @format double */
  page: number;
  /** @format double */
  pageSize?: number;
}

export interface BlacklistedBuyerAccount {
  /** @format date-time */
  createdAt: string;
  name: string;
  uuid: string;
}

export interface IAuctionAccountSellerSettings {
  blacklistedBuyerAccounts: BlacklistedBuyerAccount[];
}

/** The information of the saved search. */
export interface IAugmentedSavedSearchIdentifiers {
  /** The uuid identifier of the saved search. */
  uuid: string;
  /** The human-readable filter of the saved search (with only the first 3 filters on it). */
  readableFilter: string;
  /**
   * How many auctions the saved search filtered out.
   * @format double
   */
  auctionCount: number;
  /** The link of the saved search for the buyer. */
  linkToResults: string;
  /** The flag in which is decide to if the buyer choose to be notified or not about the results of the saved search. */
  shouldNotify: boolean;
  /** The date in which the saved search was created. */
  createdAt: string;
}

/** The information of the auction and vehicle that matched the filter on the saved search. */
export interface IAugmentedSavedSearchAuctionResult {
  /** The link of the auction detail for the buyer. */
  buyerLinkToAuction: string;
  /** The formatted auction object. */
  auction: {
    /** The exact date of when the auction was started. */
    startingTime: string;
    /** The exact date of when the auction will expire. */
    endingTime: string;
    /** The distance (in km) of the auction location fro mthe buyer location. */
    distanceFromBuyer: string;
    /** The label of the auction, consisting of the vehicle make, model and date of first registration (EZ). */
    label: string;
    /** The uuid identifier of the auction. */
    uuid: string;
  };
  /** The augmented object of the vehicle that is on auction. */
  associatedVehicle: {
    /** The model description of a vehicle. */
    model: string;
    /** The make of the vehicle, such as "Ford" or "Mercedes-Benz". */
    make: string;
    /** The image url of the vehicle. */
    picture: string;
    /** The globally unique Vehicle Identification Number (given out by the OEMs). */
    vin: string;
    /** The category of the vehicle. */
    category: string;
    /** The transmission type of the vehicle. */
    transmission: string;
    /** The fuel type of the vehicle. */
    fuelType: string;
    /** The date of first registration in the format "MM/YYYY". */
    ez: string;
    /** The mileage of the vehicle. */
    mileageInKm: string;
  };
}

/** The necessary fields to send notifications to buyers about their saved searches. */
export interface IAugmentedSavedSearchWithAuctions {
  /** The information of the saved search. */
  search: IAugmentedSavedSearchIdentifiers;
  /** The list containing the auctions and vehicle that matched the filter on the saved search. */
  results: IAugmentedSavedSearchAuctionResult[];
}

export interface IPageIAugmentedSavedSearchWithAuctions {
  items: IAugmentedSavedSearchWithAuctions[];
  /** @format double */
  total: number;
  /** @format double */
  page: number;
  /** @format double */
  pageSize?: number;
}

/** Data structure that is used to return results for resource counting queries. */
export interface IResourceCountResult {
  /** @format double */
  _count: number;
}

/**
 * The type of a balance transaction.
 *
 * PURCHASE, INVOICE and REFUND are negatively impacting the customer balance.
 *
 * FUNDS_TRANSACTION and CREDIT_NOTE are positively impacting the customer balance.
 */
export enum ECustomerBalanceTransactionType {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
}

/** A transaction impacting the balance (both in a negative or a positive way) of a customer. */
export interface ICustomerBalanceTransaction {
  /**
   * Date when the given transaction is effective (e.g. the invocation date of an invoice)
   * @format date-time
   */
  date: string;
  /** Label describing the balance transaction. */
  label: string;
  /** Optional detailed description. */
  description: string;
  /** Type of the balance transaction (i.e. whether it is an invoice, purchase of funds transaction). */
  type: ECustomerBalanceTransactionType;
  /**
   * The actual negative or positive gross amount of the transaction.
   * @format double
   */
  amount: number;
  /** Currency of the transaction. */
  currency: ECurrency;
}

/** Customer balance, being the aggregation of balance transactions. */
export interface ICustomerBalance {
  /** @format double */
  amount: number;
  /** A monetary currency. */
  currency: ECurrency;
  /** @format double */
  balanceAdjustmentValue: number;
  transactions: ICustomerBalanceTransaction[];
}

/** A bank account with a label (alias) and the bank account number (IBAN). */
export interface IBankAccount {
  uuid: string;
  /**
   * Indicating the time when the entity has been created, i.e. when the entity was
   * persisted to the database.
   * @format date-time
   */
  createdAt?: string;
  /**
   * Indicating the last time a value of the entity has been changed (updated).
   * @format date-time
   */
  updatedAt?: string;
  /**
   * Indicating the time when the entity has been (softly) deleted.
   * "null" if entry is not deleted.
   * @format date-time
   */
  deletedAt?: string;
  /** A label (alias) to identify the label. */
  label: string;
  /** The bank account number (IBAN) for the bank account. */
  iban: string;
}

/** Remaining funds for a buyer user. */
export interface IBuyerBalanceRemainingFunds {
  /**
   * Remaining funds that were transferred but could not be charged.
   * @format double
   */
  remainingFunds: number;
}

/** A credit note for an invoice. */
export interface IInvoiceCreditNote {
  /**
   * Indicates when the credit note has been created/issued.
   * @format date-time
   */
  createdAt?: string;
  /** Unique reference ID for the credit note. */
  referenceId: string;
  /**
   * Unique reference number for the credit note.
   * "null" if no reference number is available.
   */
  referenceNumber: string;
  /**
   * Amount that is credited for the invoice.
   * @format double
   */
  amount: number;
  /** Currency of the credited amount. */
  currency: ECurrency;
  /** Indicates whether the credit note has been refunded to the original bank account. */
  isRefunded: boolean;
  /** The reason why the credit note has been issued. */
  reason: EInvoiceCreditNoteReason;
  /** Unique reference ID for the invoice linked to the credit note. */
  invoiceReferenceId: string;
  /**
   * Unique reference number for the invoice linked to the credit note.
   * "null" if no invoice is associated.
   */
  invoiceReferenceNumber: string;
  /**
   * Small description of the credit note purpose.
   * "null" if no description is available.
   */
  description: string;
  /** The URL to access the credit note PDF. */
  urlToPdf: string;
  /**
   * The auction associated with the credit note.
   * "null" if no auction is associated.
   */
  _fk_uuid_auction: string;
}

export interface IPaymentFundsRefund {
  /**
   * Timestamp when the refund was executed.
   * @format date-time
   */
  createdAt?: string;
  /** The currency of the transaction amount. */
  currency: ECurrency;
  /**
   * The amount that was refunded.
   * @format double
   */
  amount: number;
  /** The description of the refund (if available). */
  description: string;
  /** External transaction identifier. */
  externalReference: string;
  /** Unique reference ID for the invoice linked to the credit note. */
  invoiceReferenceId: string;
}

/** Auctions with the invoices that affect the buyer balance */
export interface IBuyerBalanceAuctionView {
  /** The auction (also contains state and invoice for vehicle). */
  auction: IAuction;
  /** Invoices linked to this auction (CoS purchase fee, transportation fee, document shipping fee, etc...) */
  invoices: IInvoice[];
  /** Credit notes linked to this auction. */
  creditNotes: IInvoiceCreditNote[];
  /** Refunds linked to this auction. */
  refunds: IPaymentFundsRefund[];
}

export interface IPageIBuyerBalanceAuctionView {
  items: IBuyerBalanceAuctionView[];
  /** @format double */
  total: number;
  /** @format double */
  page: number;
  /** @format double */
  pageSize?: number;
}

/** Estimation for the fees and total financed amount for a Buy Now Pay Later order. */
export interface IBuyNowPayLaterEstimate {
  /** @format double */
  feeAmount: number;
  /** @format double */
  totalAmount: number;
}

/** The external providers of the Buy Now Pay Later. */
export enum EBuyNowPayLaterProvider {
  BILLIE = 'BILLIE',
}

/** Represents buyer data in the BuyNowPayLater billing information. */
export interface IBuyNowPayLaterBillingDataBuyerData {
  /** The first name of the user */
  firstName?: string;
  /** The last name of the user */
  lastName?: string;
  /** The phone number. */
  phoneNumber?: string;
  /** The email address. */
  email: string;
}

/** Represents addresses in the BuyNowPayLater billing information. */
export interface IBuyNowPayLaterBillingDataAddress {
  /** The country. */
  country: string;
  /** The street name. */
  streetName: string;
  /** The house number (optional). */
  houseNumber?: string;
  /** The zip code. */
  zipCode: string;
  /** The city. */
  city: string;
}

/** Represents buyer business data in the BuyNowPayLater billing information. */
export interface IBuyNowPayLaterBillingDataBusinessData {
  /** The business name. */
  name: string;
  /** The VAT ID. */
  vatId: string;
  /** The address. */
  address: IBuyNowPayLaterBillingDataAddress;
}

/** Represents an item in the BuyNowPayLater billing data, such as invoice details or fee information. */
export interface IBuyNowPayLaterBillingDataItem {
  /** The reference ID of the invoice. */
  invoiceReferenceId?: string;
  /** The URL of the invoice. */
  invoiceUrl?: string;
  /** The title or name of the item. */
  title: string;
  /**
   * The quantity of the item.
   * @format double
   */
  quantity: number;
  /** The amount details for the item. */
  amount: IBuyNowPayLaterAmount;
}

/** Represents the complete BuyNowPayLater billing data, including buyer information and item details. */
export interface IBuyNowPayLaterBillingData {
  /** The buyer information. */
  buyer: IBuyNowPayLaterBillingDataBuyerData;
  /** The buyer business information. */
  buyerBusiness: IBuyNowPayLaterBillingDataBusinessData;
  /** The delivery address. */
  deliveryAddress: IBuyNowPayLaterBillingDataAddress;
  /** The total amount. */
  totalAmount: IBuyNowPayLaterAmount;
  /**
   * The duration in days
   * @format double
   */
  durationInDays?: number;
  /** List of items for the BuyNowPayLater Order */
  items: IBuyNowPayLaterBillingDataItem[];
  /** The value of the buy now pay later fee */
  feeAmount: IBuyNowPayLaterAmount;
  /**
   * The rate used to calculate the fee for the BNPL order, expressed as a fraction, i.e. 0.0289 for 2.89%.
   * @format double
   */
  feeRate: number;
}

export interface IBuyNowPayLaterProvider {
  name: string;
  /** @format double */
  feeRate: number;
  /** The external providers of the Buy Now Pay Later. */
  provider: EBuyNowPayLaterProvider;
}

export interface IBuyNowPayLaterSessionWithBillingData {
  /** The provider session Id */
  id?: string;
  /** The type of BuyNowPayLater external provider. */
  provider: IBuyNowPayLaterProvider;
  /** Represents the complete BuyNowPayLater billing data, including buyer information and item details. */
  billingData: IBuyNowPayLaterBillingData;
}

/** Represents a Buy Now Pay Later order */
export interface IBuyNowPayLaterOrder {
  /** The uuid of the order. */
  uuid: string;
  /**
   * Indicating the time when the entity has been created, i.e. when the entity was
   * persisted to the database.
   * @format date-time
   */
  createdAt?: string;
  /**
   * Indicating the last time a value of the entity has been changed (updated).
   * @format date-time
   */
  updatedAt?: string;
  /**
   * Indicating the time when the entity has been (softly) deleted.
   * "null" if entry is not deleted.
   * @format date-time
   */
  deletedAt?: string;
  /** The correlation id of the order. Could be a uuid of an auction for example. */
  correlationId: string;
  /** The provider type used on the order. */
  provider: IBuyNowPayLaterProvider;
  /** The current state of the order */
  currentState: IBuyNowPayLaterOrderState;
  /**
   * the duration in days for the order (usually 30 or 60 days)
   * @format double
   */
  durationInDays: number;
  /** The total amount. */
  totalAmount: IBuyNowPayLaterAmount;
  /** The value of the buy now pay lader provider fee. */
  feeAmount: IBuyNowPayLaterAmount;
  /** The history of states of the order */
  stateHistory: IBuyNowPayLaterOrderState[];
  /** If order invoices were submitted to the provider */
  invoicesSubmitted: boolean;
  /** The link to the order within the buy now pay later provider. */
  providerOrderUrl: string;
  /** The order buy now pay later provider id. */
  providerOrderId: string;
  /**
   * Time when the BNPL Order due date is set.
   * @format date-time
   */
  dueDate: string;
}

/** The request body for creating Buy Now Pay Later orders, including only data the needs to be sent from the client (frontend or app). */
export interface IBuyNowPayLaterCreateOrderClientRequest {
  /** The buyer business information. */
  buyerBusiness: IBuyNowPayLaterBillingDataBusinessData;
  /** The delivery address. */
  deliveryAddress: IBuyNowPayLaterBillingDataAddress;
  /** The buyer information. */
  buyer: IBuyNowPayLaterBillingDataBuyerData;
  /** The total amount. */
  totalAmount: IBuyNowPayLaterAmount;
  /**
   * The duration in days
   * @format double
   */
  durationInDays?: number;
  /** List of items for the BuyNowPayLater Order */
  items: IBuyNowPayLaterBillingDataItem[];
  /** The value of the buy now pay later fee */
  feeAmount: IBuyNowPayLaterAmount;
  /**
   * The rate used to calculate the fee for the BNPL order, expressed as a fraction, i.e. 0.0289 for 2.89%.
   * @format double
   */
  feeRate: number;
  /** The provider */
  provider: EBuyNowPayLaterProvider;
  /** The session if used by the provider */
  session?: {
    /** The decline reason given by the Buy Now Pay Later provider if available; */
    declineReason?: EBuyNowPayLaterDeclineReason;
    /** The pre result given by the Buy Now Pay Later provider if available; */
    preResult?: EBuyNowPayLaterOrderStatus;
    /** The session Id */
    id: string;
  };
}

/** A summary of an order to be displayed within the buy now pay later balance view. */
export interface IBuyNowPayLaterBalanceOrder {
  /** The correlation id of the given order. */
  correlationId: string;
  /**
   * The creation date of the order.
   * @format date-time
   */
  createDate: string;
  /**
   * The total gross amount of the order.
   * @format double
   */
  amount: number;
  /** The current status of the order. */
  status: EBuyNowPayLaterOrderStatus;
  /** The decline reason of the order, if the order was declined. */
  declineReason: EBuyNowPayLaterDeclineReason;
}

/** The buy now pay later balance limits. */
export interface IBuyNowPayLaterBalanceLimits {
  /**
   * The amount that is currently available for new orders.
   * @format double
   */
  available: number;
  /**
   * The amount that is currently held up by pending orders.
   * @format double
   */
  pending: number;
  /**
   * The amount that is currently used for approved orders.
   * @format double
   */
  used: number;
}

/** The thresholds for the different approval chances for a given account and provider. */
export interface IBuyNowPayLaterApprovalChanceThresholds {
  /** @format double */
  undefined?: number;
}

/** {@link IBuyNowPayLaterBalance} but with the label of the order. */
export interface IBuyNowPayLaterBalanceWithLabel {
  /** The limits of the balance. */
  limits: IBuyNowPayLaterBalanceLimits;
  /** The last orders of the account, augmented with a label. */
  lastOrders: (IBuyNowPayLaterBalanceOrder & {
    label: string;
  })[];
  /** The thresholds for the different approval rates for a given account and provider. */
  approvalChanceThresholds: IBuyNowPayLaterApprovalChanceThresholds;
}

export interface IAuctionPaymentAmountItem {
  /** @format double */
  grossAmount: number;
  /** @format double */
  netAmount: number;
  /** @format double */
  discountedAmount: number;
  /** @format double */
  taxAmount: number;
  /** @format double */
  amountDue: number;
}

export interface IAuctionPaymentAmount {
  vehicle: IAuctionPaymentAmountItem;
  purchaseFee: IAuctionPaymentAmountItem;
  buyNowPayLater: IAuctionPaymentAmountItem;
  totals: IAuctionPaymentAmountItem;
}

export enum EPaymentLinkProvider {
  Plaid = 'plaid',
}

/** Interface used in the Http Response on Payment Link creation */
export interface IPaymentLinkCreationResponse {
  uuid: string;
  /** The provider that will be used to create the payment link */
  provider: EPaymentLinkProvider;
  /**
   * Object containing the required information in the response if the provider is {EPaymentLinkProvider.PLAID}
   *
   * Note: Despite it's an optional field, this always return value whenever the provider is {EPaymentLinkProvider.PLAID}
   */
  undefined?: {
    /**
     * The expiration date for the token
     * @format date-time
     */
    expiredAt: string;
    /** The link token generated */
    linkToken: string;
  };
}

export interface IBuyerCheckoutStatistics {
  readyToPay: {
    isPaymentOverdue: boolean;
    /** @format double */
    amount: number;
  };
  readyToPickup: {
    /** @format double */
    amount: number;
  };
  ongoingTransports: {
    /** @format double */
    amount: number;
  };
  finished: {
    /** @format double */
    lastMonthPercentage: number;
    /** @format double */
    lastWeekPercentage: number;
    /** @format double */
    amount: number;
  };
}

/** An order invoice augmented with abridged invoice data. */
export interface IOrderInvoiceWithData {
  uuid: string;
  /**
   * Indicating the time when the entity has been created, i.e. when the entity was
   * persisted to the database.
   * @format date-time
   */
  createdAt?: string;
  /**
   * Indicating the last time a value of the entity has been changed (updated).
   * @format date-time
   */
  updatedAt?: string;
  /**
   * Indicating the time when the entity has been (softly) deleted.
   * "null" if entry is not deleted.
   * @format date-time
   */
  deletedAt?: string;
  invoiceReference: string;
  /** Values indicating different articles (products) that invoices can be created for in COS. */
  invoiceArticle: EInvoicedArticle;
  chargeReference?: string;
  /**
   * A simplified view containing only the most essential information of an invoice.
   *
   * Mostly used to give an overview of the status and amount that needs to be paid.
   */
  data: IAbridgedInvoice;
}

/**
 * Describes an invoice for an order created on the P&I service.
 *
 * An order can have multiple invoices.
 */
export interface IOrderInvoice {
  uuid: string;
  /**
   * Indicating the time when the entity has been created, i.e. when the entity was
   * persisted to the database.
   * @format date-time
   */
  createdAt?: string;
  /**
   * Indicating the last time a value of the entity has been changed (updated).
   * @format date-time
   */
  updatedAt?: string;
  /**
   * Indicating the time when the entity has been (softly) deleted.
   * "null" if entry is not deleted.
   * @format date-time
   */
  deletedAt?: string;
  invoiceReference: string;
  /** Values indicating different articles (products) that invoices can be created for in COS. */
  invoiceArticle: EInvoicedArticle;
  chargeReference?: string;
}

export interface IOrderWithInvoiceDataAndPaymentInfo {
  uuid: string;
  /**
   * Indicating the time when the entity has been created, i.e. when the entity was
   * persisted to the database.
   * @format date-time
   */
  createdAt?: string;
  /**
   * Indicating the last time a value of the entity has been changed (updated).
   * @format date-time
   */
  updatedAt?: string;
  /**
   * Indicating the time when the entity has been (softly) deleted.
   * "null" if entry is not deleted.
   * @format date-time
   */
  deletedAt?: string;
  accountUuid: string;
  correlationId: string;
  paymentRequestUrl?: string;
  description: string;
  invoices: IOrderInvoiceWithData[];
  deposits: IOrderDeposit[];
  /** The possible statuses an order can be in. */
  status: EOrderStatus;
  /** @format date-time */
  payoutScheduledAt?: string;
  /** @format date-time */
  payoutPaidAt?: string;
  /** @format double */
  payoutDaysDelay?: number;
  /**
   * Related invoices are invoices that are not part of the order, but shared the same correlation ID as the order.
   *
   * Note: This is a temporary solution because we currently don't include all invoice types in the order,
   * but want to be able to display all invoices related to a purchase together with the order.
   */
  relatedInvoices: IAbridgedInvoice[];
  transferReference: string;
  /** @format double */
  amountDue: number;
  /** A bank account to use for funding an account. */
  paymentDetails: IFundingBankAccount;
}

/** The types of where the auction discount can be applied. */
export enum EAuctionDiscountType {
  FEE = 'FEE',
}

/** The current reasons of a discount that an auction can have applied at the moment. */
export enum EAuctionDiscountReason {
  REGULAR_BUYER = 'REGULAR_BUYER',
  PRIVATE_SELLER = 'PRIVATE_SELLER',
  STRATEGIC_SELLER = 'STRATEGIC_SELLER',
  FREQUENT_BUYER_PROGRAM = 'FREQUENT_BUYER_PROGRAM',
}

/** Defines what type of the value that a discount can have for an auction. */
export enum EAuctionDiscountValueType {
  ABSOLUTE = 'ABSOLUTE',
  RELATIVE = 'RELATIVE',
}

/** The discount that an auction can have. */
export interface IAuctionDiscount {
  /**
   * The value that the discount is removing.
   * @format double
   */
  value: number;
  /** Where the discount can be applied. */
  type: EAuctionDiscountType;
  /** The reason of the discount that can be applied. */
  reason: EAuctionDiscountReason;
  /** Type of the value of the discount. */
  valueType: EAuctionDiscountValueType;
  /** Optional label that can be used to show a visual representation of the discount. */
  label?: string;
}

export enum EPaymentLinkStatus {
  INITIAL = 'INITIAL',
  CREATED = 'CREATED',
  PENDING = 'PENDING',
  FAILED = 'FAILED',
  FINISHED = 'FINISHED',
  CANCELLED = 'CANCELLED',
}

export interface IBuyerAuctionViewWithOrder {
  /**
   * @deprecated
   * @format double
   */
  id: number;
  uuid: string;
  /**
   * Indicating the time when the entity has been created, i.e. when the entity was
   * persisted to the database.
   * @format date-time
   */
  createdAt?: string;
  /**
   * Indicating the last time a value of the entity has been changed (updated).
   * @format date-time
   */
  updatedAt?: string;
  /**
   * Indicating the time when the entity has been (softly) deleted.
   * "null" if entry is not deleted.
   * @format date-time
   */
  deletedAt?: string;
  /** Stores which type of additional tax applies to the auction if any. */
  additionalTaxType: EAdditionalTaxType;
  /**
   * Stores the value of the additional tax that might apply to an auction.
   * @format double
   */
  additionalTaxValue: number;
  /**
   * Stores the export discount for taxes.
   * @format double
   */
  additionalTaxExportDiscount: number;
  state: EAuctionState;
  /** @format date-time */
  endingTime: string;
  /** @format double */
  remainingTimeInSeconds: number;
  /** @format double */
  remainingTimeForInstantPurchaseInSeconds: number;
  /** @format date-time */
  instantPurchasePossibleUntil: string;
  _fk_uuid_highestBiddingBuyerUser: string;
  /** @format double */
  currentHighestBidValue: number;
  /** @format double */
  currentHighestBidValueNet: number;
  /** @format double */
  minimumRequiredAsk: number;
  /** @format double */
  minimumRequiredAskNet: number;
  /** @format double */
  numBids: number;
  /** @format double */
  buyerRecommendationScore?: number;
  /** Was this auction created with the help of an inspection done or commissioned by CarOnSale? */
  inspectionWasCommissionedByCos: boolean;
  /** By which type of user was this auction created. */
  creatorType: EAuctionCreatorType;
  /** UUID of the creating internal user if there is one. */
  _fk_uuid_creatingInternalUser: string;
  /** UUID of the creating seller user if there is one. */
  _fk_uuid_creatingSellerUser: string;
  /**
   * Indicates if the auction was created for testing purposes and
   * should be handled differently: it should not be ended automatically,
   * so the seller should decide would he like to sell it or not.
   */
  isTest: boolean;
  /** Indicates if minimum required ask should be displayed for buyers. */
  displayMinAsk: boolean;
  /**
   * GPS coordinates of the auction (can be used for e.g. calculating distances between auctions and buyers).
   * @format double
   */
  locationGeoLat: number;
  /** @format double */
  locationGeoLon: number;
  /** @format double */
  counterOfferByBuyer: number;
  /** @format double */
  counterOfferByBuyerNet: number;
  /** @format double */
  lastOfferBySeller: number;
  /** @format double */
  lastOfferBySellerNet: number;
  /**
   * A value denoting the previous renegotiation offer given by a buyer user.
   * @format double
   */
  previousCounterOfferByBuyer: number;
  /** @format double */
  previousCounterOfferByBuyerNet: number;
  /**
   * A value denoting the previous renegotiation offer given by a seller user.
   * This is required in order to properly display the right information during the renegotiation process.
   * @format double
   */
  previousLastOfferBySeller: number;
  /** @format double */
  previousLastOfferBySellerNet: number;
  /** A flag for if the seller user connected to the auction has decided not to participate in the renegotiation any further. */
  renegotiationStoppedBySeller: boolean;
  /** A flag for if the highest bidding buyer user connected to the auction has decided not to participate in the renegotiation any further. */
  renegotiationStoppedByBuyer: boolean;
  /** A fag indicating if the buyer's withdrawal from the renegotiation process was done automatically or not. */
  wasRenegotiationStoppageByBuyerAutomatic: boolean;
  /** A fag indicating if the seller's withdrawal from the renegotiation process was done automatically or not. */
  wasRenegotiationStoppageBySellerAutomatic: boolean;
  /**
   * The total number of offer rounds a buyer has participated in for an auction during the renegotiation phase.
   * @format double
   */
  numBuyerRenegotiationOfferRounds: number;
  /**
   * The total number of offer rounds a seller has participated in for an auction during the renegotiation phase.
   * @format double
   */
  numSellerRenegotiationOfferRounds: number;
  /**
   * The calculated renegotiation midpoint value of the auction.
   * This is used once the renegotiation offers get too close for the offer process to continue.
   * This value will be undefined unless the auction is ready to be purchased for such a value.
   * @format double
   */
  renegotiationMidpointValue: number;
  /** @format double */
  renegotiationMidpointValueNet: number;
  /**
   * Indicates that the highest bidding buyer user for an auction in renegotiation is willing to accept the midpoint of their offer and
   * the offer of the seller for the same auction. This is used when the renegotiation offers get very close during the renegotiation process.
   */
  buyerAcceptedRenegotiationMidpoint: boolean;
  /**
   * Indicates that the seller of an auction is willing to accept the midpoint of their offer and the offer of the highest bidding
   * buyer user for the same auction. This is used when the renegotiation offers get very close during the renegotiation process.
   */
  sellerAcceptedRenegotiationMidpoint: boolean;
  /**
   * This is the datetime object denoting when the most recent renegotiation round concluded at.
   * @format date-time
   */
  lastRenegotiationRoundEndedAt: string;
  /**
   * The number of seconds until the current renegotiation round times out.
   * @format double
   */
  numSecondsUntilRenegotiationTimeout: number;
  /**
   * The UUID of the external case tracking the renegotiation of the auction.
   * We track this to enable case updates as the auction moves through renegotiation.
   */
  externalRenegotiationTicketUuid: string;
  /**
   * The calculated renegotiation fair value of the auction.
   * This is used once the renegotiation starts based on the auction GPR or MPR so we know
   * the fair value that both seller and buyer should agree upon.
   * @format double
   */
  renegotiationFairValue: number;
  /**
   * Value that is indicating the highest bid value on the auction when it ended.
   * This value should not change for a given auction since it supposed to be a historical record.
   * @format double
   */
  highestBidValueAtEndingTime: number;
  /** @format double */
  highestBidValueAtEndingTimeNet: number;
  /** Field data related to the renegotiation round process. */
  renegotiationRoundData?: IRenegotiationRoundData;
  /**
   * Indicates when manual renegotiation was finished via Salesforce.
   * @format date-time
   */
  finishedManuallyAt?: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing service (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the transfer of funds
   * for the vat deposit from COS to the deposit account (only relevant for cross border transactions).
   * This deposit is refunded by COS once the buyer provides the necessary documentation for a cross border transaction.
   */
  thirdPartyVATDepositTransferReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing service (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the transfer of funds
   * for the additional tax deposit from COS to the deposit account (only relevant for cross border transactions).
   * This deposit is refunded by COS once the buyer provides the necessary documentation for a cross border transaction.
   */
  thirdPartyAdditionalTaxDepositTransferReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing system (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the transfer of funds
   * for the auction additional taxes (e.g country specific luxury taxes): from COS to the seller.
   */
  thirdPartyAdditionalTaxTransferReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing service (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the transfer of funds
   * for the VAT: from COS to the seller (only relevant for cross border transactions for now).
   * COS is advancing the vat amount to the seller.
   */
  thirdPartyVatTransferReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing service (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the charge of funds
   * for the auction volume from the buyer to COS.
   */
  thirdPartyVolumeChargeReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing service (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the charge of funds
   * for the auction deposit from the buyer to COS (only relevant for cross border transactions for now).
   * This deposit is refunded by COS once the buyer provides the necessary documentation for a cross border transaction.
   */
  thirdPartyVATDepositChargeReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing service (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the charge of funds
   * for the auction additional tax (e.g country specific luxury tax) from the buyer to COS.
   */
  thirdPartyAdditionalTaxChargeReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing system (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the refund of an
   * additional tax deposit (only used for cross-border transactions currently).
   */
  thirdPartyAdditionalTaxRefundReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing system (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the payout of funds.
   */
  thirdPartyPayoutReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing system (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the refund of a
   * deposit (only used for cross-border transactions currently).
   */
  thirdPartyVATDepositRefundReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing system (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the refund of a
   * transfer to the deposit account owned by cos (only used for cross-border transactions currently).
   */
  thirdPartyVATDepositReversalReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing system (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the refund of the
   * additional tax transfer to the deposit account owned by cos (only used for cross-border transactions).
   */
  thirdPartyAdditionalTaxDepositReversalReference: string;
  /**
   * Reference to the invoice for the success fee issued to the seller for a sold vehicle (see ESellerFeeInvoicingMode.INVOICE_ON_SUCCESS).
   * The reference identifies the invoice issued to the seller in an external invoicing system (e.g. Stripe).
   *
   * This fee is not always charged, therefore this reference is optional (can be null).
   */
  sellerSuccessFeeInvoiceReference: string;
  /**
   * Reference to the invoice for the listing fee issued to the seller for a listed vehicle (see ESellerFeeInvoicingMode.INVOICE_ON_LISTING).
   * The reference identifies the invoice issued to the seller in an external invoicing system (e.g. Stripe).
   *
   * This fee is not always charged, therefore this reference is optional (can be null).
   */
  sellerListingFeeInvoiceReference: string;
  /**
   * Reference to the invoice for the standing fee issued to the buyer for a purchased vehicle picked up too late.
   *
   * This fee is not always charged, therefore this reference is optional (can be null).
   */
  buyerStandingFeeInvoiceReference: string;
  /**
   * Reference to a resource in an external invoicing system (such as Stripe).
   * References our own transaction fee invoice.
   */
  invoiceReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing system (such as Stripe).
   * This is only non-null, if external payment is allowed for this auction.
   */
  thirdPartyInvoiceReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing system.
   * This is only non-null, if external payment is allowed for this auction and it had to be cancelled.
   */
  thirdPartyCorrectionInvoiceReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing system (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the transfer of funds
   * for the auction volume: from COS to the seller.
   */
  thirdPartyTransferReference: string;
  /**
   * Reference of an invoice on an external invoicing system.
   * This is invoice is used to charge booked services (like guarantee) after the auction expires.
   */
  bookedServicesInvoiceReference: string;
  /**
   * Date-time when the auction was reauctioned and is now waiting for buyer payment until the new auction gets paid.
   * @format date-time
   */
  reauctionedWaitingForBuyerPaymentAt: string;
  /**
   * Date-time when the first reauction of this auction has expired.
   * We need to keep track of this so we can block the original buyer of seeing the original auction.
   * @format date-time
   */
  firstReauctionExpiredAt: string;
  /** Flag that indicates if this auction is a clonned reauction. */
  isReauction: boolean;
  /**
   * The highest bid value that we show to the seller when we merge the reauction with the original auction.
   * This is done so the seller see the amount that cos pay them and the buyer sees the amount that they paid cos.
   * @format double
   */
  sellerViewHighestBidValue: number;
  /**
   * The purchase confirmed at that we show to the seller when we merge the reauction with the original auction.
   * This is done so the seller see the date when the original auction was confirmed and the buyer sees the date that the reauction was confirmed.
   * @format date-time
   */
  sellerViewPurchaseConfirmedAt: string;
  /**
   * Date-time when the reauction was merged back at the original auction.
   * @format date-time
   */
  reauctionedMergedAt: string;
  /** Indicates wherever an auction was purchase using the Buy Now Pay Later feature. */
  wasPurchasedUsingBuyNowPayLater: boolean;
  /**
   * The timestamp at which the buyer has confirmed his choice to use Buy Now Pay Later for the auction.
   * @format date-time
   */
  buyNowPayLaterConfirmedAt: string;
  /** The IP address which confirmed the Buy Now Pay Later choice for the auction. */
  buyNowPayLaterConfirmationIPAddress: string;
  /** Reference to the Buy Now Pay Later Order on the PNI Service. */
  buyNowPayLaterOrderReference: string;
  /** Reference of the invoice for buy now pay later provider fee on an external invoicing system. */
  buyNowPayLaterInvoiceReference: string;
  /** The payment link reference */
  paymentLinkReference: string;
  /** Reference to the payment order on the P&I service */
  paymentOrderReference: string;
  isInternalFulfillment: boolean;
  belowMinAskBehaviour: EAuctionBelowMinAskBehaviour;
  /** The label of the auction, consisting of the vehicle make, model and date of first registration (EZ). */
  label: string;
  /**
   * Date+time when the auction is started.
   * When an auction is started right away (without drafting), the "createdAt" and "startedAt" should have
   * (roughly) the same values.
   * @format date-time
   */
  startedAt: string;
  /**
   * Date+time when the auction is "confirmed to be sold" by the seller (or by an admin).
   * If an auction is ending equal or above the minimum required ask, the "purchaseConfirmedAt"
   * will automatically be set and will be equal to the "endingTime".
   *
   * If an auction is ending below the minimum ask (CLOSED_UNDER_MINIMUM_ASK), the "purchaseConfirmedAt" timestamp
   * is only set if the auction is then manually "confirmed" by the seller or an admin.
   *
   * If an auction is never "successful", the "purchaseConfirmedAt" will remain "null".
   *
   * This value was introduced in the course of 2019. For all previous auctions, the endingTime was set
   * as the "purchaseConfirmedAt" retrospectively.
   * @format date-time
   */
  purchaseConfirmedAt: string;
  /**
   * Date-time when the auction has definitely been rejected by a seller or an admin, after the auction
   * ended below minimum required ask.
   *
   * This value is only available since 2019/07/29. All auctions rejected before that date have the value "null".
   * @format date-time
   */
  purchaseRejectedAt: string;
  /** Indicates the reason that an auction failed the renegotiation process. */
  rejectionReason: EAuctionRejectionReason;
  /**
   * Elaborates on the rejection reason to provide further context to why an
   * auction was rejected during the renegotiation process.
   */
  rejectionReasonNote: string;
  /**
   * The name of the competitor that the auction was sold to when the reject chosen reason was
   * VEHICLE_SOLD_ON_DIFFERENT_PLATFORM
   */
  rejectionReasonCompetitorName: string;
  /** In case if there was a direct upload requested but didn't succeed the reason should be stored. */
  directUploadRejectionReasons: EAuctionDirectUploadRejectionReason[];
  /**
   * Value that is indicating initial bid value auction should be started with.
   * @format double
   */
  startingBidValue: number;
  /** @format double */
  startingBidValueNet: number;
  /**
   * Value that can be manually set in case edge-cases apply and the payout amount is disconnected from the auction current highest bid.
   * This value can only be adjusted by internal users.
   * @format double
   */
  overridePayoutValue: number;
  /**
   * If an auction is ending below the minimum required ask, the "originalMinimumRequiredAsk" will be set to the
   * "minimumRequiredAsk" value before it is set to "currentHighestBidValue".
   * @format double
   */
  originalMinimumRequiredAsk: number;
  /** @format double */
  originalMinimumRequiredAskNet: number;
  /**
   * The purchase price for the vehicle that is auctioned.
   * This is set manually by the seller, and is mostly used to analyze the "real" margin that a seller
   * makes with an auction.
   * @format double
   */
  purchasePrice: number;
  /** @format double */
  purchasePriceNet: number;
  /**
   * The full object of the vehicle that is on auction.
   * This is augmented on every auction retrieval, based on _fk_uuid_vehicle.
   */
  associatedVehicle: IVehicle;
  /** Indicates whether the auction process is rated by the SellerUser. */
  isRatedByDealership: boolean;
  /** Indicates whether the auction process is rated by the BuyerUser. */
  isRatedByBuyer: boolean;
  /**
   * Indicates whether the BuyerUser has marked this auction as paid (payment of the auction volume).
   * This is done manually by the BuyerUser, so this value is not indicating that the actual payment has really
   * reached the seller.
   */
  isPaidByBuyer: boolean;
  /**
   * Timestamp when the BuyerUser has marked this auction as "paid" (i.e. set the "isPaidByBuyer").
   * @format date-time
   */
  outgoingPaymentConfirmedAt: string;
  /**
   * Timestamp when the SellerUser has confirmed that the payment has reached the seller bank account.
   * @format date-time
   */
  incomingPaymentConfirmedAt: string;
  /**
   * Timestamp when the auction state has changed from "CLOSED_WAITING_FOR_PAYMENT" to "CLOSED_WAITING_FOR_PICKUP".
   * @format date-time
   */
  pickupConfirmedAt: string;
  /** Physical address of where the vehicle on auction is located (a.k.a. pickup address). */
  locationCountryCode: ECountryCode;
  locationAddress: string;
  locationCity: string;
  locationZip: string;
  /** Physical address of where the vehicle on auction was inspected (a.k.a. inspection address). */
  inspectionLocationCountryCode: ECountryCode;
  inspectionLocationAddress: string;
  inspectionLocationCity: string;
  inspectionLocationZip: string;
  /**
   * Indicating to which bank account the BuyerUser has to transfer the auction volume to.
   * @deprecated
   */
  sellerIban: string;
  /**
   * URL to the seller invoice for the given auction, manually uploaded by the SellerUser, after
   * the auction has ended successfully.
   */
  urlToInvoice: string;
  /** URL for the cancelled invoice in case it was cancelled. */
  urlToCorrectionInvoice: string;
  /**
   * Flag indicating whether an auction has an "hot bid" phase in the last 20 seconds before expiration.
   * @deprecated
   */
  hotBid: boolean;
  /**
   * Seller's instant purchase price for the vehicle.
   * For the first 24 hours after the auction is started, the vehicle will only be available in "instant purchase"
   * mode (bidding is not allowed in that time, only purchasing right away).
   *
   * After 24 hours, if nobody purchased the vehicle, it is going on auction, just like all other auctions.
   * @format double
   */
  instantPurchasePrice: number;
  /** @format double */
  instantPurchasePriceNet: number;
  /**
   * Flag indicating that instant purchase is enabled for that auction.
   * (Implies that "instantPurchasePrice" is set)
   */
  allowInstantPurchase: boolean;
  /**
   * Flag indicating that an auction "ended" with an instant purchase, resp.
   * did never go on auction.
   */
  didEndWithInstantPurchase: boolean;
  /**
   * Internal counter, incrementing on every "restart" of an auction.
   * Starts with 0.
   *
   * e.g. count of 2 means that the auction has been restarted 2 times, leading to 3 auction objects in the
   * database.
   * @format double
   */
  auctioningIterations: number;
  /**
   * Indicating the strategic priority of an auction for CarOnSale.de
   * The higher the number, the more "relevant" the auction is.
   * @format double
   */
  priority: number;
  /** @deprecated */
  advertisementHtmlContent: string;
  /** @deprecated */
  buyerComplaint: EAuctionBuyerComplaint;
  /**
   * Custom field that is not used internally.
   * Allows sellers to store e.g. a reference ID to their own vehicle management system.
   */
  internalReferenceText: string;
  _fk_uuid_vehicle: string;
  _fk_uuid_sellerUser: string;
  /** This is a URL that redirects to an external invoice payment site (such as provided by Stripe). */
  urlToPaymentSite: string;
  /**
   * A flag indicating if an auction needs to be reviewed by an internal review agent (for quality assurance)
   * If true, an auction is currently under review by CarOnSale, and cannot be edited by the owning seller user.
   */
  needsReview: boolean;
  /** The reasons why an auction was rejected during the review. */
  reviewReasons: EAuctionReviewReason[];
  /**
   * Reasons why an auction is rejected during the review process ("Parkhaus").
   *
   * This reason is automatically set to UNKNOWN for all auctions and is then manually adjusted by
   * an internal review agent (in case the auction gets rejected) before it is sent back to the responsible
   * SellerUser.
   * @deprecated
   */
  reviewReason: EAuctionReviewReason;
  /** Specifies reason when "reviewReasons" contains EAuctionReviewReason.OTHER. */
  reviewReasonDetails: string;
  /** The comment given by an internal review agent on rejecting an auction under review. */
  reviewComment: string;
  /** Flag indicating if the auction can be published without review. */
  canSkipReview?: boolean;
  /**
   * Random PIN code that is generated when an auction is ready to be picked up or in advance before COSCheck inspection.
   * This PIN code is printed out on the hangar in the vehicle or handed to the seller of the auction, which can then forward it to
   * the buyer to confirm the pickup.
   */
  pickupPinCode: string;
  /**
   * Random UUID that is used to confirm vehicle pickup via scanning QR code.
   * It will be printed on the hangar or in the pickup document available for sellers.
   */
  pickupPinUuid: string;
  /**
   * Timestamp when PIN code was entered by the buyer.
   * Allows to determine how many auctions were self-picked up.
   * @format date-time
   */
  pickupPinCodeEnteredAt: string;
  /**
   * Timestamp when PIN UUID was entered by the buyer via scanning QR code.
   * Allows to determine how many auctions were self-picked up.
   * @format date-time
   */
  pickupPinUuidEnteredAt: string;
  /** Flag is indicating if pickup was confirmed with PIN using new process (via pin.caronsale.de) */
  pickupConfirmedWithNewPin: boolean;
  /** URL to the pickup information document for the BuyerUser. */
  urlToPickupBuyerDocument: string;
  /** URL to the pickup information document for the SellerUser (containing the "pickupPinCode"). */
  urlToPickupSellerDocument: string;
  /** URL to the success report document for the SellerUser. */
  urlToSellerSuccessReport: string;
  /** The payment process that is used to pay money for the purchase volume (auction volume). */
  paymentProcess: EAuctionPaymentProcess;
  /**
   * Referencing the bank account to which the payment for the purchase volume should be paid out.
   * This is null, if the paymentProcess === "CLASSIC".
   *
   * It is only used, when external payment (COS Payment) is enabled.
   */
  _fk_uuid_associatedBankAccount: string;
  /**
   * Indicates how often an auction has been reviewed.
   * This is incremented every time an auction is rejected after a review.
   * @format double
   */
  draftReviewIterations: number;
  /**
   * Timestamp when an admin resets a time window for a possibility to complain
   * @format date-time
   */
  complaintTimeWindowExtendedAt: string;
  /**
   * Flag indicates if this auction is suspected to have been paid in cash (only for auctions that use the external
   * invoice process), this means that no corresponding transfer exists on Stripe and the buyer confirmed the pickup
   * through the seller pin code.
   */
  isSuspectedCashPayment: boolean;
  /**
   * Timestamp when the auction was sent to review.
   * @format date-time
   */
  reviewStartedAt: string;
  /**
   * Timestamp when the auction QA process actually started (i.e. when Parkhaus started reviewing the auction).
   * @format date-time
   */
  qaStartedAt: string;
  /**
   * Timestamp when the auction was ready to be reinserted (a.k.a. restarted).
   * @format date-time
   */
  readyToBeReinsertedSince: string;
  /** The reference to the original auction from which current one was cloned. */
  _fk_uuid_clonedFrom: string;
  /** Auction type. */
  type: EAuctionType;
  /** Indicates whether the rejection wait period for this auction is over and thus can be rejected by the seller. */
  isRejectionWaitPeriodOver: boolean;
  /** Reference to invoice object for the external Billomat invoicing provider. */
  thirdPartyVehiclePurchaseInvoiceReference: string;
  /**
   * URL to the seller made invoice invoicing CoS for the given auction, manually uploaded to the crm by an internal user, after
   * the auction has ended successfully.
   */
  sellerToCosInvoiceUrl: string;
  /**
   * Timestamp for when the sellerToCosInvoiceUrl was last updated.
   * @format date-time
   */
  sellerToCosInvoiceUrlLastUpdatedAt: string;
  /**
   * Timestamp for when the sellerToCosInvoiceUrl is first uploaded.
   * @format date-time
   */
  sellerToCosInvoiceUrlFirstUploadedAt: string;
  /** Flag indicating wherever the uploaded seller to cos invoice was verified by an internal user. */
  sellerToCosInvoiceIsValid: boolean;
  /** The comment given by an internal user on the provided seller to cos invoice. */
  sellerToCosInvoiceComment: string;
  /** Shows if auction is marked as 'live', so, it will be slotted during starting and expire in less than 24 hours. */
  isLive: boolean;
  /** Shows if auction was already slotted via the slotting algorithm. */
  isSlotted: boolean;
  /**
   * The exact date+time when the auction was slotted to end.
   * @format date-time
   */
  slottedEndingTime: string;
  /** Indicating if transportation should be disabled manually. */
  isTransportationDisabledManually: boolean;
  /** Optional pickup instructions that will be communicated to the buyer. */
  pickupInstructions: string;
  /** Indicating wherever the seller should be charged a success/listing fee. */
  preventSellerFactoring: boolean;
  /** Indicating if buyer success fee should be applied or not. */
  applyBuyerSuccessFee: boolean;
  /** Info from document shipping service about the order, like status and links to the documents. */
  documentShippingOrder: IAuctionDocumentShippingOrder;
  /** Info about un-/confirmed auction data points. */
  fieldsConfirmationStatus: IAuctionFieldConfirmationStatusMap;
  /**
   * Whether the "Value Added Tax is reportable", a special tax condition in the EU.
   * If this is true, it means that the vehicle is "Regelbesteuert", meaning that the invoice for the vehicle
   * (either invoiced directly from seller to buyer - or from COS Payment to buyer) needs to include
   * the VAT (in Germany e.g. 19%) in the invoice.
   *
   * If this is false, the invoice does not need to have explicit VAT.
   */
  isVATReportable: boolean;
  /** Indicates how the auction was uploaded to the marketplace. */
  uploadMethod: EAuctionUploadMethod;
  /** Prebooked services available for the auction, like transport, warranty. */
  prebookedServices: IPrebookedService[];
  /** Indicates if cosCheckPlus guarantee can be booked for this auction. */
  isCosCheckPlusGuaranteeEnabled: boolean;
  /** Indicates if extended guarantee can be booked for this auction. */
  isExtendedGuaranteeEnabled: boolean;
  /** URL to the auction vehicle cover letter for sellers */
  urlToCoverLetter: string;
  /**
   * Timestamp when the automatic pickup approval was requested for auction
   * @format date-time
   */
  autoPickupOnPaymentRequestedAt: string;
  /**
   * This means that the auctions is using complimentary vehicle feature.
   *
   * Note: This is marked as true when the seller uses one of his complimentary vehicles to pay for the fees.
   */
  isSellerFeeComplimentary: boolean;
  /** Flag when standing fee is enabled to the auction and we'll need to extra charge a buyer as a penalty. */
  isStandingFeeEnabled: boolean;
  _fk_uuid_auctionRoom: string;
  /**
   * An auction can have a list of fulfillment criteria that impact how the
   * auction will be fulfilled depending on the buyer that wins it.
   */
  fulfillmentCriteria?: IAuctionFulfillmentCriteria[];
  /**
   * The auction marked with this flag won't start automatically after review.
   *
   * Instead, it will transition to state: READY_TO_BE_PUBLISHED.
   *
   * Default: false
   */
  shouldWaitToBePublishedAfterReview: boolean;
  /** A flag that marks if an auction is a selective ware or not. */
  isSelectiveWare: boolean;
  /** The id for the look and feel applied to the auction. */
  lookAndFeelId: EAuctionLookAndFeelId;
  /** A flag that marks if an auction will use the pickup qr code. */
  isQRCodeAvailable: boolean;
  /**
   * Indicates whether the highest bid should be transferred if this auction gets cloned.
   * Defaults to `false` if not specified.
   */
  shouldTransferHighestBid?: boolean;
  recommendationId?: string;
  /** Indicates whether the buyer is currently the highest bidder for the auction. */
  amIHighestBidder: boolean;
  /** This flag indicates whether the minimumRequiredAsk has already been reached. */
  isMinAskReached: boolean;
  /** @deprecated */
  isWatching: boolean;
  /**
   * This property contains the value of the current bidding agent, set by the buyer.
   * Optional, if it's not set it means that no bidding agent is set.
   * @format double
   */
  biddingAgentValue?: number;
  /** @format double */
  biddingAgentValueNet?: number;
  /**
   * A bidding agent could be set, but temporarily not active (for example, if the buyer is blocked of bidding).
   * This flag indicates whether the bidding agent is active or not.
   * Optional, if it's not set it means that no bidding agent is set.
   */
  biddingAgentActive?: boolean;
  /**
   * Indicates whether the given auction is a cross-border net sale transaction for the buyer looking at it.
   * This is the case if the vehicle is "regelbesteuert" and sold from a country that is different from the buyer's country.
   */
  isCrossBorderNetSale: boolean;
  /**
   * Purchase fee that is charged to the buyer on success of the auction.
   * This is the variable transaction fee, which is calculated based on bucket pricing.
   *
   * This value is augmented, calculated dynamically based on the highest bid value.
   *
   * Discounts are already applied
   * @format double
   */
  buyerPurchaseFee: number;
  /**
   * Purchase fee that is charged to the buyer on success of the auction.
   * This is the variable transaction fee, which is calculated based on bucket pricing.
   *
   * This value is augmented, calculated dynamically based on the highest bid value.
   *
   * Discounts are not applied yet
   * @format double
   */
  buyerPurchaseFeeBeforeDiscounts: number;
  /** A list with all the discounts that are currently applied to the auction. */
  appliedDiscounts?: IAuctionDiscount[];
  /**
   * Fee that should be charged to the buyer in case the auction is successful (fee will be added to purchase fee invoice).
   * This value is augmented, based on the fee that is configured on the seller account.
   * @format double
   */
  buyerSuccessFee: number;
  /**
   * Total fee or discount for cross-border sale transactions.
   * This is a calculated field based on the Export Discount and Export Handling Fee
   * @format double
   */
  buyerCrossBorderProcessingAmount: number;
  /**
   * Indicates the amount of VAT tax that applies to the auction at current highest bid.
   * @format double
   */
  vatAmount: number;
  /**
   * VAT rate that applies to this auction.
   * @format double
   */
  vatRate: number;
  /**
   * End of the time that a user has to make a complaint after a self-pickup or transport booking process.
   * @format date-time
   */
  complaintPeriodEndAt: string;
  /**
   * End of the time available that a user can book a transportation.
   * @format date-time
   */
  transportBookingPeriodEndAt: string;
  /** The state of the buy now pay later order */
  buyNowPayLaterOrderState?: IBuyNowPayLaterOrderState;
  /** The value of the buy now pay later fee */
  buyNowPayLaterFee?: IBuyNowPayLaterAmount;
  /** The link to the order within the buy now pay later provider. */
  buyNowPayLaterProviderOrderUrl?: string;
  paymentLinkStatus?: EPaymentLinkStatus;
  /** A flag that represents either or not if the auction was paid externally. */
  wasExternallyPaid?: boolean;
  /** A flag that represents either or not if the auction was delivered externally. */
  wasExternallyPickedUp?: boolean;
  /**
   * Indicates whether the buyer has bid at least once in an auction.
   *
   * Either true or false, but not null.
   */
  didIBidAtLeastOnce: boolean;
  /**
   * Indicates whether the buyer has this auction in the parking lot.
   *
   * Either true or false, but not null.
   */
  isParked: boolean;
  /**
   * Indicates whether this auction is recommended based on the buyers activity.
   *
   * Either true or false, but not null.
   */
  isRecommended: boolean;
  /**
   * Indicates whether the buyer is assigned as a regular buyer to the seller account that
   * is owning that auction.
   *
   * Either true or false, but not null.
   */
  amIRegularBuyer: boolean;
  /**
   * Seller account offering this auction.
   * The account data is displayed partially for buyers depending on the Buyer and the Auction state.
   *
   * The attributes are the same from ISellerAccount (this could be a Partial<ISellerAccount>,
   * but it's not due to limitations of backend libraries with Utility Types)
   */
  sellerAccount: {
    isCompoundEnabled?: boolean;
    shouldApplyStandingCosts?: boolean;
    name?: string;
    uuid?: string;
  };
  /** If available, contains the rating for the auction (given from buyer to seller) */
  rating: IRating;
  /** This property is indicating the business type of the selling user. */
  sellerType: ESellerBusinessType;
  /**
   * If visible to the buyer (e.g. after a successful auction), this contains the contact information about
   * the seller for the auction.
   *
   * null if auction is not yet expired.
   * Not null if buyer has "won" the auction.
   */
  sellerContact: ISellerUser;
  /**
   * This field contains the associated transportation task that was booked by the buyer.
   * "null" if no task has been booked.
   */
  transportationTask: ITransportationTask;
  /**
   * This flag should indicate whether an external payment service (i.e. CarOnPay)
   * can be used to facilitate the payment.
   */
  isExternalPaymentAllowed: boolean;
  /** This flag indicates whether transport has to be booked through CarOnSale. */
  enforceTransportation: boolean;
  /**
   * This is a countdown, being set if an auction is waiting for payment.
   * After a certain amount of days without payment, the vehicle will be re-auctioned.
   * @format double
   */
  remainingDaysUntilReauctioning: number;
  /**
   * This is a countdown, being set if an auction is waiting for pickup.
   * After a certain amount of days without pickup, the late pickup fee will be applied (based on the days).
   * @format double
   */
  remainingDaysUntilLatePickup: number;
  /**
   * This is a field containing the fee that the buyer has to pay due to late pickup.
   * @format double
   */
  latePickupFee: number;
  /**
   * This flag is showing a possibility to book a transportation. True by default,
   * switching to false after 72 hours after purchase
   */
  isTransportationBookingPossible: boolean;
  /**
   * This flag is showing a availability for transportation (the auction doesn't have to be closed). True by default,
   * false if a vehicle is not movable and the seller is not transport only.
   */
  isTransportationAvailable: boolean;
  /**
   * This flag indicates whether the buyer is allowed to request/book transportation for the given auction.
   * This is mostly based on the region of the buyer and the auction and is calculated dynamically.
   */
  isTransportationAllowedForRegion: boolean;
  /** This flag indicates whether express pickup is allowed for this auction (by the seller). */
  isExpressPickupAvailable: boolean;
  /**
   * Number of days from when pickup will be possible.
   * "null" if not available.
   * @format double
   */
  pickupPossibleInDays: number;
  /**
   * Approximate distance to the vehicle from a buyer in km.
   * @format double
   */
  distanceToVehicleInKms: number;
  /** Identify whether the auction vehicle needs to be pickup in the compound */
  isCompoundPickup: boolean;
  /** Identify whether there's an increased time before the auction vehicle can be picked up */
  hasIncreasedPickupTime: boolean;
  /** Identify whether there is a next auction to go to on auction detail */
  hasNextAuction?: boolean;
  /** Identify whether there is a previous auction to go to on auction detail */
  hasPreviousAuction?: boolean;
  /** Indicates whether the buyer can use the Buy Now, Pay Later feature in this auction. */
  isBuyNowPayLaterAvailable: boolean;
  /**
   * Indicates the date which the standing fee will start to be charged.
   * @format date-time
   */
  standingFeeDueFrom?: string;
  /**
   * The maximum value that a bid can have before appearing a bid warning (this is calculated based on the auction min ask and MPR).
   * This is necessary to reduce the number of improperly placed bids.
   * @format double
   */
  bidMaxValueForWarning?: number;
  /**
   * The maximum value that a bid can have before being blocked from bidding (this is calculated based on the auction min ask and MPR).
   * This is necessary to reduce the number of improperly placed bids.
   * @format double
   */
  bidMaxValueForBlocking?: number;
  /**
   * Indicates the date which the auction payment is due.
   * @format date-time
   */
  paymentDueFrom?: string;
  /** This flag indicates whether the auction has a zero cost transport. */
  isZeroCostTransport?: boolean;
  /**
   * Number of times the auction has been parked by buyers.
   * @format double
   */
  timesParked?: number;
  order: IOrderWithInvoiceDataAndPaymentInfo;
}

export interface IPageIBuyerAuctionViewWithOrder {
  items: IBuyerAuctionViewWithOrder[];
  /** @format double */
  total: number;
  /** @format double */
  page: number;
  /** @format double */
  pageSize?: number;
}

export interface ICreateCreditNoteXMLResponse {
  urlToElectronicInvoice: string;
}

export interface ICreateCreditNoteXMLPayload {
  creditNoteNumber: string;
  isCancellationInvoice: boolean;
}

/** Parameters for creating credit notes for invoices. */
export interface IInvoiceCreditNoteCreationParams {
  /** The invoice for which the credit note should be created. */
  invoiceUuid: string;
  /**
   * The absolute amount for which the credit note should be created.
   *
   * If this is set, the "relativeAmount" cannot be set.
   * @format double
   */
  absoluteAmount?: number;
  /**
   * The relative amount for which the credit note should be created.
   *
   * If this is set, the "absoluteAmount" cannot be set.
   * @format double
   */
  relativeAmount?: number;
  /** The currency in which the credit note should be created in. */
  currency: ECurrency;
  /** The reason why the credit note is created. */
  reason: EInvoiceCreditNoteReason;
  /** Flag indicating whether the amount should be refunded (in case the invoice is already paid). */
  shouldBeRefunded?: boolean;
  /**
   * Text that is added in the line item for the given credit note.
   * Should contain a description what has been credited (maybe also why).
   */
  description: string;
}

/**
 * Inform that a new resource was created. Server will
 * add a Location header and set status to 201
 */
export interface ReturnNewResourceIInvoice {
  location: string;
  /** @format double */
  statusCode: number;
  /** the body to be sent */
  body: IInvoice;
}

/** Represents all the possible mistake types. */
export enum EAuctionMistakeType {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
  Value5 = 5,
  Value6 = 6,
  Value7 = 7,
  Value8 = 8,
  Value9 = 9,
  Value10 = 10,
  Value11 = 11,
  Value12 = 12,
  Value13 = 13,
  Value14 = 14,
  Value15 = 15,
  Value16 = 16,
  Value17 = 17,
  Value18 = 18,
  Value19 = 19,
  Value20 = 20,
  Value21 = 21,
  Value22 = 22,
  Value23 = 23,
  Value24 = 24,
  Value25 = 25,
  Value26 = 26,
  Value27 = 27,
  Value28 = 28,
  Value29 = 29,
  Value30 = 30,
  Value31 = 31,
  Value32 = 32,
  Value33 = 33,
  Value34 = 34,
  Value35 = 35,
  Value36 = 36,
  Value37 = 37,
}

/** Indicates by whom the mistake was made. */
export enum EAuctionMistakeOrigin {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
  Value5 = 5,
}

/** Shows when the mistake was reported. */
export enum EAuctionMistakeState {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
}

/** Indicates how big is financial impact (more or less 300 EUR). */
export enum EAuctionMistakeFinancialImpact {
  Value0 = 0,
  Value1 = 1,
}

export interface IAuctionMistake {
  uuid: string;
  /**
   * Indicating the time when the entity has been created, i.e. when the entity was
   * persisted to the database.
   * @format date-time
   */
  createdAt?: string;
  /**
   * Indicating the last time a value of the entity has been changed (updated).
   * @format date-time
   */
  updatedAt?: string;
  /**
   * Indicating the time when the entity has been (softly) deleted.
   * "null" if entry is not deleted.
   * @format date-time
   */
  deletedAt?: string;
  /** Type of mistake. */
  type: EAuctionMistakeType;
  /** Origin of mistake. */
  origin?: EAuctionMistakeOrigin;
  /** State of an auction when a mistake was found. */
  auctionMistakeState?: EAuctionMistakeState;
  /** Type of financial impact. */
  financialImpact?: EAuctionMistakeFinancialImpact;
  /** Auction reference. */
  _fk_uuid_auction?: string;
  /** Creator reference. */
  _fk_uuid_creator?: string;
}

/** Auctions how they are seen by (internal) admin users. */
export interface IAdminAuctionView {
  /**
   * @deprecated
   * @format double
   */
  id: number;
  uuid: string;
  /**
   * Indicating the time when the entity has been created, i.e. when the entity was
   * persisted to the database.
   * @format date-time
   */
  createdAt?: string;
  /**
   * Indicating the last time a value of the entity has been changed (updated).
   * @format date-time
   */
  updatedAt?: string;
  /**
   * Indicating the time when the entity has been (softly) deleted.
   * "null" if entry is not deleted.
   * @format date-time
   */
  deletedAt?: string;
  /** Stores which type of additional tax applies to the auction if any. */
  additionalTaxType: EAdditionalTaxType;
  /**
   * Stores the value of the additional tax that might apply to an auction.
   * @format double
   */
  additionalTaxValue: number;
  /**
   * Stores the export discount for taxes.
   * @format double
   */
  additionalTaxExportDiscount: number;
  /**
   * The state of the auction, indicating whether an auction is not yet published (DRAFTED), running (ACTIVE),
   * closed successfully (CLOSED_WAITING_FOR_PAYMENT, CLOSED_WAITING_FOR_PICKUP, FINISHED), failed (CLOSED_NO_BIDS,
   * CLOSED_UNDER_MINIMUM_ASK) or disabled (DISABLED).
   *
   * Depending on the state, different timestamps are available per auction.
   */
  state: EAuctionState;
  /**
   * The exact date+time when the auction is expiring/ending.
   * @format date-time
   */
  endingTime: string;
  /**
   * The remaining number of seconds that this auction will be in the state ACTIVE (running).
   *
   * This is a dynamic, non-persisted field, calculated on every retrieval of the auction data.
   * @format double
   */
  remainingTimeInSeconds: number;
  /**
   * The remaining number of seconds that this auction is available for instant purchase
   * (usually the first 24 hours after the auction is started).
   *
   * This is a dynamic, non-persisted field, calculated on every retrieval of the auction data.
   * @format double
   */
  remainingTimeForInstantPurchaseInSeconds: number;
  /**
   * Date+time indicating until when an auction is available for instant purchasing.
   * By default, this is 24 hours after the start of an auction.
   * @format date-time
   */
  instantPurchasePossibleUntil: string;
  _fk_uuid_highestBiddingBuyerUser: string;
  /**
   * Value that is indicating the current highest bid value on the auction.
   * This is of course a dynamic field, that will be incremented on every new bid placed on the auction.
   * @format double
   */
  currentHighestBidValue: number;
  /** @format double */
  currentHighestBidValueNet: number;
  /**
   * The minimal price the seller user wants to reach for this specific auction.
   * If an auction ends above that price, the auction is successful right away
   * (switching to state CLOSED_WAITING_FOR_PAYMENT).
   *
   * If the auction ends below the minimum required ask, the auction will switch to state CLOSED_UNDER_MINIMUM_ASK,
   * which then requires the seller's explicit "confirmation" or "rejection" of the highest bid value.
   * @format double
   */
  minimumRequiredAsk: number;
  /** @format double */
  minimumRequiredAskNet: number;
  /**
   * Number of bids placed on an auction.
   * @format double
   */
  numBids: number;
  /** @format double */
  buyerRecommendationScore?: number;
  /** Was this auction created with the help of an inspection done or commissioned by CarOnSale? */
  inspectionWasCommissionedByCos: boolean;
  /** By which type of user was this auction created. */
  creatorType: EAuctionCreatorType;
  /** UUID of the creating internal user if there is one. */
  _fk_uuid_creatingInternalUser: string;
  /** UUID of the creating seller user if there is one. */
  _fk_uuid_creatingSellerUser: string;
  /**
   * Indicates if the auction was created for testing purposes and
   * should be handled differently: it should not be ended automatically,
   * so the seller should decide would he like to sell it or not.
   */
  isTest: boolean;
  /** Indicates if minimum required ask should be displayed for buyers. */
  displayMinAsk: boolean;
  /**
   * GPS coordinates of the auction (can be used for e.g. calculating distances between auctions and buyers).
   * @format double
   */
  locationGeoLat: number;
  /** @format double */
  locationGeoLon: number;
  /** @format double */
  counterOfferByBuyer: number;
  /** @format double */
  counterOfferByBuyerNet: number;
  /** @format double */
  lastOfferBySeller: number;
  /** @format double */
  lastOfferBySellerNet: number;
  /**
   * A value denoting the previous renegotiation offer given by a buyer user.
   * @format double
   */
  previousCounterOfferByBuyer: number;
  /** @format double */
  previousCounterOfferByBuyerNet: number;
  /**
   * A value denoting the previous renegotiation offer given by a seller user.
   * This is required in order to properly display the right information during the renegotiation process.
   * @format double
   */
  previousLastOfferBySeller: number;
  /** @format double */
  previousLastOfferBySellerNet: number;
  /** A flag for if the seller user connected to the auction has decided not to participate in the renegotiation any further. */
  renegotiationStoppedBySeller: boolean;
  /** A flag for if the highest bidding buyer user connected to the auction has decided not to participate in the renegotiation any further. */
  renegotiationStoppedByBuyer: boolean;
  /** A fag indicating if the buyer's withdrawal from the renegotiation process was done automatically or not. */
  wasRenegotiationStoppageByBuyerAutomatic: boolean;
  /** A fag indicating if the seller's withdrawal from the renegotiation process was done automatically or not. */
  wasRenegotiationStoppageBySellerAutomatic: boolean;
  /**
   * The total number of offer rounds a buyer has participated in for an auction during the renegotiation phase.
   * @format double
   */
  numBuyerRenegotiationOfferRounds: number;
  /**
   * The total number of offer rounds a seller has participated in for an auction during the renegotiation phase.
   * @format double
   */
  numSellerRenegotiationOfferRounds: number;
  /**
   * The calculated renegotiation midpoint value of the auction.
   * This is used once the renegotiation offers get too close for the offer process to continue.
   * This value will be undefined unless the auction is ready to be purchased for such a value.
   * @format double
   */
  renegotiationMidpointValue: number;
  /** @format double */
  renegotiationMidpointValueNet: number;
  /**
   * Indicates that the highest bidding buyer user for an auction in renegotiation is willing to accept the midpoint of their offer and
   * the offer of the seller for the same auction. This is used when the renegotiation offers get very close during the renegotiation process.
   */
  buyerAcceptedRenegotiationMidpoint: boolean;
  /**
   * Indicates that the seller of an auction is willing to accept the midpoint of their offer and the offer of the highest bidding
   * buyer user for the same auction. This is used when the renegotiation offers get very close during the renegotiation process.
   */
  sellerAcceptedRenegotiationMidpoint: boolean;
  /**
   * This is the datetime object denoting when the most recent renegotiation round concluded at.
   * @format date-time
   */
  lastRenegotiationRoundEndedAt: string;
  /**
   * The number of seconds until the current renegotiation round times out.
   * @format double
   */
  numSecondsUntilRenegotiationTimeout: number;
  /**
   * The UUID of the external case tracking the renegotiation of the auction.
   * We track this to enable case updates as the auction moves through renegotiation.
   */
  externalRenegotiationTicketUuid: string;
  /**
   * The calculated renegotiation fair value of the auction.
   * This is used once the renegotiation starts based on the auction GPR or MPR so we know
   * the fair value that both seller and buyer should agree upon.
   * @format double
   */
  renegotiationFairValue: number;
  /**
   * Value that is indicating the highest bid value on the auction when it ended.
   * This value should not change for a given auction since it supposed to be a historical record.
   * @format double
   */
  highestBidValueAtEndingTime: number;
  /** @format double */
  highestBidValueAtEndingTimeNet: number;
  /** Field data related to the renegotiation round process. */
  renegotiationRoundData?: IRenegotiationRoundData;
  /**
   * Indicates when manual renegotiation was finished via Salesforce.
   * @format date-time
   */
  finishedManuallyAt?: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing service (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the transfer of funds
   * for the vat deposit from COS to the deposit account (only relevant for cross border transactions).
   * This deposit is refunded by COS once the buyer provides the necessary documentation for a cross border transaction.
   */
  thirdPartyVATDepositTransferReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing service (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the transfer of funds
   * for the additional tax deposit from COS to the deposit account (only relevant for cross border transactions).
   * This deposit is refunded by COS once the buyer provides the necessary documentation for a cross border transaction.
   */
  thirdPartyAdditionalTaxDepositTransferReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing system (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the transfer of funds
   * for the auction additional taxes (e.g country specific luxury taxes): from COS to the seller.
   */
  thirdPartyAdditionalTaxTransferReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing service (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the transfer of funds
   * for the VAT: from COS to the seller (only relevant for cross border transactions for now).
   * COS is advancing the vat amount to the seller.
   */
  thirdPartyVatTransferReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing service (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the charge of funds
   * for the auction volume from the buyer to COS.
   */
  thirdPartyVolumeChargeReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing service (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the charge of funds
   * for the auction deposit from the buyer to COS (only relevant for cross border transactions for now).
   * This deposit is refunded by COS once the buyer provides the necessary documentation for a cross border transaction.
   */
  thirdPartyVATDepositChargeReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing service (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the charge of funds
   * for the auction additional tax (e.g country specific luxury tax) from the buyer to COS.
   */
  thirdPartyAdditionalTaxChargeReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing system (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the refund of an
   * additional tax deposit (only used for cross-border transactions currently).
   */
  thirdPartyAdditionalTaxRefundReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing system (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the payout of funds.
   */
  thirdPartyPayoutReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing system (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the refund of a
   * deposit (only used for cross-border transactions currently).
   */
  thirdPartyVATDepositRefundReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing system (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the refund of a
   * transfer to the deposit account owned by cos (only used for cross-border transactions currently).
   */
  thirdPartyVATDepositReversalReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing system (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the refund of the
   * additional tax transfer to the deposit account owned by cos (only used for cross-border transactions).
   */
  thirdPartyAdditionalTaxDepositReversalReference: string;
  /**
   * Reference to the invoice for the success fee issued to the seller for a sold vehicle (see ESellerFeeInvoicingMode.INVOICE_ON_SUCCESS).
   * The reference identifies the invoice issued to the seller in an external invoicing system (e.g. Stripe).
   *
   * This fee is not always charged, therefore this reference is optional (can be null).
   */
  sellerSuccessFeeInvoiceReference: string;
  /**
   * Reference to the invoice for the listing fee issued to the seller for a listed vehicle (see ESellerFeeInvoicingMode.INVOICE_ON_LISTING).
   * The reference identifies the invoice issued to the seller in an external invoicing system (e.g. Stripe).
   *
   * This fee is not always charged, therefore this reference is optional (can be null).
   */
  sellerListingFeeInvoiceReference: string;
  /**
   * Reference to the invoice for the standing fee issued to the buyer for a purchased vehicle picked up too late.
   *
   * This fee is not always charged, therefore this reference is optional (can be null).
   */
  buyerStandingFeeInvoiceReference: string;
  /**
   * Reference to a resource in an external invoicing system (such as Stripe).
   * References our own transaction fee invoice.
   */
  invoiceReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing system (such as Stripe).
   * This is only non-null, if external payment is allowed for this auction.
   */
  thirdPartyInvoiceReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing system.
   * This is only non-null, if external payment is allowed for this auction and it had to be cancelled.
   */
  thirdPartyCorrectionInvoiceReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing system (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the transfer of funds
   * for the auction volume: from COS to the seller.
   */
  thirdPartyTransferReference: string;
  /**
   * Reference of an invoice on an external invoicing system.
   * This is invoice is used to charge booked services (like guarantee) after the auction expires.
   */
  bookedServicesInvoiceReference: string;
  /**
   * Date-time when the auction was reauctioned and is now waiting for buyer payment until the new auction gets paid.
   * @format date-time
   */
  reauctionedWaitingForBuyerPaymentAt: string;
  /**
   * Date-time when the first reauction of this auction has expired.
   * We need to keep track of this so we can block the original buyer of seeing the original auction.
   * @format date-time
   */
  firstReauctionExpiredAt: string;
  /** Flag that indicates if this auction is a clonned reauction. */
  isReauction: boolean;
  /**
   * The highest bid value that we show to the seller when we merge the reauction with the original auction.
   * This is done so the seller see the amount that cos pay them and the buyer sees the amount that they paid cos.
   * @format double
   */
  sellerViewHighestBidValue: number;
  /**
   * The purchase confirmed at that we show to the seller when we merge the reauction with the original auction.
   * This is done so the seller see the date when the original auction was confirmed and the buyer sees the date that the reauction was confirmed.
   * @format date-time
   */
  sellerViewPurchaseConfirmedAt: string;
  /**
   * Date-time when the reauction was merged back at the original auction.
   * @format date-time
   */
  reauctionedMergedAt: string;
  /** Indicates wherever an auction was purchase using the Buy Now Pay Later feature. */
  wasPurchasedUsingBuyNowPayLater: boolean;
  /**
   * The timestamp at which the buyer has confirmed his choice to use Buy Now Pay Later for the auction.
   * @format date-time
   */
  buyNowPayLaterConfirmedAt: string;
  /** The IP address which confirmed the Buy Now Pay Later choice for the auction. */
  buyNowPayLaterConfirmationIPAddress: string;
  /** Reference to the Buy Now Pay Later Order on the PNI Service. */
  buyNowPayLaterOrderReference: string;
  /** Reference of the invoice for buy now pay later provider fee on an external invoicing system. */
  buyNowPayLaterInvoiceReference: string;
  /** The payment link reference */
  paymentLinkReference: string;
  /** Reference to the payment order on the P&I service */
  paymentOrderReference: string;
  isInternalFulfillment: boolean;
  belowMinAskBehaviour: EAuctionBelowMinAskBehaviour;
  /** The label of the auction, consisting of the vehicle make, model and date of first registration (EZ). */
  label: string;
  /**
   * Date+time when the auction is started.
   * When an auction is started right away (without drafting), the "createdAt" and "startedAt" should have
   * (roughly) the same values.
   * @format date-time
   */
  startedAt: string;
  /**
   * Date+time when the auction is "confirmed to be sold" by the seller (or by an admin).
   * If an auction is ending equal or above the minimum required ask, the "purchaseConfirmedAt"
   * will automatically be set and will be equal to the "endingTime".
   *
   * If an auction is ending below the minimum ask (CLOSED_UNDER_MINIMUM_ASK), the "purchaseConfirmedAt" timestamp
   * is only set if the auction is then manually "confirmed" by the seller or an admin.
   *
   * If an auction is never "successful", the "purchaseConfirmedAt" will remain "null".
   *
   * This value was introduced in the course of 2019. For all previous auctions, the endingTime was set
   * as the "purchaseConfirmedAt" retrospectively.
   * @format date-time
   */
  purchaseConfirmedAt: string;
  /**
   * Date-time when the auction has definitely been rejected by a seller or an admin, after the auction
   * ended below minimum required ask.
   *
   * This value is only available since 2019/07/29. All auctions rejected before that date have the value "null".
   * @format date-time
   */
  purchaseRejectedAt: string;
  /** Indicates the reason that an auction failed the renegotiation process. */
  rejectionReason: EAuctionRejectionReason;
  /**
   * Elaborates on the rejection reason to provide further context to why an
   * auction was rejected during the renegotiation process.
   */
  rejectionReasonNote: string;
  /**
   * The name of the competitor that the auction was sold to when the reject chosen reason was
   * VEHICLE_SOLD_ON_DIFFERENT_PLATFORM
   */
  rejectionReasonCompetitorName: string;
  /** In case if there was a direct upload requested but didn't succeed the reason should be stored. */
  directUploadRejectionReasons: EAuctionDirectUploadRejectionReason[];
  /**
   * Value that is indicating initial bid value auction should be started with.
   * @format double
   */
  startingBidValue: number;
  /** @format double */
  startingBidValueNet: number;
  /**
   * Value that can be manually set in case edge-cases apply and the payout amount is disconnected from the auction current highest bid.
   * This value can only be adjusted by internal users.
   * @format double
   */
  overridePayoutValue: number;
  /**
   * If an auction is ending below the minimum required ask, the "originalMinimumRequiredAsk" will be set to the
   * "minimumRequiredAsk" value before it is set to "currentHighestBidValue".
   * @format double
   */
  originalMinimumRequiredAsk: number;
  /** @format double */
  originalMinimumRequiredAskNet: number;
  /**
   * The purchase price for the vehicle that is auctioned.
   * This is set manually by the seller, and is mostly used to analyze the "real" margin that a seller
   * makes with an auction.
   * @format double
   */
  purchasePrice: number;
  /** @format double */
  purchasePriceNet: number;
  /**
   * The full object of the vehicle that is on auction.
   * This is augmented on every auction retrieval, based on _fk_uuid_vehicle.
   */
  associatedVehicle: IVehicle;
  /** Indicates whether the auction process is rated by the SellerUser. */
  isRatedByDealership: boolean;
  /** Indicates whether the auction process is rated by the BuyerUser. */
  isRatedByBuyer: boolean;
  /**
   * Indicates whether the BuyerUser has marked this auction as paid (payment of the auction volume).
   * This is done manually by the BuyerUser, so this value is not indicating that the actual payment has really
   * reached the seller.
   */
  isPaidByBuyer: boolean;
  /**
   * Timestamp when the BuyerUser has marked this auction as "paid" (i.e. set the "isPaidByBuyer").
   * @format date-time
   */
  outgoingPaymentConfirmedAt: string;
  /**
   * Timestamp when the SellerUser has confirmed that the payment has reached the seller bank account.
   * @format date-time
   */
  incomingPaymentConfirmedAt: string;
  /**
   * Timestamp when the auction state has changed from "CLOSED_WAITING_FOR_PAYMENT" to "CLOSED_WAITING_FOR_PICKUP".
   * @format date-time
   */
  pickupConfirmedAt: string;
  /** Physical address of where the vehicle on auction is located (a.k.a. pickup address). */
  locationCountryCode: ECountryCode;
  locationAddress: string;
  locationCity: string;
  locationZip: string;
  /** Physical address of where the vehicle on auction was inspected (a.k.a. inspection address). */
  inspectionLocationCountryCode: ECountryCode;
  inspectionLocationAddress: string;
  inspectionLocationCity: string;
  inspectionLocationZip: string;
  /**
   * Indicating to which bank account the BuyerUser has to transfer the auction volume to.
   * @deprecated
   */
  sellerIban: string;
  /**
   * URL to the seller invoice for the given auction, manually uploaded by the SellerUser, after
   * the auction has ended successfully.
   */
  urlToInvoice: string;
  /** URL for the cancelled invoice in case it was cancelled. */
  urlToCorrectionInvoice: string;
  /**
   * Flag indicating whether an auction has an "hot bid" phase in the last 20 seconds before expiration.
   * @deprecated
   */
  hotBid: boolean;
  /**
   * Seller's instant purchase price for the vehicle.
   * For the first 24 hours after the auction is started, the vehicle will only be available in "instant purchase"
   * mode (bidding is not allowed in that time, only purchasing right away).
   *
   * After 24 hours, if nobody purchased the vehicle, it is going on auction, just like all other auctions.
   * @format double
   */
  instantPurchasePrice: number;
  /** @format double */
  instantPurchasePriceNet: number;
  /**
   * Flag indicating that instant purchase is enabled for that auction.
   * (Implies that "instantPurchasePrice" is set)
   */
  allowInstantPurchase: boolean;
  /**
   * Flag indicating that an auction "ended" with an instant purchase, resp.
   * did never go on auction.
   */
  didEndWithInstantPurchase: boolean;
  /**
   * Internal counter, incrementing on every "restart" of an auction.
   * Starts with 0.
   *
   * e.g. count of 2 means that the auction has been restarted 2 times, leading to 3 auction objects in the
   * database.
   * @format double
   */
  auctioningIterations: number;
  /**
   * Indicating the strategic priority of an auction for CarOnSale.de
   * The higher the number, the more "relevant" the auction is.
   * @format double
   */
  priority: number;
  /** @deprecated */
  advertisementHtmlContent: string;
  /** @deprecated */
  buyerComplaint: EAuctionBuyerComplaint;
  /**
   * Custom field that is not used internally.
   * Allows sellers to store e.g. a reference ID to their own vehicle management system.
   */
  internalReferenceText: string;
  _fk_uuid_vehicle: string;
  _fk_uuid_sellerUser: string;
  /** This is a URL that redirects to an external invoice payment site (such as provided by Stripe). */
  urlToPaymentSite: string;
  /**
   * A flag indicating if an auction needs to be reviewed by an internal review agent (for quality assurance)
   * If true, an auction is currently under review by CarOnSale, and cannot be edited by the owning seller user.
   */
  needsReview: boolean;
  /** The reasons why an auction was rejected during the review. */
  reviewReasons: EAuctionReviewReason[];
  /**
   * Reasons why an auction is rejected during the review process ("Parkhaus").
   *
   * This reason is automatically set to UNKNOWN for all auctions and is then manually adjusted by
   * an internal review agent (in case the auction gets rejected) before it is sent back to the responsible
   * SellerUser.
   * @deprecated
   */
  reviewReason: EAuctionReviewReason;
  /** Specifies reason when "reviewReasons" contains EAuctionReviewReason.OTHER. */
  reviewReasonDetails: string;
  /** The comment given by an internal review agent on rejecting an auction under review. */
  reviewComment: string;
  /** Flag indicating if the auction can be published without review. */
  canSkipReview?: boolean;
  /**
   * Random PIN code that is generated when an auction is ready to be picked up or in advance before COSCheck inspection.
   * This PIN code is printed out on the hangar in the vehicle or handed to the seller of the auction, which can then forward it to
   * the buyer to confirm the pickup.
   */
  pickupPinCode: string;
  /**
   * Random UUID that is used to confirm vehicle pickup via scanning QR code.
   * It will be printed on the hangar or in the pickup document available for sellers.
   */
  pickupPinUuid: string;
  /**
   * Timestamp when PIN code was entered by the buyer.
   * Allows to determine how many auctions were self-picked up.
   * @format date-time
   */
  pickupPinCodeEnteredAt: string;
  /**
   * Timestamp when PIN UUID was entered by the buyer via scanning QR code.
   * Allows to determine how many auctions were self-picked up.
   * @format date-time
   */
  pickupPinUuidEnteredAt: string;
  /** Flag is indicating if pickup was confirmed with PIN using new process (via pin.caronsale.de) */
  pickupConfirmedWithNewPin: boolean;
  /** URL to the pickup information document for the BuyerUser. */
  urlToPickupBuyerDocument: string;
  /** URL to the pickup information document for the SellerUser (containing the "pickupPinCode"). */
  urlToPickupSellerDocument: string;
  /** URL to the success report document for the SellerUser. */
  urlToSellerSuccessReport: string;
  /** The payment process that is used to pay money for the purchase volume (auction volume). */
  paymentProcess: EAuctionPaymentProcess;
  /**
   * Referencing the bank account to which the payment for the purchase volume should be paid out.
   * This is null, if the paymentProcess === "CLASSIC".
   *
   * It is only used, when external payment (COS Payment) is enabled.
   */
  _fk_uuid_associatedBankAccount: string;
  /**
   * Indicates how often an auction has been reviewed.
   * This is incremented every time an auction is rejected after a review.
   * @format double
   */
  draftReviewIterations: number;
  /**
   * Timestamp when an admin resets a time window for a possibility to complain
   * @format date-time
   */
  complaintTimeWindowExtendedAt: string;
  /**
   * Flag indicates if this auction is suspected to have been paid in cash (only for auctions that use the external
   * invoice process), this means that no corresponding transfer exists on Stripe and the buyer confirmed the pickup
   * through the seller pin code.
   */
  isSuspectedCashPayment: boolean;
  /**
   * Timestamp when the auction was sent to review.
   * @format date-time
   */
  reviewStartedAt: string;
  /**
   * Timestamp when the auction QA process actually started (i.e. when Parkhaus started reviewing the auction).
   * @format date-time
   */
  qaStartedAt: string;
  /**
   * Timestamp when the auction was ready to be reinserted (a.k.a. restarted).
   * @format date-time
   */
  readyToBeReinsertedSince: string;
  /** The reference to the original auction from which current one was cloned. */
  _fk_uuid_clonedFrom: string;
  /** Auction type. */
  type: EAuctionType;
  /** Indicates whether the rejection wait period for this auction is over and thus can be rejected by the seller. */
  isRejectionWaitPeriodOver: boolean;
  /** Reference to invoice object for the external Billomat invoicing provider. */
  thirdPartyVehiclePurchaseInvoiceReference: string;
  /**
   * URL to the seller made invoice invoicing CoS for the given auction, manually uploaded to the crm by an internal user, after
   * the auction has ended successfully.
   */
  sellerToCosInvoiceUrl: string;
  /**
   * Timestamp for when the sellerToCosInvoiceUrl was last updated.
   * @format date-time
   */
  sellerToCosInvoiceUrlLastUpdatedAt: string;
  /**
   * Timestamp for when the sellerToCosInvoiceUrl is first uploaded.
   * @format date-time
   */
  sellerToCosInvoiceUrlFirstUploadedAt: string;
  /** Flag indicating wherever the uploaded seller to cos invoice was verified by an internal user. */
  sellerToCosInvoiceIsValid: boolean;
  /** The comment given by an internal user on the provided seller to cos invoice. */
  sellerToCosInvoiceComment: string;
  /** Shows if auction is marked as 'live', so, it will be slotted during starting and expire in less than 24 hours. */
  isLive: boolean;
  /** Shows if auction was already slotted via the slotting algorithm. */
  isSlotted: boolean;
  /**
   * The exact date+time when the auction was slotted to end.
   * @format date-time
   */
  slottedEndingTime: string;
  /** Indicating if transportation should be disabled manually. */
  isTransportationDisabledManually: boolean;
  /** Optional pickup instructions that will be communicated to the buyer. */
  pickupInstructions: string;
  /** Indicating wherever the seller should be charged a success/listing fee. */
  preventSellerFactoring: boolean;
  /** Indicating if buyer success fee should be applied or not. */
  applyBuyerSuccessFee: boolean;
  /** Info from document shipping service about the order, like status and links to the documents. */
  documentShippingOrder: IAuctionDocumentShippingOrder;
  /** Info about un-/confirmed auction data points. */
  fieldsConfirmationStatus: IAuctionFieldConfirmationStatusMap;
  /**
   * Whether the "Value Added Tax is reportable", a special tax condition in the EU.
   * If this is true, it means that the vehicle is "Regelbesteuert", meaning that the invoice for the vehicle
   * (either invoiced directly from seller to buyer - or from COS Payment to buyer) needs to include
   * the VAT (in Germany e.g. 19%) in the invoice.
   *
   * If this is false, the invoice does not need to have explicit VAT.
   */
  isVATReportable: boolean;
  /** Indicates how the auction was uploaded to the marketplace. */
  uploadMethod: EAuctionUploadMethod;
  /** Prebooked services available for the auction, like transport, warranty. */
  prebookedServices: IPrebookedService[];
  /** Indicates if cosCheckPlus guarantee can be booked for this auction. */
  isCosCheckPlusGuaranteeEnabled: boolean;
  /** Indicates if extended guarantee can be booked for this auction. */
  isExtendedGuaranteeEnabled: boolean;
  /** URL to the auction vehicle cover letter for sellers */
  urlToCoverLetter: string;
  /**
   * Timestamp when the automatic pickup approval was requested for auction
   * @format date-time
   */
  autoPickupOnPaymentRequestedAt: string;
  /**
   * This means that the auctions is using complimentary vehicle feature.
   *
   * Note: This is marked as true when the seller uses one of his complimentary vehicles to pay for the fees.
   */
  isSellerFeeComplimentary: boolean;
  /** Flag when standing fee is enabled to the auction and we'll need to extra charge a buyer as a penalty. */
  isStandingFeeEnabled: boolean;
  _fk_uuid_auctionRoom: string;
  /**
   * An auction can have a list of fulfillment criteria that impact how the
   * auction will be fulfilled depending on the buyer that wins it.
   */
  fulfillmentCriteria?: IAuctionFulfillmentCriteria[];
  /**
   * The auction marked with this flag won't start automatically after review.
   *
   * Instead, it will transition to state: READY_TO_BE_PUBLISHED.
   *
   * Default: false
   */
  shouldWaitToBePublishedAfterReview: boolean;
  /** A flag that marks if an auction is a selective ware or not. */
  isSelectiveWare: boolean;
  /** The id for the look and feel applied to the auction. */
  lookAndFeelId: EAuctionLookAndFeelId;
  /** A flag that marks if an auction will use the pickup qr code. */
  isQRCodeAvailable: boolean;
  /**
   * Indicates whether the highest bid should be transferred if this auction gets cloned.
   * Defaults to `false` if not specified.
   */
  shouldTransferHighestBid?: boolean;
  /** A flag that represents either or not if the auction was paid externally. */
  wasExternallyPaid?: boolean;
  /** A flag that represents either or not if the auction was delivered externally. */
  wasExternallyPickedUp?: boolean;
  /** Confirm to remove auction from auction room when changing auction ending time. */
  removeFromAuctionRoom?: boolean;
  /**
   * Indicates the amount of VAT tax that applies to the auction at current highest bid.
   * @format double
   */
  vatAmount: number;
  /**
   * VAT rate that applies to this auction.
   * @format double
   */
  vatRate: number;
  /** The state of the buy now pay later order */
  buyNowPayLaterOrderState: IBuyNowPayLaterOrderState;
  /** The value of the buy now pay later fee */
  buyNowPayLaterFee: IBuyNowPayLaterAmount;
  /** The link to the order within the buy now pay later provider. */
  buyNowPayLaterProviderOrderUrl: string;
  recommendationId?: string;
  /** The seller user that is owning this auction. */
  seller: ISellerUser;
  /** The account associated with the seller of this auction. */
  account: IAccountWithAssignedInternalUsers;
  /**
   * The associated SellerAccount for this auction.
   * "null" if no SellerAccount is assigned to the seller user.
   * @deprecated
   */
  sellerAccount: ISellerAccount;
  /**
   * The bank account to which the payment should be paid out.
   *
   * "null", if payment process is classic.
   */
  targetSellerBankAccount: ISellerBankAccount;
  /** The current highest bidder for this auction. */
  highestBidder: IGeneralUser;
  /** The transportation job that has been requested for this auction (only if really booked) */
  requestedTransportationTask: ITransportationTask;
  /** This flag indicates whether transport has to be booked through COS (no self-pickup possible) */
  enforceTransportation: boolean;
  /** Containing recent memos for this auction. */
  memos: IAuctionMemo[];
  /**
   * Value of the highest bidding agent currently set.
   * (Always belonging to the current highest bidder)
   * @format double
   */
  highestBiddingAgentValue: number;
  /** @format double */
  highestBiddingAgentValueNet: number;
  /**
   * The current state of the payment (only available for auctions with COS-Payment).
   *
   * This is null, if the payment process is classic.
   */
  externalPaymentState: IAuctionPaymentState;
  /**
   *
   * Url to pick up authorization document for associated transportation task
   */
  urlToSignedPickUpAuthorizationDocument: string;
  /**
   * Number of days from when pickup will be possible.
   * "null" if not available.
   * @format double
   */
  pickupPossibleInDays: number;
  /**
   * Indicates whether the current highest bidder is assigned as a regular buyer to the seller account that
   * is owning that auction.
   *
   * Either true or false, but not null.
   */
  isHighestBidderRegularBuyer: boolean;
  /**
   * An internal user who created an auction, can be an inspector or any other internal user.
   * Filled in from '_fk_uuid_creatingInternalUser' value.
   */
  creatingInternalUser: IGeneralUser;
  /**
   * Indicates whether cross-border net sale is possible for the auction
   * (i.e. the vehicle is "regelbesteuert" and foreign buyers are allowed to purchase it).
   */
  isCrossBorderNetSalePossible: boolean;
  /**
   * Indicates whether the auction actually ended as a cross-border net sale transaction
   * (i.e. the vehicle is "regelbesteuert" and was purchased by a foreign buyer).
   *
   * This is always false for auctions that are NOT closed successfully.
   */
  isCrossBorderNetSaleTransaction: boolean;
  /**
   * Buyer purchase fee that is charged for this auction on success.
   * This is the variable transaction fee, which is calculated based on bucket pricing.
   *
   * This value is augmented, calculated dynamically based on the highest bid value.
   * @format double
   */
  buyerPurchaseFee: number;
  /**
   * Buyer success fee that is charged for this auction on success.
   *
   * This value is augmented, based on the (static) fee that is configured on the seller account.
   * @format double
   */
  buyerSuccessFee: number;
  /**
   * Buyer ExportHandlingFee is charged for cross-border sales
   *
   * It is dependent on the country of the seller.
   *
   * e.g. DE -> 49, NL,FR,AU -> 19 (August '22)
   * @format double
   */
  buyerExportHandlingFee: number;
  /**
   * The GPR associated with the auction.
   *
   * This value is augmented, it can be null.
   */
  gpr: IGuaranteedPriceRequest;
  /**
   * The MPR associated with the auction.
   *
   * This value is augmented, it can be null.
   */
  mpr: ITradeInValuationRequest;
  /** Flag that is marked as true when the auction is enabled for express pickup. */
  isEnabledForExpressPickup: boolean;
  /** Flag that is marked as true when the auction is enabled for automatic release. */
  isEnabledForAutomaticRelease: boolean;
  /** Identify whether the auction vehicle needs to be pickup in the compound */
  isCompoundPickup: boolean;
  /**
   * This flag is showing a availability for transportation (the auction doesn't have to be closed). True by default,
   * false if a vehicle is not movable and the seller is not transport only.
   */
  isTransportationAvailable: boolean;
  /** The external Id of the original auction that this one is a reauction of. */
  originalAuctionExternalId: string;
  /** The external Id of the reauction that this one is the original auction of. */
  reauctionExternalId: string;
  /** Renegotiation augmented from external table */
  renegotiation?: IRenegotiation;
  /** The purchase invoice associated with the given auction. */
  purchaseInvoice: IInvoice;
  /**
   * The invoice for the transportation task associated
   * with the auction.
   */
  transportationInvoice: IInvoice;
  /** All invoices associated with the auction. */
  invoices: IInvoice[];
  /** Rating that the buyer received from the seller. */
  highestBidderRating: IRating;
  /**
   * Distinct visits to the auction by buyers.
   * @format double
   */
  numDistinctVisits: number;
  /**
   * Total number of visits by buyers to the auction.
   * @format double
   */
  numTotalVisits: number;
  /** Reported mistakes. */
  mistakes: IAuctionMistake[];
}

export type Invoice = object;

/** Describes the payload to create an invoice item of an Invoice on PI Service (part of {@link ICreateInvoicePayload}) */
export interface ICreateInvoiceItemPayload {
  /** Type of the item */
  article: EInvoicedArticle;
  /** Item description */
  description: string;
  /**
   *
   * Additional description texts for the item.
   */
  additionalDescription?: {
    lines: string[];
  };
  /**
   * Amount
   * @format double
   */
  amount: number;
  /** Currency */
  currency: ECurrency;
  /**
   * If true, VAT should be calculated on the difference between the selling price and the
   * original purchase price of the item, rather than the full selling price.
   * @default "false"
   */
  isMarginTaxed?: boolean;
  /**
   * Quantity. Defaults to 1 if no quantity is passed.
   * @format double
   */
  quantity?: number;
}

/** Values indicating different invoice types that can be created. */
export type InvoiceType = EInvoicedArticle;

/** Interface to create stand-alone invoices for a customer via a front-end form. */
export interface ICreateInvoiceViaFormRequest {
  /** Id used to correlates the invoice creation */
  correlationId?: string;
  /** Items of the invoice to be created */
  items: ICreateInvoiceItemPayload[];
  /** Values indicating different invoice types that can be created. */
  invoiceType?: InvoiceType;
  /**
   * Invoice date
   * @format date-time
   */
  date?: string;
  /**
   * The number of days until the invoice is due.
   * @format double
   */
  dueInDays?: number;
  /** The country of the CarOnSale entity that is emitting the invoice. */
  invoicingEntityCountry: ECountryCode;
  /** Title text to be displayed on top of the invoice, above the invoice items. */
  title?: string;
  /**
   * Indicates if the invoice should be paid automatically using direct debit.
   * @default "false"
   */
  payWithDirectDebit?: boolean;
  /** Additional info text that shows up above the invoice items on the invoice PDF. */
  additionalInfoText?: string;
  /** Additional text that shows up below the invoice items at the bottom of the invoice PDF. */
  footerText?: string;
  /**
   * The general user UUID that will be invoiced.
   *
   * This is optional on `ICreateInvoicePayload`, but required here, since the legal entity account will be obtained based on this user.
   */
  payerUserUuid: string;
}

export enum EVoucherDiscountType {
  Fixed = 'fixed',
  Percent = 'percent',
}

/**
 * A voucher can be used to receive discounts on applicable purchases. This represents the basic structure
 * of a voucher, but it needs to be assigned to a user in order to be used. See: {@link IVoucherAssignment }
 */
export interface IVoucher {
  uuid: string;
  /**
   * Indicating the time when the entity has been created, i.e. when the entity was
   * persisted to the database.
   * @format date-time
   */
  createdAt?: string;
  /**
   * Indicating the last time a value of the entity has been changed (updated).
   * @format date-time
   */
  updatedAt?: string;
  /**
   * Indicating the time when the entity has been (softly) deleted.
   * "null" if entry is not deleted.
   * @format date-time
   */
  deletedAt?: string;
  /**
   * The amount that will be discounted by the voucher. Can be a fixed amount or a percentage.
   * @format double
   */
  amount: number;
  /** Whether the voucher should be auto-applied on the next purchase or not. */
  autoApply: boolean;
  /** Indicates if the amount of the voucher is fixed or a percentage. */
  discountType: EVoucherDiscountType;
  /** The original label text that will be shown to the user if a translation is not available. */
  displayLabel: string;
  /** The i18n translation key for the label text that will be displayed to the user in his preferred language. */
  labelTranslationKey: string;
  /** The invoice articles that this voucher can be applied to. If empty, it can be applied to any invoice. */
  eligibleArticles: EInvoicedArticle[];
  /** The credit note reason that will be written to the invoice when applying this voucher. */
  creditNoteReason: EInvoiceCreditNoteReason;
  /** The line item text that will be added to the invoice when this voucher is applied. */
  invoiceText: string;
  /** Whether the voucher is single-use or can be re-used. */
  reusable: boolean;
  /**
   * Expiration date for the voucher. If not set, voucher will be valid forever.
   * @format date-time
   */
  validUntil?: string;
  /**
   * How many times the voucher can be used. If not set, the voucher will either
   * have unlimited uses or a single use, depending on the value of the `reusable` property.
   * @format double
   */
  maxUses?: number;
  /** Key-value pair structure for any data that should be stored together with the voucher. */
  metadata?: Record<string, any>;
}

export interface ICreateVoucherPayload {
  /**
   * The amount that will be discounted by the voucher. Can be a fixed amount or a percentage.
   * @format double
   */
  amount: number;
  /** Indicates if the amount of the voucher is fixed or a percentage. */
  discountType: EVoucherDiscountType;
  /** The credit note reason that will be written to the invoice when applying this voucher. */
  creditNoteReason: EInvoiceCreditNoteReason;
  /** The line item text that will be added to the invoice when this voucher is applied. */
  invoiceText: string;
  /** Whether the voucher should be auto-applied on the next purchase or not. */
  autoApply: boolean;
  /** Whether the voucher is single-use or can be re-used. */
  reusable: boolean;
  /** The invoice articles that this voucher can be applied to. If empty, it can be applied to any invoice. */
  eligibleArticles: EInvoicedArticle[];
  /**
   * How many times the voucher can be used. If not set, the voucher will either
   * have unlimited uses or a single use, depending on the value of the `reusable` property.
   * @format double
   */
  maxUses?: number;
  /** The original label text that will be shown to the user if a translation is not available. */
  displayLabel: string;
  /**
   * Expiration date for the voucher. If not set, voucher will be valid forever.
   * @format date-time
   */
  validUntil?: string;
  /** Key-value pair structure for any data that should be stored together with the voucher. */
  metadata?: Record<string, any>;
  /** A list of user UUIDs that the created voucher will be assigned to. */
  assignToUserUuids?: string[];
  /** The user uuid who requested the creation of the voucher */
  createdByUuid: string;
}

export enum EVoucherAssignmentState {
  Available = 'available',
  Locked = 'locked',
  Finished = 'finished',
}

/**
 * Represents a voucher assigned to a given user, that can be used to receive a discount on purchases.
 *
 * Contains the basic properties of {@link IVoucher} and additional properties
 * that track the voucher usage by the user.
 */
export interface IVoucherAssignment {
  /**
   * Indicating the time when the entity has been created, i.e. when the entity was
   * persisted to the database.
   * @format date-time
   */
  createdAt?: string;
  /**
   * Indicating the last time a value of the entity has been changed (updated).
   * @format date-time
   */
  updatedAt?: string;
  /**
   * Indicating the time when the entity has been (softly) deleted.
   * "null" if entry is not deleted.
   * @format date-time
   */
  deletedAt?: string;
  /**
   * The amount that will be discounted by the voucher. Can be a fixed amount or a percentage.
   * @format double
   */
  amount: number;
  /** Indicates if the amount of the voucher is fixed or a percentage. */
  discountType: EVoucherDiscountType;
  /** The credit note reason that will be written to the invoice when applying this voucher. */
  creditNoteReason: EInvoiceCreditNoteReason;
  /** The line item text that will be added to the invoice when this voucher is applied. */
  invoiceText: string;
  /** Whether the voucher should be auto-applied on the next purchase or not. */
  autoApply: boolean;
  /** Whether the voucher is single-use or can be re-used. */
  reusable: boolean;
  /** The invoice articles that this voucher can be applied to. If empty, it can be applied to any invoice. */
  eligibleArticles: EInvoicedArticle[];
  /**
   * How many times the voucher can be used. If not set, the voucher will either
   * have unlimited uses or a single use, depending on the value of the `reusable` property.
   * @format double
   */
  maxUses?: number;
  /** The original label text that will be shown to the user if a translation is not available. */
  displayLabel: string;
  /**
   * Expiration date for the voucher. If not set, voucher will be valid forever.
   * @format date-time
   */
  validUntil?: string;
  /** Key-value pair structure for any data that should be stored together with the voucher. */
  metadata?: Record<string, any>;
  /** The i18n translation key for the label text that will be displayed to the user in his preferred language. */
  labelTranslationKey: string;
  /** UUID of the voucher that this assignment refers to. */
  voucherUuid: string;
  /** UUID of the user this voucher assignment belongs to. */
  userUuid: string;
  /** Indicates if the voucher is locked (i.e. reserved for a future purchase) or not. */
  isLocked: boolean;
  /**
   * How much balance is left of the voucher.
   * If not set, the voucher has unlimited balance (i.e. it's a percent-based voucher)
   * @format double
   */
  remainingBalance?: number;
  /**
   * How many uses are left of the voucher. If not set, the voucher has unlimited uses.
   * @format double
   */
  remainingUses?: number;
  /** The state of this voucher assignment. */
  state: EVoucherAssignmentState;
}

export enum EPaymentSource {
  SepaCreditTransfer = 'sepa_credit_transfer',
  SepaDebit = 'sepa_debit',
  EurBankTransfer = 'eur_bank_transfer',
}

export interface IChargeParams {
  paymentSource: EPaymentSource;
}

/** Funds that a customer (mostly buyers) can have in COS-Payment. */
export interface IPaymentFunds {
  /** Currency for all amounts in this object. */
  currency: ECurrency;
  /**
   * Amount that is available for additional charges.
   * @format double
   */
  availableAmount: number;
  /**
   * Amount that is already charged to invoices or auction
   * purchase volumes and therefore not "available" anymore.
   * @format double
   */
  alreadyChargedAmount: number;
  /**
   * Full amount that has been transferred by the customer.
   *
   * This is the sum of "availableAmount" and "alreadyChargedAmount".
   * @format double
   */
  transferredTotalAmount: number;
}

export enum EPaymentFundsTransactionType {
  SEPA_CREDIT_TRANSFER = 'SEPA_CREDIT_TRANSFER',
  EUR_BANK_TRANSFER = 'EUR_BANK_TRANSFER',
}

/** A transaction, leading to funds to be available. */
export interface IPaymentFundsTransaction {
  /**
   * Timestamp when the transaction has been committed.
   * @format date-time
   */
  createdAt?: string;
  /** The currency of the transaction amount. */
  currency: ECurrency;
  /**
   * The amount that is transferred with the transaction (i.e. the amount
   * that will be added to the "available" funds).
   * @format double
   */
  amount: number;
  /**
   * The reference string which was given to the transaction.
   * For a SEPA transfer, this is the content of the "reference" field.
   */
  transactionReference: string;
  senderBankAccountName: string;
  senderBankAccountNumber: string;
  /** Specifies the type of the transaction. */
  transactionType?: EPaymentFundsTransactionType;
}

/** A payment funds charge, as seen by internal users. */
export interface IInternalPaymentFundsChargeView {
  /**
   * Timestamp when the funds have been charged.
   * @format date-time
   */
  createdAt?: string;
  /** The currency of the transaction amount. */
  currency: ECurrency;
  /**
   * The amount that is charged from the funds.
   * @format double
   */
  amount: number;
  /** The description of the charge (if available). */
  chargeDescription: string;
  /** UUID of associated auction (if available) */
  auctionReference: string;
  /** Reference to external invoice (if available). */
  externalInvoiceReference: string;
  /** Article that is paid with this charge (null if no/unknown article). */
  invoicedArticle: EInvoicedArticle;
  /**
   * The auction associated with the charge
   * (if there is an auction associated).
   */
  auction: IAuction;
}

export interface IBalanceAdjustmentValuePayloadParams {
  /** @format double */
  balanceAdjustmentValue: number;
  reason: string;
}

/**
 * Inform that a new resource was created. Server will
 * add a Location header and set status to 201
 */
export interface ReturnNewResourceISellerBankAccount {
  location: string;
  /** @format double */
  statusCode: number;
  /** the body to be sent */
  body: ISellerBankAccount;
}

/** Parameters for creating a seller bank account object. */
export interface ISellerBankAccountCreationParams {
  /**
   * The label of a seller bank account, which can be used to identify
   * the bank account easier than using the IBAN.
   */
  label: string;
  /** IBAN of the bank account. */
  iban: string;
  /** The seller account that this bank account is assigned to. */
  _fk_uuid_sellerAccount: string;
  /**
   * Optional parameter, allowing to associated bank accounts with
   * existing Stripe bank accounts.
   */
  externalAccountReference?: string;
}

/** Different types of statuses that a payment account owner can have. */
export enum EPaymentAccountVerificationStatus {
  Unverified = 'unverified',
  Pending = 'pending',
  Verified = 'verified',
}

/** Different types of reason for which a payment account can be disabled. */
export enum EPaymentAccountDisabledReason {
  RequirementsPastDue = 'requirements.past_due',
  RequirementsPendingVerification = 'requirements.pending_verification',
  RejectedFraud = 'rejected.fraud',
  RejectedTermsOfService = 'rejected.terms_of_service',
  RejectedListed = 'rejected.listed',
  RejectedOther = 'rejected.other',
  Listed = 'listed',
  UnderReview = 'under_review',
  Other = 'other',
}

/**
 * The account verification state contains all types of
 * information regarding the KYC (Know Your Customer) process.
 * @deprecated
 */
export interface ISellerAccountVerificationState {
  payoutsEnabled: boolean;
  /** @format date-time */
  paymentCurrentDeadline: string;
  /** Different types of statuses that a payment account owner can have. */
  paymentOwnerStatus: EPaymentAccountVerificationStatus;
  paymentOwnerDetails: string;
  paymentCurrentlyDue: string[];
  paymentPendingVerification: string[];
  /** Different types of reason for which a payment account can be disabled. */
  paymentDisabledReason: EPaymentAccountDisabledReason;
}

/** The state of a payment transaction. */
export enum EPaymentBalanceTransactionState {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
}

export interface IPaymentBalanceTransaction {
  /** @format double */
  value: number;
  currency: string;
  /** The state of a payment transaction. */
  state: EPaymentBalanceTransactionState;
  /**
   * The auction model, which is a central element in the data model, referencing and referenced by most other
   * entities in the model.
   *
   * TODO
   */
  associatedAuction: IAuction;
}

/** Balance for payments (e.g. for a given seller user or account) */
export interface IPaymentBalance {
  /** @format double */
  outstandingAmount: number;
  /** @format double */
  transferredAmount: number;
  /** @format double */
  paidOutAmount: number;
}

export interface IRenegotiationApplicationView {
  uuid: string;
  /** @format date-time */
  startedAt: string;
  /** @format double */
  lastBuyerOffer: number;
  /** @format double */
  lastSellerOffer: number;
  /** Information about the rounds */
  roundData: IRenegotiationRoundData;
  /**
   * When renegotiation still in progress, the timeout in seconds.
   * @format double
   */
  timeoutInSeconds: number;
  /** @format date-time */
  finishedAt: string;
}

export interface IManualRenegotiationFinishedResponse {
  reinsertedUuid?: string;
}

export enum EManualRenegotiationFinishedResult {
  DISABLED = 'DISABLED',
  REINSERTED = 'REINSERTED',
  SOLD = 'SOLD',
}

export interface IManualRenegotiationFinishedSoldParams {
  /**
   * The amount the buyer pays for the vehicle.
   * @format double
   */
  buyerPrice: number;
  /**
   * The subsidized amount. Seller amount = buyer price + seller subsidy.
   * @format double
   */
  sellerSubsidyAmount?: number;
  /**
   * The discount applied to the buyer fee.
   * @format double
   */
  buyerFeeDiscount?: number;
  /**
   * The reason for the buyer fee discount
   *
   * Cannot typehint EAuctionDiscountReason because it's defined in marketplace
   */
  buyerFeeDiscountReason?: string;
  /** The UUID of the new buyer, in case the auction was sold to another buyer. */
  newBuyerUuid?: string;
}

/** Auction rejection payloads. */
export interface IBasicAuctionRejectionPayloadParams {
  /**
   * These are the possible reasons that an auction could be rejected during
   * the renegotiation process. These are meant to be used as categories of
   * rejection rationals, with the details (if applicable) being supplied
   * by a separate 'auctionRejectionNote' metric.
   */
  rejectionReason: EAuctionRejectionReason;
  rejectionReasonNote?: string;
  rejectionReasonCompetitorName?: string;
  /** Allow client to store a memo message when reinserting the auction */
  memoMessage?: string;
  /** Allow client to store the user that is reinserting the auction */
  memoUser?: IGeneralUser;
}

export type IManualRenegotiationFinishedDisableParams = IBasicAuctionRejectionPayloadParams;

export interface IManualRenegotiationFinishedReinsertParams {
  /** @format double */
  newMinAskPrice: number;
  /** @format double */
  newStartingBidValue?: number;
  /** @format date-time */
  newEndingTime?: string;
  instantPurchase?: boolean;
  /** When set to true, the auction will be published directly after being created. */
  publishDirectly?: boolean;
  /** When set to true, the auction will be published without review. */
  skipReview?: boolean;
}

export interface IManualRenegotiationFinishedRequest {
  auctionUuid: string;
  internalUserUuid: string;
  result: EManualRenegotiationFinishedResult;
  soldParams?: IManualRenegotiationFinishedSoldParams;
  disableParams?: IManualRenegotiationFinishedDisableParams;
  reinsertParams?: IManualRenegotiationFinishedReinsertParams;
}

export enum EFrequentBuyerStatusTypeNames {
  None = 'none',
  Bronze = 'bronze',
  Silver = 'silver',
  Gold = 'gold',
  Platinum = 'platinum',
}

export enum EFrequentBuyerStatusPerkNames {
  SeeReservePrice = 'see_reserve_price',
  PriorityCaseHandling = 'priority_case_handling',
  FreeVehicleReturn = 'free_vehicle_return',
}

/** The perks of a frequent buyer status */
export interface IFrequentBuyerStatusPerks {
  /** The name of the perk */
  name: EFrequentBuyerStatusPerkNames;
  /** Default text of the perk */
  text: string;
  /** Whether the perk is available to the current Frequent Buyer Status Type */
  available: boolean;
}

/** The status of a buyer user. */
export interface IFrequentBuyerStatusTypes {
  uuid: string;
  /**
   * Indicating the time when the entity has been created, i.e. when the entity was
   * persisted to the database.
   * @format date-time
   */
  createdAt?: string;
  /**
   * Indicating the last time a value of the entity has been changed (updated).
   * @format date-time
   */
  updatedAt?: string;
  /**
   * Indicating the time when the entity has been (softly) deleted.
   * "null" if entry is not deleted.
   * @format date-time
   */
  deletedAt?: string;
  /**
   * The rank of the status
   * 1 being the highest
   * @format double
   */
  statusRank: number;
  /**
   * The threshold value required to achieve this status.
   * @format double
   */
  threshold: number;
  /** Whether this status is currently active. */
  isActive: boolean;
  /** The name of the status */
  statusName: EFrequentBuyerStatusTypeNames;
  /**
   * The discount percentage for the status
   * @format double
   */
  discountPercentage: number;
  /** The perks of the status */
  perks: IFrequentBuyerStatusPerks[];
}

/** Summary of a buyer's status including current status, progress, and next achievable status */
export interface IFrequentBuyerStatusSummary {
  uuid: string;
  /**
   * Indicating the time when the entity has been created, i.e. when the entity was
   * persisted to the database.
   * @format date-time
   */
  createdAt?: string;
  /**
   * Indicating the last time a value of the entity has been changed (updated).
   * @format date-time
   */
  updatedAt?: string;
  /**
   * Indicating the time when the entity has been (softly) deleted.
   * "null" if entry is not deleted.
   * @format date-time
   */
  deletedAt?: string;
  /** Unique identifier of the buyer account */
  _fk_uuid_account: string;
  /** Unique identifier of the next achievable status, if any */
  _fk_uuid_nextStatus?: string;
  /** Unique identifier of the fallback status if no purchases are made */
  _fk_uuid_statusIfStopPurchases: string;
  /** Unique identifier of the current status */
  _fk_uuid_frequentBuyerStatus: string;
  /**
   * Start date of the current status
   * @format date-time
   */
  currentStatusSince: string;
  /**
   * Expiry date of the current status
   * @format date-time
   */
  currentStatusUntil: string;
  /**
   * Total number of purchases made by the buyer in the current period
   * @format double
   */
  currentPurchasesCount: number;
  /** List of UUIDs representing auctions purchased by the buyer in the current period */
  currentPurchasedAuctions: string[];
  /**
   * Total amount of discounts granted to the buyer in the current period
   * @format double
   */
  currentPeriodDiscounts: number;
  /**
   * Total amount of discounts granted to the buyer in all previous periods combined
   * @format double
   */
  sumOfAllDiscountsApplied: number;
  /**
   * Number of vehicles needed to achieve the next status
   * @format double
   */
  nextStatusPurchasesNeeded: number;
  /**
   * Number of vehicles needed to retain the current status in the next period
   * @format double
   */
  retainCurrentStatusPurchasesNeeded: number;
  /**
   * Date when the fallback status will become active if no purchases are made
   * @format date-time
   */
  statusIfStopPurchasesWhen: string;
  /** List of other statuses with details */
  otherStatuses: IFrequentBuyerStatusTypes[];
  /**
   * The period start date of a buyer account
   * @format date-time
   */
  currentPeriodStart: string;
  /**
   * The period end date of a buyer account
   * @format date-time
   */
  currentPeriodEnd: string;
  /**
   * Date when the header animation was viewed
   * @format date-time
   */
  headerAnimationViewedAt: string | null;
  /**
   * Date when the detail animation was viewed
   * @format date-time
   */
  detailsAnimationViewedAt: string | null;
}

export enum EFrequentBuyerStatusSources {
  PurchasesCurrentPeriod = 'purchases_current_period',
  PurchasesPreviousPeriod = 'purchases_previous_period',
  Manual = 'manual',
}

/** The frequent buyer status of a buyer user. */
export interface IFrequentBuyerStatus {
  uuid: string;
  /**
   * Indicating the time when the entity has been created, i.e. when the entity was
   * persisted to the database.
   * @format date-time
   */
  createdAt?: string;
  /**
   * Indicating the last time a value of the entity has been changed (updated).
   * @format date-time
   */
  updatedAt?: string;
  /**
   * Indicating the time when the entity has been (softly) deleted.
   * "null" if entry is not deleted.
   * @format date-time
   */
  deletedAt?: string;
  /** The UUID of the buyer account this status belongs to */
  _fk_uuid_account: string;
  /** The UUID of the status type (e.g. none, bronze, silver, gold) */
  _fk_uuid_statusType: string;
  /**
   * The date from which this status is valid
   * @format date-time
   */
  validFrom: string;
  /**
   * The date until which this status is valid
   * @format date-time
   */
  validUntil: string;
  /** The source of this status (purchases, previous period, manual) */
  source: EFrequentBuyerStatusSources;
}

export interface IFrequentBuyerStatusAndSummary {
  statusSummary: IFrequentBuyerStatusSummary | null;
  status:
    | (IFrequentBuyerStatus & {
        /** The status of a buyer user. */
        statusType: IFrequentBuyerStatusTypes;
      })
    | null;
}

export enum TAnimationType {
  Header = 'header',
  Details = 'details',
}

export enum EVehicleInspectionRequestVehiclePriceEstimationStatus {
  FETCHING = 'FETCHING',
  DONE = 'DONE',
  NOT_ENOUGH_DATA = 'NOT_ENOUGH_DATA',
}

export enum EVehicleInspectionRequestVehiclePriceAssessment {
  GOOD = 'GOOD',
  SLIGHTLY_INCREASED = 'SLIGHTLY_INCREASED',
  TOO_HIGH = 'TOO_HIGH',
}

export interface IVehicleInspectionRequestVehicleView {
  uuid: string;
  /** @format date-time */
  createdAt?: string;
  /** @format date-time */
  updatedAt?: string;
  /** @format date-time */
  deletedAt?: string;
  make?: string;
  model?: string;
  vin?: string;
  ez?: string;
  hasCoCPapers?: boolean;
  isAdditionalTaxPaid?: boolean;
  /** @format double */
  previousOwners?: number;
  comments?: string;
  attachments?: string[];
  knownDamages?: string;
  /** @format double */
  purchasePrice?: number;
  /** @format double */
  minimumPrice?: number;
  isTaxationDifferential?: boolean;
  /** @format double */
  mileage?: number;
  /** @format double */
  position?: number;
  _fk_uuid_request?: string;
  _fk_uuid_appointment?: string | null;
  priceEstimationStatus: EVehicleInspectionRequestVehiclePriceEstimationStatus;
  priceAssessment: EVehicleInspectionRequestVehiclePriceAssessment;
}

export interface IVehicleInspectionRequestUserView {
  uuid: string;
  mailAddress: string;
  firstName: string;
  lastName: string;
  urlToProfilePicture: string;
}

export enum EVehicleInspectionRequestState {
  DRAFT = 'DRAFT',
  SUBMITTED = 'SUBMITTED',
}

export enum EVehicleInspectionRequestSource {
  COS = 'COS',
  ALPHA_CONTROLLER = 'ALPHA_CONTROLLER',
}

export interface IVehicleInspectionRequestVehicle {
  uuid: string;
  /** @format date-time */
  createdAt?: string;
  /** @format date-time */
  updatedAt?: string;
  /** @format date-time */
  deletedAt?: string;
  make?: string;
  model?: string;
  vin?: string;
  ez?: string;
  hasCoCPapers?: boolean;
  isAdditionalTaxPaid?: boolean;
  /** @format double */
  previousOwners?: number;
  comments?: string;
  attachments?: string[];
  knownDamages?: string;
  /** @format double */
  purchasePrice?: number;
  /** @format double */
  minimumPrice?: number;
  isTaxationDifferential?: boolean;
  /** @format double */
  mileage?: number;
  /** @format double */
  position?: number;
  _fk_uuid_request?: string;
  _fk_uuid_appointment?: string | null;
}

export interface IVehicleInspectionRequestView {
  uuid: string;
  /** @format date-time */
  createdAt?: string;
  /** @format date-time */
  updatedAt?: string;
  /** @format date-time */
  submittedAt?: string;
  /** @format date-time */
  deletedAt?: string;
  /** @format double */
  numberOfVehicles: number;
  userHasRegularAppointment: boolean;
  /** @format date-time */
  appointmentDate?: string;
  /** @format date-time */
  desiredDateRangeStart?: string;
  /** @format date-time */
  desiredDateRangeEnd?: string;
  state?: EVehicleInspectionRequestState;
  source?: EVehicleInspectionRequestSource;
  vehicles?: IVehicleInspectionRequestVehicleView[];
  _fk_uuid_user?: string;
  _fk_uuid_creator_user?: string;
  _fk_uuid_address?: string;
  _fk_uuid_account?: string;
  /** @format double */
  numberOfVehicleDataFilled: number;
  canBeEdited: boolean;
  user: IVehicleInspectionRequestUserView;
}

export interface IVehicleInspectionRequest {
  uuid: string;
  /** @format date-time */
  createdAt?: string;
  /** @format date-time */
  updatedAt?: string;
  /** @format date-time */
  submittedAt?: string;
  /** @format date-time */
  deletedAt?: string;
  /** @format double */
  numberOfVehicles: number;
  userHasRegularAppointment: boolean;
  /** @format date-time */
  appointmentDate?: string;
  /** @format date-time */
  desiredDateRangeStart?: string;
  /** @format date-time */
  desiredDateRangeEnd?: string;
  state?: EVehicleInspectionRequestState;
  source?: EVehicleInspectionRequestSource;
  vehicles?: IVehicleInspectionRequestVehicle[];
  _fk_uuid_user?: string;
  _fk_uuid_creator_user?: string;
  _fk_uuid_address?: string;
  _fk_uuid_account?: string;
}

/** From T, pick a set of properties whose keys are in the union K */
export interface PickIVehicleInspectionRequestExcludeKeysSourceOrCreatedAtOrUpdatedAtOrDeletedAt {
  uuid: string;
  /** @format date-time */
  submittedAt?: string;
  /** @format double */
  numberOfVehicles: number;
  userHasRegularAppointment: boolean;
  /** @format date-time */
  appointmentDate?: string;
  /** @format date-time */
  desiredDateRangeStart?: string;
  /** @format date-time */
  desiredDateRangeEnd?: string;
  state?: EVehicleInspectionRequestState;
  vehicles?: IVehicleInspectionRequestVehicle[];
  _fk_uuid_user?: string;
  _fk_uuid_creator_user?: string;
  _fk_uuid_address?: string;
  _fk_uuid_account?: string;
}

/** Construct a type with the properties of T except for those in type K. */
export type OmitIVehicleInspectionRequestSourceOrCreatedAtOrUpdatedAtOrDeletedAt =
  PickIVehicleInspectionRequestExcludeKeysSourceOrCreatedAtOrUpdatedAtOrDeletedAt;

export type IUpdateVehicleInspectionRequest = OmitIVehicleInspectionRequestSourceOrCreatedAtOrUpdatedAtOrDeletedAt;

export interface IVehicleInspectionRequestVehiclePriceAssessmentResponse {
  assessment: EVehicleInspectionRequestVehiclePriceAssessment;
}

export interface IVehicleInspectionRequestVehiclePriceAssessmentRequest {
  /** @format double */
  price: number;
}

export interface IPageIVehicleInspectionRequestView {
  items: IVehicleInspectionRequestView[];
  /** @format double */
  total: number;
  /** @format double */
  page: number;
  /** @format double */
  pageSize?: number;
}

export interface IHasInspectionRequestCountsByState {
  countsByState: {
    /** @format double */
    DRAFT?: number;
    /** @format double */
    SUBMITTED?: number;
  };
}

/** Make all properties in T optional */
export type PartialIVehicleInspectionRequestVehicle = object;

/** A map of errors returned by the parsing functions (related to a single item). */
export type XlsxParserParsingErrorsMapIVehicleInspectionRequestVehicle = object;

/**
 * The result of parsing for a single row in the spreadsheet.
 * The "value" property returns a Partial<T> because it may not be possible to find all specified columns.
 * The "errors" property represents a map of errors found during parsing of this single row (null when no errors were found).
 * "rowNumber" is the ordinal position of the row (starting with 1), disregarding empty rows in between and also the position
 * of the header itself (actual data can start further down in the spreadsheet).
 * "rowNumberInSpreadsheet" represents the actual position of the row in the spreadsheet.
 */
export interface XlsxParserParsedItemIVehicleInspectionRequestVehicle {
  /** @format double */
  rowNumberInSpreadsheet: number;
  /** @format double */
  rowNumber: number;
  errors: XlsxParserParsingErrorsMapIVehicleInspectionRequestVehicle | null;
  /** Make all properties in T optional */
  value: PartialIVehicleInspectionRequestVehicle;
}

/** The final result of parsing, containing a list of items, and lists for detected and undetected columns. */
export interface XlsxParserResultIVehicleInspectionRequestVehicle {
  undetectedColumns: string[];
  detectedColumns: string[];
  items: XlsxParserParsedItemIVehicleInspectionRequestVehicle[];
}

export type InspectionRequestVehicleXlsxParserResult = XlsxParserResultIVehicleInspectionRequestVehicle;

/** From T, pick a set of properties whose keys are in the union K */
export interface PickIVehicleInspectionRequestVehicleUuidOrMakeOrModelOrVinOrEzOrIsAdditionalTaxPaidOrHasCoCPapersOrMileageOrMinimumPriceOrIsTaxationDifferentialOrPurchasePriceOrPreviousOwnersOrKnownDamagesOrAttachmentsOrComments {
  uuid: string;
  make?: string;
  model?: string;
  vin?: string;
  ez?: string;
  isAdditionalTaxPaid?: boolean;
  hasCoCPapers?: boolean;
  /** @format double */
  mileage?: number;
  /** @format double */
  minimumPrice?: number;
  isTaxationDifferential?: boolean;
  /** @format double */
  purchasePrice?: number;
  /** @format double */
  previousOwners?: number;
  knownDamages?: string;
  attachments?: string[];
  comments?: string;
}

/** From T, pick a set of properties whose keys are in the union K */
export interface PickIVehicleInspectionRequestUuidOrAppointmentDateOrDesiredDateRangeStartOrDesiredDateRangeEndOrCreatedAtOrState {
  uuid: string;
  /** @format date-time */
  createdAt?: string;
  /** @format date-time */
  appointmentDate?: string;
  /** @format date-time */
  desiredDateRangeStart?: string;
  /** @format date-time */
  desiredDateRangeEnd?: string;
  state?: EVehicleInspectionRequestState;
}

export interface IVehicleRequestedForInspectionView {
  /** From T, pick a set of properties whose keys are in the union K */
  vehicle: PickIVehicleInspectionRequestVehicleUuidOrMakeOrModelOrVinOrEzOrIsAdditionalTaxPaidOrHasCoCPapersOrMileageOrMinimumPriceOrIsTaxationDifferentialOrPurchasePriceOrPreviousOwnersOrKnownDamagesOrAttachmentsOrComments;
  /** From T, pick a set of properties whose keys are in the union K */
  inspectionRequest: PickIVehicleInspectionRequestUuidOrAppointmentDateOrDesiredDateRangeStartOrDesiredDateRangeEndOrCreatedAtOrState;
  address: {
    countryCode: ECountryCode;
    city: string;
    zipCode?: string;
    addressLine?: string;
    uuid: string;
  };
  user: {
    lastName: string;
    firstName: string;
    uuid: string;
  };
  account: {
    name: string;
    uuid: string;
  };
}

export interface IPageIVehicleRequestedForInspectionView {
  items: IVehicleRequestedForInspectionView[];
  /** @format double */
  total: number;
  /** @format double */
  page: number;
  /** @format double */
  pageSize?: number;
}

export interface IGPRRequestingUserInfo {
  accountName: string;
  /** Seller Business Type */
  sellerBusinessType: ESellerBusinessType;
  /** The first name of the user */
  firstName: string;
  /** The last name of the user */
  lastName: string;
  /** The mail address of the user */
  mailAddress: string;
  /** Slot 1 for the phone number of the user */
  phoneNumber: string;
  city: string;
  countryCode: ECountryCode;
}

export interface IGPRAssociatedAuctionInfo {
  /**
   * The state of the auction, indicating whether an auction is not yet published (DRAFTED), running (ACTIVE),
   * closed successfully (CLOSED_WAITING_FOR_PAYMENT, CLOSED_WAITING_FOR_PICKUP, FINISHED), failed (CLOSED_NO_BIDS,
   * CLOSED_UNDER_MINIMUM_ASK) or disabled (DISABLED).
   *
   * Depending on the state, different timestamps are available per auction.
   */
  state: EAuctionState;
  /**
   * @deprecated
   * @format double
   */
  id: number;
  /**
   * The minimal price the seller user wants to reach for this specific auction.
   * If an auction ends above that price, the auction is successful right away
   * (switching to state CLOSED_WAITING_FOR_PAYMENT).
   *
   * If the auction ends below the minimum required ask, the auction will switch to state CLOSED_UNDER_MINIMUM_ASK,
   * which then requires the seller's explicit "confirmation" or "rejection" of the highest bid value.
   * @format double
   */
  minimumRequiredAsk: number;
  /**
   * Value that is indicating the current highest bid value on the auction.
   * This is of course a dynamic field, that will be incremented on every new bid placed on the auction.
   * @format double
   */
  currentHighestBidValue: number;
  /**
   * The exact date+time when the auction is expiring/ending.
   * @format date-time
   */
  endingTime: string;
}

export interface IGPRInternalView {
  /**
   * @deprecated
   * @format double
   */
  id: number;
  uuid: string;
  /**
   * Indicating the time when the entity has been created, i.e. when the entity was
   * persisted to the database.
   * @format date-time
   */
  createdAt?: string;
  /**
   * Indicating the last time a value of the entity has been changed (updated).
   * @format date-time
   */
  updatedAt?: string;
  /**
   * Indicating the time when the entity has been (softly) deleted.
   * "null" if entry is not deleted.
   * @format date-time
   */
  deletedAt?: string;
  /** If an auction is created in association with a GPR, the uuid of the auction is linked here. */
  _fk_uuid_auction?: string;
  /** The UUID of the creating seller user. */
  _fk_uuid_sellerUser?: string;
  /** The UUID of the creating seller account. */
  _fk_uuid_sellerAccount?: string;
  /** The UUID of the internal user who fulfilled this GPR. */
  _fk_uuid_fulfillingInternalUser?: string;
  /** The UUID of the principal pricer who filled in the principalPriceSuggestion */
  _fk_uuid_principalPricerInternalUser?: string;
  /** The UUID of the secondary pricer who filled in the secndaryPriceSuggestion */
  _fk_uuid_secondaryPricerInternalUser?: string;
  /** The current status of the GPR. */
  status?: EGPRStatus;
  /**
   * The datetime that the GPR was submitted at (completed and sent for review).
   * @format date-time
   */
  submittedAt?: string;
  /**
   * The datetime that the GPR was fulfilled at (when the guaranteed price was sent back).
   * @format date-time
   */
  fulfilledAt?: string;
  /**
   * The date at which a GPR was rejected at. If null, this means the GPR has not been rejected.
   * @format date-time
   */
  rejectedAt?: string;
  /**
   * The date at which the principal pricer suggested a price (without immediately fulfilling the GPR)
   * @format date-time
   */
  principalGuaranteedPriceSuggestedAt?: string;
  /**
   * The date at which a secondary pricer suggested a price
   * @format date-time
   */
  secondaryGuaranteedPriceSuggestedAt?: string;
  /** The supplied reason that the GPR was rejected, if they were rejected. */
  rejectionReason?: EGPRRejectionReason;
  /** The comment that allows the price team to explain their decision when rejecting the GPR. */
  rejectionReasonComment?: string;
  /** The comment that allows the price team to explain the pricing decision when fulfilling the GPR. */
  fulfillmentReasonComment?: string;
  /**
   * The latest datetime a fulfilled GPR can be booked.
   * Calculated as GPR_UNBOOKED_VALIDITY_DURATION_DAYS after fulfilledAt or extendedAt.
   * @format date-time
   */
  bookableUntil?: string;
  /**
   * The datetime that an inspector was booked for a GPR.
   * This denotes an acceptance of the GP.
   * @format date-time
   */
  inspectorBookedAt?: string;
  /**
   * The datetime that the GPR was extended at (when the data team booked it after the expiration date).
   * @format date-time
   */
  extendedAt?: string;
  /**
   * The datetime that the GPR was refreshed at (when the seller refresh a rejected/expired GPR back to the submit state).
   * @format date-time
   */
  refreshedAt?: string;
  /**
   * The number of days that the GPR is valid for.
   * Counted from the `fulfilledAt` timestamp.
   * @format double
   */
  daysValidFor?: number;
  /**
   * The guaranteed price that the principal pricer would choose (while waiting for the secondary)
   * Usually the secondary price comes first, and the principal pricer just enters a final price.
   * But in case there is no secondary price yet, he can store his suggestion here
   * @format double
   */
  principalGuaranteedPriceSuggestion?: number;
  /**
   * A suggestion for the guaranteed price made by the secondary pricer.
   * @format double
   */
  secondaryGuaranteedPriceSuggestion?: number;
  /**
   * The final guaranteed price which was calculated by COS.
   * @format double
   */
  guaranteedPrice?: number;
  /**
   * The fee which will be paid by the user should they accept the GP and book an inspector.
   * @format double
   */
  calculatedFee?: number;
  /** The data of the vehicle desired to be guaranteed. */
  vehicleData: IGPRVehicleData;
  /** The URL for the document that is created after the GPR is fulfilled. */
  documentReportURL?: string;
  /**
   * Fields that serve to improve the inspection process after request for the internal users,
   * a seller user should be able to select date of inspection and the location of the vehicle.
   * Some sellers also do have regular appointments, in this case they do not have to select a date.
   * @format date-time
   */
  appointmentDate?: string;
  selectedLocation?: string;
  /**
   * A flag in which says if the GPR can be refreshed.
   * A GPR can be refreshed when it was rejected or expired.
   */
  isRefreshAllowed?: boolean;
  /**
   * Property to identify the origin of the request to create the GPR.
   * Used when integrating with third-party systems.
   */
  origin?: EGPRIntegrationOrigin;
  requestingUser: IGPRRequestingUserInfo;
  /** @format date-time */
  firstResponseAt: string;
  associatedAuction: IGPRAssociatedAuctionInfo;
  mobileDeLink: string;
}

export interface IPageIGPRInternalView {
  items: IGPRInternalView[];
  /** @format double */
  total: number;
  /** @format double */
  page: number;
  /** @format double */
  pageSize?: number;
}

/**
 * An object defining the data of vehicles found to be similar to a given trade-in vehicle.
 * Picked interface is meant to keep this in line with IVehicle.
 */
export interface ISimilarCOSVehicle {
  /** @format double */
  enginePowerInHp: number;
  make: string;
  model: string;
  ez: string;
  /** @format double */
  mileageInKm: number;
  readyToDrive: EVehicleReadyToDriveStatus;
  /** The label of the auction, consisting of the vehicle make, model and date of first registration (EZ). */
  label?: string;
  /**
   * @deprecated
   * @format double
   */
  id?: number;
  /**
   * The minimal price the seller user wants to reach for this specific auction.
   * If an auction ends above that price, the auction is successful right away
   * (switching to state CLOSED_WAITING_FOR_PAYMENT).
   *
   * If the auction ends below the minimum required ask, the auction will switch to state CLOSED_UNDER_MINIMUM_ASK,
   * which then requires the seller's explicit "confirmation" or "rejection" of the highest bid value.
   * @format double
   */
  minimumRequiredAsk?: number;
  /**
   * Number of bids placed on an auction.
   * @format double
   */
  numBids?: number;
  /**
   * The price that this similar vehicle got sold for (if there is a finished auction available for it)
   * @format double
   */
  price: number;
  /**
   * The date that this similar vehicle got sold (if there is a finished auction available for it)
   * @format date-time
   */
  purchaseDate: string;
  /**
   * The computed similarity of this vehicle to the vehicle in the original trade in valuation request (MPR)
   * @format double
   */
  similarityScore: number;
}

export interface IGPRInternalPricingData {
  /** @format double */
  b2bPrice: number;
  /** @format double */
  datPrice: number;
  highUncertainty: boolean;
  similarCOSVehicles: ISimilarCOSVehicle[];
}

/**
 * This is an extension of the `ITradeInPricingVehicleData` type. The fields present here are involved in the same purpose,
 * but are not `IVehicle` fields, hence their lack of inclusion in the `ITradeInPricingVehicleData` type.
 */
export interface ITradeInPricingData {
  /**
   * The degree to which the presence or lack of airbags affected the price estimation for this vehicle.
   * @format double
   */
  airbags?: number;
  euroNorm: EEuroNorm;
  /** @format double */
  numSeats: number;
  category: EVehicleCategory;
  ac: EAirCondition;
  parkingAssistance: EParkingAssistance;
  fuelType: EFuelType;
  transmission: ETransmissionType;
  upholstery: EUpholsteryType;
  doors: EVehicleDoors;
  /** @format double */
  engineSizeInCcm: number;
  /** @format double */
  enginePowerInHp: number;
  ez: string;
  /** @format double */
  mileageInKm: number;
}

/** An object which holds all the data required to make the MPR/Trade-in details dashboard. */
export interface ITradeInValuationResultDetails {
  similarCOSVehicles: ISimilarCOSVehicle[];
  /**
   * This is an extension of the `ITradeInPricingVehicleData` type. The fields present here are involved in the same purpose,
   * but are not `IVehicle` fields, hence their lack of inclusion in the `ITradeInPricingVehicleData` type.
   */
  pricingData: ITradeInPricingData;
}

export interface IMarketPriceRequest {
  /**
   * @deprecated
   * @format double
   */
  id: number;
  uuid: string;
  /**
   * Indicating the time when the entity has been created, i.e. when the entity was
   * persisted to the database.
   * @format date-time
   */
  createdAt?: string;
  /**
   * Indicating the last time a value of the entity has been changed (updated).
   * @format date-time
   */
  updatedAt?: string;
  /**
   * Indicating the time when the entity has been (softly) deleted.
   * "null" if entry is not deleted.
   * @format date-time
   */
  deletedAt?: string;
  /** @format double */
  maxPrice: number;
  /** @format double */
  minPrice: number;
  /** @format date-time */
  requestedAt: string;
  /** @format date-time */
  valuatedAt: string;
  serializedVehicleConfiguration: string;
  vehicleConfiguration: IVehicleConfiguration;
  comment: string;
  _fk_uuid_sellerUser: string;
  vin: string;
  /** @format double */
  datPrice: number;
  /** @format double */
  b2bPrice: number;
  /** @format double */
  b2bMinPrice: number;
  /** @format double */
  b2bMaxPrice: number;
  /** @format double */
  b2cPrice: number;
  /** @format double */
  b2cMinPrice: number;
  /** @format double */
  b2cMaxPrice: number;
  highUncertainty: boolean;
  /**
   * Denotes if the customer who requested the trade in valuation (market price request) felt that it was useful to them.
   * Will be unset (null/undefined) if no feedback has been given and either false or true otherwise depending on if the
   * feedback was negative or positive respectively.
   */
  positiveCustomerFeedback: boolean;
  /**
   * A flag indicating if and when the customer requested an inspector from the MPR UI.
   * If this flag is populated, that means we already sent an internal email communicating the request.
   * @format date-time
   */
  inspectorRequestedAt: string;
  /** The creation origin of the MPR (or TradeInValuationRequest) */
  origin: EMarketPriceRequestOrigin;
  /**
   * The UUID of any auction which is created after this MPR was made.
   * This relationship is used for seeing how many auctions are getting created as a result or partial result of an MPR having been executed.
   */
  _fk_uuid_auction: string;
  /** The UUID of the Guaranteed Price Request that is associated with this MPR, if it exists. */
  _fk_uuid_guaranteedPriceRequest: string;
  /**
   * The UUID of the request to the pricing service.
   * This is a unique identifier which can tie together a given MPR object
   * with the request sent to the pricing service to populate it with data.
   * This is often useful for forensics/troubleshooting.
   */
  estimationRequestUuid?: string;
  /**
   * Fields that serve to improve the inspection process after request for the internal users,
   * a seller user should be able to select date of inspection and the location of the vehicle.
   * Some sellers also do have regular appointments, in this case they do not have to select a date.
   * @format double
   */
  minimumRequiredAsk?: number;
  /** @format date-time */
  appointmentDate?: string;
  selectedLocation?: string;
  /** An object which holds all the data required to make the MPR/Trade-in details dashboard. */
  details: ITradeInValuationResultDetails;
}

export interface IMPRBaseCreationParams {
  sellerUuid: string;
  vin: string;
  make: string;
  model: string;
  subModel?: string;
  ez: string;
  /** @format double */
  mileageInKm: number;
  fuelType: EFuelType;
  /** @format double */
  enginePowerInHp: number;
}

/**
 * A basic view of the SellerAccount, it features just a small fraction of the attributes (the most basic ones).
 * This interface is intended to be used in cases where we don't need (or want) all SellerAccount information but only a simplified version of it.
 * @deprecated
 */
export interface ISellerAccountBasicView {
  uuid: string;
  addressLine?: string;
  zipCode?: string;
  city: string;
  countryCode: ECountryCode;
  /** Add extra comments do the location (e.g., pick-up instruction in transportation tasks). */
  comment?: string;
  /** Language of the contact person at the location. */
  contactLanguage?: string;
  /** Same as ISellerAccount.name */
  name: string;
  /** Same as ISellerAccount.dealershipType */
  dealershipType: ESellerBusinessType;
}

/** SellerUser view augmented with its basic Seller Account data */
export interface ISellerUserWithAccountBasicView {
  /**
   * @deprecated
   * @format double
   */
  id: number;
  uuid: string;
  /**
   * Indicating the time when the entity has been created, i.e. when the entity was
   * persisted to the database.
   * @format date-time
   */
  createdAt?: string;
  /**
   * Indicating the last time a value of the entity has been changed (updated).
   * @format date-time
   */
  updatedAt?: string;
  /**
   * Indicating the time when the entity has been (softly) deleted.
   * "null" if entry is not deleted.
   * @format date-time
   */
  deletedAt?: string;
  /** The userId generated by the IdP */
  userId?: string;
  /** The state of the user. */
  state: EUserState;
  /** The mail address of the user. */
  mailAddress: string;
  /** The mail address which is using for login. */
  loginMailAddress?: string;
  /** The password of the user. */
  password?: string;
  /** The privilege string, indicating what the user is allowed to do. */
  privileges: string;
  /** The user type. */
  type?: EUserType;
  preferredLanguage: string;
  /**
   * Whether the customer wants to be addressed with honorific form
   * (e.g. "Sie" instead of "Du" in German)
   */
  honorific: boolean;
  /** The first name of the customer. */
  firstName: string;
  /** The last name of the customer. */
  lastName: string;
  /**
   * Multiple slots for phone numbers.
   * "null" if a slot is not used.
   */
  phoneNumber: string;
  phoneNumber2: string;
  phoneNumber3: string;
  /**
   * The referral code that was used by the customer when registering to COS.
   * "null" if no referral code has been used.
   */
  referralCode: string;
  isAllowedToSeeTradeIns: boolean;
  isAllowedToManageTradeIns: boolean;
  isAllowedToSeeTradeInRequests: boolean;
  isAllowedToSeeTradeInValuationSellPrice: boolean;
  isAllowedToSeeTradeInValuationBuyPrice: boolean;
  isAllowedToCreateAuctions: boolean;
  isAllowedToConfirmRejectBelowMin: boolean;
  isAllowedToSeePurchasePrices: boolean;
  isAllowedToSeeClosedAuctions: boolean;
  isAllowedToSeeDirectlyAssociatedAuctions: boolean;
  isAllowedToSeeAllAssociatedAuctions: boolean;
  isAllowedToChangeAccountData: boolean;
  /** Current version vector */
  versionVector: number[];
  /** Indicates this update comes from a remote system */
  isRemoteUpdate: boolean;
  /** Same as {@link IGeneralUser.permissions }, added here for compatibility after migrating Seller Users to General Users */
  permissions: EGeneralUserPermission[];
  /**
   * If this user is enabled for trade-in platform features, this is the preference with
   * which he wants incoming trade-in leads to be valuated.
   */
  tradeInValuationPreference: EDealershipTradeInValuationPreference;
  /** Flag indicating whether the seller user is allowed to make trade-in valuation requests (market price requests). */
  allowTradeInValuationRequests: boolean;
  /** Flag indicating whether the seller user is allowed to make guaranteed price requests. */
  allowGuaranteedPriceRequests: boolean;
  /**
   * The default value of this seller's margin in the Trade In Valuation (Market Price Request) view.
   * @format double
   */
  defaultTradeInValuationMargin: number;
  /** Reference to the SellerAccount to which the seller user is assigned to. */
  _fk_uuid_seller_account: string;
  /** Indicates whether the user itself should be used as the logistics contact for its own auctions. */
  isLogisticsContactForOwnAuctions: boolean;
  /** Same as {@link IGeneralUserSettings.notificationPreferences }, added here for compatibility after migrating Seller Users to General Users */
  notificationPreferences: IUserNotificationPreferences;
  /** Basic Seller Account information */
  sellerAccount: ISellerAccountBasicView;
}

export interface IKAMTradeInValuationRequestView {
  /**
   * @deprecated
   * @format double
   */
  id: number;
  uuid: string;
  /**
   * Indicating the time when the entity has been created, i.e. when the entity was
   * persisted to the database.
   * @format date-time
   */
  createdAt?: string;
  /**
   * Indicating the last time a value of the entity has been changed (updated).
   * @format date-time
   */
  updatedAt?: string;
  /**
   * Indicating the time when the entity has been (softly) deleted.
   * "null" if entry is not deleted.
   * @format date-time
   */
  deletedAt?: string;
  /** @format double */
  maxPrice: number;
  /** @format double */
  minPrice: number;
  /** @format date-time */
  requestedAt: string;
  /** @format date-time */
  valuatedAt: string;
  serializedVehicleConfiguration: string;
  vehicleConfiguration: IVehicleConfiguration;
  comment: string;
  _fk_uuid_sellerUser: string;
  vin: string;
  /** @format double */
  datPrice: number;
  /** @format double */
  b2bPrice: number;
  /** @format double */
  b2bMinPrice: number;
  /** @format double */
  b2bMaxPrice: number;
  /** @format double */
  b2cPrice: number;
  /** @format double */
  b2cMinPrice: number;
  /** @format double */
  b2cMaxPrice: number;
  highUncertainty: boolean;
  /**
   * Denotes if the customer who requested the trade in valuation (market price request) felt that it was useful to them.
   * Will be unset (null/undefined) if no feedback has been given and either false or true otherwise depending on if the
   * feedback was negative or positive respectively.
   */
  positiveCustomerFeedback: boolean;
  /**
   * A flag indicating if and when the customer requested an inspector from the MPR UI.
   * If this flag is populated, that means we already sent an internal email communicating the request.
   * @format date-time
   */
  inspectorRequestedAt: string;
  /** The creation origin of the MPR (or TradeInValuationRequest) */
  origin: EMarketPriceRequestOrigin;
  /**
   * The UUID of any auction which is created after this MPR was made.
   * This relationship is used for seeing how many auctions are getting created as a result or partial result of an MPR having been executed.
   */
  _fk_uuid_auction: string;
  /** The UUID of the Guaranteed Price Request that is associated with this MPR, if it exists. */
  _fk_uuid_guaranteedPriceRequest: string;
  /**
   * The UUID of the request to the pricing service.
   * This is a unique identifier which can tie together a given MPR object
   * with the request sent to the pricing service to populate it with data.
   * This is often useful for forensics/troubleshooting.
   */
  estimationRequestUuid?: string;
  /**
   * Fields that serve to improve the inspection process after request for the internal users,
   * a seller user should be able to select date of inspection and the location of the vehicle.
   * Some sellers also do have regular appointments, in this case they do not have to select a date.
   * @format double
   */
  minimumRequiredAsk?: number;
  /** @format date-time */
  appointmentDate?: string;
  selectedLocation?: string;
  /** SellerUser view augmented with its basic Seller Account data */
  requestingDealership: ISellerUserWithAccountBasicView;
}

export interface IPageIKAMTradeInValuationRequestView {
  items: IKAMTradeInValuationRequestView[];
  /** @format double */
  total: number;
  /** @format double */
  page: number;
  /** @format double */
  pageSize?: number;
}

/**
 * The DAT data that is used to prefill the basic vehicle data inputs when creating a new GPR.
 * The internal references enable autocomplete for those fields.
 */
export interface IGPRInitialDATVehicleInformation {
  /** @format double */
  id?: number;
  uuid: string;
  /** @format date-time */
  createdAt?: string;
  /** @format date-time */
  updatedAt?: string;
  /** @format date-time */
  deletedAt?: string;
  vin: string;
  make: string;
  makeInternalDatReference?: string;
  model: string;
  modelInternalDatReference?: string;
  /** @format double */
  cleanMakeId: number;
  cleanMake: string;
  /** @format double */
  cleanModelId: number;
  cleanModel: string;
  /** @format double */
  cleanGroupId: number;
  cleanGroup: string;
  datBaseModelRaw: string;
  ez: string;
  /** @format double */
  mileageInKm: number;
  isOdometerInMiles: boolean;
  category: EVehicleCategory;
  transmission: ETransmissionType;
  upholstery: EUpholsteryType;
  /** @format double */
  numSeats: number;
  fuelType: EFuelType;
  doors: EVehicleDoors;
  /** @format double */
  engineSizeInCcm: number;
  /** @format double */
  enginePowerInHp: number;
  /** @format double */
  secondaryEnginePowerInHp: number;
  /** @format double */
  combinedEnginePowerInHp: number;
  /** @format double */
  totalRangeInKm: number;
  /** @format double */
  batteryRangeInKm: number;
  /** @format double */
  batteryCapacityInKwh: number;
  urlToBatteryConditionCertificate: string | null;
  /** @format double */
  quickChargeInHours: number;
  /** @format double */
  normalChargeInHours: number;
  chargerPlugType: string;
  energyEfficiencyClass: string;
  batteryConstructionType: string;
  /** @format double */
  batteryVoltage: number;
  isPluginSystem: boolean;
  /** @format double */
  energyConsumption: number;
  hasRentalBattery: boolean;
  bodyColorCode: EVehicleColor;
  /** @format double */
  dimensionWidthInCm: number;
  /** @format double */
  dimensionHeightInCm: number;
  /** @format double */
  dimensionLengthInCm: number;
  /** @format double */
  unloadedWeightInKg: number;
  lastHu: string;
  huReportExists: boolean;
  huReportAvailable: EHuReportAvailable;
  urlsToHuReport: string[];
  /** @format double */
  numPreOwners: number;
  /** @format double */
  numKeys: number;
  vatIdReportable: boolean;
  fullServiceHistoryType: EVehicleFullServiceHistoryType;
  serviceHistoryAvailability: EVehicleServiceHistoryAvailability;
  urlsToServiceHistoryDocument: string[];
  hasMaintenanceBook: boolean;
  urlsToMaintenanceBook: string[];
  isReimportedVehicle: boolean;
  euroNorm: EEuroNorm;
  hadAccident: boolean;
  accidentDescription: string;
  hasDamages: boolean;
  damagesDescription: string;
  damages: IVehicleDamage[];
  additionalInfo: string;
  readyToDrive: EVehicleReadyToDriveStatus;
  readyToDriveDetails: string;
  vehicleImages: IVehicleImage[];
  urlToMotorSound?: string;
  urlToAttachment1: string | null;
  urlToAttachment2: string | null;
  urlToAttachment3: string | null;
  urlToVehicleSummarySheet: string;
  isRegistrationDocumentAvailable: boolean;
  urlToRegistrationDocument: string;
  urlsToRegistrationDocument: string[];
  isRegistrationDocumentPart2Available: boolean;
  urlsToRegistrationDocumentPart2: string[];
  urlsToOtherDocuments: string[];
  urlsToMotorRecordings: string[];
  urlsByLanguageToExpose?: IVehicleExposeByLanguageMap;
  /** @format double */
  estimatedValue: number;
  lastServiceInspectionDate: string;
  /** @format double */
  lastServiceInspectionMileage: number;
  isCocDocumentAvailable: boolean;
  urlsToCocDocument: string[];
  isDataExcerptAvailable: boolean;
  urlsToDataExcerpt: string[];
  countryOfLastRegistration: ECountryCode;
  origin: EVehicleCreationOrigin;
  dataSource: EVehicleDataSource;
  paintState: IVehiclePaintState[];
  tires: IVehicleTireSet[];
  dataWarnings: EVehicleDataWarning[];
  inspectionUuid: string;
  technicalState: IVehicleTechState[];
  fieldsConfirmationStatus: IVehicleFieldConfirmationStatusMap;
  licensePlate: string;
  fuelConsumption: IFuelConsumption;
  co2Emission: ICo2Emission;
  commercialUsage: EVehicleCommercialUsage[];
  isVehicleClassN1: boolean;
  isIntendedUse01: boolean;
  hasEndorsements: boolean;
  sourceLanguage: ELanguageCode;
  isRollable: boolean;
  ac: EAirCondition;
  /** @format double */
  navigation: number;
  headlights: EHeadlights;
  coupling: ECoupling;
  vehicleHeater: EVehicleHeater;
  parkingAssistance: EParkingAssistance;
  /** @format double */
  sunRoof: number;
  /** @format double */
  sportPackage: number;
  isToolkitIncluded: boolean;
  lockingWheelNut: ELockingWheelNut;
  hasAnimalSmell: boolean;
  hasSmokeSmell: boolean;
  equipmentData: IVehicleEquipmentData[];
  equipmentHighlights: IVehicleEquipmentHighlightData[];
  linesByLanguage?: IVehicleLineByLanguageMap;
  externalLineCode?: string;
  externalReports?: IVehicleExternalReport[];
  obd2Readings?: IVehicleObd2Reading[];
  obd2ReadingsFailure?: boolean;
  countOfDrivenAxles: EVehicleCountOfDrivenAxles;
  vehicleMakeDATInternalReference: string;
  vehicleModelDATInternalReference: string;
  vehicleModelVariantDATInternalReference: string;
  vehicleType?: EVehicleType;
}

/** The structure of the request when refreshing a GPR. */
export interface IGPRRefreshData {
  /** The flag that will inform if the user updated the GPR when refreshing it. */
  wasGprUpdated: boolean;
}

/**
 * Inform that a new resource was created. Server will
 * add a Location header and set status to 201
 */
export interface ReturnNewResourceITradeInValuationRequest {
  location: string;
  /** @format double */
  statusCode: number;
  /** the body to be sent */
  body: ITradeInValuationRequest;
}

/**
 * The structure of the augmented list result for fetching many MPRs (Trade In Valuation Requests) augmented with associated GPRs.
 * In this way, it is clear which GPR is associated with which MPR so that they can be easily connected.
 */
export interface IMPRAugmentedWithGPR {
  /**
   * @deprecated
   * @format double
   */
  id: number;
  uuid: string;
  /**
   * Indicating the time when the entity has been created, i.e. when the entity was
   * persisted to the database.
   * @format date-time
   */
  createdAt?: string;
  /**
   * Indicating the last time a value of the entity has been changed (updated).
   * @format date-time
   */
  updatedAt?: string;
  /**
   * Indicating the time when the entity has been (softly) deleted.
   * "null" if entry is not deleted.
   * @format date-time
   */
  deletedAt?: string;
  /** @format double */
  maxPrice: number;
  /** @format double */
  minPrice: number;
  /** @format date-time */
  requestedAt: string;
  /** @format date-time */
  valuatedAt: string;
  serializedVehicleConfiguration: string;
  vehicleConfiguration: IVehicleConfiguration;
  comment: string;
  _fk_uuid_sellerUser: string;
  vin: string;
  /** @format double */
  datPrice: number;
  /** @format double */
  b2bPrice: number;
  /** @format double */
  b2bMinPrice: number;
  /** @format double */
  b2bMaxPrice: number;
  /** @format double */
  b2cPrice: number;
  /** @format double */
  b2cMinPrice: number;
  /** @format double */
  b2cMaxPrice: number;
  highUncertainty: boolean;
  /**
   * Denotes if the customer who requested the trade in valuation (market price request) felt that it was useful to them.
   * Will be unset (null/undefined) if no feedback has been given and either false or true otherwise depending on if the
   * feedback was negative or positive respectively.
   */
  positiveCustomerFeedback: boolean;
  /**
   * A flag indicating if and when the customer requested an inspector from the MPR UI.
   * If this flag is populated, that means we already sent an internal email communicating the request.
   * @format date-time
   */
  inspectorRequestedAt: string;
  /** The creation origin of the MPR (or TradeInValuationRequest) */
  origin: EMarketPriceRequestOrigin;
  /**
   * The UUID of any auction which is created after this MPR was made.
   * This relationship is used for seeing how many auctions are getting created as a result or partial result of an MPR having been executed.
   */
  _fk_uuid_auction: string;
  /** The UUID of the Guaranteed Price Request that is associated with this MPR, if it exists. */
  _fk_uuid_guaranteedPriceRequest: string;
  /**
   * The UUID of the request to the pricing service.
   * This is a unique identifier which can tie together a given MPR object
   * with the request sent to the pricing service to populate it with data.
   * This is often useful for forensics/troubleshooting.
   */
  estimationRequestUuid?: string;
  /**
   * Fields that serve to improve the inspection process after request for the internal users,
   * a seller user should be able to select date of inspection and the location of the vehicle.
   * Some sellers also do have regular appointments, in this case they do not have to select a date.
   * @format double
   */
  minimumRequiredAsk?: number;
  /** @format date-time */
  appointmentDate?: string;
  selectedLocation?: string;
  /**
   * Object description of the GPR object.
   * Most fields are optional because GPRs can be created in a draft state with minimal data and edited from there on.
   * Validation of completion, therefore, takes place upon submission rather than object creation.
   */
  gpr?: IGuaranteedPriceRequest;
}

/**
 * Object that serve to improve the inspection process after requesting an inspector,
 * a seller user should be able to select date of inspection and the location of the vehicle.
 * Some sellers also do have regular appointments, in this case they do not have to select a date.
 */
export interface IMPRInspectionData {
  /** @format double */
  minimumRequiredAsk?: number;
  /** @format date-time */
  appointmentDate?: string;
  selectedLocation?: string;
}

/**
 * The structure of the augmented list result for fetching many MPRs (Trade In Valuation Requests) augmented with
 * the seller name of each MPR used for when a supervisor is requesting all the MPRs of their subordinated sellers.
 * In this way, it is clear which seller is associated with which MPR.
 */
export interface IMPRSellerSupervisorView {
  /**
   * @deprecated
   * @format double
   */
  id: number;
  uuid: string;
  /**
   * Indicating the time when the entity has been created, i.e. when the entity was
   * persisted to the database.
   * @format date-time
   */
  createdAt?: string;
  /**
   * Indicating the last time a value of the entity has been changed (updated).
   * @format date-time
   */
  updatedAt?: string;
  /**
   * Indicating the time when the entity has been (softly) deleted.
   * "null" if entry is not deleted.
   * @format date-time
   */
  deletedAt?: string;
  /** @format double */
  maxPrice: number;
  /** @format double */
  minPrice: number;
  /** @format date-time */
  requestedAt: string;
  /** @format date-time */
  valuatedAt: string;
  serializedVehicleConfiguration: string;
  vehicleConfiguration: IVehicleConfiguration;
  comment: string;
  _fk_uuid_sellerUser: string;
  vin: string;
  /** @format double */
  datPrice: number;
  /** @format double */
  b2bPrice: number;
  /** @format double */
  b2bMinPrice: number;
  /** @format double */
  b2bMaxPrice: number;
  /** @format double */
  b2cPrice: number;
  /** @format double */
  b2cMinPrice: number;
  /** @format double */
  b2cMaxPrice: number;
  highUncertainty: boolean;
  /**
   * Denotes if the customer who requested the trade in valuation (market price request) felt that it was useful to them.
   * Will be unset (null/undefined) if no feedback has been given and either false or true otherwise depending on if the
   * feedback was negative or positive respectively.
   */
  positiveCustomerFeedback: boolean;
  /**
   * A flag indicating if and when the customer requested an inspector from the MPR UI.
   * If this flag is populated, that means we already sent an internal email communicating the request.
   * @format date-time
   */
  inspectorRequestedAt: string;
  /** The creation origin of the MPR (or TradeInValuationRequest) */
  origin: EMarketPriceRequestOrigin;
  /**
   * The UUID of any auction which is created after this MPR was made.
   * This relationship is used for seeing how many auctions are getting created as a result or partial result of an MPR having been executed.
   */
  _fk_uuid_auction: string;
  /** The UUID of the Guaranteed Price Request that is associated with this MPR, if it exists. */
  _fk_uuid_guaranteedPriceRequest: string;
  /**
   * The UUID of the request to the pricing service.
   * This is a unique identifier which can tie together a given MPR object
   * with the request sent to the pricing service to populate it with data.
   * This is often useful for forensics/troubleshooting.
   */
  estimationRequestUuid?: string;
  /**
   * Fields that serve to improve the inspection process after request for the internal users,
   * a seller user should be able to select date of inspection and the location of the vehicle.
   * Some sellers also do have regular appointments, in this case they do not have to select a date.
   * @format double
   */
  minimumRequiredAsk?: number;
  /** @format date-time */
  appointmentDate?: string;
  selectedLocation?: string;
  /**
   * Object description of the GPR object.
   * Most fields are optional because GPRs can be created in a draft state with minimal data and edited from there on.
   * Validation of completion, therefore, takes place upon submission rather than object creation.
   */
  gpr?: IGuaranteedPriceRequest;
  sellerName?: string;
}

export interface IPageIMPRAugmentedWithGPROrIMPRSellerSupervisorView {
  items: (IMPRAugmentedWithGPR | IMPRSellerSupervisorView)[];
  /** @format double */
  total: number;
  /** @format double */
  page: number;
  /** @format double */
  pageSize?: number;
}

/** The combined MPR and GPR statistics. */
export interface IVehiclePriceRequestStats {
  mpr: {
    /** @format double */
    countAll: number;
  };
  gpr: {
    /** @format double */
    countBooked: number;
    /** @format double */
    countSubmitted: number;
  };
}

/**
 * The structure of the augmented list result for fetching many GPRs augmented with their associated MPRs.
 * In this way, it is clear which MPR is associated with which GPR so that they can be easily connected.
 */
export interface IGPRAugmentedWithMPR {
  /**
   * @deprecated
   * @format double
   */
  id: number;
  uuid: string;
  /**
   * Indicating the time when the entity has been created, i.e. when the entity was
   * persisted to the database.
   * @format date-time
   */
  createdAt?: string;
  /**
   * Indicating the last time a value of the entity has been changed (updated).
   * @format date-time
   */
  updatedAt?: string;
  /**
   * Indicating the time when the entity has been (softly) deleted.
   * "null" if entry is not deleted.
   * @format date-time
   */
  deletedAt?: string;
  /** If an auction is created in association with a GPR, the uuid of the auction is linked here. */
  _fk_uuid_auction?: string;
  /** The UUID of the creating seller user. */
  _fk_uuid_sellerUser?: string;
  /** The UUID of the creating seller account. */
  _fk_uuid_sellerAccount?: string;
  /** The UUID of the internal user who fulfilled this GPR. */
  _fk_uuid_fulfillingInternalUser?: string;
  /** The UUID of the principal pricer who filled in the principalPriceSuggestion */
  _fk_uuid_principalPricerInternalUser?: string;
  /** The UUID of the secondary pricer who filled in the secndaryPriceSuggestion */
  _fk_uuid_secondaryPricerInternalUser?: string;
  /** The current status of the GPR. */
  status?: EGPRStatus;
  /**
   * The datetime that the GPR was submitted at (completed and sent for review).
   * @format date-time
   */
  submittedAt?: string;
  /**
   * The datetime that the GPR was fulfilled at (when the guaranteed price was sent back).
   * @format date-time
   */
  fulfilledAt?: string;
  /**
   * The date at which a GPR was rejected at. If null, this means the GPR has not been rejected.
   * @format date-time
   */
  rejectedAt?: string;
  /**
   * The date at which the principal pricer suggested a price (without immediately fulfilling the GPR)
   * @format date-time
   */
  principalGuaranteedPriceSuggestedAt?: string;
  /**
   * The date at which a secondary pricer suggested a price
   * @format date-time
   */
  secondaryGuaranteedPriceSuggestedAt?: string;
  /** The supplied reason that the GPR was rejected, if they were rejected. */
  rejectionReason?: EGPRRejectionReason;
  /** The comment that allows the price team to explain their decision when rejecting the GPR. */
  rejectionReasonComment?: string;
  /** The comment that allows the price team to explain the pricing decision when fulfilling the GPR. */
  fulfillmentReasonComment?: string;
  /**
   * The latest datetime a fulfilled GPR can be booked.
   * Calculated as GPR_UNBOOKED_VALIDITY_DURATION_DAYS after fulfilledAt or extendedAt.
   * @format date-time
   */
  bookableUntil?: string;
  /**
   * The datetime that an inspector was booked for a GPR.
   * This denotes an acceptance of the GP.
   * @format date-time
   */
  inspectorBookedAt?: string;
  /**
   * The datetime that the GPR was extended at (when the data team booked it after the expiration date).
   * @format date-time
   */
  extendedAt?: string;
  /**
   * The datetime that the GPR was refreshed at (when the seller refresh a rejected/expired GPR back to the submit state).
   * @format date-time
   */
  refreshedAt?: string;
  /**
   * The number of days that the GPR is valid for.
   * Counted from the `fulfilledAt` timestamp.
   * @format double
   */
  daysValidFor?: number;
  /**
   * The guaranteed price that the principal pricer would choose (while waiting for the secondary)
   * Usually the secondary price comes first, and the principal pricer just enters a final price.
   * But in case there is no secondary price yet, he can store his suggestion here
   * @format double
   */
  principalGuaranteedPriceSuggestion?: number;
  /**
   * A suggestion for the guaranteed price made by the secondary pricer.
   * @format double
   */
  secondaryGuaranteedPriceSuggestion?: number;
  /**
   * The final guaranteed price which was calculated by COS.
   * @format double
   */
  guaranteedPrice?: number;
  /**
   * The fee which will be paid by the user should they accept the GP and book an inspector.
   * @format double
   */
  calculatedFee?: number;
  /** The data of the vehicle desired to be guaranteed. */
  vehicleData: IGPRVehicleData;
  /** The URL for the document that is created after the GPR is fulfilled. */
  documentReportURL?: string;
  /**
   * Fields that serve to improve the inspection process after request for the internal users,
   * a seller user should be able to select date of inspection and the location of the vehicle.
   * Some sellers also do have regular appointments, in this case they do not have to select a date.
   * @format date-time
   */
  appointmentDate?: string;
  selectedLocation?: string;
  /**
   * A flag in which says if the GPR can be refreshed.
   * A GPR can be refreshed when it was rejected or expired.
   */
  isRefreshAllowed?: boolean;
  /**
   * Property to identify the origin of the request to create the GPR.
   * Used when integrating with third-party systems.
   */
  origin?: EGPRIntegrationOrigin;
  /** Also referred to as a Market Price Request. */
  mpr?: ITradeInValuationRequest;
}

/** The structure of the augmented list result for fetching many Vehicle Price Requests augmented with their associated GPRs or MPRs. */
export interface IVehiclePriceRequestLinkAugmentedWithGPRAndMPR {
  uuid: string;
  /**
   * Indicating the time when the entity has been created, i.e. when the entity was
   * persisted to the database.
   * @format date-time
   */
  createdAt?: string;
  /**
   * Indicating the last time a value of the entity has been changed (updated).
   * @format date-time
   */
  updatedAt?: string;
  /**
   * Indicating the time when the entity has been (softly) deleted.
   * "null" if entry is not deleted.
   * @format date-time
   */
  deletedAt?: string;
  /** The UUID of the Guaranteed Price Request, if it exists. */
  _fk_uuid_guaranteedPriceRequest?: string;
  /** The UUID of the Market Price Request (or Trade In Valuation Request), if it exists. */
  _fk_uuid_marketPriceRequest?: string;
  /**
   * The structure of the augmented list result for fetching many GPRs augmented with their associated MPRs.
   * In this way, it is clear which MPR is associated with which GPR so that they can be easily connected.
   */
  gpr?: IGPRAugmentedWithMPR;
  /**
   * The structure of the augmented list result for fetching many MPRs (Trade In Valuation Requests) augmented with associated GPRs.
   * In this way, it is clear which GPR is associated with which MPR so that they can be easily connected.
   */
  mpr?: IMPRAugmentedWithGPR;
  /** The seller name of each MPR or GPR used for when a supervisor is requesting all the price requests of their subordinated sellers. */
  sellerName?: string;
}

export interface IPageIVehiclePriceRequestLinkAugmentedWithGPRAndMPR {
  items: IVehiclePriceRequestLinkAugmentedWithGPRAndMPR[];
  /** @format double */
  total: number;
  /** @format double */
  page: number;
  /** @format double */
  pageSize?: number;
}

/** The result to show the pricing range for an auction based on its vin. */
export type IPricingRangeResult = Record<
  string,
  {
    /**
     * The max of the optimal range.
     * @format double
     */
    optimalMax: number;
    /**
     * The min of the optimal range.
     * @format double
     */
    optimalMin: number;
    /**
     * The max of the total range.
     * @format double
     */
    totalMax: number;
    /**
     * The min of the total range.
     * @format double
     */
    totalMin: number;
  }
>;

export interface ISyncResults {
  /** @format double */
  updateCount: number;
  /** @format double */
  errorCount: number;
}

/** The purchase preferences of a buyer user. */
export interface IPurchasePreferences {
  uuid: string;
  /**
   * Indicating the time when the entity has been created, i.e. when the entity was
   * persisted to the database.
   * @format date-time
   */
  createdAt?: string;
  /**
   * Indicating the last time a value of the entity has been changed (updated).
   * @format date-time
   */
  updatedAt?: string;
  /**
   * Indicating the time when the entity has been (softly) deleted.
   * "null" if entry is not deleted.
   * @format date-time
   */
  deletedAt?: string;
  /** Current version vector */
  versionVector: number[];
  /** Indicates this update comes from a remote system */
  isRemoteUpdate: boolean;
  /** The makes the user is interested in. */
  makes: string[];
  /**
   * The earliest vehicle age the user is interested in.
   * @format double
   */
  vehicleAgeFrom?: number;
  /**
   * The latest vehicle age the user is interested in.
   * @format double
   */
  vehicleAgeTo?: number;
  /**
   * The price range the user is interested in.
   * @format double
   */
  priceFrom?: number;
  /**
   * The price range the user is interested in.
   * @format double
   */
  priceTo?: number;
  /**
   * The mileage range the user is interested in.
   * @format double
   */
  mileageFromKM?: number;
  /**
   * The mileage range the user is interested in.
   * @format double
   */
  mileageToKM?: number;
  /** The fuel types the user is interested in. */
  fuelTypes: EFuelType[];
  /** The vehicle categories the user is interested in. */
  vehicleCategories: EVehicleCategory[];
  /**
   * The distance to the vehicle the user is interested in.
   * @format double
   */
  distanceRadiusKM?: number;
  /**
   * The countries the user is interested in.
   * TODO: Is it country of vehicle registration, vehicle location or vehicle origin?
   */
  countries: ECountryCode[];
  /** The the id of the user this purchase preferences belong to. */
  _fk_uuid_user: string;
}

export interface IPurchasePreferencesFormView {
  makes: string[];
  vehicleCategories: EVehicleCategory[] | string[];
  vehicleAgeFrom: number | string[];
  fuelTypes: EFuelType[] | string[];
  countries: ECountryCode[] | string[];
  vehicleAgeTo: number | string;
  priceFrom: number | string;
  priceTo: number | string;
  mileageFromKM: number | string;
  mileageToKM: number | string;
  distanceRadiusKM: number | string;
  /** @format date-time */
  createdAt: string;
  uuid: string;
  _fk_uuid_user: string;
}

export interface ICreatePurchasePreferencesBody {
  makes: string[];
  /** @format double */
  vehicleAgeFrom: number;
  /** @format double */
  vehicleAgeTo: number;
  /** @format double */
  priceFrom: number;
  /** @format double */
  priceTo: number;
  /** @format double */
  mileageFromKM: number;
  /** @format double */
  mileageToKM: number;
  fuelTypes: EFuelType[];
  vehicleCategories: EVehicleCategory[];
  /** @format double */
  distanceRadiusKM: number;
  countries: ECountryCode[];
}

/** Make all properties in T optional */
export interface PartialIPurchasePreferences {
  /** The makes the user is interested in. */
  makes?: string[];
  /**
   * The earliest vehicle age the user is interested in.
   * @format double
   */
  vehicleAgeFrom?: number;
  /**
   * The latest vehicle age the user is interested in.
   * @format double
   */
  vehicleAgeTo?: number;
  /**
   * The price range the user is interested in.
   * @format double
   */
  priceFrom?: number;
  /**
   * The price range the user is interested in.
   * @format double
   */
  priceTo?: number;
  /**
   * The mileage range the user is interested in.
   * @format double
   */
  mileageFromKM?: number;
  /**
   * The mileage range the user is interested in.
   * @format double
   */
  mileageToKM?: number;
  /** The fuel types the user is interested in. */
  fuelTypes?: EFuelType[];
  /** The vehicle categories the user is interested in. */
  vehicleCategories?: EVehicleCategory[];
  /**
   * The distance to the vehicle the user is interested in.
   * @format double
   */
  distanceRadiusKM?: number;
  /**
   * The countries the user is interested in.
   * TODO: Is it country of vehicle registration, vehicle location or vehicle origin?
   */
  countries?: ECountryCode[];
  /** The the id of the user this purchase preferences belong to. */
  _fk_uuid_user?: string;
  /**
   * Indicating the time when the entity has been created, i.e. when the entity was
   * persisted to the database.
   * @format date-time
   */
  createdAt?: string;
  /**
   * Indicating the last time a value of the entity has been changed (updated).
   * @format date-time
   */
  updatedAt?: string;
  /**
   * Indicating the time when the entity has been (softly) deleted.
   * "null" if entry is not deleted.
   * @format date-time
   */
  deletedAt?: string;
  uuid?: string;
  /** Current version vector */
  versionVector?: number[];
  /** Indicates this update comes from a remote system */
  isRemoteUpdate?: boolean;
}

export enum ESurveyCategory {
  NPS = 'NPS',
  UNCATEGORIZED = 'UNCATEGORIZED',
  GPR = 'GPR',
}

/** A survey, e.g. for customers. */
export interface ISurvey {
  /**
   * @deprecated
   * @format double
   */
  id: number;
  uuid: string;
  /**
   * Indicating the time when the entity has been created, i.e. when the entity was
   * persisted to the database.
   * @format date-time
   */
  createdAt?: string;
  /**
   * Indicating the last time a value of the entity has been changed (updated).
   * @format date-time
   */
  updatedAt?: string;
  /**
   * Indicating the time when the entity has been (softly) deleted.
   * "null" if entry is not deleted.
   * @format date-time
   */
  deletedAt?: string;
  /**
   * When the survey has been started, i.e. when the survey is available
   * to the target audience (e.g. buyers or sellers).
   * @format date-time
   */
  startedAt: string;
  /**
   * When the survey has been closed, i.e. when no more participation is
   * possible anymore.
   * @format date-time
   */
  closedAt: string;
  /**
   * Indicating whether the survey is active, i.e. if the survey is displayed
   * to the audience.
   */
  isActive: boolean;
  /** The public name of the survey, also displayed to the audience. */
  label: string;
  /** The URI to the external survey, e.g. TypeForm. */
  uri: string;
  /** The language code of the Survey */
  languageCode: string;
  /** The category of Survey */
  category: ESurveyCategory;
}

/**
 * Inform that a new resource was created. Server will
 * add a Location header and set status to 201
 */
export interface ReturnNewResourceISurvey {
  location: string;
  /** @format double */
  statusCode: number;
  /** the body to be sent */
  body: ISurvey;
}

export interface ISurveyCreationParams {
  label: string;
  uri: string;
  category?: ESurveyCategory;
  languageCode?: string;
}

export type ComplaintDamage = IVehicleDamage & {
  urlToVideos: string[];
};

export enum EVehiclePartAIRCONDITIONER {
  Value34 = 34,
}

export enum EAirConditionerAnomalyTypeWITHOUTFUNCTION {
  WithoutFunction = 'withoutFunction',
}

export enum EAirConditionerAnomalyTypeINSUFFICIENTCOOLINGPOWER {
  InsufficientCoolingPower = 'insufficientCoolingPower',
}

export enum EAirConditionerAnomalyTypeVENTILATIONNOISES {
  VentilationNoises = 'ventilationNoises',
}

export type AirConditionerAnomaly =
  | {
      type: EAirConditionerAnomalyTypeWITHOUTFUNCTION | EAirConditionerAnomalyTypeINSUFFICIENTCOOLINGPOWER;
    }
  | {
      specifications: EAirConditionerNoiseAnomalySpecification[];
      type: EAirConditionerAnomalyTypeVENTILATIONNOISES;
    };

export interface IOtherAnomaly {
  type: 'other';
  description: string;
}

export interface IAirConditionerTechState {
  uuid: string;
  state: EVehicleTechState;
  part: EVehiclePartAIRCONDITIONER;
  anomalies?: (AirConditionerAnomaly | IOtherAnomaly)[];
}

export enum EVehiclePartBATTERY {
  Value33 = 33,
}

export enum EBatteryAnomalyTypeDEFECT {
  Defect = 'defect',
}

export enum EBatteryAnomalyTypeBATTERYINDICATORLIGHTSON {
  BatteryIndicatorLightsOn = 'batteryIndicatorLightsOn',
}

export enum EBatteryAnomalyTypeDEEPDISCHARGE {
  DeepDischarge = 'deepDischarge',
}

export enum EBatteryAnomalyTypeMISSING {
  Missing = 'missing',
}

export enum EBatteryAnomalyTypeANOMALIESINATTACHMENTS {
  AnomaliesInAttachment = 'anomaliesInAttachment',
}

export type BatteryAnomaly =
  | {
      type: EBatteryAnomalyTypeDEFECT | EBatteryAnomalyTypeBATTERYINDICATORLIGHTSON | EBatteryAnomalyTypeDEEPDISCHARGE | EBatteryAnomalyTypeMISSING;
    }
  | {
      specifications: EBatteryAttachmentsAnomaly[];
      type: EBatteryAnomalyTypeANOMALIESINATTACHMENTS;
    };

export interface IBatteryTechState {
  uuid: string;
  state: EVehicleTechState;
  part: EVehiclePartBATTERY;
  anomalies?: (BatteryAnomaly | IOtherAnomaly)[];
}

export enum EVehiclePartBRAKINGSYSTEM {
  Value38 = 38,
}

export enum EBrakeSystemAnomalyTypeNOBRAKEPRESSURE {
  NoBrakePressure = 'noBrakePressure',
}

export enum EBrakeSystemAnomalyTypeBRAKEINDICATORLIGHTSON {
  BrakeIndicatorLightsOn = 'brakeIndicatorLightsOn',
}

export enum EBrakeSystemAnomalyTypeABSINDICATORLIGHTSON {
  AbsIndicatorLightsOn = 'absIndicatorLightsOn',
}

export enum EBrakeSystemAnomalyTypeHANDBRAKENOTWORKING {
  HandbrakeNotWorking = 'handbrakeNotWorking',
}

export enum EBrakeSystemAnomalyTypeBRAKENEARWORNOUT {
  BrakeNearWornOut = 'brakeNearWornOut',
}

export enum EBrakeSystemAnomalyTypeBRAKEWORNOUT {
  BrakeWornOut = 'brakeWornOut',
}

export enum EBrakeSystemAnomalyTypeBRAKERUSTY {
  BrakeRusty = 'brakeRusty',
}

export enum EBrakeSystemAnomalyTypeBRAKEFLUIDABNORMAL {
  BrakeFluidAbnormal = 'brakeFluidAbnormal',
}

export type BrakeSystemAnomaly =
  | {
      type:
        | EBrakeSystemAnomalyTypeNOBRAKEPRESSURE
        | EBrakeSystemAnomalyTypeBRAKEINDICATORLIGHTSON
        | EBrakeSystemAnomalyTypeABSINDICATORLIGHTSON
        | EBrakeSystemAnomalyTypeHANDBRAKENOTWORKING;
    }
  | {
      specifications: ERearFrontAnomalySpecification[];
      type: EBrakeSystemAnomalyTypeBRAKENEARWORNOUT | EBrakeSystemAnomalyTypeBRAKEWORNOUT | EBrakeSystemAnomalyTypeBRAKERUSTY;
    }
  | {
      specifications: EBrakeFluidAnomalySpecification[];
      type: EBrakeSystemAnomalyTypeBRAKEFLUIDABNORMAL;
    };

export interface IBrakeSystemTechState {
  uuid: string;
  state: EVehicleTechState;
  part: EVehiclePartBRAKINGSYSTEM;
  anomalies?: (BrakeSystemAnomaly | IOtherAnomaly)[];
}

export enum EVehiclePartCHASSIS {
  Value31 = 31,
}

export enum EChassisAnomalyTypeESPINDICATORLIGHTSON {
  EspIndicatorLightsOn = 'espIndicatorLightsOn',
}

export enum EChassisAnomalyTypeAIRSUSPENSIONWITHOUTFUNCTION {
  AirSuspensionWithoutFunction = 'airSuspensionWithoutFunction',
}

export enum EChassisAnomalyTypeUNEQUALCHASSISLEVEL {
  UnequalChassisLevel = 'unequalChassisLevel',
}

export enum EChassisAnomalyTypeVEHICLELOWERED {
  VehicleLowered = 'vehicleLowered',
}

export enum EChassisAnomalyTypeSUSPENSIONSPRINGBROKEN {
  SuspensionSpringBroken = 'suspensionSpringBroken',
}

export type ChassisAnomaly =
  | {
      type:
        | EChassisAnomalyTypeESPINDICATORLIGHTSON
        | EChassisAnomalyTypeAIRSUSPENSIONWITHOUTFUNCTION
        | EChassisAnomalyTypeUNEQUALCHASSISLEVEL
        | EChassisAnomalyTypeVEHICLELOWERED;
    }
  | {
      specifications: ERearFrontAnomalySpecification[];
      type: EChassisAnomalyTypeSUSPENSIONSPRINGBROKEN;
    };

export interface IChassisTechState {
  uuid: string;
  state: EVehicleTechState;
  part: EVehiclePartCHASSIS;
  anomalies?: (ChassisAnomaly | IOtherAnomaly)[];
}

export enum EVehiclePartCLUTCH {
  Value32 = 32,
}

export enum EClutchAnomalyTypeCLUTCHNEARWORN {
  ClutchNearWorn = 'clutchNearWorn',
}

export enum EClutchAnomalyTypeCOUPLINGDEFECTIVE {
  CouplingDefective = 'couplingDefective',
}

export enum EClutchAnomalyTypeSTRIKINGNOISE {
  StrikingNoise = 'strikingNoise',
}

export enum EClutchAnomalyTypeCLUTCHPEDALANOMALIES {
  ClutchPedalAnomalies = 'clutchPedalAnomalies',
}

export type ClutchAnomaly =
  | {
      type: EClutchAnomalyTypeCLUTCHNEARWORN | EClutchAnomalyTypeCOUPLINGDEFECTIVE;
    }
  | {
      specifications: EClutchNoiseAnomalySpecification[];
      type: EClutchAnomalyTypeSTRIKINGNOISE;
    }
  | {
      specifications: EClutchPedalAnomalySpecification[];
      type: EClutchAnomalyTypeCLUTCHPEDALANOMALIES;
    };

export interface IClutchTechState {
  uuid: string;
  state: EVehicleTechState;
  part: EVehiclePartCLUTCH;
  anomalies?: (ClutchAnomaly | IOtherAnomaly)[];
}

export enum EVehiclePartENGINE {
  Value36 = 36,
}

export enum EEngineAnomalyTypeOUTOFROUNDRUN {
  OutOfRoundRun = 'outOfRoundRun',
}

export enum EEngineAnomalyTypeMISFIRE {
  Misfire = 'misfire',
}

export enum EEngineAnomalyTypeDOESNOTRUNBATTERYOK {
  DoesNotRunBatteryOk = 'doesNotRunBatteryOk',
}

export enum EEngineAnomalyTypeSTRIKINGNOISE {
  StrikingNoise = 'strikingNoise',
}

export enum EEngineAnomalyTypeVISUALWARNINGS {
  VisualWarnings = 'visualWarnings',
}

export enum EEngineAnomalyTypeOILANOMALY {
  OilAnomaly = 'oilAnomaly',
}

export enum EEngineAnomalyTypeOILLEAK {
  OilLeak = 'oilLeak',
}

export enum EEngineAnomalyTypeCOOLINGWATERANOMALY {
  CoolingWaterAnomaly = 'coolingWaterAnomaly',
}

export enum EEngineAnomalyTypeEXHAUSTSYSTEMANOMALY {
  ExhaustSystemAnomaly = 'exhaustSystemAnomaly',
}

export enum EEngineAnomalyTypeSTARTERANOMALY {
  StarterAnomaly = 'starterAnomaly',
}

export enum EEngineAnomalyTypeDUALMASSANOMALY {
  DualMassAnomaly = 'dualMassAnomaly',
}

export type EngineAnomaly =
  | {
      type: EEngineAnomalyTypeOUTOFROUNDRUN | EEngineAnomalyTypeMISFIRE | EEngineAnomalyTypeDOESNOTRUNBATTERYOK;
    }
  | {
      specifications: EEngineNoiseAnomalySpecification[];
      type: EEngineAnomalyTypeSTRIKINGNOISE;
    }
  | {
      specifications: EControlLightsAnomalySpecification[];
      type: EEngineAnomalyTypeVISUALWARNINGS;
    }
  | {
      specifications: EOilAnomalySpecification[];
      type: EEngineAnomalyTypeOILANOMALY | EEngineAnomalyTypeOILLEAK;
    }
  | {
      specifications: ECoolingWaterAnomalySpecification[];
      type: EEngineAnomalyTypeCOOLINGWATERANOMALY;
    }
  | {
      specifications: EExhaustAnomalySpecification[];
      type: EEngineAnomalyTypeEXHAUSTSYSTEMANOMALY;
    }
  | {
      specifications: EStarterAnomalySpecification[];
      type: EEngineAnomalyTypeSTARTERANOMALY;
    }
  | {
      specifications: EDualMassAnomalySpecification[];
      type: EEngineAnomalyTypeDUALMASSANOMALY;
    };

export interface IEngineTechState {
  uuid: string;
  state: EVehicleTechState;
  part: EVehiclePartENGINE;
  anomalies?: (EngineAnomaly | IOtherAnomaly)[];
}

export enum EVehiclePartSTEERING {
  Value22 = 22,
}

export enum ESteeringAnomalyTypeSTEERINGINDICATORLIGHTSON {
  SteeringIndicatorLightsOn = 'steeringIndicatorLightsOn',
}

export enum ESteeringAnomalyTypeSTEERINGGEARLEAKING {
  SteeringGearLeaking = 'steeringGearLeaking',
}

export enum ESteeringAnomalyTypeSTIFF {
  Stiff = 'stiff',
}

export enum ESteeringAnomalyTypeATPOWERSTEERING {
  AtPowerSteering = 'atPowerSteering',
}

export enum ESteeringAnomalyTypeNOISEWHENUSINGSTEERINGWHEEL {
  NoiseWhenUsingSteeringWheel = 'noiseWhenUsingSteeringWheel',
}

export type SteeringAnomaly =
  | {
      type: ESteeringAnomalyTypeSTEERINGINDICATORLIGHTSON | ESteeringAnomalyTypeSTEERINGGEARLEAKING | ESteeringAnomalyTypeSTIFF;
    }
  | {
      specifications: EPowerSteeringAnomalySpecification[];
      type: ESteeringAnomalyTypeATPOWERSTEERING;
    }
  | {
      specifications: ESteeringWheelNoiseAnomalySpecification[];
      type: ESteeringAnomalyTypeNOISEWHENUSINGSTEERINGWHEEL;
    };

export interface ISteeringTechState {
  uuid: string;
  state: EVehicleTechState;
  part: EVehiclePartSTEERING;
  anomalies?: (SteeringAnomaly | IOtherAnomaly)[];
}

export enum EVehiclePartTRANSMISSION {
  Value37 = 37,
}

export enum ETransmissionAnomalyTypeGEARSTICKS {
  GearSticks = 'gearSticks',
}

export enum ETransmissionAnomalyTypeGEARBOXDAMAGE {
  GearboxDamage = 'gearboxDamage',
}

export enum ETransmissionAnomalyTypeOILWET {
  OilWet = 'oilWet',
}

export enum ETransmissionAnomalyTypeGEARNOTENGAGEABLE {
  GearNotEngageable = 'gearNotEngageable',
}

export enum ETransmissionAnomalyTypeSHIFTINGDIFFICULT {
  ShiftingDifficult = 'shiftingDifficult',
}

export enum ETransmissionAnomalyTypeRUNNINGNOISE {
  RunningNoise = 'runningNoise',
}

export type TransmissionAnomaly =
  | {
      type:
        | ETransmissionAnomalyTypeGEARSTICKS
        | ETransmissionAnomalyTypeGEARBOXDAMAGE
        | ETransmissionAnomalyTypeOILWET
        | ETransmissionAnomalyTypeGEARNOTENGAGEABLE
        | ETransmissionAnomalyTypeSHIFTINGDIFFICULT;
    }
  | {
      specifications: ETransmissionNoiseAnomalySpecification[];
      type: ETransmissionAnomalyTypeRUNNINGNOISE;
    };

export interface ITransmissionTechState {
  uuid: string;
  state: EVehicleTechState;
  part: EVehiclePartTRANSMISSION;
  anomalies?: (TransmissionAnomaly | IOtherAnomaly)[];
}

export enum EVehiclePartTRANSFERCASE {
  Value48 = 48,
}

export enum ETransferCaseAnomalyTypeSTRIKINGSOUNDS {
  StrikingSounds = 'strikingSounds',
}

export enum ETransferCaseAnomalyTypePROFILEDEVIATIONONWHEELS {
  ProfileDeviationOnWheels = 'profileDeviationOnWheels',
}

export interface TransferCaseAnomaly {
  type: ETransferCaseAnomalyTypeSTRIKINGSOUNDS | ETransferCaseAnomalyTypePROFILEDEVIATIONONWHEELS;
}

export interface ITransferCaseTechState {
  uuid: string;
  state: EVehicleTechState;
  part: EVehiclePartTRANSFERCASE;
  anomalies?: (TransferCaseAnomaly | IOtherAnomaly)[];
}

export enum EVehiclePartDIFFERENTIAL {
  Value49 = 49,
}

export enum EDifferentialAnomalyTypeSTRIKINGSOUNDS {
  StrikingSounds = 'strikingSounds',
}

export enum EDifferentialAnomalyTypeLEAKAGE {
  Leakage = 'leakage',
}

export interface DifferentialAnomaly {
  type: EDifferentialAnomalyTypeSTRIKINGSOUNDS | EDifferentialAnomalyTypeLEAKAGE;
}

export interface IDifferentialTechState {
  uuid: string;
  state: EVehicleTechState;
  part: EVehiclePartDIFFERENTIAL;
  anomalies?: (DifferentialAnomaly | IOtherAnomaly)[];
}

export type VehicleTechState =
  | IAirConditionerTechState
  | IBatteryTechState
  | IBrakeSystemTechState
  | IChassisTechState
  | IClutchTechState
  | IEngineTechState
  | ISteeringTechState
  | ITransmissionTechState
  | ITransferCaseTechState
  | IDifferentialTechState;

export type ComplaintTechState = VehicleTechState & {
  urlToAttachments?: string[];
};

export interface DisputedVehicleData {
  ez?: string;
  mileageInKm?: string;
  fuelType?: string;
  enginePowerInHp?: string;
  engineSizeInCcm?: string;
  transmission?: string;
  lastHu?: string;
  lastServiceInspectionDate?: string;
  lastServiceInspectionMileage?: string;
  numKeys?: string;
  numPreOwners?: string;
  countryOfLastRegistration?: string;
  commercialUsage?: string;
  isReimportedVehicle?: string;
  readyToDrive?: string;
  numSeats?: string;
  doors?: string;
}

/**
 * Potential reasons why the complaint is filed.
 * @deprecated
 */
export enum EAuctionComplaintReason {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
  Value5 = 5,
  Value6 = 6,
  Value7 = 7,
  Value8 = 8,
  Value9 = 9,
  Value10 = 10,
}

export interface IRelatedVehiclePart {
  part: EVehiclePart;
}

export enum ETireSetComplaint {
  Value0 = 0,
  Value1 = 1,
}

export interface IRelatedTireSet {
  tireSet: ETireSetComplaint;
}

/** Different types of parts that can be related to a complaint reason. Either a vehicle part or a set of tires. */
export type IRelatedComplaintPart = IRelatedVehiclePart | IRelatedTireSet;

/**
 * Potential damage categories.
 * @deprecated
 */
export enum EAuctionComplaintDamageCategory {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
}

/**
 * Potential paint damage types.
 * @deprecated
 */
export enum EAuctionComplaintDamageTypeBodyPaint {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
  Value5 = 5,
}

/**
 * Potential tires damage types.
 * @deprecated
 */
export enum EAuctionComplaintDamageTypeTiresRims {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
}

/** A complaint which is filed for an auction by a buyer. */
export interface IAuctionComplaint {
  /**
   * The mileage of the associated vehicle at the time the complaint is filed.
   * This is to indicate how many kilometers the vehicle has been driven after purchase.
   * @format double
   */
  mileageInKm: number;
  /** Indicates if a buyer had already reached out to the seller for the complaint. */
  hasReachedSeller: boolean;
  /** The URL to the photo of the odometer of the vehicle at the time the complaint is filed. */
  odometerPhotoUrl?: string;
  /** The estimated repair cost of the vehicle provided by a licensed repair shop. */
  estimatedRepairCostAttachmentUrl?: string;
  /** The CMR or other proof of transport document URL. */
  proofOfTransportAttachmentUrl?: string;
  /**
   * The vehicle registration document of the vehicle for which the complaint is filed.
   * This is to verify the vehicle data (in case we didn't have it earlier).
   */
  urlsToVehicleRegistrationDocument?: string[];
  /**
   * The vehicle registration document part2 of the vehicle for which the complaint is filed.
   * This is to verify the vehicle data (in case we don't have it earlier).
   */
  urlsToVehicleRegistrationDocumentPart2?: string[];
  /** The damages of the vehicle for which the complaint is filed for. */
  damages?: ComplaintDamage[];
  /** The claimed technical state that the complaint is filed for. */
  technicalState?: ComplaintTechState[];
  /** The disputed vehicle data that the complaint is filed for. */
  vehicleData?: DisputedVehicleData;
  /**
   * The reason why the complaint is filed.
   * @deprecated
   */
  reason?: EAuctionComplaintReason;
  /**
   * An detailed description of the complaint, describing the exact nature of the claim.
   * @deprecated
   */
  description?: string;
  /**
   * Links to the attached images/videos, supporting the complaint.
   * @deprecated
   */
  urlToAttachment1?: string;
  /** @deprecated */
  urlToAttachment2?: string;
  /** @deprecated */
  urlToAttachment3?: string;
  /** @deprecated */
  urlToAttachment4?: string;
  /** @deprecated */
  urlToAttachment5?: string;
  /**
   * The part which the reason of the complaint is related to.
   * @deprecated
   */
  relatedPart?: IRelatedComplaintPart;
  /**
   * The flaws of the vehicle for which the complaint is filed for.
   * @deprecated
   */
  damageCategory?: EAuctionComplaintDamageCategory;
  /** @deprecated */
  damageType?: EAuctionComplaintDamageTypeBodyPaint | EAuctionComplaintDamageTypeTiresRims;
  /**
   * Phone number given by the complaining buyer, which can be used to reach him.
   * @deprecated
   */
  contactPhoneNumber?: string;
  uuid: string;
  /**
   * Indicating the time when the entity has been created, i.e. when the entity was
   * persisted to the database.
   * @format date-time
   */
  createdAt?: string;
  /**
   * Indicating the last time a value of the entity has been changed (updated).
   * @format date-time
   */
  updatedAt?: string;
  /**
   * Indicating the time when the entity has been (softly) deleted.
   * "null" if entry is not deleted.
   * @format date-time
   */
  deletedAt?: string;
  /** External ticket ID, referencing a ticket in an external ticketing system (e.g. FreshDesk). */
  ticketId: string;
  /** The auction for which the complaint is filed for. */
  _fk_uuid_auction: string;
  /**
   * URL to the generated PDF file based on the complaint data.
   * This PDF can be used to send an overview of the complaint to e.g. the Sales team
   * or the seller of the car.
   */
  urlToPdf: string;
}

/**
 * Inform that a new resource was created. Server will
 * add a Location header and set status to 201
 */
export interface ReturnNewResourceIAuctionComplaint {
  location: string;
  /** @format double */
  statusCode: number;
  /** the body to be sent */
  body: IAuctionComplaint;
}

export interface IAuctionComplaintCreationParams {
  /**
   * The mileage of the associated vehicle at the time the complaint is filed.
   * This is to indicate how many kilometers the vehicle has been driven after purchase.
   * @format double
   */
  mileageInKm: number;
  /** Indicates if a buyer had already reached out to the seller for the complaint. */
  hasReachedSeller: boolean;
  /** The URL to the photo of the odometer of the vehicle at the time the complaint is filed. */
  odometerPhotoUrl?: string;
  /** The estimated repair cost of the vehicle provided by a licensed repair shop. */
  estimatedRepairCostAttachmentUrl?: string;
  /** The CMR or other proof of transport document URL. */
  proofOfTransportAttachmentUrl?: string;
  /**
   * The vehicle registration document of the vehicle for which the complaint is filed.
   * This is to verify the vehicle data (in case we didn't have it earlier).
   */
  urlsToVehicleRegistrationDocument?: string[];
  /**
   * The vehicle registration document part2 of the vehicle for which the complaint is filed.
   * This is to verify the vehicle data (in case we don't have it earlier).
   */
  urlsToVehicleRegistrationDocumentPart2?: string[];
  /** The damages of the vehicle for which the complaint is filed for. */
  damages?: ComplaintDamage[];
  /** The claimed technical state that the complaint is filed for. */
  technicalState?: ComplaintTechState[];
  /** The disputed vehicle data that the complaint is filed for. */
  vehicleData?: DisputedVehicleData;
  /**
   * The reason why the complaint is filed.
   * @deprecated
   */
  reason?: EAuctionComplaintReason;
  /**
   * An detailed description of the complaint, describing the exact nature of the claim.
   * @deprecated
   */
  description?: string;
  /**
   * Links to the attached images/videos, supporting the complaint.
   * @deprecated
   */
  urlToAttachment1?: string;
  /** @deprecated */
  urlToAttachment2?: string;
  /** @deprecated */
  urlToAttachment3?: string;
  /** @deprecated */
  urlToAttachment4?: string;
  /** @deprecated */
  urlToAttachment5?: string;
  /**
   * The part which the reason of the complaint is related to.
   * @deprecated
   */
  relatedPart?: IRelatedComplaintPart;
  /**
   * The flaws of the vehicle for which the complaint is filed for.
   * @deprecated
   */
  damageCategory?: EAuctionComplaintDamageCategory;
  /** @deprecated */
  damageType?: EAuctionComplaintDamageTypeBodyPaint | EAuctionComplaintDamageTypeTiresRims;
  /**
   * Phone number given by the complaining buyer, which can be used to reach him.
   * @deprecated
   */
  contactPhoneNumber?: string;
}

/** The explanation reason why complaint cannot be posted. */
export enum EAuctionComplaintProhibitionReason {
  Value0 = 0,
  Value1 = 1,
}

/** Detailed explanation of complaint possibility. */
export interface IAuctionComplaintPossibility {
  isPossible: boolean;
  /** The explanation reason why complaint cannot be posted. */
  prohibitionReason: EAuctionComplaintProhibitionReason;
}

/**
 * Inform that a new resource was created. Server will
 * add a Location header and set status to 201
 */
export interface ReturnNewResourceIAuctionMistake {
  location: string;
  /** @format double */
  statusCode: number;
  /** the body to be sent */
  body: IAuctionMistake;
}

/** The entity of the auction model. */
export interface IAuctionEntity {
  /**
   * @deprecated
   * @format double
   */
  id: number;
  uuid: string;
  /**
   * Indicating the time when the entity has been created, i.e. when the entity was
   * persisted to the database.
   * @format date-time
   */
  createdAt?: string;
  /**
   * Indicating the last time a value of the entity has been changed (updated).
   * @format date-time
   */
  updatedAt?: string;
  /**
   * Indicating the time when the entity has been (softly) deleted.
   * "null" if entry is not deleted.
   * @format date-time
   */
  deletedAt?: string;
  /** Stores which type of additional tax applies to the auction if any. */
  additionalTaxType: EAdditionalTaxType;
  /**
   * Stores the value of the additional tax that might apply to an auction.
   * @format double
   */
  additionalTaxValue: number;
  /**
   * Stores the export discount for taxes.
   * @format double
   */
  additionalTaxExportDiscount: number;
  /**
   * The state of the auction, indicating whether an auction is not yet published (DRAFTED), running (ACTIVE),
   * closed successfully (CLOSED_WAITING_FOR_PAYMENT, CLOSED_WAITING_FOR_PICKUP, FINISHED), failed (CLOSED_NO_BIDS,
   * CLOSED_UNDER_MINIMUM_ASK) or disabled (DISABLED).
   *
   * Depending on the state, different timestamps are available per auction.
   */
  state: EAuctionState;
  /**
   * The exact date+time when the auction is expiring/ending.
   * @format date-time
   */
  endingTime: string;
  /**
   * The remaining number of seconds that this auction will be in the state ACTIVE (running).
   *
   * This is a dynamic, non-persisted field, calculated on every retrieval of the auction data.
   * @format double
   */
  remainingTimeInSeconds: number;
  /**
   * The remaining number of seconds that this auction is available for instant purchase
   * (usually the first 24 hours after the auction is started).
   *
   * This is a dynamic, non-persisted field, calculated on every retrieval of the auction data.
   * @format double
   */
  remainingTimeForInstantPurchaseInSeconds: number;
  /**
   * Date+time indicating until when an auction is available for instant purchasing.
   * By default, this is 24 hours after the start of an auction.
   * @format date-time
   */
  instantPurchasePossibleUntil: string;
  _fk_uuid_highestBiddingBuyerUser: string;
  /**
   * Value that is indicating the current highest bid value on the auction.
   * This is of course a dynamic field, that will be incremented on every new bid placed on the auction.
   * @format double
   */
  currentHighestBidValue: number;
  /** @format double */
  currentHighestBidValueNet: number;
  /**
   * The minimal price the seller user wants to reach for this specific auction.
   * If an auction ends above that price, the auction is successful right away
   * (switching to state CLOSED_WAITING_FOR_PAYMENT).
   *
   * If the auction ends below the minimum required ask, the auction will switch to state CLOSED_UNDER_MINIMUM_ASK,
   * which then requires the seller's explicit "confirmation" or "rejection" of the highest bid value.
   * @format double
   */
  minimumRequiredAsk: number;
  /** @format double */
  minimumRequiredAskNet: number;
  /**
   * Number of bids placed on an auction.
   * @format double
   */
  numBids: number;
  /** @format double */
  buyerRecommendationScore?: number;
  /** Was this auction created with the help of an inspection done or commissioned by CarOnSale? */
  inspectionWasCommissionedByCos: boolean;
  /** By which type of user was this auction created. */
  creatorType: EAuctionCreatorType;
  /** UUID of the creating internal user if there is one. */
  _fk_uuid_creatingInternalUser: string;
  /** UUID of the creating seller user if there is one. */
  _fk_uuid_creatingSellerUser: string;
  /**
   * Indicates if the auction was created for testing purposes and
   * should be handled differently: it should not be ended automatically,
   * so the seller should decide would he like to sell it or not.
   */
  isTest: boolean;
  /** Indicates if minimum required ask should be displayed for buyers. */
  displayMinAsk: boolean;
  /**
   * GPS coordinates of the auction (can be used for e.g. calculating distances between auctions and buyers).
   * @format double
   */
  locationGeoLat: number;
  /** @format double */
  locationGeoLon: number;
  /** @format double */
  counterOfferByBuyer: number;
  /** @format double */
  counterOfferByBuyerNet: number;
  /** @format double */
  lastOfferBySeller: number;
  /** @format double */
  lastOfferBySellerNet: number;
  /**
   * A value denoting the previous renegotiation offer given by a buyer user.
   * @format double
   */
  previousCounterOfferByBuyer: number;
  /** @format double */
  previousCounterOfferByBuyerNet: number;
  /**
   * A value denoting the previous renegotiation offer given by a seller user.
   * This is required in order to properly display the right information during the renegotiation process.
   * @format double
   */
  previousLastOfferBySeller: number;
  /** @format double */
  previousLastOfferBySellerNet: number;
  /** A flag for if the seller user connected to the auction has decided not to participate in the renegotiation any further. */
  renegotiationStoppedBySeller: boolean;
  /** A flag for if the highest bidding buyer user connected to the auction has decided not to participate in the renegotiation any further. */
  renegotiationStoppedByBuyer: boolean;
  /** A fag indicating if the buyer's withdrawal from the renegotiation process was done automatically or not. */
  wasRenegotiationStoppageByBuyerAutomatic: boolean;
  /** A fag indicating if the seller's withdrawal from the renegotiation process was done automatically or not. */
  wasRenegotiationStoppageBySellerAutomatic: boolean;
  /**
   * The total number of offer rounds a buyer has participated in for an auction during the renegotiation phase.
   * @format double
   */
  numBuyerRenegotiationOfferRounds: number;
  /**
   * The total number of offer rounds a seller has participated in for an auction during the renegotiation phase.
   * @format double
   */
  numSellerRenegotiationOfferRounds: number;
  /**
   * The calculated renegotiation midpoint value of the auction.
   * This is used once the renegotiation offers get too close for the offer process to continue.
   * This value will be undefined unless the auction is ready to be purchased for such a value.
   * @format double
   */
  renegotiationMidpointValue: number;
  /** @format double */
  renegotiationMidpointValueNet: number;
  /**
   * Indicates that the highest bidding buyer user for an auction in renegotiation is willing to accept the midpoint of their offer and
   * the offer of the seller for the same auction. This is used when the renegotiation offers get very close during the renegotiation process.
   */
  buyerAcceptedRenegotiationMidpoint: boolean;
  /**
   * Indicates that the seller of an auction is willing to accept the midpoint of their offer and the offer of the highest bidding
   * buyer user for the same auction. This is used when the renegotiation offers get very close during the renegotiation process.
   */
  sellerAcceptedRenegotiationMidpoint: boolean;
  /**
   * This is the datetime object denoting when the most recent renegotiation round concluded at.
   * @format date-time
   */
  lastRenegotiationRoundEndedAt: string;
  /**
   * The number of seconds until the current renegotiation round times out.
   * @format double
   */
  numSecondsUntilRenegotiationTimeout: number;
  /**
   * The UUID of the external case tracking the renegotiation of the auction.
   * We track this to enable case updates as the auction moves through renegotiation.
   */
  externalRenegotiationTicketUuid: string;
  /**
   * The calculated renegotiation fair value of the auction.
   * This is used once the renegotiation starts based on the auction GPR or MPR so we know
   * the fair value that both seller and buyer should agree upon.
   * @format double
   */
  renegotiationFairValue: number;
  /**
   * Value that is indicating the highest bid value on the auction when it ended.
   * This value should not change for a given auction since it supposed to be a historical record.
   * @format double
   */
  highestBidValueAtEndingTime: number;
  /** @format double */
  highestBidValueAtEndingTimeNet: number;
  /** Field data related to the renegotiation round process. */
  renegotiationRoundData?: IRenegotiationRoundData;
  /**
   * Indicates when manual renegotiation was finished via Salesforce.
   * @format date-time
   */
  finishedManuallyAt?: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing service (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the transfer of funds
   * for the vat deposit from COS to the deposit account (only relevant for cross border transactions).
   * This deposit is refunded by COS once the buyer provides the necessary documentation for a cross border transaction.
   */
  thirdPartyVATDepositTransferReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing service (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the transfer of funds
   * for the additional tax deposit from COS to the deposit account (only relevant for cross border transactions).
   * This deposit is refunded by COS once the buyer provides the necessary documentation for a cross border transaction.
   */
  thirdPartyAdditionalTaxDepositTransferReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing system (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the transfer of funds
   * for the auction additional taxes (e.g country specific luxury taxes): from COS to the seller.
   */
  thirdPartyAdditionalTaxTransferReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing service (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the transfer of funds
   * for the VAT: from COS to the seller (only relevant for cross border transactions for now).
   * COS is advancing the vat amount to the seller.
   */
  thirdPartyVatTransferReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing service (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the charge of funds
   * for the auction volume from the buyer to COS.
   */
  thirdPartyVolumeChargeReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing service (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the charge of funds
   * for the auction deposit from the buyer to COS (only relevant for cross border transactions for now).
   * This deposit is refunded by COS once the buyer provides the necessary documentation for a cross border transaction.
   */
  thirdPartyVATDepositChargeReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing service (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the charge of funds
   * for the auction additional tax (e.g country specific luxury tax) from the buyer to COS.
   */
  thirdPartyAdditionalTaxChargeReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing system (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the refund of an
   * additional tax deposit (only used for cross-border transactions currently).
   */
  thirdPartyAdditionalTaxRefundReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing system (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the payout of funds.
   */
  thirdPartyPayoutReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing system (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the refund of a
   * deposit (only used for cross-border transactions currently).
   */
  thirdPartyVATDepositRefundReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing system (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the refund of a
   * transfer to the deposit account owned by cos (only used for cross-border transactions currently).
   */
  thirdPartyVATDepositReversalReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing system (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the refund of the
   * additional tax transfer to the deposit account owned by cos (only used for cross-border transactions).
   */
  thirdPartyAdditionalTaxDepositReversalReference: string;
  /**
   * Reference to the invoice for the success fee issued to the seller for a sold vehicle (see ESellerFeeInvoicingMode.INVOICE_ON_SUCCESS).
   * The reference identifies the invoice issued to the seller in an external invoicing system (e.g. Stripe).
   *
   * This fee is not always charged, therefore this reference is optional (can be null).
   */
  sellerSuccessFeeInvoiceReference: string;
  /**
   * Reference to the invoice for the listing fee issued to the seller for a listed vehicle (see ESellerFeeInvoicingMode.INVOICE_ON_LISTING).
   * The reference identifies the invoice issued to the seller in an external invoicing system (e.g. Stripe).
   *
   * This fee is not always charged, therefore this reference is optional (can be null).
   */
  sellerListingFeeInvoiceReference: string;
  /**
   * Reference to the invoice for the standing fee issued to the buyer for a purchased vehicle picked up too late.
   *
   * This fee is not always charged, therefore this reference is optional (can be null).
   */
  buyerStandingFeeInvoiceReference: string;
  /**
   * Reference to a resource in an external invoicing system (such as Stripe).
   * References our own transaction fee invoice.
   */
  invoiceReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing system (such as Stripe).
   * This is only non-null, if external payment is allowed for this auction.
   */
  thirdPartyInvoiceReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing system.
   * This is only non-null, if external payment is allowed for this auction and it had to be cancelled.
   */
  thirdPartyCorrectionInvoiceReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing system (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the transfer of funds
   * for the auction volume: from COS to the seller.
   */
  thirdPartyTransferReference: string;
  /**
   * Reference of an invoice on an external invoicing system.
   * This is invoice is used to charge booked services (like guarantee) after the auction expires.
   */
  bookedServicesInvoiceReference: string;
  /**
   * Date-time when the auction was reauctioned and is now waiting for buyer payment until the new auction gets paid.
   * @format date-time
   */
  reauctionedWaitingForBuyerPaymentAt: string;
  /**
   * Date-time when the first reauction of this auction has expired.
   * We need to keep track of this so we can block the original buyer of seeing the original auction.
   * @format date-time
   */
  firstReauctionExpiredAt: string;
  /** Flag that indicates if this auction is a clonned reauction. */
  isReauction: boolean;
  /**
   * The highest bid value that we show to the seller when we merge the reauction with the original auction.
   * This is done so the seller see the amount that cos pay them and the buyer sees the amount that they paid cos.
   * @format double
   */
  sellerViewHighestBidValue: number;
  /**
   * The purchase confirmed at that we show to the seller when we merge the reauction with the original auction.
   * This is done so the seller see the date when the original auction was confirmed and the buyer sees the date that the reauction was confirmed.
   * @format date-time
   */
  sellerViewPurchaseConfirmedAt: string;
  /**
   * Date-time when the reauction was merged back at the original auction.
   * @format date-time
   */
  reauctionedMergedAt: string;
  /** Indicates wherever an auction was purchase using the Buy Now Pay Later feature. */
  wasPurchasedUsingBuyNowPayLater: boolean;
  /**
   * The timestamp at which the buyer has confirmed his choice to use Buy Now Pay Later for the auction.
   * @format date-time
   */
  buyNowPayLaterConfirmedAt: string;
  /** The IP address which confirmed the Buy Now Pay Later choice for the auction. */
  buyNowPayLaterConfirmationIPAddress: string;
  /** Reference to the Buy Now Pay Later Order on the PNI Service. */
  buyNowPayLaterOrderReference: string;
  /** Reference of the invoice for buy now pay later provider fee on an external invoicing system. */
  buyNowPayLaterInvoiceReference: string;
  /** The payment link reference */
  paymentLinkReference: string;
  /** Reference to the payment order on the P&I service */
  paymentOrderReference: string;
  isInternalFulfillment: boolean;
  belowMinAskBehaviour: EAuctionBelowMinAskBehaviour;
  /** The label of the auction, consisting of the vehicle make, model and date of first registration (EZ). */
  label: string;
  /**
   * Date+time when the auction is started.
   * When an auction is started right away (without drafting), the "createdAt" and "startedAt" should have
   * (roughly) the same values.
   * @format date-time
   */
  startedAt: string;
  /**
   * Date+time when the auction is "confirmed to be sold" by the seller (or by an admin).
   * If an auction is ending equal or above the minimum required ask, the "purchaseConfirmedAt"
   * will automatically be set and will be equal to the "endingTime".
   *
   * If an auction is ending below the minimum ask (CLOSED_UNDER_MINIMUM_ASK), the "purchaseConfirmedAt" timestamp
   * is only set if the auction is then manually "confirmed" by the seller or an admin.
   *
   * If an auction is never "successful", the "purchaseConfirmedAt" will remain "null".
   *
   * This value was introduced in the course of 2019. For all previous auctions, the endingTime was set
   * as the "purchaseConfirmedAt" retrospectively.
   * @format date-time
   */
  purchaseConfirmedAt: string;
  /**
   * Date-time when the auction has definitely been rejected by a seller or an admin, after the auction
   * ended below minimum required ask.
   *
   * This value is only available since 2019/07/29. All auctions rejected before that date have the value "null".
   * @format date-time
   */
  purchaseRejectedAt: string;
  /** Indicates the reason that an auction failed the renegotiation process. */
  rejectionReason: EAuctionRejectionReason;
  /**
   * Elaborates on the rejection reason to provide further context to why an
   * auction was rejected during the renegotiation process.
   */
  rejectionReasonNote: string;
  /**
   * The name of the competitor that the auction was sold to when the reject chosen reason was
   * VEHICLE_SOLD_ON_DIFFERENT_PLATFORM
   */
  rejectionReasonCompetitorName: string;
  /** In case if there was a direct upload requested but didn't succeed the reason should be stored. */
  directUploadRejectionReasons: EAuctionDirectUploadRejectionReason[];
  /**
   * Value that is indicating initial bid value auction should be started with.
   * @format double
   */
  startingBidValue: number;
  /** @format double */
  startingBidValueNet: number;
  /**
   * Value that can be manually set in case edge-cases apply and the payout amount is disconnected from the auction current highest bid.
   * This value can only be adjusted by internal users.
   * @format double
   */
  overridePayoutValue: number;
  /**
   * If an auction is ending below the minimum required ask, the "originalMinimumRequiredAsk" will be set to the
   * "minimumRequiredAsk" value before it is set to "currentHighestBidValue".
   * @format double
   */
  originalMinimumRequiredAsk: number;
  /** @format double */
  originalMinimumRequiredAskNet: number;
  /**
   * The purchase price for the vehicle that is auctioned.
   * This is set manually by the seller, and is mostly used to analyze the "real" margin that a seller
   * makes with an auction.
   * @format double
   */
  purchasePrice: number;
  /** @format double */
  purchasePriceNet: number;
  /**
   * The full object of the vehicle that is on auction.
   * This is augmented on every auction retrieval, based on _fk_uuid_vehicle.
   */
  associatedVehicle: IVehicle;
  /** Indicates whether the auction process is rated by the SellerUser. */
  isRatedByDealership: boolean;
  /** Indicates whether the auction process is rated by the BuyerUser. */
  isRatedByBuyer: boolean;
  /**
   * Indicates whether the BuyerUser has marked this auction as paid (payment of the auction volume).
   * This is done manually by the BuyerUser, so this value is not indicating that the actual payment has really
   * reached the seller.
   */
  isPaidByBuyer: boolean;
  /**
   * Timestamp when the BuyerUser has marked this auction as "paid" (i.e. set the "isPaidByBuyer").
   * @format date-time
   */
  outgoingPaymentConfirmedAt: string;
  /**
   * Timestamp when the SellerUser has confirmed that the payment has reached the seller bank account.
   * @format date-time
   */
  incomingPaymentConfirmedAt: string;
  /**
   * Timestamp when the auction state has changed from "CLOSED_WAITING_FOR_PAYMENT" to "CLOSED_WAITING_FOR_PICKUP".
   * @format date-time
   */
  pickupConfirmedAt: string;
  /** Physical address of where the vehicle on auction is located (a.k.a. pickup address). */
  locationCountryCode: ECountryCode;
  locationAddress: string;
  locationCity: string;
  locationZip: string;
  /** Physical address of where the vehicle on auction was inspected (a.k.a. inspection address). */
  inspectionLocationCountryCode: ECountryCode;
  inspectionLocationAddress: string;
  inspectionLocationCity: string;
  inspectionLocationZip: string;
  /**
   * Indicating to which bank account the BuyerUser has to transfer the auction volume to.
   * @deprecated
   */
  sellerIban: string;
  /**
   * URL to the seller invoice for the given auction, manually uploaded by the SellerUser, after
   * the auction has ended successfully.
   */
  urlToInvoice: string;
  /** URL for the cancelled invoice in case it was cancelled. */
  urlToCorrectionInvoice: string;
  /**
   * Flag indicating whether an auction has an "hot bid" phase in the last 20 seconds before expiration.
   * @deprecated
   */
  hotBid: boolean;
  /**
   * Seller's instant purchase price for the vehicle.
   * For the first 24 hours after the auction is started, the vehicle will only be available in "instant purchase"
   * mode (bidding is not allowed in that time, only purchasing right away).
   *
   * After 24 hours, if nobody purchased the vehicle, it is going on auction, just like all other auctions.
   * @format double
   */
  instantPurchasePrice: number;
  /** @format double */
  instantPurchasePriceNet: number;
  /**
   * Flag indicating that instant purchase is enabled for that auction.
   * (Implies that "instantPurchasePrice" is set)
   */
  allowInstantPurchase: boolean;
  /**
   * Flag indicating that an auction "ended" with an instant purchase, resp.
   * did never go on auction.
   */
  didEndWithInstantPurchase: boolean;
  /**
   * Internal counter, incrementing on every "restart" of an auction.
   * Starts with 0.
   *
   * e.g. count of 2 means that the auction has been restarted 2 times, leading to 3 auction objects in the
   * database.
   * @format double
   */
  auctioningIterations: number;
  /**
   * Indicating the strategic priority of an auction for CarOnSale.de
   * The higher the number, the more "relevant" the auction is.
   * @format double
   */
  priority: number;
  /** @deprecated */
  advertisementHtmlContent: string;
  /** @deprecated */
  buyerComplaint: EAuctionBuyerComplaint;
  /**
   * Custom field that is not used internally.
   * Allows sellers to store e.g. a reference ID to their own vehicle management system.
   */
  internalReferenceText: string;
  _fk_uuid_vehicle: string;
  _fk_uuid_sellerUser: string;
  /** This is a URL that redirects to an external invoice payment site (such as provided by Stripe). */
  urlToPaymentSite: string;
  /**
   * A flag indicating if an auction needs to be reviewed by an internal review agent (for quality assurance)
   * If true, an auction is currently under review by CarOnSale, and cannot be edited by the owning seller user.
   */
  needsReview: boolean;
  /** The reasons why an auction was rejected during the review. */
  reviewReasons: EAuctionReviewReason[];
  /**
   * Reasons why an auction is rejected during the review process ("Parkhaus").
   *
   * This reason is automatically set to UNKNOWN for all auctions and is then manually adjusted by
   * an internal review agent (in case the auction gets rejected) before it is sent back to the responsible
   * SellerUser.
   * @deprecated
   */
  reviewReason: EAuctionReviewReason;
  /** Specifies reason when "reviewReasons" contains EAuctionReviewReason.OTHER. */
  reviewReasonDetails: string;
  /** The comment given by an internal review agent on rejecting an auction under review. */
  reviewComment: string;
  /** Flag indicating if the auction can be published without review. */
  canSkipReview?: boolean;
  /**
   * Random PIN code that is generated when an auction is ready to be picked up or in advance before COSCheck inspection.
   * This PIN code is printed out on the hangar in the vehicle or handed to the seller of the auction, which can then forward it to
   * the buyer to confirm the pickup.
   */
  pickupPinCode: string;
  /**
   * Random UUID that is used to confirm vehicle pickup via scanning QR code.
   * It will be printed on the hangar or in the pickup document available for sellers.
   */
  pickupPinUuid: string;
  /**
   * Timestamp when PIN code was entered by the buyer.
   * Allows to determine how many auctions were self-picked up.
   * @format date-time
   */
  pickupPinCodeEnteredAt: string;
  /**
   * Timestamp when PIN UUID was entered by the buyer via scanning QR code.
   * Allows to determine how many auctions were self-picked up.
   * @format date-time
   */
  pickupPinUuidEnteredAt: string;
  /** Flag is indicating if pickup was confirmed with PIN using new process (via pin.caronsale.de) */
  pickupConfirmedWithNewPin: boolean;
  /** URL to the pickup information document for the BuyerUser. */
  urlToPickupBuyerDocument: string;
  /** URL to the pickup information document for the SellerUser (containing the "pickupPinCode"). */
  urlToPickupSellerDocument: string;
  /** URL to the success report document for the SellerUser. */
  urlToSellerSuccessReport: string;
  /** The payment process that is used to pay money for the purchase volume (auction volume). */
  paymentProcess: EAuctionPaymentProcess;
  /**
   * Referencing the bank account to which the payment for the purchase volume should be paid out.
   * This is null, if the paymentProcess === "CLASSIC".
   *
   * It is only used, when external payment (COS Payment) is enabled.
   */
  _fk_uuid_associatedBankAccount: string;
  /**
   * Indicates how often an auction has been reviewed.
   * This is incremented every time an auction is rejected after a review.
   * @format double
   */
  draftReviewIterations: number;
  /**
   * Timestamp when an admin resets a time window for a possibility to complain
   * @format date-time
   */
  complaintTimeWindowExtendedAt: string;
  /**
   * Flag indicates if this auction is suspected to have been paid in cash (only for auctions that use the external
   * invoice process), this means that no corresponding transfer exists on Stripe and the buyer confirmed the pickup
   * through the seller pin code.
   */
  isSuspectedCashPayment: boolean;
  /**
   * Timestamp when the auction was sent to review.
   * @format date-time
   */
  reviewStartedAt: string;
  /**
   * Timestamp when the auction QA process actually started (i.e. when Parkhaus started reviewing the auction).
   * @format date-time
   */
  qaStartedAt: string;
  /**
   * Timestamp when the auction was ready to be reinserted (a.k.a. restarted).
   * @format date-time
   */
  readyToBeReinsertedSince: string;
  /** The reference to the original auction from which current one was cloned. */
  _fk_uuid_clonedFrom: string;
  /** Auction type. */
  type: EAuctionType;
  /** Indicates whether the rejection wait period for this auction is over and thus can be rejected by the seller. */
  isRejectionWaitPeriodOver: boolean;
  /** Reference to invoice object for the external Billomat invoicing provider. */
  thirdPartyVehiclePurchaseInvoiceReference: string;
  /**
   * URL to the seller made invoice invoicing CoS for the given auction, manually uploaded to the crm by an internal user, after
   * the auction has ended successfully.
   */
  sellerToCosInvoiceUrl: string;
  /**
   * Timestamp for when the sellerToCosInvoiceUrl was last updated.
   * @format date-time
   */
  sellerToCosInvoiceUrlLastUpdatedAt: string;
  /**
   * Timestamp for when the sellerToCosInvoiceUrl is first uploaded.
   * @format date-time
   */
  sellerToCosInvoiceUrlFirstUploadedAt: string;
  /** Flag indicating wherever the uploaded seller to cos invoice was verified by an internal user. */
  sellerToCosInvoiceIsValid: boolean;
  /** The comment given by an internal user on the provided seller to cos invoice. */
  sellerToCosInvoiceComment: string;
  /** Shows if auction is marked as 'live', so, it will be slotted during starting and expire in less than 24 hours. */
  isLive: boolean;
  /** Shows if auction was already slotted via the slotting algorithm. */
  isSlotted: boolean;
  /**
   * The exact date+time when the auction was slotted to end.
   * @format date-time
   */
  slottedEndingTime: string;
  /** Indicating if transportation should be disabled manually. */
  isTransportationDisabledManually: boolean;
  /** Optional pickup instructions that will be communicated to the buyer. */
  pickupInstructions: string;
  /** Indicating wherever the seller should be charged a success/listing fee. */
  preventSellerFactoring: boolean;
  /** Indicating if buyer success fee should be applied or not. */
  applyBuyerSuccessFee: boolean;
  /** Info from document shipping service about the order, like status and links to the documents. */
  documentShippingOrder: IAuctionDocumentShippingOrder;
  /** Info about un-/confirmed auction data points. */
  fieldsConfirmationStatus: IAuctionFieldConfirmationStatusMap;
  /**
   * Whether the "Value Added Tax is reportable", a special tax condition in the EU.
   * If this is true, it means that the vehicle is "Regelbesteuert", meaning that the invoice for the vehicle
   * (either invoiced directly from seller to buyer - or from COS Payment to buyer) needs to include
   * the VAT (in Germany e.g. 19%) in the invoice.
   *
   * If this is false, the invoice does not need to have explicit VAT.
   */
  isVATReportable: boolean;
  /** Indicates how the auction was uploaded to the marketplace. */
  uploadMethod: EAuctionUploadMethod;
  /** Prebooked services available for the auction, like transport, warranty. */
  prebookedServices: IPrebookedService[];
  /** Indicates if cosCheckPlus guarantee can be booked for this auction. */
  isCosCheckPlusGuaranteeEnabled: boolean;
  /** Indicates if extended guarantee can be booked for this auction. */
  isExtendedGuaranteeEnabled: boolean;
  /** URL to the auction vehicle cover letter for sellers */
  urlToCoverLetter: string;
  /**
   * Timestamp when the automatic pickup approval was requested for auction
   * @format date-time
   */
  autoPickupOnPaymentRequestedAt: string;
  /**
   * This means that the auctions is using complimentary vehicle feature.
   *
   * Note: This is marked as true when the seller uses one of his complimentary vehicles to pay for the fees.
   */
  isSellerFeeComplimentary: boolean;
  /** Flag when standing fee is enabled to the auction and we'll need to extra charge a buyer as a penalty. */
  isStandingFeeEnabled: boolean;
  _fk_uuid_auctionRoom: string;
  /**
   * An auction can have a list of fulfillment criteria that impact how the
   * auction will be fulfilled depending on the buyer that wins it.
   */
  fulfillmentCriteria?: IAuctionFulfillmentCriteria[];
  /**
   * The auction marked with this flag won't start automatically after review.
   *
   * Instead, it will transition to state: READY_TO_BE_PUBLISHED.
   *
   * Default: false
   */
  shouldWaitToBePublishedAfterReview: boolean;
  /** A flag that marks if an auction is a selective ware or not. */
  isSelectiveWare: boolean;
  /** The id for the look and feel applied to the auction. */
  lookAndFeelId: EAuctionLookAndFeelId;
  /** A flag that marks if an auction will use the pickup qr code. */
  isQRCodeAvailable: boolean;
  /**
   * Indicates whether the highest bid should be transferred if this auction gets cloned.
   * Defaults to `false` if not specified.
   */
  shouldTransferHighestBid?: boolean;
  /** The reference to the renegotiation that this auction have. */
  _fk_uuid_renegotiation?: string;
  /** The UUID of the auction fee row related to this auction. */
  _fk_uuid_auctionFee?: string;
  /**
   * A list of UUIDs representing the auction(s) from which this auction was cloned.
   * This field forms a chain of auction history, where the first UUID represents the original auction,
   * and subsequent UUIDs represent the lineage of cloned auctions.
   */
  auctionLineageForClonedBids?: string[];
}

/**
 * Inform that a new resource was created. Server will
 * add a Location header and set status to 201
 */
export interface ReturnNewResourceIAuctionEntityArray {
  location: string;
  /** @format double */
  statusCode: number;
  /** the body to be sent */
  body: IAuctionEntity[];
}

export interface ITestAuctionCreationParams {
  sellerUuid?: string;
  /**
   * This is the new user structure, meant to replace our old fragmented user structure with Sellers, Buyers and etc.
   * A user is someone that is connected to a company and uses our platform for an activity enabled for that account.
   * As we're a B2B platform, our focus is always the account/business, the user is just someone acting in the name of an account.
   */
  seller?: IGeneralUser;
  /** The entity of the auction model. */
  auction: IAuctionEntity;
  buyerUuid?: string;
  /**
   * This is the new user structure, meant to replace our old fragmented user structure with Sellers, Buyers and etc.
   * A user is someone that is connected to a company and uses our platform for an activity enabled for that account.
   * As we're a B2B platform, our focus is always the account/business, the user is just someone acting in the name of an account.
   */
  buyer: IGeneralUser;
  /** @format double */
  numAuctions: number;
  skipReview?: boolean;
  directUpload?: {
    skipReview: boolean;
    shouldFail: boolean;
  };
}

/**
 * Inform that a new resource was created. Server will
 * add a Location header and set status to 201
 */
export interface ReturnNewResourceIAuctionEntity {
  location: string;
  /** @format double */
  statusCode: number;
  /** the body to be sent */
  body: IAuctionEntity;
}

export interface IMercedesAuctionCreationParams {
  vin: string;
  sellerEmail: string;
  setDefaultMercedesValues: boolean;
  auctionDetails?: {
    isVATReportable: boolean;
    /** @format double */
    minimumRequiredAsk: number;
    label: string;
    needsReview?: boolean;
    shouldWaitToBePublishedAfterReview?: boolean;
    fulfillmentCriteria?: IAuctionFulfillmentCriteria[];
    isSelectiveWare?: boolean;
  };
}

export interface IInspectorAuctionCreationParams {
  skipReview?: boolean;
  directUploadRejectionReasons: EAuctionDirectUploadRejectionReason[];
}

export interface PageIAuction {
  items: IAuction[];
  /** @format double */
  total: number;
  /** @format double */
  page: number;
  /** @format double */
  pageSize?: number;
}

/** From T, pick a set of properties whose keys are in the union K */
export interface PickITransporterUserBusinessNameOrPhoneNumber {
  /**
   * Multiple slots for phone numbers.
   * "null" if a slot is not used.
   */
  phoneNumber: string;
  businessName: string;
}

export interface IApplicationAuctionView {
  /**
   * @deprecated
   * @format double
   */
  id: number;
  uuid: string;
  /**
   * Indicating the time when the entity has been created, i.e. when the entity was
   * persisted to the database.
   * @format date-time
   */
  createdAt?: string;
  /**
   * Indicating the last time a value of the entity has been changed (updated).
   * @format date-time
   */
  updatedAt?: string;
  /**
   * Indicating the time when the entity has been (softly) deleted.
   * "null" if entry is not deleted.
   * @format date-time
   */
  deletedAt?: string;
  /** Stores which type of additional tax applies to the auction if any. */
  additionalTaxType: EAdditionalTaxType;
  /**
   * Stores the value of the additional tax that might apply to an auction.
   * @format double
   */
  additionalTaxValue: number;
  /**
   * Stores the export discount for taxes.
   * @format double
   */
  additionalTaxExportDiscount: number;
  /**
   * The state of the auction, indicating whether an auction is not yet published (DRAFTED), running (ACTIVE),
   * closed successfully (CLOSED_WAITING_FOR_PAYMENT, CLOSED_WAITING_FOR_PICKUP, FINISHED), failed (CLOSED_NO_BIDS,
   * CLOSED_UNDER_MINIMUM_ASK) or disabled (DISABLED).
   *
   * Depending on the state, different timestamps are available per auction.
   */
  state: EAuctionState;
  /**
   * The exact date+time when the auction is expiring/ending.
   * @format date-time
   */
  endingTime: string;
  /**
   * The remaining number of seconds that this auction will be in the state ACTIVE (running).
   *
   * This is a dynamic, non-persisted field, calculated on every retrieval of the auction data.
   * @format double
   */
  remainingTimeInSeconds: number;
  /**
   * The remaining number of seconds that this auction is available for instant purchase
   * (usually the first 24 hours after the auction is started).
   *
   * This is a dynamic, non-persisted field, calculated on every retrieval of the auction data.
   * @format double
   */
  remainingTimeForInstantPurchaseInSeconds: number;
  /**
   * Date+time indicating until when an auction is available for instant purchasing.
   * By default, this is 24 hours after the start of an auction.
   * @format date-time
   */
  instantPurchasePossibleUntil: string;
  _fk_uuid_highestBiddingBuyerUser: string;
  /**
   * Value that is indicating the current highest bid value on the auction.
   * This is of course a dynamic field, that will be incremented on every new bid placed on the auction.
   * @format double
   */
  currentHighestBidValue: number;
  /** @format double */
  currentHighestBidValueNet: number;
  /**
   * The minimal price the seller user wants to reach for this specific auction.
   * If an auction ends above that price, the auction is successful right away
   * (switching to state CLOSED_WAITING_FOR_PAYMENT).
   *
   * If the auction ends below the minimum required ask, the auction will switch to state CLOSED_UNDER_MINIMUM_ASK,
   * which then requires the seller's explicit "confirmation" or "rejection" of the highest bid value.
   * @format double
   */
  minimumRequiredAsk: number;
  /** @format double */
  minimumRequiredAskNet: number;
  /**
   * Number of bids placed on an auction.
   * @format double
   */
  numBids: number;
  /** @format double */
  buyerRecommendationScore?: number;
  /** Was this auction created with the help of an inspection done or commissioned by CarOnSale? */
  inspectionWasCommissionedByCos: boolean;
  /** By which type of user was this auction created. */
  creatorType: EAuctionCreatorType;
  /** UUID of the creating internal user if there is one. */
  _fk_uuid_creatingInternalUser: string;
  /** UUID of the creating seller user if there is one. */
  _fk_uuid_creatingSellerUser: string;
  /**
   * Indicates if the auction was created for testing purposes and
   * should be handled differently: it should not be ended automatically,
   * so the seller should decide would he like to sell it or not.
   */
  isTest: boolean;
  /** Indicates if minimum required ask should be displayed for buyers. */
  displayMinAsk: boolean;
  /**
   * GPS coordinates of the auction (can be used for e.g. calculating distances between auctions and buyers).
   * @format double
   */
  locationGeoLat: number;
  /** @format double */
  locationGeoLon: number;
  /** @format double */
  counterOfferByBuyer: number;
  /** @format double */
  counterOfferByBuyerNet: number;
  /** @format double */
  lastOfferBySeller: number;
  /** @format double */
  lastOfferBySellerNet: number;
  /**
   * A value denoting the previous renegotiation offer given by a buyer user.
   * @format double
   */
  previousCounterOfferByBuyer: number;
  /** @format double */
  previousCounterOfferByBuyerNet: number;
  /**
   * A value denoting the previous renegotiation offer given by a seller user.
   * This is required in order to properly display the right information during the renegotiation process.
   * @format double
   */
  previousLastOfferBySeller: number;
  /** @format double */
  previousLastOfferBySellerNet: number;
  /** A flag for if the seller user connected to the auction has decided not to participate in the renegotiation any further. */
  renegotiationStoppedBySeller: boolean;
  /** A flag for if the highest bidding buyer user connected to the auction has decided not to participate in the renegotiation any further. */
  renegotiationStoppedByBuyer: boolean;
  /** A fag indicating if the buyer's withdrawal from the renegotiation process was done automatically or not. */
  wasRenegotiationStoppageByBuyerAutomatic: boolean;
  /** A fag indicating if the seller's withdrawal from the renegotiation process was done automatically or not. */
  wasRenegotiationStoppageBySellerAutomatic: boolean;
  /**
   * The total number of offer rounds a buyer has participated in for an auction during the renegotiation phase.
   * @format double
   */
  numBuyerRenegotiationOfferRounds: number;
  /**
   * The total number of offer rounds a seller has participated in for an auction during the renegotiation phase.
   * @format double
   */
  numSellerRenegotiationOfferRounds: number;
  /**
   * The calculated renegotiation midpoint value of the auction.
   * This is used once the renegotiation offers get too close for the offer process to continue.
   * This value will be undefined unless the auction is ready to be purchased for such a value.
   * @format double
   */
  renegotiationMidpointValue: number;
  /** @format double */
  renegotiationMidpointValueNet: number;
  /**
   * Indicates that the highest bidding buyer user for an auction in renegotiation is willing to accept the midpoint of their offer and
   * the offer of the seller for the same auction. This is used when the renegotiation offers get very close during the renegotiation process.
   */
  buyerAcceptedRenegotiationMidpoint: boolean;
  /**
   * Indicates that the seller of an auction is willing to accept the midpoint of their offer and the offer of the highest bidding
   * buyer user for the same auction. This is used when the renegotiation offers get very close during the renegotiation process.
   */
  sellerAcceptedRenegotiationMidpoint: boolean;
  /**
   * This is the datetime object denoting when the most recent renegotiation round concluded at.
   * @format date-time
   */
  lastRenegotiationRoundEndedAt: string;
  /**
   * The number of seconds until the current renegotiation round times out.
   * @format double
   */
  numSecondsUntilRenegotiationTimeout: number;
  /**
   * The UUID of the external case tracking the renegotiation of the auction.
   * We track this to enable case updates as the auction moves through renegotiation.
   */
  externalRenegotiationTicketUuid: string;
  /**
   * The calculated renegotiation fair value of the auction.
   * This is used once the renegotiation starts based on the auction GPR or MPR so we know
   * the fair value that both seller and buyer should agree upon.
   * @format double
   */
  renegotiationFairValue: number;
  /**
   * Value that is indicating the highest bid value on the auction when it ended.
   * This value should not change for a given auction since it supposed to be a historical record.
   * @format double
   */
  highestBidValueAtEndingTime: number;
  /** @format double */
  highestBidValueAtEndingTimeNet: number;
  /** Field data related to the renegotiation round process. */
  renegotiationRoundData?: IRenegotiationRoundData;
  /**
   * Indicates when manual renegotiation was finished via Salesforce.
   * @format date-time
   */
  finishedManuallyAt?: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing service (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the transfer of funds
   * for the vat deposit from COS to the deposit account (only relevant for cross border transactions).
   * This deposit is refunded by COS once the buyer provides the necessary documentation for a cross border transaction.
   */
  thirdPartyVATDepositTransferReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing service (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the transfer of funds
   * for the additional tax deposit from COS to the deposit account (only relevant for cross border transactions).
   * This deposit is refunded by COS once the buyer provides the necessary documentation for a cross border transaction.
   */
  thirdPartyAdditionalTaxDepositTransferReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing system (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the transfer of funds
   * for the auction additional taxes (e.g country specific luxury taxes): from COS to the seller.
   */
  thirdPartyAdditionalTaxTransferReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing service (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the transfer of funds
   * for the VAT: from COS to the seller (only relevant for cross border transactions for now).
   * COS is advancing the vat amount to the seller.
   */
  thirdPartyVatTransferReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing service (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the charge of funds
   * for the auction volume from the buyer to COS.
   */
  thirdPartyVolumeChargeReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing service (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the charge of funds
   * for the auction deposit from the buyer to COS (only relevant for cross border transactions for now).
   * This deposit is refunded by COS once the buyer provides the necessary documentation for a cross border transaction.
   */
  thirdPartyVATDepositChargeReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing service (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the charge of funds
   * for the auction additional tax (e.g country specific luxury tax) from the buyer to COS.
   */
  thirdPartyAdditionalTaxChargeReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing system (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the refund of an
   * additional tax deposit (only used for cross-border transactions currently).
   */
  thirdPartyAdditionalTaxRefundReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing system (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the payout of funds.
   */
  thirdPartyPayoutReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing system (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the refund of a
   * deposit (only used for cross-border transactions currently).
   */
  thirdPartyVATDepositRefundReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing system (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the refund of a
   * transfer to the deposit account owned by cos (only used for cross-border transactions currently).
   */
  thirdPartyVATDepositReversalReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing system (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the refund of the
   * additional tax transfer to the deposit account owned by cos (only used for cross-border transactions).
   */
  thirdPartyAdditionalTaxDepositReversalReference: string;
  /**
   * Reference to the invoice for the success fee issued to the seller for a sold vehicle (see ESellerFeeInvoicingMode.INVOICE_ON_SUCCESS).
   * The reference identifies the invoice issued to the seller in an external invoicing system (e.g. Stripe).
   *
   * This fee is not always charged, therefore this reference is optional (can be null).
   */
  sellerSuccessFeeInvoiceReference: string;
  /**
   * Reference to the invoice for the listing fee issued to the seller for a listed vehicle (see ESellerFeeInvoicingMode.INVOICE_ON_LISTING).
   * The reference identifies the invoice issued to the seller in an external invoicing system (e.g. Stripe).
   *
   * This fee is not always charged, therefore this reference is optional (can be null).
   */
  sellerListingFeeInvoiceReference: string;
  /**
   * Reference to the invoice for the standing fee issued to the buyer for a purchased vehicle picked up too late.
   *
   * This fee is not always charged, therefore this reference is optional (can be null).
   */
  buyerStandingFeeInvoiceReference: string;
  /**
   * Reference to a resource in an external invoicing system (such as Stripe).
   * References our own transaction fee invoice.
   */
  invoiceReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing system (such as Stripe).
   * This is only non-null, if external payment is allowed for this auction.
   */
  thirdPartyInvoiceReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing system.
   * This is only non-null, if external payment is allowed for this auction and it had to be cancelled.
   */
  thirdPartyCorrectionInvoiceReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing system (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the transfer of funds
   * for the auction volume: from COS to the seller.
   */
  thirdPartyTransferReference: string;
  /**
   * Reference of an invoice on an external invoicing system.
   * This is invoice is used to charge booked services (like guarantee) after the auction expires.
   */
  bookedServicesInvoiceReference: string;
  /**
   * Date-time when the auction was reauctioned and is now waiting for buyer payment until the new auction gets paid.
   * @format date-time
   */
  reauctionedWaitingForBuyerPaymentAt: string;
  /**
   * Date-time when the first reauction of this auction has expired.
   * We need to keep track of this so we can block the original buyer of seeing the original auction.
   * @format date-time
   */
  firstReauctionExpiredAt: string;
  /** Flag that indicates if this auction is a clonned reauction. */
  isReauction: boolean;
  /**
   * The highest bid value that we show to the seller when we merge the reauction with the original auction.
   * This is done so the seller see the amount that cos pay them and the buyer sees the amount that they paid cos.
   * @format double
   */
  sellerViewHighestBidValue: number;
  /**
   * The purchase confirmed at that we show to the seller when we merge the reauction with the original auction.
   * This is done so the seller see the date when the original auction was confirmed and the buyer sees the date that the reauction was confirmed.
   * @format date-time
   */
  sellerViewPurchaseConfirmedAt: string;
  /**
   * Date-time when the reauction was merged back at the original auction.
   * @format date-time
   */
  reauctionedMergedAt: string;
  /** Indicates wherever an auction was purchase using the Buy Now Pay Later feature. */
  wasPurchasedUsingBuyNowPayLater: boolean;
  /**
   * The timestamp at which the buyer has confirmed his choice to use Buy Now Pay Later for the auction.
   * @format date-time
   */
  buyNowPayLaterConfirmedAt: string;
  /** The IP address which confirmed the Buy Now Pay Later choice for the auction. */
  buyNowPayLaterConfirmationIPAddress: string;
  /** Reference to the Buy Now Pay Later Order on the PNI Service. */
  buyNowPayLaterOrderReference: string;
  /** Reference of the invoice for buy now pay later provider fee on an external invoicing system. */
  buyNowPayLaterInvoiceReference: string;
  /** The payment link reference */
  paymentLinkReference: string;
  /** Reference to the payment order on the P&I service */
  paymentOrderReference: string;
  isInternalFulfillment: boolean;
  belowMinAskBehaviour: EAuctionBelowMinAskBehaviour;
  /** The label of the auction, consisting of the vehicle make, model and date of first registration (EZ). */
  label: string;
  /**
   * Date+time when the auction is started.
   * When an auction is started right away (without drafting), the "createdAt" and "startedAt" should have
   * (roughly) the same values.
   * @format date-time
   */
  startedAt: string;
  /**
   * Date+time when the auction is "confirmed to be sold" by the seller (or by an admin).
   * If an auction is ending equal or above the minimum required ask, the "purchaseConfirmedAt"
   * will automatically be set and will be equal to the "endingTime".
   *
   * If an auction is ending below the minimum ask (CLOSED_UNDER_MINIMUM_ASK), the "purchaseConfirmedAt" timestamp
   * is only set if the auction is then manually "confirmed" by the seller or an admin.
   *
   * If an auction is never "successful", the "purchaseConfirmedAt" will remain "null".
   *
   * This value was introduced in the course of 2019. For all previous auctions, the endingTime was set
   * as the "purchaseConfirmedAt" retrospectively.
   * @format date-time
   */
  purchaseConfirmedAt: string;
  /**
   * Date-time when the auction has definitely been rejected by a seller or an admin, after the auction
   * ended below minimum required ask.
   *
   * This value is only available since 2019/07/29. All auctions rejected before that date have the value "null".
   * @format date-time
   */
  purchaseRejectedAt: string;
  /** Indicates the reason that an auction failed the renegotiation process. */
  rejectionReason: EAuctionRejectionReason;
  /**
   * Elaborates on the rejection reason to provide further context to why an
   * auction was rejected during the renegotiation process.
   */
  rejectionReasonNote: string;
  /**
   * The name of the competitor that the auction was sold to when the reject chosen reason was
   * VEHICLE_SOLD_ON_DIFFERENT_PLATFORM
   */
  rejectionReasonCompetitorName: string;
  /** In case if there was a direct upload requested but didn't succeed the reason should be stored. */
  directUploadRejectionReasons: EAuctionDirectUploadRejectionReason[];
  /**
   * Value that is indicating initial bid value auction should be started with.
   * @format double
   */
  startingBidValue: number;
  /** @format double */
  startingBidValueNet: number;
  /**
   * Value that can be manually set in case edge-cases apply and the payout amount is disconnected from the auction current highest bid.
   * This value can only be adjusted by internal users.
   * @format double
   */
  overridePayoutValue: number;
  /**
   * If an auction is ending below the minimum required ask, the "originalMinimumRequiredAsk" will be set to the
   * "minimumRequiredAsk" value before it is set to "currentHighestBidValue".
   * @format double
   */
  originalMinimumRequiredAsk: number;
  /** @format double */
  originalMinimumRequiredAskNet: number;
  /**
   * The purchase price for the vehicle that is auctioned.
   * This is set manually by the seller, and is mostly used to analyze the "real" margin that a seller
   * makes with an auction.
   * @format double
   */
  purchasePrice: number;
  /** @format double */
  purchasePriceNet: number;
  /**
   * The full object of the vehicle that is on auction.
   * This is augmented on every auction retrieval, based on _fk_uuid_vehicle.
   */
  associatedVehicle: IVehicle;
  /** Indicates whether the auction process is rated by the SellerUser. */
  isRatedByDealership: boolean;
  /** Indicates whether the auction process is rated by the BuyerUser. */
  isRatedByBuyer: boolean;
  /**
   * Indicates whether the BuyerUser has marked this auction as paid (payment of the auction volume).
   * This is done manually by the BuyerUser, so this value is not indicating that the actual payment has really
   * reached the seller.
   */
  isPaidByBuyer: boolean;
  /**
   * Timestamp when the BuyerUser has marked this auction as "paid" (i.e. set the "isPaidByBuyer").
   * @format date-time
   */
  outgoingPaymentConfirmedAt: string;
  /**
   * Timestamp when the SellerUser has confirmed that the payment has reached the seller bank account.
   * @format date-time
   */
  incomingPaymentConfirmedAt: string;
  /**
   * Timestamp when the auction state has changed from "CLOSED_WAITING_FOR_PAYMENT" to "CLOSED_WAITING_FOR_PICKUP".
   * @format date-time
   */
  pickupConfirmedAt: string;
  /** Physical address of where the vehicle on auction is located (a.k.a. pickup address). */
  locationCountryCode: ECountryCode;
  locationAddress: string;
  locationCity: string;
  locationZip: string;
  /** Physical address of where the vehicle on auction was inspected (a.k.a. inspection address). */
  inspectionLocationCountryCode: ECountryCode;
  inspectionLocationAddress: string;
  inspectionLocationCity: string;
  inspectionLocationZip: string;
  /**
   * Indicating to which bank account the BuyerUser has to transfer the auction volume to.
   * @deprecated
   */
  sellerIban: string;
  /**
   * URL to the seller invoice for the given auction, manually uploaded by the SellerUser, after
   * the auction has ended successfully.
   */
  urlToInvoice: string;
  /** URL for the cancelled invoice in case it was cancelled. */
  urlToCorrectionInvoice: string;
  /**
   * Flag indicating whether an auction has an "hot bid" phase in the last 20 seconds before expiration.
   * @deprecated
   */
  hotBid: boolean;
  /**
   * Seller's instant purchase price for the vehicle.
   * For the first 24 hours after the auction is started, the vehicle will only be available in "instant purchase"
   * mode (bidding is not allowed in that time, only purchasing right away).
   *
   * After 24 hours, if nobody purchased the vehicle, it is going on auction, just like all other auctions.
   * @format double
   */
  instantPurchasePrice: number;
  /** @format double */
  instantPurchasePriceNet: number;
  /**
   * Flag indicating that instant purchase is enabled for that auction.
   * (Implies that "instantPurchasePrice" is set)
   */
  allowInstantPurchase: boolean;
  /**
   * Flag indicating that an auction "ended" with an instant purchase, resp.
   * did never go on auction.
   */
  didEndWithInstantPurchase: boolean;
  /**
   * Internal counter, incrementing on every "restart" of an auction.
   * Starts with 0.
   *
   * e.g. count of 2 means that the auction has been restarted 2 times, leading to 3 auction objects in the
   * database.
   * @format double
   */
  auctioningIterations: number;
  /**
   * Indicating the strategic priority of an auction for CarOnSale.de
   * The higher the number, the more "relevant" the auction is.
   * @format double
   */
  priority: number;
  /** @deprecated */
  advertisementHtmlContent: string;
  /** @deprecated */
  buyerComplaint: EAuctionBuyerComplaint;
  /**
   * Custom field that is not used internally.
   * Allows sellers to store e.g. a reference ID to their own vehicle management system.
   */
  internalReferenceText: string;
  _fk_uuid_vehicle: string;
  _fk_uuid_sellerUser: string;
  /** This is a URL that redirects to an external invoice payment site (such as provided by Stripe). */
  urlToPaymentSite: string;
  /**
   * A flag indicating if an auction needs to be reviewed by an internal review agent (for quality assurance)
   * If true, an auction is currently under review by CarOnSale, and cannot be edited by the owning seller user.
   */
  needsReview: boolean;
  /** The reasons why an auction was rejected during the review. */
  reviewReasons: EAuctionReviewReason[];
  /**
   * Reasons why an auction is rejected during the review process ("Parkhaus").
   *
   * This reason is automatically set to UNKNOWN for all auctions and is then manually adjusted by
   * an internal review agent (in case the auction gets rejected) before it is sent back to the responsible
   * SellerUser.
   * @deprecated
   */
  reviewReason: EAuctionReviewReason;
  /** Specifies reason when "reviewReasons" contains EAuctionReviewReason.OTHER. */
  reviewReasonDetails: string;
  /** The comment given by an internal review agent on rejecting an auction under review. */
  reviewComment: string;
  /** Flag indicating if the auction can be published without review. */
  canSkipReview?: boolean;
  /**
   * Random PIN code that is generated when an auction is ready to be picked up or in advance before COSCheck inspection.
   * This PIN code is printed out on the hangar in the vehicle or handed to the seller of the auction, which can then forward it to
   * the buyer to confirm the pickup.
   */
  pickupPinCode: string;
  /**
   * Random UUID that is used to confirm vehicle pickup via scanning QR code.
   * It will be printed on the hangar or in the pickup document available for sellers.
   */
  pickupPinUuid: string;
  /**
   * Timestamp when PIN code was entered by the buyer.
   * Allows to determine how many auctions were self-picked up.
   * @format date-time
   */
  pickupPinCodeEnteredAt: string;
  /**
   * Timestamp when PIN UUID was entered by the buyer via scanning QR code.
   * Allows to determine how many auctions were self-picked up.
   * @format date-time
   */
  pickupPinUuidEnteredAt: string;
  /** Flag is indicating if pickup was confirmed with PIN using new process (via pin.caronsale.de) */
  pickupConfirmedWithNewPin: boolean;
  /** URL to the pickup information document for the BuyerUser. */
  urlToPickupBuyerDocument: string;
  /** URL to the pickup information document for the SellerUser (containing the "pickupPinCode"). */
  urlToPickupSellerDocument: string;
  /** URL to the success report document for the SellerUser. */
  urlToSellerSuccessReport: string;
  /** The payment process that is used to pay money for the purchase volume (auction volume). */
  paymentProcess: EAuctionPaymentProcess;
  /**
   * Referencing the bank account to which the payment for the purchase volume should be paid out.
   * This is null, if the paymentProcess === "CLASSIC".
   *
   * It is only used, when external payment (COS Payment) is enabled.
   */
  _fk_uuid_associatedBankAccount: string;
  /**
   * Indicates how often an auction has been reviewed.
   * This is incremented every time an auction is rejected after a review.
   * @format double
   */
  draftReviewIterations: number;
  /**
   * Timestamp when an admin resets a time window for a possibility to complain
   * @format date-time
   */
  complaintTimeWindowExtendedAt: string;
  /**
   * Flag indicates if this auction is suspected to have been paid in cash (only for auctions that use the external
   * invoice process), this means that no corresponding transfer exists on Stripe and the buyer confirmed the pickup
   * through the seller pin code.
   */
  isSuspectedCashPayment: boolean;
  /**
   * Timestamp when the auction was sent to review.
   * @format date-time
   */
  reviewStartedAt: string;
  /**
   * Timestamp when the auction QA process actually started (i.e. when Parkhaus started reviewing the auction).
   * @format date-time
   */
  qaStartedAt: string;
  /**
   * Timestamp when the auction was ready to be reinserted (a.k.a. restarted).
   * @format date-time
   */
  readyToBeReinsertedSince: string;
  /** The reference to the original auction from which current one was cloned. */
  _fk_uuid_clonedFrom: string;
  /** Auction type. */
  type: EAuctionType;
  /** Indicates whether the rejection wait period for this auction is over and thus can be rejected by the seller. */
  isRejectionWaitPeriodOver: boolean;
  /** Reference to invoice object for the external Billomat invoicing provider. */
  thirdPartyVehiclePurchaseInvoiceReference: string;
  /**
   * URL to the seller made invoice invoicing CoS for the given auction, manually uploaded to the crm by an internal user, after
   * the auction has ended successfully.
   */
  sellerToCosInvoiceUrl: string;
  /**
   * Timestamp for when the sellerToCosInvoiceUrl was last updated.
   * @format date-time
   */
  sellerToCosInvoiceUrlLastUpdatedAt: string;
  /**
   * Timestamp for when the sellerToCosInvoiceUrl is first uploaded.
   * @format date-time
   */
  sellerToCosInvoiceUrlFirstUploadedAt: string;
  /** Flag indicating wherever the uploaded seller to cos invoice was verified by an internal user. */
  sellerToCosInvoiceIsValid: boolean;
  /** The comment given by an internal user on the provided seller to cos invoice. */
  sellerToCosInvoiceComment: string;
  /** Shows if auction is marked as 'live', so, it will be slotted during starting and expire in less than 24 hours. */
  isLive: boolean;
  /** Shows if auction was already slotted via the slotting algorithm. */
  isSlotted: boolean;
  /**
   * The exact date+time when the auction was slotted to end.
   * @format date-time
   */
  slottedEndingTime: string;
  /** Indicating if transportation should be disabled manually. */
  isTransportationDisabledManually: boolean;
  /** Optional pickup instructions that will be communicated to the buyer. */
  pickupInstructions: string;
  /** Indicating wherever the seller should be charged a success/listing fee. */
  preventSellerFactoring: boolean;
  /** Indicating if buyer success fee should be applied or not. */
  applyBuyerSuccessFee: boolean;
  /** Info from document shipping service about the order, like status and links to the documents. */
  documentShippingOrder: IAuctionDocumentShippingOrder;
  /** Info about un-/confirmed auction data points. */
  fieldsConfirmationStatus: IAuctionFieldConfirmationStatusMap;
  /**
   * Whether the "Value Added Tax is reportable", a special tax condition in the EU.
   * If this is true, it means that the vehicle is "Regelbesteuert", meaning that the invoice for the vehicle
   * (either invoiced directly from seller to buyer - or from COS Payment to buyer) needs to include
   * the VAT (in Germany e.g. 19%) in the invoice.
   *
   * If this is false, the invoice does not need to have explicit VAT.
   */
  isVATReportable: boolean;
  /** Indicates how the auction was uploaded to the marketplace. */
  uploadMethod: EAuctionUploadMethod;
  /** Prebooked services available for the auction, like transport, warranty. */
  prebookedServices: IPrebookedService[];
  /** Indicates if cosCheckPlus guarantee can be booked for this auction. */
  isCosCheckPlusGuaranteeEnabled: boolean;
  /** Indicates if extended guarantee can be booked for this auction. */
  isExtendedGuaranteeEnabled: boolean;
  /** URL to the auction vehicle cover letter for sellers */
  urlToCoverLetter: string;
  /**
   * Timestamp when the automatic pickup approval was requested for auction
   * @format date-time
   */
  autoPickupOnPaymentRequestedAt: string;
  /**
   * This means that the auctions is using complimentary vehicle feature.
   *
   * Note: This is marked as true when the seller uses one of his complimentary vehicles to pay for the fees.
   */
  isSellerFeeComplimentary: boolean;
  /** Flag when standing fee is enabled to the auction and we'll need to extra charge a buyer as a penalty. */
  isStandingFeeEnabled: boolean;
  _fk_uuid_auctionRoom: string;
  /**
   * An auction can have a list of fulfillment criteria that impact how the
   * auction will be fulfilled depending on the buyer that wins it.
   */
  fulfillmentCriteria?: IAuctionFulfillmentCriteria[];
  /**
   * The auction marked with this flag won't start automatically after review.
   *
   * Instead, it will transition to state: READY_TO_BE_PUBLISHED.
   *
   * Default: false
   */
  shouldWaitToBePublishedAfterReview: boolean;
  /** A flag that marks if an auction is a selective ware or not. */
  isSelectiveWare: boolean;
  /** The id for the look and feel applied to the auction. */
  lookAndFeelId: EAuctionLookAndFeelId;
  /** A flag that marks if an auction will use the pickup qr code. */
  isQRCodeAvailable: boolean;
  /**
   * Indicates whether the highest bid should be transferred if this auction gets cloned.
   * Defaults to `false` if not specified.
   */
  shouldTransferHighestBid?: boolean;
  /** A flag that represents either or not if the auction was paid externally. */
  wasExternallyPaid?: boolean;
  /** A flag that represents either or not if the auction was delivered externally. */
  wasExternallyPickedUp?: boolean;
  /** Confirm to remove auction from auction room when changing auction ending time. */
  removeFromAuctionRoom?: boolean;
  /**
   * Indicates the amount of VAT tax that applies to the auction at current highest bid.
   * @format double
   */
  vatAmount: number;
  /**
   * VAT rate that applies to this auction.
   * @format double
   */
  vatRate: number;
  /** The state of the buy now pay later order */
  buyNowPayLaterOrderState: IBuyNowPayLaterOrderState;
  /** The value of the buy now pay later fee */
  buyNowPayLaterFee: IBuyNowPayLaterAmount;
  /** The link to the order within the buy now pay later provider. */
  buyNowPayLaterProviderOrderUrl: string;
  recommendationId?: string;
  /** The seller user that is owning this auction. */
  seller: ISellerUser;
  /** The account associated with the seller of this auction. */
  account: IAccountWithAssignedInternalUsers;
  /**
   * The associated SellerAccount for this auction.
   * "null" if no SellerAccount is assigned to the seller user.
   * @deprecated
   */
  sellerAccount: ISellerAccount;
  /**
   * The bank account to which the payment should be paid out.
   *
   * "null", if payment process is classic.
   */
  targetSellerBankAccount: ISellerBankAccount;
  /** The current highest bidder for this auction. */
  highestBidder: IGeneralUser;
  /** The transportation job that has been requested for this auction (only if really booked) */
  requestedTransportationTask: ITransportationTask;
  /** This flag indicates whether transport has to be booked through COS (no self-pickup possible) */
  enforceTransportation: boolean;
  /** Containing recent memos for this auction. */
  memos: IAuctionMemo[];
  /**
   * Value of the highest bidding agent currently set.
   * (Always belonging to the current highest bidder)
   * @format double
   */
  highestBiddingAgentValue: number;
  /** @format double */
  highestBiddingAgentValueNet: number;
  /**
   * The current state of the payment (only available for auctions with COS-Payment).
   *
   * This is null, if the payment process is classic.
   */
  externalPaymentState: IAuctionPaymentState;
  /**
   *
   * Url to pick up authorization document for associated transportation task
   */
  urlToSignedPickUpAuthorizationDocument: string;
  /**
   * Number of days from when pickup will be possible.
   * "null" if not available.
   * @format double
   */
  pickupPossibleInDays: number;
  /**
   * Indicates whether the current highest bidder is assigned as a regular buyer to the seller account that
   * is owning that auction.
   *
   * Either true or false, but not null.
   */
  isHighestBidderRegularBuyer: boolean;
  /**
   * An internal user who created an auction, can be an inspector or any other internal user.
   * Filled in from '_fk_uuid_creatingInternalUser' value.
   */
  creatingInternalUser: IGeneralUser;
  /**
   * Indicates whether cross-border net sale is possible for the auction
   * (i.e. the vehicle is "regelbesteuert" and foreign buyers are allowed to purchase it).
   */
  isCrossBorderNetSalePossible: boolean;
  /**
   * Indicates whether the auction actually ended as a cross-border net sale transaction
   * (i.e. the vehicle is "regelbesteuert" and was purchased by a foreign buyer).
   *
   * This is always false for auctions that are NOT closed successfully.
   */
  isCrossBorderNetSaleTransaction: boolean;
  /**
   * Buyer purchase fee that is charged for this auction on success.
   * This is the variable transaction fee, which is calculated based on bucket pricing.
   *
   * This value is augmented, calculated dynamically based on the highest bid value.
   * @format double
   */
  buyerPurchaseFee: number;
  /**
   * Buyer success fee that is charged for this auction on success.
   *
   * This value is augmented, based on the (static) fee that is configured on the seller account.
   * @format double
   */
  buyerSuccessFee: number;
  /**
   * Buyer ExportHandlingFee is charged for cross-border sales
   *
   * It is dependent on the country of the seller.
   *
   * e.g. DE -> 49, NL,FR,AU -> 19 (August '22)
   * @format double
   */
  buyerExportHandlingFee: number;
  /**
   * The GPR associated with the auction.
   *
   * This value is augmented, it can be null.
   */
  gpr: IGuaranteedPriceRequest;
  /**
   * The MPR associated with the auction.
   *
   * This value is augmented, it can be null.
   */
  mpr: ITradeInValuationRequest;
  /** Flag that is marked as true when the auction is enabled for express pickup. */
  isEnabledForExpressPickup: boolean;
  /** Flag that is marked as true when the auction is enabled for automatic release. */
  isEnabledForAutomaticRelease: boolean;
  /** Identify whether the auction vehicle needs to be pickup in the compound */
  isCompoundPickup: boolean;
  /**
   * This flag is showing a availability for transportation (the auction doesn't have to be closed). True by default,
   * false if a vehicle is not movable and the seller is not transport only.
   */
  isTransportationAvailable: boolean;
  /** The external Id of the original auction that this one is a reauction of. */
  originalAuctionExternalId: string;
  /** The external Id of the reauction that this one is the original auction of. */
  reauctionExternalId: string;
  /** Renegotiation augmented from external table */
  renegotiation?: IRenegotiation;
  externalId: string;
  /** From T, pick a set of properties whose keys are in the union K */
  transportationProvider?: PickITransporterUserBusinessNameOrPhoneNumber;
}

/**
 * Inform that a new resource was created. Server will
 * add a Location header and set status to 201
 */
export interface ReturnNewResourceIAuction {
  location: string;
  /** @format double */
  statusCode: number;
  /** the body to be sent */
  body: IAuction;
}

/** Construct a type with a set of properties K of type T */
export type RecordStringStringArray = object;

/** General business terms stored in HTML. */
export interface IGeneralBusinessTerms {
  /**
   * @deprecated
   * @format double
   */
  id: number;
  uuid: string;
  /**
   * Creation date of the general business terms.
   * @format date-time
   */
  createdAt?: string;
  /** The general business terms in the form of HTML. */
  contentAsHtml: string;
  /** Country code for the general business term */
  countryCode: ECountryCode;
}

/**
 * Inform that a new resource was created. Server will
 * add a Location header and set status to 201
 */
export interface ReturnNewResourceIGeneralBusinessTerms {
  location: string;
  /** @format double */
  statusCode: number;
  /** the body to be sent */
  body: IGeneralBusinessTerms;
}

export type IResultMapNumber = Record<string, number>;

/** Indicates the type from where that auction is being listed at. */
export enum EAuctionListType {
  Active = 'active',
  Running = 'running',
  InstantPurchase = 'instant-purchase',
  Parked = 'parked',
  UnderMinAsk = 'under-min-ask',
  WaitingForPayment = 'waiting-for-payment',
  WaitingForPickup = 'waiting-for-pickup',
  Finished = 'finished',
  Private = 'private',
  Recommended = 'recommended',
  MyAuctions = 'my-auctions',
}

/** An object capturing an auction search that the buyer wishes to save and use to pre-fill the auction search later. */
export interface IBuyerAuctionSavedSearch {
  uuid: string;
  /**
   * Indicating the time when the entity has been created, i.e. when the entity was
   * persisted to the database.
   * @format date-time
   */
  createdAt?: string;
  /**
   * Indicating the last time a value of the entity has been changed (updated).
   * @format date-time
   */
  updatedAt?: string;
  /**
   * Indicating the time when the entity has been (softly) deleted.
   * "null" if entry is not deleted.
   * @format date-time
   */
  deletedAt?: string;
  /** The buyer user who owns this saved auction search. */
  _fk_uuid_buyerUser: string;
  /** The filter or search that was saved. */
  auctionFilter: IAuctionFilter;
  /** A flag to denote if notifications for new results of this saved search are to be silenced or sent. */
  shouldNotify: boolean;
  /** A flag to denote if this saved search was marked as favorite by the buyer. */
  isFavorite: boolean;
  /** The name of the saved search as given by the user. */
  title: string;
}

/** Make all properties in T optional */
export interface PartialIBuyerAuctionSavedSearch {
  /** The buyer user who owns this saved auction search. */
  _fk_uuid_buyerUser?: string;
  /** The filter or search that was saved. */
  auctionFilter?: IAuctionFilter;
  /** A flag to denote if notifications for new results of this saved search are to be silenced or sent. */
  shouldNotify?: boolean;
  /** A flag to denote if this saved search was marked as favorite by the buyer. */
  isFavorite?: boolean;
  /** The name of the saved search as given by the user. */
  title?: string;
  /**
   * Indicating the time when the entity has been created, i.e. when the entity was
   * persisted to the database.
   * @format date-time
   */
  createdAt?: string;
  /**
   * Indicating the last time a value of the entity has been changed (updated).
   * @format date-time
   */
  updatedAt?: string;
  /**
   * Indicating the time when the entity has been (softly) deleted.
   * "null" if entry is not deleted.
   * @format date-time
   */
  deletedAt?: string;
  uuid?: string;
}

/**
 * Inform that a new resource was created. Server will
 * add a Location header and set status to 201
 */
export interface ReturnNewResourceIRating {
  location: string;
  /** @format double */
  statusCode: number;
  /** the body to be sent */
  body: IRating;
}

/** A bidding agent (task) that can be set by buyers on auctions. */
export interface IAuctionBiddingAgentTask {
  uuid: string;
  /**
   * Indicating the time when the entity has been created, i.e. when the entity was
   * persisted to the database.
   * @format date-time
   */
  createdAt?: string;
  /**
   * Indicating the last time a value of the entity has been changed (updated).
   * @format date-time
   */
  updatedAt?: string;
  /**
   * Indicating the time when the entity has been (softly) deleted.
   * "null" if entry is not deleted.
   * @format date-time
   */
  deletedAt?: string;
  /**
   * The value of the bidding agent.
   * Up to this value, the bidding agent will outbid any other (lower) bids by other buyers.
   * @format double
   */
  maxBidValue: number;
  /** @format double */
  maxBidValueNet: number;
  /** The buyer/bidder which set the bidding agent. */
  _fk_uuid_buyerUser: string;
  /** The auction for which the bidding agent has been set. */
  _fk_uuid_auction: string;
  /** Indicating whether the agent was placed from the list view. */
  isListViewAgent: boolean;
  /**
   * Indicating the time when the bidding agent has been deactivated.
   * Deactivated bidding agents will not take effect, thus not producing any bids.
   * Unlike deletedAt, this is a temporary state.
   * The motivation is that we want to block bidding agents when the buyer is prohibited from bidding.
   * We may to extend this to other use cases in the future.
   * @format date-time
   */
  deactivatedAt?: string;
}

/**
 * Inform that a new resource was created. Server will
 * add a Location header and set status to 201
 */
export interface ReturnNewResourceIAuctionBiddingAgentTask {
  location: string;
  /** @format double */
  statusCode: number;
  /** the body to be sent */
  body: IAuctionBiddingAgentTask;
}

export interface IBiddingAgentParam {
  /** @format double */
  maxValue: number;
  /** @format double */
  maxValueNet: number;
  isListViewAgent?: boolean;
}

export interface IHasUUId {
  uuid: string;
}

/**
 * Inform that a new resource was created. Server will
 * add a Location header and set status to 201
 */
export interface ReturnNewResourceIHasUUId {
  location: string;
  /** @format double */
  statusCode: number;
  /** the body to be sent */
  body: IHasUUId;
}

/** Auctions, as they are seen by buyers. */
export interface IBuyerAuctionView {
  /**
   * @deprecated
   * @format double
   */
  id: number;
  uuid: string;
  /**
   * Indicating the time when the entity has been created, i.e. when the entity was
   * persisted to the database.
   * @format date-time
   */
  createdAt?: string;
  /**
   * Indicating the last time a value of the entity has been changed (updated).
   * @format date-time
   */
  updatedAt?: string;
  /**
   * Indicating the time when the entity has been (softly) deleted.
   * "null" if entry is not deleted.
   * @format date-time
   */
  deletedAt?: string;
  /** Stores which type of additional tax applies to the auction if any. */
  additionalTaxType: EAdditionalTaxType;
  /**
   * Stores the value of the additional tax that might apply to an auction.
   * @format double
   */
  additionalTaxValue: number;
  /**
   * Stores the export discount for taxes.
   * @format double
   */
  additionalTaxExportDiscount: number;
  state: EAuctionState;
  /** @format date-time */
  endingTime: string;
  /** @format double */
  remainingTimeInSeconds: number;
  /** @format double */
  remainingTimeForInstantPurchaseInSeconds: number;
  /** @format date-time */
  instantPurchasePossibleUntil: string;
  _fk_uuid_highestBiddingBuyerUser: string;
  /** @format double */
  currentHighestBidValue: number;
  /** @format double */
  currentHighestBidValueNet: number;
  /** @format double */
  minimumRequiredAsk: number;
  /** @format double */
  minimumRequiredAskNet: number;
  /** @format double */
  numBids: number;
  /** @format double */
  buyerRecommendationScore?: number;
  /** Was this auction created with the help of an inspection done or commissioned by CarOnSale? */
  inspectionWasCommissionedByCos: boolean;
  /** By which type of user was this auction created. */
  creatorType: EAuctionCreatorType;
  /** UUID of the creating internal user if there is one. */
  _fk_uuid_creatingInternalUser: string;
  /** UUID of the creating seller user if there is one. */
  _fk_uuid_creatingSellerUser: string;
  /**
   * Indicates if the auction was created for testing purposes and
   * should be handled differently: it should not be ended automatically,
   * so the seller should decide would he like to sell it or not.
   */
  isTest: boolean;
  /** Indicates if minimum required ask should be displayed for buyers. */
  displayMinAsk: boolean;
  /**
   * GPS coordinates of the auction (can be used for e.g. calculating distances between auctions and buyers).
   * @format double
   */
  locationGeoLat: number;
  /** @format double */
  locationGeoLon: number;
  /** @format double */
  counterOfferByBuyer: number;
  /** @format double */
  counterOfferByBuyerNet: number;
  /** @format double */
  lastOfferBySeller: number;
  /** @format double */
  lastOfferBySellerNet: number;
  /**
   * A value denoting the previous renegotiation offer given by a buyer user.
   * @format double
   */
  previousCounterOfferByBuyer: number;
  /** @format double */
  previousCounterOfferByBuyerNet: number;
  /**
   * A value denoting the previous renegotiation offer given by a seller user.
   * This is required in order to properly display the right information during the renegotiation process.
   * @format double
   */
  previousLastOfferBySeller: number;
  /** @format double */
  previousLastOfferBySellerNet: number;
  /** A flag for if the seller user connected to the auction has decided not to participate in the renegotiation any further. */
  renegotiationStoppedBySeller: boolean;
  /** A flag for if the highest bidding buyer user connected to the auction has decided not to participate in the renegotiation any further. */
  renegotiationStoppedByBuyer: boolean;
  /** A fag indicating if the buyer's withdrawal from the renegotiation process was done automatically or not. */
  wasRenegotiationStoppageByBuyerAutomatic: boolean;
  /** A fag indicating if the seller's withdrawal from the renegotiation process was done automatically or not. */
  wasRenegotiationStoppageBySellerAutomatic: boolean;
  /**
   * The total number of offer rounds a buyer has participated in for an auction during the renegotiation phase.
   * @format double
   */
  numBuyerRenegotiationOfferRounds: number;
  /**
   * The total number of offer rounds a seller has participated in for an auction during the renegotiation phase.
   * @format double
   */
  numSellerRenegotiationOfferRounds: number;
  /**
   * The calculated renegotiation midpoint value of the auction.
   * This is used once the renegotiation offers get too close for the offer process to continue.
   * This value will be undefined unless the auction is ready to be purchased for such a value.
   * @format double
   */
  renegotiationMidpointValue: number;
  /** @format double */
  renegotiationMidpointValueNet: number;
  /**
   * Indicates that the highest bidding buyer user for an auction in renegotiation is willing to accept the midpoint of their offer and
   * the offer of the seller for the same auction. This is used when the renegotiation offers get very close during the renegotiation process.
   */
  buyerAcceptedRenegotiationMidpoint: boolean;
  /**
   * Indicates that the seller of an auction is willing to accept the midpoint of their offer and the offer of the highest bidding
   * buyer user for the same auction. This is used when the renegotiation offers get very close during the renegotiation process.
   */
  sellerAcceptedRenegotiationMidpoint: boolean;
  /**
   * This is the datetime object denoting when the most recent renegotiation round concluded at.
   * @format date-time
   */
  lastRenegotiationRoundEndedAt: string;
  /**
   * The number of seconds until the current renegotiation round times out.
   * @format double
   */
  numSecondsUntilRenegotiationTimeout: number;
  /**
   * The UUID of the external case tracking the renegotiation of the auction.
   * We track this to enable case updates as the auction moves through renegotiation.
   */
  externalRenegotiationTicketUuid: string;
  /**
   * The calculated renegotiation fair value of the auction.
   * This is used once the renegotiation starts based on the auction GPR or MPR so we know
   * the fair value that both seller and buyer should agree upon.
   * @format double
   */
  renegotiationFairValue: number;
  /**
   * Value that is indicating the highest bid value on the auction when it ended.
   * This value should not change for a given auction since it supposed to be a historical record.
   * @format double
   */
  highestBidValueAtEndingTime: number;
  /** @format double */
  highestBidValueAtEndingTimeNet: number;
  /** Field data related to the renegotiation round process. */
  renegotiationRoundData?: IRenegotiationRoundData;
  /**
   * Indicates when manual renegotiation was finished via Salesforce.
   * @format date-time
   */
  finishedManuallyAt?: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing service (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the transfer of funds
   * for the vat deposit from COS to the deposit account (only relevant for cross border transactions).
   * This deposit is refunded by COS once the buyer provides the necessary documentation for a cross border transaction.
   */
  thirdPartyVATDepositTransferReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing service (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the transfer of funds
   * for the additional tax deposit from COS to the deposit account (only relevant for cross border transactions).
   * This deposit is refunded by COS once the buyer provides the necessary documentation for a cross border transaction.
   */
  thirdPartyAdditionalTaxDepositTransferReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing system (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the transfer of funds
   * for the auction additional taxes (e.g country specific luxury taxes): from COS to the seller.
   */
  thirdPartyAdditionalTaxTransferReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing service (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the transfer of funds
   * for the VAT: from COS to the seller (only relevant for cross border transactions for now).
   * COS is advancing the vat amount to the seller.
   */
  thirdPartyVatTransferReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing service (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the charge of funds
   * for the auction volume from the buyer to COS.
   */
  thirdPartyVolumeChargeReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing service (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the charge of funds
   * for the auction deposit from the buyer to COS (only relevant for cross border transactions for now).
   * This deposit is refunded by COS once the buyer provides the necessary documentation for a cross border transaction.
   */
  thirdPartyVATDepositChargeReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing service (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the charge of funds
   * for the auction additional tax (e.g country specific luxury tax) from the buyer to COS.
   */
  thirdPartyAdditionalTaxChargeReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing system (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the refund of an
   * additional tax deposit (only used for cross-border transactions currently).
   */
  thirdPartyAdditionalTaxRefundReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing system (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the payout of funds.
   */
  thirdPartyPayoutReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing system (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the refund of a
   * deposit (only used for cross-border transactions currently).
   */
  thirdPartyVATDepositRefundReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing system (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the refund of a
   * transfer to the deposit account owned by cos (only used for cross-border transactions currently).
   */
  thirdPartyVATDepositReversalReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing system (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the refund of the
   * additional tax transfer to the deposit account owned by cos (only used for cross-border transactions).
   */
  thirdPartyAdditionalTaxDepositReversalReference: string;
  /**
   * Reference to the invoice for the success fee issued to the seller for a sold vehicle (see ESellerFeeInvoicingMode.INVOICE_ON_SUCCESS).
   * The reference identifies the invoice issued to the seller in an external invoicing system (e.g. Stripe).
   *
   * This fee is not always charged, therefore this reference is optional (can be null).
   */
  sellerSuccessFeeInvoiceReference: string;
  /**
   * Reference to the invoice for the listing fee issued to the seller for a listed vehicle (see ESellerFeeInvoicingMode.INVOICE_ON_LISTING).
   * The reference identifies the invoice issued to the seller in an external invoicing system (e.g. Stripe).
   *
   * This fee is not always charged, therefore this reference is optional (can be null).
   */
  sellerListingFeeInvoiceReference: string;
  /**
   * Reference to the invoice for the standing fee issued to the buyer for a purchased vehicle picked up too late.
   *
   * This fee is not always charged, therefore this reference is optional (can be null).
   */
  buyerStandingFeeInvoiceReference: string;
  /**
   * Reference to a resource in an external invoicing system (such as Stripe).
   * References our own transaction fee invoice.
   */
  invoiceReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing system (such as Stripe).
   * This is only non-null, if external payment is allowed for this auction.
   */
  thirdPartyInvoiceReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing system.
   * This is only non-null, if external payment is allowed for this auction and it had to be cancelled.
   */
  thirdPartyCorrectionInvoiceReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing system (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the transfer of funds
   * for the auction volume: from COS to the seller.
   */
  thirdPartyTransferReference: string;
  /**
   * Reference of an invoice on an external invoicing system.
   * This is invoice is used to charge booked services (like guarantee) after the auction expires.
   */
  bookedServicesInvoiceReference: string;
  /**
   * Date-time when the auction was reauctioned and is now waiting for buyer payment until the new auction gets paid.
   * @format date-time
   */
  reauctionedWaitingForBuyerPaymentAt: string;
  /**
   * Date-time when the first reauction of this auction has expired.
   * We need to keep track of this so we can block the original buyer of seeing the original auction.
   * @format date-time
   */
  firstReauctionExpiredAt: string;
  /** Flag that indicates if this auction is a clonned reauction. */
  isReauction: boolean;
  /**
   * The highest bid value that we show to the seller when we merge the reauction with the original auction.
   * This is done so the seller see the amount that cos pay them and the buyer sees the amount that they paid cos.
   * @format double
   */
  sellerViewHighestBidValue: number;
  /**
   * The purchase confirmed at that we show to the seller when we merge the reauction with the original auction.
   * This is done so the seller see the date when the original auction was confirmed and the buyer sees the date that the reauction was confirmed.
   * @format date-time
   */
  sellerViewPurchaseConfirmedAt: string;
  /**
   * Date-time when the reauction was merged back at the original auction.
   * @format date-time
   */
  reauctionedMergedAt: string;
  /** Indicates wherever an auction was purchase using the Buy Now Pay Later feature. */
  wasPurchasedUsingBuyNowPayLater: boolean;
  /**
   * The timestamp at which the buyer has confirmed his choice to use Buy Now Pay Later for the auction.
   * @format date-time
   */
  buyNowPayLaterConfirmedAt: string;
  /** The IP address which confirmed the Buy Now Pay Later choice for the auction. */
  buyNowPayLaterConfirmationIPAddress: string;
  /** Reference to the Buy Now Pay Later Order on the PNI Service. */
  buyNowPayLaterOrderReference: string;
  /** Reference of the invoice for buy now pay later provider fee on an external invoicing system. */
  buyNowPayLaterInvoiceReference: string;
  /** The payment link reference */
  paymentLinkReference: string;
  /** Reference to the payment order on the P&I service */
  paymentOrderReference: string;
  isInternalFulfillment: boolean;
  belowMinAskBehaviour: EAuctionBelowMinAskBehaviour;
  /** The label of the auction, consisting of the vehicle make, model and date of first registration (EZ). */
  label: string;
  /**
   * Date+time when the auction is started.
   * When an auction is started right away (without drafting), the "createdAt" and "startedAt" should have
   * (roughly) the same values.
   * @format date-time
   */
  startedAt: string;
  /**
   * Date+time when the auction is "confirmed to be sold" by the seller (or by an admin).
   * If an auction is ending equal or above the minimum required ask, the "purchaseConfirmedAt"
   * will automatically be set and will be equal to the "endingTime".
   *
   * If an auction is ending below the minimum ask (CLOSED_UNDER_MINIMUM_ASK), the "purchaseConfirmedAt" timestamp
   * is only set if the auction is then manually "confirmed" by the seller or an admin.
   *
   * If an auction is never "successful", the "purchaseConfirmedAt" will remain "null".
   *
   * This value was introduced in the course of 2019. For all previous auctions, the endingTime was set
   * as the "purchaseConfirmedAt" retrospectively.
   * @format date-time
   */
  purchaseConfirmedAt: string;
  /**
   * Date-time when the auction has definitely been rejected by a seller or an admin, after the auction
   * ended below minimum required ask.
   *
   * This value is only available since 2019/07/29. All auctions rejected before that date have the value "null".
   * @format date-time
   */
  purchaseRejectedAt: string;
  /** Indicates the reason that an auction failed the renegotiation process. */
  rejectionReason: EAuctionRejectionReason;
  /**
   * Elaborates on the rejection reason to provide further context to why an
   * auction was rejected during the renegotiation process.
   */
  rejectionReasonNote: string;
  /**
   * The name of the competitor that the auction was sold to when the reject chosen reason was
   * VEHICLE_SOLD_ON_DIFFERENT_PLATFORM
   */
  rejectionReasonCompetitorName: string;
  /** In case if there was a direct upload requested but didn't succeed the reason should be stored. */
  directUploadRejectionReasons: EAuctionDirectUploadRejectionReason[];
  /**
   * Value that is indicating initial bid value auction should be started with.
   * @format double
   */
  startingBidValue: number;
  /** @format double */
  startingBidValueNet: number;
  /**
   * Value that can be manually set in case edge-cases apply and the payout amount is disconnected from the auction current highest bid.
   * This value can only be adjusted by internal users.
   * @format double
   */
  overridePayoutValue: number;
  /**
   * If an auction is ending below the minimum required ask, the "originalMinimumRequiredAsk" will be set to the
   * "minimumRequiredAsk" value before it is set to "currentHighestBidValue".
   * @format double
   */
  originalMinimumRequiredAsk: number;
  /** @format double */
  originalMinimumRequiredAskNet: number;
  /**
   * The purchase price for the vehicle that is auctioned.
   * This is set manually by the seller, and is mostly used to analyze the "real" margin that a seller
   * makes with an auction.
   * @format double
   */
  purchasePrice: number;
  /** @format double */
  purchasePriceNet: number;
  /**
   * The full object of the vehicle that is on auction.
   * This is augmented on every auction retrieval, based on _fk_uuid_vehicle.
   */
  associatedVehicle: IVehicle;
  /** Indicates whether the auction process is rated by the SellerUser. */
  isRatedByDealership: boolean;
  /** Indicates whether the auction process is rated by the BuyerUser. */
  isRatedByBuyer: boolean;
  /**
   * Indicates whether the BuyerUser has marked this auction as paid (payment of the auction volume).
   * This is done manually by the BuyerUser, so this value is not indicating that the actual payment has really
   * reached the seller.
   */
  isPaidByBuyer: boolean;
  /**
   * Timestamp when the BuyerUser has marked this auction as "paid" (i.e. set the "isPaidByBuyer").
   * @format date-time
   */
  outgoingPaymentConfirmedAt: string;
  /**
   * Timestamp when the SellerUser has confirmed that the payment has reached the seller bank account.
   * @format date-time
   */
  incomingPaymentConfirmedAt: string;
  /**
   * Timestamp when the auction state has changed from "CLOSED_WAITING_FOR_PAYMENT" to "CLOSED_WAITING_FOR_PICKUP".
   * @format date-time
   */
  pickupConfirmedAt: string;
  /** Physical address of where the vehicle on auction is located (a.k.a. pickup address). */
  locationCountryCode: ECountryCode;
  locationAddress: string;
  locationCity: string;
  locationZip: string;
  /** Physical address of where the vehicle on auction was inspected (a.k.a. inspection address). */
  inspectionLocationCountryCode: ECountryCode;
  inspectionLocationAddress: string;
  inspectionLocationCity: string;
  inspectionLocationZip: string;
  /**
   * Indicating to which bank account the BuyerUser has to transfer the auction volume to.
   * @deprecated
   */
  sellerIban: string;
  /**
   * URL to the seller invoice for the given auction, manually uploaded by the SellerUser, after
   * the auction has ended successfully.
   */
  urlToInvoice: string;
  /** URL for the cancelled invoice in case it was cancelled. */
  urlToCorrectionInvoice: string;
  /**
   * Flag indicating whether an auction has an "hot bid" phase in the last 20 seconds before expiration.
   * @deprecated
   */
  hotBid: boolean;
  /**
   * Seller's instant purchase price for the vehicle.
   * For the first 24 hours after the auction is started, the vehicle will only be available in "instant purchase"
   * mode (bidding is not allowed in that time, only purchasing right away).
   *
   * After 24 hours, if nobody purchased the vehicle, it is going on auction, just like all other auctions.
   * @format double
   */
  instantPurchasePrice: number;
  /** @format double */
  instantPurchasePriceNet: number;
  /**
   * Flag indicating that instant purchase is enabled for that auction.
   * (Implies that "instantPurchasePrice" is set)
   */
  allowInstantPurchase: boolean;
  /**
   * Flag indicating that an auction "ended" with an instant purchase, resp.
   * did never go on auction.
   */
  didEndWithInstantPurchase: boolean;
  /**
   * Internal counter, incrementing on every "restart" of an auction.
   * Starts with 0.
   *
   * e.g. count of 2 means that the auction has been restarted 2 times, leading to 3 auction objects in the
   * database.
   * @format double
   */
  auctioningIterations: number;
  /**
   * Indicating the strategic priority of an auction for CarOnSale.de
   * The higher the number, the more "relevant" the auction is.
   * @format double
   */
  priority: number;
  /** @deprecated */
  advertisementHtmlContent: string;
  /** @deprecated */
  buyerComplaint: EAuctionBuyerComplaint;
  /**
   * Custom field that is not used internally.
   * Allows sellers to store e.g. a reference ID to their own vehicle management system.
   */
  internalReferenceText: string;
  _fk_uuid_vehicle: string;
  _fk_uuid_sellerUser: string;
  /** This is a URL that redirects to an external invoice payment site (such as provided by Stripe). */
  urlToPaymentSite: string;
  /**
   * A flag indicating if an auction needs to be reviewed by an internal review agent (for quality assurance)
   * If true, an auction is currently under review by CarOnSale, and cannot be edited by the owning seller user.
   */
  needsReview: boolean;
  /** The reasons why an auction was rejected during the review. */
  reviewReasons: EAuctionReviewReason[];
  /**
   * Reasons why an auction is rejected during the review process ("Parkhaus").
   *
   * This reason is automatically set to UNKNOWN for all auctions and is then manually adjusted by
   * an internal review agent (in case the auction gets rejected) before it is sent back to the responsible
   * SellerUser.
   * @deprecated
   */
  reviewReason: EAuctionReviewReason;
  /** Specifies reason when "reviewReasons" contains EAuctionReviewReason.OTHER. */
  reviewReasonDetails: string;
  /** The comment given by an internal review agent on rejecting an auction under review. */
  reviewComment: string;
  /** Flag indicating if the auction can be published without review. */
  canSkipReview?: boolean;
  /**
   * Random PIN code that is generated when an auction is ready to be picked up or in advance before COSCheck inspection.
   * This PIN code is printed out on the hangar in the vehicle or handed to the seller of the auction, which can then forward it to
   * the buyer to confirm the pickup.
   */
  pickupPinCode: string;
  /**
   * Random UUID that is used to confirm vehicle pickup via scanning QR code.
   * It will be printed on the hangar or in the pickup document available for sellers.
   */
  pickupPinUuid: string;
  /**
   * Timestamp when PIN code was entered by the buyer.
   * Allows to determine how many auctions were self-picked up.
   * @format date-time
   */
  pickupPinCodeEnteredAt: string;
  /**
   * Timestamp when PIN UUID was entered by the buyer via scanning QR code.
   * Allows to determine how many auctions were self-picked up.
   * @format date-time
   */
  pickupPinUuidEnteredAt: string;
  /** Flag is indicating if pickup was confirmed with PIN using new process (via pin.caronsale.de) */
  pickupConfirmedWithNewPin: boolean;
  /** URL to the pickup information document for the BuyerUser. */
  urlToPickupBuyerDocument: string;
  /** URL to the pickup information document for the SellerUser (containing the "pickupPinCode"). */
  urlToPickupSellerDocument: string;
  /** URL to the success report document for the SellerUser. */
  urlToSellerSuccessReport: string;
  /** The payment process that is used to pay money for the purchase volume (auction volume). */
  paymentProcess: EAuctionPaymentProcess;
  /**
   * Referencing the bank account to which the payment for the purchase volume should be paid out.
   * This is null, if the paymentProcess === "CLASSIC".
   *
   * It is only used, when external payment (COS Payment) is enabled.
   */
  _fk_uuid_associatedBankAccount: string;
  /**
   * Indicates how often an auction has been reviewed.
   * This is incremented every time an auction is rejected after a review.
   * @format double
   */
  draftReviewIterations: number;
  /**
   * Timestamp when an admin resets a time window for a possibility to complain
   * @format date-time
   */
  complaintTimeWindowExtendedAt: string;
  /**
   * Flag indicates if this auction is suspected to have been paid in cash (only for auctions that use the external
   * invoice process), this means that no corresponding transfer exists on Stripe and the buyer confirmed the pickup
   * through the seller pin code.
   */
  isSuspectedCashPayment: boolean;
  /**
   * Timestamp when the auction was sent to review.
   * @format date-time
   */
  reviewStartedAt: string;
  /**
   * Timestamp when the auction QA process actually started (i.e. when Parkhaus started reviewing the auction).
   * @format date-time
   */
  qaStartedAt: string;
  /**
   * Timestamp when the auction was ready to be reinserted (a.k.a. restarted).
   * @format date-time
   */
  readyToBeReinsertedSince: string;
  /** The reference to the original auction from which current one was cloned. */
  _fk_uuid_clonedFrom: string;
  /** Auction type. */
  type: EAuctionType;
  /** Indicates whether the rejection wait period for this auction is over and thus can be rejected by the seller. */
  isRejectionWaitPeriodOver: boolean;
  /** Reference to invoice object for the external Billomat invoicing provider. */
  thirdPartyVehiclePurchaseInvoiceReference: string;
  /**
   * URL to the seller made invoice invoicing CoS for the given auction, manually uploaded to the crm by an internal user, after
   * the auction has ended successfully.
   */
  sellerToCosInvoiceUrl: string;
  /**
   * Timestamp for when the sellerToCosInvoiceUrl was last updated.
   * @format date-time
   */
  sellerToCosInvoiceUrlLastUpdatedAt: string;
  /**
   * Timestamp for when the sellerToCosInvoiceUrl is first uploaded.
   * @format date-time
   */
  sellerToCosInvoiceUrlFirstUploadedAt: string;
  /** Flag indicating wherever the uploaded seller to cos invoice was verified by an internal user. */
  sellerToCosInvoiceIsValid: boolean;
  /** The comment given by an internal user on the provided seller to cos invoice. */
  sellerToCosInvoiceComment: string;
  /** Shows if auction is marked as 'live', so, it will be slotted during starting and expire in less than 24 hours. */
  isLive: boolean;
  /** Shows if auction was already slotted via the slotting algorithm. */
  isSlotted: boolean;
  /**
   * The exact date+time when the auction was slotted to end.
   * @format date-time
   */
  slottedEndingTime: string;
  /** Indicating if transportation should be disabled manually. */
  isTransportationDisabledManually: boolean;
  /** Optional pickup instructions that will be communicated to the buyer. */
  pickupInstructions: string;
  /** Indicating wherever the seller should be charged a success/listing fee. */
  preventSellerFactoring: boolean;
  /** Indicating if buyer success fee should be applied or not. */
  applyBuyerSuccessFee: boolean;
  /** Info from document shipping service about the order, like status and links to the documents. */
  documentShippingOrder: IAuctionDocumentShippingOrder;
  /** Info about un-/confirmed auction data points. */
  fieldsConfirmationStatus: IAuctionFieldConfirmationStatusMap;
  /**
   * Whether the "Value Added Tax is reportable", a special tax condition in the EU.
   * If this is true, it means that the vehicle is "Regelbesteuert", meaning that the invoice for the vehicle
   * (either invoiced directly from seller to buyer - or from COS Payment to buyer) needs to include
   * the VAT (in Germany e.g. 19%) in the invoice.
   *
   * If this is false, the invoice does not need to have explicit VAT.
   */
  isVATReportable: boolean;
  /** Indicates how the auction was uploaded to the marketplace. */
  uploadMethod: EAuctionUploadMethod;
  /** Prebooked services available for the auction, like transport, warranty. */
  prebookedServices: IPrebookedService[];
  /** Indicates if cosCheckPlus guarantee can be booked for this auction. */
  isCosCheckPlusGuaranteeEnabled: boolean;
  /** Indicates if extended guarantee can be booked for this auction. */
  isExtendedGuaranteeEnabled: boolean;
  /** URL to the auction vehicle cover letter for sellers */
  urlToCoverLetter: string;
  /**
   * Timestamp when the automatic pickup approval was requested for auction
   * @format date-time
   */
  autoPickupOnPaymentRequestedAt: string;
  /**
   * This means that the auctions is using complimentary vehicle feature.
   *
   * Note: This is marked as true when the seller uses one of his complimentary vehicles to pay for the fees.
   */
  isSellerFeeComplimentary: boolean;
  /** Flag when standing fee is enabled to the auction and we'll need to extra charge a buyer as a penalty. */
  isStandingFeeEnabled: boolean;
  _fk_uuid_auctionRoom: string;
  /**
   * An auction can have a list of fulfillment criteria that impact how the
   * auction will be fulfilled depending on the buyer that wins it.
   */
  fulfillmentCriteria?: IAuctionFulfillmentCriteria[];
  /**
   * The auction marked with this flag won't start automatically after review.
   *
   * Instead, it will transition to state: READY_TO_BE_PUBLISHED.
   *
   * Default: false
   */
  shouldWaitToBePublishedAfterReview: boolean;
  /** A flag that marks if an auction is a selective ware or not. */
  isSelectiveWare: boolean;
  /** The id for the look and feel applied to the auction. */
  lookAndFeelId: EAuctionLookAndFeelId;
  /** A flag that marks if an auction will use the pickup qr code. */
  isQRCodeAvailable: boolean;
  /**
   * Indicates whether the highest bid should be transferred if this auction gets cloned.
   * Defaults to `false` if not specified.
   */
  shouldTransferHighestBid?: boolean;
  recommendationId?: string;
  /** Indicates whether the buyer is currently the highest bidder for the auction. */
  amIHighestBidder: boolean;
  /** This flag indicates whether the minimumRequiredAsk has already been reached. */
  isMinAskReached: boolean;
  /** @deprecated */
  isWatching: boolean;
  /**
   * This property contains the value of the current bidding agent, set by the buyer.
   * Optional, if it's not set it means that no bidding agent is set.
   * @format double
   */
  biddingAgentValue?: number;
  /** @format double */
  biddingAgentValueNet?: number;
  /**
   * A bidding agent could be set, but temporarily not active (for example, if the buyer is blocked of bidding).
   * This flag indicates whether the bidding agent is active or not.
   * Optional, if it's not set it means that no bidding agent is set.
   */
  biddingAgentActive?: boolean;
  /**
   * Indicates whether the given auction is a cross-border net sale transaction for the buyer looking at it.
   * This is the case if the vehicle is "regelbesteuert" and sold from a country that is different from the buyer's country.
   */
  isCrossBorderNetSale: boolean;
  /**
   * Purchase fee that is charged to the buyer on success of the auction.
   * This is the variable transaction fee, which is calculated based on bucket pricing.
   *
   * This value is augmented, calculated dynamically based on the highest bid value.
   *
   * Discounts are already applied
   * @format double
   */
  buyerPurchaseFee: number;
  /**
   * Purchase fee that is charged to the buyer on success of the auction.
   * This is the variable transaction fee, which is calculated based on bucket pricing.
   *
   * This value is augmented, calculated dynamically based on the highest bid value.
   *
   * Discounts are not applied yet
   * @format double
   */
  buyerPurchaseFeeBeforeDiscounts: number;
  /** A list with all the discounts that are currently applied to the auction. */
  appliedDiscounts?: IAuctionDiscount[];
  /**
   * Fee that should be charged to the buyer in case the auction is successful (fee will be added to purchase fee invoice).
   * This value is augmented, based on the fee that is configured on the seller account.
   * @format double
   */
  buyerSuccessFee: number;
  /**
   * Total fee or discount for cross-border sale transactions.
   * This is a calculated field based on the Export Discount and Export Handling Fee
   * @format double
   */
  buyerCrossBorderProcessingAmount: number;
  /**
   * Indicates the amount of VAT tax that applies to the auction at current highest bid.
   * @format double
   */
  vatAmount: number;
  /**
   * VAT rate that applies to this auction.
   * @format double
   */
  vatRate: number;
  /**
   * End of the time that a user has to make a complaint after a self-pickup or transport booking process.
   * @format date-time
   */
  complaintPeriodEndAt: string;
  /**
   * End of the time available that a user can book a transportation.
   * @format date-time
   */
  transportBookingPeriodEndAt: string;
  /** The state of the buy now pay later order */
  buyNowPayLaterOrderState?: IBuyNowPayLaterOrderState;
  /** The value of the buy now pay later fee */
  buyNowPayLaterFee?: IBuyNowPayLaterAmount;
  /** The link to the order within the buy now pay later provider. */
  buyNowPayLaterProviderOrderUrl?: string;
  paymentLinkStatus?: EPaymentLinkStatus;
  /** A flag that represents either or not if the auction was paid externally. */
  wasExternallyPaid?: boolean;
  /** A flag that represents either or not if the auction was delivered externally. */
  wasExternallyPickedUp?: boolean;
  /**
   * Indicates whether the buyer has bid at least once in an auction.
   *
   * Either true or false, but not null.
   */
  didIBidAtLeastOnce: boolean;
  /**
   * Indicates whether the buyer has this auction in the parking lot.
   *
   * Either true or false, but not null.
   */
  isParked: boolean;
  /**
   * Indicates whether this auction is recommended based on the buyers activity.
   *
   * Either true or false, but not null.
   */
  isRecommended: boolean;
  /**
   * Indicates whether the buyer is assigned as a regular buyer to the seller account that
   * is owning that auction.
   *
   * Either true or false, but not null.
   */
  amIRegularBuyer: boolean;
  /**
   * Seller account offering this auction.
   * The account data is displayed partially for buyers depending on the Buyer and the Auction state.
   *
   * The attributes are the same from ISellerAccount (this could be a Partial<ISellerAccount>,
   * but it's not due to limitations of backend libraries with Utility Types)
   */
  sellerAccount: {
    isCompoundEnabled?: boolean;
    shouldApplyStandingCosts?: boolean;
    name?: string;
    uuid?: string;
  };
  /** If available, contains the rating for the auction (given from buyer to seller) */
  rating: IRating;
  /** This property is indicating the business type of the selling user. */
  sellerType: ESellerBusinessType;
  /**
   * If visible to the buyer (e.g. after a successful auction), this contains the contact information about
   * the seller for the auction.
   *
   * null if auction is not yet expired.
   * Not null if buyer has "won" the auction.
   */
  sellerContact: ISellerUser;
  /**
   * This field contains the associated transportation task that was booked by the buyer.
   * "null" if no task has been booked.
   */
  transportationTask: ITransportationTask;
  /**
   * This flag should indicate whether an external payment service (i.e. CarOnPay)
   * can be used to facilitate the payment.
   */
  isExternalPaymentAllowed: boolean;
  /** This flag indicates whether transport has to be booked through CarOnSale. */
  enforceTransportation: boolean;
  /**
   * This is a countdown, being set if an auction is waiting for payment.
   * After a certain amount of days without payment, the vehicle will be re-auctioned.
   * @format double
   */
  remainingDaysUntilReauctioning: number;
  /**
   * This is a countdown, being set if an auction is waiting for pickup.
   * After a certain amount of days without pickup, the late pickup fee will be applied (based on the days).
   * @format double
   */
  remainingDaysUntilLatePickup: number;
  /**
   * This is a field containing the fee that the buyer has to pay due to late pickup.
   * @format double
   */
  latePickupFee: number;
  /**
   * This flag is showing a possibility to book a transportation. True by default,
   * switching to false after 72 hours after purchase
   */
  isTransportationBookingPossible: boolean;
  /**
   * This flag is showing a availability for transportation (the auction doesn't have to be closed). True by default,
   * false if a vehicle is not movable and the seller is not transport only.
   */
  isTransportationAvailable: boolean;
  /**
   * This flag indicates whether the buyer is allowed to request/book transportation for the given auction.
   * This is mostly based on the region of the buyer and the auction and is calculated dynamically.
   */
  isTransportationAllowedForRegion: boolean;
  /** This flag indicates whether express pickup is allowed for this auction (by the seller). */
  isExpressPickupAvailable: boolean;
  /**
   * Number of days from when pickup will be possible.
   * "null" if not available.
   * @format double
   */
  pickupPossibleInDays: number;
  /**
   * Approximate distance to the vehicle from a buyer in km.
   * @format double
   */
  distanceToVehicleInKms: number;
  /** Identify whether the auction vehicle needs to be pickup in the compound */
  isCompoundPickup: boolean;
  /** Identify whether there's an increased time before the auction vehicle can be picked up */
  hasIncreasedPickupTime: boolean;
  /** Identify whether there is a next auction to go to on auction detail */
  hasNextAuction?: boolean;
  /** Identify whether there is a previous auction to go to on auction detail */
  hasPreviousAuction?: boolean;
  /** Indicates whether the buyer can use the Buy Now, Pay Later feature in this auction. */
  isBuyNowPayLaterAvailable: boolean;
  /**
   * Indicates the date which the standing fee will start to be charged.
   * @format date-time
   */
  standingFeeDueFrom?: string;
  /**
   * The maximum value that a bid can have before appearing a bid warning (this is calculated based on the auction min ask and MPR).
   * This is necessary to reduce the number of improperly placed bids.
   * @format double
   */
  bidMaxValueForWarning?: number;
  /**
   * The maximum value that a bid can have before being blocked from bidding (this is calculated based on the auction min ask and MPR).
   * This is necessary to reduce the number of improperly placed bids.
   * @format double
   */
  bidMaxValueForBlocking?: number;
  /**
   * Indicates the date which the auction payment is due.
   * @format date-time
   */
  paymentDueFrom?: string;
  /** This flag indicates whether the auction has a zero cost transport. */
  isZeroCostTransport?: boolean;
  /**
   * Number of times the auction has been parked by buyers.
   * @format double
   */
  timesParked?: number;
}

/** @deprecated */
export interface IBuyerAuctionDirectionalTravelResponse {
  /** Auctions, as they are seen by buyers. */
  auctionToShow: IBuyerAuctionView;
  nextAuctionUuid: string;
  previousAuctionUuid: string;
}

export interface IBuyerAuctionBiddingDataView {
  /** Stores which type of additional tax applies to the auction if any. */
  additionalTaxType: EAdditionalTaxType;
  /**
   * Stores the value of the additional tax that might apply to an auction.
   * @format double
   */
  additionalTaxValue: number;
  /**
   * Stores the export discount for taxes.
   * @format double
   */
  additionalTaxExportDiscount: number;
  uuid: string;
  state: EAuctionState;
  /** @format date-time */
  endingTime: string;
  /** @format double */
  remainingTimeInSeconds: number;
  /** @format double */
  remainingTimeForInstantPurchaseInSeconds: number;
  /** @format date-time */
  instantPurchasePossibleUntil: string;
  _fk_uuid_highestBiddingBuyerUser: string;
  /** @format double */
  currentHighestBidValue: number;
  /** @format double */
  currentHighestBidValueNet: number;
  /** @format double */
  minimumRequiredAsk: number;
  /** @format double */
  minimumRequiredAskNet: number;
  /** @format double */
  numBids: number;
  recommendationId?: string;
  /** Indicates whether the buyer is currently the highest bidder for the auction. */
  amIHighestBidder: boolean;
  /** This flag indicates whether the minimumRequiredAsk has already been reached. */
  isMinAskReached: boolean;
  /** @deprecated */
  isWatching: boolean;
  /**
   * This property contains the value of the current bidding agent, set by the buyer.
   * Optional, if it's not set it means that no bidding agent is set.
   * @format double
   */
  biddingAgentValue?: number;
  /** @format double */
  biddingAgentValueNet?: number;
  /**
   * A bidding agent could be set, but temporarily not active (for example, if the buyer is blocked of bidding).
   * This flag indicates whether the bidding agent is active or not.
   * Optional, if it's not set it means that no bidding agent is set.
   */
  biddingAgentActive?: boolean;
  /**
   * Indicates whether the given auction is a cross-border net sale transaction for the buyer looking at it.
   * This is the case if the vehicle is "regelbesteuert" and sold from a country that is different from the buyer's country.
   */
  isCrossBorderNetSale: boolean;
  /**
   * Purchase fee that is charged to the buyer on success of the auction.
   * This is the variable transaction fee, which is calculated based on bucket pricing.
   *
   * This value is augmented, calculated dynamically based on the highest bid value.
   *
   * Discounts are already applied
   * @format double
   */
  buyerPurchaseFee: number;
  /**
   * Purchase fee that is charged to the buyer on success of the auction.
   * This is the variable transaction fee, which is calculated based on bucket pricing.
   *
   * This value is augmented, calculated dynamically based on the highest bid value.
   *
   * Discounts are not applied yet
   * @format double
   */
  buyerPurchaseFeeBeforeDiscounts: number;
  /** A list with all the discounts that are currently applied to the auction. */
  appliedDiscounts?: IAuctionDiscount[];
  /**
   * Fee that should be charged to the buyer in case the auction is successful (fee will be added to purchase fee invoice).
   * This value is augmented, based on the fee that is configured on the seller account.
   * @format double
   */
  buyerSuccessFee: number;
  /**
   * Total fee or discount for cross-border sale transactions.
   * This is a calculated field based on the Export Discount and Export Handling Fee
   * @format double
   */
  buyerCrossBorderProcessingAmount: number;
}

/** Not really used at the moment. */
export enum EUserClientCategory {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
}

/** A bid that has been made by a bidder (BuyerUser) on an auction. */
export interface IAuctionBid {
  /**
   * @deprecated
   * @format double
   */
  id: number;
  uuid: string;
  /**
   * Indicating the time when the entity has been created, i.e. when the entity was
   * persisted to the database.
   * @format date-time
   */
  createdAt?: string;
  /**
   * Indicating the last time a value of the entity has been changed (updated).
   * @format date-time
   */
  updatedAt?: string;
  /**
   * Indicating the time when the entity has been (softly) deleted.
   * "null" if entry is not deleted.
   * @format date-time
   */
  deletedAt?: string;
  /**
   * The value of the bid that was given by the bidder/buyer.
   * @format double
   */
  value: number;
  /** @format double */
  valueNet: number;
  /**
   * Indicating whether the bid was given in the "hot bid phase", therefore
   * extending the runtime of the auction.
   */
  isHotBid: boolean;
  /** @deprecated */
  isAutoBid: boolean;
  /** Indicating whether the bid was placed from the list view. */
  isListViewBid: boolean;
  /** Indicating where the bid was made by the bidder/buyer (browser or mobile UI). */
  clientCategory: EUserClientCategory;
  /** The auction that this bid is for. */
  _fk_uuid_auction: string;
  /** The bidder which placed the bid. */
  _fk_uuid_biddingBuyer: string;
  /** If this bid was automatically triggered by a different buyers bid, the identity of the triggering buyer is stored here. */
  _fk_uuid_triggeringBuyer?: string;
  /**
   * Indicates if this bid was made on the reauction of this auction and
   * should be seen by the buyer of the reauction and not the seller of the original auction.
   */
  isReauctionBid: boolean;
}

/**
 * Inform that a new resource was created. Server will
 * add a Location header and set status to 201
 */
export interface ReturnNewResourceIAuctionBid {
  location: string;
  /** @format double */
  statusCode: number;
  /** the body to be sent */
  body: IAuctionBid;
}

/** How buyers see auction bids. */
export interface IBuyerAuctionBidView {
  /** Bidder name. */
  buyerName: string;
  /** Triggering bidder name if present. */
  triggeringBuyerName?: string;
  /**
   * Bid amount.
   * @format double
   */
  value: number;
  /** @format double */
  valueNet: number;
  /**
   * Timestamp when bid was created.
   * @format date-time
   */
  placedAt: string;
  /** The UUID of the auction to which this bid is related. */
  auctionUUID: string;
}

export interface IPageIInternalAuctionView {
  items: IInternalAuctionView[];
  /** @format double */
  total: number;
  /** @format double */
  page: number;
  /** @format double */
  pageSize?: number;
}

/** The localized text for the notification. */
export interface INotificationLocalizedText {
  en: string;
  de: string;
  [key: string]: any;
}

/** The notification dynamic data that will be available on the app. */
export interface IPushNotificationData {
  /** The primary role of the notification (see {@link GENERAL_USER_ROLES }). */
  role?: string;
  /** The notification type. */
  type: ENotificationType;
  [key: string]: any;
}

/** Interface for user notifications. */
export interface INotification {
  /** The localized text for the notification. */
  contents: INotificationLocalizedText;
  /** The localized text for the notification. */
  headings?: INotificationLocalizedText;
  /** The notification dynamic data that will be available on the app. */
  data: IPushNotificationData;
}

export interface IAuctionRecommendationEmailRequest {
  recommendedAuctionUuids: string[];
  customizedMessage?: string;
}

export interface IPageWithFilterIBuyerAuctionView {
  items: IBuyerAuctionView[];
  /** @format double */
  total: number;
  /** @format double */
  page: number;
  /** @format double */
  pageSize?: number;
  /** Filter object which can used when obtaining auctions in/from the COS backend. */
  filter: IAuctionFilter;
}

export interface IPageIBuyerAuctionView {
  items: IBuyerAuctionView[];
  /** @format double */
  total: number;
  /** @format double */
  page: number;
  /** @format double */
  pageSize?: number;
}

/** Generic type which will have all the keys from type T. */
export interface ICountPerKeyIVehicle {
  /** @format double */
  id?: number;
  uuid?: string;
  /** @format date-time */
  createdAt?: string;
  /** @format date-time */
  updatedAt?: string;
  /** @format date-time */
  deletedAt?: string;
  vin?: string;
  make?: string;
  makeInternalDatReference?: string;
  model?: string;
  modelInternalDatReference?: string;
  /** @format double */
  cleanMakeId?: number;
  cleanMake?: string;
  /** @format double */
  cleanModelId?: number;
  cleanModel?: string;
  /** @format double */
  cleanGroupId?: number;
  cleanGroup?: string;
  datBaseModelRaw?: string;
  ez?: string;
  /** @format double */
  mileageInKm?: number;
  isOdometerInMiles?: boolean;
  category?: EVehicleCategory;
  transmission?: ETransmissionType;
  upholstery?: EUpholsteryType;
  /** @format double */
  numSeats?: number;
  fuelType?: EFuelType;
  doors?: EVehicleDoors;
  /** @format double */
  engineSizeInCcm?: number;
  /** @format double */
  enginePowerInHp?: number;
  /** @format double */
  secondaryEnginePowerInHp?: number;
  /** @format double */
  combinedEnginePowerInHp?: number;
  /** @format double */
  totalRangeInKm?: number;
  /** @format double */
  batteryRangeInKm?: number;
  /** @format double */
  batteryCapacityInKwh?: number;
  urlToBatteryConditionCertificate?: string | null;
  /** @format double */
  quickChargeInHours?: number;
  /** @format double */
  normalChargeInHours?: number;
  chargerPlugType?: string;
  energyEfficiencyClass?: string;
  batteryConstructionType?: string;
  /** @format double */
  batteryVoltage?: number;
  isPluginSystem?: boolean;
  /** @format double */
  energyConsumption?: number;
  hasRentalBattery?: boolean;
  bodyColorCode?: EVehicleColor;
  /** @format double */
  dimensionWidthInCm?: number;
  /** @format double */
  dimensionHeightInCm?: number;
  /** @format double */
  dimensionLengthInCm?: number;
  /** @format double */
  unloadedWeightInKg?: number;
  lastHu?: string;
  huReportExists?: boolean;
  huReportAvailable?: EHuReportAvailable;
  urlsToHuReport?: string[];
  /** @format double */
  numPreOwners?: number;
  /** @format double */
  numKeys?: number;
  vatIdReportable?: boolean;
  fullServiceHistoryType?: EVehicleFullServiceHistoryType;
  serviceHistoryAvailability?: EVehicleServiceHistoryAvailability;
  urlsToServiceHistoryDocument?: string[];
  hasMaintenanceBook?: boolean;
  urlsToMaintenanceBook?: string[];
  isReimportedVehicle?: boolean;
  euroNorm?: EEuroNorm;
  hadAccident?: boolean;
  accidentDescription?: string;
  hasDamages?: boolean;
  damagesDescription?: string;
  damages?: IVehicleDamage[];
  additionalInfo?: string;
  readyToDrive?: EVehicleReadyToDriveStatus;
  readyToDriveDetails?: string;
  vehicleImages?: IVehicleImage[];
  urlToMotorSound?: string;
  urlToAttachment1?: string | null;
  urlToAttachment2?: string | null;
  urlToAttachment3?: string | null;
  urlToVehicleSummarySheet?: string;
  isRegistrationDocumentAvailable?: boolean;
  urlToRegistrationDocument?: string;
  urlsToRegistrationDocument?: string[];
  isRegistrationDocumentPart2Available?: boolean;
  urlsToRegistrationDocumentPart2?: string[];
  urlsToOtherDocuments?: string[];
  urlsToMotorRecordings?: string[];
  urlsByLanguageToExpose?: IVehicleExposeByLanguageMap;
  /** @format double */
  estimatedValue?: number;
  lastServiceInspectionDate?: string;
  /** @format double */
  lastServiceInspectionMileage?: number;
  isCocDocumentAvailable?: boolean;
  urlsToCocDocument?: string[];
  isDataExcerptAvailable?: boolean;
  urlsToDataExcerpt?: string[];
  countryOfLastRegistration?: ECountryCode;
  origin?: EVehicleCreationOrigin;
  dataSource?: EVehicleDataSource;
  paintState?: IVehiclePaintState[];
  tires?: IVehicleTireSet[];
  dataWarnings?: EVehicleDataWarning[];
  inspectionUuid?: string;
  technicalState?: IVehicleTechState[];
  fieldsConfirmationStatus?: IVehicleFieldConfirmationStatusMap;
  licensePlate?: string;
  fuelConsumption?: IFuelConsumption;
  co2Emission?: ICo2Emission;
  commercialUsage?: EVehicleCommercialUsage[];
  isVehicleClassN1?: boolean;
  isIntendedUse01?: boolean;
  hasEndorsements?: boolean;
  sourceLanguage?: ELanguageCode;
  isRollable?: boolean;
  ac?: EAirCondition;
  /** @format double */
  navigation?: number;
  headlights?: EHeadlights;
  coupling?: ECoupling;
  vehicleHeater?: EVehicleHeater;
  parkingAssistance?: EParkingAssistance;
  /** @format double */
  sunRoof?: number;
  /** @format double */
  sportPackage?: number;
  isToolkitIncluded?: boolean;
  lockingWheelNut?: ELockingWheelNut;
  hasAnimalSmell?: boolean;
  hasSmokeSmell?: boolean;
  equipmentData?: IVehicleEquipmentData[];
  equipmentHighlights?: IVehicleEquipmentHighlightData[];
  linesByLanguage?: IVehicleLineByLanguageMap;
  externalLineCode?: string;
  externalReports?: IVehicleExternalReport[];
  obd2Readings?: IVehicleObd2Reading[];
  obd2ReadingsFailure?: boolean;
  countOfDrivenAxles?: EVehicleCountOfDrivenAxles;
}

export type IResultMapICountPerKeyIVehicle = Record<string, ICountPerKeyIVehicle>;

export interface IPageIBuyerAuctionSavedSearch {
  items: IBuyerAuctionSavedSearch[];
  /** @format double */
  total: number;
  /** @format double */
  page: number;
  /** @format double */
  pageSize?: number;
}

/** The possible states that an auction room could be in during its lifecycle. */
export enum EAuctionRoomStatus {
  Value1 = -1,
  Value11 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
  Value5 = 5,
  Value6 = 6,
  Value7 = 7,
}

/** This is the subset of auction room data available to the buyer view. */
export interface IAuctionRoomBuyerView {
  uuid: string;
  /** Denotes the part of the auction room lifecycle that the auction room is currently in. */
  status: EAuctionRoomStatus;
  /** The title and primary user identifier of the auction room. */
  title: string;
  /**
   * An array of string messages used to add context to an auction room.
   * Tells the users who view the auction room a bit more about the auctions contained within or the auction room itself.
   */
  metadata?: string[];
  /**
   * The time at which the auctions within the auction room begin to expire.
   * @format date-time
   */
  startTime: string;
  /** The URL of a display image for the auction room. */
  imageUrl?: string;
  auctionUuids?: string[];
  /**
   * The number of auctions that are contained within the auction room.
   * @format double
   */
  totalAuctionCount: number;
  /**
   * The number of auctions that are currently active within the auction room.
   * @format double
   */
  activeAuctionCount: number;
  /** The id for the look and feel applied to the auction room. */
  lookAndFeelId?: EAuctionLookAndFeelId;
  /**
   * Indicates whether the rules document CTA should be displayed within an auction room.
   * Defaults to `false` if not specified.
   */
  showRulesDocument?: boolean;
}

export interface IPageIAuctionRoomBuyerView {
  items: IAuctionRoomBuyerView[];
  /** @format double */
  total: number;
  /** @format double */
  page: number;
  /** @format double */
  pageSize?: number;
}

export interface IEquipmentDataTranslation {
  language: ELanguageCode;
  equipment: IVehicleEquipmentData[];
}

/** From T, pick a set of properties whose keys are in the union K */
export interface PickIVehicleTechStatePartOrAnomalies {
  part: EVehiclePart;
  anomalies?: IVehiclePartAnomalies;
}

export type IVehicleTechStateI18N = PickIVehicleTechStatePartOrAnomalies;

/** From T, pick a set of properties whose keys are in the union K */
export interface PickIVehicleDamageUuidOrDescription {
  uuid: string;
  description: string;
}

export type IVehicleDamageI18N = PickIVehicleDamageUuidOrDescription;

export interface IVehicleI18N {
  uuid: string;
  /** @format date-time */
  createdAt?: string;
  /** @format date-time */
  updatedAt?: string;
  language: ELanguageCode;
  technicalState: IVehicleTechStateI18N[];
  damages: IVehicleDamageI18N[];
  tires: IVehicleTireSet[];
  _fk_uuid_vehicle: string;
  isOriginalTranslation: boolean;
  accidentDescription: string;
  damagesDescription: string;
  additionalInfo: string;
  readyToDriveDetails: string;
}

export interface IAuctionTranslationResponse {
  associatedVehicle: IVehicleI18N;
}

/** Make all properties in T optional */
export interface PartialIAuction {
  /** The label of the auction, consisting of the vehicle make, model and date of first registration (EZ). */
  label?: string;
  /**
   * The state of the auction, indicating whether an auction is not yet published (DRAFTED), running (ACTIVE),
   * closed successfully (CLOSED_WAITING_FOR_PAYMENT, CLOSED_WAITING_FOR_PICKUP, FINISHED), failed (CLOSED_NO_BIDS,
   * CLOSED_UNDER_MINIMUM_ASK) or disabled (DISABLED).
   *
   * Depending on the state, different timestamps are available per auction.
   */
  state?: EAuctionState;
  /**
   * The exact date+time when the auction is expiring/ending.
   * @format date-time
   */
  endingTime?: string;
  /**
   * The remaining number of seconds that this auction will be in the state ACTIVE (running).
   *
   * This is a dynamic, non-persisted field, calculated on every retrieval of the auction data.
   * @format double
   */
  remainingTimeInSeconds?: number;
  /**
   * The remaining number of seconds that this auction is available for instant purchase
   * (usually the first 24 hours after the auction is started).
   *
   * This is a dynamic, non-persisted field, calculated on every retrieval of the auction data.
   * @format double
   */
  remainingTimeForInstantPurchaseInSeconds?: number;
  /**
   * Date+time when the auction is started.
   * When an auction is started right away (without drafting), the "createdAt" and "startedAt" should have
   * (roughly) the same values.
   * @format date-time
   */
  startedAt?: string;
  /**
   * Date+time when the auction is "confirmed to be sold" by the seller (or by an admin).
   * If an auction is ending equal or above the minimum required ask, the "purchaseConfirmedAt"
   * will automatically be set and will be equal to the "endingTime".
   *
   * If an auction is ending below the minimum ask (CLOSED_UNDER_MINIMUM_ASK), the "purchaseConfirmedAt" timestamp
   * is only set if the auction is then manually "confirmed" by the seller or an admin.
   *
   * If an auction is never "successful", the "purchaseConfirmedAt" will remain "null".
   *
   * This value was introduced in the course of 2019. For all previous auctions, the endingTime was set
   * as the "purchaseConfirmedAt" retrospectively.
   * @format date-time
   */
  purchaseConfirmedAt?: string;
  /**
   * Date-time when the auction has definitely been rejected by a seller or an admin, after the auction
   * ended below minimum required ask.
   *
   * This value is only available since 2019/07/29. All auctions rejected before that date have the value "null".
   * @format date-time
   */
  purchaseRejectedAt?: string;
  /** Indicates the reason that an auction failed the renegotiation process. */
  rejectionReason?: EAuctionRejectionReason;
  /**
   * Elaborates on the rejection reason to provide further context to why an
   * auction was rejected during the renegotiation process.
   */
  rejectionReasonNote?: string;
  /**
   * The name of the competitor that the auction was sold to when the reject chosen reason was
   * VEHICLE_SOLD_ON_DIFFERENT_PLATFORM
   */
  rejectionReasonCompetitorName?: string;
  /** In case if there was a direct upload requested but didn't succeed the reason should be stored. */
  directUploadRejectionReasons?: EAuctionDirectUploadRejectionReason[];
  /**
   * Value that is indicating initial bid value auction should be started with.
   * @format double
   */
  startingBidValue?: number;
  /** @format double */
  startingBidValueNet?: number;
  /**
   * Value that is indicating the current highest bid value on the auction.
   * This is of course a dynamic field, that will be incremented on every new bid placed on the auction.
   * @format double
   */
  currentHighestBidValue?: number;
  /** @format double */
  currentHighestBidValueNet?: number;
  /**
   * Value that can be manually set in case edge-cases apply and the payout amount is disconnected from the auction current highest bid.
   * This value can only be adjusted by internal users.
   * @format double
   */
  overridePayoutValue?: number;
  /**
   * The minimal price the seller user wants to reach for this specific auction.
   * If an auction ends above that price, the auction is successful right away
   * (switching to state CLOSED_WAITING_FOR_PAYMENT).
   *
   * If the auction ends below the minimum required ask, the auction will switch to state CLOSED_UNDER_MINIMUM_ASK,
   * which then requires the seller's explicit "confirmation" or "rejection" of the highest bid value.
   * @format double
   */
  minimumRequiredAsk?: number;
  /** @format double */
  minimumRequiredAskNet?: number;
  /**
   * If an auction is ending below the minimum required ask, the "originalMinimumRequiredAsk" will be set to the
   * "minimumRequiredAsk" value before it is set to "currentHighestBidValue".
   * @format double
   */
  originalMinimumRequiredAsk?: number;
  /** @format double */
  originalMinimumRequiredAskNet?: number;
  /**
   * Number of bids placed on an auction.
   * @format double
   */
  numBids?: number;
  /**
   * The purchase price for the vehicle that is auctioned.
   * This is set manually by the seller, and is mostly used to analyze the "real" margin that a seller
   * makes with an auction.
   * @format double
   */
  purchasePrice?: number;
  /** @format double */
  purchasePriceNet?: number;
  /**
   * The full object of the vehicle that is on auction.
   * This is augmented on every auction retrieval, based on _fk_uuid_vehicle.
   */
  associatedVehicle?: IVehicle;
  /** Indicates whether the auction process is rated by the SellerUser. */
  isRatedByDealership?: boolean;
  /** Indicates whether the auction process is rated by the BuyerUser. */
  isRatedByBuyer?: boolean;
  /**
   * Indicates whether the BuyerUser has marked this auction as paid (payment of the auction volume).
   * This is done manually by the BuyerUser, so this value is not indicating that the actual payment has really
   * reached the seller.
   */
  isPaidByBuyer?: boolean;
  /**
   * Timestamp when the BuyerUser has marked this auction as "paid" (i.e. set the "isPaidByBuyer").
   * @format date-time
   */
  outgoingPaymentConfirmedAt?: string;
  /**
   * Timestamp when the SellerUser has confirmed that the payment has reached the seller bank account.
   * @format date-time
   */
  incomingPaymentConfirmedAt?: string;
  /**
   * Timestamp when the auction state has changed from "CLOSED_WAITING_FOR_PAYMENT" to "CLOSED_WAITING_FOR_PICKUP".
   * @format date-time
   */
  pickupConfirmedAt?: string;
  /** Physical address of where the vehicle on auction is located (a.k.a. pickup address). */
  locationCountryCode?: ECountryCode;
  locationAddress?: string;
  locationCity?: string;
  locationZip?: string;
  /** Physical address of where the vehicle on auction was inspected (a.k.a. inspection address). */
  inspectionLocationCountryCode?: ECountryCode;
  inspectionLocationAddress?: string;
  inspectionLocationCity?: string;
  inspectionLocationZip?: string;
  /**
   * Indicating to which bank account the BuyerUser has to transfer the auction volume to.
   * @deprecated
   */
  sellerIban?: string;
  /**
   * URL to the seller invoice for the given auction, manually uploaded by the SellerUser, after
   * the auction has ended successfully.
   */
  urlToInvoice?: string;
  /** URL for the cancelled invoice in case it was cancelled. */
  urlToCorrectionInvoice?: string;
  /**
   * Flag indicating whether an auction has an "hot bid" phase in the last 20 seconds before expiration.
   * @deprecated
   */
  hotBid?: boolean;
  /**
   * Seller's instant purchase price for the vehicle.
   * For the first 24 hours after the auction is started, the vehicle will only be available in "instant purchase"
   * mode (bidding is not allowed in that time, only purchasing right away).
   *
   * After 24 hours, if nobody purchased the vehicle, it is going on auction, just like all other auctions.
   * @format double
   */
  instantPurchasePrice?: number;
  /** @format double */
  instantPurchasePriceNet?: number;
  /**
   * Flag indicating that instant purchase is enabled for that auction.
   * (Implies that "instantPurchasePrice" is set)
   */
  allowInstantPurchase?: boolean;
  /**
   * Flag indicating that an auction "ended" with an instant purchase, resp.
   * did never go on auction.
   */
  didEndWithInstantPurchase?: boolean;
  /**
   * Date+time indicating until when an auction is available for instant purchasing.
   * By default, this is 24 hours after the start of an auction.
   * @format date-time
   */
  instantPurchasePossibleUntil?: string;
  /**
   * Internal counter, incrementing on every "restart" of an auction.
   * Starts with 0.
   *
   * e.g. count of 2 means that the auction has been restarted 2 times, leading to 3 auction objects in the
   * database.
   * @format double
   */
  auctioningIterations?: number;
  /**
   * Indicating the strategic priority of an auction for CarOnSale.de
   * The higher the number, the more "relevant" the auction is.
   * @format double
   */
  priority?: number;
  /** @deprecated */
  advertisementHtmlContent?: string;
  /** @deprecated */
  buyerComplaint?: EAuctionBuyerComplaint;
  /**
   * Custom field that is not used internally.
   * Allows sellers to store e.g. a reference ID to their own vehicle management system.
   */
  internalReferenceText?: string;
  _fk_uuid_vehicle?: string;
  _fk_uuid_sellerUser?: string;
  _fk_uuid_highestBiddingBuyerUser?: string;
  /** This is a URL that redirects to an external invoice payment site (such as provided by Stripe). */
  urlToPaymentSite?: string;
  /**
   * A flag indicating if an auction needs to be reviewed by an internal review agent (for quality assurance)
   * If true, an auction is currently under review by CarOnSale, and cannot be edited by the owning seller user.
   */
  needsReview?: boolean;
  /** The reasons why an auction was rejected during the review. */
  reviewReasons?: EAuctionReviewReason[];
  /**
   * Reasons why an auction is rejected during the review process ("Parkhaus").
   *
   * This reason is automatically set to UNKNOWN for all auctions and is then manually adjusted by
   * an internal review agent (in case the auction gets rejected) before it is sent back to the responsible
   * SellerUser.
   * @deprecated
   */
  reviewReason?: EAuctionReviewReason;
  /** Specifies reason when "reviewReasons" contains EAuctionReviewReason.OTHER. */
  reviewReasonDetails?: string;
  /** The comment given by an internal review agent on rejecting an auction under review. */
  reviewComment?: string;
  /** Flag indicating if the auction can be published without review. */
  canSkipReview?: boolean;
  /**
   * Random PIN code that is generated when an auction is ready to be picked up or in advance before COSCheck inspection.
   * This PIN code is printed out on the hangar in the vehicle or handed to the seller of the auction, which can then forward it to
   * the buyer to confirm the pickup.
   */
  pickupPinCode?: string;
  /**
   * Random UUID that is used to confirm vehicle pickup via scanning QR code.
   * It will be printed on the hangar or in the pickup document available for sellers.
   */
  pickupPinUuid?: string;
  /**
   * Timestamp when PIN code was entered by the buyer.
   * Allows to determine how many auctions were self-picked up.
   * @format date-time
   */
  pickupPinCodeEnteredAt?: string;
  /**
   * Timestamp when PIN UUID was entered by the buyer via scanning QR code.
   * Allows to determine how many auctions were self-picked up.
   * @format date-time
   */
  pickupPinUuidEnteredAt?: string;
  /** Flag is indicating if pickup was confirmed with PIN using new process (via pin.caronsale.de) */
  pickupConfirmedWithNewPin?: boolean;
  /** URL to the pickup information document for the BuyerUser. */
  urlToPickupBuyerDocument?: string;
  /** URL to the pickup information document for the SellerUser (containing the "pickupPinCode"). */
  urlToPickupSellerDocument?: string;
  /** URL to the success report document for the SellerUser. */
  urlToSellerSuccessReport?: string;
  /** The payment process that is used to pay money for the purchase volume (auction volume). */
  paymentProcess?: EAuctionPaymentProcess;
  /**
   * Referencing the bank account to which the payment for the purchase volume should be paid out.
   * This is null, if the paymentProcess === "CLASSIC".
   *
   * It is only used, when external payment (COS Payment) is enabled.
   */
  _fk_uuid_associatedBankAccount?: string;
  /**
   * Indicates how often an auction has been reviewed.
   * This is incremented every time an auction is rejected after a review.
   * @format double
   */
  draftReviewIterations?: number;
  /**
   * Timestamp when an admin resets a time window for a possibility to complain
   * @format date-time
   */
  complaintTimeWindowExtendedAt?: string;
  /**
   * Flag indicates if this auction is suspected to have been paid in cash (only for auctions that use the external
   * invoice process), this means that no corresponding transfer exists on Stripe and the buyer confirmed the pickup
   * through the seller pin code.
   */
  isSuspectedCashPayment?: boolean;
  /**
   * Timestamp when the auction was sent to review.
   * @format date-time
   */
  reviewStartedAt?: string;
  /**
   * Timestamp when the auction QA process actually started (i.e. when Parkhaus started reviewing the auction).
   * @format date-time
   */
  qaStartedAt?: string;
  /**
   * Timestamp when the auction was ready to be reinserted (a.k.a. restarted).
   * @format date-time
   */
  readyToBeReinsertedSince?: string;
  /** The reference to the original auction from which current one was cloned. */
  _fk_uuid_clonedFrom?: string;
  /** Auction type. */
  type?: EAuctionType;
  /** Indicates whether the rejection wait period for this auction is over and thus can be rejected by the seller. */
  isRejectionWaitPeriodOver?: boolean;
  /** Reference to invoice object for the external Billomat invoicing provider. */
  thirdPartyVehiclePurchaseInvoiceReference?: string;
  /**
   * URL to the seller made invoice invoicing CoS for the given auction, manually uploaded to the crm by an internal user, after
   * the auction has ended successfully.
   */
  sellerToCosInvoiceUrl?: string;
  /**
   * Timestamp for when the sellerToCosInvoiceUrl was last updated.
   * @format date-time
   */
  sellerToCosInvoiceUrlLastUpdatedAt?: string;
  /**
   * Timestamp for when the sellerToCosInvoiceUrl is first uploaded.
   * @format date-time
   */
  sellerToCosInvoiceUrlFirstUploadedAt?: string;
  /** Flag indicating wherever the uploaded seller to cos invoice was verified by an internal user. */
  sellerToCosInvoiceIsValid?: boolean;
  /** The comment given by an internal user on the provided seller to cos invoice. */
  sellerToCosInvoiceComment?: string;
  /** Shows if auction is marked as 'live', so, it will be slotted during starting and expire in less than 24 hours. */
  isLive?: boolean;
  /** Shows if auction was already slotted via the slotting algorithm. */
  isSlotted?: boolean;
  /**
   * The exact date+time when the auction was slotted to end.
   * @format date-time
   */
  slottedEndingTime?: string;
  /** Indicating if transportation should be disabled manually. */
  isTransportationDisabledManually?: boolean;
  /** Optional pickup instructions that will be communicated to the buyer. */
  pickupInstructions?: string;
  /** Indicating wherever the seller should be charged a success/listing fee. */
  preventSellerFactoring?: boolean;
  /** Indicating if buyer success fee should be applied or not. */
  applyBuyerSuccessFee?: boolean;
  /** Info from document shipping service about the order, like status and links to the documents. */
  documentShippingOrder?: IAuctionDocumentShippingOrder;
  /** Info about un-/confirmed auction data points. */
  fieldsConfirmationStatus?: IAuctionFieldConfirmationStatusMap;
  /**
   * Whether the "Value Added Tax is reportable", a special tax condition in the EU.
   * If this is true, it means that the vehicle is "Regelbesteuert", meaning that the invoice for the vehicle
   * (either invoiced directly from seller to buyer - or from COS Payment to buyer) needs to include
   * the VAT (in Germany e.g. 19%) in the invoice.
   *
   * If this is false, the invoice does not need to have explicit VAT.
   */
  isVATReportable?: boolean;
  /** Indicates how the auction was uploaded to the marketplace. */
  uploadMethod?: EAuctionUploadMethod;
  /** Prebooked services available for the auction, like transport, warranty. */
  prebookedServices?: IPrebookedService[];
  /** Indicates if cosCheckPlus guarantee can be booked for this auction. */
  isCosCheckPlusGuaranteeEnabled?: boolean;
  /** Indicates if extended guarantee can be booked for this auction. */
  isExtendedGuaranteeEnabled?: boolean;
  /** URL to the auction vehicle cover letter for sellers */
  urlToCoverLetter?: string;
  /**
   * Timestamp when the automatic pickup approval was requested for auction
   * @format date-time
   */
  autoPickupOnPaymentRequestedAt?: string;
  /**
   * This means that the auctions is using complimentary vehicle feature.
   *
   * Note: This is marked as true when the seller uses one of his complimentary vehicles to pay for the fees.
   */
  isSellerFeeComplimentary?: boolean;
  /** Flag when standing fee is enabled to the auction and we'll need to extra charge a buyer as a penalty. */
  isStandingFeeEnabled?: boolean;
  _fk_uuid_auctionRoom?: string;
  /**
   * An auction can have a list of fulfillment criteria that impact how the
   * auction will be fulfilled depending on the buyer that wins it.
   */
  fulfillmentCriteria?: IAuctionFulfillmentCriteria[];
  /**
   * The auction marked with this flag won't start automatically after review.
   *
   * Instead, it will transition to state: READY_TO_BE_PUBLISHED.
   *
   * Default: false
   */
  shouldWaitToBePublishedAfterReview?: boolean;
  /** A flag that marks if an auction is a selective ware or not. */
  isSelectiveWare?: boolean;
  /** The id for the look and feel applied to the auction. */
  lookAndFeelId?: EAuctionLookAndFeelId;
  /** A flag that marks if an auction will use the pickup qr code. */
  isQRCodeAvailable?: boolean;
  /**
   * Indicates whether the highest bid should be transferred if this auction gets cloned.
   * Defaults to `false` if not specified.
   */
  shouldTransferHighestBid?: boolean;
  /**
   * @deprecated
   * @format double
   */
  id?: number;
  /**
   * Indicating the time when the entity has been created, i.e. when the entity was
   * persisted to the database.
   * @format date-time
   */
  createdAt?: string;
  /**
   * Indicating the last time a value of the entity has been changed (updated).
   * @format date-time
   */
  updatedAt?: string;
  /**
   * Indicating the time when the entity has been (softly) deleted.
   * "null" if entry is not deleted.
   * @format date-time
   */
  deletedAt?: string;
  uuid?: string;
  /** Stores which type of additional tax applies to the auction if any. */
  additionalTaxType?: EAdditionalTaxType;
  /**
   * Stores the value of the additional tax that might apply to an auction.
   * @format double
   */
  additionalTaxValue?: number;
  /**
   * Stores the export discount for taxes.
   * @format double
   */
  additionalTaxExportDiscount?: number;
  /** @format double */
  buyerRecommendationScore?: number;
  /** Was this auction created with the help of an inspection done or commissioned by CarOnSale? */
  inspectionWasCommissionedByCos?: boolean;
  /** By which type of user was this auction created. */
  creatorType?: EAuctionCreatorType;
  /** UUID of the creating internal user if there is one. */
  _fk_uuid_creatingInternalUser?: string;
  /** UUID of the creating seller user if there is one. */
  _fk_uuid_creatingSellerUser?: string;
  /**
   * Indicates if the auction was created for testing purposes and
   * should be handled differently: it should not be ended automatically,
   * so the seller should decide would he like to sell it or not.
   */
  isTest?: boolean;
  /** Indicates if minimum required ask should be displayed for buyers. */
  displayMinAsk?: boolean;
  /**
   * GPS coordinates of the auction (can be used for e.g. calculating distances between auctions and buyers).
   * @format double
   */
  locationGeoLat?: number;
  /** @format double */
  locationGeoLon?: number;
  /**
   * A value denoting the previous renegotiation offer given by a buyer user.
   * @format double
   */
  previousCounterOfferByBuyer?: number;
  /** @format double */
  previousCounterOfferByBuyerNet?: number;
  /**
   * A value denoting the previous renegotiation offer given by a seller user.
   * This is required in order to properly display the right information during the renegotiation process.
   * @format double
   */
  previousLastOfferBySeller?: number;
  /** @format double */
  previousLastOfferBySellerNet?: number;
  /** A flag for if the seller user connected to the auction has decided not to participate in the renegotiation any further. */
  renegotiationStoppedBySeller?: boolean;
  /** A flag for if the highest bidding buyer user connected to the auction has decided not to participate in the renegotiation any further. */
  renegotiationStoppedByBuyer?: boolean;
  /** A fag indicating if the buyer's withdrawal from the renegotiation process was done automatically or not. */
  wasRenegotiationStoppageByBuyerAutomatic?: boolean;
  /** A fag indicating if the seller's withdrawal from the renegotiation process was done automatically or not. */
  wasRenegotiationStoppageBySellerAutomatic?: boolean;
  /**
   * The total number of offer rounds a buyer has participated in for an auction during the renegotiation phase.
   * @format double
   */
  numBuyerRenegotiationOfferRounds?: number;
  /**
   * The total number of offer rounds a seller has participated in for an auction during the renegotiation phase.
   * @format double
   */
  numSellerRenegotiationOfferRounds?: number;
  /**
   * The calculated renegotiation midpoint value of the auction.
   * This is used once the renegotiation offers get too close for the offer process to continue.
   * This value will be undefined unless the auction is ready to be purchased for such a value.
   * @format double
   */
  renegotiationMidpointValue?: number;
  /** @format double */
  renegotiationMidpointValueNet?: number;
  /**
   * Indicates that the highest bidding buyer user for an auction in renegotiation is willing to accept the midpoint of their offer and
   * the offer of the seller for the same auction. This is used when the renegotiation offers get very close during the renegotiation process.
   */
  buyerAcceptedRenegotiationMidpoint?: boolean;
  /**
   * Indicates that the seller of an auction is willing to accept the midpoint of their offer and the offer of the highest bidding
   * buyer user for the same auction. This is used when the renegotiation offers get very close during the renegotiation process.
   */
  sellerAcceptedRenegotiationMidpoint?: boolean;
  /**
   * This is the datetime object denoting when the most recent renegotiation round concluded at.
   * @format date-time
   */
  lastRenegotiationRoundEndedAt?: string;
  /**
   * The number of seconds until the current renegotiation round times out.
   * @format double
   */
  numSecondsUntilRenegotiationTimeout?: number;
  /**
   * The UUID of the external case tracking the renegotiation of the auction.
   * We track this to enable case updates as the auction moves through renegotiation.
   */
  externalRenegotiationTicketUuid?: string;
  /**
   * The calculated renegotiation fair value of the auction.
   * This is used once the renegotiation starts based on the auction GPR or MPR so we know
   * the fair value that both seller and buyer should agree upon.
   * @format double
   */
  renegotiationFairValue?: number;
  /**
   * Value that is indicating the highest bid value on the auction when it ended.
   * This value should not change for a given auction since it supposed to be a historical record.
   * @format double
   */
  highestBidValueAtEndingTime?: number;
  /** @format double */
  highestBidValueAtEndingTimeNet?: number;
  /** Field data related to the renegotiation round process. */
  renegotiationRoundData?: IRenegotiationRoundData;
  /**
   * Indicates when manual renegotiation was finished via Salesforce.
   * @format date-time
   */
  finishedManuallyAt?: string;
  /** @format double */
  counterOfferByBuyer?: number;
  /** @format double */
  counterOfferByBuyerNet?: number;
  /** @format double */
  lastOfferBySeller?: number;
  /** @format double */
  lastOfferBySellerNet?: number;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing service (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the transfer of funds
   * for the vat deposit from COS to the deposit account (only relevant for cross border transactions).
   * This deposit is refunded by COS once the buyer provides the necessary documentation for a cross border transaction.
   */
  thirdPartyVATDepositTransferReference?: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing service (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the transfer of funds
   * for the additional tax deposit from COS to the deposit account (only relevant for cross border transactions).
   * This deposit is refunded by COS once the buyer provides the necessary documentation for a cross border transaction.
   */
  thirdPartyAdditionalTaxDepositTransferReference?: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing system (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the transfer of funds
   * for the auction additional taxes (e.g country specific luxury taxes): from COS to the seller.
   */
  thirdPartyAdditionalTaxTransferReference?: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing service (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the transfer of funds
   * for the VAT: from COS to the seller (only relevant for cross border transactions for now).
   * COS is advancing the vat amount to the seller.
   */
  thirdPartyVatTransferReference?: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing service (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the charge of funds
   * for the auction volume from the buyer to COS.
   */
  thirdPartyVolumeChargeReference?: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing service (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the charge of funds
   * for the auction deposit from the buyer to COS (only relevant for cross border transactions for now).
   * This deposit is refunded by COS once the buyer provides the necessary documentation for a cross border transaction.
   */
  thirdPartyVATDepositChargeReference?: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing service (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the charge of funds
   * for the auction additional tax (e.g country specific luxury tax) from the buyer to COS.
   */
  thirdPartyAdditionalTaxChargeReference?: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing system (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the refund of an
   * additional tax deposit (only used for cross-border transactions currently).
   */
  thirdPartyAdditionalTaxRefundReference?: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing system (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the payout of funds.
   */
  thirdPartyPayoutReference?: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing system (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the refund of a
   * deposit (only used for cross-border transactions currently).
   */
  thirdPartyVATDepositRefundReference?: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing system (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the refund of a
   * transfer to the deposit account owned by cos (only used for cross-border transactions currently).
   */
  thirdPartyVATDepositReversalReference?: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing system (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the refund of the
   * additional tax transfer to the deposit account owned by cos (only used for cross-border transactions).
   */
  thirdPartyAdditionalTaxDepositReversalReference?: string;
  /**
   * Reference to the invoice for the success fee issued to the seller for a sold vehicle (see ESellerFeeInvoicingMode.INVOICE_ON_SUCCESS).
   * The reference identifies the invoice issued to the seller in an external invoicing system (e.g. Stripe).
   *
   * This fee is not always charged, therefore this reference is optional (can be null).
   */
  sellerSuccessFeeInvoiceReference?: string;
  /**
   * Reference to the invoice for the listing fee issued to the seller for a listed vehicle (see ESellerFeeInvoicingMode.INVOICE_ON_LISTING).
   * The reference identifies the invoice issued to the seller in an external invoicing system (e.g. Stripe).
   *
   * This fee is not always charged, therefore this reference is optional (can be null).
   */
  sellerListingFeeInvoiceReference?: string;
  /**
   * Reference to the invoice for the standing fee issued to the buyer for a purchased vehicle picked up too late.
   *
   * This fee is not always charged, therefore this reference is optional (can be null).
   */
  buyerStandingFeeInvoiceReference?: string;
  /**
   * Reference to a resource in an external invoicing system (such as Stripe).
   * References our own transaction fee invoice.
   */
  invoiceReference?: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing system (such as Stripe).
   * This is only non-null, if external payment is allowed for this auction.
   */
  thirdPartyInvoiceReference?: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing system.
   * This is only non-null, if external payment is allowed for this auction and it had to be cancelled.
   */
  thirdPartyCorrectionInvoiceReference?: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing system (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the transfer of funds
   * for the auction volume: from COS to the seller.
   */
  thirdPartyTransferReference?: string;
  /**
   * Reference of an invoice on an external invoicing system.
   * This is invoice is used to charge booked services (like guarantee) after the auction expires.
   */
  bookedServicesInvoiceReference?: string;
  /**
   * Date-time when the auction was reauctioned and is now waiting for buyer payment until the new auction gets paid.
   * @format date-time
   */
  reauctionedWaitingForBuyerPaymentAt?: string;
  /**
   * Date-time when the first reauction of this auction has expired.
   * We need to keep track of this so we can block the original buyer of seeing the original auction.
   * @format date-time
   */
  firstReauctionExpiredAt?: string;
  /** Flag that indicates if this auction is a clonned reauction. */
  isReauction?: boolean;
  /**
   * The highest bid value that we show to the seller when we merge the reauction with the original auction.
   * This is done so the seller see the amount that cos pay them and the buyer sees the amount that they paid cos.
   * @format double
   */
  sellerViewHighestBidValue?: number;
  /**
   * The purchase confirmed at that we show to the seller when we merge the reauction with the original auction.
   * This is done so the seller see the date when the original auction was confirmed and the buyer sees the date that the reauction was confirmed.
   * @format date-time
   */
  sellerViewPurchaseConfirmedAt?: string;
  /**
   * Date-time when the reauction was merged back at the original auction.
   * @format date-time
   */
  reauctionedMergedAt?: string;
  /** Indicates wherever an auction was purchase using the Buy Now Pay Later feature. */
  wasPurchasedUsingBuyNowPayLater?: boolean;
  /**
   * The timestamp at which the buyer has confirmed his choice to use Buy Now Pay Later for the auction.
   * @format date-time
   */
  buyNowPayLaterConfirmedAt?: string;
  /** The IP address which confirmed the Buy Now Pay Later choice for the auction. */
  buyNowPayLaterConfirmationIPAddress?: string;
  /** Reference to the Buy Now Pay Later Order on the PNI Service. */
  buyNowPayLaterOrderReference?: string;
  /** Reference of the invoice for buy now pay later provider fee on an external invoicing system. */
  buyNowPayLaterInvoiceReference?: string;
  /** The payment link reference */
  paymentLinkReference?: string;
  /** Reference to the payment order on the P&I service */
  paymentOrderReference?: string;
  isInternalFulfillment?: boolean;
  belowMinAskBehaviour?: EAuctionBelowMinAskBehaviour;
}

export enum EBuyerFeeDiscountReason {
  RENEGOTIATION = 'RENEGOTIATION',
  DIRECT_SALE = 'DIRECT_SALE',
  MANUAL_RENEGOTIATION = 'MANUAL_RENEGOTIATION',
}

/** The frequent buyer program discount to be applied */
export interface IFrequentBuyerProgramDiscount {
  /** @format double */
  amount: number;
  tier: string;
}

/** Construct a type with a set of properties K of type T */
export type RecordStringStringOrNumberOrNull = object;

export interface ICreateInvoiceDiscountPayload {
  /**
   * Value to be applied as the discount
   * @format double
   */
  amount: number;
  /** Description of the discount */
  description: string;
  /** Reason for why discount is being applied */
  reason: EInvoiceCreditNoteReason;
  /** Free key/value pairs of information to be stored in the invoice discount, useful for the client only */
  metadata?: RecordStringStringOrNumberOrNull;
}

/** Extended invoice creation params for purchase invoices. */
export interface IPurchaseInvoiceCreationParams {
  /** When regenerating an invoice, this is the original invoice that is being regenerated. */
  originalInvoice?: Invoice;
  /** Additional info text that shows up above the invoice items on the invoice PDF. */
  additionalInfoText?: string;
  /** Additional text that shows up below the invoice items at the bottom of the invoice PDF. */
  footerText?: string;
  /**
   * Disables sending e-mail automatically after the invoice is created. Used for invoice re-generation when the
   * previous invoice is already paid.
   */
  preventSendingEmail?: boolean;
  /**
   * Disables paying the invoice automatically with direct debit. Used for invoice re-generation when the
   * previous invoice is already paid.
   */
  preventDirectDebitPayment?: boolean;
  /**
   * The discount given to a buyer by an internal user when an auction is being sold directly.
   * (Usually after renegotiation because some deal was sturck with the buyer so the auction could be sold).
   * If defined and >0, the amount is applied as a credit note to the fee invoice.
   * @format double
   */
  applyBuyerFeeDiscount?: number;
  /** The reason for the buyer fee discount, if a discount is present */
  buyerFeeDiscountReason?: EBuyerFeeDiscountReason;
  /**
   * The discount given to a buyer by being a regular buyer of the seller of the purchasing auction.
   * If defined and >0, the amount is applied as a credit note to the fee invoice.
   * @format double
   */
  applyRegularBuyerDiscount?: number;
  /**
   * The rate of a discount given to a buyer by being a regular buyer of the seller of the purchasing auction.
   * If not defined, the users have no regular relation.
   * @format double
   */
  applyRegularBuyerRate?: number;
  /**
   * The discount given to a buyer by purchasing an auction of a private seller.
   * If defined and >0, the amount is applied directly on the invoice when it's created.
   * @deprecated
   * @format double
   */
  applyPrivateSellerDiscount?: number;
  /**
   * The discount given to a buyer by purchasing an auction of a strategic seller.
   * If defined and >0, the amount is applied directly on the invoice when it's created.
   * @deprecated
   * @format double
   */
  applyStrategicSellerDiscount?: number;
  /** The discount given to a buyer that is part of the frequent buyer program */
  applyFrequentBuyerProgramDiscount?: IFrequentBuyerProgramDiscount;
  /** The list of applied discounts that will be added to the invoice. */
  appliedDiscounts?: ICreateInvoiceDiscountPayload[];
  /**
   * If this is not null and >0, the amount is applied as manual renegotiation fee to the purchase invoice.
   * @format double
   */
  applyManualRenegotiationFee?: number;
  /**
   * If this is not null and >0, the amount is applied as a successful transaction fee to the purchase invoice.
   * @format double
   */
  applySuccessfulTransactionFee?: number;
  /**
   * If this is not null and >0, the amount is applied as a compound fee to the purchase invoice.
   * @format double
   */
  applyVehicleCompoundFee?: number;
  /**
   * If this is not null and >0, the amount is applied as an export fee to the purchase invoice.
   * @format double
   */
  applyExportDiscountFee?: number;
  /**
   * If defined, the amount is applied as COSCheck Plus Guarantee fee to the purchase invoice.
   * @format double
   */
  applyCheckoutCosCheckPlusGuaranteeFee?: number;
  /**
   * If defined, the amount is applied as extended Guarantee fee to the purchase invoice.
   * @format double
   */
  applyCheckoutExtendedGuaranteeFee?: number;
  /**
   * If defined and >0, the amount is applied as a fee to the fee invoice.
   * @format double
   */
  applyExportHandlingFee?: number;
  /**
   * Purchase fee that is charged to the buyer on success of the auction.
   * @format double
   */
  applyPurchaseFee?: number;
}

/** Params for processing auction success. */
export interface IAuctionSuccessProcessParams {
  /** The reason for the buyer discount. */
  discountReason?: EAuctionDiscountReason;
  /** A comment of the reason for the buyer discount. */
  discountReasonComment?: string;
  /**
   * The amount of the seller subsidy.
   * @format double
   */
  sellerSubsidyAmount?: number;
  /** Params for creating the purchase invoice for the successful auction. */
  purchaseInvoiceCreationParams?: IPurchaseInvoiceCreationParams;
}

/**
 * Value indicating the lowest price a seller is willing to sell at,
 * is used during re-negotiation when the minimum ask price was not reached.
 */
export interface ILastOfferBySeller {
  /** @format double */
  lastOfferBySeller: number;
  /** @format double */
  lastOfferBySellerNet: number;
}

/** Make all properties in T optional */
export interface PartialICounterOfferPayload {
  /** @format double */
  counterOffer?: number;
  /** @format double */
  counterOfferNet?: number;
}

/** The structure of the response given when requesting the possible auction expiry options. */
export interface IAuctionExpiryData {
  expirationOptions: string[];
  blacklistedDates?: string[];
  /** @format date-time */
  promoDate?: string;
}

export interface IPageIAdminAuctionView {
  items: IAdminAuctionView[];
  /** @format double */
  total: number;
  /** @format double */
  page: number;
  /** @format double */
  pageSize?: number;
}

/** The possible reasons that an auction room could become disabled. */
export enum EAuctionRoomRejectionReason {
  Value1 = -1,
  Value11 = 1,
  Value2 = 2,
}

/**
 * Model definition for a room group.
 * This is a grouping of auction rooms created internally to ensure they are shown in the same view.
 */
export interface IRoomGroup {
  uuid: string;
  /**
   * Indicating the time when the entity has been created, i.e. when the entity was
   * persisted to the database.
   * @format date-time
   */
  createdAt?: string;
  /**
   * Indicating the last time a value of the entity has been changed (updated).
   * @format date-time
   */
  updatedAt?: string;
  /**
   * Indicating the time when the entity has been (softly) deleted.
   * "null" if entry is not deleted.
   * @format date-time
   */
  deletedAt?: string;
  /** A list of account groups that can see the auction room. */
  accountGroupsWhitelist?: string[];
  /** A list of countries that are not allowed to see the auction room. */
  countriesBlacklist?: ECountryCode[];
  /** The title and primary user identifier of the room group. */
  title: string;
  /** Whether the room group is visible to users. */
  visible: boolean;
  /**
   * An array of string messages used to add context to a room group.
   * Tells the users who view the room group a bit more about the auctions contained within or the room group itself.
   */
  metadata: string[];
  /** The URL of a display image for the room group. */
  imageUrl?: string;
  /** The id for the look and feel applied to the room group. */
  lookAndFeelId: EAuctionLookAndFeelId;
  /**
   * The priority that this room will take when sorting for the buyer list.
   * @format double
   */
  priority?: number;
}

/**
 * Model definition for an auction room.
 * This is a grouping of auctions that can be created internally to ensure they expire consecutively.
 */
export interface IAuctionRoom {
  /**
   * @deprecated
   * @format double
   */
  id: number;
  uuid: string;
  /**
   * Indicating the time when the entity has been created, i.e. when the entity was
   * persisted to the database.
   * @format date-time
   */
  createdAt?: string;
  /**
   * Indicating the last time a value of the entity has been changed (updated).
   * @format date-time
   */
  updatedAt?: string;
  /**
   * Indicating the time when the entity has been (softly) deleted.
   * "null" if entry is not deleted.
   * @format date-time
   */
  deletedAt?: string;
  /** A list of account groups that can see the auction room. */
  accountGroupsWhitelist?: string[];
  /** A list of countries that are not allowed to see the auction room. */
  countriesBlacklist?: ECountryCode[];
  /** The title and primary user identifier of the auction room. */
  title: string;
  /** Denotes the part of the auction room lifecycle that the auction room is currently in. */
  status: EAuctionRoomStatus;
  /**
   * The time at which the auctions within the auction room begin to expire.
   * @format date-time
   */
  startTime: string;
  /** The UUID of the internal user who created the auction room. */
  _fk_uuid_creatingInternalUser: string;
  /** The UUID of the internal user who rejected/disabled the auction room (if it is disabled). */
  _fk_uuid_rejectingInternalUser?: string;
  /** The UUID of the auction room which was cloned to make this one. */
  _fk_uuid_clonedFrom?: string;
  /**
   * An array of string messages used to add context to an auction room.
   * Tells the users who view the auction room a bit more about the auctions contained within or the auction room itself.
   */
  metadata?: string[];
  /** The URL of a display image for the auction room. */
  imageUrl?: string;
  auctionUuids?: string[];
  /**
   * The number of auctions that are contained within the auction room.
   * @format double
   */
  totalAuctionCount: number;
  /**
   * The number of auctions that are currently active within the auction room.
   * @format double
   */
  activeAuctionCount: number;
  /**
   * The date that the auction room was made visible at.
   * When an auction room is visible, it means that users can see it but cannot necessarily interact with it yet.
   * @format date-time
   */
  madeVisibleAt?: string;
  /**
   * The date at which the auction room was made accessible at.
   * When an auction room is accessible, it means that users can see and interact with it.
   * @format date-time
   */
  madeAccessibleAt?: string;
  /**
   * The date at which the auction room was made possible to run in parallel at.
   * When an auction room can run in parallel, it means that its auction will not slot a time in the common auction running slots.
   * @format date-time
   */
  madeParallelAt?: string;
  /**
   * The date at which the auction room becomes un-editable.
   * This date is usually close to the time at which the auctions within the auction room are slotted.
   * At this time, the auction room is said to be "finalized" because most attributes become un-editable
   * due to the fact that the auctions are now slotted and cannot be changed from their new positions.
   * @format date-time
   */
  finalizationDate?: string;
  /**
   * The predicted date that the auction room will end.
   * Calculated using the number of auctions, the start time and the auctioning interval.
   * @format date-time
   */
  predictedEndTime?: string;
  /** The reason that the auction room was rejected/disabled. */
  rejectionReason?: EAuctionRoomRejectionReason;
  /**
   * The time in seconds between the end of one auction and the start of the next inside the auction room.
   * If not set, the general default value is applied.
   * @format double
   */
  gapTimeInSeconds?: number;
  /** If set, all auctions inside the auction room will be hidden from the all auctions list. */
  hideFromAllAuctions?: boolean;
  /** The id for the look and feel applied to the auction room. */
  lookAndFeelId?: EAuctionLookAndFeelId;
  /**
   * The fees that can be added to an auction room that will replace the calculation being done for all auction inside of it.
   * @format double
   */
  buyerPurchaseFee?: number;
  /** @format double */
  buyerExportFee?: number;
  /** The strategy used for auctions ending under below min ask assigned to this room */
  belowMinAskBehaviour?: EAuctionBelowMinAskBehaviour;
  /** The advertisement types in the auction room. */
  advertisementTypes?: string[];
  /**
   * The priority that this room will take when sorting for the buyer list.
   * @format double
   */
  priority?: number;
  /**
   * Indicates whether the highest bid should be transferred when auctions within a room are cloned.
   * Defaults to `false` if not specified.
   */
  shouldTransferHighestBid?: boolean;
  /**
   * The room group that this auction room is part of.
   * Used to group auction rooms in a single view for the buyer.
   */
  roomGroup?: IRoomGroup;
  /**
   * Indicates whether the rules document CTA should be displayed within an auction room.
   * Defaults to `false` if not specified.
   */
  showRulesDocument?: boolean;
}

/**
 * This is the payload used to create new auctions rooms from the front end.
 * A title and start time are mandatory for initial creation.
 * All other data can be added over time by further editing the auction room.
 */
export interface IAuctionRoomCreationData {
  /** The title and primary user identifier of the auction room. */
  title: string;
  /**
   * An array of string messages used to add context to an auction room.
   * Tells the users who view the auction room a bit more about the auctions contained within or the auction room itself.
   */
  metadata?: string[];
  /**
   * The time at which the auctions within the auction room begin to expire.
   * @format date-time
   */
  startTime: string;
  /** The URL of a display image for the auction room. */
  imageUrl?: string;
  auctionUuids?: string[];
  /** The id for the look and feel applied to the auction room. */
  lookAndFeelId?: EAuctionLookAndFeelId;
  /**
   * Indicates whether the rules document CTA should be displayed within an auction room.
   * Defaults to `false` if not specified.
   */
  showRulesDocument?: boolean;
  /**
   * The priority that this room will take when sorting for the buyer list.
   * @format double
   */
  priority?: number;
  /**
   * Indicates whether the highest bid should be transferred when auctions within a room are cloned.
   * Defaults to `false` if not specified.
   */
  shouldTransferHighestBid?: boolean;
  /** The strategy used for auctions ending under below min ask assigned to this room */
  belowMinAskBehaviour?: EAuctionBelowMinAskBehaviour;
  /**
   * The time in seconds between the end of one auction and the start of the next inside the auction room.
   * If not set, the general default value is applied.
   * @format double
   */
  gapTimeInSeconds?: number;
  /** If set, all auctions inside the auction room will be hidden from the all auctions list. */
  hideFromAllAuctions?: boolean;
  /** A list of account groups that can see the auction room. */
  accountGroupsWhitelist?: string[];
  /** A list of countries that are not allowed to see the auction room. */
  countriesBlacklist?: ECountryCode[];
  /**
   * The fees that can be added to an auction room that will replace the calculation being done for all auction inside of it.
   * @format double
   */
  buyerPurchaseFee?: number;
  /** @format double */
  buyerExportFee?: number;
  /** The advertisement types in the auction room. */
  advertisementTypes?: string[];
  /**
   * The room group that this auction room is part of.
   * Used to group auction rooms in a single view for the buyer.
   */
  roomGroup?: IRoomGroup;
  isVisible?: boolean;
  isAccessible?: boolean;
  isParallel?: boolean;
}

/**
 * This is the payload used for updating an existing auction room.
 * The same constraints applied to creation get applied for this action.
 */
export interface IAuctionRoomUpdateData {
  isVisible?: boolean;
  isAccessible?: boolean;
  isParallel?: boolean;
  /** The title and primary user identifier of the auction room. */
  title?: string;
  /**
   * An array of string messages used to add context to an auction room.
   * Tells the users who view the auction room a bit more about the auctions contained within or the auction room itself.
   */
  metadata?: string[];
  /**
   * The time at which the auctions within the auction room begin to expire.
   * @format date-time
   */
  startTime?: string;
  /** The URL of a display image for the auction room. */
  imageUrl?: string;
  auctionUuids?: string[];
  /** The id for the look and feel applied to the auction room. */
  lookAndFeelId?: EAuctionLookAndFeelId;
  /**
   * Indicates whether the rules document CTA should be displayed within an auction room.
   * Defaults to `false` if not specified.
   */
  showRulesDocument?: boolean;
  /**
   * The priority that this room will take when sorting for the buyer list.
   * @format double
   */
  priority?: number;
  /**
   * Indicates whether the highest bid should be transferred when auctions within a room are cloned.
   * Defaults to `false` if not specified.
   */
  shouldTransferHighestBid?: boolean;
  /** The strategy used for auctions ending under below min ask assigned to this room */
  belowMinAskBehaviour?: EAuctionBelowMinAskBehaviour;
  /**
   * The time in seconds between the end of one auction and the start of the next inside the auction room.
   * If not set, the general default value is applied.
   * @format double
   */
  gapTimeInSeconds?: number;
  /** If set, all auctions inside the auction room will be hidden from the all auctions list. */
  hideFromAllAuctions?: boolean;
  /** A list of account groups that can see the auction room. */
  accountGroupsWhitelist?: string[];
  /** A list of countries that are not allowed to see the auction room. */
  countriesBlacklist?: ECountryCode[];
  /**
   * The fees that can be added to an auction room that will replace the calculation being done for all auction inside of it.
   * @format double
   */
  buyerPurchaseFee?: number;
  /** @format double */
  buyerExportFee?: number;
  /** The advertisement types in the auction room. */
  advertisementTypes?: string[];
  /**
   * The room group that this auction room is part of.
   * Used to group auction rooms in a single view for the buyer.
   */
  roomGroup?: IRoomGroup;
}

/** This is the auction room data augmented to show for the internal view. */
export interface IAuctionRoomInternalView {
  /**
   * @deprecated
   * @format double
   */
  id: number;
  uuid: string;
  /**
   * Indicating the time when the entity has been created, i.e. when the entity was
   * persisted to the database.
   * @format date-time
   */
  createdAt?: string;
  /**
   * Indicating the last time a value of the entity has been changed (updated).
   * @format date-time
   */
  updatedAt?: string;
  /**
   * Indicating the time when the entity has been (softly) deleted.
   * "null" if entry is not deleted.
   * @format date-time
   */
  deletedAt?: string;
  /** A list of account groups that can see the auction room. */
  accountGroupsWhitelist?: string[];
  /** A list of countries that are not allowed to see the auction room. */
  countriesBlacklist?: ECountryCode[];
  /** The title and primary user identifier of the auction room. */
  title: string;
  /** Denotes the part of the auction room lifecycle that the auction room is currently in. */
  status: EAuctionRoomStatus;
  /**
   * The time at which the auctions within the auction room begin to expire.
   * @format date-time
   */
  startTime: string;
  /** The UUID of the internal user who created the auction room. */
  _fk_uuid_creatingInternalUser: string;
  /** The UUID of the internal user who rejected/disabled the auction room (if it is disabled). */
  _fk_uuid_rejectingInternalUser?: string;
  /** The UUID of the auction room which was cloned to make this one. */
  _fk_uuid_clonedFrom?: string;
  /**
   * An array of string messages used to add context to an auction room.
   * Tells the users who view the auction room a bit more about the auctions contained within or the auction room itself.
   */
  metadata?: string[];
  /** The URL of a display image for the auction room. */
  imageUrl?: string;
  auctionUuids?: string[];
  /**
   * The number of auctions that are contained within the auction room.
   * @format double
   */
  totalAuctionCount: number;
  /**
   * The number of auctions that are currently active within the auction room.
   * @format double
   */
  activeAuctionCount: number;
  /**
   * The date that the auction room was made visible at.
   * When an auction room is visible, it means that users can see it but cannot necessarily interact with it yet.
   * @format date-time
   */
  madeVisibleAt?: string;
  /**
   * The date at which the auction room was made accessible at.
   * When an auction room is accessible, it means that users can see and interact with it.
   * @format date-time
   */
  madeAccessibleAt?: string;
  /**
   * The date at which the auction room was made possible to run in parallel at.
   * When an auction room can run in parallel, it means that its auction will not slot a time in the common auction running slots.
   * @format date-time
   */
  madeParallelAt?: string;
  /**
   * The date at which the auction room becomes un-editable.
   * This date is usually close to the time at which the auctions within the auction room are slotted.
   * At this time, the auction room is said to be "finalized" because most attributes become un-editable
   * due to the fact that the auctions are now slotted and cannot be changed from their new positions.
   * @format date-time
   */
  finalizationDate?: string;
  /**
   * The predicted date that the auction room will end.
   * Calculated using the number of auctions, the start time and the auctioning interval.
   * @format date-time
   */
  predictedEndTime?: string;
  /** The reason that the auction room was rejected/disabled. */
  rejectionReason?: EAuctionRoomRejectionReason;
  /**
   * The time in seconds between the end of one auction and the start of the next inside the auction room.
   * If not set, the general default value is applied.
   * @format double
   */
  gapTimeInSeconds?: number;
  /** If set, all auctions inside the auction room will be hidden from the all auctions list. */
  hideFromAllAuctions?: boolean;
  /** The id for the look and feel applied to the auction room. */
  lookAndFeelId?: EAuctionLookAndFeelId;
  /**
   * The fees that can be added to an auction room that will replace the calculation being done for all auction inside of it.
   * @format double
   */
  buyerPurchaseFee?: number;
  /** @format double */
  buyerExportFee?: number;
  /** The strategy used for auctions ending under below min ask assigned to this room */
  belowMinAskBehaviour?: EAuctionBelowMinAskBehaviour;
  /** The advertisement types in the auction room. */
  advertisementTypes?: string[];
  /**
   * The priority that this room will take when sorting for the buyer list.
   * @format double
   */
  priority?: number;
  /**
   * Indicates whether the highest bid should be transferred when auctions within a room are cloned.
   * Defaults to `false` if not specified.
   */
  shouldTransferHighestBid?: boolean;
  /**
   * The room group that this auction room is part of.
   * Used to group auction rooms in a single view for the buyer.
   */
  roomGroup?: IRoomGroup;
  /**
   * Indicates whether the rules document CTA should be displayed within an auction room.
   * Defaults to `false` if not specified.
   */
  showRulesDocument?: boolean;
  /** The name of the internal user who created the auction room. */
  creatingInternalUserName?: string;
}

export interface IPageIAuctionRoomInternalView {
  items: IAuctionRoomInternalView[];
  /** @format double */
  total: number;
  /** @format double */
  page: number;
  /** @format double */
  pageSize?: number;
}

/** From T, pick a set of properties whose keys are in the union K */
export interface PickIVehicleMakeOrModelOrMileageInKmOrEzOrVin {
  make: string;
  model: string;
  vin: string;
  ez: string;
  /** @format double */
  mileageInKm: number;
}

/**
 * The possible reasons that a given auction expiring on the same day as an auction room
 * would not be able to be added to that auction room.
 */
export enum EAuctionRoomExclusionReason {
  Value1 = -1,
  Value11 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
}

/** The representation of an auction from the perspective of the inclusion functionality. */
export interface IAuctionRoomPotentialAuction {
  uuid: string;
  _fk_uuid_sellerUser: string;
  /**
   * The state of the auction, indicating whether an auction is not yet published (DRAFTED), running (ACTIVE),
   * closed successfully (CLOSED_WAITING_FOR_PAYMENT, CLOSED_WAITING_FOR_PICKUP, FINISHED), failed (CLOSED_NO_BIDS,
   * CLOSED_UNDER_MINIMUM_ASK) or disabled (DISABLED).
   *
   * Depending on the state, different timestamps are available per auction.
   */
  state: EAuctionState;
  /**
   * The minimal price the seller user wants to reach for this specific auction.
   * If an auction ends above that price, the auction is successful right away
   * (switching to state CLOSED_WAITING_FOR_PAYMENT).
   *
   * If the auction ends below the minimum required ask, the auction will switch to state CLOSED_UNDER_MINIMUM_ASK,
   * which then requires the seller's explicit "confirmation" or "rejection" of the highest bid value.
   * @format double
   */
  minimumRequiredAsk: number;
  /**
   * Value that is indicating the current highest bid value on the auction.
   * This is of course a dynamic field, that will be incremented on every new bid placed on the auction.
   * @format double
   */
  currentHighestBidValue: number;
  /**
   * The exact date+time when the auction is expiring/ending.
   * @format date-time
   */
  endingTime: string;
  /**
   * Value that is indicating initial bid value auction should be started with.
   * @format double
   */
  startingBidValue: number;
  /** Physical address of where the vehicle on auction is located (a.k.a. pickup address). */
  locationCountryCode: ECountryCode;
  /**
   * Internal counter, incrementing on every "restart" of an auction.
   * Starts with 0.
   *
   * e.g. count of 2 means that the auction has been restarted 2 times, leading to 3 auction objects in the
   * database.
   * @format double
   */
  auctioningIterations: number;
  /** A flag that marks if an auction is a selective ware or not. */
  isSelectiveWare: boolean;
  externalId: string;
  coverImageUrl: string;
  sellerFullName: string;
  sellerMailAddress: string;
  /** From T, pick a set of properties whose keys are in the union K */
  associatedVehicle: PickIVehicleMakeOrModelOrMileageInKmOrEzOrVin;
  canBeIncluded: boolean;
  /** The possible reasons that an auction would not be allowed to be included in an auction room. */
  exclusionReason?: EAuctionRoomExclusionReason;
}

export interface ICustomerMemo {
  /**
   * @deprecated
   * @format double
   */
  id: number;
  uuid: string;
  /**
   * Indicating the time when the entity has been created, i.e. when the entity was
   * persisted to the database.
   * @format date-time
   */
  createdAt?: string;
  /**
   * Indicating the last time a value of the entity has been changed (updated).
   * @format date-time
   */
  updatedAt?: string;
  /**
   * Indicating the time when the entity has been (softly) deleted.
   * "null" if entry is not deleted.
   * @format date-time
   */
  deletedAt?: string;
  _fk_uuid_creator: string;
  content: string;
  /**
   * This is the new user structure, meant to replace our old fragmented user structure with Sellers, Buyers and etc.
   * A user is someone that is connected to a company and uses our platform for an activity enabled for that account.
   * As we're a B2B platform, our focus is always the account/business, the user is just someone acting in the name of an account.
   */
  associatedCreator: IGeneralUser;
  _fk_uuid_customerUser: string;
}

/** Details for a buyer who is watching an auction. */
export interface IInternalBuyerWatchingAuctionView {
  /** Internal memos for the buyer user. */
  memos: ICustomerMemo[];
  /** The name of the legal entity or branch */
  name: string;
  city: string;
  zipCode?: string;
  uuid: string;
  /** The mail address of the user */
  mailAddress: string;
  /** Slot 1 for the phone number of the user */
  phoneNumber: string;
}

/** Communication channels that can be used to communicate with customers. */
export enum ECustomerCommunicationChannel {
  Value1 = -1,
  Value0 = 0,
  Value11 = 1,
  Value2 = 2,
  Value3 = 3,
}

/**
 * A communication event (i.e. a sent message) between an internal user (e.g. a sales agent) and
 * a customer (e.g. a BuyerUser or a SellerUser).
 *
 * This is only used for WhatsApp communication at the moment.
 */
export interface ICustomerCommunicationEvent {
  /**
   * @deprecated
   * @format double
   */
  id: number;
  uuid: string;
  /**
   * Indicating the time when the entity has been created, i.e. when the entity was
   * persisted to the database.
   * @format date-time
   */
  createdAt?: string;
  /**
   * Indicating the last time a value of the entity has been changed (updated).
   * @format date-time
   */
  updatedAt?: string;
  /**
   * Indicating the time when the entity has been (softly) deleted.
   * "null" if entry is not deleted.
   * @format date-time
   */
  deletedAt?: string;
  /**
   * The customer user that the message sent resp. that the message has been send to.
   * The direction of communication is based on "outbound".
   */
  _fk_uuid_involvedCustomerUser: string;
  /**
   * The internal user that the message sent resp. that the message has been send to.
   * The direction of communication is based on "outbound".
   *
   * If a message is inbound, the involved internal user will always be the system account.
   */
  _fk_uuid_involvedInternalUser: string;
  /** The communication channel through which the message has been send (e.g. mail, WhatsApp, SMS). */
  channel: ECustomerCommunicationChannel;
  /** Whether the message is inbound (coming from a customer) or outbound (coming from an internal user) */
  outbound: boolean;
  /** The actual text content of the message. */
  message: string;
  /** An optional link to a media resource that is displayed/send in some channels (e.g. WhatsApp displays images). */
  mediaResourceUrl: string;
  /**
   * Flag indicating whether the message has been seen/consumed by an internal user.
   * Outbound messages are always consumes by default.
   *
   * A message is marked as consumed as soon as the internal user is opening the message
   * (i.e. in the WhatsApp web client).
   */
  isConsumedByInternalUser: boolean;
  /** Optional and additional meta data. */
  metaData: any;
}

/** Score details for recommended buyers. */
export interface IRawScore {
  /** @format double */
  minAsk: number;
  /** @format double */
  mileage: number;
  /** @format double */
  ez: number;
  /** @format double */
  fuelType: number;
  /** @format double */
  make: number;
  /** @format double */
  category: number;
}

/** How internal users see buyer users. */
export interface IInternalBuyerView {
  uuid: string;
  /** The mail address of the user */
  mailAddress: string;
  /**
   * Indicating the time when the entity has been created, i.e. when the entity was
   * persisted to the database.
   * @format date-time
   */
  createdAt?: string;
  /**
   * Indicating the last time a value of the entity has been changed (updated).
   * @format date-time
   */
  updatedAt?: string;
  /**
   * Indicating the time when the entity has been (softly) deleted.
   * "null" if entry is not deleted.
   * @format date-time
   */
  deletedAt?: string;
  /** Current version vector */
  versionVector: number[];
  /** Indicates this update comes from a remote system */
  isRemoteUpdate: boolean;
  /** The mail address which is using for login */
  loginMailAddress?: string;
  /** The user id generated by the IdP (identity provider) */
  idpUserId?: string;
  /** Reference to the account this user belongs to */
  _fk_uuid_account: string;
  /**
   * Whether the user wants to be addressed with honorific form
   * (e.g. "Sie" instead of "Du" in German)
   */
  honorific: boolean;
  /** The first name of the user */
  firstName: string;
  /** The last name of the user */
  lastName: string;
  /** Url to the user profile picture */
  urlToProfilePicture: string;
  /** Slot 1 for the phone number of the user */
  phoneNumber: string;
  /** Slot 2 for the phone number of the user */
  phoneNumber2: string;
  /** Slot 3 for the phone number of the user */
  phoneNumber3: string;
  /** The state of the user. */
  state: EUserState;
  /** Referral code this user can share with friends to register in the platform */
  personalReferralCodeToShare: string;
  /**
   * The referral code that was used by the user when registering to COS.
   * "null" if no referral code has been used.
   */
  referralCode: string;
  /**
   * The roles this user plays (check {@link GENERAL_USER_ROLES})
   *
   * This can also be seen as a set of permissions
   */
  roles: string[];
  /** List of permissions indicating what the user is allowed to do. */
  permissions: EGeneralUserPermission[];
  /** Data for the account this user belongs to. Optional augmentation (see: {@link EGeneralUserAugmentation.ACCOUNT}). */
  account?: IAccount;
  /** The GeneralUserSettings data that contains extra settings and preferences. Optional augmentation (see: {@link EGeneralUserAugmentation.SETTINGS}). */
  settings?: IGeneralUserSettings;
  /** Additional e-mail recipients for e-mails sent to the user */
  additionalEmailRecipients?: IUserAdditionalEmailRecipient[];
  /**
   * Timestamp when the user went completed the self-sign up process by confirmation it's e-mail address.
   * @format date-time
   */
  mailAddressConfirmedAt?: string;
  /** Whether Two-Factor Authentication is enabled for this user */
  hasTwoFactorAuth?: boolean;
  /** Internal memos for the buyer user. */
  memos: ICustomerMemo[];
  /**
   * Indicating whether the buyer user is available for WhatsApp communication
   * (i.e. the last message sent BY the buyer user is not older then 24 hours)
   */
  isAvailableForWhatsAppMessaging: boolean;
  /** Incoming messages that have not been consumed yet by internal users. */
  recentMessages: ICustomerCommunicationEvent[];
  /**
   * TODO
   * @format double
   */
  matchingScore: number;
  /** TODO */
  matchingScoreRaw: IRawScore;
}

export interface IKAMSalesmanAuctionAccessView {
  /** How internal users see buyer users. */
  salesman: IInternalBuyerView;
  /** @format date-time */
  accessedAt: string;
}

/**
 * Inform that a new resource was created. Server will
 * add a Location header and set status to 201
 */
export interface ReturnNewResourceIAuctionMemo {
  location: string;
  /** @format double */
  statusCode: number;
  /** the body to be sent */
  body: IAuctionMemo;
}

/** How internal users see auction bids. */
export interface IInternalAuctionBidView {
  /**
   * @deprecated
   * @format double
   */
  id: number;
  uuid: string;
  /**
   * Indicating the time when the entity has been created, i.e. when the entity was
   * persisted to the database.
   * @format date-time
   */
  createdAt?: string;
  /**
   * Indicating the last time a value of the entity has been changed (updated).
   * @format date-time
   */
  updatedAt?: string;
  /**
   * Indicating the time when the entity has been (softly) deleted.
   * "null" if entry is not deleted.
   * @format date-time
   */
  deletedAt?: string;
  /**
   * The value of the bid that was given by the bidder/buyer.
   * @format double
   */
  value: number;
  /** @format double */
  valueNet: number;
  /**
   * Indicating whether the bid was given in the "hot bid phase", therefore
   * extending the runtime of the auction.
   */
  isHotBid: boolean;
  /** @deprecated */
  isAutoBid: boolean;
  /** Indicating whether the bid was placed from the list view. */
  isListViewBid: boolean;
  /** Indicating where the bid was made by the bidder/buyer (browser or mobile UI). */
  clientCategory: EUserClientCategory;
  /** The auction that this bid is for. */
  _fk_uuid_auction: string;
  /** The bidder which placed the bid. */
  _fk_uuid_biddingBuyer: string;
  /** If this bid was automatically triggered by a different buyers bid, the identity of the triggering buyer is stored here. */
  _fk_uuid_triggeringBuyer?: string;
  /**
   * Indicates if this bid was made on the reauction of this auction and
   * should be seen by the buyer of the reauction and not the seller of the original auction.
   */
  isReauctionBid: boolean;
  /** The buyer that has made the bid. */
  associatedSalesman: IInternalBuyerView;
  /** Triggering bidder name if present. */
  triggeringBuyerName?: string;
  /** Triggering bidder email address if present. */
  triggeringBuyerMailAddress?: string;
}

/** All fields that the internal user can update on the auction. */
export interface IInternalAuctionUpdate {
  /**
   * @deprecated
   * @format double
   */
  id: number;
  uuid: string;
  /**
   * Indicating the time when the entity has been created, i.e. when the entity was
   * persisted to the database.
   * @format date-time
   */
  createdAt?: string;
  /**
   * Indicating the last time a value of the entity has been changed (updated).
   * @format date-time
   */
  updatedAt?: string;
  /**
   * Indicating the time when the entity has been (softly) deleted.
   * "null" if entry is not deleted.
   * @format date-time
   */
  deletedAt?: string;
  /** Stores which type of additional tax applies to the auction if any. */
  additionalTaxType: EAdditionalTaxType;
  /**
   * Stores the value of the additional tax that might apply to an auction.
   * @format double
   */
  additionalTaxValue: number;
  /**
   * Stores the export discount for taxes.
   * @format double
   */
  additionalTaxExportDiscount: number;
  /**
   * The state of the auction, indicating whether an auction is not yet published (DRAFTED), running (ACTIVE),
   * closed successfully (CLOSED_WAITING_FOR_PAYMENT, CLOSED_WAITING_FOR_PICKUP, FINISHED), failed (CLOSED_NO_BIDS,
   * CLOSED_UNDER_MINIMUM_ASK) or disabled (DISABLED).
   *
   * Depending on the state, different timestamps are available per auction.
   */
  state: EAuctionState;
  /**
   * The exact date+time when the auction is expiring/ending.
   * @format date-time
   */
  endingTime: string;
  /**
   * The remaining number of seconds that this auction will be in the state ACTIVE (running).
   *
   * This is a dynamic, non-persisted field, calculated on every retrieval of the auction data.
   * @format double
   */
  remainingTimeInSeconds: number;
  /**
   * The remaining number of seconds that this auction is available for instant purchase
   * (usually the first 24 hours after the auction is started).
   *
   * This is a dynamic, non-persisted field, calculated on every retrieval of the auction data.
   * @format double
   */
  remainingTimeForInstantPurchaseInSeconds: number;
  /**
   * Date+time indicating until when an auction is available for instant purchasing.
   * By default, this is 24 hours after the start of an auction.
   * @format date-time
   */
  instantPurchasePossibleUntil: string;
  _fk_uuid_highestBiddingBuyerUser: string;
  /**
   * Value that is indicating the current highest bid value on the auction.
   * This is of course a dynamic field, that will be incremented on every new bid placed on the auction.
   * @format double
   */
  currentHighestBidValue: number;
  /** @format double */
  currentHighestBidValueNet: number;
  /**
   * The minimal price the seller user wants to reach for this specific auction.
   * If an auction ends above that price, the auction is successful right away
   * (switching to state CLOSED_WAITING_FOR_PAYMENT).
   *
   * If the auction ends below the minimum required ask, the auction will switch to state CLOSED_UNDER_MINIMUM_ASK,
   * which then requires the seller's explicit "confirmation" or "rejection" of the highest bid value.
   * @format double
   */
  minimumRequiredAsk: number;
  /** @format double */
  minimumRequiredAskNet: number;
  /**
   * Number of bids placed on an auction.
   * @format double
   */
  numBids: number;
  /** @format double */
  buyerRecommendationScore?: number;
  /** Was this auction created with the help of an inspection done or commissioned by CarOnSale? */
  inspectionWasCommissionedByCos: boolean;
  /** By which type of user was this auction created. */
  creatorType: EAuctionCreatorType;
  /** UUID of the creating internal user if there is one. */
  _fk_uuid_creatingInternalUser: string;
  /** UUID of the creating seller user if there is one. */
  _fk_uuid_creatingSellerUser: string;
  /**
   * Indicates if the auction was created for testing purposes and
   * should be handled differently: it should not be ended automatically,
   * so the seller should decide would he like to sell it or not.
   */
  isTest: boolean;
  /** Indicates if minimum required ask should be displayed for buyers. */
  displayMinAsk: boolean;
  /**
   * GPS coordinates of the auction (can be used for e.g. calculating distances between auctions and buyers).
   * @format double
   */
  locationGeoLat: number;
  /** @format double */
  locationGeoLon: number;
  /** @format double */
  counterOfferByBuyer: number;
  /** @format double */
  counterOfferByBuyerNet: number;
  /** @format double */
  lastOfferBySeller: number;
  /** @format double */
  lastOfferBySellerNet: number;
  /**
   * A value denoting the previous renegotiation offer given by a buyer user.
   * @format double
   */
  previousCounterOfferByBuyer: number;
  /** @format double */
  previousCounterOfferByBuyerNet: number;
  /**
   * A value denoting the previous renegotiation offer given by a seller user.
   * This is required in order to properly display the right information during the renegotiation process.
   * @format double
   */
  previousLastOfferBySeller: number;
  /** @format double */
  previousLastOfferBySellerNet: number;
  /** A flag for if the seller user connected to the auction has decided not to participate in the renegotiation any further. */
  renegotiationStoppedBySeller: boolean;
  /** A flag for if the highest bidding buyer user connected to the auction has decided not to participate in the renegotiation any further. */
  renegotiationStoppedByBuyer: boolean;
  /** A fag indicating if the buyer's withdrawal from the renegotiation process was done automatically or not. */
  wasRenegotiationStoppageByBuyerAutomatic: boolean;
  /** A fag indicating if the seller's withdrawal from the renegotiation process was done automatically or not. */
  wasRenegotiationStoppageBySellerAutomatic: boolean;
  /**
   * The total number of offer rounds a buyer has participated in for an auction during the renegotiation phase.
   * @format double
   */
  numBuyerRenegotiationOfferRounds: number;
  /**
   * The total number of offer rounds a seller has participated in for an auction during the renegotiation phase.
   * @format double
   */
  numSellerRenegotiationOfferRounds: number;
  /**
   * The calculated renegotiation midpoint value of the auction.
   * This is used once the renegotiation offers get too close for the offer process to continue.
   * This value will be undefined unless the auction is ready to be purchased for such a value.
   * @format double
   */
  renegotiationMidpointValue: number;
  /** @format double */
  renegotiationMidpointValueNet: number;
  /**
   * Indicates that the highest bidding buyer user for an auction in renegotiation is willing to accept the midpoint of their offer and
   * the offer of the seller for the same auction. This is used when the renegotiation offers get very close during the renegotiation process.
   */
  buyerAcceptedRenegotiationMidpoint: boolean;
  /**
   * Indicates that the seller of an auction is willing to accept the midpoint of their offer and the offer of the highest bidding
   * buyer user for the same auction. This is used when the renegotiation offers get very close during the renegotiation process.
   */
  sellerAcceptedRenegotiationMidpoint: boolean;
  /**
   * This is the datetime object denoting when the most recent renegotiation round concluded at.
   * @format date-time
   */
  lastRenegotiationRoundEndedAt: string;
  /**
   * The number of seconds until the current renegotiation round times out.
   * @format double
   */
  numSecondsUntilRenegotiationTimeout: number;
  /**
   * The UUID of the external case tracking the renegotiation of the auction.
   * We track this to enable case updates as the auction moves through renegotiation.
   */
  externalRenegotiationTicketUuid: string;
  /**
   * The calculated renegotiation fair value of the auction.
   * This is used once the renegotiation starts based on the auction GPR or MPR so we know
   * the fair value that both seller and buyer should agree upon.
   * @format double
   */
  renegotiationFairValue: number;
  /**
   * Value that is indicating the highest bid value on the auction when it ended.
   * This value should not change for a given auction since it supposed to be a historical record.
   * @format double
   */
  highestBidValueAtEndingTime: number;
  /** @format double */
  highestBidValueAtEndingTimeNet: number;
  /** Field data related to the renegotiation round process. */
  renegotiationRoundData?: IRenegotiationRoundData;
  /**
   * Indicates when manual renegotiation was finished via Salesforce.
   * @format date-time
   */
  finishedManuallyAt?: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing service (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the transfer of funds
   * for the vat deposit from COS to the deposit account (only relevant for cross border transactions).
   * This deposit is refunded by COS once the buyer provides the necessary documentation for a cross border transaction.
   */
  thirdPartyVATDepositTransferReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing service (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the transfer of funds
   * for the additional tax deposit from COS to the deposit account (only relevant for cross border transactions).
   * This deposit is refunded by COS once the buyer provides the necessary documentation for a cross border transaction.
   */
  thirdPartyAdditionalTaxDepositTransferReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing system (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the transfer of funds
   * for the auction additional taxes (e.g country specific luxury taxes): from COS to the seller.
   */
  thirdPartyAdditionalTaxTransferReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing service (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the transfer of funds
   * for the VAT: from COS to the seller (only relevant for cross border transactions for now).
   * COS is advancing the vat amount to the seller.
   */
  thirdPartyVatTransferReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing service (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the charge of funds
   * for the auction volume from the buyer to COS.
   */
  thirdPartyVolumeChargeReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing service (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the charge of funds
   * for the auction deposit from the buyer to COS (only relevant for cross border transactions for now).
   * This deposit is refunded by COS once the buyer provides the necessary documentation for a cross border transaction.
   */
  thirdPartyVATDepositChargeReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing service (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the charge of funds
   * for the auction additional tax (e.g country specific luxury tax) from the buyer to COS.
   */
  thirdPartyAdditionalTaxChargeReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing system (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the refund of an
   * additional tax deposit (only used for cross-border transactions currently).
   */
  thirdPartyAdditionalTaxRefundReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing system (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the payout of funds.
   */
  thirdPartyPayoutReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing system (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the refund of a
   * deposit (only used for cross-border transactions currently).
   */
  thirdPartyVATDepositRefundReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing system (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the refund of a
   * transfer to the deposit account owned by cos (only used for cross-border transactions currently).
   */
  thirdPartyVATDepositReversalReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing system (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the refund of the
   * additional tax transfer to the deposit account owned by cos (only used for cross-border transactions).
   */
  thirdPartyAdditionalTaxDepositReversalReference: string;
  /**
   * Reference to the invoice for the success fee issued to the seller for a sold vehicle (see ESellerFeeInvoicingMode.INVOICE_ON_SUCCESS).
   * The reference identifies the invoice issued to the seller in an external invoicing system (e.g. Stripe).
   *
   * This fee is not always charged, therefore this reference is optional (can be null).
   */
  sellerSuccessFeeInvoiceReference: string;
  /**
   * Reference to the invoice for the listing fee issued to the seller for a listed vehicle (see ESellerFeeInvoicingMode.INVOICE_ON_LISTING).
   * The reference identifies the invoice issued to the seller in an external invoicing system (e.g. Stripe).
   *
   * This fee is not always charged, therefore this reference is optional (can be null).
   */
  sellerListingFeeInvoiceReference: string;
  /**
   * Reference to the invoice for the standing fee issued to the buyer for a purchased vehicle picked up too late.
   *
   * This fee is not always charged, therefore this reference is optional (can be null).
   */
  buyerStandingFeeInvoiceReference: string;
  /**
   * Reference to a resource in an external invoicing system (such as Stripe).
   * References our own transaction fee invoice.
   */
  invoiceReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing system (such as Stripe).
   * This is only non-null, if external payment is allowed for this auction.
   */
  thirdPartyInvoiceReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing system.
   * This is only non-null, if external payment is allowed for this auction and it had to be cancelled.
   */
  thirdPartyCorrectionInvoiceReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing system (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the transfer of funds
   * for the auction volume: from COS to the seller.
   */
  thirdPartyTransferReference: string;
  /**
   * Reference of an invoice on an external invoicing system.
   * This is invoice is used to charge booked services (like guarantee) after the auction expires.
   */
  bookedServicesInvoiceReference: string;
  /**
   * Date-time when the auction was reauctioned and is now waiting for buyer payment until the new auction gets paid.
   * @format date-time
   */
  reauctionedWaitingForBuyerPaymentAt: string;
  /**
   * Date-time when the first reauction of this auction has expired.
   * We need to keep track of this so we can block the original buyer of seeing the original auction.
   * @format date-time
   */
  firstReauctionExpiredAt: string;
  /** Flag that indicates if this auction is a clonned reauction. */
  isReauction: boolean;
  /**
   * The highest bid value that we show to the seller when we merge the reauction with the original auction.
   * This is done so the seller see the amount that cos pay them and the buyer sees the amount that they paid cos.
   * @format double
   */
  sellerViewHighestBidValue: number;
  /**
   * The purchase confirmed at that we show to the seller when we merge the reauction with the original auction.
   * This is done so the seller see the date when the original auction was confirmed and the buyer sees the date that the reauction was confirmed.
   * @format date-time
   */
  sellerViewPurchaseConfirmedAt: string;
  /**
   * Date-time when the reauction was merged back at the original auction.
   * @format date-time
   */
  reauctionedMergedAt: string;
  /** Indicates wherever an auction was purchase using the Buy Now Pay Later feature. */
  wasPurchasedUsingBuyNowPayLater: boolean;
  /**
   * The timestamp at which the buyer has confirmed his choice to use Buy Now Pay Later for the auction.
   * @format date-time
   */
  buyNowPayLaterConfirmedAt: string;
  /** The IP address which confirmed the Buy Now Pay Later choice for the auction. */
  buyNowPayLaterConfirmationIPAddress: string;
  /** Reference to the Buy Now Pay Later Order on the PNI Service. */
  buyNowPayLaterOrderReference: string;
  /** Reference of the invoice for buy now pay later provider fee on an external invoicing system. */
  buyNowPayLaterInvoiceReference: string;
  /** The payment link reference */
  paymentLinkReference: string;
  /** Reference to the payment order on the P&I service */
  paymentOrderReference: string;
  isInternalFulfillment: boolean;
  belowMinAskBehaviour: EAuctionBelowMinAskBehaviour;
  /** The label of the auction, consisting of the vehicle make, model and date of first registration (EZ). */
  label: string;
  /**
   * Date+time when the auction is started.
   * When an auction is started right away (without drafting), the "createdAt" and "startedAt" should have
   * (roughly) the same values.
   * @format date-time
   */
  startedAt: string;
  /**
   * Date+time when the auction is "confirmed to be sold" by the seller (or by an admin).
   * If an auction is ending equal or above the minimum required ask, the "purchaseConfirmedAt"
   * will automatically be set and will be equal to the "endingTime".
   *
   * If an auction is ending below the minimum ask (CLOSED_UNDER_MINIMUM_ASK), the "purchaseConfirmedAt" timestamp
   * is only set if the auction is then manually "confirmed" by the seller or an admin.
   *
   * If an auction is never "successful", the "purchaseConfirmedAt" will remain "null".
   *
   * This value was introduced in the course of 2019. For all previous auctions, the endingTime was set
   * as the "purchaseConfirmedAt" retrospectively.
   * @format date-time
   */
  purchaseConfirmedAt: string;
  /**
   * Date-time when the auction has definitely been rejected by a seller or an admin, after the auction
   * ended below minimum required ask.
   *
   * This value is only available since 2019/07/29. All auctions rejected before that date have the value "null".
   * @format date-time
   */
  purchaseRejectedAt: string;
  /** Indicates the reason that an auction failed the renegotiation process. */
  rejectionReason: EAuctionRejectionReason;
  /**
   * Elaborates on the rejection reason to provide further context to why an
   * auction was rejected during the renegotiation process.
   */
  rejectionReasonNote: string;
  /**
   * The name of the competitor that the auction was sold to when the reject chosen reason was
   * VEHICLE_SOLD_ON_DIFFERENT_PLATFORM
   */
  rejectionReasonCompetitorName: string;
  /** In case if there was a direct upload requested but didn't succeed the reason should be stored. */
  directUploadRejectionReasons: EAuctionDirectUploadRejectionReason[];
  /**
   * Value that is indicating initial bid value auction should be started with.
   * @format double
   */
  startingBidValue: number;
  /** @format double */
  startingBidValueNet: number;
  /**
   * Value that can be manually set in case edge-cases apply and the payout amount is disconnected from the auction current highest bid.
   * This value can only be adjusted by internal users.
   * @format double
   */
  overridePayoutValue: number;
  /**
   * If an auction is ending below the minimum required ask, the "originalMinimumRequiredAsk" will be set to the
   * "minimumRequiredAsk" value before it is set to "currentHighestBidValue".
   * @format double
   */
  originalMinimumRequiredAsk: number;
  /** @format double */
  originalMinimumRequiredAskNet: number;
  /**
   * The purchase price for the vehicle that is auctioned.
   * This is set manually by the seller, and is mostly used to analyze the "real" margin that a seller
   * makes with an auction.
   * @format double
   */
  purchasePrice: number;
  /** @format double */
  purchasePriceNet: number;
  /**
   * The full object of the vehicle that is on auction.
   * This is augmented on every auction retrieval, based on _fk_uuid_vehicle.
   */
  associatedVehicle: IVehicle;
  /** Indicates whether the auction process is rated by the SellerUser. */
  isRatedByDealership: boolean;
  /** Indicates whether the auction process is rated by the BuyerUser. */
  isRatedByBuyer: boolean;
  /**
   * Indicates whether the BuyerUser has marked this auction as paid (payment of the auction volume).
   * This is done manually by the BuyerUser, so this value is not indicating that the actual payment has really
   * reached the seller.
   */
  isPaidByBuyer: boolean;
  /**
   * Timestamp when the BuyerUser has marked this auction as "paid" (i.e. set the "isPaidByBuyer").
   * @format date-time
   */
  outgoingPaymentConfirmedAt: string;
  /**
   * Timestamp when the SellerUser has confirmed that the payment has reached the seller bank account.
   * @format date-time
   */
  incomingPaymentConfirmedAt: string;
  /**
   * Timestamp when the auction state has changed from "CLOSED_WAITING_FOR_PAYMENT" to "CLOSED_WAITING_FOR_PICKUP".
   * @format date-time
   */
  pickupConfirmedAt: string;
  /** Physical address of where the vehicle on auction is located (a.k.a. pickup address). */
  locationCountryCode: ECountryCode;
  locationAddress: string;
  locationCity: string;
  locationZip: string;
  /** Physical address of where the vehicle on auction was inspected (a.k.a. inspection address). */
  inspectionLocationCountryCode: ECountryCode;
  inspectionLocationAddress: string;
  inspectionLocationCity: string;
  inspectionLocationZip: string;
  /**
   * Indicating to which bank account the BuyerUser has to transfer the auction volume to.
   * @deprecated
   */
  sellerIban: string;
  /**
   * URL to the seller invoice for the given auction, manually uploaded by the SellerUser, after
   * the auction has ended successfully.
   */
  urlToInvoice: string;
  /** URL for the cancelled invoice in case it was cancelled. */
  urlToCorrectionInvoice: string;
  /**
   * Flag indicating whether an auction has an "hot bid" phase in the last 20 seconds before expiration.
   * @deprecated
   */
  hotBid: boolean;
  /**
   * Seller's instant purchase price for the vehicle.
   * For the first 24 hours after the auction is started, the vehicle will only be available in "instant purchase"
   * mode (bidding is not allowed in that time, only purchasing right away).
   *
   * After 24 hours, if nobody purchased the vehicle, it is going on auction, just like all other auctions.
   * @format double
   */
  instantPurchasePrice: number;
  /** @format double */
  instantPurchasePriceNet: number;
  /**
   * Flag indicating that instant purchase is enabled for that auction.
   * (Implies that "instantPurchasePrice" is set)
   */
  allowInstantPurchase: boolean;
  /**
   * Flag indicating that an auction "ended" with an instant purchase, resp.
   * did never go on auction.
   */
  didEndWithInstantPurchase: boolean;
  /**
   * Internal counter, incrementing on every "restart" of an auction.
   * Starts with 0.
   *
   * e.g. count of 2 means that the auction has been restarted 2 times, leading to 3 auction objects in the
   * database.
   * @format double
   */
  auctioningIterations: number;
  /**
   * Indicating the strategic priority of an auction for CarOnSale.de
   * The higher the number, the more "relevant" the auction is.
   * @format double
   */
  priority: number;
  /** @deprecated */
  advertisementHtmlContent: string;
  /** @deprecated */
  buyerComplaint: EAuctionBuyerComplaint;
  /**
   * Custom field that is not used internally.
   * Allows sellers to store e.g. a reference ID to their own vehicle management system.
   */
  internalReferenceText: string;
  _fk_uuid_vehicle: string;
  _fk_uuid_sellerUser: string;
  /** This is a URL that redirects to an external invoice payment site (such as provided by Stripe). */
  urlToPaymentSite: string;
  /**
   * A flag indicating if an auction needs to be reviewed by an internal review agent (for quality assurance)
   * If true, an auction is currently under review by CarOnSale, and cannot be edited by the owning seller user.
   */
  needsReview: boolean;
  /** The reasons why an auction was rejected during the review. */
  reviewReasons: EAuctionReviewReason[];
  /**
   * Reasons why an auction is rejected during the review process ("Parkhaus").
   *
   * This reason is automatically set to UNKNOWN for all auctions and is then manually adjusted by
   * an internal review agent (in case the auction gets rejected) before it is sent back to the responsible
   * SellerUser.
   * @deprecated
   */
  reviewReason: EAuctionReviewReason;
  /** Specifies reason when "reviewReasons" contains EAuctionReviewReason.OTHER. */
  reviewReasonDetails: string;
  /** The comment given by an internal review agent on rejecting an auction under review. */
  reviewComment: string;
  /** Flag indicating if the auction can be published without review. */
  canSkipReview?: boolean;
  /**
   * Random PIN code that is generated when an auction is ready to be picked up or in advance before COSCheck inspection.
   * This PIN code is printed out on the hangar in the vehicle or handed to the seller of the auction, which can then forward it to
   * the buyer to confirm the pickup.
   */
  pickupPinCode: string;
  /**
   * Random UUID that is used to confirm vehicle pickup via scanning QR code.
   * It will be printed on the hangar or in the pickup document available for sellers.
   */
  pickupPinUuid: string;
  /**
   * Timestamp when PIN code was entered by the buyer.
   * Allows to determine how many auctions were self-picked up.
   * @format date-time
   */
  pickupPinCodeEnteredAt: string;
  /**
   * Timestamp when PIN UUID was entered by the buyer via scanning QR code.
   * Allows to determine how many auctions were self-picked up.
   * @format date-time
   */
  pickupPinUuidEnteredAt: string;
  /** Flag is indicating if pickup was confirmed with PIN using new process (via pin.caronsale.de) */
  pickupConfirmedWithNewPin: boolean;
  /** URL to the pickup information document for the BuyerUser. */
  urlToPickupBuyerDocument: string;
  /** URL to the pickup information document for the SellerUser (containing the "pickupPinCode"). */
  urlToPickupSellerDocument: string;
  /** URL to the success report document for the SellerUser. */
  urlToSellerSuccessReport: string;
  /** The payment process that is used to pay money for the purchase volume (auction volume). */
  paymentProcess: EAuctionPaymentProcess;
  /**
   * Referencing the bank account to which the payment for the purchase volume should be paid out.
   * This is null, if the paymentProcess === "CLASSIC".
   *
   * It is only used, when external payment (COS Payment) is enabled.
   */
  _fk_uuid_associatedBankAccount: string;
  /**
   * Indicates how often an auction has been reviewed.
   * This is incremented every time an auction is rejected after a review.
   * @format double
   */
  draftReviewIterations: number;
  /**
   * Timestamp when an admin resets a time window for a possibility to complain
   * @format date-time
   */
  complaintTimeWindowExtendedAt: string;
  /**
   * Flag indicates if this auction is suspected to have been paid in cash (only for auctions that use the external
   * invoice process), this means that no corresponding transfer exists on Stripe and the buyer confirmed the pickup
   * through the seller pin code.
   */
  isSuspectedCashPayment: boolean;
  /**
   * Timestamp when the auction was sent to review.
   * @format date-time
   */
  reviewStartedAt: string;
  /**
   * Timestamp when the auction QA process actually started (i.e. when Parkhaus started reviewing the auction).
   * @format date-time
   */
  qaStartedAt: string;
  /**
   * Timestamp when the auction was ready to be reinserted (a.k.a. restarted).
   * @format date-time
   */
  readyToBeReinsertedSince: string;
  /** The reference to the original auction from which current one was cloned. */
  _fk_uuid_clonedFrom: string;
  /** Auction type. */
  type: EAuctionType;
  /** Indicates whether the rejection wait period for this auction is over and thus can be rejected by the seller. */
  isRejectionWaitPeriodOver: boolean;
  /** Reference to invoice object for the external Billomat invoicing provider. */
  thirdPartyVehiclePurchaseInvoiceReference: string;
  /**
   * URL to the seller made invoice invoicing CoS for the given auction, manually uploaded to the crm by an internal user, after
   * the auction has ended successfully.
   */
  sellerToCosInvoiceUrl: string;
  /**
   * Timestamp for when the sellerToCosInvoiceUrl was last updated.
   * @format date-time
   */
  sellerToCosInvoiceUrlLastUpdatedAt: string;
  /**
   * Timestamp for when the sellerToCosInvoiceUrl is first uploaded.
   * @format date-time
   */
  sellerToCosInvoiceUrlFirstUploadedAt: string;
  /** Flag indicating wherever the uploaded seller to cos invoice was verified by an internal user. */
  sellerToCosInvoiceIsValid: boolean;
  /** The comment given by an internal user on the provided seller to cos invoice. */
  sellerToCosInvoiceComment: string;
  /** Shows if auction is marked as 'live', so, it will be slotted during starting and expire in less than 24 hours. */
  isLive: boolean;
  /** Shows if auction was already slotted via the slotting algorithm. */
  isSlotted: boolean;
  /**
   * The exact date+time when the auction was slotted to end.
   * @format date-time
   */
  slottedEndingTime: string;
  /** Indicating if transportation should be disabled manually. */
  isTransportationDisabledManually: boolean;
  /** Optional pickup instructions that will be communicated to the buyer. */
  pickupInstructions: string;
  /** Indicating wherever the seller should be charged a success/listing fee. */
  preventSellerFactoring: boolean;
  /** Indicating if buyer success fee should be applied or not. */
  applyBuyerSuccessFee: boolean;
  /** Info from document shipping service about the order, like status and links to the documents. */
  documentShippingOrder: IAuctionDocumentShippingOrder;
  /** Info about un-/confirmed auction data points. */
  fieldsConfirmationStatus: IAuctionFieldConfirmationStatusMap;
  /**
   * Whether the "Value Added Tax is reportable", a special tax condition in the EU.
   * If this is true, it means that the vehicle is "Regelbesteuert", meaning that the invoice for the vehicle
   * (either invoiced directly from seller to buyer - or from COS Payment to buyer) needs to include
   * the VAT (in Germany e.g. 19%) in the invoice.
   *
   * If this is false, the invoice does not need to have explicit VAT.
   */
  isVATReportable: boolean;
  /** Indicates how the auction was uploaded to the marketplace. */
  uploadMethod: EAuctionUploadMethod;
  /** Prebooked services available for the auction, like transport, warranty. */
  prebookedServices: IPrebookedService[];
  /** Indicates if cosCheckPlus guarantee can be booked for this auction. */
  isCosCheckPlusGuaranteeEnabled: boolean;
  /** Indicates if extended guarantee can be booked for this auction. */
  isExtendedGuaranteeEnabled: boolean;
  /** URL to the auction vehicle cover letter for sellers */
  urlToCoverLetter: string;
  /**
   * Timestamp when the automatic pickup approval was requested for auction
   * @format date-time
   */
  autoPickupOnPaymentRequestedAt: string;
  /**
   * This means that the auctions is using complimentary vehicle feature.
   *
   * Note: This is marked as true when the seller uses one of his complimentary vehicles to pay for the fees.
   */
  isSellerFeeComplimentary: boolean;
  /** Flag when standing fee is enabled to the auction and we'll need to extra charge a buyer as a penalty. */
  isStandingFeeEnabled: boolean;
  _fk_uuid_auctionRoom: string;
  /**
   * An auction can have a list of fulfillment criteria that impact how the
   * auction will be fulfilled depending on the buyer that wins it.
   */
  fulfillmentCriteria?: IAuctionFulfillmentCriteria[];
  /**
   * The auction marked with this flag won't start automatically after review.
   *
   * Instead, it will transition to state: READY_TO_BE_PUBLISHED.
   *
   * Default: false
   */
  shouldWaitToBePublishedAfterReview: boolean;
  /** A flag that marks if an auction is a selective ware or not. */
  isSelectiveWare: boolean;
  /** The id for the look and feel applied to the auction. */
  lookAndFeelId: EAuctionLookAndFeelId;
  /** A flag that marks if an auction will use the pickup qr code. */
  isQRCodeAvailable: boolean;
  /**
   * Indicates whether the highest bid should be transferred if this auction gets cloned.
   * Defaults to `false` if not specified.
   */
  shouldTransferHighestBid?: boolean;
  /** A flag that represents either or not if the auction was paid externally. */
  wasExternallyPaid?: boolean;
  /** A flag that represents either or not if the auction was delivered externally. */
  wasExternallyPickedUp?: boolean;
  /** Confirm to remove auction from auction room when changing auction ending time. */
  removeFromAuctionRoom?: boolean;
}

export interface ISellerUserStatistics {
  /** @format double */
  numSuccessfulAuctions: number;
  /** The creation date for each auction of each user it is using to generate its statistics. */
  referenceUserAuctionCreationDates?: Record<string, string[]>;
  /**
   * The ending date for each successful auction of each user it is using to generate its statistics
   * (auctions in the CLOSED_WAITING_FOR_PAYMENT, CLOSED_WAITING_FOR_PICKUP or FINISHED state).
   */
  referenceUserSuccessfulAuctionEndingDates?: Record<string, string[]>;
  /**
   * The ending date for each failed auction of each user it is using to generate its statistics
   * (auctions in the CLOSED_NO_BIDS or CLOSED_UNDER_MINIMUM_ASK state).
   */
  referenceUserFailedAuctionEndingDates?: Record<string, string[]>;
  /** @format double */
  numAllAuctions: number;
  /** @format double */
  averageRelativeMargin: number;
  /** @format double */
  totalAbsoluteMargin: number;
  /** @format double */
  averageRelativeMarginOnPurchasePrice: number;
  /** @format double */
  totalAbsoluteMarginOnPurchasePrice: number;
  /**
   * available for internal users
   * @format double
   */
  volumeSuccessfulAuctions?: number;
  /**
   * available for internal users
   * @format double
   */
  numAuctionsBelowMinAsk?: number;
}

/**
 * Inform that a new resource was created. Server will
 * add a Location header and set status to 201
 */
export interface ReturnNewResourceISellerAuctionView {
  location: string;
  /** @format double */
  statusCode: number;
  /** the body to be sent */
  body: ISellerAuctionView;
}

/**
 * Inform that the request was accepted but is not completed.
 * A Location header should inform the location where the user
 * can monitor his request processing status.
 */
export interface ReturnRequestAcceptedISellerAuctionView {
  location: string;
  /** @format double */
  statusCode: number;
  /** the body to be sent */
  body: ISellerAuctionView;
}

/** @deprecated */
export enum EIncidentType {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
  Value5 = 5,
  Value6 = 6,
  Value7 = 7,
  Value8 = 8,
}

/**
 * An auction-related incident, which is reported from sellers or buyers
 * during the course of an auction (mostly related to late payment or pickup).
 * @deprecated
 */
export interface IIncident {
  /**
   * @deprecated
   * @format double
   */
  id: number;
  uuid: string;
  /** The type of the incident (e.g. "Late payment") */
  type: EIncidentType;
  /**
   * Reporting date of the incident.
   * @format date-time
   */
  createdAt?: string;
  metaData: any;
  /** TODO */
  _fk_uuid_auction: string;
  /**
   * The auction model, which is a central element in the data model, referencing and referenced by most other
   * entities in the model.
   *
   * TODO
   */
  associatedAuction: IAuction;
  /** TODO */
  _fk_uuid_buyerUser: string;
  associatedSalesman: IBuyerUser;
  /** TODO */
  _fk_uuid_sellerUser: string;
  /**
   * The description of a seller user (i.e. user types putting vehicles for sale onto the platform).
   * Previously referred to as dealership users.
   */
  associatedDealership: ISellerUser;
}

/**
 * Inform that a new resource was created. Server will
 * add a Location header and set status to 201
 */
export interface ReturnNewResourceIIncident {
  location: string;
  /** @format double */
  statusCode: number;
  /** the body to be sent */
  body: IIncident;
}

/** Payload that contains the field to allow an auction to be reinserted. */
export interface IAuctionReinsertionPayload {
  /** @format double */
  newMinAskPrice: number;
  /** @format double */
  newStartingBidValue?: number;
  /** @format date-time */
  newEndingTime?: string;
  instantPurchase?: boolean;
}

export interface IPageISellerAuctionView {
  items: ISellerAuctionView[];
  /** @format double */
  total: number;
  /** @format double */
  page: number;
  /** @format double */
  pageSize?: number;
}

export interface ISellerSupervisorAuctionView {
  /**
   * @deprecated
   * @format double
   */
  id: number;
  uuid: string;
  /**
   * Indicating the time when the entity has been created, i.e. when the entity was
   * persisted to the database.
   * @format date-time
   */
  createdAt?: string;
  /**
   * Indicating the last time a value of the entity has been changed (updated).
   * @format date-time
   */
  updatedAt?: string;
  /**
   * Indicating the time when the entity has been (softly) deleted.
   * "null" if entry is not deleted.
   * @format date-time
   */
  deletedAt?: string;
  /** Stores which type of additional tax applies to the auction if any. */
  additionalTaxType: EAdditionalTaxType;
  /**
   * Stores the value of the additional tax that might apply to an auction.
   * @format double
   */
  additionalTaxValue: number;
  /**
   * Stores the export discount for taxes.
   * @format double
   */
  additionalTaxExportDiscount: number;
  /**
   * The state of the auction, indicating whether an auction is not yet published (DRAFTED), running (ACTIVE),
   * closed successfully (CLOSED_WAITING_FOR_PAYMENT, CLOSED_WAITING_FOR_PICKUP, FINISHED), failed (CLOSED_NO_BIDS,
   * CLOSED_UNDER_MINIMUM_ASK) or disabled (DISABLED).
   *
   * Depending on the state, different timestamps are available per auction.
   */
  state: EAuctionState;
  /**
   * The exact date+time when the auction is expiring/ending.
   * @format date-time
   */
  endingTime: string;
  /**
   * The remaining number of seconds that this auction will be in the state ACTIVE (running).
   *
   * This is a dynamic, non-persisted field, calculated on every retrieval of the auction data.
   * @format double
   */
  remainingTimeInSeconds: number;
  /**
   * The remaining number of seconds that this auction is available for instant purchase
   * (usually the first 24 hours after the auction is started).
   *
   * This is a dynamic, non-persisted field, calculated on every retrieval of the auction data.
   * @format double
   */
  remainingTimeForInstantPurchaseInSeconds: number;
  /**
   * Date+time indicating until when an auction is available for instant purchasing.
   * By default, this is 24 hours after the start of an auction.
   * @format date-time
   */
  instantPurchasePossibleUntil: string;
  _fk_uuid_highestBiddingBuyerUser: string;
  /**
   * Value that is indicating the current highest bid value on the auction.
   * This is of course a dynamic field, that will be incremented on every new bid placed on the auction.
   * @format double
   */
  currentHighestBidValue: number;
  /** @format double */
  currentHighestBidValueNet: number;
  /**
   * The minimal price the seller user wants to reach for this specific auction.
   * If an auction ends above that price, the auction is successful right away
   * (switching to state CLOSED_WAITING_FOR_PAYMENT).
   *
   * If the auction ends below the minimum required ask, the auction will switch to state CLOSED_UNDER_MINIMUM_ASK,
   * which then requires the seller's explicit "confirmation" or "rejection" of the highest bid value.
   * @format double
   */
  minimumRequiredAsk: number;
  /** @format double */
  minimumRequiredAskNet: number;
  /**
   * Number of bids placed on an auction.
   * @format double
   */
  numBids: number;
  /** @format double */
  buyerRecommendationScore?: number;
  /** Was this auction created with the help of an inspection done or commissioned by CarOnSale? */
  inspectionWasCommissionedByCos: boolean;
  /** By which type of user was this auction created. */
  creatorType: EAuctionCreatorType;
  /** UUID of the creating internal user if there is one. */
  _fk_uuid_creatingInternalUser: string;
  /** UUID of the creating seller user if there is one. */
  _fk_uuid_creatingSellerUser: string;
  /**
   * Indicates if the auction was created for testing purposes and
   * should be handled differently: it should not be ended automatically,
   * so the seller should decide would he like to sell it or not.
   */
  isTest: boolean;
  /** Indicates if minimum required ask should be displayed for buyers. */
  displayMinAsk: boolean;
  /**
   * GPS coordinates of the auction (can be used for e.g. calculating distances between auctions and buyers).
   * @format double
   */
  locationGeoLat: number;
  /** @format double */
  locationGeoLon: number;
  /** @format double */
  counterOfferByBuyer: number;
  /** @format double */
  counterOfferByBuyerNet: number;
  /** @format double */
  lastOfferBySeller: number;
  /** @format double */
  lastOfferBySellerNet: number;
  /**
   * A value denoting the previous renegotiation offer given by a buyer user.
   * @format double
   */
  previousCounterOfferByBuyer: number;
  /** @format double */
  previousCounterOfferByBuyerNet: number;
  /**
   * A value denoting the previous renegotiation offer given by a seller user.
   * This is required in order to properly display the right information during the renegotiation process.
   * @format double
   */
  previousLastOfferBySeller: number;
  /** @format double */
  previousLastOfferBySellerNet: number;
  /** A flag for if the seller user connected to the auction has decided not to participate in the renegotiation any further. */
  renegotiationStoppedBySeller: boolean;
  /** A flag for if the highest bidding buyer user connected to the auction has decided not to participate in the renegotiation any further. */
  renegotiationStoppedByBuyer: boolean;
  /** A fag indicating if the buyer's withdrawal from the renegotiation process was done automatically or not. */
  wasRenegotiationStoppageByBuyerAutomatic: boolean;
  /** A fag indicating if the seller's withdrawal from the renegotiation process was done automatically or not. */
  wasRenegotiationStoppageBySellerAutomatic: boolean;
  /**
   * The total number of offer rounds a buyer has participated in for an auction during the renegotiation phase.
   * @format double
   */
  numBuyerRenegotiationOfferRounds: number;
  /**
   * The total number of offer rounds a seller has participated in for an auction during the renegotiation phase.
   * @format double
   */
  numSellerRenegotiationOfferRounds: number;
  /**
   * The calculated renegotiation midpoint value of the auction.
   * This is used once the renegotiation offers get too close for the offer process to continue.
   * This value will be undefined unless the auction is ready to be purchased for such a value.
   * @format double
   */
  renegotiationMidpointValue: number;
  /** @format double */
  renegotiationMidpointValueNet: number;
  /**
   * Indicates that the highest bidding buyer user for an auction in renegotiation is willing to accept the midpoint of their offer and
   * the offer of the seller for the same auction. This is used when the renegotiation offers get very close during the renegotiation process.
   */
  buyerAcceptedRenegotiationMidpoint: boolean;
  /**
   * Indicates that the seller of an auction is willing to accept the midpoint of their offer and the offer of the highest bidding
   * buyer user for the same auction. This is used when the renegotiation offers get very close during the renegotiation process.
   */
  sellerAcceptedRenegotiationMidpoint: boolean;
  /**
   * This is the datetime object denoting when the most recent renegotiation round concluded at.
   * @format date-time
   */
  lastRenegotiationRoundEndedAt: string;
  /**
   * The number of seconds until the current renegotiation round times out.
   * @format double
   */
  numSecondsUntilRenegotiationTimeout: number;
  /**
   * The UUID of the external case tracking the renegotiation of the auction.
   * We track this to enable case updates as the auction moves through renegotiation.
   */
  externalRenegotiationTicketUuid: string;
  /**
   * The calculated renegotiation fair value of the auction.
   * This is used once the renegotiation starts based on the auction GPR or MPR so we know
   * the fair value that both seller and buyer should agree upon.
   * @format double
   */
  renegotiationFairValue: number;
  /**
   * Value that is indicating the highest bid value on the auction when it ended.
   * This value should not change for a given auction since it supposed to be a historical record.
   * @format double
   */
  highestBidValueAtEndingTime: number;
  /** @format double */
  highestBidValueAtEndingTimeNet: number;
  /** Field data related to the renegotiation round process. */
  renegotiationRoundData?: IRenegotiationRoundData;
  /**
   * Indicates when manual renegotiation was finished via Salesforce.
   * @format date-time
   */
  finishedManuallyAt?: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing service (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the transfer of funds
   * for the vat deposit from COS to the deposit account (only relevant for cross border transactions).
   * This deposit is refunded by COS once the buyer provides the necessary documentation for a cross border transaction.
   */
  thirdPartyVATDepositTransferReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing service (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the transfer of funds
   * for the additional tax deposit from COS to the deposit account (only relevant for cross border transactions).
   * This deposit is refunded by COS once the buyer provides the necessary documentation for a cross border transaction.
   */
  thirdPartyAdditionalTaxDepositTransferReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing system (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the transfer of funds
   * for the auction additional taxes (e.g country specific luxury taxes): from COS to the seller.
   */
  thirdPartyAdditionalTaxTransferReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing service (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the transfer of funds
   * for the VAT: from COS to the seller (only relevant for cross border transactions for now).
   * COS is advancing the vat amount to the seller.
   */
  thirdPartyVatTransferReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing service (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the charge of funds
   * for the auction volume from the buyer to COS.
   */
  thirdPartyVolumeChargeReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing service (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the charge of funds
   * for the auction deposit from the buyer to COS (only relevant for cross border transactions for now).
   * This deposit is refunded by COS once the buyer provides the necessary documentation for a cross border transaction.
   */
  thirdPartyVATDepositChargeReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing service (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the charge of funds
   * for the auction additional tax (e.g country specific luxury tax) from the buyer to COS.
   */
  thirdPartyAdditionalTaxChargeReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing system (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the refund of an
   * additional tax deposit (only used for cross-border transactions currently).
   */
  thirdPartyAdditionalTaxRefundReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing system (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the payout of funds.
   */
  thirdPartyPayoutReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing system (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the refund of a
   * deposit (only used for cross-border transactions currently).
   */
  thirdPartyVATDepositRefundReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing system (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the refund of a
   * transfer to the deposit account owned by cos (only used for cross-border transactions currently).
   */
  thirdPartyVATDepositReversalReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing system (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the refund of the
   * additional tax transfer to the deposit account owned by cos (only used for cross-border transactions).
   */
  thirdPartyAdditionalTaxDepositReversalReference: string;
  /**
   * Reference to the invoice for the success fee issued to the seller for a sold vehicle (see ESellerFeeInvoicingMode.INVOICE_ON_SUCCESS).
   * The reference identifies the invoice issued to the seller in an external invoicing system (e.g. Stripe).
   *
   * This fee is not always charged, therefore this reference is optional (can be null).
   */
  sellerSuccessFeeInvoiceReference: string;
  /**
   * Reference to the invoice for the listing fee issued to the seller for a listed vehicle (see ESellerFeeInvoicingMode.INVOICE_ON_LISTING).
   * The reference identifies the invoice issued to the seller in an external invoicing system (e.g. Stripe).
   *
   * This fee is not always charged, therefore this reference is optional (can be null).
   */
  sellerListingFeeInvoiceReference: string;
  /**
   * Reference to the invoice for the standing fee issued to the buyer for a purchased vehicle picked up too late.
   *
   * This fee is not always charged, therefore this reference is optional (can be null).
   */
  buyerStandingFeeInvoiceReference: string;
  /**
   * Reference to a resource in an external invoicing system (such as Stripe).
   * References our own transaction fee invoice.
   */
  invoiceReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing system (such as Stripe).
   * This is only non-null, if external payment is allowed for this auction.
   */
  thirdPartyInvoiceReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing system.
   * This is only non-null, if external payment is allowed for this auction and it had to be cancelled.
   */
  thirdPartyCorrectionInvoiceReference: string;
  /**
   * This is a reference (e.g. primary key) to a resource in an external invoicing system (such as Stripe).
   * If this is not null, it should reference an external resource, containing information about the transfer of funds
   * for the auction volume: from COS to the seller.
   */
  thirdPartyTransferReference: string;
  /**
   * Reference of an invoice on an external invoicing system.
   * This is invoice is used to charge booked services (like guarantee) after the auction expires.
   */
  bookedServicesInvoiceReference: string;
  /**
   * Date-time when the auction was reauctioned and is now waiting for buyer payment until the new auction gets paid.
   * @format date-time
   */
  reauctionedWaitingForBuyerPaymentAt: string;
  /**
   * Date-time when the first reauction of this auction has expired.
   * We need to keep track of this so we can block the original buyer of seeing the original auction.
   * @format date-time
   */
  firstReauctionExpiredAt: string;
  /** Flag that indicates if this auction is a clonned reauction. */
  isReauction: boolean;
  /**
   * The highest bid value that we show to the seller when we merge the reauction with the original auction.
   * This is done so the seller see the amount that cos pay them and the buyer sees the amount that they paid cos.
   * @format double
   */
  sellerViewHighestBidValue: number;
  /**
   * The purchase confirmed at that we show to the seller when we merge the reauction with the original auction.
   * This is done so the seller see the date when the original auction was confirmed and the buyer sees the date that the reauction was confirmed.
   * @format date-time
   */
  sellerViewPurchaseConfirmedAt: string;
  /**
   * Date-time when the reauction was merged back at the original auction.
   * @format date-time
   */
  reauctionedMergedAt: string;
  /** Indicates wherever an auction was purchase using the Buy Now Pay Later feature. */
  wasPurchasedUsingBuyNowPayLater: boolean;
  /**
   * The timestamp at which the buyer has confirmed his choice to use Buy Now Pay Later for the auction.
   * @format date-time
   */
  buyNowPayLaterConfirmedAt: string;
  /** The IP address which confirmed the Buy Now Pay Later choice for the auction. */
  buyNowPayLaterConfirmationIPAddress: string;
  /** Reference to the Buy Now Pay Later Order on the PNI Service. */
  buyNowPayLaterOrderReference: string;
  /** Reference of the invoice for buy now pay later provider fee on an external invoicing system. */
  buyNowPayLaterInvoiceReference: string;
  /** The payment link reference */
  paymentLinkReference: string;
  /** Reference to the payment order on the P&I service */
  paymentOrderReference: string;
  isInternalFulfillment: boolean;
  belowMinAskBehaviour: EAuctionBelowMinAskBehaviour;
  /** The label of the auction, consisting of the vehicle make, model and date of first registration (EZ). */
  label: string;
  /**
   * Date+time when the auction is started.
   * When an auction is started right away (without drafting), the "createdAt" and "startedAt" should have
   * (roughly) the same values.
   * @format date-time
   */
  startedAt: string;
  /**
   * Date+time when the auction is "confirmed to be sold" by the seller (or by an admin).
   * If an auction is ending equal or above the minimum required ask, the "purchaseConfirmedAt"
   * will automatically be set and will be equal to the "endingTime".
   *
   * If an auction is ending below the minimum ask (CLOSED_UNDER_MINIMUM_ASK), the "purchaseConfirmedAt" timestamp
   * is only set if the auction is then manually "confirmed" by the seller or an admin.
   *
   * If an auction is never "successful", the "purchaseConfirmedAt" will remain "null".
   *
   * This value was introduced in the course of 2019. For all previous auctions, the endingTime was set
   * as the "purchaseConfirmedAt" retrospectively.
   * @format date-time
   */
  purchaseConfirmedAt: string;
  /**
   * Date-time when the auction has definitely been rejected by a seller or an admin, after the auction
   * ended below minimum required ask.
   *
   * This value is only available since 2019/07/29. All auctions rejected before that date have the value "null".
   * @format date-time
   */
  purchaseRejectedAt: string;
  /** Indicates the reason that an auction failed the renegotiation process. */
  rejectionReason: EAuctionRejectionReason;
  /**
   * Elaborates on the rejection reason to provide further context to why an
   * auction was rejected during the renegotiation process.
   */
  rejectionReasonNote: string;
  /**
   * The name of the competitor that the auction was sold to when the reject chosen reason was
   * VEHICLE_SOLD_ON_DIFFERENT_PLATFORM
   */
  rejectionReasonCompetitorName: string;
  /** In case if there was a direct upload requested but didn't succeed the reason should be stored. */
  directUploadRejectionReasons: EAuctionDirectUploadRejectionReason[];
  /**
   * Value that is indicating initial bid value auction should be started with.
   * @format double
   */
  startingBidValue: number;
  /** @format double */
  startingBidValueNet: number;
  /**
   * Value that can be manually set in case edge-cases apply and the payout amount is disconnected from the auction current highest bid.
   * This value can only be adjusted by internal users.
   * @format double
   */
  overridePayoutValue: number;
  /**
   * If an auction is ending below the minimum required ask, the "originalMinimumRequiredAsk" will be set to the
   * "minimumRequiredAsk" value before it is set to "currentHighestBidValue".
   * @format double
   */
  originalMinimumRequiredAsk: number;
  /** @format double */
  originalMinimumRequiredAskNet: number;
  /**
   * The purchase price for the vehicle that is auctioned.
   * This is set manually by the seller, and is mostly used to analyze the "real" margin that a seller
   * makes with an auction.
   * @format double
   */
  purchasePrice: number;
  /** @format double */
  purchasePriceNet: number;
  /**
   * The full object of the vehicle that is on auction.
   * This is augmented on every auction retrieval, based on _fk_uuid_vehicle.
   */
  associatedVehicle: IVehicle;
  /** Indicates whether the auction process is rated by the SellerUser. */
  isRatedByDealership: boolean;
  /** Indicates whether the auction process is rated by the BuyerUser. */
  isRatedByBuyer: boolean;
  /**
   * Indicates whether the BuyerUser has marked this auction as paid (payment of the auction volume).
   * This is done manually by the BuyerUser, so this value is not indicating that the actual payment has really
   * reached the seller.
   */
  isPaidByBuyer: boolean;
  /**
   * Timestamp when the BuyerUser has marked this auction as "paid" (i.e. set the "isPaidByBuyer").
   * @format date-time
   */
  outgoingPaymentConfirmedAt: string;
  /**
   * Timestamp when the SellerUser has confirmed that the payment has reached the seller bank account.
   * @format date-time
   */
  incomingPaymentConfirmedAt: string;
  /**
   * Timestamp when the auction state has changed from "CLOSED_WAITING_FOR_PAYMENT" to "CLOSED_WAITING_FOR_PICKUP".
   * @format date-time
   */
  pickupConfirmedAt: string;
  /** Physical address of where the vehicle on auction is located (a.k.a. pickup address). */
  locationCountryCode: ECountryCode;
  locationAddress: string;
  locationCity: string;
  locationZip: string;
  /** Physical address of where the vehicle on auction was inspected (a.k.a. inspection address). */
  inspectionLocationCountryCode: ECountryCode;
  inspectionLocationAddress: string;
  inspectionLocationCity: string;
  inspectionLocationZip: string;
  /**
   * Indicating to which bank account the BuyerUser has to transfer the auction volume to.
   * @deprecated
   */
  sellerIban: string;
  /**
   * URL to the seller invoice for the given auction, manually uploaded by the SellerUser, after
   * the auction has ended successfully.
   */
  urlToInvoice: string;
  /** URL for the cancelled invoice in case it was cancelled. */
  urlToCorrectionInvoice: string;
  /**
   * Flag indicating whether an auction has an "hot bid" phase in the last 20 seconds before expiration.
   * @deprecated
   */
  hotBid: boolean;
  /**
   * Seller's instant purchase price for the vehicle.
   * For the first 24 hours after the auction is started, the vehicle will only be available in "instant purchase"
   * mode (bidding is not allowed in that time, only purchasing right away).
   *
   * After 24 hours, if nobody purchased the vehicle, it is going on auction, just like all other auctions.
   * @format double
   */
  instantPurchasePrice: number;
  /** @format double */
  instantPurchasePriceNet: number;
  /**
   * Flag indicating that instant purchase is enabled for that auction.
   * (Implies that "instantPurchasePrice" is set)
   */
  allowInstantPurchase: boolean;
  /**
   * Flag indicating that an auction "ended" with an instant purchase, resp.
   * did never go on auction.
   */
  didEndWithInstantPurchase: boolean;
  /**
   * Internal counter, incrementing on every "restart" of an auction.
   * Starts with 0.
   *
   * e.g. count of 2 means that the auction has been restarted 2 times, leading to 3 auction objects in the
   * database.
   * @format double
   */
  auctioningIterations: number;
  /**
   * Indicating the strategic priority of an auction for CarOnSale.de
   * The higher the number, the more "relevant" the auction is.
   * @format double
   */
  priority: number;
  /** @deprecated */
  advertisementHtmlContent: string;
  /** @deprecated */
  buyerComplaint: EAuctionBuyerComplaint;
  /**
   * Custom field that is not used internally.
   * Allows sellers to store e.g. a reference ID to their own vehicle management system.
   */
  internalReferenceText: string;
  _fk_uuid_vehicle: string;
  _fk_uuid_sellerUser: string;
  /** This is a URL that redirects to an external invoice payment site (such as provided by Stripe). */
  urlToPaymentSite: string;
  /**
   * A flag indicating if an auction needs to be reviewed by an internal review agent (for quality assurance)
   * If true, an auction is currently under review by CarOnSale, and cannot be edited by the owning seller user.
   */
  needsReview: boolean;
  /** The reasons why an auction was rejected during the review. */
  reviewReasons: EAuctionReviewReason[];
  /**
   * Reasons why an auction is rejected during the review process ("Parkhaus").
   *
   * This reason is automatically set to UNKNOWN for all auctions and is then manually adjusted by
   * an internal review agent (in case the auction gets rejected) before it is sent back to the responsible
   * SellerUser.
   * @deprecated
   */
  reviewReason: EAuctionReviewReason;
  /** Specifies reason when "reviewReasons" contains EAuctionReviewReason.OTHER. */
  reviewReasonDetails: string;
  /** The comment given by an internal review agent on rejecting an auction under review. */
  reviewComment: string;
  /** Flag indicating if the auction can be published without review. */
  canSkipReview?: boolean;
  /**
   * Random PIN code that is generated when an auction is ready to be picked up or in advance before COSCheck inspection.
   * This PIN code is printed out on the hangar in the vehicle or handed to the seller of the auction, which can then forward it to
   * the buyer to confirm the pickup.
   */
  pickupPinCode: string;
  /**
   * Random UUID that is used to confirm vehicle pickup via scanning QR code.
   * It will be printed on the hangar or in the pickup document available for sellers.
   */
  pickupPinUuid: string;
  /**
   * Timestamp when PIN code was entered by the buyer.
   * Allows to determine how many auctions were self-picked up.
   * @format date-time
   */
  pickupPinCodeEnteredAt: string;
  /**
   * Timestamp when PIN UUID was entered by the buyer via scanning QR code.
   * Allows to determine how many auctions were self-picked up.
   * @format date-time
   */
  pickupPinUuidEnteredAt: string;
  /** Flag is indicating if pickup was confirmed with PIN using new process (via pin.caronsale.de) */
  pickupConfirmedWithNewPin: boolean;
  /** URL to the pickup information document for the BuyerUser. */
  urlToPickupBuyerDocument: string;
  /** URL to the pickup information document for the SellerUser (containing the "pickupPinCode"). */
  urlToPickupSellerDocument: string;
  /** URL to the success report document for the SellerUser. */
  urlToSellerSuccessReport: string;
  /** The payment process that is used to pay money for the purchase volume (auction volume). */
  paymentProcess: EAuctionPaymentProcess;
  /**
   * Referencing the bank account to which the payment for the purchase volume should be paid out.
   * This is null, if the paymentProcess === "CLASSIC".
   *
   * It is only used, when external payment (COS Payment) is enabled.
   */
  _fk_uuid_associatedBankAccount: string;
  /**
   * Indicates how often an auction has been reviewed.
   * This is incremented every time an auction is rejected after a review.
   * @format double
   */
  draftReviewIterations: number;
  /**
   * Timestamp when an admin resets a time window for a possibility to complain
   * @format date-time
   */
  complaintTimeWindowExtendedAt: string;
  /**
   * Flag indicates if this auction is suspected to have been paid in cash (only for auctions that use the external
   * invoice process), this means that no corresponding transfer exists on Stripe and the buyer confirmed the pickup
   * through the seller pin code.
   */
  isSuspectedCashPayment: boolean;
  /**
   * Timestamp when the auction was sent to review.
   * @format date-time
   */
  reviewStartedAt: string;
  /**
   * Timestamp when the auction QA process actually started (i.e. when Parkhaus started reviewing the auction).
   * @format date-time
   */
  qaStartedAt: string;
  /**
   * Timestamp when the auction was ready to be reinserted (a.k.a. restarted).
   * @format date-time
   */
  readyToBeReinsertedSince: string;
  /** The reference to the original auction from which current one was cloned. */
  _fk_uuid_clonedFrom: string;
  /** Auction type. */
  type: EAuctionType;
  /** Indicates whether the rejection wait period for this auction is over and thus can be rejected by the seller. */
  isRejectionWaitPeriodOver: boolean;
  /** Reference to invoice object for the external Billomat invoicing provider. */
  thirdPartyVehiclePurchaseInvoiceReference: string;
  /**
   * URL to the seller made invoice invoicing CoS for the given auction, manually uploaded to the crm by an internal user, after
   * the auction has ended successfully.
   */
  sellerToCosInvoiceUrl: string;
  /**
   * Timestamp for when the sellerToCosInvoiceUrl was last updated.
   * @format date-time
   */
  sellerToCosInvoiceUrlLastUpdatedAt: string;
  /**
   * Timestamp for when the sellerToCosInvoiceUrl is first uploaded.
   * @format date-time
   */
  sellerToCosInvoiceUrlFirstUploadedAt: string;
  /** Flag indicating wherever the uploaded seller to cos invoice was verified by an internal user. */
  sellerToCosInvoiceIsValid: boolean;
  /** The comment given by an internal user on the provided seller to cos invoice. */
  sellerToCosInvoiceComment: string;
  /** Shows if auction is marked as 'live', so, it will be slotted during starting and expire in less than 24 hours. */
  isLive: boolean;
  /** Shows if auction was already slotted via the slotting algorithm. */
  isSlotted: boolean;
  /**
   * The exact date+time when the auction was slotted to end.
   * @format date-time
   */
  slottedEndingTime: string;
  /** Indicating if transportation should be disabled manually. */
  isTransportationDisabledManually: boolean;
  /** Optional pickup instructions that will be communicated to the buyer. */
  pickupInstructions: string;
  /** Indicating wherever the seller should be charged a success/listing fee. */
  preventSellerFactoring: boolean;
  /** Indicating if buyer success fee should be applied or not. */
  applyBuyerSuccessFee: boolean;
  /** Info from document shipping service about the order, like status and links to the documents. */
  documentShippingOrder: IAuctionDocumentShippingOrder;
  /** Info about un-/confirmed auction data points. */
  fieldsConfirmationStatus: IAuctionFieldConfirmationStatusMap;
  /**
   * Whether the "Value Added Tax is reportable", a special tax condition in the EU.
   * If this is true, it means that the vehicle is "Regelbesteuert", meaning that the invoice for the vehicle
   * (either invoiced directly from seller to buyer - or from COS Payment to buyer) needs to include
   * the VAT (in Germany e.g. 19%) in the invoice.
   *
   * If this is false, the invoice does not need to have explicit VAT.
   */
  isVATReportable: boolean;
  /** Indicates how the auction was uploaded to the marketplace. */
  uploadMethod: EAuctionUploadMethod;
  /** Prebooked services available for the auction, like transport, warranty. */
  prebookedServices: IPrebookedService[];
  /** Indicates if cosCheckPlus guarantee can be booked for this auction. */
  isCosCheckPlusGuaranteeEnabled: boolean;
  /** Indicates if extended guarantee can be booked for this auction. */
  isExtendedGuaranteeEnabled: boolean;
  /** URL to the auction vehicle cover letter for sellers */
  urlToCoverLetter: string;
  /**
   * Timestamp when the automatic pickup approval was requested for auction
   * @format date-time
   */
  autoPickupOnPaymentRequestedAt: string;
  /**
   * This means that the auctions is using complimentary vehicle feature.
   *
   * Note: This is marked as true when the seller uses one of his complimentary vehicles to pay for the fees.
   */
  isSellerFeeComplimentary: boolean;
  /** Flag when standing fee is enabled to the auction and we'll need to extra charge a buyer as a penalty. */
  isStandingFeeEnabled: boolean;
  _fk_uuid_auctionRoom: string;
  /**
   * An auction can have a list of fulfillment criteria that impact how the
   * auction will be fulfilled depending on the buyer that wins it.
   */
  fulfillmentCriteria?: IAuctionFulfillmentCriteria[];
  /**
   * The auction marked with this flag won't start automatically after review.
   *
   * Instead, it will transition to state: READY_TO_BE_PUBLISHED.
   *
   * Default: false
   */
  shouldWaitToBePublishedAfterReview: boolean;
  /** A flag that marks if an auction is a selective ware or not. */
  isSelectiveWare: boolean;
  /** The id for the look and feel applied to the auction. */
  lookAndFeelId: EAuctionLookAndFeelId;
  /** A flag that marks if an auction will use the pickup qr code. */
  isQRCodeAvailable: boolean;
  /**
   * Indicates whether the highest bid should be transferred if this auction gets cloned.
   * Defaults to `false` if not specified.
   */
  shouldTransferHighestBid?: boolean;
  /**
   * Indicates the amount of VAT tax that applies to the auction at current highest bid.
   * @format double
   */
  vatAmount: number;
  /**
   * VAT rate that applies to this auction.
   * @format double
   */
  vatRate: number;
  /** The rating of the seller to the buyer of the auction. */
  rating: IRating;
  /** Contact to the buyer of the auction. */
  buyerContact: IGeneralUser;
  /**
   * The transportation task associated with the auction.
   * Null if no transportation is booked.
   */
  transportationTask: ITransportationTask;
  /** Payment state of the auction. */
  paymentState: IAuctionPaymentState;
  /**
   * The bank account to which the payment should be paid out.
   *
   * "null", if payment process is classic.
   */
  targetSellerBankAccount: ISellerBankAccount;
  /**
   * Transporter company name
   * Null if no transportation is booked
   */
  transportationProviderCompanyName: string;
  /**
   * Number of times the auction has been parked by buyers.
   * @format double
   */
  timesParked: number;
  /**
   * Number of unique bidders.
   * @format double
   */
  uniqueBidders: number;
  /**
   * Number of views per auction.
   * @format double
   */
  views: number;
  /** SellerUser view augmented with its basic Seller Account data */
  owningDealership: ISellerUserWithAccountBasicView;
}

export interface IPageISellerSupervisorAuctionView {
  items: ISellerSupervisorAuctionView[];
  /** @format double */
  total: number;
  /** @format double */
  page: number;
  /** @format double */
  pageSize?: number;
}

export interface ICognitoUserLastLoginInfo {
  ipAddress: string;
  deviceName: string;
  city: string;
  country: string;
  hasTwoFactorAuth?: boolean;
}

export enum EUserRole {
  Buyers = 'buyers',
  Sellers = 'sellers',
  SellersSupervisors = 'sellersSupervisors',
  InternalUsers = 'internalUsers',
  Sysadmins = 'sysadmins',
  TransportationProviders = 'transportationProviders',
  CargoCustomers = 'cargoCustomers',
  ClientApplication = 'clientApplication',
  Inspectors = 'inspectors',
}

export enum EAuthenticationChallenge {
  NEW_PASSWORD_REQUIRED = 'NEW_PASSWORD_REQUIRED',
  CONFIRM_SIGN_IN_WITH_TOTP_CODE = 'CONFIRM_SIGN_IN_WITH_TOTP_CODE',
}

export interface Error {
  name: string;
  message: string;
  stack?: string;
}

export interface IAuthenticationResult {
  authenticated: boolean;
  userId: string;
  /** @format double */
  internalUserId: number;
  internalUserUUID: string;
  token: string;
  type: EUserType;
  privileges: string;
  userRole?: EUserRole;
  authenticationChallenge?: EAuthenticationChallenge;
  authenticationError?: Error;
}

/**
 * Inform that a new resource was created. Server will
 * add a Location header and set status to 201
 */
export interface ReturnNewResourceIAuthenticationResult {
  location: string;
  /** @format double */
  statusCode: number;
  /** the body to be sent */
  body: IAuthenticationResult;
}

export interface IAuthenticationRequest {
  password: string;
  meta: string;
}

export interface UpdateNotificationConfigDTO {
  /** The status of the notification config */
  isActive: boolean;
  /** The email receivers of the notification config */
  emailReceivers: string[];
  /** The sms receivers of the notification config */
  smsReceivers: string[];
  /** The push receivers of the notification config */
  pushReceivers: string[];
}

export interface UpdateNotificationPreferencesDTO {
  /**
   * Notification configurations, keyed by predefined message names
   * @example {"WELCOME":{},"PASSWORD_RESET":{}}
   */
  config: {
    SELLER_AUCTION_SUCCESSFUL_CLASSIC_INVOICING?: UpdateNotificationConfigDTO;
    INTERNAL_AUCTION_CREATED_BY_INSPECTOR?: UpdateNotificationConfigDTO;
    SELLER_INSTANT_PURCHASE_AUCTION_UNSUCCESSFUL?: UpdateNotificationConfigDTO;
    SELLER_AUCTION_REVIEW_REJECTED?: UpdateNotificationConfigDTO;
    SELLER_AUCTION_DRAFT_REVIEW?: UpdateNotificationConfigDTO;
    BUYER_AUCTION_SUCCESSFUL?: UpdateNotificationConfigDTO;
    BUYER_AUCTION_SUCCESSFUL_WITH_EXTERNAL_FULFILLMENT?: UpdateNotificationConfigDTO;
    BUYER_AUCTION_BNPL_APPROVED?: UpdateNotificationConfigDTO;
    SELLER_AUCTION_READY_TO_REINSERT_TO_BE_DISABLED?: UpdateNotificationConfigDTO;
    BUYER_AUCTION_PAYMENT_TRIGGERED_EXPRESS_PICKUP_INFO?: UpdateNotificationConfigDTO;
    BUYER_OUTBID_ON_AUCTION?: UpdateNotificationConfigDTO;
    SELLER_SHIPPING_ORDER_SHIP_DOCUMENTS_WITH_EXPRESS_PICKUP_WITHOUT_DOCUMENT_SHIPPING?: UpdateNotificationConfigDTO;
    BUYER_AUCTION_RENEGOTIATION_TIMEOUT?: UpdateNotificationConfigDTO;
    SELLER_PAYOUT_SUCCESSFUL_WITH_EXPRESS_PICKUP?: UpdateNotificationConfigDTO;
    SELLER_AUCTION_SUCCESSFUL_COS_NO_INVOICING_WITHOUT_DOCUMENT_SHIPPING?: UpdateNotificationConfigDTO;
    INTERNAL_INSPECTOR_REQUESTED_FOR_GPR_BY_SELLER?: UpdateNotificationConfigDTO;
    BUYER_EXPIRING_PARKED_AUCTIONS?: UpdateNotificationConfigDTO;
    SELLER_AUCTION_ENDED_BELOW_MINIMUM_ASK?: UpdateNotificationConfigDTO;
    BUYER_ADDED_TO_SELLER_REGULAR_BUYERS?: UpdateNotificationConfigDTO;
    SELLER_AUCTION_SUCCESSFUL_COS_INVOICING_BUYER_WITHOUT_DOCUMENT_SHIPPING?: UpdateNotificationConfigDTO;
    SELLER_AUCTION_LISTING_REPORT?: UpdateNotificationConfigDTO;
    SELLER_AUCTION_STARTING_PAYMENT_REMINDER_PROCESS?: UpdateNotificationConfigDTO;
    BUYER_TEST_ACCOUNT_CREATED_NO_PASSWORD?: UpdateNotificationConfigDTO;
    BUYER_SIGNUP_CONFIRMATION_REMINDER?: UpdateNotificationConfigDTO;
    BUYER_SEND_CONFIRMATION_OF_ARRIVAL_DOCUMENT?: UpdateNotificationConfigDTO;
    SELLER_AUCTION_RENEGOTIATION_FINAL_ROUND_ENDED?: UpdateNotificationConfigDTO;
    BUYER_AUCTION_BNPL_DECLINED?: UpdateNotificationConfigDTO;
    SELLER_SHIPPING_ORDER_SHIP_DOCUMENTS_WITH_EXPRESS_PICKUP?: UpdateNotificationConfigDTO;
    TRADE_IN_VALUATION?: UpdateNotificationConfigDTO;
    SELLER_AUCTION_RENEGOTIATION_TIMEOUT?: UpdateNotificationConfigDTO;
    INTERNAL_INSPECTOR_REQUEST_CHANGED_BY_USER?: UpdateNotificationConfigDTO;
    BUYER_AUCTION_SAVED_SEARCH_NEW_RESULTS?: UpdateNotificationConfigDTO;
    BUYER_AUCTION_5_DAY_PAYMENT_REMINDER?: UpdateNotificationConfigDTO;
    SELLER_CONDITIONAL_PAYOUTS_NO_INVOICE_REMINDER?: UpdateNotificationConfigDTO;
    DEMO_REQUESTED_SELLER?: UpdateNotificationConfigDTO;
    BUYER_SIGNUP_CONFIRMATION?: UpdateNotificationConfigDTO;
    INTERNAL_INSPECTOR_REQUESTED_BY_USER?: UpdateNotificationConfigDTO;
    SELLER_AUCTION_RENEGOTIATION_INTERMEDIATE_ROUND_ENDED?: UpdateNotificationConfigDTO;
    SELLER_GUARANTEED_PRICE_REQUEST_REJECTED?: UpdateNotificationConfigDTO;
    BUYER_AUCTION_REJECTED_BELOW_MINIMUM_ASK?: UpdateNotificationConfigDTO;
    SELLER_INSPECTOR_REQUESTED?: UpdateNotificationConfigDTO;
    PREREGISTERED_USER_AUCTION_SAVED_SEARCH_NEW_RESULTS?: UpdateNotificationConfigDTO;
    BUYER_AUCTION_PAYMENT_TRIGGERED_INFO?: UpdateNotificationConfigDTO;
    BUYER_AUCTION_BNPL_ACCEPTED?: UpdateNotificationConfigDTO;
    SELLER_AUCTION_SUCCESSFUL_COS_INVOICING?: UpdateNotificationConfigDTO;
    BUYER_BNPL_PAYMENT_RECEIVED?: UpdateNotificationConfigDTO;
    BUYER_RECOMMENDED_AUCTIONS?: UpdateNotificationConfigDTO;
    BUYER_LOST_AUCTION?: UpdateNotificationConfigDTO;
    BUYER_AUCTION_RENEGOTIATION_INTERMEDIATE_ROUND_ENDED?: UpdateNotificationConfigDTO;
    SELLER_AUCTION_SUCCESSFUL_COS_INVOICING_BUYER?: UpdateNotificationConfigDTO;
    DEALERSHIP_NEWSLETTER?: UpdateNotificationConfigDTO;
    SELLER_INCOMING_TRADE_IN_APPOINTMENT_WITHOUT_VALUATION?: UpdateNotificationConfigDTO;
    BUYER_AUCTION_SUCCESSUL_CLASSIC_INVOICE_WITHOUT_DOCUMENT_SHIPPING?: UpdateNotificationConfigDTO;
    BUYER_AUCTION_LAST_OFFER_SET?: UpdateNotificationConfigDTO;
    SELLER_TRANSFER_VEHICLE_OWNERSHIP_REMINDER?: UpdateNotificationConfigDTO;
    DEMO_REQUESTED_SELLER_INTERNAL?: UpdateNotificationConfigDTO;
    BUYER_TRANSPORTATION_TASK_UPDATE?: UpdateNotificationConfigDTO;
    SELLER_TRANSPORTATION_TASK_BOOKED?: UpdateNotificationConfigDTO;
    SELLER_SURCHARGE_FEE_REMINDER?: UpdateNotificationConfigDTO;
    BUYER_TRANSPORTATION_TASK_BOOKED?: UpdateNotificationConfigDTO;
    SELLER_INCOMING_TRADE_IN_APPOINTMENT_WITH_VALUATION?: UpdateNotificationConfigDTO;
    SELLER_SHIPPING_ORDER_SHIP_DOCUMENTS?: UpdateNotificationConfigDTO;
    SELLER_AUCTION_REVIEW_APPROVED?: UpdateNotificationConfigDTO;
    BUYER_AUCTION_PAYMENT_TRIGGERED_INVOICE_DOWNLOAD_INFO?: UpdateNotificationConfigDTO;
    BUYER_AUCTION_SUCCESSUL_CLASSIC_INVOICE?: UpdateNotificationConfigDTO;
    INTERNAL_AUCTION_RENEGOTIATION_TIMEOUT_FOR_SELLER?: UpdateNotificationConfigDTO;
    BUYER_PICKUP_REMINDER_2?: UpdateNotificationConfigDTO;
    SELLER_PREREGISTERED_ACCOUNT_CREATED_NO_PASSWORD?: UpdateNotificationConfigDTO;
    SELLER_SHIPPING_ORDER_RECEIVED_DOCUMENTS?: UpdateNotificationConfigDTO;
    SELLER_GUARANTEED_PRICE_REQUEST_BOOKED?: UpdateNotificationConfigDTO;
    BUYER_AUCTION_END_NON_EXCLUSIVE?: UpdateNotificationConfigDTO;
    BUYER_AUCTION_2_DAY_PAYMENT_REMINDER_WITH_COSPAY?: UpdateNotificationConfigDTO;
    SELLER_PAYOUT_SUCCESSFUL_WITHOUT_DOCUMENT_SHIPPING?: UpdateNotificationConfigDTO;
    BUYER_AUCTION_NOT_SUCCESSFUL?: UpdateNotificationConfigDTO;
    SELLER_SHIPPING_ORDER_DOCUMENTS_MISSING?: UpdateNotificationConfigDTO;
    BUYER_FINAL_WELCOME_TO_COS?: UpdateNotificationConfigDTO;
    SELLER_SHIPPING_ORDER_SHIP_DOCUMENTS_WITHOUT_DOCUMENT_SHIPPING?: UpdateNotificationConfigDTO;
    BUYER_AUCTION_ENTERING_24_HOUR_INSTANT_PURCHASE?: UpdateNotificationConfigDTO;
    BUYER_TRANSPORTATION_TASK_VEHICLE_PICKED_UP?: UpdateNotificationConfigDTO;
    SELLER_AUCTION_READY_TO_REINSERT_HALFWAY_THROUGH_PERIOD?: UpdateNotificationConfigDTO;
    INTERNAL_AUCTION_RENEGOTIATION_TIMEOUT_FOR_BUYER?: UpdateNotificationConfigDTO;
    BUYER_SIGNUP_DUPLICATE?: UpdateNotificationConfigDTO;
    BUYER_SHIPPING_ORDER_IN_TRANSIT?: UpdateNotificationConfigDTO;
    BUYER_SHIPPING_ORDER_DOCUMENTS_SCANNED?: UpdateNotificationConfigDTO;
    BUYER_AUCTION_PAYMENT_TRIGGERED_EXPRESS_PICKUP_INVOICE_DOWNLOAD_INFO?: UpdateNotificationConfigDTO;
    BUYER_AUCTION_RENEGOTIATION_FINAL_ROUND_ENDED?: UpdateNotificationConfigDTO;
    BUYER_BNPL_ACCESS_ENABLED?: UpdateNotificationConfigDTO;
    BUYER_AUCTION_PAYMENT_CONFIRMATION_WITH_COMPOUND?: UpdateNotificationConfigDTO;
    SELLER_GUARANTEED_PRICE_REQUEST_FULFILLED?: UpdateNotificationConfigDTO;
    SELLER_AUCTION_SUCCESSFUL_COS_NO_INVOICING?: UpdateNotificationConfigDTO;
    FAILED_TRADE_IN_VALUATION?: UpdateNotificationConfigDTO;
    INTERNAL_INSPECTOR_REQUESTED_FOR_TRADE_IN_VALUATION_BY_SELLER?: UpdateNotificationConfigDTO;
    SELLER_INVOICE_MISSING_REMINDER?: UpdateNotificationConfigDTO;
    BUYER_BNPL_5_DAY_PAYMENT_REMINDER?: UpdateNotificationConfigDTO;
    RESET_PASSWORD?: UpdateNotificationConfigDTO;
    BUYER_AUCTION_SUCCESSFUL_WITH_INVOICES?: UpdateNotificationConfigDTO;
    BUYER_AUCTION_ENDED_BELOW_MINIMUM_ASK?: UpdateNotificationConfigDTO;
    BUYER_DOCUMENT_CHECK_STARTING?: UpdateNotificationConfigDTO;
    BUYER_TRANSPORTATION_TASK_DELAY?: UpdateNotificationConfigDTO;
    BUYER_TEST_ACCOUNT_CREATED?: UpdateNotificationConfigDTO;
    SELLER_AUCTION_READY_TO_REINSERT?: UpdateNotificationConfigDTO;
    SELLER_PAYOUT_SUCCESSFUL?: UpdateNotificationConfigDTO;
    SELLER_PREREGISTERED_ACCOUNT_CREATED?: UpdateNotificationConfigDTO;
    BUYER_AUCTION_DEACTIVATED?: UpdateNotificationConfigDTO;
    BUYER_AUCTION_PAYMENT_CONFIRMATION?: UpdateNotificationConfigDTO;
    BUYER_PICKUP_REMINDER_1?: UpdateNotificationConfigDTO;
    BUYER_AUCTION_2_DAY_PAYMENT_REMINDER_NOT_COSPAY?: UpdateNotificationConfigDTO;
  };
}

export enum ChangeableUserType {
  BUYER = 'BUYER',
  SELLER = 'SELLER',
}

export type IServicesAggregation = object;

/** A collection of services data. */
export interface IServiceHealthDataResponse {
  health: {
    /** Overall Status Code */
    isHealthy: boolean;
    services: IServicesAggregation;
  };
}

/** A collection of backend service meta data information. */
export interface IServiceMetaData {
  /** The name of the project as defined in the package.json file. */
  name: string;
  /** The version of the project as defined in the package.json file. */
  version: string;
  /** The deployment environment. */
  environment: string;
  /**
   * The current unix system time.
   * @format double
   */
  currentTimeInMilliseconds: number;
  /** The hash of the currently deployed commit. */
  deployedCommitHash: string;
}

/** Piece of meta data denoting if the current time falls within the application business hours or not. */
export interface IBusinessState {
  /** Whether or not the current time falls within application business hours. */
  isOpenForBusiness: boolean;
}

/** Using as a response from the backend about a mobile app status. */
export interface IMobileAppStatus {
  /** True if app requires an update. */
  needsUpdate: boolean;
}

/** Input data about a mobile app. */
export interface IMobileAppDetails {
  /** Current mobile app version. */
  version: string;
  /**
   * Application name.
   * e.g. buyer, reviewer, seller.
   */
  name: string;
}

export interface IDamageCatalogUrl {
  url: string;
}

/** Make all properties in T optional */
export interface PartialIAccount {
  /** List of services that this account is enabled for */
  enabledServices?: EAccountService[];
  /** Reference to the parent account (N <-> 1) */
  _fk_uuid_parentAccount?: string;
  /** Reference to the primary internal user assigned to this account */
  _fk_uuid_assignedInternalUser1?: string;
  /** Reference to the secondary internal user assigned to this account */
  _fk_uuid_assignedInternalUser2?: string;
  /** Reference to the tertiary internal user assigned to this account */
  _fk_uuid_assignedInternalUser3?: string;
  /** The name of the legal entity or branch */
  name?: string;
  /** Url to the homepage of the company */
  urlToHomePage?: string;
  /** Url to the image of the company */
  urlToImage?: string;
  /** Contact phone number for the legal entity or branch */
  phoneNumber?: string;
  /** Value Added Tax Id of the legal entity */
  vatId?: string;
  /**
   * Indicating whether the VAT ID has been verified
   * This is mostly done for VAT IDs from non-German countries
   *
   * Verification is usually done automatically through external APIs
   */
  isVatIdVerified?: boolean;
  /** Tax Id of the legal entity (different from the Vat Id) */
  taxId?: string;
  /**
   * Indicates whether the account is an independent legal entity (i.e. a business).
   * In some cases, e.g. a subsidiary (location) of a business is also modelled as an Account,
   * even though it is not an independent business/legal entity.
   *
   * This information is relevant for COSPay, so COSPay knows that it has to check for parent accounts, when
   * checking if a user belongs to a KYC-checked Account (only legal entities are KYC-checked)
   */
  isLegalEntity?: boolean;
  /** URL to an uploaded version of the business registration document */
  urlToBusinessRegistrationDocument?: string;
  /**
   * Start and end of the validity of a tax registration document, that is legally required in the EU
   * @format date-time
   */
  taxRegistrationDocumentValidFrom?: string;
  /** @format date-time */
  taxRegistrationDocumentValidUntil?: string;
  /** German tax registration number */
  taxRegistrationNumber?: string;
  /** URL to an uploaded version of a tax registration document, that is legally required in the germany §22 */
  urlToTaxCertificateDocument?: string;
  /**
   * Timestamp indicating when the tax certificate document was uploaded. Should be null if not present
   * @format date-time
   */
  taxCertificateUploadedAt?: string;
  /** Flag indicating if the Account is strategic partner */
  isStrategicPartner?: boolean;
  /** Account group's UUID linked to the account */
  groups?: string[];
  /** lookAndFeelIds linked to the account */
  lookAndFeelIds?: EUserLookAndFeelId[];
  /**
   * The business type of the account enabled for buying/selling
   * Most are "LICENSED"
   */
  dealershipType?: EBusinessType;
  /**
   * Represents the estimated number of yearly B2B purchases made by the account.
   * This field is collected during the company detail step of the sign-up funnel
   * to help the call center and marketing team evaluate user potential.
   *
   * Example values:
   * - "0 - 5"
   * - "5 - 10"
   * - "10 - 20"
   * - "20 - 50"
   * - "50 - 100"
   * - "100+"
   */
  yearlyB2BPurchases?: string;
  /**
   * Indicating the time when the entity has been created, i.e. when the entity was
   * persisted to the database.
   * @format date-time
   */
  createdAt?: string;
  /**
   * Indicating the last time a value of the entity has been changed (updated).
   * @format date-time
   */
  updatedAt?: string;
  /**
   * Indicating the time when the entity has been (softly) deleted.
   * "null" if entry is not deleted.
   * @format date-time
   */
  deletedAt?: string;
  uuid?: string;
  addressLine?: string;
  zipCode?: string;
  city?: string;
  countryCode?: ECountryCode;
  /** Add extra comments do the location (e.g., pick-up instruction in transportation tasks). */
  comment?: string;
  /** Language of the contact person at the location. */
  contactLanguage?: string;
  /** Current version vector */
  versionVector?: number[];
  /** Indicates this update comes from a remote system */
  isRemoteUpdate?: boolean;
  /** First name of the/an owner (CEO for example) of the legal entity. */
  ownerFirstName?: string;
  /** Last name of the owner/an (CEO for example) of the legal entity. */
  ownerLastName?: string;
  /**
   * Day of birth of the owner of the legal entity.
   * @format double
   */
  ownerBirthDay?: number;
  /**
   * Month of birth of the owner of the legal entity.
   * @format double
   */
  ownerBirthMonth?: number;
  /**
   * Year of birth of the owner of the legal entity.
   * @format double
   */
  ownerBirthYear?: number;
  /** Email address of the owner of the legal entity. */
  ownerEmail?: string;
  /** Phone number of the owner of the legal entity. */
  ownerPhoneNumber?: string;
  /**
   * URL's to uploaded versions of identity verification documents for the owner of the legal entity
   * that can be used for the KYC (Know Your Customer) process
   */
  ownerUrlToIdDocumentFront?: string;
  ownerUrlToIdDocumentBack?: string;
  ownerUrlToAdditionalDocument?: string;
  /** Email address that will be used to invoice the legal entity */
  invoicingMailAddress?: string;
  /** Flag indicating if a separate address (from the main account address) should be used for billing */
  useSeparateBillingInfo?: boolean;
  /** Name used for billing */
  billingName?: string;
  /** Vat id used for billing */
  billingVatId?: string;
  /**
   * All accounts ({@link IAccount }) enabled for Selling ({@link EAccountService.MARKETPLACE_SELLER }) need this accessory entity ({@link IAccountSettingsSeller})
   * that contains all settings that are specific to the selling activity
   */
  settingsSeller?: IAccountSettingsSeller;
  /**
   * All accounts ({@link IAccount }) enabled for Buying ({@link EAccountService.MARKETPLACE_BUYER }) need this accessory entity ({@link IAccountSettingsBuyer})
   * that contains all settings that are specific to the buying activity
   */
  settingsBuyer?: IAccountSettingsBuyer;
  addresses?: IAccountAddress[];
  /**
   * GPS coordinates of the auction (can be used for e.g. calculating distances between auctions and buyers).
   * @format double
   */
  locationGeoLat?: number;
  /** @format double */
  locationGeoLon?: number;
  /**
   * A flag indicating whether this is a "test account" (i.e. preregistered account).
   * This is set to "true" when the account is created as a test account (e.g. through the call center).
   */
  isPreregisteredAccount?: boolean;
  /**
   * Timestamp when the account has been fully registered (i.e. when the account is turning from a
   * preregistered to fully a registered account).
   * @format date-time
   */
  fullyRegisteredAt?: string;
  /** Reference to stripe customer entity of this Account */
  stripeCustomerId?: string;
  /** Reference to the vat id object from Stripe. */
  stripeVatId?: string;
  /** Reference to account object for the Billomat invoicing provider. */
  billomatClientId?: string;
  /** Reference to account object for the Zoho invoicing provider. */
  zohoCustomerId?: string;
  /** Reference to the address object for the Zoho invoicing provider. */
  zohoAddressId?: string;
}

/** Make all properties in T optional */
export interface PartialIAccountAdditionalAddresses {
  /** Postal address of the owner. */
  ownerAddressLine?: string;
  ownerCity?: string;
  ownerZipCode?: string;
  ownerCountryCode?: ECountryCode;
  /** Postal address for billing */
  billingAddressLine?: string;
  billingZipCode?: string;
  billingCity?: string;
  billingCountryCode?: ECountryCode;
}

export interface IAccountGroup {
  /** Current version vector */
  versionVector: number[];
  /** Indicates this update comes from a remote system */
  isRemoteUpdate: boolean;
  uuid: string;
  /**
   * Indicating the time when the entity has been created, i.e. when the entity was
   * persisted to the database.
   * @format date-time
   */
  createdAt?: string;
  /**
   * Indicating the last time a value of the entity has been changed (updated).
   * @format date-time
   */
  updatedAt?: string;
  /**
   * Indicating the time when the entity has been (softly) deleted.
   * "null" if entry is not deleted.
   * @format date-time
   */
  deletedAt?: string;
  /** Account group name */
  name: string;
  /** Account group description */
  description: string;
}

/** Utility interface with the basic attributes that identify a user */
export interface IUserBasicIdentification {
  uuid: string;
  /** The mail address of the user */
  mailAddress: string;
}

export interface IInternalAccountViewLegalEntitySettings {
  /** @format double */
  regularBuyerDiscountRate: number;
}

/**
 * Augmented account view for internal users: accounts are augmented with
 * the number of users assigned to them.
 */
export interface IInternalAccountView {
  uuid: string;
  /**
   * Indicating the time when the entity has been created, i.e. when the entity was
   * persisted to the database.
   * @format date-time
   */
  createdAt?: string;
  /**
   * Indicating the last time a value of the entity has been changed (updated).
   * @format date-time
   */
  updatedAt?: string;
  /**
   * Indicating the time when the entity has been (softly) deleted.
   * "null" if entry is not deleted.
   * @format date-time
   */
  deletedAt?: string;
  addressLine?: string;
  zipCode?: string;
  city: string;
  countryCode: ECountryCode;
  /** Add extra comments do the location (e.g., pick-up instruction in transportation tasks). */
  comment?: string;
  /** Language of the contact person at the location. */
  contactLanguage?: string;
  /** Current version vector */
  versionVector: number[];
  /** Indicates this update comes from a remote system */
  isRemoteUpdate: boolean;
  /** First name of the/an owner (CEO for example) of the legal entity. */
  ownerFirstName: string;
  /** Last name of the owner/an (CEO for example) of the legal entity. */
  ownerLastName: string;
  /**
   * Day of birth of the owner of the legal entity.
   * @format double
   */
  ownerBirthDay: number;
  /**
   * Month of birth of the owner of the legal entity.
   * @format double
   */
  ownerBirthMonth: number;
  /**
   * Year of birth of the owner of the legal entity.
   * @format double
   */
  ownerBirthYear: number;
  /** Email address of the owner of the legal entity. */
  ownerEmail: string;
  /** Phone number of the owner of the legal entity. */
  ownerPhoneNumber: string;
  /**
   * URL's to uploaded versions of identity verification documents for the owner of the legal entity
   * that can be used for the KYC (Know Your Customer) process
   */
  ownerUrlToIdDocumentFront: string;
  ownerUrlToIdDocumentBack: string;
  ownerUrlToAdditionalDocument: string;
  /** Email address that will be used to invoice the legal entity */
  invoicingMailAddress: string;
  /** Flag indicating if a separate address (from the main account address) should be used for billing */
  useSeparateBillingInfo: boolean;
  /** Name used for billing */
  billingName: string;
  /** Vat id used for billing */
  billingVatId: string;
  /**
   * All accounts ({@link IAccount }) enabled for Selling ({@link EAccountService.MARKETPLACE_SELLER }) need this accessory entity ({@link IAccountSettingsSeller})
   * that contains all settings that are specific to the selling activity
   */
  settingsSeller?: IAccountSettingsSeller;
  /**
   * All accounts ({@link IAccount }) enabled for Buying ({@link EAccountService.MARKETPLACE_BUYER }) need this accessory entity ({@link IAccountSettingsBuyer})
   * that contains all settings that are specific to the buying activity
   */
  settingsBuyer?: IAccountSettingsBuyer;
  addresses?: IAccountAddress[];
  /**
   * GPS coordinates of the auction (can be used for e.g. calculating distances between auctions and buyers).
   * @format double
   */
  locationGeoLat: number;
  /** @format double */
  locationGeoLon: number;
  /**
   * A flag indicating whether this is a "test account" (i.e. preregistered account).
   * This is set to "true" when the account is created as a test account (e.g. through the call center).
   */
  isPreregisteredAccount: boolean;
  /**
   * Timestamp when the account has been fully registered (i.e. when the account is turning from a
   * preregistered to fully a registered account).
   * @format date-time
   */
  fullyRegisteredAt: string;
  /** Reference to stripe customer entity of this Account */
  stripeCustomerId: string;
  /** Reference to the vat id object from Stripe. */
  stripeVatId: string;
  /** Reference to account object for the Billomat invoicing provider. */
  billomatClientId: string;
  /** Reference to account object for the Zoho invoicing provider. */
  zohoCustomerId: string;
  /** Reference to the address object for the Zoho invoicing provider. */
  zohoAddressId: string;
  /** List of services that this account is enabled for */
  enabledServices: EAccountService[];
  /** Reference to the parent account (N <-> 1) */
  _fk_uuid_parentAccount: string;
  /** Reference to the primary internal user assigned to this account */
  _fk_uuid_assignedInternalUser1: string;
  /** Reference to the secondary internal user assigned to this account */
  _fk_uuid_assignedInternalUser2: string;
  /** Reference to the tertiary internal user assigned to this account */
  _fk_uuid_assignedInternalUser3: string;
  /** The name of the legal entity or branch */
  name: string;
  /** Url to the homepage of the company */
  urlToHomePage: string;
  /** Url to the image of the company */
  urlToImage: string;
  /** Contact phone number for the legal entity or branch */
  phoneNumber: string;
  /** Value Added Tax Id of the legal entity */
  vatId: string;
  /**
   * Indicating whether the VAT ID has been verified
   * This is mostly done for VAT IDs from non-German countries
   *
   * Verification is usually done automatically through external APIs
   */
  isVatIdVerified: boolean;
  /** Tax Id of the legal entity (different from the Vat Id) */
  taxId: string;
  /**
   * Indicates whether the account is an independent legal entity (i.e. a business).
   * In some cases, e.g. a subsidiary (location) of a business is also modelled as an Account,
   * even though it is not an independent business/legal entity.
   *
   * This information is relevant for COSPay, so COSPay knows that it has to check for parent accounts, when
   * checking if a user belongs to a KYC-checked Account (only legal entities are KYC-checked)
   */
  isLegalEntity: boolean;
  /** URL to an uploaded version of the business registration document */
  urlToBusinessRegistrationDocument: string;
  /**
   * Start and end of the validity of a tax registration document, that is legally required in the EU
   * @format date-time
   */
  taxRegistrationDocumentValidFrom: string;
  /** @format date-time */
  taxRegistrationDocumentValidUntil: string;
  /** German tax registration number */
  taxRegistrationNumber: string;
  /** URL to an uploaded version of a tax registration document, that is legally required in the germany §22 */
  urlToTaxCertificateDocument: string;
  /**
   * Timestamp indicating when the tax certificate document was uploaded. Should be null if not present
   * @format date-time
   */
  taxCertificateUploadedAt: string;
  /** Flag indicating if the Account is strategic partner */
  isStrategicPartner: boolean;
  /** Account group's UUID linked to the account */
  groups: string[];
  /** lookAndFeelIds linked to the account */
  lookAndFeelIds?: EUserLookAndFeelId[];
  /**
   * The business type of the account enabled for buying/selling
   * Most are "LICENSED"
   */
  dealershipType: EBusinessType;
  /**
   * Represents the estimated number of yearly B2B purchases made by the account.
   * This field is collected during the company detail step of the sign-up funnel
   * to help the call center and marketing team evaluate user potential.
   *
   * Example values:
   * - "0 - 5"
   * - "5 - 10"
   * - "10 - 20"
   * - "20 - 50"
   * - "50 - 100"
   * - "100+"
   */
  yearlyB2BPurchases?: string;
  /** @format double */
  associatedUserCount: number;
  legalEntitySettings?: IInternalAccountViewLegalEntitySettings;
}

export interface PageIInternalAccountView {
  items: IInternalAccountView[];
  /** @format double */
  total: number;
  /** @format double */
  page: number;
  /** @format double */
  pageSize?: number;
}

/**
 * Inform that a new resource was created. Server will
 * add a Location header and set status to 201
 */
export interface ReturnNewResourceIAccount {
  location: string;
  /** @format double */
  statusCode: number;
  /** the body to be sent */
  body: IAccount;
}

/** From T, pick a set of properties whose keys are in the union K */
export interface PickIAccountIsVatIdVerified {
  /**
   * Indicating whether the VAT ID has been verified
   * This is mostly done for VAT IDs from non-German countries
   *
   * Verification is usually done automatically through external APIs
   */
  isVatIdVerified: boolean;
}

/**
 * Inform that a new resource was created. Server will
 * add a Location header and set status to 201
 */
export interface ReturnNewResourceICustomerMemo {
  location: string;
  /** @format double */
  statusCode: number;
  /** the body to be sent */
  body: ICustomerMemo;
}

/** From T, pick a set of properties whose keys are in the union K */
export interface PickICustomerMemoContent {
  content: string;
}

/**
 * Inform that a new resource was created. Server will
 * add a Location header and set status to 201
 */
export interface ReturnNewResourceIAccountSettingsSeller {
  location: string;
  /** @format double */
  statusCode: number;
  /** the body to be sent */
  body: IAccountSettingsSeller;
}

/** Make all properties in T optional */
export interface PartialIAccountSettingsSeller {
  /** Reference to the account these settings are for */
  _fk_uuid_account?: string;
  /**
   * The business type of the account enabled for selling
   * Most are "LICENSED" sellers
   * @deprecated
   */
  dealershipType?: ESellerBusinessType;
  /** Flag indicating if seller's net auctions can be sold to foreign buyers. */
  allowCrossBorderNetSale?: boolean;
  /**
   * Indicating when sellers are invoiced seller fees.
   * (see ESellerFeeInvoicingMode)
   *
   * The default mode to invoice the (seller) fee is
   *
   * Default is NONE.
   */
  feeInvoicingMode?: ESellerFeeInvoicingMode;
  /**
   * The success fee (in EUR by default) that is charged for a seller per successfully transacted vehicle.
   *
   * This is only relevant when the IAccountSettingsSeller.feeInvoicingMode = ESellerFeeInvoicingMode.INVOICE_ON_SUCCESS
   *
   * Default fee is 0.
   * @format double
   */
  successFeePerVehicle?: number;
  /**
   * The listing fee (in EUR by default) that is charged for a seller per (initially) listed vehicle.
   *
   * This is only relevant when the IAccountSettingsSeller.feeInvoicingMode = ESellerFeeInvoicingMode.INVOICE_ON_LISTING
   *
   * Default fee is 0
   * @format double
   */
  listingFeePerVehicle?: number;
  /**
   * This flag indicates whether a seller "success" fee (see {@link ESellerFeeInvoicingMode}) should be charged when a
   * sale is made to a regular buyer. Some sellers have special contract conditions, where "regular buyer discounts"
   * are also not charged on the seller side.
   *
   * This is only relevant when the "feeInvoicingMode" = {@link ESellerFeeInvoicingMode.INVOICE_ON_SUCCESS}
   *
   * Default is false.
   *
   * TODO: This value is not yet used/relevant for the system.
   */
  shouldNotInvoiceSuccessFeeForRegularBuyers?: boolean;
  /** Flag indicating if manual creation of auctions are allowed for the seller users of this account */
  isManualAuctionCreationForbidden?: boolean;
  /**
   * @deprecated
   * @format double
   */
  regularBuyerDiscountRate?: number;
  /**
   * A fee that should be charged on top of the buyer purchase fee invoice.
   * This is used to charge the buy-side for fees that we would usually charge on the sell-side.
   * In some cases, this is not possible, so we need to charge it on the buy-side.
   * @format double
   */
  buyerSuccessFeePerVehicleNet?: number;
  /**
   * Was a CosBox (locker that Cos provides which contains documents and keys for vehicles ready to pick-up)
   * deployed to this account.
   */
  isCosBoxDeployed?: boolean;
  /**
   * Indicating the time when the entity has been created, i.e. when the entity was
   * persisted to the database.
   * @format date-time
   */
  createdAt?: string;
  /**
   * Indicating the last time a value of the entity has been changed (updated).
   * @format date-time
   */
  updatedAt?: string;
  /**
   * Indicating the time when the entity has been (softly) deleted.
   * "null" if entry is not deleted.
   * @format date-time
   */
  deletedAt?: string;
  uuid?: string;
  /** Current version vector */
  versionVector?: number[];
  /** Indicates this update comes from a remote system */
  isRemoteUpdate?: boolean;
  /** A flag indicating whether transportation is enforced for auctions owned by this Account. */
  enforceTransportation?: boolean;
  /** Controls whether the automatic pickup approval is enabled (default = true). */
  autoPickupOnPaymentReceivedBySeller?: boolean;
  /**
   * Flag indicating if pickups should be immediately
   * possible on money coming to COSPay.
   *
   * When this is set to "true", auctions in "CLOSED_WAITING_FOR_PAYMENT"
   * will be automatically set to "CLOSED_WAITING_FOR_PICKUP" as soon as
   * an incoming payment is detected by COSPay.
   */
  autoPickupOnPaymentReceivedByCos?: boolean;
  /** Flag indicating whether the document shipping process has to be activated for this account */
  isDocumentShippingEnabled?: boolean;
  /** Flag indicating whether the document shipping process has to be activated for this account and its regular buyers. */
  isDocumentShippingEnabledForRegularBuyers?: boolean;
  /** Flag which indicates whether standings costs can be applied to buyers of auctions by this account */
  shouldApplyStandingCosts?: boolean;
  /**
   *
   * Optional pickup instructions that will be communicated to the buyer
   */
  pickupInstructions?: string;
  /** Flag which indicates if a vehicle is handled via logistics compound */
  isCompoundEnabled?: boolean;
  /** Flag which indicates if a CRM user enabled this account to participate in auction rooms */
  auctionRoomsEnabled?: boolean;
  /** Flag which indicates that vehicles from this seller can only be picked up after a delay of several working days */
  delayedPickupEnabled?: boolean;
  /** Preferable document shipping provider for the seller */
  documentShippingProviderName?: EShippingOrderProvider;
  /** Start time for document pickup (e.g 08:00) */
  documentPickupStartTime?: string;
  /** End time for document pickup (e.g 17:00) */
  documentPickupEndTime?: string;
  /**
   * Days to delay the document pickup due preparations
   * @format double
   */
  documentPickupDelayDays?: number;
  /** Notes related to the document pickup */
  documentPickupNote?: string;
  /** Optional first name of the person that should receive a copy of all logistic-related communication. */
  logisticsContactFirstName?: string;
  /** Optional last name of the person that should receive a copy of all logistic-related communication. */
  logisticsContactLastName?: string;
  /** Optional business name of the person that should receive a copy of all logistic-related communication. */
  logisticsContactBusinessName?: string;
  /** Optional phone number of the person that should receive a copy of all logistic-related communication. */
  logisticsContactPhoneNumber?: string;
  /** Optional email address of the person that should receive a copy of all logistic-related communication. */
  logisticsContactMailAddress?: string;
  /** Optional email addresses of the people that should receive a copy of all logistic-related communication separated by comma ','. */
  logisticsContactCcMail?: string;
  /** Legal business type of the legal entity for Stripe */
  stripeBusinessType?: EStripeBusinessType;
  /** Flag that indicates if this account is activated to use our payment service: CosPay */
  cosPayEnabled?: boolean;
  /** Is true when the account has been kyc checked by Stripe */
  stripePayoutsEnabled?: boolean;
  /** Flag indicating if payouts are to be blocked to account. When the flag indicates true no payouts will be processed */
  blockPayouts?: boolean;
  /** Is true when KYC needed information is under review */
  kycCheckInProgress?: boolean;
  /**
   * Is true when KYC needed information is incomplete, i.e., there are pendencies to be solved
   * (meaning one or more fields are currently due)
   */
  kycCheckPendencies?: boolean;
  /**
   * Timestamp indicating when the kyc checked was approved. Should be null if not kyc checked.
   * @format date-time
   */
  kycCheckedAt?: string;
  /**
   * Sometimes there are additional requirements after a connected account passes a certain volume
   * When this happens a deadline is defined, if the requested information is not collected or updated until that date,
   * payouts will be disabled.
   * @format date-time
   */
  kycExpirationDate?: string;
  /** Indicates how auction is invoiced to the sellers and buyers */
  purchaseInvoicingMode?: ESellerPurchaseInvoicingMode;
  /**
   * Timestamp the purchase invoicing mode was last updated at
   * @format date-time
   */
  purchaseInvoicingModeUpdatedAt?: string;
  /** Flag being true indicates that the payout is only enabled when the Seller to COS invoice has been received */
  conditionalPayoutsEnabled?: boolean;
  /** Flag being true indicates that we'll automatically payout money to the seller from our balance, BEFORE receiving the money from the buyer */
  paymentInAdvanceEnabled?: boolean;
  /** Indicates if the seller is enabled to use the Instant Payouts feature from Stripe. */
  stripeInstantPayoutsEnabled?: boolean;
  /** Indicates if the bulk invoices are enabled or not */
  sellerFeeInvoicingFrequency?: ESellerFeeInvoicingFrequency;
  /**
   * Setting to enable a distinct fee below a certain auctionHighestBid threshold.
   *
   * Note: (Only for seller success fee)
   */
  enableSuccessFeeThreshold?: boolean;
  /**
   * Auction bid threshold value
   * @format double
   */
  successFeeThreshold?: number;
  /**
   * Fee below threshold value
   * @format double
   */
  successFeeAmountWhenBelowThreshold?: number;
  /** Has free amount of vehicles enabled */
  complimentaryVehiclesEnabled?: boolean;
  /**
   * Amount of free vehicles remaining
   * @format double
   */
  remainingComplimentaryVehicles?: number;
  prePaymentCategory?: EPrePaymentCategory;
  /** Reference to stripe account entity */
  stripeConnectedAccountId?: string;
  /** Reference to the tax registration document in Stripe */
  stripeFileIdTaxRegistrationDocument?: string;
  /**
   * Reference to the direct debit handle (e.g. the Stripe source for direct debit)
   *
   * If this is null, it means that no direct debit handle (i.e. source) has been created and therefore the account
   * has not yet approved the direct debit mandate (SEPA mandate).
   */
  stripeDirectDebitSourceId?: string;
  /** Reference to company the owner in Stripe */
  stripePersonIdOwner?: string;
  /** References to the company owner identity verification documents used in the Stripe KYC process */
  stripeFileIdOwnerDocumentFront?: string;
  stripeFileIdOwnerDocumentBack?: string;
  stripeFileIdOwnerAdditionalDocument?: string;
  /** Stores the auction schedule configuration. */
  auctionSlottingSchedule?: ISlottingSchedule;
  /** Stores the new auction schedule. */
  auctionSlottingDays?: ISlottingDays;
  /** Flag indicating that all auctions from this seller should be test auctions per default */
  shouldMarkAllAuctionsAsTest?: boolean;
  /** A flag indicating if any vehicles owned by members of this account should have their minimum required asks displayed to buyers. */
  revealMinAskOnAuctions?: boolean;
}

/**
 * Inform that a new resource was created. Server will
 * add a Location header and set status to 201
 */
export interface ReturnNewResourceIAccountSettingsBuyer {
  location: string;
  /** @format double */
  statusCode: number;
  /** the body to be sent */
  body: IAccountSettingsBuyer;
}

/** Make all properties in T optional */
export interface PartialIAccountSettingsBuyer {
  /** Reference to the account these settings are for */
  _fk_uuid_account?: string;
  /** Flag indicating if this account can view and purchase cross-border net sale auctions. */
  allowCrossBorderNetPurchase?: boolean;
  /**
   * The last "behavior class" (i.e. whether the account is "active" or "inactive" in buying) that was calculated
   *
   * Behavior is calculated on a frequent basis.
   * @deprecated
   */
  lastBehaviorClass?: ESalesmanBehaviorClass;
  /**
   * Adjustment value that is offset from the total balance value
   * @format double
   */
  balanceManualOffset?: number;
  /** Config of all available prebooked services for the Account. */
  prebookedServicesConfig?: IPrebookedServiceConfig[];
  /** Flag that determine if the buyer has buy now, pay later feature enabled */
  allowBuyNowPayLater?: boolean;
  /** Flag when standing fee is enabled for the buyer and we'll need to extra charge them for some penalty. */
  isStandingFeeEnabled?: boolean;
  /** Flag that indicates if the buyer account is marked as Premium */
  isPremium?: boolean;
  /** Delivery preferences of a mailbox delivery for document shipping */
  mailboxDeliveryPreference?: EMailboxDeliveryPreference;
  /** Flag enables invoice and the vehicle report to be merged into one PDF */
  isEnabledAttachVehicleReport?: boolean;
  /** Flag enables invoice to be attached on the auction e-mail */
  isEnableAttachInvoiceToAuctionEmail?: boolean;
  /** Notes related to the document delivery */
  documentDeliveryNote?: string;
  /**
   * Indicating the time when the entity has been created, i.e. when the entity was
   * persisted to the database.
   * @format date-time
   */
  createdAt?: string;
  /**
   * Indicating the last time a value of the entity has been changed (updated).
   * @format date-time
   */
  updatedAt?: string;
  /**
   * Indicating the time when the entity has been (softly) deleted.
   * "null" if entry is not deleted.
   * @format date-time
   */
  deletedAt?: string;
  uuid?: string;
  /** Current version vector */
  versionVector?: number[];
  /** Indicates this update comes from a remote system */
  isRemoteUpdate?: boolean;
  /**
   * URL to the template document for the power of attorney document for transportation jobs.
   * This template document is generated automatically for every account enabled for buying.
   */
  urlToPowerOfAttorneyDocumentFile?: string;
  /**
   * URL to the signed document for the power of attorney document for transportation jobs.
   * This is only set after a user of the Account has uploaded the signed document (either manually uploaded
   * or automatically after signing through HelloSign)
   */
  urlToSignedPowerOfAttorneyDocumentFile?: string;
  /**
   * When the signed power of attorney document for transportation was uploaded.
   * @format date-time
   */
  signedPowerOfAttorneyDocumentUploadDate?: string;
  /**
   * Timestamp when the associated account has requested to be KYC checked.
   *
   * This is null if a buyer has not requested the KYC yet.
   * This will also be reset to null if the KYC for the account is rejected.
   * @format date-time
   */
  kycRequestedAt?: string;
  /**
   * Indicating whether the buyer is allowed to see minimum ask prices of ALL auctions.
   * This is configured on a per buyer basis.
   *
   * If this is true, the buyer should see all available minimum ask prices for auctions.
   *
   * If this is false, the buyer can still see minimum ask prices for auctions with sellers configured to display the min ask prices.
   */
  isAllowedToSeeMinAskPrices?: boolean;
  /**
   * A flag indicating whether the account is not allowed to bid on auctions.
   *
   * This can be set manually by admins (e.g. when a buyer is not paying his invoices).
   * Also, for all "test accounts" (i.e. preregistered accounts), bidding is always prohibited
   * since the creation.
   */
  isBiddingProhibited?: boolean;
  /** Stores a reason why the account was blocked. */
  biddingProhibitionReason?: EBuyerBiddingProhibitionReason;
  /** Flag when the buyer is limited in the amount of vehicle they can buy, for example a first buyer. */
  hasLimitedAmountOfPurchases?: boolean;
}

export interface IPageISellerAccount {
  items: ISellerAccount[];
  /** @format double */
  total: number;
  /** @format double */
  page: number;
  /** @format double */
  pageSize?: number;
}

/** @deprecated */
export interface IAdminSellerAccountView {
  /**
   * @deprecated
   * @format double
   */
  id: number;
  uuid: string;
  /**
   * Indicating the time when the entity has been created, i.e. when the entity was
   * persisted to the database.
   * @format date-time
   */
  createdAt?: string;
  /**
   * Indicating the last time a value of the entity has been changed (updated).
   * @format date-time
   */
  updatedAt?: string;
  /**
   * Indicating the time when the entity has been (softly) deleted.
   * "null" if entry is not deleted.
   * @format date-time
   */
  deletedAt?: string;
  addressLine?: string;
  zipCode?: string;
  city: string;
  countryCode: ECountryCode;
  /** Add extra comments do the location (e.g., pick-up instruction in transportation tasks). */
  comment?: string;
  /** Language of the contact person at the location. */
  contactLanguage?: string;
  /** Current version vector */
  versionVector: number[];
  /** Indicates this update comes from a remote system */
  isRemoteUpdate: boolean;
  /** The name (i.e. the name of the legal entity). */
  name: string;
  /** Url to the homepage of the legal entity. */
  urlToHomePage: string;
  /** Url to the image of the legal entity. */
  urlToImage: string;
  /** Contact phone number for the legal entity. */
  phoneNumber: string;
  /** Value Added Tax Id of the legal entity. */
  vatId: string;
  /** Reference to the vat id object from the external payment provider Stripe. */
  vatIdExternalReference: string;
  /**
   * Reference to the direct debit handle (e.g. the Stripe source for direct debit) for this particular
   * seller account.
   *
   * If this is null, it means that no direct debit handle (i.e. source) has been created and therefore the seller
   * has not yet approved the direct debit mandate (SEPA mandate).
   */
  externalDirectDebitReference: string;
  /**
   * Indicating when sellers are invoiced seller fees.
   * (see ESellerFeeInvoicingMode)
   *
   * The default mode to invoice the (seller) fee is
   *
   * Default is NONE.
   */
  feeInvoicingMode: ESellerFeeInvoicingMode;
  /**
   * The success fee (in EUR by default) that is charged for a seller per successfully transacted vehicle.
   *
   * This is only relevant when the "feeInvoicingMode" = ESellerFeeInvoicingMode.INVOICE_ON_SUCCESS
   *
   * Default fee is 0.
   * @format double
   */
  successFeePerVehicle: number;
  /**
   * The listing fee (in EUR by default) that is charged for a seller per (initially) listed vehicle.
   *
   * This is only relevant when the "feeInvoicingMode" = ESellerFeeInvoicingMode.INVOICE_ON_LISTING
   *
   * Default fee is 0
   * @format double
   */
  listingFeePerVehicle: number;
  /**
   * This flag indicates whether a seller "success" fee (see ESellerFeeInvoicingMode) should be charged when a
   * sale is made to a regular buyer. Some sellers have special contract conditions, where "regular buyer discounts"
   * are also not charged on the seller side.
   *
   * This is only relevant when the "feeInvoicingMode" = ESellerFeeInvoicingMode.INVOICE_ON_SUCCESS
   *
   * Default is false.
   *
   * TODO: This value is not yet used/relevant for the system.
   */
  shouldNotInvoiceSuccessFeeForRegularBuyers: boolean;
  /** Indicates how auction is invoiced to the seller and buyer. */
  purchaseInvoicingMode: ESellerPurchaseInvoicingMode;
  /**
   * Timestamp the purchase invoicing mode was last updated at.
   * @format date-time
   */
  purchaseInvoicingModeUpdatedAt: string;
  /** Tax Id of the legal entity (different from the Vat Id). */
  taxId: string;
  /**
   * Indicates whether the seller account is an independent legal entity (i.e. a business).
   * Most SellerAccounts are legal entities, but in some cases, e.g. a subsidiary (location) of a business
   * is also modelled as a SellerAccount, even though it is not an independent business/legal entity.
   *
   * This information is relevant for COSPay, so COSPay knows that it has to check for parent accounts, when
   * checking if a SellerUser belongs to a KYC-checked SellerAccount (only legal entities are KYC-checked).
   */
  isLegalEntity: boolean;
  /** Email address that will be used to invoice the legal entity. */
  invoicingMailAddress: string;
  /**
   * Flag that indicates if this account is activated to use an external payment service (i.e. CarOnPay)
   * to facilitate payments.
   */
  isExternalPaymentEnabled: boolean;
  /**
   * References to external payment provider (such as Stripe).
   * Could contain IDs for invoice objects in the third-party system.
   */
  externalPaymentSellerId: string;
  externalPaymentId: string;
  /** First name of the/an owner (CEO for example) of the legal entity. */
  ownerFirstName: string;
  /** Last name of the owner/an (CEO for example) of the legal entity. */
  ownerLastName: string;
  /**
   * Date of birth of the owner of the legal entity.
   * @deprecated
   * @format date-time
   */
  ownerBirthDate: string;
  /**
   * Day of birth of the owner of the legal entity.
   * @format double
   */
  ownerBirthDay: number;
  /**
   * Month of birth of the owner of the legal entity.
   * @format double
   */
  ownerBirthMonth: number;
  /**
   * Year of birth of the owner of the legal entity.
   * @format double
   */
  ownerBirthYear: number;
  /** Reference to external payment provider (such as Stripe) for the owner. */
  ownerExternalReference: string;
  /** Email address of the owner of the legal entity. */
  ownerEmail: string;
  /** Phone number of the owner of the legal entity. */
  ownerPhoneNumber: string;
  /**
   * URL's to uploaded versions of identity verification documents for the owner of the legal entity
   * that can be used for the KYC (Know Your Customer) process for external payment service (such as CarOnPay).
   */
  ownerUrlToIdDocumentFront: string;
  ownerUrlToIdDocumentBack: string;
  ownerUrlToAdditionalDocument: string;
  /**
   * References to the owners of the legal entity's identity verification document used in the KYC process
   * for external payment providers (such as Stripe).
   */
  ownerIdDocumentFrontExternalReference: string;
  ownerIdDocumentBackExternalReference: string;
  ownerAdditionalDocumentExternalReference: string;
  /**
   * URL to an uploaded version of a tax registration document, that is legally required in the EU. This
   * document has to be provided or retrieved from the seller themselves.
   */
  urlToTaxRegistrationDocument: string;
  /** Reference to a tax registration document for an external payment provider (such as Stripe). */
  taxRegistrationDocumentExternalReference: string;
  /**
   * Start and end of the validity of an tax registration document, that is legally required in the EU.
   * @format date-time
   */
  taxRegistrationDocumentValidFrom: string;
  /** @format date-time */
  taxRegistrationDocumentValidUntil: string;
  /** German tax registration number. */
  taxRegistrationNumber: string;
  /**
   * URL to an uploaded version of a tax registration document, that is legally required in the germany §22. This
   * document has to be provided or retrieved from the seller themselves.
   */
  urlToTaxCertificateDocument: string;
  /** Legal business type of the legal entity for external payment service (such as CarOnPay). */
  legalBusinessType: ELegalBusinessType;
  /** Is true when the seller account has been kyc checked by the payment service provider. */
  payoutsEnabled: boolean;
  /** Reference to the parent seller account. */
  _fk_uuid_parentAccount: string;
  /** Reference to responsible field sales manager user. */
  _fk_uuid_internalUser: string;
  /** Reference to responsible inside sales manager user. */
  _fk_uuid_internalUserInsideSales: string;
  /** Is true when information is under review by the payment service provider. */
  kycCheckPending: boolean;
  /**
   * Is true when information that was provided to the payment service provider is incomplete.
   * (meaning one or more fields are currently due).
   */
  kycCheckIncomplete: boolean;
  /**
   * Flag indicating if pickups should be immediately
   * possible on money coming to COSPay.
   *
   * When this is set to "true", auctions in "CLOSED_WAITING_FOR_PAYMENT"
   * will be automatically set to "CLOSED_WAITING_FOR_PICKUP" as soon as
   * an incoming payment is detected by COSPay.
   */
  autoPickupOnPaymentReceivedByCos: boolean;
  /**
   * Flag indicating if manual creation of auctions are allowed for the seller users
   * being owned by the given seller account.
   */
  isManualAuctionCreationForbidden: boolean;
  /**
   * Timestamp indicating when the kyc checked was approved by Stripe (payoutsEnabled = true). Should be null if
   * not kyc checked.
   * @format date-time
   */
  kycCheckedAt: string;
  /**
   * Timestamp indicating when the tax certificate document was uploaded. Should be null if not present.
   * @format date-time
   */
  taxCertificateUploadedAt: string;
  /**
   * Sometimes the payment provider requests additional requirements after a connected account passes a certain volume,
   * When this happens a deadline is defined, if the requested information is not collected or updated until that date,
   * payouts will be disabled.
   * @format date-time
   */
  kycExpirationDate: string;
  /** Controls whether the automatic pickup approval is enabled (default = true). */
  autoPickupOnPaymentReceivedBySeller: boolean;
  /** Flag indicating if the seller account is strategic partner or not. */
  isStrategicPartner: boolean;
  /**
   * Flag indicating if payouts are to be blocked to this seller. When the flag indicates true no payouts
   * to the seller will be processed.
   */
  blockPayouts: boolean;
  /**
   * Flag indicating if a separate address (from the main seller account addressed used for the kyc check)
   * should be used for billing.
   */
  useSeparateBillingInfo: boolean;
  /** Name used for billing. */
  billingName: string;
  /** Vat id used for billing. */
  billingVatId: string;
  /** Flag indicating if seller's net auctions can be sold to foreign buyers. */
  allowCrossBorderNetSale: boolean;
  /**
   * Regular buyer discount rate from 0 to 1 (default is 1, a full discount on the commission).
   * @format double
   */
  regularBuyerDiscountRate: number;
  /** A flag indicating if any vehicles owned by members of this seller account should have their minimum required asks displayed to buyers. */
  revealMinAskOnAuctions: boolean;
  /** Stores the auction schedule configuration. */
  scheduleConfig: ISlottingSchedule;
  /**
   * A fee that should be charged on top of the buyer purchase fee invoice.
   * This is used to charge the buy-side for fees that we would usually charge on the sell-side.
   * In some cases, this is not possible, so we need to charge it on the buy-side.
   * @format double
   */
  buyerSuccessFeePerVehicleNet: number;
  /**
   * Flag indicating wherever the flash sale process should be used for all auctions created by subordinate
   * seller users for this seller account.
   * @deprecated
   */
  useFlashSaleProcess: boolean;
  /** Flag indicating wherever the document shipping process have to be activated for certain kind of user. */
  isDocumentShippingEnabled: boolean;
  /** Flag indicating wherever the document shipping process have to be activated for certain kind of user and your regular buyers. */
  isDocumentShippingEnabledForRegularBuyers: boolean;
  /** Flag which indicates wherever standings costs can be applied to buyers of auctions by this seller. */
  shouldApplyStandingCosts: boolean;
  /** Optional first name of the person that should receive a copy of all logistic-related communication. */
  logisticsContactFirstName: string;
  /** Optional last name of the person that should receive a copy of all logistic-related communication. */
  logisticsContactLastName: string;
  /** Optional business name of the person that should receive a copy of all logistic-related communication. */
  logisticsContactBusinessName: string;
  /** Optional phone number of the person that should receive a copy of all logistic-related communication. */
  logisticsContactPhoneNumber: string;
  /** Optional email address of the person that should receive a copy of all logistic-related communication. */
  logisticsContactMailAddress: string;
  /** Optional email addresses of the people that should receive a copy of all logistic-related communication separated by comma ','. */
  logisticsContactCcMail: string;
  /** Optional pickup instructions that will be communicated to the buyer. */
  pickupInstructions: string;
  /**
   * A flag indicating whether transportation is enforced for auctions owned by this SellerUser.
   *
   * This flag will be moved to the SellerAccount eventually, making this field deprecated.
   */
  enforceTransportation: boolean;
  /**
   * Was a CosBox (locker that Cos provides which contains documents and keys for vehicles ready to pick-up)
   * deployed to this seller.
   */
  isCosBoxDeployed: boolean;
  /**
   * The business type of the SellerUser.
   * Most SellerUsers are "LICENSED" sellers.
   */
  dealershipType: ESellerBusinessType;
  /** Flag being true indicates that the payout is only enabled when the Seller to COS invoice has been received */
  conditionalPayoutsEnabled: boolean;
  /** Flag which indicates if a vehicle is handled via logistics compound */
  isCompoundEnabled: boolean;
  /** Flag which indicates if a CRM user enabled this account to participate in auction rooms */
  auctionRoomsEnabled: boolean;
  /** @format double */
  associatedSellerUserCount: number;
}

export interface IPasswordChangeRequest {
  currentPassword?: string;
  newPassword: string;
}

/** The user profile information needed to integrate the IdP user with its profiles */
export interface IUserProfile {
  type: EUserType;
  /** @format double */
  internalUserId: number;
  internalUserUuid: string;
  privileges: string;
  mailAddress: string;
  /** @deprecated */
  userRole: EUserRole;
  userRoles: EUserRole[];
}

/** The interface to hold the user information from all the user profiles */
export interface IUserInfo {
  profiles: IUserProfile[];
  migrationCompleted?: boolean;
}

export interface IRegularBuyerState {
  isRegularBuyer: boolean;
}

export interface BuyerActivationTask {
  /** @format double */
  value?: number;
  active: boolean;
}

/** The tasks/warnings that a buyer needs to be aware of regarding his account status on the platform. */
export interface IBuyerActivationTasks {
  /** If the buyer is pre-registered (has the test account flag marked) and didnt upload any documents. */
  showNeedsToUploadDocuments: BuyerActivationTask;
  /** If the buyer is fully registered and has at least one saved search. */
  showCanCheckAuctions: BuyerActivationTask;
  /** The amount of ongoing renegotiations that the buyer has at the moment. */
  showNumOfOngoingRenegotiations: BuyerActivationTask;
  /** The amount of auctions that the buyer bid at least once but were outbid. */
  showNumOfOutbidAuctions: BuyerActivationTask;
  /** The amount of open checkouts that the buyer has (purchases of the current and day before when no payment has been made or transport booked). */
  showNumOfOpenCheckouts: BuyerActivationTask;
  /** The amount of open payments that the buyer has to make (open invoices on recent purchases). */
  showNumOfOpenPayments: BuyerActivationTask;
  /** The amount of vehicles that the buyer has to pick up (auctions with no transport booked and if no pickup confirmed +2 days post release). */
  showNumOfVehiclesToPickup: BuyerActivationTask;
  /** If the buyer is fully registered and is prohibited from bidding. */
  showBiddingIsProhibited: BuyerActivationTask;
  /** should show a warning to the buyer due to the limit purchase rule */
  showBuyerLimitPurchaseWarning: BuyerActivationTask;
  /** should show that the buyer is blocked due to the limit purchase rule */
  showBuyerLimitPurchaseBlocked: BuyerActivationTask;
  /** should show that the buyer needs to set his purchase preferences */
  showSetPurchasePreferences: BuyerActivationTask;
}

/** A list of buyer activation tasks with their names. */
export type IBuyerActivationTaskList = (BuyerActivationTask & {
  name:
    | 'showNeedsToUploadDocuments'
    | 'showCanCheckAuctions'
    | 'showNumOfOngoingRenegotiations'
    | 'showNumOfOutbidAuctions'
    | 'showNumOfOpenCheckouts'
    | 'showNumOfOpenPayments'
    | 'showNumOfVehiclesToPickup'
    | 'showBiddingIsProhibited'
    | 'showBuyerLimitPurchaseWarning'
    | 'showBuyerLimitPurchaseBlocked'
    | 'showSetPurchasePreferences';
})[];

/** How admin users see buyer users. */
export interface IAdminBuyerView {
  uuid: string;
  /** The mail address of the user */
  mailAddress: string;
  /**
   * Indicating the time when the entity has been created, i.e. when the entity was
   * persisted to the database.
   * @format date-time
   */
  createdAt?: string;
  /**
   * Indicating the last time a value of the entity has been changed (updated).
   * @format date-time
   */
  updatedAt?: string;
  /**
   * Indicating the time when the entity has been (softly) deleted.
   * "null" if entry is not deleted.
   * @format date-time
   */
  deletedAt?: string;
  /** Current version vector */
  versionVector: number[];
  /** Indicates this update comes from a remote system */
  isRemoteUpdate: boolean;
  /** The mail address which is using for login */
  loginMailAddress?: string;
  /** The user id generated by the IdP (identity provider) */
  idpUserId?: string;
  /** Reference to the account this user belongs to */
  _fk_uuid_account: string;
  /**
   * Whether the user wants to be addressed with honorific form
   * (e.g. "Sie" instead of "Du" in German)
   */
  honorific: boolean;
  /** The first name of the user */
  firstName: string;
  /** The last name of the user */
  lastName: string;
  /** Url to the user profile picture */
  urlToProfilePicture: string;
  /** Slot 1 for the phone number of the user */
  phoneNumber: string;
  /** Slot 2 for the phone number of the user */
  phoneNumber2: string;
  /** Slot 3 for the phone number of the user */
  phoneNumber3: string;
  /** The state of the user. */
  state: EUserState;
  /** Referral code this user can share with friends to register in the platform */
  personalReferralCodeToShare: string;
  /**
   * The referral code that was used by the user when registering to COS.
   * "null" if no referral code has been used.
   */
  referralCode: string;
  /**
   * The roles this user plays (check {@link GENERAL_USER_ROLES})
   *
   * This can also be seen as a set of permissions
   */
  roles: string[];
  /** List of permissions indicating what the user is allowed to do. */
  permissions: EGeneralUserPermission[];
  /** Data for the account this user belongs to. Optional augmentation (see: {@link EGeneralUserAugmentation.ACCOUNT}). */
  account?: IAccount;
  /** The GeneralUserSettings data that contains extra settings and preferences. Optional augmentation (see: {@link EGeneralUserAugmentation.SETTINGS}). */
  settings?: IGeneralUserSettings;
  /** Additional e-mail recipients for e-mails sent to the user */
  additionalEmailRecipients?: IUserAdditionalEmailRecipient[];
  /**
   * Timestamp when the user went completed the self-sign up process by confirmation it's e-mail address.
   * @format date-time
   */
  mailAddressConfirmedAt?: string;
  /** Whether Two-Factor Authentication is enabled for this user */
  hasTwoFactorAuth?: boolean;
  /** Internal memos for the buyer user. */
  memos: ICustomerMemo[];
  /**
   * Indicating whether the buyer user is available for WhatsApp communication
   * (i.e. the last message sent BY the buyer user is not older then 24 hours)
   */
  isAvailableForWhatsAppMessaging: boolean;
  /** Incoming messages that have not been consumed yet by internal users. */
  recentMessages: ICustomerCommunicationEvent[];
  /**
   * TODO
   * @format double
   */
  matchingScore: number;
  /** TODO */
  matchingScoreRaw: IRawScore;
}

export enum EUserDeviceType {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
}

/** An object containing the information of a user device for push and in app notifications. */
export interface IUserDevice {
  uuid: string;
  /**
   * Indicating the time when the entity has been created, i.e. when the entity was
   * persisted to the database.
   * @format date-time
   */
  createdAt?: string;
  /**
   * Indicating the last time a value of the entity has been changed (updated).
   * @format date-time
   */
  updatedAt?: string;
  /**
   * Indicating the time when the entity has been (softly) deleted.
   * "null" if entry is not deleted.
   * @format date-time
   */
  deletedAt?: string;
  /** The type of the user device, see {@link IGeneralUser } */
  type: EUserDeviceType;
  /** The device id, this is unique */
  deviceId: string;
  /** The name of the device id (currently used as the os of the device) */
  label: string;
  /** The OS version of the device */
  osVersion: string;
  /** The app version of the device */
  appVersion: string;
  /**
   * The last login made by the user with this device
   * @format date-time
   */
  lastLogin: string;
  /** Reference to the user this device belongs to */
  _fk_uuid_user: string;
}

export interface IAuctionsConfig {
  /**
   * @deprecated
   * @format double
   */
  id: number;
  uuid: string;
  /**
   * The minimum difference between a bid and the next higher bid on an auction.
   * @format double
   */
  minimumBidStep: number;
  /**
   * Minimum runtime of an auction in seconds.
   * @format double
   */
  minAuctionDurationInSeconds: number;
  /**
   * Maximum runtime of an auction in seconds.
   * @format double
   */
  maxAuctionDurationInSeconds: number;
  /** @format double */
  maxVehicleImageSize: number;
  /** @format double */
  defaultInstantPurchasePhaseDurationInSeconds: number;
  /** @format date-time */
  createdAt?: string;
}

/** Data structure for storing configuration settings for the business. */
export interface IBusinessDataConfig {
  /**
   * @deprecated
   * @format double
   */
  id: number;
  uuid: string;
  /**
   * Indicating the time when the entity has been created, i.e. when the entity was
   * persisted to the database.
   * @format date-time
   */
  createdAt?: string;
  /**
   * Indicating the last time a value of the entity has been changed (updated).
   * @format date-time
   */
  updatedAt?: string;
  /**
   * Indicating the time when the entity has been (softly) deleted.
   * "null" if entry is not deleted.
   * @format date-time
   */
  deletedAt?: string;
  /**
   * @deprecated
   * @format double
   */
  maxTimeInSecondsForSalesmanToPickup: number;
  /**
   * The fee (in EUR) that is due per day that a vehicle is not yet picked up after the pickup deadline
   * (9 days after the purchase has been confirmed).
   * @format double
   */
  latePickupFee: number;
  /** Link to a hosted version of the current damage catalog. */
  urlToDamageCatalog: string;
}

export interface IUsersConfig {
  /**
   * @deprecated
   * @format double
   */
  id: number;
  uuid: string;
  /** @format double */
  minDocumentSize: number;
  /** @format double */
  maxDocumentSize: number;
  /** @format double */
  sessionDuration: number;
  /** @format date-time */
  createdAt?: string;
}

/**
 * Inform that a new resource was created. Server will
 * add a Location header and set status to 201
 */
export interface ReturnNewResourceIEmailTemplateSettings {
  location: string;
  /** @format double */
  statusCode: number;
  /** the body to be sent */
  body: IEmailTemplateSettings;
}

export interface IRandomPasswordResponse {
  userUuid: string;
  newPassword: string;
}

/**
 * Inform that a new resource was created. Server will
 * add a Location header and set status to 201
 */
export interface ReturnNewResourceIGeneralUser {
  location: string;
  /** @format double */
  statusCode: number;
  /** the body to be sent */
  body: IGeneralUser;
}

/** Parameters for creating a new GeneralUser */
export interface IGeneralUserCreateParams {
  /** The GeneralUser data to create or update. */
  user: IGeneralUser;
  /** The GeneralUserSettings data to optionally create or update. */
  settings?: IGeneralUserSettings;
  plainPassword: string;
}

export enum EAuthenticationActivityType {
  Value0 = 0,
  Value1 = 1,
}

export interface IAuthenticationActivity {
  uuid: string;
  /** @format date-time */
  createdAt?: string;
  activity: EAuthenticationActivityType;
  deviceId: string;
  _fk_uuid_user: string;
  ipAddress?: string;
  deviceName?: string;
  city?: string;
  country?: string;
}

/**
 * Internal users (i.e. users that belong to COS like admins, sales agents, etc.)
 * @deprecated
 */
export interface IInternalUser {
  /**
   * @deprecated
   * @format double
   */
  id: number;
  uuid: string;
  /**
   * Indicating the time when the entity has been created, i.e. when the entity was
   * persisted to the database.
   * @format date-time
   */
  createdAt?: string;
  /**
   * Indicating the last time a value of the entity has been changed (updated).
   * @format date-time
   */
  updatedAt?: string;
  /**
   * Indicating the time when the entity has been (softly) deleted.
   * "null" if entry is not deleted.
   * @format date-time
   */
  deletedAt?: string;
  /** The userId generated by the IdP */
  userId?: string;
  /** The state of the user. */
  state: EUserState;
  /** The mail address of the user. */
  mailAddress: string;
  /** The mail address which is using for login. */
  loginMailAddress?: string;
  /** The password of the user. */
  password?: string;
  /** The privilege string, indicating what the user is allowed to do. */
  privileges: string;
  /** The user type. */
  type?: EUserType;
  /** Supply Key Account Managers manage relationships to sellers/dealerships. */
  hasRoleSupplyKeyAccountManager: boolean;
  /**
   * (Operations) Managers are able to e.g. create auctions from vehicle reports.
   * TODO: Will be replaced by primitive privilege system
   */
  hasRoleManager: boolean;
  /**
   * Evaluators are able to see requested vehicle valuations and to review auctions in the "auction draft review process".
   * TODO: Will be replaced by primitive privilege system
   */
  hasRoleEvaluator: boolean;
  /** Current version vector */
  versionVector: number[];
  /** Indicates this update comes from a remote system */
  isRemoteUpdate: boolean;
  /** Same as {@link IGeneralUser._fk_uuid_account }, added here for compatibility after migrating Internal Users to General Users */
  _fk_uuid_account: string;
  /** Same as {@link IGeneralUser.permissions }, added here for compatibility after migrating Internal Users to General Users */
  permissions: EGeneralUserPermission[];
  firstName: string;
  lastName: string;
  /** The personal phone number for this user. */
  phoneNumber: string;
  /** The public phone number advertised to customers. */
  customerPhoneNumber?: string;
  urlToProfilePicture: string;
  /** Salesforce users can use Salesforce (and consume licenses). */
  isSalesforceUser: boolean;
  /**
   * Controls behaviour of creating auctions and starting when right away
   * w/o any review. Should be applied for inspectors only (hasRoleEvaluator value).
   */
  hasDirectAuctionUploadPrivileges: boolean;
}

/**
 * Inform that a new resource was created. Server will
 * add a Location header and set status to 201
 */
export interface ReturnNewResourceIInternalUser {
  location: string;
  /** @format double */
  statusCode: number;
  /** the body to be sent */
  body: IInternalUser;
}

/** How internal users see seller users. */
export interface IInternalSellerView {
  /**
   * @deprecated
   * @format double
   */
  id: number;
  uuid: string;
  /**
   * Indicating the time when the entity has been created, i.e. when the entity was
   * persisted to the database.
   * @format date-time
   */
  createdAt?: string;
  /**
   * Indicating the last time a value of the entity has been changed (updated).
   * @format date-time
   */
  updatedAt?: string;
  /**
   * Indicating the time when the entity has been (softly) deleted.
   * "null" if entry is not deleted.
   * @format date-time
   */
  deletedAt?: string;
  /** The userId generated by the IdP */
  userId?: string;
  /** The state of the user. */
  state: EUserState;
  /** The mail address of the user. */
  mailAddress: string;
  /** The mail address which is using for login. */
  loginMailAddress?: string;
  /** The password of the user. */
  password?: string;
  /** The privilege string, indicating what the user is allowed to do. */
  privileges: string;
  /** The user type. */
  type?: EUserType;
  preferredLanguage: string;
  /**
   * Whether the customer wants to be addressed with honorific form
   * (e.g. "Sie" instead of "Du" in German)
   */
  honorific: boolean;
  /** The first name of the customer. */
  firstName: string;
  /** The last name of the customer. */
  lastName: string;
  /**
   * Multiple slots for phone numbers.
   * "null" if a slot is not used.
   */
  phoneNumber: string;
  phoneNumber2: string;
  phoneNumber3: string;
  /**
   * The referral code that was used by the customer when registering to COS.
   * "null" if no referral code has been used.
   */
  referralCode: string;
  isAllowedToSeeTradeIns: boolean;
  isAllowedToManageTradeIns: boolean;
  isAllowedToSeeTradeInRequests: boolean;
  isAllowedToSeeTradeInValuationSellPrice: boolean;
  isAllowedToSeeTradeInValuationBuyPrice: boolean;
  isAllowedToCreateAuctions: boolean;
  isAllowedToConfirmRejectBelowMin: boolean;
  isAllowedToSeePurchasePrices: boolean;
  isAllowedToSeeClosedAuctions: boolean;
  isAllowedToSeeDirectlyAssociatedAuctions: boolean;
  isAllowedToSeeAllAssociatedAuctions: boolean;
  isAllowedToChangeAccountData: boolean;
  /** Current version vector */
  versionVector: number[];
  /** Indicates this update comes from a remote system */
  isRemoteUpdate: boolean;
  /** Same as {@link IGeneralUser.permissions }, added here for compatibility after migrating Seller Users to General Users */
  permissions: EGeneralUserPermission[];
  /**
   * If this user is enabled for trade-in platform features, this is the preference with
   * which he wants incoming trade-in leads to be valuated.
   */
  tradeInValuationPreference: EDealershipTradeInValuationPreference;
  /** Flag indicating whether the seller user is allowed to make trade-in valuation requests (market price requests). */
  allowTradeInValuationRequests: boolean;
  /** Flag indicating whether the seller user is allowed to make guaranteed price requests. */
  allowGuaranteedPriceRequests: boolean;
  /**
   * The default value of this seller's margin in the Trade In Valuation (Market Price Request) view.
   * @format double
   */
  defaultTradeInValuationMargin: number;
  /** Reference to the SellerAccount to which the seller user is assigned to. */
  _fk_uuid_seller_account: string;
  /** Indicates whether the user itself should be used as the logistics contact for its own auctions. */
  isLogisticsContactForOwnAuctions: boolean;
  /** Same as {@link IGeneralUserSettings.notificationPreferences }, added here for compatibility after migrating Seller Users to General Users */
  notificationPreferences: IUserNotificationPreferences;
  /** Most recent internal memos for seller users. */
  memos: ICustomerMemo[];
  /** Auctions owned by the seller user (most recently created). */
  recentAuctions: IAuction[];
  /**
   * Number of total auctions owned by the seller user.
   * @format double
   */
  numTotalAuctions: number;
  /**
   * Number of successful auctions by the seller user.
   * @format double
   */
  numSuccessfulAuctions: number;
  /** @format double */
  averageRelativeMargin: number;
  /** @format double */
  totalAbsoluteMargin: number;
  /** @format double */
  volumeSuccessfulAuctions?: number;
  /** @format double */
  numAuctionsBelowMinAsk?: number;
  /** The internal user that is assigned as a supply manager to the given seller user. */
  assignedSupplyManager: IInternalUser;
  /** Basic Seller Account information */
  sellerAccount?: ISellerAccountBasicView;
}

/** How admin users see seller users. */
export interface IAdminSellerView {
  /**
   * @deprecated
   * @format double
   */
  id: number;
  uuid: string;
  /**
   * Indicating the time when the entity has been created, i.e. when the entity was
   * persisted to the database.
   * @format date-time
   */
  createdAt?: string;
  /**
   * Indicating the last time a value of the entity has been changed (updated).
   * @format date-time
   */
  updatedAt?: string;
  /**
   * Indicating the time when the entity has been (softly) deleted.
   * "null" if entry is not deleted.
   * @format date-time
   */
  deletedAt?: string;
  /** The userId generated by the IdP */
  userId?: string;
  /** The state of the user. */
  state: EUserState;
  /** The mail address of the user. */
  mailAddress: string;
  /** The mail address which is using for login. */
  loginMailAddress?: string;
  /** The password of the user. */
  password?: string;
  /** The privilege string, indicating what the user is allowed to do. */
  privileges: string;
  /** The user type. */
  type?: EUserType;
  preferredLanguage: string;
  /**
   * Whether the customer wants to be addressed with honorific form
   * (e.g. "Sie" instead of "Du" in German)
   */
  honorific: boolean;
  /** The first name of the customer. */
  firstName: string;
  /** The last name of the customer. */
  lastName: string;
  /**
   * Multiple slots for phone numbers.
   * "null" if a slot is not used.
   */
  phoneNumber: string;
  phoneNumber2: string;
  phoneNumber3: string;
  /**
   * The referral code that was used by the customer when registering to COS.
   * "null" if no referral code has been used.
   */
  referralCode: string;
  isAllowedToSeeTradeIns: boolean;
  isAllowedToManageTradeIns: boolean;
  isAllowedToSeeTradeInRequests: boolean;
  isAllowedToSeeTradeInValuationSellPrice: boolean;
  isAllowedToSeeTradeInValuationBuyPrice: boolean;
  isAllowedToCreateAuctions: boolean;
  isAllowedToConfirmRejectBelowMin: boolean;
  isAllowedToSeePurchasePrices: boolean;
  isAllowedToSeeClosedAuctions: boolean;
  isAllowedToSeeDirectlyAssociatedAuctions: boolean;
  isAllowedToSeeAllAssociatedAuctions: boolean;
  isAllowedToChangeAccountData: boolean;
  /** Current version vector */
  versionVector: number[];
  /** Indicates this update comes from a remote system */
  isRemoteUpdate: boolean;
  /** Same as {@link IGeneralUser.permissions }, added here for compatibility after migrating Seller Users to General Users */
  permissions: EGeneralUserPermission[];
  /**
   * If this user is enabled for trade-in platform features, this is the preference with
   * which he wants incoming trade-in leads to be valuated.
   */
  tradeInValuationPreference: EDealershipTradeInValuationPreference;
  /** Flag indicating whether the seller user is allowed to make trade-in valuation requests (market price requests). */
  allowTradeInValuationRequests: boolean;
  /** Flag indicating whether the seller user is allowed to make guaranteed price requests. */
  allowGuaranteedPriceRequests: boolean;
  /**
   * The default value of this seller's margin in the Trade In Valuation (Market Price Request) view.
   * @format double
   */
  defaultTradeInValuationMargin: number;
  /** Reference to the SellerAccount to which the seller user is assigned to. */
  _fk_uuid_seller_account: string;
  /** Indicates whether the user itself should be used as the logistics contact for its own auctions. */
  isLogisticsContactForOwnAuctions: boolean;
  /** Same as {@link IGeneralUserSettings.notificationPreferences }, added here for compatibility after migrating Seller Users to General Users */
  notificationPreferences: IUserNotificationPreferences;
  /** Most recent internal memos for seller users. */
  memos: ICustomerMemo[];
  /** Auctions owned by the seller user (most recently created). */
  recentAuctions: IAuction[];
  /**
   * Number of total auctions owned by the seller user.
   * @format double
   */
  numTotalAuctions: number;
  /**
   * Number of successful auctions by the seller user.
   * @format double
   */
  numSuccessfulAuctions: number;
  /** @format double */
  averageRelativeMargin: number;
  /** @format double */
  totalAbsoluteMargin: number;
  /** @format double */
  volumeSuccessfulAuctions?: number;
  /** @format double */
  numAuctionsBelowMinAsk?: number;
  /** The internal user that is assigned as a supply manager to the given seller user. */
  assignedSupplyManager: IInternalUser;
  /** Basic Seller Account information */
  sellerAccount?: ISellerAccountBasicView;
}

export interface PageIGeneralUser {
  items: IGeneralUser[];
  /** @format double */
  total: number;
  /** @format double */
  page: number;
  /** @format double */
  pageSize?: number;
}

/** Parameters for updating a GeneralUser */
export interface IGeneralUserUpdateParams {
  /** The GeneralUser data to create or update. */
  user: IGeneralUser;
  /** The GeneralUserSettings data to optionally create or update. */
  settings?: IGeneralUserSettings;
}

export enum TwoFactorAuthEvent {
  Activation = 'activation',
  Deactivation = 'deactivation',
}

export interface IMailAttachment {
  content: string;
  filename: string;
  type?: string;
  disposition?: string;
  contentId?: string;
}

export interface IMail {
  sendingAddress: string;
  receivingAddresses: string[];
  ccAddresses?: string[];
  bccAddresses?: string[];
  subject: string;
  content: string;
  messageType: ECustomerMessageType;
  attachments?: IMailAttachment[];
}

/** @deprecated */
export interface IBroadcastMailRequest {
  recipients: string[];
  mail: IMail;
  template?: EMailTemplate;
  templateParams: any;
}

export interface IPageIInternalSellerView {
  items: IInternalSellerView[];
  /** @format double */
  total: number;
  /** @format double */
  page: number;
  /** @format double */
  pageSize?: number;
}

export interface IPageITransporterUser {
  items: ITransporterUser[];
  /** @format double */
  total: number;
  /** @format double */
  page: number;
  /** @format double */
  pageSize?: number;
}

export interface IPageIGeneralUser {
  items: IGeneralUser[];
  /** @format double */
  total: number;
  /** @format double */
  page: number;
  /** @format double */
  pageSize?: number;
}

export interface IKAMSalesmanMemoView {
  /**
   * @deprecated
   * @format double
   */
  id: number;
  uuid: string;
  /**
   * Indicating the time when the entity has been created, i.e. when the entity was
   * persisted to the database.
   * @format date-time
   */
  createdAt?: string;
  /**
   * Indicating the last time a value of the entity has been changed (updated).
   * @format date-time
   */
  updatedAt?: string;
  /**
   * Indicating the time when the entity has been (softly) deleted.
   * "null" if entry is not deleted.
   * @format date-time
   */
  deletedAt?: string;
  _fk_uuid_creator: string;
  content: string;
  /**
   * This is the new user structure, meant to replace our old fragmented user structure with Sellers, Buyers and etc.
   * A user is someone that is connected to a company and uses our platform for an activity enabled for that account.
   * As we're a B2B platform, our focus is always the account/business, the user is just someone acting in the name of an account.
   */
  associatedCreator: IGeneralUser;
  _fk_uuid_customerUser: string;
  /** How internal users see buyer users. */
  associatedSalesmanUser: IInternalBuyerView;
}

export interface IPageIKAMSalesmanMemoView {
  items: IKAMSalesmanMemoView[];
  /** @format double */
  total: number;
  /** @format double */
  page: number;
  /** @format double */
  pageSize?: number;
}

/** Construct a type with a set of properties K of type T */
export type RecordStringNever = object;

/**
 * Inform that a new resource was created. Server will
 * add a Location header and set status to 201
 */
export interface ReturnNewResourceRecordStringNever {
  location: string;
  /** @format double */
  statusCode: number;
  /** the body to be sent */
  body: RecordStringNever;
}

export interface IPreRegisteredUserCreate {
  emailAddress: string;
  businessName: string;
  countryCode?: ECountryCode;
  acceptedBusinessTermsAndConditions?: boolean;
  preferredLanguage?: string;
  signup_url?: string;
  utm_camid?: string;
  utm_adgid?: string;
  utm_term?: string;
  utm_matchtype?: string;
  utm_device?: string;
  gclid?: string;
  client_id?: string;
  utm_content?: string;
  utm_campaign?: string;
  utm_medium?: string;
  utm_source?: string;
  traffic_type?: string;
  customer_segment?: string;
  first_touch_point?: string;
  last_touch_point?: string;
  last_attribution_date?: string;
  user_journey?: string;
  fbclid?: string;
  li_fat_id?: string;
  variant?: string;
  organic_touchpoints?: string;
}

/**
 * Inform that the resource has temporarily
 * moved to another location, but that future references should
 * still use the original URI to access the resource.
 */
export interface ReturnMovedTemporarilyVoid {
  location: string;
  /** @format double */
  statusCode: number;
  /** the body to be sent */
  body: any;
}

/**
 * Inform that a new resource was created. Server will
 * add a Location header and set status to 201
 */
export interface ReturnNewResourceITransporterUser {
  location: string;
  /** @format double */
  statusCode: number;
  /** the body to be sent */
  body: ITransporterUser;
}

export interface IDealershipSubordinateStatistic {
  /** @format double */
  numSuccessfulAuctions: number;
  /** The creation date for each auction of each user it is using to generate its statistics. */
  referenceUserAuctionCreationDates?: Record<string, string[]>;
  /**
   * The ending date for each successful auction of each user it is using to generate its statistics
   * (auctions in the CLOSED_WAITING_FOR_PAYMENT, CLOSED_WAITING_FOR_PICKUP or FINISHED state).
   */
  referenceUserSuccessfulAuctionEndingDates?: Record<string, string[]>;
  /**
   * The ending date for each failed auction of each user it is using to generate its statistics
   * (auctions in the CLOSED_NO_BIDS or CLOSED_UNDER_MINIMUM_ASK state).
   */
  referenceUserFailedAuctionEndingDates?: Record<string, string[]>;
  /** @format double */
  numNewVehicles: number;
  /** @format double */
  numRunningAuctions: number;
}

export type IDealershipSubordinateStatSeries = {
  statsPerPeriod: IDealershipSubordinateStatistic[];
  /**
   * This is the new user structure, meant to replace our old fragmented user structure with Sellers, Buyers and etc.
   * A user is someone that is connected to a company and uses our platform for an activity enabled for that account.
   * As we're a B2B platform, our focus is always the account/business, the user is just someone acting in the name of an account.
   */
  subordinate: IGeneralUser;
}[];

export interface IPageISellerUserWithAccountBasicView {
  items: ISellerUserWithAccountBasicView[];
  /** @format double */
  total: number;
  /** @format double */
  page: number;
  /** @format double */
  pageSize?: number;
}

/**
 * Inform that a new resource was created. Server will
 * add a Location header and set status to 201
 */
export interface ReturnNewResourceIUserDevice {
  location: string;
  /** @format double */
  statusCode: number;
  /** the body to be sent */
  body: IUserDevice;
}

export enum ETicketStatus {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
  SOLVED = 'SOLVED',
}

export enum EUserRequestTopic {
  PICK_UP = 'PICK_UP',
  TRANSPORT = 'TRANSPORT',
  PAYMENT = 'PAYMENT',
  BILLING = 'BILLING',
  PAYMENT_HANDLING = 'PAYMENT_HANDLING',
  DOCUMENTS = 'DOCUMENTS',
  CROSS_BORDER_TRANSACTION = 'CROSS_BORDER_TRANSACTION',
  OTHER = 'OTHER',
}

export enum EUserRequestReason {
  NO_PICKUP_SLIP_RECEIVED = 'NO_PICKUP_SLIP_RECEIVED',
  PROBLEMS_WITH_PICKUP_CONFIRMATION = 'PROBLEMS_WITH_PICKUP_CONFIRMATION',
  CANNOT_FETCH_VEHICLE_SELLER_ISSUES = 'CANNOT_FETCH_VEHICLE_SELLER_ISSUES',
  CANNOT_FETCH_VEHICLE_OTHER = 'CANNOT_FETCH_VEHICLE_OTHER',
  SELLER_NOT_ANSWER = 'SELLER_NOT_ANSWER',
  BUYER_CANNOT_PICKUP_VEHICLE = 'BUYER_CANNOT_PICKUP_VEHICLE',
  STAND_FEE = 'STAND_FEE',
  VEHICLE_RELEASE_DATE = 'VEHICLE_RELEASE_DATE',
  MISSING_VEHICLE_DOCUMENTS = 'MISSING_VEHICLE_DOCUMENTS',
  CANCELLATION_OF_TRANSPORT = 'CANCELLATION_OF_TRANSPORT',
  INCORRECT_TRANSPORT_BOOKING = 'INCORRECT_TRANSPORT_BOOKING',
  TRANSPORT_INVOICE = 'TRANSPORT_INVOICE',
  TRANSPORT_CHANGE_DELIVERY_ADDRESS = 'TRANSPORT_CHANGE_DELIVERY_ADDRESS',
  PICKUP_POWER_OF_ATTORNEY = 'PICKUP_POWER_OF_ATTORNEY',
  VEHICLE_COULD_NOT_BE_PICKED_UP = 'VEHICLE_COULD_NOT_BE_PICKED_UP',
  TRANSPORT_DELAY = 'TRANSPORT_DELAY',
  ARRIVAL_OF_TRANSPORT = 'ARRIVAL_OF_TRANSPORT',
  NOTICE_OF_NON_PAYMENT_BUYER = 'NOTICE_OF_NON_PAYMENT_BUYER',
  TECHNICAL_PROBLEM_MONEY_TRANSFER = 'TECHNICAL_PROBLEM_MONEY_TRANSFER',
  TRANSFER_RECEIPT = 'TRANSFER_RECEIPT',
  MISSING_CREDIT_NOTE = 'MISSING_CREDIT_NOTE',
  COS_ACCOUNT_LOCKED = 'COS_ACCOUNT_LOCKED',
  MISSING_VOUCHER = 'MISSING_VOUCHER',
  SALES_FEE_INVOICE = 'SALES_FEE_INVOICE',
  VEHICLE_INVOICE = 'VEHICLE_INVOICE',
  DIRECT_PAYMENT_TO_COS = 'DIRECT_PAYMENT_TO_COS',
  REJECTION_PURCHASE_ACCOUNT_REQUEST = 'REJECTION_PURCHASE_ACCOUNT_REQUEST',
  REQUEST_PURCHASE_CONTRACT = 'REQUEST_PURCHASE_CONTRACT',
  REFUND_DEPOSIT = 'REFUND_DEPOSIT',
  CMR = 'CMR',
  CONFIRMATION_OF_ARRIVAL = 'CONFIRMATION_OF_ARRIVAL',
  OTHER = 'OTHER',
}

export enum EUserRequestMissingDocuments {
  REGISTRATION_DOCUMENT = 'REGISTRATION_DOCUMENT',
  IDENTIFICATION_DOCUMENT = 'IDENTIFICATION_DOCUMENT',
  COC = 'COC',
  SERVICE_BOOKLET = 'SERVICE_BOOKLET',
}

export interface IUserRequestAdditionalFields {
  missingDocuments?: EUserRequestMissingDocuments[];
}

export interface IUserRequest {
  uuid: string;
  /**
   * Indicating the time when the entity has been created, i.e. when the entity was
   * persisted to the database.
   * @format date-time
   */
  createdAt?: string;
  /**
   * Indicating the last time a value of the entity has been changed (updated).
   * @format date-time
   */
  updatedAt?: string;
  /**
   * Indicating the time when the entity has been (softly) deleted.
   * "null" if entry is not deleted.
   * @format date-time
   */
  deletedAt?: string;
  /** Reference to the user who submitted the request */
  _fk_uuid_user: string;
  /** Reference to ticket on the external system (Salesforce Case UUID) */
  externalTicketUUID: string;
  /** Ticket description */
  description: string;
  /** Ticket status */
  ticketStatus: ETicketStatus;
  /** Request related topic */
  topic: EUserRequestTopic;
  /** Request reason */
  requestReason: EUserRequestReason;
  /** Attachments */
  attachments: string[];
  /** Reference to the auction related to the request */
  _fk_uuid_auction?: string;
  /** Additional fields depending on the request reason */
  additionalFields?: IUserRequestAdditionalFields;
}

export interface ICreateUserRequest {
  /** Attachments */
  attachments: string[];
  /** Ticket description */
  description: string;
  /** Ticket status */
  ticketStatus: ETicketStatus;
  /** Request related topic */
  topic: EUserRequestTopic;
  /** Request reason */
  requestReason: EUserRequestReason;
  /** Additional fields depending on the request reason */
  additionalFields?: IUserRequestAdditionalFields;
  auctionExternalId?: string;
}

/** Represents one entry for purchase fee. */
export interface IPurchaseFeeBucketPrice {
  uuid: string;
  /**
   * Indicating the time when the entity has been created, i.e. when the entity was
   * persisted to the database.
   * @format date-time
   */
  createdAt?: string;
  /**
   * Indicating the last time a value of the entity has been changed (updated).
   * @format date-time
   */
  updatedAt?: string;
  /**
   * Indicating the time when the entity has been (softly) deleted.
   * "null" if entry is not deleted.
   * @format date-time
   */
  deletedAt?: string;
  /**
   * min price from which fee is applying.
   * @format double
   */
  minValue: number;
  /**
   * Fee value (net) in EUR.
   * @format double
   */
  feeNet: number;
}

/** Make all properties in T optional */
export interface PartialRecordEPublicEnvironmentConfigKeysString {
  USE_CUSTOMIZED_AUCTION_SCHEDULING_STRATEGY?: string;
  GUARANTEE_FEATURE_ENABLED?: string;
  DEFAULT_AUCTION_ENDING_HOUR?: string;
  DEFAULT_AUCTION_EVENING_ENDING_HOUR?: string;
  DEFAULT_AUCTION_HOT_BID_DURATION_SECONDS?: string;
  AUCTION_SLOTTING_INTERVAL_IN_MIN?: string;
  AUCTION_ROOM_FINALIZATION_HOUR?: string;
}

export enum EAsyncJobStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  DONE = 'DONE',
  FAIL = 'FAIL',
}

export interface IAsyncJobAny {
  uuid?: string;
  status: EAsyncJobStatus;
  result?: any;
  createdBy?: string;
  /** @format date-time */
  createdAt?: string;
  /** @format date-time */
  updatedAt?: string;
}

export interface IVehiclesData {
  uuid: string;
  make?: string;
  model?: string;
  vin?: string;
  ez?: string;
  hasCoCPapers?: boolean;
  isAdditionalTaxPaid?: boolean;
  /** @format double */
  previousOwners?: number;
  comments?: string;
  attachments?: string[];
  knownDamages?: string;
  /** @format double */
  purchasePrice?: number;
  /** @format double */
  minimumPrice?: number;
  isTaxationDifferential?: boolean;
  /** @format double */
  mileage?: number;
  /** @format double */
  position?: number;
  _fk_uuid_request?: string;
  _fk_uuid_appointment?: string | null;
  requestUserUuid: string;
}

export enum EInspectionAppointmentStatus {
  NONE = 'NONE',
  CANCELED = 'CANCELED',
  SCHEDULED = 'SCHEDULED',
  DISPATCHED = 'DISPATCHED',
  IN_PROGRESS = 'IN_PROGRESS',
  CANNOT_COMPLETE = 'CANNOT_COMPLETE',
  COMPLETED = 'COMPLETED',
}

export interface InspectionAppointmentForInspectorDto {
  uuid: string;
  status: EInspectionAppointmentStatus;
  /** @format double */
  numberOfVehicles: number;
  addressLine: string;
  addressZipCode: string;
  addressCity: string;
  addressCountryCode: ECountryCode;
  /** @format date-time */
  scheduledStartDateTime: string;
  /** @format date-time */
  scheduledEndDateTime: string;
  _fk_uuid_seller_user: string;
  _fk_uuid_inspector_user: string;
  _fk_uuid_seller_account: string;
  sellerAccount: {
    name: string;
    uuid: string;
  };
  vehiclesWithData: IVehiclesData[];
}

export interface InspectionAppointmentForSellerDto {
  uuid: string;
  status: EInspectionAppointmentStatus;
  /** @format double */
  numberOfVehicles: number;
  addressLine: string;
  addressZipCode: string;
  addressCity: string;
  addressCountryCode: ECountryCode;
  /** @format date-time */
  scheduledStartDateTime: string;
  /** @format date-time */
  scheduledEndDateTime: string;
  _fk_uuid_seller_user: string;
  _fk_uuid_inspector_user: string;
  _fk_uuid_seller_account: string;
  assignedInspector: {
    lastName: string;
    firstName: string;
    uuid: string;
  };
  vehiclesWithData: IVehiclesData[];
}

export interface IPageInspectionAppointmentForSellerDto {
  items: InspectionAppointmentForSellerDto[];
  /** @format double */
  total: number;
  /** @format double */
  page: number;
  /** @format double */
  pageSize?: number;
}

export interface IHasInspectionAppointmentCountsByState {
  countsByState: {
    /** @format double */
    NONE?: number;
    /** @format double */
    CANCELED?: number;
    /** @format double */
    SCHEDULED?: number;
    /** @format double */
    DISPATCHED?: number;
    /** @format double */
    IN_PROGRESS?: number;
    /** @format double */
    CANNOT_COMPLETE?: number;
    /** @format double */
    COMPLETED?: number;
  };
}

/** The structure for creating auctions via the Inspector App */
export interface IInspectionAuctionCreationParams {
  /** Indication that the inspector found a non-technical deviation between the gpr "auto inspection" made by seller and his assessment */
  gprHasNonTechnicalDeviation?: boolean;
  /** Indication that the inspector found a technical deviation between the gpr "auto inspection" made by seller and his assessment */
  gprHasTechnicalDeviation?: boolean;
  /** A description that the inspector made about the non-technical deviation found */
  gprNonTechnicalDeviationDescription?: string;
  /** A description that the inspector made about the technical deviation found */
  gprTechnicalDeviationDescription?: string;
  label?: string;
  /** @format double */
  minimumRequiredAsk: number;
  /** @format double */
  purchasePrice: number;
  vatIdReportable: boolean;
  sellerUserUuid: string;
  bankAccountUuid: string;
  /** ending time of the auction, as a ISO string date format */
  endingTime: string;
  vin: string;
  inspectionUuid: string;
  pickupPinUuid: string;
  pickupPinCode: string;
  locationAddress: string;
  locationZip: string;
  locationCity: string;
  isTest: boolean;
}

/** Represents an Auction created by an Inspector */
export interface IInspectorAuction {
  /** @format double */
  id: number;
  uuid: string;
  label: string;
  directUploadRejectionReasons: EAuctionDirectUploadRejectionReason[];
  associatedVehicle: {
    urlToAttachment1: string;
  };
}

/** From T, pick a set of properties whose keys are in the union K */
export interface PickIAccountAddressExcludeKeysAddressHashOrFkUuidAccountOrCreatedAtOrUpdatedAtOrDeletedAtOrVersionVectorOrIsRemoteUpdate {
  uuid: string;
  /**
   * GPS coordinates of the auction (can be used for e.g. calculating distances between auctions and buyers).
   * @format double
   */
  locationGeoLat: number;
  /** @format double */
  locationGeoLon: number;
  label?: string;
  city: string;
  countryCode: ECountryCode;
  zipCode?: string;
  addressLine?: string;
  /** Add extra comments do the location (e.g., pick-up instruction in transportation tasks). */
  comment?: string;
  /** Language of the contact person at the location. */
  contactLanguage?: string;
  addressTypes: EAddressType[];
  typesSettings?: IAccountAddressTypeSettings[];
  contactFirstName?: string;
  contactLastName?: string;
  contactEmail?: string;
  contactPhoneNumber?: string;
}

/** Construct a type with the properties of T except for those in type K. */
export type OmitIAccountAddressAddressHashOrFkUuidAccountOrCreatedAtOrUpdatedAtOrDeletedAtOrVersionVectorOrIsRemoteUpdate =
  PickIAccountAddressExcludeKeysAddressHashOrFkUuidAccountOrCreatedAtOrUpdatedAtOrDeletedAtOrVersionVectorOrIsRemoteUpdate;

/** Make all properties in T optional */
export interface PartialISellerBankAccount {
  /** The seller account owning this bank account. */
  _fk_uuid_sellerAccount?: string;
  /**
   * A reference to an external bank account object in an external payment system such as Stripe.
   * It is important to ensure that the IBAN in the bank account object is matching to the IBAN that
   * the external account is pointing to.
   */
  externalAccountReference?: string;
  /** A label (alias) to identify the label. */
  label?: string;
  /** The bank account number (IBAN) for the bank account. */
  iban?: string;
  /**
   * Indicating the time when the entity has been created, i.e. when the entity was
   * persisted to the database.
   * @format date-time
   */
  createdAt?: string;
  /**
   * Indicating the last time a value of the entity has been changed (updated).
   * @format date-time
   */
  updatedAt?: string;
  /**
   * Indicating the time when the entity has been (softly) deleted.
   * "null" if entry is not deleted.
   * @format date-time
   */
  deletedAt?: string;
  uuid?: string;
}

export interface IInspectorSellerView {
  uuid: string;
  name: string;
  dealershipName: string;
  /** @deprecated */
  zipCode: string;
  /** @deprecated */
  addressLine: string;
  /** @deprecated */
  city: string;
  /** @deprecated */
  countryCode: ECountryCode;
  /** Addresses of the user account (company of this seller) */
  addresses: OmitIAccountAddressAddressHashOrFkUuidAccountOrCreatedAtOrUpdatedAtOrDeletedAtOrVersionVectorOrIsRemoteUpdate[];
  bankAccounts: PartialISellerBankAccount[];
}

export interface IPageIInspectorSellerView {
  items: IInspectorSellerView[];
  /** @format double */
  total: number;
  /** @format double */
  page: number;
  /** @format double */
  pageSize?: number;
}

export interface IPriceRequestResponse {
  /** Also referred to as a Market Price Request. */
  mpr: ITradeInValuationRequest;
  /** An object which holds all the data required to make the MPR/Trade-in details dashboard. */
  mprDetails: ITradeInValuationResultDetails;
  /**
   * Object description of the GPR object.
   * Most fields are optional because GPRs can be created in a draft state with minimal data and edited from there on.
   * Validation of completion, therefore, takes place upon submission rather than object creation.
   */
  gpr: IGuaranteedPriceRequest;
}

export enum EVehicleImportVehicleStatus {
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  WARNING = 'WARNING',
  NOT_FOUND = 'NOT_FOUND',
  ERROR = 'ERROR',
}

export enum EVehicleImportVehicleMessage {
  VehicleImportNewAuctionCreated = 'vehicle-import.new-auction-created',
  VehicleImportAuctionUpdated = 'vehicle-import.auction-updated',
  VehicleImportAuctionClonedAndUpdated = 'vehicle-import.auction-cloned-and-updated',
  VehicleImportAuctionRunning = 'vehicle-import.auction-running',
  VehicleImportIncompleteVehicleData = 'vehicle-import.incomplete-vehicle-data',
  VehicleImportAuctionAlreadyFinished = 'vehicle-import.auction-already-finished',
  VehicleImportAuctionIsRunning = 'vehicle-import.auction-is-running',
  VehicleImportAuctionHasRenegotiation = 'vehicle-import.auction-has-renegotiation',
  VehicleImportAuctionCreationFailed = 'vehicle-import.auction-creation-failed',
  VehicleImportAuctionReinsertionFailed = 'vehicle-import.auction-reinsertion-failed',
  VehicleImportVehicleFetchingFailed = 'vehicle-import.vehicle-fetching-failed',
}

export interface IVehicleImportVehicle {
  uuid: string;
  /**
   * Indicating the time when the entity has been created, i.e. when the entity was
   * persisted to the database.
   * @format date-time
   */
  createdAt?: string;
  /**
   * Indicating the last time a value of the entity has been changed (updated).
   * @format date-time
   */
  updatedAt?: string;
  /**
   * Indicating the time when the entity has been (softly) deleted.
   * "null" if entry is not deleted.
   * @format date-time
   */
  deletedAt?: string;
  _fk_uuid_vehicle_import: string;
  vin: string;
  status: EVehicleImportVehicleStatus;
  messages: EVehicleImportVehicleMessage[];
}

export enum EVehicleImportOrigin {
  MERCEDES_BENZ_AT = 'MERCEDES_BENZ_AT',
  MERCEDES_BENZ_FR = 'MERCEDES_BENZ_FR',
  ALPHA_EASY_CAR_SALES_TIEMEYER = 'ALPHA_EASY_CAR_SALES_TIEMEYER',
}

export interface IVehicleImportView {
  uuid: string;
  /**
   * Indicating the time when the entity has been created, i.e. when the entity was
   * persisted to the database.
   * @format date-time
   */
  createdAt?: string;
  /**
   * Indicating the last time a value of the entity has been changed (updated).
   * @format date-time
   */
  updatedAt?: string;
  /**
   * Indicating the time when the entity has been (softly) deleted.
   * "null" if entry is not deleted.
   * @format date-time
   */
  deletedAt?: string;
  origin: EVehicleImportOrigin;
  vehicles: IVehicleImportVehicle[];
}

export interface IVehicleImportXlsxDto {
  origin: EVehicleImportOrigin;
  file: IFile;
}

export interface IVehicleCleanMakeModel {
  /** @format double */
  makeId: number;
  make: string;
  /** @format double */
  modelId: number;
  model: string;
  /** @format double */
  groupId: number;
  group: string;
}

export interface IVehicleCleanMake {
  /** @format double */
  makeId: number;
  make: string;
}

export interface MoveToRoomRequest {
  auctionUuids: string[];
  targetRoomUuid: string;
}
