import { IAuction } from '@caronsale/cos-models';
import { ERenegotiationState, RenegotiationData } from './renegotiation-data';

export enum ERenegotiationWaitState {
  WAITING_FOR_BOTH,
  WAITING_FOR_BUYER,
  WAITING_FOR_SELLER,
  RENEGOTIATION_FAILED,
  NOT_STARTED,
}

export interface IAdminRenegotiationState {
  readonly round: number;
  readonly totalRounds: number;
  readonly waitState: ERenegotiationWaitState;
  readonly requiresManualIntervention: boolean;
  readonly manualInterventionKey: string;
}

export class CrmAdminRenegotiationData extends RenegotiationData {
  public constructor(public auction: IAuction) {
    super(auction); // determine renegotiationState
  }

  public getStatus(): IAdminRenegotiationState {
    const renegotiationRoundData = this.getRenegotiationRoundData();
    const round =
      this.renegotiationState === ERenegotiationState.INITIAL_TEST_CAR || this.renegotiationState === ERenegotiationState.INITIAL_WAITING_FOR_MANUAL_ACTION
        ? 0
        : this.isRenegotiationStillOngoing()
          ? Math.min(renegotiationRoundData.buyer.currentRound, renegotiationRoundData.seller.currentRound)
          : renegotiationRoundData.totalRounds;

    return {
      round,
      totalRounds: renegotiationRoundData.totalRounds,
      waitState: this.getWaitState(),
      requiresManualIntervention: this.requiresManualIntervention(),
      manualInterventionKey: this.getManualInterventionKey(),
    };
  }

  private getWaitState(): ERenegotiationWaitState {
    switch (this.renegotiationState) {
      case ERenegotiationState.INITIAL:
      case ERenegotiationState.NEW_ROUND:
      case ERenegotiationState.TOO_CLOSE:
        return ERenegotiationWaitState.WAITING_FOR_BOTH;

      case ERenegotiationState.INITIAL_BUYER_SENT_AN_OFFER_WAITING_FOR_SELLER:
      case ERenegotiationState.INITIAL_BUYER_HAS_WITHDRAWN_WAITING_FOR_SELLER:
      case ERenegotiationState.NEW_ROUND_BUYER_HAS_WITHDRAWN_WAITING_FOR_SELLER:
      case ERenegotiationState.NEW_ROUND_BUYER_SENT_AN_OFFER_WAITING_FOR_SELLER:
      case ERenegotiationState.TOO_CLOSE_BUYER_ACCEPTED_MIDPOINT_WAITING_FOR_SELLER:
      case ERenegotiationState.TOO_CLOSE_BUYER_HAS_WITHDRAWN_WAITING_FOR_SELLER:
        return ERenegotiationWaitState.WAITING_FOR_SELLER;

      case ERenegotiationState.INITIAL_SELLER_SENT_AN_OFFER_WAITING_FOR_BUYER:
      case ERenegotiationState.INITIAL_SELLER_HAS_WITHDRAWN_WAITING_FOR_BUYER:
      case ERenegotiationState.NEW_ROUND_SELLER_SENT_AN_OFFER_WAITING_FOR_BUYER:
      case ERenegotiationState.NEW_ROUND_SELLER_HAS_WITHDRAWN_WAITING_FOR_BUYER:
      case ERenegotiationState.TOO_CLOSE_SELLER_ACCEPTED_MIDPOINT_WAITING_FOR_BUYER:
      case ERenegotiationState.TOO_CLOSE_SELLER_HAS_WITHDRAWN_WAITING_FOR_BUYER:
        return ERenegotiationWaitState.WAITING_FOR_BUYER;

      case ERenegotiationState.INITIAL_BUYER_HAS_WITHDRAWN:
      case ERenegotiationState.INITIAL_BOTH_HAVE_WITHDRAWN:
      case ERenegotiationState.INITIAL_SELLER_HAS_WITHDRAWN:
      case ERenegotiationState.NEW_ROUND_BUYER_HAS_WITHDRAWN:
      case ERenegotiationState.NEW_ROUND_SELLER_HAS_WITHDRAWN:
      case ERenegotiationState.NEW_ROUND_BOTH_HAVE_WITHDRAWN:
      case ERenegotiationState.TOO_CLOSE_SELLER_HAS_WITHDRAWN_BUYER_ACCEPTED_MIDPOINT:
      case ERenegotiationState.TOO_CLOSE_BUYER_HAS_WITHDRAWN_SELLER_ACCEPTED_MIDPOINT:
      case ERenegotiationState.TOO_CLOSE_BOTH_HAVE_WITHDRAWN:
        return ERenegotiationWaitState.RENEGOTIATION_FAILED;

      case ERenegotiationState.INITIAL_TEST_CAR:
      case ERenegotiationState.INITIAL_WAITING_FOR_MANUAL_ACTION:
        return ERenegotiationWaitState.NOT_STARTED;
    }
  }

  private requiresManualIntervention(): boolean {
    return !this.isRenegotiationStillOngoing();
  }

  private getManualInterventionKey(): string {
    switch (this.renegotiationState) {
      case ERenegotiationState.INITIAL_BUYER_HAS_WITHDRAWN_WAITING_FOR_SELLER:
      case ERenegotiationState.NEW_ROUND_BUYER_HAS_WITHDRAWN_WAITING_FOR_SELLER:
      case ERenegotiationState.TOO_CLOSE_BUYER_HAS_WITHDRAWN_WAITING_FOR_SELLER:
      case ERenegotiationState.INITIAL_BUYER_HAS_WITHDRAWN:
      case ERenegotiationState.NEW_ROUND_BUYER_HAS_WITHDRAWN:
      case ERenegotiationState.TOO_CLOSE_BUYER_HAS_WITHDRAWN_SELLER_ACCEPTED_MIDPOINT:
        if (this.auction.wasRenegotiationStoppageByBuyerAutomatic) {
          return 'crm.renegotiation.intervention-reason.buyer-withdrew-by-timeout';
        }
        return 'crm.renegotiation.intervention-reason.buyer-has-withdrawn';

      case ERenegotiationState.INITIAL_SELLER_HAS_WITHDRAWN_WAITING_FOR_BUYER:
      case ERenegotiationState.NEW_ROUND_SELLER_HAS_WITHDRAWN_WAITING_FOR_BUYER:
      case ERenegotiationState.TOO_CLOSE_SELLER_HAS_WITHDRAWN_WAITING_FOR_BUYER:
      case ERenegotiationState.INITIAL_SELLER_HAS_WITHDRAWN:
      case ERenegotiationState.NEW_ROUND_SELLER_HAS_WITHDRAWN:
      case ERenegotiationState.TOO_CLOSE_SELLER_HAS_WITHDRAWN_BUYER_ACCEPTED_MIDPOINT:
        if (this.auction.wasRenegotiationStoppageBySellerAutomatic) {
          return 'crm.renegotiation.intervention-reason.seller-withdrew-by-timeout';
        }
        return 'crm.renegotiation.intervention-reason.seller-has-withdrawn';

      case ERenegotiationState.INITIAL_BOTH_HAVE_WITHDRAWN:
      case ERenegotiationState.NEW_ROUND_BOTH_HAVE_WITHDRAWN:
      case ERenegotiationState.TOO_CLOSE_BOTH_HAVE_WITHDRAWN:
        if (this.auction.wasRenegotiationStoppageByBuyerAutomatic && this.auction.wasRenegotiationStoppageBySellerAutomatic) {
          return 'crm.renegotiation.intervention-reason.both-withdrew-by-timeout';
        }
        if (this.auction.wasRenegotiationStoppageByBuyerAutomatic) {
          return 'crm.renegotiation.intervention-reason.seller-has-withdrawn-buyer-withdrew-by-timeout';
        }
        if (this.auction.wasRenegotiationStoppageBySellerAutomatic) {
          return 'crm.renegotiation.intervention-reason.buyer-has-withdrawn-seller-withdrew-by-timeout';
        }
        return 'crm.renegotiation.intervention-reason.both-have-withdrawn';

      case ERenegotiationState.INITIAL_TEST_CAR:
        return 'crm.renegotiation.intervention-reason.test-car-needs-minimum-ask';

      case ERenegotiationState.INITIAL_WAITING_FOR_MANUAL_ACTION:
        return 'crm.renegotiation.intervention-reason.waiting-for-manual-action';
    }
  }
}
