import { computed, input, Directive, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { IAuctionDiscount, IBuyerAuctionView, EAuctionDiscountReason } from '@caronsale/cos-models';
import { EnzoIcon } from '@caronsale/enzo';
import { EnzoBannerVariations } from '@enzo';
import { ProductAnalyticsService } from '@cosCoreServices/product-analytics/product-analytics.service';

type LoyaltyProgramTierConfig = {
  translationKey: string;
  icon: EnzoIcon['icon'];
  variation: EnzoBannerVariations;
};

export const LOYALTY_PROGRAM_CONFIG: Record<string, LoyaltyProgramTierConfig> = {
  BRONZE: {
    translationKey: 'buyer.loyalty-program.status.bronze',
    icon: 'trophy-bronze',
    variation: 'warning',
  },
  SILVER: {
    translationKey: 'buyer.loyalty-program.status.silver',
    icon: 'trophy-silver',
    variation: 'muted',
  },
  GOLD: {
    translationKey: 'buyer.loyalty-program.status.gold',
    icon: 'trophy-gold',
    variation: 'attention',
  },
  PLATINUM: {
    translationKey: 'buyer.loyalty-program.status.platinum',
    icon: 'trophy-platinum',
    variation: 'highlight',
  },
};

@Directive()
export abstract class LoyaltyDiscountAwareComponent {
  public auction = input.required<IBuyerAuctionView>();

  public displayLoyaltyProgramFF = toSignal(inject(ProductAnalyticsService).isOn('eg-frequent-buyer-program'));

  public activeLoyaltyDiscount = computed(() => {
    if (!this.displayLoyaltyProgramFF()) {
      return null;
    }

    const discounts = this.auction().appliedDiscounts;

    if (!discounts) {
      return null;
    }

    const discount: IAuctionDiscount = discounts.find(discount => discount.reason === EAuctionDiscountReason.FREQUENT_BUYER_PROGRAM);

    return discount;
  });

  public activeLoyaltyProgramDiscountPercentage = computed(() => {
    return (this.activeLoyaltyDiscount()?.value || 0) * 100;
  });

  public selectedTierConfig = computed(() => {
    return LOYALTY_PROGRAM_CONFIG[this.activeLoyaltyDiscount()?.label.toUpperCase()];
  });
}
