import { ImageLoaderConfig } from '@angular/common';
import { environment } from '@cosCoreEnvironments/environment';

function normalizeSrc(src: string): string {
  return src.startsWith('/') ? src.slice(1) : src;
}

function removeFileFormat(src: string): string {
  return src.replace(/\.[^.]+$/, '');
}

export function getFilePathFromUrl(src: string): string {
  return src.split('/upload/')[1];
}

export function customCloudinaryLoader(config: ImageLoaderConfig) {
  const envPath = environment.useProdCloudinaryAccount ? 'castle-tech-gmbh' : 'castle-tech-gmbh-dev';
  config.src = normalizeSrc(config.src);

  if (config.loaderParams?.['getOriginal']) {
    return `https://res.cloudinary.com/${envPath}/image/upload/${config.src}`;
  }

  let params = `f_auto,q_auto`;
  if (config.width) {
    params += `,w_${config.width}`;
  }

  if (config.loaderParams?.['getVideoThumbnail']) {
    return `https://res.cloudinary.com/${envPath}/video/upload/${params}/${removeFileFormat(config.src)}.jpg`;
  }

  return `https://res.cloudinary.com/${envPath}/image/upload/${params}/${config.src}`;
}
