import { ChangeDetectionStrategy, Component, input, signal } from '@angular/core';
import { EnzoTextComponent } from '../../typography/enzo-text/enzo-text.component';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { EnzoCheckboxToggleBaseComponent } from './enzo-checkbox-toggle-base.component';

@Component({
  selector: 'enzo-toggle',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './enzo-checkbox-toggle.component.html',
  styleUrls: ['../enzo-checkbox-toggle-radio-texts.scss', './enzo-toggle.component.scss'],
  standalone: true,
  imports: [EnzoTextComponent],
  host: {
    '(focusout)': 'isTouched.set(true); onTouched()',
    '(keydown.enter)': 'checkbox().nativeElement.click()',
    '[attr.checked]': '_checked()',
    '[attr.disabled]': '_disabled()',
    '[attr.data-label-position]': 'labelPosition()',
    '[attr.data-size]': 'size()',
  },
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: EnzoToggleComponent,
      multi: true,
    },
  ],
})
export class EnzoToggleComponent extends EnzoCheckboxToggleBaseComponent {
  public size = input<'small' | 'large'>('small');
  protected _indeterminate = signal(false); // no Input, always false, just needed for the common template
}
