import { EAuctionHighlight } from '@caronsale/cos-models';
import { Color, Icon } from '@caronsale/enzo';

type IconOptions = {
  enzoIcon?: Icon;
  iconFromSource?: string;
};

export type AuctionHighlightConfig = {
  key: EAuctionHighlight;
  icon?: IconOptions;
  color: Color;
  labelKey: string;
};

export const AUCTION_HIGHLIGHTS_CONFIGS: Record<EAuctionHighlight, AuctionHighlightConfig> = {
  [EAuctionHighlight.SELECTIVE_WARE]: {
    key: EAuctionHighlight.SELECTIVE_WARE,
    icon: { iconFromSource: 'caronsale/look-and-feel/mercedes/partner-icon' },
    color: 'yellow',
    labelKey: 'auction.selective-ware',
  },
  [EAuctionHighlight.SELECTIVE_WARE_PLUS]: {
    key: EAuctionHighlight.SELECTIVE_WARE_PLUS,
    icon: { iconFromSource: 'caronsale/look-and-feel/mercedes/partner-icon' },
    color: 'yellow',
    labelKey: 'auction.selective-ware-plus',
  },
};
