<enzo-headline level="6">{{ 'auction.guarantee-dialog.title' | translate }}</enzo-headline>
<app-prebooked-guarantee
  [auction]="data.auction"
  [currentPrebookedServices]="data.prebookedServices"
  (guaranteeSelectionChange)="onGuaranteeSelectionChange($event)" />

<div class="confirmation" *ngIf="requireGuaranteeTerms" testid="terms-and-conditions">
  <mat-checkbox [(ngModel)]="guaranteeTermsAccepted" />
  <div class="confirmation-text">
    <enzo-text
      kind="caption"
      [innerHTML]="'prebooked-service.guarantee.accept-terms-and-conditions-text.message' | translate: { messageTemplate: confirmationTemplate }" />
    <enzo-text class="error-message" color="red-dark" kind="caption" *ngIf="!guaranteeTermsAccepted && isConfirmedAndInvalid" testid="error-message">
      {{ 'error.terms-and-conditions-error' | translate }}
    </enzo-text>
  </div>
</div>
