<mat-dialog-content>
  <div class="message" [innerHTML]="biddingConfirmationData.translationBaseKey + '.message' | translate: biddingConfirmationData.translationParams"></div>
  <ng-container *ngIf="!showBasicConfirmationDialog">
    <app-prebooked-services
      view="CONFIRMATION_MODAL"
      [auction]="auction"
      [auctionPrice]="bidValue"
      [currentPrebookedServices]="prebookedServices"
      [displayBuyNowPayLaterDisclaimer]="true"
      [displayPrebookedSevices]="false"
      (serviceSelectionChange)="onServiceSelectionChange($event)"
      (requireGuaranteeTermsAcceptance)="onRequireGuaranteeTermsAcceptance($event)" />
  </ng-container>
  <app-voucher-selection [auction]="auction" />
  <app-fee-info [auction]="auction" [currentPrebookedServices]="prebookedServices" [showPrebookedServicesFees]="false" [highestBidOrNextBid]="bidValue" />
</mat-dialog-content>
<mat-dialog-actions>
  <mat-checkbox *ngIf="requireGuaranteeTerms" [(ngModel)]="guaranteeTermsAccepted">
    <span [innerHTML]="'prebooked-service.guarantee.accept-terms-and-conditions-text.message' | translate: { messageTemplate: confirmationTemplate }"></span>
  </mat-checkbox>

  <div class="buttons">
    <button mat-stroked-button testid="btn-decline" (click)="onDecline()">
      {{ biddingConfirmationData.translationBaseKey + '.reject' | translate: biddingConfirmationData.translationParams }}
    </button>
    <button
      mat-flat-button
      color="primary"
      testid="btn-confirm"
      [disabled]="requireGuaranteeTerms && !guaranteeTermsAccepted"
      (click)="onConfirm()"
      data-cy="bidding_confirm-bid-button">
      <span
        [matTooltip]="requireGuaranteeTerms && !guaranteeTermsAccepted ? ('prebooked-service.guarantee.accept-terms-and-conditions-tooltip' | translate) : ''">
        {{ biddingConfirmationData.translationBaseKey + '.confirm' | translate: biddingConfirmationData.translationParams }}
      </span>
    </button>
  </div>
</mat-dialog-actions>
