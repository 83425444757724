<!--<app-loading-layer [showLoadingLayerWhen]="this.busy"></app-loading-layer>-->
<div fxLayout="column">
  <div fxLayout="row">
    <h2>{{ 'transportationProviderSchedule.title' | translate }}</h2>
  </div>

  <div fxFlex="2" fxFlexOffset="2"></div>

  <div fxLayout="row">
    <div fxFlex="100">
      <div *ngFor="let daySchedule of schedule; let i = index" class="day-select-container">
        <div fxFlex="15" class="day-name-container">
          <mat-label>{{ this.getDayName(daySchedule.dayOfWeek) }}</mat-label>
        </div>

        <div fxFlex="5"></div>

        <div fxFlex="30" fxLayout="column">
          <mat-form-field appearance="outline" class="full-width">
            <mat-select placeholder="{{ 'common.from' | translate }}" [(ngModel)]="daySchedule.from">
              <mat-option *ngFor="let hour of availableHours" [value]="hour">{{ hour }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div fxFlex="5"></div>

        <div fxFlex="30" fxLayout="column">
          <mat-form-field appearance="outline" class="full-width">
            <mat-select placeholder="{{ 'common.to' | translate }}" [(ngModel)]="daySchedule.to">
              <mat-option *ngFor="let hour of availableHours" [value]="hour">{{ hour }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
  <div class="row-spacer"></div>

  <div fxLayout="row">
    <div>
      <button mat-flat-button color="light" (click)="this.close()">
        <fa-icon icon="times" />
      </button>
    </div>
    <div class="row-spacer"></div>
    <div>
      <button mat-flat-button color="warn" (click)="this.save()">
        <fa-icon icon="save" />
      </button>
    </div>
  </div>
</div>
