export enum EBuyerCockpitAuctionListType {
  Discount = 'discount',
  LastViewed = 'last-viewed',
  LastSearched = 'last-searched',
  PurchasePreference = 'purchase-preference',
  Recommended = 'recommended',
  Watchlist = 'watchlist',
  Running = 'running',
  PrivateVehicle = 'private-vehicle',
  InstantPurchase = 'instant-purchase',
}

export const COCKPIT_CARD_TYPES = [
  'blocked',
  'buyerLimitPurchaseBlocked',
  'buyerLimitPurchaseWarning',
  'openPickups',
  'openPayments',
  'openCheckouts',
  'outbid',
  'openRenegotiations',
  'uploadDocuments',
  'setPurchasePreferences',
  'checkAuctions',
] as const;

export type TBuyerCockpitCardType = (typeof COCKPIT_CARD_TYPES)[number];
