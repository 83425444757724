import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

import { ModelUtils, Validation } from '@caronsale/cos-models';
import { IVehicle } from '@caronsale/cos-vehicle-models';

import { I18nErrorDialogComponent } from '@cosCoreComponentsGeneral/i18n/error-dialog/i18n-error-dialog.component';
import { EUnknownValues } from '@cosUtils/business';

@Injectable()
export class I18nValidationService {
  public validateVehicleDamages(vehicle: IVehicle, dialog: MatDialog): boolean {
    if (vehicle.readyToDrive === null) {
      I18nErrorDialogComponent.show(dialog, 'validation.vehicle.dialogs.vehicle-ready-to-drive');
      return false;
    }

    if (vehicle.hadAccident) {
      if (!vehicle.accidentDescription) {
        I18nErrorDialogComponent.show(dialog, 'validation.vehicle.dialogs.describe-damages');
        return false;
      }
    }
    return true;
  }

  /**
   * Validates vehicle and returns associated error keys for further handling.
   *
   * @param vehicle
   */
  public validateVehicle(vehicle: IVehicle): string[] {
    const baseKey = 'validation.vehicle.invalid';

    const errorKeys = [];

    if (this.isVINInvalid(vehicle)) {
      errorKeys.push(`${baseKey}.vin`);
    }
    if (Validation.isInvalidString(vehicle.make)) {
      errorKeys.push(`${baseKey}.make`);
    }
    if (Validation.isInvalidString(vehicle.model)) {
      errorKeys.push(`${baseKey}.model`);
    }
    if (!ModelUtils.isSemanticEz(vehicle.ez)) {
      errorKeys.push(`${baseKey}.ez`);
    }
    if (Validation.isInvalidMileage(vehicle.mileageInKm)) {
      errorKeys.push(`${baseKey}.mileage`);
    }
    if (Validation.isInvalidCategory(vehicle.category)) {
      errorKeys.push(`${baseKey}.vehicle-category`);
    }
    if (Validation.isInvalidTransmission(vehicle.transmission)) {
      errorKeys.push(`${baseKey}.transmission`);
    }
    if (Validation.isUndefinedOrNull(vehicle.bodyColorCode)) {
      errorKeys.push(`${baseKey}.color`);
    }
    if (Validation.isInvalidEngineSize(vehicle.engineSizeInCcm)) {
      errorKeys.push(`${baseKey}.engine-size`);
    }
    if (Validation.isInvalidFuelType(vehicle.fuelType)) {
      errorKeys.push(`${baseKey}.fuel-type`);
    }
    if (Validation.isInvalidUpholsteryType(vehicle.upholstery)) {
      errorKeys.push(`${baseKey}.upholstery`);
    }
    if (Validation.isInvalidDoorValue(vehicle.doors)) {
      errorKeys.push(`${baseKey}.nr-of-doors`);
    }
    if (Validation.isUndefinedOrNull(vehicle.numSeats)) {
      errorKeys.push(`${baseKey}.nr-of-seats`);
    }
    if (Validation.isUndefinedOrNull(vehicle.enginePowerInHp) || vehicle.enginePowerInHp < 1) {
      errorKeys.push(`${baseKey}.engine-power`);
    }
    if (Validation.isUndefinedOrNull(vehicle.huReportExists)) {
      errorKeys.push(`${baseKey}.hu-report`);
    }
    if (Validation.isUndefinedOrNull(vehicle.fullServiceHistoryType)) {
      errorKeys.push(`${baseKey}.full-service-history-type`);
    }
    if (Validation.isUndefined(vehicle.isReimportedVehicle)) {
      errorKeys.push(`${baseKey}.reimported`);
    }
    if (this.isInvalidNumPreOwners(vehicle.numPreOwners)) {
      errorKeys.push(`${baseKey}.num-pre-owners`);
    }
    return errorKeys;
  }

  private isInvalidNumPreOwners(numPreOwners: number): boolean {
    if (numPreOwners >= 0 || numPreOwners < 9 || numPreOwners === EUnknownValues.PRE_OWNERS) {
      return false;
    }
    return true;
  }

  public isVINInvalid(vehicle: IVehicle): boolean {
    // TODO: Match VIN pattern
    return Validation.isUndefinedNullOrEmptyString(vehicle.vin) || Validation.isInvalidVin(vehicle.vin);
  }

  /**
   * Validates vehicle equipment and returns associated error keys for further handling.
   *
   * @param vehicle
   */
  public validateVehicleEquipment(vehicle: IVehicle): string[] {
    const baseKey = 'validation.vehicle-equipment.invalid';
    const errorKeys = [];

    if (Validation.isInvalidAc(vehicle.ac)) {
      errorKeys.push(`${baseKey}.ac`);
    }
    if (Validation.isInvalidCoupling(vehicle.coupling)) {
      errorKeys.push(`${baseKey}.coupling`);
    }
    if (Validation.isInvalidNavigation(vehicle.navigation)) {
      errorKeys.push(`${baseKey}.navigation`);
    }
    if (Validation.isInvalidParkingAssistance(vehicle.parkingAssistance)) {
      errorKeys.push(`${baseKey}.parking-assist`);
    }
    if (Validation.isInvalidHeadlights(vehicle.headlights)) {
      errorKeys.push(`${baseKey}.lights`);
    }
    if (Validation.isInvalidVehicleHeater(vehicle.vehicleHeater)) {
      errorKeys.push(`${baseKey}.heating`);
    }
    if (Validation.isInvalidSportPackage(vehicle.sportPackage)) {
      errorKeys.push(`${baseKey}.sport-package`);
    }
    if (Validation.isInvalidSunRoof(vehicle.sunRoof)) {
      errorKeys.push(`${baseKey}.sun-roof`);
    }
    return errorKeys;
  }
}
